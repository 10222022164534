import React from "react";
import "./AddReport.scss";
import { TextArea, Checkbox } from "semantic-ui-react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Icon, Button } from "semantic-ui-react";
import EditPlayerPhysical from "./EditPlayerPhysical";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import AddPhysicalCategory from "./AddPhysicalCategory";
import EditPhysicalCategory from "./EditPhysicalCategory";
import axios from "axios";
import { logout } from "./../../utils/api";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Select from "react-select";
import { store } from "react-notifications-component";
import language from "react-syntax-highlighter/dist/esm/languages/hljs/1c";

class AddReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      report_name: "",
      report_description: "",
      report_start_date: null,
      report_end_date: null,
      evaluator: "",
      selectedTeams: [],
      addedParameter: null,
      newParameterAdded: "",
      newParameterAddedUm: "",

      teams_dropdown: [],
      evaluators_dropdown: [],
      category_dropdown: [],
      players_dropdown: [],

      subCategories: [],

      openEditPopup: false,
      openAddEvaluationCategory: false,
      openEditCategory: false,
      playerToEdit: {},

      currentPlayer: "",
      currentPlayerName: "",
      currentPlayerValues: [],
      currentPlayerIdTeam: "",

      selectedCategories: [],

      players: [],
      addPlayer: false,

      checkedSubcategories: [],
    };
  }

  componentDidMount = () => {
    this.getDropdownTeams();
    this.getDropdownEvaluators();
    this.getDropdownCategories();
  };

  saveReport = () => {
    let dataPlayers = [];
    this.state.players.forEach((player) => {
      let marks = [];
      player.values.forEach((mark) => {
        marks.push({
          id_category: mark.category_id,
          id_subcategory: mark.param_id,
          test_grade: mark.test_grade,
          test_value: mark.value,
        });
      });

      dataPlayers.push({
        id_player: player.id_player,
        id_team: player.id_team,
        marks: marks,
      });
    });

    let total_parameters = 0;
    this.state.players.forEach((player) => {
      player.values.forEach((value) => {
        total_parameters++;
      });
    });

    //id team in players
    let idTeamPlayers = this.state.players.map((player) => player.id_team);

    //id selected teams
    let idSelectedTeams = this.state.selectedTeams.map((team) => team.value);

    //check if all teams have at least one player evaluated
    let teamsWithoutPlayer = idSelectedTeams.filter((idTeam) => {
      return idTeamPlayers.indexOf(idTeam) < 0;
    });

    let payloadTeams = idSelectedTeams.filter(
      (idTeam) => {
        return teamsWithoutPlayer.indexOf(idTeam) < 0;
      } // Returns true for items not found in b.
    );

    let payload = {
      id_club: this.props.currentUser.id_club,
      teams: payloadTeams,
      physical_test_name: this.state.report_name,
      id_evaluator: this.state.evaluator.value,
      physical_test_description: this.state.report_description,
      physical_test_start: this.state.report_start_date,
      physical_test_end: this.state.report_end_date,
      physical_test_data: dataPlayers,
      total_parameters: total_parameters,
    };

    axios
      .post("/report/add_physical_test", payload)
      .then((res) => {
        if (res.data == 1) {
          store.addNotification({
            title: "Evaluare raport",
            message: "Evaluarea a fost adaugata cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
          this.props.history.push(`/reports/report`);
        } else {
          store.addNotification({
            title: "Evaluare raport",
            message: "Evaluarea nu a fost adaugata cu succes!",
            type: "warning",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  addEditedParamValue = (arrayOfValues, idPlayer) => {
    let playersArray = this.state.players;

    let editedPlayer = playersArray.find(
      (player) => player.id_player == idPlayer
    );
    editedPlayer.values = arrayOfValues;
    // let index = playersArray.indexOf(idPlayer);
    let index = playersArray.findIndex((player) => {
      return player.id_player == idPlayer;
    });
    playersArray[index] = editedPlayer;
    this.setState({ players: playersArray });
  };

  getDropdownTeams = () => {
    axios
      .get(`report/get_teams?id_club=${this.props.currentUser.id_club}`)
      .then((res) => {
        let teamsDropdown = [];
        res.data.forEach((team) => {
          teamsDropdown.push({
            value: team.value,
            label: team.team_name,
          });
        });
        this.setState({ teams_dropdown: teamsDropdown });
      })
      .catch((err) => console.log(err));
  };

  getDropdownPlayers = (selectedTeams) => {
    this.setState({ selectedTeams: selectedTeams });

    let arrayOfIdTeams = this.state.selectedTeams.map(function(team) {
      return team.value; // Get team id from teams object
    });

    let arrayOfTeamsIdCurrent = [];

    if (selectedTeams.length > 0) {
      arrayOfTeamsIdCurrent = selectedTeams.map(function(currentTeam) {
        return currentTeam.value; // Get team id for current Teams
      });
    }

    if (selectedTeams.length > arrayOfIdTeams.length) {
      selectedTeams.forEach((team) => {
        if (!arrayOfIdTeams.includes(team.value)) {
          axios
            .get(`report/get_players`, {
              params: {
                id_team: team.value,
                // token: this.props.token,
              },
            })
            .then((res) => {
              let players = this.state.players_dropdown;

              res.data.forEach((player) => {
                players.push({
                  value: player.id_player,
                  label: player.player_name,
                  id_team: player.id_team,
                });
              });

              this.setState({
                players_dropdown: players,
              });
            })
            .catch((err) => console.log(err));
        } else {
        }
      });
    } else {
      // delete team from array
      arrayOfIdTeams.forEach((team, index) => {
        if (arrayOfTeamsIdCurrent.indexOf(team) == -1) {
          let previousState = this.state.selectedTeams;
          previousState.splice(index, 1); // function for deleting team based on index

          let players = this.state.players_dropdown.filter(
            (player) => player.id_team != team
          );

          //remove player from list of evaluation when team is removed
          let evaluationPlayers = this.state.players;
          let removeFromEvaluation = evaluationPlayers.filter(
            (player) => player.id_team != team
          );

          this.setState({
            // selectedTeams: previousState,
            players_dropdown: players,
            players: removeFromEvaluation,
          }); // set new state with new array of teams
        } else {
        }
      });
    }
  };

  getDropdownEvaluators = () => {
    axios
      .get(`report/get_evaluators?id_club=${this.props.currentUser.id_club}`)
      .then((res) => {
        let evaluators = [];
        res.data.forEach((evaluator) => {
          evaluators.push({
            value: evaluator.value,
            label: evaluator.member_name,
          });
        });
        this.setState({ evaluators_dropdown: evaluators });
      })
      .catch((err) => console.log(err));
  };

  getDropdownCategories = () => {
    axios
      .get(
        `report/get_physical_parameters_categories?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => {
        this.setState({ category_dropdown: res.data });
      })
      .catch((err) => console.log(err));
  };

  getParameters = (selectedCategories) => {
    this.setState({
      selectedCategories: selectedCategories,
    });
    var arrayOfCategories = this.state.selectedCategories.map(function(obj) {
      return obj.value;
    });
    var arrayOfCategoriesCurrent = [];

    if (selectedCategories.length > 0) {
      arrayOfCategoriesCurrent = selectedCategories.map(function(obj) {
        return obj.value;
      });
    }
    if (selectedCategories.length > arrayOfCategories.length) {
      selectedCategories.forEach((category) => {
        if (!arrayOfCategories.includes(category.value)) {
          axios
            .get(
              `report/get_physical_parameters?id_club=${this.props.currentUser.id_club}&category=${category.value}`
            )
            .then((res) => {
              category.checked = true;
              let subcategories = res.data;

              subcategories.forEach((subcategory) => {
                subcategory.checked = true;
              });
              const payload = {
                category: category,
                subcategories: subcategories,
              };
              let previousState = this.state.subCategories;
              previousState.push(payload);

              this.setState(
                {
                  subCategories: previousState,
                },
                () => this.findAllCheckedSubcat()
              );
            })
            .catch((err) => console.log(err));
        } else {
        }
      });
    } else {
      arrayOfCategories.forEach((category, index) => {
        if (arrayOfCategoriesCurrent.indexOf(category) == -1) {
          let previousState = this.state.subCategories;
          previousState.splice(index, 1);
          this.setState(
            {
              subCategories: previousState,
            },
            () => this.findAllCheckedSubcat()
          );
        } else {
        }
      });
    }
  };

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDateChangeStart = (value) => {
    this.setState({ report_start_date: value });
  };

  handleDateChangeEnd = (value) => {
    this.setState({ report_end_date: value });
  };

  handleDeletePlayer = (player) => {
    let playersListId = [];
    let playersList = this.state.players;

    playersList.forEach((player) => playersListId.push(player.id_player));
    const index = playersListId.indexOf(player.id_player);
    if (index > -1) {
      playersList.splice(index, 1);
    }

    let playersDropdown = this.state.players_dropdown;
    playersDropdown.push({
      value: player.id_player,
      label: player.player_name,
      id_team: player.id_team,
    });

    this.setState({ players: playersList, players_dropdown: playersDropdown });
  };

  handleExpandPlayer = (playerIndex) => {
    let playersList = this.state.players;
    playersList[playerIndex].expandPlayerRow = !playersList[playerIndex]
      .expandPlayerRow;
    this.setState({ players: playersList });
  };

  changeCheckedCategory = (index, data) => {
    let currentSubCategories = this.state.subCategories;
    currentSubCategories[index].category.checked = data.checked;
    if (!data.checked) {
      currentSubCategories[index].subcategories.forEach((subcategory) => {
        subcategory.checked = false;
      });
    } else {
      currentSubCategories[index].subcategories.forEach((subcategory) => {
        subcategory.checked = true;
      });
    }
    this.setState({ subCategories: currentSubCategories }, () => {
      this.findAllCheckedSubcat();
    });
  };

  findAllCheckedSubcat = () => {
    let checkedSubcategories = [];
    let subCategoriesCopy = this.state.subCategories;
    subCategoriesCopy.forEach((category) => {
      category.subcategories.forEach((subcategory) => {
        if (subcategory.checked) {
          const { checked, label, um, value } = subcategory;
          checkedSubcategories.push({
            checked: checked,
            label: label,
            um: um,
            value: value,
            id_category: category.category.value,
          });
        }
      });
    });

    this.setState({
      checkedSubcategories: checkedSubcategories,
    });
  };

  changeCheckedSubCategory = (indexCategory, indexSubCategory, data) => {
    let currentSubCategories = this.state.subCategories;
    currentSubCategories[indexCategory].subcategories[
      indexSubCategory
    ].checked = data.checked;
    if (!data.checked) {
      currentSubCategories[indexCategory].category.checked = false;
    } else {
      let checkAll = currentSubCategories[indexCategory].subcategories.every(
        (elem) => {
          return elem.checked;
        }
      );
      if (checkAll) {
        currentSubCategories[indexCategory].category.checked = true;
      }
    }
    this.setState({ subCategories: currentSubCategories }, () => {
      this.findAllCheckedSubcat();
    });
  };

  addPlayerList = () => {
    const playerAddedToList = {
      id_player: this.state.currentPlayer,
      id_team: this.state.currentPlayerIdTeam,
      player_name: this.state.currentPlayerName,
      values: this.state.currentPlayerValues,
      expandPlayerRow: true,
    };

    if (playerAddedToList.id_player == "") {
      this.showNotification(
        this.props.languagePack["add_player_warning_notification_title"],
        this.props.languagePack["add_player_warning_notification_text"],
        "warning",
        6000
      );
      return false;
    }

    let paramsGrades = [];
    let paramsValues = [];

    this.state.currentPlayerValues.forEach((playerParamValue) => {
      paramsGrades.push(playerParamValue.test_grade);
      paramsValues.push(playerParamValue.value);
    });

    let paramBiggerThan = paramsGrades.some((value) => value > 5);
    let smallerBiggerThan = paramsGrades.some((value) => value < 1);
    let emptyValueInGrade = paramsGrades.some((grade) => grade == "");

    let noValueParam = paramsValues.some((value) => value === undefined);
    let emptyValueInValue = paramsValues.some((value) => value == "");

    if (paramsGrades.length != this.state.checkedSubcategories.length) {
      this.showNotification(
        "Introdu nota",
        "Toate notele pentru parametrii trebuie intoduse",
        "warning",
        6000
      );
      return false;
    }

    if (emptyValueInGrade == true) {
      this.showNotification(
        "Introdu nota",
        "Toate notele pentru parametrii trebuie intoduse!!!!!!!",
        "warning",
        6000
      );
      return false;
    }

    if (emptyValueInValue == true || noValueParam == true) {
      this.showNotification(
        "Introdu valoare parametru",
        "Toate valorile pentru parametrii trebuie intoduse!!!!!!!",
        "warning",
        6000
      );
      return false;
    }

    if (paramBiggerThan == true) {
      this.showNotification(
        "Valore nota prea mare",
        "Nota introdusa trebuie sa fie cuprinsa intre 1 si 5",
        "warning",
        6000
      );
      return false;
    }

    if (smallerBiggerThan == true) {
      this.showNotification(
        "Valore nota prea mica",
        "Nota introdusa trebuie sa fie cuprinsa intre 1 si 5",
        "warning",
        6000
      );
      return false;
    }

    //remove player from players dropdown options
    let playersDropdown = this.state.players_dropdown.filter(
      (player) => player.value != playerAddedToList.id_player
    );

    let players = this.state.players;
    players.forEach((player) => {
      player.expandPlayerRow = false;
    });
    players.unshift(playerAddedToList);

    this.setState((prevState) => ({
      players: players,
      addPlayer: false,
      currentPlayerIdTeam: "",
      currentPlayer: "",
      currentPlayerName: "",
      currentPlayerValues: [],
      players_dropdown: playersDropdown,
    }));
  };

  handleChangeParamValue = (
    e,
    param_id,
    category_id,
    param_name,
    param_um,
    param_index
  ) => {
    let param_value = e.target.value;
    let copyPlayers = this.state.currentPlayerValues;

    if (copyPlayers[param_index] != undefined) {
      copyPlayers[param_index].value = param_value;
      copyPlayers[param_index].param_name = param_name;
      copyPlayers[param_index].param_um = param_um;
      copyPlayers[param_index].param_id = param_id;
      copyPlayers[param_index].category_id = category_id;
      copyPlayers[param_index].index = param_index;
    } else {
      copyPlayers[param_index] = {
        value: param_value,
        param_name: param_name,
        param_um: param_um,
        param_id: param_id,
        category_id: category_id,
        index: param_index,
        test_grade: "",
      };
    }

    this.setState({
      currentPlayerValues: copyPlayers,
    });
  };

  handleTestGrade = (gradeValue, index) => {
    let currentPlayerValues = this.state.currentPlayerValues;

    if (
      // currentPlayerValues[index] == null ||
      currentPlayerValues[index] == undefined
    ) {
      currentPlayerValues[index] = {
        index: index,
        test_grade: gradeValue,
      };
      this.setState({ currentPlayerValues: currentPlayerValues });
      return;
    }

    if (currentPlayerValues[index] == null) {
      currentPlayerValues[index] = {
        test_grade: gradeValue,
      };
      this.setState({ currentPlayerValues: currentPlayerValues });
      return;
    }

    currentPlayerValues[index].test_grade = gradeValue;
    this.setState({ currentPlayerValues: currentPlayerValues });
  };

  showNotification = (title, message, type, duration = 3000) => {
    store.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: duration,
      },
    });
  };

  render() {
    const { languagePack } = this.props;

    return (
      <div className="row">
        <div className="col-lg-6">
          <div className="card card-custom">
            <div className="add-report-container">
              <div className="add-report-header">
                <div className="add-report-title">
                  {languagePack.add_physical_tests}
                </div>
              </div>
              <div className="add-report-form">
                <div className="add-report-row">
                  <div className="add-report-select">
                    <Select
                      placeholder={languagePack.select_team_teams}
                      isMulti={true}
                      options={this.state.teams_dropdown}
                      value={this.state.selectedTeams}
                      onChange={(selected) => {
                        selected != null
                          ? this.getDropdownPlayers(selected)
                          : this.getDropdownPlayers([]);

                        if (selected == null)
                          this.setState({
                            addPlayer: false,
                          });
                      }}
                    />
                  </div>
                </div>

                <div className="add-report-row">
                  <div className="add-report-select">
                    <Select
                      placeholder={languagePack.select_evaluator}
                      options={this.state.evaluators_dropdown}
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={this.state.evaluator}
                      onChange={(selected) =>
                        this.setState({ evaluator: selected })
                      }
                      isClearable={true}
                      isSearchable={true}
                    />
                  </div>
                </div>

                <div className="add-report-row">
                  <input
                    value={this.state.report_name}
                    onChange={this.handleChangeInput}
                    className="add-report-input"
                    name="report_name"
                    placeholder={languagePack["report_name"]}
                  />
                </div>

                <TextArea
                  value={this.state.report_description}
                  onChange={this.handleChangeInput}
                  className="add-report-text-area"
                  name="report_description"
                  placeholder={languagePack["staff_description"]}
                />

                <div className="add-report-row">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="add-report-date-group">
                      <DateTimePicker
                        value={this.state.report_start_date}
                        onChange={this.handleDateChangeStart}
                        format="dd MMMM,  HH:mm"
                        name="physical_test_date_start"
                        className="report-date-input"
                        inputVariant="outlined"
                        emptyLabel={languagePack["starting_date"]}
                      />
                      <Icon
                        name="calendar alternate outline"
                        className="calendar-icon"
                        size="small"
                      />
                    </div>
                    <div className="add-report-date-group">
                      <DateTimePicker
                        value={this.state.report_end_date}
                        onChange={this.handleDateChangeEnd}
                        format="dd MMMM,  HH:mm"
                        name="physical_test_date_end"
                        inputVariant="outlined"
                        className="report-date-input"
                        emptyLabel={languagePack["ending_date"]}
                      />
                      <Icon
                        name="calendar alternate outline"
                        className="calendar-icon"
                        size="small"
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="parameters-subtitle">
                {this.state.subCategories.length}{" "}
                {languagePack.selected_categories}
                <div className="add-report-header">
                  <Button
                    onClick={() => this.setState({ openEditCategory: true })}
                    className="edit-physical-category-button"
                    style={{ backgroundColor: "#666666" }}
                  >
                    {languagePack.edit_category_list}
                  </Button>
                  <Button
                    onClick={() =>
                      this.setState({ openAddEvaluationCategory: true })
                    }
                    className="add-physical-category-button"
                  >
                    {languagePack.add_cost_category}
                    <Icon
                      name="add"
                      className="add-physical-category-button-icon"
                    />
                  </Button>
                </div>
              </div>
              <div className="add-param-row">
                <Select
                  placeholder={languagePack.select_parameters_category}
                  isMulti={true}
                  options={this.state.category_dropdown}
                  className="basic-single"
                  classNamePrefix="select"
                  value={this.state.selectedCategories}
                  onChange={(selected) => {
                    selected != null
                      ? this.getParameters(selected)
                      : this.getParameters([]);

                    if (selected == null)
                      this.setState({ subCategories: [], addPlayer: false });
                  }}
                  isClearable={true}
                  isSearchable={true}
                />
              </div>
              <div className="table-content">
                {this.state.subCategories.map((item, index) => {
                  return (
                    <div key={"subCateg-" + index} className="table-row">
                      <div className="evaluation-category-name">
                        <div
                          className="player-name"
                          style={{ fontSize: "20px" }}
                        >
                          {item.category.label}
                        </div>

                        {/* CHECKBOX */}
                        <div className="add-player-row">
                          <div className="add-checkbox-input">
                            <Checkbox
                              checked={item.category.checked}
                              onChange={(event, data) => {
                                this.changeCheckedCategory(index, data);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="sub-categories-row">
                        {item.subcategories.map((elem, index2) => {
                          return (
                            <div
                              key={"subCateg1-" + index2}
                              className="sub-category"
                            >
                              <div className="evaluation-category-name">
                                <div className="player-name">{elem.label}</div>
                              </div>

                              {/* CHECKBOX */}
                              <div className="add-player-row">
                                <div className="add-checkbox-input">
                                  <Checkbox
                                    checked={elem.checked}
                                    onChange={(event, data) => {
                                      this.changeCheckedSubCategory(
                                        index,
                                        index2,
                                        data
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card card-custom">
            <div className="add-report-container">
              <div className="add-report-header">
                <div className="add-report-title">
                  {languagePack["evaluation_list"]}:
                </div>
                {this.state.selectedCategories.length > 0 &&
                  this.state.selectedTeams.length > 0 && (
                    <Button
                      onClick={() =>
                        this.setState((prevState) => ({
                          ...prevState,
                          addPlayer: true,
                        }))
                      }
                      className="add-player-button"
                    >
                      {languagePack["add_player"]}
                      <Icon name="add" className="add-player-button-icon" />
                    </Button>
                  )}
              </div>
              {this.state.addPlayer && (
                <div className="add-player-container">
                  <Select
                    placeholder={languagePack.choose_player}
                    options={this.state.players_dropdown}
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={(e) =>
                      this.setState({
                        currentPlayer: e == null ? "" : e.value,
                        currentPlayerName: e == null ? "" : e.label,
                        currentPlayerIdTeam: e == null ? "" : e.id_team,
                      })
                    }
                    isClearable={true}
                    isSearchable={true}
                  />
                  <div className="params-physical-test-list">
                    {this.state.subCategories.map((item, index) => {
                      return (
                        <React.Fragment key={"add-data-key-" + index}>
                          {item.subcategories.some(
                            (subCategory) => subCategory.checked == true
                          ) && (
                            <div
                              className="category-name-player"
                              key={"category-name-player-key" + index}
                            >
                              {item.category.label}
                            </div>
                          )}
                          {this.state.checkedSubcategories.map(
                            (subcategory, index2) => {
                              if (
                                subcategory.id_category == item.category.value
                              ) {
                                return (
                                  <div
                                    className="add-player-param-row"
                                    style={{
                                      backgroundColor:
                                        index2 % 2 == 0 ? "#fafafa" : "",
                                    }}
                                    key={
                                      "add-player-params-" +
                                      index +
                                      "-" +
                                      index2
                                    }
                                  >
                                    <div
                                      className="parameter-name"
                                      key={"subcategory-display-key-" + index2}
                                    >
                                      {subcategory.label}
                                    </div>
                                    <div
                                      className="physical-test-input-buttons"
                                      key={
                                        "subcategory-inputs-list-key-" + index2
                                      }
                                    >
                                      <input
                                        className="add-player-value-input"
                                        key={
                                          "subcategory-input-value-list-key-" +
                                          index2
                                        }
                                        placeholder={languagePack.value}
                                        type="number"
                                        onChange={(e) =>
                                          this.handleChangeParamValue(
                                            e,
                                            subcategory.value,
                                            item.category.value,
                                            subcategory.label,
                                            subcategory.um,
                                            index2
                                          )
                                        }
                                      />
                                      <input
                                        className="add-player-value-input"
                                        key={
                                          "subcategory-input-grade-list-key-" +
                                          index2
                                        }
                                        placeholder={languagePack.mark}
                                        type="number"
                                        min="1"
                                        max="5"
                                        onChange={(e) =>
                                          this.handleTestGrade(
                                            e.target.value,
                                            index2
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            }
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                  {this.state.subCategories.length > 0 &&
                    this.state.selectedTeams.length > 0 && (
                      <div className="add-player-buttons-container">
                        <Button
                          className="add-report-button cancel-button"
                          onClick={() => this.setState({ addPlayer: false })}
                        >
                          {languagePack["admin_cancel"]}{" "}
                          <Icon name="ban" className="add-report-button-icon" />
                        </Button>
                        <Button
                          className="add-report-button"
                          onClick={this.addPlayerList}
                        >
                          {languagePack["add_data"]}{" "}
                          <Icon name="add" className="add-report-button-icon" />
                        </Button>
                      </div>
                    )}
                </div>
              )}
              <div className="players-list">
                {this.state.players.length > 0 ? (
                  <div className="players-list-table-header">
                    <div>{languagePack["player_name"]}</div>
                    <div>{languagePack["evaluation"]}</div>
                  </div>
                ) : (
                  <div className="no-evaluation-test-physical-message">
                    <Icon name="search minus" color="black" size="big" />
                    <div className="no-evaluation-test-physical-message-text">
                      {languagePack["no_data_report_physical"]}
                    </div>
                  </div>
                )}
                {this.state.players.map((item, index) => {
                  return (
                    <React.Fragment key={"show-player-added-data-key-" + index}>
                      <div
                        className="player-row"
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                        }}
                        key={"added-player-" + index}
                      >
                        <div className="player-name">
                          <img
                            className="player-avatar"
                            alt="avatar"
                            src={`${window.baseURL}uploads/players/${item.id_player}.jpg`}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = `${window.baseURL}uploads/players/placeholder.png`;
                            }}
                          />

                          <div className="name">{item.player_name}</div>
                        </div>

                        <div className="list-test-player-icons">
                          {!item.expandPlayerRow ? (
                            <div
                              className="test-parameter-expand-button"
                              onClick={() => {
                                this.handleExpandPlayer(index);
                              }}
                            >
                              <Icon
                                name="angle down"
                                alt="expand-row"
                                className="angle-down-icon"
                              />
                            </div>
                          ) : (
                            <div
                              className="test-parameter-expand-button"
                              onClick={() => {
                                this.handleExpandPlayer(index);
                              }}
                            >
                              <Icon
                                name="angle up"
                                alt="expand-row"
                                className="angle-up-icon"
                              />
                            </div>
                          )}
                          <div
                            className="test-parameter-delete-button"
                            onClick={() => {
                              this.handleDeletePlayer(item);
                            }}
                          >
                            <Icon
                              name="trash alternate"
                              alt="delete"
                              className="test-trash-img"
                            />
                          </div>
                          <div
                            className="test-parameter-edit-button"
                            onClick={() => {
                              this.setState({
                                openEditPopup: true,
                                playerToEdit: item,
                              });
                            }}
                          >
                            <Icon
                              name="edit"
                              className="edit-icon black-icon"
                            />
                          </div>
                        </div>
                      </div>
                      {item.expandPlayerRow && (
                        <div className="player-expanded-row">
                          {item.values.map((param, paramIndex) => {
                            return (
                              <div
                                className="param-container"
                                key={"params-to-add-" + paramIndex}
                              >
                                <div className="param-name">
                                  {param.param_name}
                                </div>
                                <div className="param-value">
                                  {param.value} {param.um}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
              {this.state.players.length > 0 && (
                <div className="final-report-submit-button-row">
                  <Button
                    className="final-report-submit-button"
                    onClick={() => this.saveReport()}
                    disabled={
                      this.state.report_name == "" ||
                      this.state.report_start_date == null ||
                      this.state.report_end_date == null ||
                      this.state.evaluator == ""
                    }
                  >
                    {languagePack.save_report}
                    <Icon
                      name="add"
                      className="final-report-submit-button-icon"
                    />{" "}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <Dialog open={this.state.openEditPopup}>
          <DialogContent>
            <EditPlayerPhysical
              closePopup={() => this.setState({ openEditPopup: false })}
              setPlayerParamValues={this.addEditedParamValue}
              playerToEdit={this.state.playerToEdit}
              languagePack={this.props.languagePack}
            />
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.openAddEvaluationCategory}>
          <DialogContent>
            <AddPhysicalCategory
              closePopup={() =>
                this.setState({ openAddEvaluationCategory: false })
              }
              id_club={this.props.currentUser.id_club}
              languagePack={this.props.languagePack}
              getDropdownCategories={this.getDropdownCategories}
            />
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.openEditCategory}>
          <DialogContent>
            <EditPhysicalCategory
              closePopup={() => this.setState({ openEditCategory: false })}
              id_club={this.props.currentUser.id_club}
              languagePack={this.props.languagePack}
              categories={this.state.category_dropdown}
              getCategories={this.getDropdownCategories}
              getSubCategories={this.getParameters}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(AddReport);
