import React from "react";
import TFBButton from "../../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../../components/design-system/Icon/TFBIcon";
import TFBDialog from "../../../../../components/design-system/Dialog/TFBDialog";
import axios from "axios";
import { store } from "react-notifications-component";

export default function DeletePlayerPopup({
  closePopup,
  openDeletePopup,
  languagePack,
  id_match,
  id_player_to_delete,
  refreshSquad,
}) {
  const renderBtn = (position, onClick) => {
    return (
      <TFBButton
        onClick={onClick}
        endIcon={true}
        color={position == "left" ? "darkGray" : "red"}
        renderIcon={() => (
          <TFBIcon name={position == "left" ? "cancel" : "delete"} />
        )}
        prettier-ignore
      >
        {position == "left" ? languagePack.Cancel : languagePack.Delete}
      </TFBButton>
    );
  };

  const deletePlayer = () => {
    let payload = {
      id_match: id_match,
      id_player: id_player_to_delete,
    };

    axios
      .post("match/delete_player_from_match_squad", payload)
      .then((res) => {
        if (res.status == 200 && res.data.success == 1) {
          store.addNotification({
            title: languagePack.match_pl,
            message: languagePack.the_player_has_been_removed_from_the_squad,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: languagePack.match_pl,
            message:
              languagePack.fail_the_player_has_been_removed_from_the_squad,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        closePopup();
        refreshSquad();
      })
      .catch((e) => console.log(e));
  };

  return (
    <TFBDialog
      className="pop-up-dialog-box prevent-select-text"
      title={languagePack.delete_player}
      open={openDeletePopup}
      closePopup={closePopup}
      btnRight={() => renderBtn("right", () => deletePlayer())}
      btnLeft={() => renderBtn("left", () => closePopup())}
    >
      <div style={{ fontWeight: 700, fontSize: "15px", textAlign: "center" }}>
        {languagePack.delete_player_from_match_squad}
      </div>
    </TFBDialog>
  );
}
