import React from "react";
import "./SiteSponsors.scss";
import { Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import axios from "axios";
import Moment from "react-moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddSponsor from "./AddSponsor";
import delete_icon from "../../../assets/icons/delete.png";
import { store } from "react-notifications-component";

class SiteSponsors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addSponsorPopup: false,
      id_deleted_sponsor: "",
      partners: [],
    };
  }

  componentDidMount = () => {
    this.getPartners();
  };

  getPartners = () => {
    axios
      .get(`site/get_sponsors?id_club=${this.props.currentUser.id_club}`)
      .then((res) => this.setState({ partners: res.data }))
      .catch((err) => console.log(err));
  };

  deleteSponsor = () => {
    const payload = {
      id_sponsor: this.state.id_deleted_sponsor,
    };
    axios
      .post(`site/delete_sponsor`, payload)
      .then((res) => {
        store.addNotification({
          title: this.props.languagePack["sponsor_site_notif_title"],
          message: this.props.languagePack["delete_sponsor_notif_text"],
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        this.setState({ id_deleted_sponsor: "" }, this.getPartners);
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="card card-custom">
        <div className="site-sponsors-container">
          <div className="site-sponsors-header">
            <div className="site-sponsors-title">
              {languagePack["partners_title"]}
            </div>
            <Button
              onClick={() => this.setState({ addSponsorPopup: true })}
              className="add-sponsor-button"
            >
              {languagePack["add_partner_button"]}
              <Icon name="arrow up" className="add-sponsor-icon" />
            </Button>
          </div>
          <div className="site-sponsors-list">
            <div className="site-sponsors-list-header">
              <div className="sponsor-name">{languagePack.sponsor_name}</div>
              <div className="sponsor-date-add">{languagePack.Date_added}</div>
              <div className="sponsor-options">
                {languagePack.news_page_header_options}
              </div>
            </div>
            {this.state.partners.map((item, index) => (
              <div
                className="site-sponsor-row"
                style={{
                  backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                }}
                key={"partner-" + index}
              >
                <div className="sponsor-name">
                  <img
                    src={`https://api.thefootballbrain.app/uploads/site/sponsors_logos/${this.props.currentUser.id_club}/${item.id_site_sponsor}.png`}
                    alt="sponsor-logo"
                  />
                  {item.sponsor_name}
                </div>
                <div className="sponsor-date-add">
                  <Moment format="DD.MM.YYYY">{item.date_add}</Moment>
                </div>
                <div className="sponsor-options">
                  <img
                    src={delete_icon}
                    alt="delete"
                    onClick={() =>
                      this.setState({
                        id_deleted_sponsor: item.id_site_sponsor,
                      })
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <Dialog
          open={this.state.addSponsorPopup}
          // onClose={() => this.setState({ addSponsorPopup: false })}
          maxWidth="xl"
        >
          <DialogContent className="add-partner-popup-container">
            <div className="add-sponsor-delete-popup">
              <div className="header">
                <div className="header-title">
                  {languagePack["add_partner_button"]}
                </div>
                <Icon
                  name="close"
                  className="header-photos-delete-icon"
                  onClick={() => this.setState({ addSponsorPopup: false })}
                />
              </div>
              <AddSponsor
                id_club={this.props.currentUser.id_club}
                closePopup={() => this.setState({ addSponsorPopup: false })}
                refreshList={() => this.getPartners()}
              />
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.id_deleted_sponsor !== ""}
          // onClose={() => this.setState({ id_deleted_sponsor: "" })}
          maxWidth="xl"
        >
          <DialogContent className="add-partner-popup-container">
            <div className="add-sponsor-delete-popup">
              <div className="header">
                <div className="header-title">
                  {languagePack["delete_partner"]}
                </div>
                <Icon
                  name="close"
                  className="header-photos-delete-icon"
                  onClick={() => this.setState({ id_deleted_sponsor: "" })}
                />
              </div>
              <div className="delete-partner-text">
                {languagePack["delete_partner_popup_text"]}
              </div>
              <div className="add-partner-buttons-row">
                <Button
                  className="add-partner-button"
                  style={{ backgroundColor: "#666666" }}
                  onClick={() => this.setState({ id_deleted_sponsor: "" })}
                >
                  {languagePack["admin_cancel"]}
                  <Icon name="ban" size="small" className="add-partner-icon" />
                </Button>
                <Button
                  className="add-partner-button"
                  style={{ backgroundColor: "#00d948" }}
                  onClick={this.deleteSponsor}
                >
                  {languagePack["admin_confirm"]}
                  <Icon
                    name="check"
                    size="small"
                    className="add-partner-icon"
                  />
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(SiteSponsors);
