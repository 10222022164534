import React, { useState, useEffect } from "react";
import "../Matches.scss";
import axios from "axios";
import { Icon } from "semantic-ui-react";
import { ReactComponent as EyeIcon } from "../../../../assets/icons/eye-white.svg";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DeleteMatch from "../AddMatch/DeleteMatch";
import EditMatch from "../AddMatch/EditMatch";
import * as constants from "./../../../../../app/utils/constants";
import permissions from "./../../../../../app/utils/permissions";
import moment from "moment";
import "moment/locale/ro";

function ManualLastMatches(props) {
  const { languagePack } = props;
  const history = useHistory();

  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);

  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [idEditMatch, setIdEditMatch] = useState("");

  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [idDeleteMatch, setIdDeleteMatch] = useState("");

  useEffect(() => {
    getPastMatches();
  }, [
    props.id_team,
    props.id_comp,
    props.refreshTimes,
    props.currencyItemSelected,
  ]);

  function getPastMatches() {
    axios
      .get(
        `match/get_matches_all_footbal_api_2?id_team=${props.id_team}&id_club=${props.currentUser.id_club}&type=past&currency=${props.currencyItemSelected.uniqueSlug}`
      )
      .then((res) => {
        setMatches(Array.isArray(res.data) ? res.data : []);
        setLoading(true);
      })
      .catch((err) => console.log(err));
  }

  return (
    <div>
      {loading && matches.length > 0 ? (
        <div className="matches-list">
          <div className="match-container match-list-col-title match-schedule-head-desktop">
            <div className="match-date">{languagePack["round"]}</div>
            <div className="match-column-title">{languagePack["match"]}</div>
          </div>
          {matches.map((item, index) => {
            return item.id_instat_match == null ? (
              <div
                className="match-container flex-column-mobile"
                style={{ backgroundColor: index % 2 === 0 ? "#ffffff" : "" }}
                key={"match-" + index}
              >
                <div className="match-date">
                  {item.id_competition > 0
                    ? item.competition_name +
                      ", " +
                      languagePack["round"] +
                      " " +
                      item.round
                    : languagePack.frendly_match}
                  <br></br>
                  {item.venue_name != "" && (
                    <>
                      {item.venue_name}
                      <br></br>
                    </>
                  )}
                  {item.match_date != "0000-00-00 00:00:00"
                    ? moment(item.match_date)
                        .locale("ro")
                        .format("dddd, DD.MM.YYYY, HH:mm")
                    : "-"}
                </div>
                <div className="match-details">
                  <div className="match-team">
                    <div
                      className="team-name"
                      style={{ textAlign: "end", paddingRight: 30 }}
                    >
                      {item.team_home_name}
                    </div>
                    <div className="match-logo">
                      <img src={item.home_team_url} alt="team_home_logo" />
                    </div>
                  </div>
                  <div className="match-score">
                    {item.team_home_goals}:{item.team_away_goals}
                  </div>
                  <div className="match-team">
                    <div className="match-logo">
                      <img src={item.away_team_url} alt="team_away_logo" />
                    </div>
                    <div className="team-name" style={{ paddingLeft: 30 }}>
                      {item.team_away_name}
                    </div>
                  </div>
                </div>
                <div className="icons-container">
                  <div className="icon-notification">
                    <div className="add-score-icon">
                      <Icon
                        style={{ cursor: "pointer" }}
                        name="credit card"
                        size="large"
                        color="black"
                        onClick={() => {
                          history.push(
                            `/club/match-costs/edit/${item.id_match}`
                          );
                        }}
                      />
                    </div>
                    <div className="notification-container">
                      <div className="notification-circle">
                        <div className="notification-number">
                          {Number(item.match_costs_update).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                  {(item.id_common_competition == null ||
                    props.currentUser.user_permissions[
                      permissions.EDIT_COMMON_COMPETITION
                    ]) && (
                    <>
                      <div className="add-score-icon">
                        <Icon
                          style={{ cursor: "pointer" }}
                          name="trash"
                          size="large"
                          color="black"
                          onClick={() => {
                            setIdDeleteMatch(item.id_match);
                            setOpenDeletePopup(true);
                          }}
                        />
                      </div>
                      <div className="add-score-icon">
                        <Icon
                          style={{ cursor: "pointer" }}
                          name="edit"
                          size="large"
                          color="black"
                          onClick={() => {
                            setOpenEditPopup(true);
                            setIdEditMatch(item.id_match);
                          }}
                        />
                      </div>
                    </>
                  )}
                  <div className="match-see-more">
                    <div
                      className="match-see-more-button"
                      onClick={() =>
                        history.push(`/club/match/${item.id_match}`)
                      }
                    >
                      <EyeIcon />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="match-container flex-column-mobile"
                style={{ backgroundColor: index % 2 === 0 ? "#ffffff" : "" }}
                key={"match-" + index}
              >
                <div className="match-date">
                  {item.id_competition > 0
                    ? item.competition_name +
                      ", " +
                      languagePack["round"] +
                      " " +
                      item.round
                    : "Meci amical"}
                  <br></br>
                  {item.venue_name != "" && (
                    <>
                      {item.venue_name}
                      <br></br>
                    </>
                  )}
                  {moment(item.match_date)
                    .locale("ro")
                    .format("dddd, DD.MM.YYYY, HH:mm")}
                </div>
                <div className="match-details">
                  <div className="match-team">
                    <div
                      className="team-name"
                      style={{ textAlign: "end", paddingRight: 30 }}
                    >
                      {item.team_home_name}
                    </div>
                    <div className="match-logo">
                      <img src={item.home_team_url} alt="team_home_logo" />
                    </div>
                  </div>
                  <div className="match-score">
                    {item.team_home_goals}:{item.team_away_goals}
                  </div>
                  <div className="match-team">
                    <div className="match-logo">
                      <img src={item.away_team_url} alt="team_away_logo" />
                    </div>
                    <div className="team-name" style={{ paddingLeft: 30 }}>
                      {item.team_away_name}
                    </div>
                  </div>
                </div>
                <div className="icons-container">
                  <div className="icon-notification">
                    <div className="add-score-icon">
                      <Icon
                        style={{ cursor: "pointer" }}
                        name="credit card"
                        size="large"
                        color="black"
                        onClick={() => {
                          history.push(
                            `/club/match-costs/edit/${item.id_match}`
                          );
                        }}
                      />
                    </div>
                    <div className="notification-container">
                      <div className="notification-circle">
                        <div className="notification-number">
                          {Number(item.match_costs_update).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="match-see-more">
                    <div
                      className="match-see-more-button"
                      onClick={() =>
                        history.push(
                          `/club/match/instat/${item.id_instat_match}`
                        )
                      }
                    >
                      <EyeIcon />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          className="no-matches-placeholder"
          style={{ backgroundColor: "#fafafa" }}
        >
          {languagePack["no_matches_for_selected_options"]}
        </div>
      )}
      <Dialog open={openEditPopup} fullWidth maxWidth="sm">
        <DialogContent className="add-team-manual-wrapper">
          <EditMatch
            closePopup={() => {
              setOpenEditPopup(false);
              setIdEditMatch("");
            }}
            getMatches={() => {
              getPastMatches();
            }}
            idMatch={idEditMatch}
            type={"past"}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openDeletePopup}>
        <DialogContent className="add-team-manual-wrapper">
          <DeleteMatch
            closePopup={() => {
              setOpenDeletePopup(false);
              setIdDeleteMatch("");
            }}
            getMatches={() => {
              getPastMatches();
            }}
            id_match={idDeleteMatch}
            type={"past"}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  currencyItemSelected:
    constants.currencies[auth?.user?.currency] || constants.currencies.EUR,
}))(ManualLastMatches);
