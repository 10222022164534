import React from "react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { CirclePicker } from "react-color";
import moment from "moment";
import { Dropdown, Button, Icon, Popup, TextArea } from "semantic-ui-react";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import axios from "axios";

class AddEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event_types: [],
      newEventName: "",
      newEventTeam: "",
      newEventCategory: "",
      newEventDescription: "",
      newEventStart: null,
      newEventEnd: null,
      newCategoryName: "",
      newCategoryColor: "",
      addNewCategory: false,
    };
  }

  componentDidMount = () => {
    this.getTypes();
    this.getTeams();
  };

  getTypes = () => {
    axios
      .get("calendar/get_event_type", {
        params: {
          token: this.props.token,
          id_club: this.props.currentUser.id_club
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState({
            event_types: Array.isArray(res.data) ? res.data : [],
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getTeams = () => {
    axios
      .get("calendar/get_teams_dropdown", {
        params: {
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState({
            teams_dropdown: Array.isArray(res.data) ? res.data : [],
          });
        }
      })
      .catch((err) => console.log(err));
  };

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeDropdown = (e, value) => {
    this.setState({
      [value.name]: value.value,
    });
  };

  handleDateChangeStart = (value) => {
    this.setState({ newEventStart: value });
  };

  handleDateChangeEnd = (value) => {
    this.setState({ newEventEnd: value });
  };

  addNewCategory = () => {
    const categ = {
      event_type_name: this.state.newCategoryName,
      event_type_color: this.state.newCategoryColor,
      id_club: this.props.currentUser.id_club,
    };
    axios
      .post("calendar/add_category", categ, {
        params: {
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.getTypes();
          this.setState({
            newEventCategory: res.data,
            addNewCategory: false,
          });
        }
      });
  };

  handleSubmitEvent = () => {
    const formData = new FormData();
    formData.append("id_club", this.props.currentUser.id_club);
    formData.append("id_team", this.state.newEventTeam);
    formData.append("title", this.state.newEventName);
    formData.append("type", this.state.newEventCategory);
    if (this.state.newEventDescription.length > 0) {
      formData.append("description", this.state.newEventDescription);
    }
    formData.append(
      "start",
      moment(this.state.newEventStart).format("YYYY-MM-DD HH:mm:ss")
    );
    formData.append(
      "end",
      moment(this.state.newEventEnd).format("YYYY-MM-DD HH:mm:ss")
    );
    formData.append("id_user_added", this.props.currentUser.id_user);

    axios
      .post("calendar/add_event", formData, {
        params: {
          token: this.props.token,
        },
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState(
            {
              addEventDialogOpen: false,
              newEventName: "",
              newEventTeam: "",
              newEventStart: null,
              newEventEnd: null,
              newEventCategory: "",
              newEventDescription: "",
              newCategoryColor: "",
            },
            this.props.refreshEvents()
          );
          store.addNotification({
            title: "Evenimente calendar",
            message: "Evenimentul a fost adaugat cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      });
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="add-event-form-container">
        <div className="add-event-form-row">
          <input
            type="text"
            value={this.state.newEventName}
            onChange={(e) => this.setState({ newEventName: e.target.value })}
            placeholder={languagePack.event_name}
            className="add-event-input"
          />
        </div>
        <div className="add-event-form-row">
          <Dropdown
            placeholder={languagePack["choose_team"]}
            className="add-event-dropdown dropdown-full-width"
            selection
            options={this.state.teams_dropdown}
            onChange={this.handleChangeDropdown}
            name="newEventTeam"
            value={this.state.newEventTeam}
          />
        </div>

        <div className="add-event-form-row">
          <Dropdown
            placeholder={languagePack.event_category}
            selection
            options={this.state.event_types}
            onChange={this.handleChangeDropdown}
            name="newEventCategory"
            value={this.state.newEventCategory}
            disabled={this.state.addNewCategory}
            className="add-event-dropdown"
          />
          <div
            className="add-event-form-button dark-grey-button"
            onClick={() =>
              this.setState({
                addNewCategory: !this.state.addNewCategory,
              })
            }
          >
            <Icon name="add" className="add-event-plus-icon" size="small" />
          </div>
        </div>

        {this.state.addNewCategory ? (
          <div>
            <div className="add-event-form-row">
              <input
                type="text"
                value={this.state.newCategoryName}
                onChange={(e) =>
                  this.setState({ newCategoryName: e.target.value })
                }
                placeholder={languagePack.new_categ_name}
                className="add-event-input"
              />
            </div>
            <div
              className="add-event-form-row"
              style={{ justifyContent: "space-between" }}
            >
              <Popup
                trigger={
                  <Button
                    style={{
                      backgroundColor: this.state.newCategoryColor
                        ? this.state.newCategoryColor
                        : "#fafafa",
                    }}
                    className="color-button"
                  >
                    {languagePack["label_color"]}
                  </Button>
                }
                content={
                  <CirclePicker
                    color={this.state.newCategoryColor}
                    onChange={(color) => {
                      this.setState({ newCategoryColor: color.hex });
                    }}
                  />
                }
                on="click"
              />
              <div className="buttons-color-container">
                <Button
                  className="add-event-form-button  dark-grey-button"
                  onClick={() =>
                    this.setState({
                      addNewCategory: false,
                    })
                  }
                >
                  <Icon
                    className="add-event-plus-icon"
                    name="ban"
                    size="small"
                  />
                </Button>
                <Button
                  className="add-event-form-button green-button"
                  onClick={this.addNewCategory}
                  disabled={this.state.newCategoryName.trim() == ""}
                >
                  <Icon
                    className="add-event-plus-icon"
                    name="add"
                    size="small"
                  />
                </Button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="add-event-form-row">
            <DateTimePicker
              emptyLabel={languagePack.starting_date}
              value={this.state.newEventStart}
              onChange={this.handleDateChangeStart}
              ampm={false}
              format="dd MMMM  HH:mm"
              name="newEventStart"
              className="add-event-date-input"
            />
            <Icon
              name="calendar alternate outline"
              className="calendar-icon"
              size="small"
            />
          </div>
          <div className="add-event-form-row">
            <DateTimePicker
              value={this.state.newEventEnd}
              onChange={this.handleDateChangeEnd}
              ampm={false}
              format="dd MMMM  HH:mm"
              name="newEventEnd"
              emptyLabel={languagePack.ending_date}
              className="add-event-date-input"
            />
            <Icon
              name="calendar alternate outline"
              className="calendar-icon"
              size="small"
            />
          </div>
        </MuiPickersUtilsProvider>

        <div className="add-event-form-row">
          <TextArea
            placeholder={languagePack["description"]}
            name="newEventDescription"
            value={this.state.newEventDescription}
            onChange={this.handleChangeInput}
            className="text-area-add-event"
          />
        </div>

        <div className="container-buttons-add-event">
          {/* <Button
            className="cancel-button"
            onClick={() => this.props.closePopup()}
          >
            {languagePack.Cancel}
          </Button> */}

          <Button className="add-button" onClick={this.handleSubmitEvent}
            disabled={this.state.newEventName.length == 0 || this.state.newEventTeam.length == 0 || this.state.newEventCategory.length == 0 || this.state.newEventStart == null || this.state.newEventEnd == null || this.state.addNewCategory}
          >
            <Icon name="add" className="plus-icon-button" />
            {languagePack.Add}
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  language: layoutService.lang,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(AddEvent);
