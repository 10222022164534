import React from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { Icon, Button } from "semantic-ui-react";
import "./TrainingSections.scss";
import { connect } from "react-redux";

function DeleteTrainingSection(props) {
  const { languagePack } = props;

  function deleteSection() {
    const payload = {
      id_section_to_delete: props.id_section_to_delete,
    };

    axios.post("training/delete_training_section", payload).then((resp) => {
      if (resp.data == 1) {
        store.addNotification({
          title: languagePack.training_sections,
          message: languagePack.section_deleted_successfully,
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
        props.getTrainingSections();
      } else {
        store.addNotification({
          title: languagePack.training_sections,
          message: languagePack.section_deleted_failed,
          type: "fail",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      }
      props.closePopup();
    });
  }

  return (
    <div className="delete-section-training-popup-container">
      <div className="delete-section-training-header">
        <div className="delete-section-training-title">
          {languagePack.delete_section}
        </div>
        <Icon
          name="close"
          className="close"
          size="large"
          onClick={() => props.closePopup()}
        />
      </div>
      <div className="delete-section-training-text">
        {languagePack.delete_section_text}
      </div>
      <div className="delete-section-training-buttons">
        <Button className="cancel-button" onClick={() => props.closePopup()}>
          {languagePack["admin_cancel"]}
          <Icon name="close" className="white-icon" />
        </Button>
        <Button className="delete-comp-button" onClick={() => deleteSection()}>
          {languagePack["admin_delete"]}
          <Icon name="trash alternate outline" className="white-icon" />
        </Button>
      </div>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(DeleteTrainingSection);
