import React from "react";
import "./SiteContact.scss";
import { connect } from "react-redux";
import axios from "axios";
import { Button, Icon } from "semantic-ui-react";
import { store } from "react-notifications-component";

class SiteContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactData: {},
    };
  }

  componentDidMount = () => {
    this.getContactInfo();
  };

  getContactInfo = () => {
    axios
      .get(`site/admin_contact_info?id_club=${this.props.currentUser.id_club}`)
      .then((res) => {
        this.setState({
          contactData: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

  editValue = (event) => {
    event.persist();
    this.setState(() => ({
      contactData: {
        ...this.state.contactData,
        [event.target.name]: event.target.value,
      },
    }));
  };

  saveChanges = () => {
    const payload = this.state.contactData;
    axios
      .post("site/save_contact_details", payload)
      .then(() => {
        this.getContactInfo();
        store.addNotification({
          title: this.props.languagePack["admin_contact_notif_title"],
          message: this.props.languagePack["admin_contact_notif_text_success"],
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="card card-custom">
        <div className="site-contact-container">
          <div className="site-contact-title">
            {languagePack["admin_contact_title"]}
          </div>
          <div className="site-contact-row">
            <div className="input-group">
              <div className="input-label">
                {languagePack["admin_contact_phone"]}
              </div>
              <input
                className="contact-info-input"
                value={this.state.contactData?.contact_phone ? this.state.contactData.contact_phone : ""}
                name="contact_phone"
                onChange={this.editValue}
              />
            </div>

            <div className="input-group">
              <div className="input-label">Email</div>
              <input
                className="contact-info-input"
                value={this.state.contactData?.contact_email ? this.state.contactData.contact_email : ""}
                name="contact_email"
                onChange={this.editValue}
              />
            </div>

            <div className="input-group">
              <div className="input-label">
                {languagePack["admin_contact_address"]}
              </div>
              <input
                className="contact-info-input"
                value={this.state.contactData?.contact_address ? this.state.contactData.contact_address : ""}
                name="contact_address"
                onChange={this.editValue}
              />
            </div>
          </div>

          <div className="site-contact-row">
            <div className="input-group">
              <div className="input-label">
                {languagePack["admin_contact_org_name"]}
              </div>
              <input
                className="contact-info-input"
                value={this.state.contactData?.non_profit_name ? this.state.contactData.non_profit_name : ""}
                name="non_profit_name"
                onChange={this.editValue}
              />
            </div>

            <div className="input-group">
              <div className="input-label">CUI</div>
              <input
                className="contact-info-input"
                value={this.state.contactData?.CUI ? this.state.contactData.CUI : ""}
                name="CUI"
                onChange={this.editValue}
              />
            </div>

            <div className="input-group">
              <div className="input-label">IBAN</div>
              <input
                className="contact-info-input"
                value={this.state.contactData?.IBAN ? this.state.contactData.IBAN : ""}
                name="IBAN"
                onChange={this.editValue}
              />
            </div>
          </div>
          <div className="site-contact-button-row">
            <Button
              className="site-contact-details-button"
              onClick={this.saveChanges}
            >
              {languagePack["admin_contact_save_changes"]}
              <Icon name="check" className="site-details-icon" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(SiteContact);
