import React from "react";
import axios from "axios";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { Icon, Dropdown, Button } from "semantic-ui-react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "../Club.styles.scss";
import "./PlayerProfile.scss";
import { connect } from "react-redux";
import moment from "moment";
import dateFormat from "dateformat";
import Select from "react-select";
import { countries } from "../../../utils/countries";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "react-notifications-component";
import EditNationalTeamCallUpWidget from "./EditNationalTeamCallUpWidget";

import { TableContainer } from "@material-ui/core";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import {
  sxStickyColumn,
  TFBTableCell,
} from "../../../components/reusable/useStyles";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";

class NationalTeamCallUpWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      player_nationa_team_call_up: [],
      opneAddDialog: false,

      national_team: "",
      action_date: null,
      competition: null,
      new_competition_name: "",
      age_group: "",
      action_type: "",
      action_name: "",
      own_goals: "",
      opponent_goals: "",
      played_minutes: "",
      scored_goals: "",
      assists: "",
      yellow_cards: "",
      red_cards: "",

      competitions: [],
      age_groups: [],
      action_types: [
        {
          label: "Meci",
          value: "0",
        },
        {
          label: "Antrenament",
          value: "1",
        },
      ],

      height: "initial",

      open_delete_dialog: false,
      deleting_elem: "",

      open_edit_dialog: false,
      editing_elem: "",
      new_competition_name_edit: "",
    };
  }

  componentDidMount = () => {
    this.getPlayerNationalTeamCallUp();
    this.getCompetitonsForNationalTeamCallUp();
    this.generateAgeGroups();
  };

  componentDidUpdate(prevProps) {
    // if (prevProps.id_season !== this.props.id_season) {
    //   this.getContractData();
    //   this.getBonuses();
    // }
  }

  getPlayerNationalTeamCallUp = () => {
    axios
      .get(
        `player/get_player_national_team_call_up?id_player=${this.props.id_player}`
      )
      .then((res) => {
        this.setState({
          player_nationa_team_call_up: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

  getCompetitonsForNationalTeamCallUp = () => {
    axios
      .get(
        `player/get_competitions_for_national_team_call_up?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => {
        this.setState({
          competitions: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

  generateAgeGroups = () => {
    let groups = [
      {
        label: "Echipa nationala",
        value: "1",
      },
    ];
    for (let i = 21; i >= 15; i--) {
      groups.push({
        label: "U" + i,
        value: i,
      });
    }
    this.setState({
      age_groups: groups,
    });
  };

  closeAddDialog = () => {
    this.setState({
      opneAddDialog: false,
      national_team: "",
      action_date: null,
      competition: null,
      age_group: "",
      action_type: "",
      action_name: "",
      own_goals: "",
      opponent_goals: "",
      played_minutes: "",
      scored_goals: "",
      assists: "",
      yellow_cards: "",
      red_cards: "",
      new_competition_name: "",
    });
  };

  closeEditDialog = () => {
    this.setState({
      open_edit_dialog: false,
      editing_elem: "",
    });
  };

  handleDateChange = (value) => {
    this.setState({ action_date: value });
  };

  addNationalTeamCallUp = () => {
    let payload = {
      payload_for_national_team_call_up: {
        id_club: this.props.currentUser.id_club,
        id_player: this.props.id_player,
        national_team: countries.find(
          (item) => item.value === this.state.national_team
        ).text,
        age_group: this.state.age_group.label,
        start_date: this.state.action_date,
        end_date: this.state.action_date,
      },
      payload_for_national_team_call_up_action: {
        action_date: this.state.action_date,
        action_type:
          this.state.action_type?.value == 1
            ? "antrenament"
            : this.state.action_name,
      },
    };

    let reload_competitions = 0;

    if (this.state.competition?.value == 0) {
      payload.payload_for_national_team_call_up.new_competition_name = this.state.new_competition_name;
      reload_competitions = 1;
    } else {
      payload.payload_for_national_team_call_up.id_competition = this.state.competition.value;
    }

    if (this.state.action_name != "") {
      payload.payload_for_national_team_call_up_action.own_goals =
        this.state.own_goals == "" ? null : this.state.own_goals;
      payload.payload_for_national_team_call_up_action.opponent_goals =
        this.state.opponent_goals == "" ? null : this.state.opponent_goals;
      payload.payload_for_national_team_call_up_action.played_minutes =
        this.state.played_minutes == "" ? null : this.state.played_minutes;
      payload.payload_for_national_team_call_up_action.scored_goals =
        this.state.scored_goals == "" ? null : this.state.scored_goals;
      payload.payload_for_national_team_call_up_action.assists =
        this.state.assists == "" ? null : this.state.assists;
      payload.payload_for_national_team_call_up_action.yellow_cards =
        this.state.yellow_cards == "" ? null : this.state.yellow_cards;
      payload.payload_for_national_team_call_up_action.red_cards =
        this.state.red_cards == "" ? null : this.state.red_cards;
    }

    axios
      .post(`player/add_national_team_call_up`, payload)
      .then((res) => {
        if (res.data == 1) {
          if (reload_competitions == 1) {
            this.getCompetitonsForNationalTeamCallUp();
          }
          this.getPlayerNationalTeamCallUp();
          this.closeAddDialog();
          store.addNotification({
            title: "Jucator",
            message: "Convocarea la nationala a fost adaugata cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          this.closeAddDialog();
          store.addNotification({
            title: "Jucator",
            message:
              "S-a produs o eroare! Convocarea la nationala nu a fost adaugata cu succes!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  deleteNationalTeamCallUp = () => {
    axios
      .post(`player/delete_national_team_call_up`, {
        id_national_team_call_up_action: this.state.deleting_elem
          .id_national_team_call_up_action,
      })
      .then((res) => {
        if (res.data == 1) {
          this.getPlayerNationalTeamCallUp();
          this.setState({
            open_delete_dialog: false,
            deleting_elem: "",
          });
          store.addNotification({
            title: "Jucator",
            message: "Convocarea la nationala a fost stearsa cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          this.setState({
            open_delete_dialog: false,
          });
          store.addNotification({
            title: "Jucator",
            message:
              "S-a produs o eroare! Convocarea la nationala nu a fost stearsa cu succes!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    const customStylesSelect = {
      container: (provided, state) => ({
        ...provided,
        backgroundColor: "#fafafa",
        width: "100%",
      }),
      control: (provided, state) => ({
        ...provided,
        backgroundColor: "#fafafa",
        height: "50px",
        border: "none",
        "&:hover": {
          border: "none",
          boxShadow: "0 0 0 2px black",
          // border: "2px black"
        },
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        paddingLeft: "22px",
        fontSize: "14px",
        fontWeight: 700,
      }),
      placeholder: (provided, state) => ({
        ...provided,
        color: "#666666",
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
      }),
      menu: (provided, state) => ({
        ...provided,
        maxHeight: "350px",
      }),
      menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
    };

    return (
      <TFBCard>
        <TFBCardHeader title={languagePack.national_team_calls}>
          <TFBButton
            color="green"
            onClick={() => this.setState({ opneAddDialog: true })}
            renderIcon={() => <TFBIcon name="add" />}
          >
            {languagePack.add_national_team_call_up}
          </TFBButton>
        </TFBCardHeader>
        <TFBCardBody>
          {this.state.player_nationa_team_call_up.length > 0 ? (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn}>
                      {languagePack.injury_date}
                    </TFBTableCell>
                    <TFBTableCell>{languagePack.national_team}</TFBTableCell>
                    <TFBTableCell>{languagePack.Competition}</TFBTableCell>
                    <TFBTableCell>{languagePack.age_group}</TFBTableCell>
                    <TFBTableCell>{languagePack.activity_type}</TFBTableCell>
                    <TFBTableCell>{languagePack.score}</TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.minutes_played}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.goals_scored_pl}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.key_pass}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.yellow_cards_pl}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.red_cards_pl}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.order_actions}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.player_nationa_team_call_up.map((elem, index) => (
                    <TableRow
                      key={"national-team-call-up-row-" + index}
                      sx={{
                        backgroundColor:
                          index % 2 == 0
                            ? "var(--lineBackgroundGray)"
                            : "white",
                      }}
                    >
                      <TFBTableCell
                        component="th"
                        scope="row"
                        sx={[
                          sxStickyColumn,
                          {
                            backgroundColor:
                              index % 2 == 0
                                ? "var(--lineBackgroundGray)"
                                : "white",
                          },
                        ]}
                      >
                        {moment(elem["action_date"]).format("DD.MM.YYYY")}
                      </TFBTableCell>
                      <TFBTableCell>{elem["national_team"]}</TFBTableCell>
                      <TFBTableCell>{elem["competition_name"]}</TFBTableCell>
                      <TFBTableCell>{elem["age_group"]}</TFBTableCell>
                      <TFBTableCell>{elem["action_type"]}</TFBTableCell>
                      <TFBTableCell>{elem["score"]}</TFBTableCell>
                      <TFBTableCell align="center">
                        {elem["played_minutes"]}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {elem["scored_goals"]}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {elem["assists"]}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {elem["yellow_cards"]}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {elem["red_cards"]}
                      </TFBTableCell>
                      <TFBTableCell>
                        <div className="actions-btns-container">
                          <TFBIconButton
                            name="edit"
                            color="darkGray"
                            onClick={() =>
                              this.setState({
                                open_edit_dialog: true,
                                editing_elem: elem,
                              })
                            }
                          />
                          <TFBIconButton
                            name="delete"
                            color="darkGray"
                            onClick={() =>
                              this.setState({
                                open_delete_dialog: true,
                                deleting_elem: elem,
                              })
                            }
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TFBPlaceholder text={languagePack.no_national_team_call_up} />
          )}
        </TFBCardBody>

        {/* ADAUGARE CONVOCARE LA NATIONALA */}
        <Dialog open={this.state.opneAddDialog} maxWidth="xl">
          <DialogContent style={{ height: this.state.height }}>
            <div className="player-transaction-popup-container add-national-team-call-up">
              <div className="player-transaction-header">
                <div className="player-transaction-title">
                  {languagePack.add_national_team_call_up_popup}
                </div>
                <Icon
                  name="close"
                  size="large"
                  onClick={this.closeAddDialog}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <div className="add-event-form-container">
              <div className="add-event-form-container">
                <Dropdown
                  placeholder={languagePack.select_national_team}
                  options={countries}
                  value={this.state.national_team}
                  fluid
                  search
                  selection
                  className="national-team-dropdown"
                  onChange={(e, selected) => {
                    this.setState({ national_team: selected.value });
                  }}
                />
              </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="add-event-form-row">
                  <DatePicker
                    emptyLabel={languagePack.call_up_date}
                    value={this.state.action_date}
                    onChange={this.handleDateChange}
                    ampm={false}
                    format="dd.MM.yyyy"
                    name="date-picker"
                    className="add-event-date-input"
                  />
                  <Icon
                    name="calendar alternate outline"
                    className="calendar-icon"
                    size="small"
                  />
                </div>
              </MuiPickersUtilsProvider>
              <div className="add-event-form-row">
                <Select
                  placeholder={languagePack.select_competition}
                  options={this.state.competitions}
                  value={this.state.competition}
                  onChange={(selected) => {
                    this.setState({
                      competition: selected,
                    });
                    if (this.state.competition?.value != selected.value) {
                      this.setState({
                        new_competition_name: "",
                      });
                    }
                  }}
                  styles={customStylesSelect}
                  menuPortalTarget={document.body}
                />
              </div>
              {this.state.competition?.value == 0 && (
                <div className="add-event-form-row">
                  <input
                    type="text"
                    value={this.state.new_competition_name}
                    onChange={(e) =>
                      this.setState({ new_competition_name: e.target.value })
                    }
                    placeholder={"Nume competitie noua"}
                    className="add-event-input"
                  />
                </div>
              )}
              <div className="add-event-form-row">
                <Select
                  placeholder={languagePack.select_age_group}
                  options={this.state.age_groups}
                  value={this.state.age_group}
                  onChange={(selected) => {
                    this.setState({
                      age_group: selected,
                    });
                  }}
                  styles={customStylesSelect}
                  menuPortalTarget={document.body}
                />
              </div>
              <div className="add-event-form-row">
                <Select
                  placeholder={languagePack.select_type_of_activity}
                  options={this.state.action_types}
                  value={this.state.action_type}
                  onChange={(selected) => {
                    this.setState({
                      action_type: selected,
                    });
                    if (this.state.action_type?.value != selected.value) {
                      this.setState({
                        action_name: "",
                        own_goals: "",
                        opponent_goals: "",
                        played_minutes: "",
                        scored_goals: "",
                        assists: "",
                        yellow_cards: "",
                        red_cards: "",
                      });
                    }
                  }}
                  styles={customStylesSelect}
                  menuPortalTarget={document.body}
                />
              </div>
              {this.state.action_type?.value == 0 && (
                <>
                  <div className="add-event-form-row">
                    <input
                      type="text"
                      value={this.state.action_name}
                      onChange={(e) =>
                        this.setState({ action_name: e.target.value })
                      }
                      placeholder={"Nume echipa adversa"}
                      className="add-event-input"
                    />
                  </div>
                  <div className="add-event-form-row">
                    <input
                      type="text"
                      value={this.state.own_goals}
                      onChange={(e) =>
                        this.setState({ own_goals: e.target.value })
                      }
                      placeholder={"Numar goluri echipa jucatorului"}
                      className="add-event-input"
                    />
                  </div>
                  <div className="add-event-form-row">
                    <input
                      type="text"
                      value={this.state.opponent_goals}
                      onChange={(e) =>
                        this.setState({ opponent_goals: e.target.value })
                      }
                      placeholder={"Numar goluri echipa adversa"}
                      className="add-event-input"
                    />
                  </div>
                  <div className="add-event-form-row">
                    <input
                      type="text"
                      value={this.state.played_minutes}
                      onChange={(e) =>
                        this.setState({ played_minutes: e.target.value })
                      }
                      placeholder={"Minute jucate"}
                      className="add-event-input"
                    />
                  </div>
                  <div className="add-event-form-row">
                    <input
                      type="text"
                      value={this.state.scored_goals}
                      onChange={(e) =>
                        this.setState({ scored_goals: e.target.value })
                      }
                      placeholder={"Goluri marcate"}
                      className="add-event-input"
                    />
                  </div>
                  <div className="add-event-form-row">
                    <input
                      type="text"
                      value={this.state.assists}
                      onChange={(e) =>
                        this.setState({ assists: e.target.value })
                      }
                      placeholder={"Pase decisive"}
                      className="add-event-input"
                    />
                  </div>
                  <div className="add-event-form-row">
                    <input
                      type="text"
                      value={this.state.yellow_cards}
                      onChange={(e) =>
                        this.setState({ yellow_cards: e.target.value })
                      }
                      placeholder={"Cartonase galbene"}
                      className="add-event-input"
                    />
                  </div>
                  <div className="add-event-form-row">
                    <input
                      type="text"
                      value={this.state.red_cards}
                      onChange={(e) =>
                        this.setState({ red_cards: e.target.value })
                      }
                      placeholder={"Cartonase rosii"}
                      className="add-event-input"
                    />
                  </div>
                </>
              )}
              <div className="container-buttons-add-event">
                <Button
                  className="cancel-button"
                  onClick={() => this.closeAddDialog()}
                >
                  <Icon name="close" className="plus-icon-button" />
                  {languagePack.Cancel}
                </Button>

                <Button
                  className="add-button"
                  onClick={this.addNationalTeamCallUp}
                  disabled={
                    this.state.national_team == "" ||
                    this.state.action_date == null ||
                    this.state.competition == null ||
                    this.state.age_group == "" ||
                    this.state.action_type == "" ||
                    (this.state.action_type?.value == 0 &&
                      this.state.action_name == "")
                    // ||
                    // this.state.own_goals == "" ||
                    // this.state.opponent_goals == "" ||
                    // this.state.played_minutes == "" ||
                    // this.state.scored_goals == "" ||
                    // this.state.assists == "" ||
                    // this.state.yellow_cards == "" ||
                    // this.state.red_cards == ""
                  }
                >
                  <Icon name="add" className="plus-icon-button" />
                  {languagePack.Add}
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/* MODIFICARE CONVOCARE LA NATIONALA */}
        <Dialog open={this.state.open_edit_dialog} maxWidth="xl">
          <DialogContent style={{ height: this.state.height }}>
            <EditNationalTeamCallUpWidget
              editing_elem={this.state.editing_elem}
              closeEditDialog={this.closeEditDialog}
              competitions={this.state.competitions}
              setHeight={(height) => this.setState({ height: height })}
              age_groups={this.state.age_groups}
              action_types={this.state.action_types}
              getCompetitonsForNationalTeamCallUp={
                this.getCompetitonsForNationalTeamCallUp
              }
              getPlayerNationalTeamCallUp={this.getPlayerNationalTeamCallUp}
            />
          </DialogContent>
        </Dialog>

        {/* STERGERE CONVOCARE NATIONALA*/}
        <Dialog open={this.state.open_delete_dialog}>
          <div className="bonus-options-list-popup-header">
            <div className="bonus-options-list-title">
              {languagePack.delete_national_call_up}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={() =>
                  this.setState({
                    open_delete_dialog: false,
                    deleting_elem: "",
                  })
                }
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <DialogContent>
            <div className="delete-bonus-query">
              {languagePack.delete_national_call_up_message}
              <span
                style={{
                  color: "var(--primaryColor)",
                  textTransform: "uppercase",
                }}
              >
                {moment(this.state.deleting_elem.action_date).format(
                  "DD.MM.YYYY"
                )}
              </span>
              {languagePack.for_competition}
              <span
                style={{
                  color: "var(--primaryColor)",
                  textTransform: "uppercase",
                }}
              >
                {this.state.deleting_elem.competition_name}
                {` - ${this.state.deleting_elem.action_type}`}
              </span>
              {"?"}
            </div>
            <div className="delete-bonus-buttons">
              <Button
                className="delete-bonus-button grey-btn"
                onClick={() =>
                  this.setState({
                    open_delete_dialog: false,
                    deleting_elem: "",
                  })
                }
              >
                <div className="button-content">
                  {languagePack["admin_cancel"]}
                </div>
                <Icon name="ban" className="white-icon" />
              </Button>
              <Button
                className="delete-bonus-button"
                color="red"
                onClick={this.deleteNationalTeamCallUp}
              >
                <div className="button-content">{languagePack.yes_delete}</div>
                <Icon name="trash" className="white-icon" />
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </TFBCard>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(NationalTeamCallUpWidget);
