import React from "react";
import axios from "axios";
import { Button, Icon } from "semantic-ui-react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { store } from "react-notifications-component";

class EditTransactionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction_value: this.props.initialValue,
      transaction_date: this.props.initialDate,
      transaction_description: this.props.initialDescription,
    };
  }

  saveEditChanges = () => {
    const id_transaction = this.props.id_transaction;

    const newTransactionData = {
      id: id_transaction,
      value: this.state.transaction_value,
      description: this.state.transaction_description,
      date: this.state.transaction_date,
    };

    axios
      .post("finances/edit_transaction", newTransactionData)
      .then(() => {
        this.props.closeDialog();
        this.props.refreshData();
        store.addNotification({
          title: "Operatiuni partener",
          message: "Modificarile au fost salvate cu succes",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  handleDateChange = (value) => {
    this.setState({ transaction_date: value });
  };

  deleteTransaction = () => {
    const transaction = {
      id: this.props.id_transaction,
    };
    axios
      .post("finances/delete_transaction", transaction)
      .then(() => {
        this.props.closeDialog();
        this.props.refreshData();
        store.addNotification({
          title: "Operatiuni partener",
          message: "Tranzactia a fost eliminata cu succes",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div className="edit-transaction-container">
        <div className="edit-transaction-row">
          <input
            type="text"
            className="edit-partner-input"
            value={this.state.transaction_value}
            onChange={(e) =>
              this.setState({ transaction_value: e.target.value })
            }
            placeholder="Valoare"
          />
        </div>
        <div className="edit-transaction-row">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              value={this.state.transaction_date}
              onChange={this.handleDateChange}
              format="dd MMMM yyyy"
              name="transaction_date"
              inputVariant="outlined"
              className="transaction-date-input"
              emptyLabel="Data efectuarii"
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="edit-transaction-row">
          <input
            type="text"
            className="edit-partner-input"
            value={this.state.transaction_description}
            onChange={(e) =>
              this.setState({ transaction_description: e.target.value })
            }
            placeholder="Descriere"
          />
        </div>

        <div
          className="operation-form-row buttons-container-operations"
          style={{ justifyContent: "space-between", display: "flex" }}
        >
          <Button
            className="operation-cancel-button"
            onClick={this.deleteTransaction}
          >
            {" "}
            <div className="button-content">
              Sterge
              <Icon name="x" className="op-cancel-icon" />
            </div>
          </Button>
          <Button
            className="operation-save-button"
            onClick={this.saveEditChanges}
          >
            {" "}
            <div className="button-content">
              Salveaza
              <Icon name="add" className="add-icon" />
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default EditTransactionDetails;
