import React from "react";
import axios from "axios";
import { logout } from "./../../../utils/api";
import "./NecessityReport.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon, Flag, Button, Dropdown } from "semantic-ui-react";
import moment from "moment";
import AddNecessityReport from "./AddNecessityReport.jsx";
import { Dialog, DialogContent } from "@material-ui/core";
import * as myConstants from "./../../../utils/constants";
import { store } from "react-notifications-component";
import UpdateNecessityReport from "./UpdateNecessityReport";
import permissions from "./../../../../app/utils/permissions";

const statusList = {
  approved: {
    text: "Aprobat",
    color: "var(--primaryColor)",
  },
  partial_approved: {
    text: "Partial aprobat",
    color: "#00aaff",
  },
  waiting: {
    text: "În așteptare",
    color: "#eedb00",
  },
  declined: {
    text: "Respins",
    color: "#d91200",
  },
};

class NecessityReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      last_registration_number: 1,
      open_add_report_popup: false,

      report_to_delete: "",
      open_delete_popup: false,

      report_to_edit: "",
      open_edit_report_popup: false,

      report_to_approve: "",
      open_approve_report_popup: false,
      approve_reason: "",
    };
  }

  componentDidMount = () => {
    this.getReports();
  };

  getReports = () => {
    axios
      .get(
        `finances/get_necessary_reports_new?id_club=${this.props.currentUser.id_club}&id_user=${this.props.currentUser.id_user}&token=${this.props.token}`
      )
      .then((res) => {
        if (res.data.success == 1) {
          if (res.data.list.length > 0) {
            //find last register number
            let registrationNumbers = [];
            res.data.list.forEach((elem) => {
              registrationNumbers.push(Number(elem.registration_number));
            });
            const lastRegistrationNumber = Math.max(...registrationNumbers);
            this.setState({
              reports: res.data.list,
              last_registration_number: lastRegistrationNumber,
            });
          }
        }
      })
      .catch((err) => console.log(err));
  };

  deleteReport = () => {
    const payload = {
      id_necessity_report: this.state.report_to_delete,
    };

    axios.post("finances/delete_necessary_report", payload).then((res) => {
      if (res.data == 1) {
        store.addNotification({
          title: "Necessity report",
          message: "Referatul de necesitate a fost sters cu suscces!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
        this.getReports();
      } else {
        store.addNotification({
          title: "Necessity report",
          message: "Referatul de necesitate nu a fost sters cu suscces!",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      }
      this.closeDeletePopup();
    });
  };

  approveReport = () => {
    const payload = {
      id_necessity_report: this.state.report_to_approve.id_necessity_report,
      status_justification: this.state.approve_reason,
      id_user: this.props.currentUser.id_user,
    };

    axios.post("finances/approve_necessary_report", payload).then((res) => {
      if (res.status == 200) {
        store.addNotification({
          title: "Necessity report",
          message: "Referatul de necesitate a fost aprobat cu suscces!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
        this.getReports();
      } else {
        store.addNotification({
          title: "Necessity report",
          message: "Referatul de necesitate nu a fost aprobat cu suscces!",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      }
      this.closeApprovePopup();
    });
  };

  disapproveReport = () => {
    const payload = {
      id_necessity_report: this.state.report_to_approve.id_necessity_report,
      status_justification: this.state.approve_reason,
      id_user: this.props.currentUser.id_user,
    };

    axios.post("finances/disapprove_necessary_report", payload).then((res) => {
      if (res.status == 200) {
        store.addNotification({
          title: "Necessity report",
          message: "Referatul de necesitate a fost respins cu suscces!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
        this.getReports();
      } else {
        store.addNotification({
          title: "Necessity report",
          message: "Referatul de necesitate nu a fost respins cu suscces!",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      }
      this.closeApprovePopup();
    });
  };

  closeDeletePopup = () => {
    this.setState({
      open_delete_popup: false,
      report_to_delete: "",
    });
  };

  closeEditPopup = () => {
    this.setState({
      open_edit_report_popup: false,
      report_to_edit: "",
    });
  };

  closeApprovePopup = () => {
    this.setState({
      open_approve_report_popup: false,
      report_to_approve: "",
      approve_reason: "",
    });
  };

  render() {
    const { languagePack } = this.props;

    return (
      <div className="card card-custom">
        <div className="necessity-reports-list-container">
          <div className="necessity-reports-header">
            <div className="necessity-reports-title">
              {languagePack.necessity_reports}
            </div>
            <div className="header-buttons">
              <Button
                className="necessity-reports-add-button"
                onClick={() => this.setState({ open_add_report_popup: true })}
              >
                {languagePack.add_necessity_report}
                <Icon
                  name="add"
                  className="add-necessity-reports-button-icon"
                />
              </Button>
            </div>
          </div>
          {this.state.reports.length > 0 ? (
            <div className="necessity-reports-table">
              <div className="necessity-reports-table-header">
                <div className="number-order">
                  {languagePack.criteria_number}
                </div>
                <div className="created-by">{languagePack.made_by}</div>
                <div className="created-by">
                  {languagePack.registration_number}
                </div>
                <div className="create-date">{languagePack.injury_date}</div>
                <div className="category">{languagePack.purchase_category}</div>
                <div className="amount">{languagePack.trasnaction_amount}</div>
                <div className="status">{languagePack.product_status}</div>
                <div className="options">
                  {languagePack.news_page_header_options}
                </div>
              </div>
              <div className="necessity-reports-table-content">
                {this.state.reports.map((item, index) => {
                  return (
                    <div
                      className="necessity-reports-row-content"
                      key={"necessity-reports-row-" + index}
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      <div className="number-order col1">
                        <div className="player-name-text">{index + 1}</div>
                      </div>
                      <div className="created-by col1">{item["added_by"]}</div>
                      <div className="created-by col1">
                        {item["registration_number"]}
                      </div>
                      <div className="create-date col1">
                        {moment(item["registration_date"]).format("DD.MM.yyyy")}
                      </div>
                      <div className="category col1">{item["category"]}</div>
                      <div className="amount col1">
                        {item["total_value"] + " RON"}
                      </div>
                      <div
                        className="status col1"
                        style={{ color: statusList[item["status"]].color }}
                      >
                        {statusList[item["status"]].text +
                          (item["status"] == "partial_approved" ||
                          item["status"] == "approved"
                            ? " " + item["nr_of_approvals"] + "/2"
                            : "")}
                      </div>
                      <div className="options options-button col1">
                        {this.props.currentUser.user_permissions[
                          permissions.APPROVE_NECESSITY_REPORT
                        ] && (
                          <div
                            className="necessity-report-edit"
                            onClick={() =>
                              this.setState({
                                report_to_approve: item,
                                open_approve_report_popup: true,
                              })
                            }
                          >
                            <Icon
                              name="check circle outline"
                              className="approve-icon"
                              size="large"
                            />
                          </div>
                        )}
                        <a
                          href={`https://api.thefootballbrain.app/finances/download_necessary_report?id_report=${item.id_necessity_report}&token=${this.props.token}`}
                        >
                          <div className="necessity-report-download">
                            <Icon name="download" className="download-icon" />
                          </div>
                        </a>
                        {item["status"] != "approved" && (
                          <div
                            className="necessity-report-edit"
                            onClick={() =>
                              this.setState({
                                report_to_edit: item["id_necessity_report"],
                                open_edit_report_popup: true,
                              })
                            }
                          >
                            <Icon name="edit" className="edit-icon" />
                          </div>
                        )}
                        <div
                          className="necessity-report-delete trash-hover"
                          onClick={() =>
                            this.setState({
                              report_to_delete: item["id_necessity_report"],
                              open_delete_popup: true,
                            })
                          }
                        >
                          <Icon
                            name="trash alternate"
                            className="delete-icon"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="no-necessity-reports-message">
              <Icon name="search minus" color="black" size="big" />
              <div className="no-necessity-reports-message-text">
                {"Nu sunt referate de necesitate."}
              </div>
            </div>
          )}
        </div>
        {/* ADAUGARE RAPORT DE NECESITATE */}
        <Dialog open={this.state.open_add_report_popup}>
          <div
            className="bonus-options-list-popup-header"
            style={{ paddingLeft: 50 }}
          >
            <div className="bonus-options-list-title">
              {languagePack.add_the_necessity_report}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={() =>
                  this.setState({
                    open_add_report_popup: false,
                  })
                }
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <DialogContent className="add-file-container-all">
            <AddNecessityReport
              lastRegistrationNumber={this.state.last_registration_number + 1}
              refreshReports={this.getReports}
              closePopup={() =>
                this.setState({
                  open_add_report_popup: false,
                })
              }
            />
          </DialogContent>
        </Dialog>

        {/* DELETE REPORT */}
        <Dialog open={this.state.open_delete_popup} maxWidth="xl">
          <DialogContent>
            <div className="delete-competition-popup-container">
              <div className="delete-competition-header">
                <div className="delete-competition-title">
                  {languagePack.delete_necessity_report}
                </div>
                <Icon
                  name="close"
                  className="close"
                  size="large"
                  onClick={this.closeDeletePopup}
                />
              </div>
              <div className="delete-competition-text">
                {languagePack.delete_necessity_report_text}
              </div>
              <div className="delete-competition-buttons">
                <Button
                  className="cancel-user-mobile-button"
                  onClick={this.closeDeletePopup}
                >
                  {languagePack["admin_cancel"]}
                  <Icon name="close" className="white-icon" />
                </Button>
                <Button
                  className="delete-user-mobile-button"
                  onClick={this.deleteReport}
                >
                  {languagePack["admin_delete"]}
                  <Icon name="trash alternate outline" className="white-icon" />
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/* EDITARE RAPORT DE NECESITATE */}
        <Dialog open={this.state.open_edit_report_popup}>
          <div
            className="bonus-options-list-popup-header"
            style={{ paddingLeft: 50 }}
          >
            <div className="bonus-options-list-title">
              {languagePack.edit_necessity_report}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={this.closeEditPopup}
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <DialogContent className="add-file-container-all">
            <UpdateNecessityReport
              refreshReports={this.getReports}
              closePopup={this.closeEditPopup}
              idReport={this.state.report_to_edit}
            />
          </DialogContent>
        </Dialog>

        {/* APROBARE RAPORT DE NECESITATE */}
        <Dialog open={this.state.open_approve_report_popup} maxWidth="xl">
          <DialogContent>
            <div className="delete-competition-popup-container">
              <div className="delete-competition-header gap-40">
                <div className="delete-competition-title">
                  {languagePack.approval_disapproval_of_necessity_report}
                </div>
                <Icon
                  name="close"
                  className="close"
                  size="big"
                  onClick={this.closeApprovePopup}
                />
              </div>
              <div className="approve-necessity-report-container">
                <div className="approve-necessity-report-row">
                  <label className="label-approve-reason">
                    {languagePack.necessity_report_reason}
                    <span style={{ color: "red", fontSize: "11px" }}>
                      {languagePack.in_case_of_disapproval_is_mandatory_field}
                    </span>
                  </label>
                  <textarea
                    id="approve_reason"
                    name="approve_reason"
                    className="add-necessity-report-form-input"
                    rows="3"
                    placeholder="Introdu motivul..."
                    value={this.state.approve_reason}
                    onChange={(e) =>
                      this.setState({
                        approve_reason: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="delete-competition-buttons">
                <Button
                  className="delete-user-mobile-button"
                  onClick={this.disapproveReport}
                  disabled={this.state.approve_reason.trim() == ""}
                >
                  {"Respinge"}
                  <Icon
                    name="window close outline"
                    className="white-icon"
                    size="large"
                  />
                </Button>
                <Button
                  className="add-file-button"
                  onClick={this.approveReport}
                >
                  {"Aproba"}
                  <Icon
                    name="check circle outline"
                    className="white-icon"
                    size="large"
                  />
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyItemSelected:
      myConstants.currencies[auth?.user?.currency] ||
      myConstants.currencies.EUR,
  }),
  mapDispatchToProps
)(NecessityReport);
