import React from "react";
import { connect } from "react-redux";
import "./PresenceList.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon.tsx";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../components/reusable/useStyles";
import { TableContainer } from "@material-ui/core";

class PresenceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { languagePack } = this.props;
    return (
      <>
        <TFBCardHeader title={languagePack.confirmed_presence}></TFBCardHeader>

        <TFBCardBody>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TFBTableCell sx={sxStickyColumn}>
                    {languagePack.player_name}
                  </TFBTableCell>
                  <TFBTableCell>{languagePack.presence}</TFBTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.presenceList.map((row, index) => (
                  <TableRow
                    key={"player-will-atempt-" + index}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      backgroundColor:
                        row.will_attend == 1
                          ? "rgba(173, 255, 179, 0.8)"
                          : index % 2 == 0 ? "#fafafa" : "white",
                    }}
                  >
                    <TFBTableCell
                      component="th"
                      scope="row"
                      sx={sxStickyColumn}
                      style={{
                        backgroundColor:
                          row.will_attend == 1
                            ? "rgba(173, 255, 179, 0.8)"
                            : index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      {row.player_name}
                    </TFBTableCell>

                    <TFBTableCell align="left" width={"50%"}>
                      <div className="plan-training-presence-list-wrapper">
                        {row.will_attend == "1" ? (
                          <TFBIcon
                            name="check"
                            color="green"
                            className="plan-training-presence-list-icon"
                          />
                        ) : (
                          <TFBIcon
                            name="x-small"
                            color="black"
                            className="plan-training-presence-list-icon"
                          />
                        )}
                      </div>
                    </TFBTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TFBCardBody>
      </>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(PresenceList);
