import React, { ReactElement } from "react";
import { ButtonProps, IconButton } from "../types";
import styles from "./TFBIconButton.module.scss";

const TFBIconButton = ({
  disabled,
  color = "darkGray",
  size = "sm",
  onClick,
  className,
  classNameIcon,
  name,
  style,
  badge = undefined,
  id = ""
}: IconButton) => {
  const cssColor = color;
  const cssSize = size;
  const iconPath = require(`../../../assets/icons/design-system/${name}.svg`);
  return (
    <button
      className={`${styles.tfbIconButton} ${className ? className : ""} ${styles[cssColor]
        } ${styles[cssSize]} ${disabled ? styles.disabledBtn : ""}`}
      onClick={onClick}
      disabled={disabled}
      style={style}
      id={id}
    >
      <img
        src={iconPath}
        className={`${styles.tfbbtnicon} ${classNameIcon ? classNameIcon : ""}`}
        alt={`${name} icon`}
        style={badge ? { position: "absolute" } : undefined}
      />
      {badge && (
        <div className={styles.badgeContainer}>
          <div className={styles.badgeCircle}>
            <div className={styles.badgeNumber}>{badge}</div>
          </div>
        </div>
      )}
    </button>
  );
};

export default TFBIconButton;
