import React from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import CircularLoader from "../../../../components/reusable/Loader";
import { Icon, Button, TextArea } from "semantic-ui-react";
import NumberFormat from "react-number-format";
import { logout } from "../../../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as constants from "../../../../utils/constants";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { store } from "react-notifications-component";
import "./FRFLists.scss";
import { DialogContent } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ListComponent from "./ListComponent";
import AddPlayerToList from "./AddPlayerToList";

class FRFLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addPlayerToListPopup: false,
      activeItem: "A",
      update_list: 0,
      height: "initial",
    };
  }

  componentDidMount = () => {};

  generateList = () => {
    const url = `https://api.thefootballbrain.app/club/generate_frf_list?frf_list=${this.state.activeItem}&id_club=${this.props.currentUser.id_club}`;
    window.location.assign(url);
  };

  render() {
    const { languagePack } = this.props;

    return (
      <div className="card card-custom" style={{ padding: 50 }}>
        <div className="matches-header">
          <div className="matches-section-title">
            {/* {languagePack["fixtures_schedule"]} */}
            {"Liste FRF"}
          </div>
          <div className="frf-list-header-buttons">
            {this.state.activeItem == "A" && (
              <Button
                className="download-list-button"
                onClick={this.generateList}
              >
                {/* {languagePack["add_match"]} */}
                {"Descarcare lista A"}
                <Icon name="download" className="white-icon" />
              </Button>
            )}
            {this.state.activeItem == "B" && (
              <Button
                className="download-list-button"
                onClick={this.generateList}
              >
                {/* {languagePack["add_match"]} */}
                {"Descarcare lista B"}
                <Icon name="download" className="white-icon" />
              </Button>
            )}
            <Button
              className="add-match-button"
              onClick={() => this.setState({ addPlayerToListPopup: true })}
            >
              {/* {languagePack["add_match"]} */}
              {"Adauga jucator in lista"}
              <Icon name="add" className="white-icon" />
            </Button>
          </div>
        </div>
        <div className="matches-menu-options">
          <div
            className="matches-option"
            onClick={() => this.setState({ activeItem: "A" })}
            style={{
              backgroundColor: this.state.activeItem === "A" ? "#fafafa" : "",
            }}
          >
            {/* <div>{languagePack["past_matches"]}</div> */}
            <div>{"Lista A"}</div>
          </div>
          <div
            className="matches-option"
            onClick={() => this.setState({ activeItem: "B" })}
            style={{
              backgroundColor: this.state.activeItem === "B" ? "#fafafa" : "",
            }}
          >
            {/* <div>{languagePack["future_matches"]}</div> */}
            <div>{"Lista B"}</div>
          </div>
        </div>
        {this.state.activeItem === "A" ? (
          <ListComponent type={"A"} refreshList={this.state.update_list} />
        ) : (
          <ListComponent type={"B"} refreshList={this.state.update_list} />
        )}
        <Dialog
          open={this.state.addPlayerToListPopup}
          // onClose={() => this.setState({ addPlayerToListPopup: false })}
        >
          <DialogContent
            className="add-team-manual-wrapper"
            style={{ height: this.state.height }}
          >
            <AddPlayerToList
              closePopup={() => this.setState({ addPlayerToListPopup: false })}
              refreshList={() =>
                this.setState({ update_list: this.state.update_list + 1 })
              }
              setHeight={(value) => {
                this.setState({
                  height: value,
                });
              }}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
};
const mapStateToProps = ({ layoutService: { languagePack }, auth }) => ({
  languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
});
export default connect(mapStateToProps, mapDispatchToProps)(FRFLists);
