import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./MatchDetails.scss";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import TFBCard from "../../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";

const generalEvaluationOptions = [
  {
    value: "1",
    label: "Foarte bine",
  },
  {
    value: "2",
    label: "Bine",
  },
  {
    value: "3",
    label: "Mediu",
  },
  {
    value: "4",
    label: "Slab",
  },
  {
    value: "5",
    label: "Foarte slab",
  },
];

function MatchReportInfo(props) {
  const {
    languagePack,
    // token,
    matchDetails,
  } = props;

  const [isLoad, setIsLoad] = useState(false);

  const [weatherCondition, setWeatherCondition] = useState(
    matchDetails.weather_condition
  );
  const [pitchCondition, setPitchCondition] = useState(
    matchDetails.pitch_condition
  );
  const [textEditor, setTextEditor] = useState(
    matchDetails.match_description ?? ""
  );
  const [generalEvaluation, setGeneralEvaluation] = useState(
    generalEvaluationOptions.find(
      (option) => option.value == matchDetails.match_rating
    )
  );

  const idParam = useParams();

  useEffect(() => {
    setIsLoad(true);
  }, []);

  const saveGeneratorDetails = () => {
    let payload = {
      general_match_evaluation: generalEvaluation.value ?? null,
      weather_condition: weatherCondition,
      pitch_condition: pitchCondition,
      match_description: textEditor,
      id_match: idParam.id,
    };

    axios
      .post(`match/save_additional_details`, payload)
      .then((res) => {
        if (res.data == 1) {
          store.addNotification({
            title: languagePack.match_sg,
            message: languagePack.manage_competitions_success_changes,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: languagePack.match_sg,
            message: languagePack.manage_competitions_failed_changes,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    isLoad && (
      <TFBCard style={{ marginTop: "25px" }}>
        <TFBCardHeader
          title={languagePack.additional_info_generate_raport}
        ></TFBCardHeader>

        <TFBCardBody>
          <div className="aditional-inputs-report-generator">
            <div className="inputs-row">
              <TFBInput
                placeholder={languagePack.weather_condition}
                label={languagePack.weather_condition}
                fullWidth
                value={weatherCondition}
                onChange={(e) => setWeatherCondition(e.target.value)}
              />
              <TFBInput
                placeholder={languagePack.pitch_condition}
                label={languagePack.pitch_condition}
                fullWidth
                value={pitchCondition}
                onChange={(e) => setPitchCondition(e.target.value)}
              />
            </div>

            <div className="select-general-match-evaluation">
              <TFBSelect
                placeholder={languagePack.select_match_evaluation}
                label={languagePack.select_match_evaluation}
                options={generalEvaluationOptions}
                value={generalEvaluation}
                onChange={(selected) => setGeneralEvaluation(selected)}
              />
            </div>

            <div className="text-editor">
              <label className="ds-main-text-semi-bold ">
                {languagePack.game_play}
              </label>

              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "undo",
                    "redo",
                  ],
                }}
                data={textEditor}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  // console.log(data);
                  setTextEditor(data);
                }}
              />
            </div>

            <TFBButton
              onClick={saveGeneratorDetails}
              endIcon="true"
              color={"green"}
              className="save-generator-details"
              renderIcon={() => <TFBIcon name={"add"} />}
            >
              {languagePack.save_details}
            </TFBButton>
          </div>
        </TFBCardBody>
      </TFBCard>
    )
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(MatchReportInfo);
