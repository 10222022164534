import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import "../UserList/UserList.scss";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TFBTableCell } from "../../../components/reusable/useStyles";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton.tsx";
import CreateNotification from "./CreateNotification/CreateNotification";
import ViewNotification from "./ViewNotification/ViewNotification";
import DeleteNotification from "./DeleteNotification/DeleteNotification";
import moment from "moment";

const NotificationListMobile = (props) => {
  const [mobileUsers, setMobileUsers] = useState([]);
  const [openAddNotificaton, setOpenAddNotificaton] = useState(false);
  const [openViewNotificaton, setOpenViewNotificaton] = useState(false);
  const [openDeleteNotificaton, setOpenDeleteNotificaton] = useState(false);

  const [notificationToView, setNotificationToView] = useState(null);
  const [idNotificationToDelete, setIdNotificationToDelete] = useState(null);

  useEffect(() => {
    getMobileUsers();
  }, []);

  const getMobileUsers = () => {
    const { id_club, id_user, token } = props.currentUser;
    axios
      .get(
        `notification/get_notifications_sent_from_admin?id_club=${id_club}&id_user=${id_user}&token=${token}`
      )
      .then((res) => setMobileUsers(res.data.list))
      .catch((err) => console.log(err));
  };

  const { languagePack } = props;

  return (
    <>
      <TFBCard>
        <TFBCardHeader title={props.languagePack.users_notifications}>
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
            onClick={() => setOpenAddNotificaton(true)}
          >
            {languagePack.create_notification}
          </TFBButton>
        </TFBCardHeader>

        <TFBCardBody style={{ marginTop: "50px" }}>
          {/* ============ NOTIFICATIONS TABLE ===================== */}
          {/* ====================================================== */}
          <TableContainer>
            <Table faria-label="simple table" sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TFBTableCell>{languagePack.sent_notification}</TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.notification_send_by}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.date_hour_of_send}
                  </TFBTableCell>
                  <TFBTableCell align="right">
                    {languagePack.product_actions}
                  </TFBTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mobileUsers.map((row, index) => (
                  <TableRow
                    key={"notification-number-" + index}
                    sx={{
                      backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                    }}
                  >
                    <TFBTableCell
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      {row.notification_title}
                    </TFBTableCell>
                    <TFBTableCell align="center">{row.full_name}</TFBTableCell>
                    <TFBTableCell align="center">
                      {moment(row.notification_date).format("DD.MM.YYYY HH:mm:ss")}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      <div
                        className="actions-btns-container"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <TFBIconButton
                          name="view"
                          color="darkGray"
                          onClick={() => {
                            setOpenViewNotificaton(true);
                            setNotificationToView(row);
                          }}
                        />

                        <TFBIconButton
                          onClick={() => {
                            setOpenDeleteNotificaton(true);
                            setIdNotificationToDelete(row.id_notification);
                          }}
                          name="delete"
                          color="darkGray"
                        />
                      </div>
                    </TFBTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TFBCardBody>
      </TFBCard>

      {/* ============ ADD NOTIFICATION DIALOG ===================== */}
      {/* ========================================================== */}
      {openAddNotificaton && (
        <CreateNotification
          openDialog={openAddNotificaton}
          closePopup={() => setOpenAddNotificaton(false)}
          refreshMobileUsers={() => getMobileUsers()}
        />
      )}

      {/* ============ VIEW NOTIFICATION DIALOG ===================== */}
      {/* ========================================================== */}
      {openViewNotificaton && (
        <ViewNotification
          openDialog={openViewNotificaton}
          closePopup={() => setOpenViewNotificaton(false)}
          notificationDetail={notificationToView}
        />
      )}

      {/* ============ DELETE NOTIFICATION DIALOG ===================== */}
      {/* ========================================================== */}
      {openDeleteNotificaton && (
        <DeleteNotification
          openDeletePopup={openDeleteNotificaton}
          closePopup={() => setOpenDeleteNotificaton(false)}
          languagePack={languagePack}
          id_notification_to_delete={idNotificationToDelete}
          id_club={props.currentUser.id_club}
          id_user={props.currentUser.id_user}
          token={props.currentUser.token}
          refreshMobileUsers={() => getMobileUsers()}
        />
      )}
    </>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(NotificationListMobile);
