import React from "react";
import "./MatchCosts.scss";
import axios from "axios";
import { connect } from "react-redux";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye-white.svg";
import * as myConstants from "./../../../../app/utils/constants";
import moment, { lang } from "moment";
import "moment/locale/ro";
import Loader from "react-loader-spinner";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";

class MatchCosts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      upcomingMatches: [],
      pastMatches: [],
      isLoadedPast: false,
      isLoadedFuture: false,
    };
  }

  componentDidMount = () => {
    this.getUpcomingMatches();
    this.getPastMatches();
  };

  getUpcomingMatches = () => {
    axios
      .get(
        `match/get_matches_all_footbal_api_2?id_club=${this.props.currentUser.id_club}&type=future&currency=${this.props.currencyItemSelected.uniqueSlug}`
      )
      .then((res) =>
        this.setState({ upcomingMatches: res.data, isLoadedFuture: true })
      )
      .catch((err) => console.log(err));
  };

  getPastMatches = () => {
    axios
      .get(
        `match/get_matches_all_footbal_api_2?id_club=${this.props.currentUser.id_club}&type=past&currency=${this.props.currencyItemSelected.uniqueSlug}`
      )
      .then((res) =>
        this.setState({ pastMatches: res.data, isLoadedPast: true })
      )
      .catch((err) => console.log(err));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentUser.currency != this.props.currentUser.currency) {
      this.setState({
        isLoadedPast: false,
        isLoadedFuture: false,
      });
      this.getUpcomingMatches();
      this.getPastMatches();
    }
  }

  render() {
    const { languagePack } = this.props;

    return (
      <TFBCard>
        <TFBCardHeader title={languagePack.match_costs} />
        <TFBCardBody>
          {this.state.isLoadedPast && this.state.isLoadedFuture ? (
            <div className="match-costs-container">
              {/* <div className="match-costs-title">{"Cheltuieli de meci"}</div> */}
              <div className="matches-container">
                <div className="matches-container-title">
                  {languagePack.future_matches}
                </div>
                <div className="matches-container-list">
                  {this.state.upcomingMatches.map((item, index) => {
                    return item.id_instat_match == null ? (
                      <div
                        key={"match-" + index}
                        className="match-container"
                        style={{
                          backgroundColor: index % 2 === 0 ? "#fafafa" : "",
                        }}
                      >
                        <div className="match-costs-match-date match-date">
                          {item.id_competition > 0
                            ? item.competition_name +
                              ", " +
                              languagePack["round"] +
                              " " +
                              item.round
                            : languagePack.frendly_match}
                          <br></br>
                          {item.venue_name != "" && (
                            <>
                              {item.venue_name}
                              <br></br>
                            </>
                          )}
                          {item.match_date != "0000-00-00 00:00:00" ? (
                            <div
                              style={{
                                color:
                                  item.approximate_date == 1
                                    ? "red"
                                    : "#666666",
                              }}
                            >
                              {moment(item.match_date)
                                .locale("ro")
                                .format("dddd, DD.MM.YYYY, HH:mm")}
                              {item.approximate_date == 1
                                ? " (data aproximativa)"
                                : ""}
                            </div>
                          ) : (
                            "-"
                          )}
                        </div>
                        <div className="match-costs-match-info">
                          <div className="match-team">
                            <div
                              className="team-name"
                              style={{ textAlign: "end", paddingRight: 30 }}
                            >
                              {item.team_home_name}
                            </div>
                            <div className="match-logo">
                              <img
                                src={item.home_team_url}
                                alt="team_home_logo"
                              />
                            </div>
                          </div>
                          <div className="match-score">vs</div>
                          <div className="match-team">
                            <div className="match-logo">
                              <img
                                src={item.away_team_url}
                                alt="team_away_logo"
                              />
                            </div>
                            <div
                              className="team-name"
                              style={{ paddingLeft: 30 }}
                            >
                              {item.team_away_name}
                            </div>
                          </div>
                        </div>
                        <div className="match-costs-currency">
                          <div className="match-see-more match-costs">
                            <div className="data-amount">
                              {Number(item.match_costs_update).toFixed(2) +
                                ` ${this.props.currencyItemSelected.uniqueSlug}`}
                            </div>
                            <div
                              className="eye-icon-container"
                              onClick={() =>
                                this.props.history.push(
                                  `/club/match-costs/edit/${item.id_match}`
                                )
                              }
                            >
                              <EyeIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        key={"match-instat-" + index}
                        className="match-container"
                        style={{
                          backgroundColor: index % 2 === 0 ? "#fafafa" : "",
                        }}
                      >
                        <div className="match-costs-match-date match-date">
                          {item.id_competition > 0
                            ? item.competition_name +
                              ", " +
                              languagePack["round"] +
                              " " +
                              item.round
                            : languagePack.frendly_match}
                          <br></br>
                          {item.venue_name != "" && (
                            <>
                              {item.venue_name}
                              <br></br>
                            </>
                          )}
                          {item.match_date != "0000-00-00 00:00:00" ? (
                            <div
                              style={{
                                color:
                                  item.approximate_date == 1
                                    ? "red"
                                    : "#666666",
                              }}
                            >
                              {moment(item.match_date)
                                .locale("ro")
                                .format("dddd, DD.MM.YYYY, HH:mm")}
                              {item.approximate_date == 1
                                ? " (data aproximativa)"
                                : ""}
                            </div>
                          ) : (
                            "-"
                          )}
                        </div>
                        <div className="match-costs-match-info">
                          <div className="match-team">
                            <div
                              className="team-name"
                              style={{ textAlign: "end", paddingRight: 30 }}
                            >
                              {item.team_home_name}
                            </div>
                            <div className="match-logo">
                              <img
                                src={item.home_team_url}
                                alt="team_home_logo"
                              />
                            </div>
                          </div>
                          <div className="match-score">vs</div>
                          <div className="match-team">
                            <div className="match-logo">
                              <img
                                src={item.away_team_url}
                                alt="team_away_logo"
                              />
                            </div>
                            <div
                              className="team-name"
                              style={{ paddingLeft: 30 }}
                            >
                              {item.team_away_name}
                            </div>
                          </div>
                        </div>
                        <div className="match-costs-currency">
                          <div className="match-see-more match-costs">
                            <div className="data-amount">
                              {Number(item.match_costs_update).toFixed(2) +
                                ` ${this.props.currencyItemSelected.uniqueSlug}`}
                            </div>
                            <div
                              className="eye-icon-container"
                              onClick={() =>
                                this.props.history.push(
                                  `/club/match-costs/edit/${item.id_match}`
                                )
                              }
                            >
                              <EyeIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="matches-container">
                <div className="matches-container-title">
                  {languagePack.past_matches}
                </div>
                <div className="matches-container-list">
                  {this.state.pastMatches.map((item, index) => {
                    return item.id_instat_match == null ? (
                      <div
                        key={"match-" + index}
                        className="match-container"
                        style={{
                          backgroundColor: index % 2 === 0 ? "#fafafa" : "",
                        }}
                      >
                        <div className="match-costs-match-date match-date">
                          {item.id_competition > 0
                            ? item.competition_name +
                              ", " +
                              languagePack["round"] +
                              " " +
                              item.round
                            : languagePack.frendly_match}
                          <br></br>
                          {item.venue_name != "" && (
                            <>
                              {item.venue_name}
                              <br></br>
                            </>
                          )}
                          {item.match_date != "0000-00-00 00:00:00" ? (
                            <div
                              style={{
                                color:
                                  item.approximate_date == 1
                                    ? "red"
                                    : "#666666",
                              }}
                            >
                              {moment(item.match_date)
                                .locale("ro")
                                .format("dddd, DD.MM.YYYY, HH:mm")}
                              {item.approximate_date == 1
                                ? " (data aproximativa)"
                                : ""}
                            </div>
                          ) : (
                            "-"
                          )}
                        </div>
                        <div className="match-costs-match-info">
                          <div className="match-team">
                            <div
                              className="team-name"
                              style={{ textAlign: "end", paddingRight: 30 }}
                            >
                              {item.team_home_name}
                            </div>
                            <div className="match-logo">
                              <img
                                src={item.home_team_url}
                                alt="team_home_logo"
                              />
                            </div>
                          </div>
                          <div className="match-score">
                            {item.team_home_goals}:{item.team_away_goals}
                          </div>
                          <div className="match-team">
                            <div className="match-logo">
                              <img
                                src={item.away_team_url}
                                alt="team_away_logo"
                              />
                            </div>
                            <div
                              className="team-name"
                              style={{ paddingLeft: 30 }}
                            >
                              {item.team_away_name}
                            </div>
                          </div>
                        </div>
                        <div className="match-costs-currency">
                          <div className="match-see-more match-costs">
                            <div className="data-amount">
                              {Number(item.match_costs_update).toFixed(2) +
                                ` ${this.props.currencyItemSelected.uniqueSlug}`}
                            </div>
                            <div
                              className="eye-icon-container"
                              onClick={() =>
                                this.props.history.push(
                                  `/club/match-costs/edit/${item.id_match}`
                                )
                              }
                            >
                              <EyeIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        key={"match-instat-" + index}
                        className="match-container"
                        style={{
                          backgroundColor: index % 2 === 0 ? "#fafafa" : "",
                        }}
                      >
                        <div className="match-costs-match-date match-date">
                          {item.id_competition > 0
                            ? item.competition_name +
                              ", " +
                              languagePack["round"] +
                              " " +
                              item.round
                            : languagePack.frendly_match}
                          <br></br>
                          {item.venue_name != "" && (
                            <>
                              {item.venue_name}
                              <br></br>
                            </>
                          )}
                          {item.match_date != "0000-00-00 00:00:00" ? (
                            <div
                              style={{
                                color:
                                  item.approximate_date == 1
                                    ? "red"
                                    : "#666666",
                              }}
                            >
                              {moment(item.match_date)
                                .locale("ro")
                                .format("dddd, DD.MM.YYYY, HH:mm")}
                              {item.approximate_date == 1
                                ? " (data aproximativa)"
                                : ""}
                            </div>
                          ) : (
                            "-"
                          )}
                        </div>
                        <div className="match-costs-match-info">
                          <div className="match-team">
                            <div
                              className="team-name"
                              style={{ textAlign: "end", paddingRight: 30 }}
                            >
                              {item.team_home_name}
                            </div>
                            <div className="match-logo">
                              <img
                                src={item.home_team_url}
                                alt="team_home_logo"
                              />
                            </div>
                          </div>
                          <div className="match-score">
                            {item.team_home_goals}:{item.team_away_goals}
                          </div>
                          <div className="match-team">
                            <div className="match-logo">
                              <img
                                src={item.away_team_url}
                                alt="team_away_logo"
                              />
                            </div>
                            <div
                              className="team-name"
                              style={{ paddingLeft: 30 }}
                            >
                              {item.team_away_name}
                            </div>
                          </div>
                        </div>
                        <div className="match-costs-currency">
                          <div className="match-see-more match-costs">
                            <div className="data-amount">
                              {Number(item.match_costs_update).toFixed(2) +
                                ` ${this.props.currencyItemSelected.uniqueSlug}`}
                            </div>
                            <div
                              className="eye-icon-container"
                              onClick={() =>
                                this.props.history.push(
                                  `/club/match-costs/edit/${item.id_match}`
                                )
                              }
                            >
                              <EyeIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className="loader-container">
              <Loader
                type="TailSpin"
                color={window.clubCustomization.primary_color}
                height={60}
                width={60}
              />
            </div>
          )}
        </TFBCardBody>
      </TFBCard>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  currencyItemSelected:
    myConstants.currencies[auth?.user?.currency] || myConstants.currencies.EUR,
}))(MatchCosts);
