import React from "react";
import styles from "./TFBInput.module.scss";
import { TextField } from "@material-ui/core";
import { TInput } from "../types";
import { makeStyles } from "@material-ui/core/styles";

const TFBInput = ({
  className,
  defaultValue,
  disabled,
  error,
  fullWidth,
  id,
  label,
  multiline,
  onChange,
  placeholder,
  required,
  type,
  variant = "outlined",
  style,
  value,
  inputProps,
}: TInput) => {
  const classes = makeStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        height: "50px",
      },
    },
  });

  return label ? (
    <div className={styles.inputGroup}>
      <h6 className={styles.inputLabel}>{label}</h6>
      <TextField
        className={`${styles.tfbInput} ${className ? className : ""} ${
          classes().root
        }`}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        error={error}
        fullWidth={fullWidth}
        id={id}
        multiline={multiline}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        type={type}
        variant={variant}
        style={style}
        inputProps={inputProps}
      />
    </div>
  ) : (
    <TextField
      className={`${styles.tfbInput} ${className ? className : ""}`}
      defaultValue={defaultValue}
      value={value}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      id={id}
      multiline={multiline}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      type={type}
      variant={variant}
      style={style}
      inputProps={inputProps}
    />
  );
  // <>
  //   {label && <h6 className={styles.inputLabel}>{label}</h6>}
  //   <TextField
  //     className={`${styles.tfbInput} ${className ? className : ""}`}
  //     defaultValue={defaultValue}
  //     disabled={disabled}
  //     error={error}
  //     fullWidth={fullWidth}
  //     id={id}
  //     multiline={multiline}
  //     onChange={onChange}
  //     placeholder={placeholder}
  //     required={required}
  //     type={type}
  //     variant={variant}
  //   />
  // </>
};

export default TFBInput;
