import React from "react";
import styles from "./Card.module.scss";
import { TCardHeader } from "../types";

const TFBCardHeader = ({ title, children, className, style }: TCardHeader) => {
  const classN = className ? className : "";
  return (
    <div className={`${styles.cardHeader} ${classN}`} style={style}>
      <h1 className={styles.headerTitle}>{title}</h1>
      <div className={styles.headerRight}>{children}</div>
    </div>
  );
};

export default TFBCardHeader;
