import React, { Component } from "react";
import "./AddTeam.scss";
import { Icon, Button } from "semantic-ui-react";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import Select from "react-select"

class AddTeamToGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teamOption: "new",
            newTeamName: "",
            teamName: "",
            teamImage: "",
            clubTeams: [],
            group: "",
            id_team: "",
            team: "",
            teams: []
        };
    }

    addTeamToGroup = () => {
        const payload = {
            id_comp_group: this.state.group,
            id_team: this.state.team
        };
        axios
            .post("match/add_team_to_group", payload)
            .then((res) => {
                this.props.getMatches();
                this.props.closePopup();
                this.props.getTeams();
            })
            .catch((err) => console.log(err));
    };

    componentDidMount = () => {
        this.getClubTeams();
        this.getCompetitionTeams();
    };

    getClubTeams = () => {
        axios
            .get(`match/get_club_teams?id_club=${this.props.currentUser.id_club}`)
            .then((res) => this.setState({ clubTeams: res.data }))
            .catch((err) => console.log(err));
    };

    getCompetitionTeams = () => {
        axios
            .get(`match/get_competition_teams?id_competition=${this.props.id_competition}`)
            .then((res) => {
                this.setState({
                    teams: res.data
                });
            })
            .catch((err) => console.log(err));
    }

    handleChangeImage = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            this.setState({
                fileSection: file,
                teamImage: reader.result,
            });
        };
    };

    render() {
        const { languagePack } = this.props;
        return (
            <div className="add-team-manual-container" style={{ paddingBottom: "12px" }}>
                <div className="add-team-manual-title">
                    {/* {languagePack["add_team"]} */}
                    {"Add team to group"}
                </div>
                <div className="add-team-container-input">
                    {this.props.groups.length > 0 &&
                        <div className="group-dropdown club-team-dropdown">
                            <select
                                value={this.state.group}
                                onChange={(e) => {
                                    this.setState({ group: e.target.value })
                                }}
                            >
                                <option value="" disabled selected>
                                    {"Choose group"}
                                </option>
                                {this.props.groups.map((item) => {
                                    return (
                                        <option value={item.value} key={item.value}>
                                            {item.label}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    }
                    {this.props.teams.length > 0 &&
                        <div className="group-dropdown club-team-dropdown">
                            <select
                                value={this.state.team}
                                onChange={(e) => {
                                    this.setState({ team: e.target.value })
                                }}
                            >
                                <option value="" disabled selected>
                                    {"Choose team"}
                                </option>
                                {this.state.teams.map((item) => {
                                    return (
                                        <option value={item.id_manual_competition_team} key={item.id_manual_competition_team}>
                                            {item.team_name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    }
                    <div className="add-team-manual-buttons">
                        <Button
                            className="add-team-button cancel-button"
                            onClick={() => this.props.closePopup()}
                        >
                            {languagePack["admin_cancel"]}
                            <Icon name="cancel" className="white-icon" />
                        </Button>
                        <Button
                            className="add-team-button"
                            disabled={this.state.team == "" || this.state.group == ""}
                            onClick={this.addTeamToGroup}>
                            {languagePack["admin_add"]}
                            <Icon name="add" className="white-icon" />
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
}))(AddTeamToGroup);
