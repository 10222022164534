import React from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { Icon, Button } from "semantic-ui-react";
import "../../Club/Matches/Competitions/ManageCompetitions.scss";
import { connect } from "react-redux";

function DeleteTraining(props) {
  function deleteTrainingSession() {
    const payload = {
      id_training: props.idTraining,
    };
    axios.post("training/delete_training_session", payload).then((res) => {
      if (res.data == 1) {
        props.refreshTrainingsList();
        store.addNotification({
          title: props.languagePack["training_add_title"],
          message: props.languagePack.training_session_successfully_deleted,
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      } else {
        store.addNotification({
          title: props.languagePack["training_add_title"],
          message: props.languagePack.training_session_failed_deleted,
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      }
      props.closePopup();
    });
  }

  return (
    <div className="delete-competition-popup-container">
      <div className="delete-competition-header">
        <div className="delete-competition-title">
          {props.languagePack.delete_training}
        </div>
        <Icon
          name="close"
          className="close"
          size="large"
          onClick={props.closePopup}
        />
      </div>
      <div className="delete-competition-text">
        {props.languagePack.delete_training_text}
      </div>
      <div className="delete-competition-buttons">
        <Button className="cancel-button" onClick={props.closePopup}>
          {props.languagePack["admin_cancel"]}
          <Icon name="close" className="white-icon" />
        </Button>
        <Button className="delete-comp-button" onClick={deleteTrainingSession}>
          {props.languagePack["admin_delete"]}
          <Icon name="trash alternate outline" className="white-icon" />
        </Button>
      </div>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(DeleteTraining);
