import React from "react";
import axios from "axios";
import "./WeightControl.scss";
import { Icon, Button, Dropdown } from "semantic-ui-react";
import moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "react-notifications-component";
import Moment from "react-moment";
import Avatar from "@material-ui/core/Avatar";
import arrow from "../../../assets/icons/arrow-down.png";
import { connect } from "react-redux";

import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton.tsx";
import TFBButton from "../../../components/design-system/Button/TFBButton.tsx";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon.tsx";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../components/reusable/useStyles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TFBDropdown from "../../../components/design-system/Dropdown/TFBDropdown";

class WeightControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      players: [],
      teams: [],
      dates: [],
      period_start: moment().startOf("isoWeek"),
      period_end: moment().endOf("isoWeek"),
      selected_team: "",
      addWeightId: "",
      addOptimalWeightId: "",

      addedWeightNumber: "",
      addedOptWeightNumber: "",
      addedWeightDate: new Date(),
    };
  }

  componentDidMount = () => {
    this.getPlayers();
    this.getTeams();
  };

  getTeams = () => {
    axios
      .get(
        `training/get_teams_dropdown?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => this.setState({ teams: res.data }))
      .catch((err) => console.log(err));
  };

  getPlayers = () => {
    axios
      .get(
        `training/get_players_weight?id_club=${
          this.props.currentUser.id_club
        }&id_team=${
          this.state.selected_team
        }&date_start=${this.state.period_start.format(
          "YYYY-MM-DD"
        )}&date_end=${this.state.period_end.format("YYYY-MM-DD")}`
      )
      .then((res) =>
        this.setState({
          players: res.data.players,
          dates: res.data.dates,
          selected_team: res.data.id_team,
        })
      )
      .catch((err) => console.log(err));
  };

  decreasePeriod = () => {
    this.setState(
      {
        period_start: this.state.period_start.subtract(1, "weeks"),
        period_end: this.state.period_end.subtract(1, "weeks"),
      },
      this.getPlayers
    );
  };

  increasePeriod = () => {
    this.setState(
      {
        period_start: this.state.period_start.add(1, "weeks"),
        period_end: this.state.period_end.add(1, "weeks"),
      },
      this.getPlayers
    );
  };

  handleChangeDropdown = (e, value) => {
    this.setState(
      {
        [value.name]: value.value,
      },
      this.getPlayers
    );
  };

  handleDateChange = (value) => {
    this.setState({ addedWeightDate: value });
  };

  handleSubmitWeight = () => {
    const payload = {
      id_player: this.state.addWeightId,
      player_weight: this.state.addedWeightNumber,
      measure_date: moment(this.state.addedWeightDate).format("YYYY-MM-DD"),
    };
    axios
      .post("training/add_weight", payload)
      .then((res) => {
        store.addNotification({
          title: "Monitorizare greutate",
          message: "Masuratoarea a fost adaugata cu succes",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        this.setState(
          { addWeightId: "", addedWeightNumber: "" },
          this.getPlayers
        );
      })
      .catch((err) => console.log(err));
  };

  handleSubmitOptWeight = () => {
    const payload = {
      id_player: this.state.addOptimalWeightId,
      player_weight: this.state.addedOptWeightNumber,
    };
    axios
      .post("training/add_optimal_weight", payload)
      .then((res) => {
        store.addNotification({
          title: "Monitorizare greutate",
          message: "Masuratoarea a fost adaugata cu succes",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        this.setState(
          { addOptimalWeightId: "", addedOptWeightNumber: "" },
          this.getPlayers
        );
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <TFBCard>
        <div className="weight-control-container">
          {/* <div className="weight-control-header">
            <div className="weight-control-title">
              {languagePack["weight_control"]}
            </div>

            <div className="wrapper-weight-control-team-dropdown">
              <Dropdown
                selection
                className="weight-control-team-dropdown"
                options={this.state.teams}
                placeholder={languagePack["select_team"]}
                value={this.state.selected_team}
                name="selected_team"
                onChange={this.handleChangeDropdown}
              />
            </div>
          </div> */}

          <TFBCardHeader title={languagePack["weight_control"]}>
            <TFBDropdown
              placeholder={languagePack["select_team"]}
              selection
              options={this.state.teams}
              name="selected_team"
              color="gray"
              value={this.state.selected_team}
              onChange={this.handleChangeDropdown}
            />
          </TFBCardHeader>

          <div className="period-container">
            <img
              src={arrow}
              alt=""
              onClick={this.decreasePeriod}
              className="left-arrow"
            />

            <div className="period">
              {this.state.period_start.format("D MMM")} -{" "}
              {this.state.period_end.format("D MMM")}
            </div>

            <img
              src={arrow}
              alt=""
              onClick={this.increasePeriod}
              className="right-arrow"
            />
          </div>

          <div className="weight-control-table-header desktop-header">
            <div className="player-name">{languagePack["PLAYER_NAME"]}</div>
            {this.state.dates.map((i, index) => {
              return (
                <div className="player-day" key={"day-" + index}>
                  <Moment format="DD MMM">{i}</Moment>
                </div>
              );
            })}
            <div className="optimal-weight">
              {languagePack["optimal_weight"]}
            </div>
          </div>

          <div className="weight-control-list">
            {/* <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn}>
                      {languagePack["PLAYER_NAME"]}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {"Prezență confirmată"}
                    </TFBTableCell>
                    <TFBTableCell align="center">{"Locatie"}</TFBTableCell>
                    <TFBTableCell align="center">{"Data"}</TFBTableCell>
                    <TFBTableCell align="center">
                      {"Ora inceperii"}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {"Ora incheierii"}
                    </TFBTableCell>
                    <TFBTableCell align="right">{"Acțiuni"}</TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.trainings.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      <TFBTableCell
                        component="th"
                        scope="row"
                        sx={sxStickyColumn}
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        {row.team_name}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {" "}
                        <div
                          className="confirmed-presence"
                          style={{
                            gap: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              backgroundColor: row.color,
                              borderRadius: "5px",
                              width: "33px",
                              height: "33px",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "#fafafa",
                            }}
                            className="presence"
                          >
                            {row.nr_will_attend}
                          </div>
                          {"/"}
                          {row.nr_total_players}
                        </div>
                      </TFBTableCell>
                      <TFBTableCell align="center">{row.venue}</TFBTableCell>
                      <TFBTableCell align="center">{row.date}</TFBTableCell>
                      <TFBTableCell align="center">
                        {row.hour_start}
                      </TFBTableCell>
                      <TFBTableCell align="center">{row.hour_end}</TFBTableCell>
                      <TFBTableCell align="right" style={{ width: "5%" }}>
                        <div
                          className=""
                          style={{ gap: "5px", display: "flex" }}
                        >
                          <TFBIconButton
                            name="list"
                            onClick={() => {
                              this.props.history.push(
                                `/training/history/details/${row.id_training}`
                              );
                            }}
                            color="darkGray"
                          />
                          <TFBIconButton
                            name="eye"
                            onClick={() =>
                              this.props.history.push(
                                `/training/history/${row.id_training}`
                              )
                            }
                            color="darkGray"
                          />
                          <TFBIconButton
                            name="edit"
                            onClick={() =>
                              this.setState(
                                {
                                  openTrainingToEdit: true,
                                  trainingToEdit: row,
                                },
                                () => {
                                  this.props.history.push(
                                    `/training/plan/edit/${row.id_training}`
                                  );
                                }
                              )
                            }
                            color="darkGray"
                          />
                          <TFBIconButton
                            name="delete"
                            onClick={() =>
                              this.setState({
                                openTrainingToDelete: true,
                                trainingToDelete: row.id_training,
                              })
                            }
                            color="red"
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}

            {this.state.players.map((item, index) => {
              return (
                <div
                  className="player-row"
                  key={"player-row-" + index}
                  style={{
                    backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                  }}
                >
                  <div className="header-row">
                    <div className="weight-control-table-header mobile-header">
                      <div className="player-name">
                        {languagePack["PLAYER_NAME"]}
                      </div>
                      {this.state.dates.map((i, index) => {
                        return (
                          <div
                            className="player-day"
                            key={"player-row-day-" + index}
                          >
                            <Moment format="DD MMM">{i}</Moment>
                          </div>
                        );
                      })}
                      <div className="optimal-weight">
                        {languagePack["optimal_weight"]}
                      </div>
                    </div>
                  </div>
                  <div className="content-row">
                    <div
                      className="player-name fullname"
                      onClick={() =>
                        this.props.history.push(
                          `/club/player/${item.id_player}`
                        )
                      }
                    >
                      <Avatar
                        src={`${window.baseURL}uploads/players/${item.id_player}.jpg`}
                      />
                      <div className="fullname" style={{ marginLeft: 15 }}>
                        {item.player_name}
                      </div>
                    </div>
                    {item.weights.map((day, index) => {
                      return (
                        <div
                          className="player-day"
                          style={{
                            color:
                              day > item.optimal_weight ? "#d91200" : "#666666",
                          }}
                          key={"player-row-day_2-" + index}
                        >
                          {day} {day !== "-" ? "kg" : ""}
                        </div>
                      );
                    })}
                    <div className="optimal-weight">
                      {item.optimal_weight ? (
                        <div
                          onClick={() =>
                            this.setState({
                              addOptimalWeightId: item.id_player,
                              addedOptWeightNumber: item.optimal_weight,
                            })
                          }
                          title={languagePack["add_optimal_weight"]}
                        >
                          {item.optimal_weight}
                        </div>
                      ) : (
                        <div
                          onClick={() =>
                            this.setState({
                              addOptimalWeightId: item.id_player,
                            })
                          }
                          title={languagePack["add_optimal_weight"]}
                        >
                          +
                        </div>
                      )}
                      {item.optimal_weight && (
                        <div
                          onClick={() =>
                            this.setState({
                              addOptimalWeightId: item.id_player,
                              addedOptWeightNumber: item.optimal_weight,
                            })
                          }
                          title={languagePack["add_optimal_weight"]}
                        >
                          {" "}
                          kg
                        </div>
                      )}
                    </div>
                    <div className="player-options">
                      <div
                        className="add-weight-button-container"
                        onClick={() =>
                          this.setState({ addWeightId: item.id_player })
                        }
                      >
                        <Icon
                          name="add"
                          size="small"
                          className="weight-control-add-icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <Dialog
          open={this.state.addWeightId !== ""}
          // onClose={() => this.setState({ addWeightId: "" })}
        >
          <DialogContent>
            <div className="add-player-weight-popup">
              <div className="add-player-weight-header">
                <div className="add-weight-title">
                  {languagePack["add_weight"]}
                </div>
                <Icon
                  name="close"
                  size="large"
                  onClick={() => this.setState({ addWeightId: "" })}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="add-weight-date-row">
                  <DatePicker
                    value={this.state.addedWeightDate}
                    onChange={this.handleDateChange}
                    format="dd MMMM yyyy"
                    name="addedWeightDate"
                    inputVariant="outlined"
                    className="weight-date-input"
                    emptyLabel={languagePack["measure_date"]}
                  />
                  <Icon
                    name="calendar alternate outline"
                    className="weight-calendar-icon"
                    size="small"
                  />
                </div>
              </MuiPickersUtilsProvider>
              <input
                value={this.state.addedWeightNumber}
                onChange={(e) =>
                  this.setState({ addedWeightNumber: e.target.value })
                }
                className="add-weight-input"
                placeholder={languagePack["trasnaction_amount"]}
              />
              <Button
                className="add-player-weight-button"
                onClick={this.handleSubmitWeight}
              >
                {languagePack["admin_save"]}
                <Icon name="add" className="player-weight-button-icon" />
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.addOptimalWeightId !== ""}
          // onClose={() => this.setState({ addOptimalWeightId: "" })}
        >
          <DialogContent>
            <div className="add-player-weight-popup">
              <div className="add-player-weight-header">
                <div className="add-weight-title">
                  {languagePack["add_optimal_weight"]}
                </div>
                <Icon
                  name="close"
                  size="large"
                  onClick={() => this.setState({ addOptimalWeightId: "" })}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <input
                value={this.state.addedOptWeightNumber}
                onChange={(e) =>
                  this.setState({ addedOptWeightNumber: e.target.value })
                }
                className="add-weight-input"
                placeholder={languagePack["trasnaction_amount"]}
              />
              <Button
                className="add-player-weight-button"
                onClick={this.handleSubmitOptWeight}
              >
                {languagePack["admin_save"]}
                <Icon name="add" className="player-weight-button-icon" />
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </TFBCard>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(WeightControl);
