import React, { Component } from "react";
import "../../instat/player/PlayerEventsPitch.scss";
import axios from "axios";
import { Popup, Checkbox, Dropdown } from "semantic-ui-react";

import default_pin from "../../../../assets/instat-pitch/default.png";
import goal_pin from "../../../../assets/instat-pitch/goal.png";
import miss_pin from "../../../../assets/instat-pitch/miss.png";
import ontarget_pin from "../../../../assets/instat-pitch/ontarget.png";
import dribble_pin from "../../../../assets/instat-pitch/dribble.png";
import foul_pin from "../../../../assets/instat-pitch/foul.png";
import pass_start_pin from "../../../../assets/instat-pitch/pass_start.png";
import pass_end_pin from "../../../../assets/instat-pitch/pass_end.png";
import Xarrow from "react-xarrows";
import {
  initialOptionsShots,
  initialOptionsDefensive,
  initialOptionsTechnical,
  pinColors,
} from "../PitchParameters";

class MatchEventsPitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      scale: 8,
      selectedOptions: [4010, 4040, 8010],
      selectedTeam: "",
      matchTeams: [],
      playersDropdown: [],
      selectedPlayer: "",
    };
  }

  getEvents = () => {
    axios
      .get(
        `instat/match_events?id_match=${
          this.props.id_match
        }&actions=${JSON.stringify(this.state.selectedOptions)}&id_team=${
          this.state.selectedTeam
        }&id_player=${this.state.selectedPlayer}`
      )
      .then((res) =>
        this.setState({ events: res.data.events, matchTeams: res.data.teams })
      )
      .catch((err) => console.log(err));
  };

  componentDidMount = () => {
    this.getEvents();
    this.getPlayers();
  };

  getPlayers = () => {
    axios
      .get(
        `instat/match_events_players_dropdown?id_match=${this.props.id_match}`
      )
      .then((res) => this.setState({ playersDropdown: res.data }))
      .catch((err) => console.log(err));
  };

  getPinName = (type) => {
    switch (type) {
      case "4010": //shot on target
        return ontarget_pin;
      case "8010": //goal
      case "1011": //attacking pass accurate (GK)
      case "13011": //successful interception (GK)
        return goal_pin;
      case "4020": //wide shot
      case "4030": //shot in post
      case "4040": //shot blocked
      case "4050": //shot blocked by field player
      case "1012": //attacking pass inaccurate (GK)
        return miss_pin;
      case "3010": //foul committed
      case "13012": //unsuccessful interception (GK)
        return foul_pin;
      case "2051": //successful dribble
        return dribble_pin;
      default:
        return default_pin;
    }
  };

  handleChangeCheckbox = (id) => {
    if (this.state.selectedOptions.filter((el) => el === id).length > 0) {
      this.setState(
        {
          selectedOptions: this.state.selectedOptions.filter(
            (item) => item !== id
          ),
        },
        this.getEvents
      );
    } else {
      this.setState(
        { selectedOptions: this.state.selectedOptions.concat(id) },
        this.getEvents
      );
    }
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value }, this.getEvents);
  };

  render() {
    return (
      <div className="card card-custom" style={{ padding: 50 }}>
        <div className="player-events-pitch-header">
          <div className="player-events-pitch-title">Evenimente meci</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Dropdown
              selection
              clearable
              options={this.state.matchTeams}
              value={this.state.selectedTeam}
              placeholder="Selecteaza echipa"
              onChange={this.handleChangeDropdown}
              name="selectedTeam"
              className="players-dropdown"
              style={{ minWidth: 190 }}
            />
            <Dropdown
              selection
              clearable
              options={this.state.playersDropdown}
              value={this.state.selectedPlayer}
              placeholder="Selecteaza jucator"
              onChange={this.handleChangeDropdown}
              name="selectedPlayer"
              className="players-dropdown"
              style={{ minWidth: 330, marginLeft: 15 }}
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div className="pitch-container">
            {this.state.events.map((item, i) => {
              const pinURL = this.getPinName(item.action_id);
              return (
                <Popup
                  position="top center"
                  key={i}
                  content={
                    <div className="popup-container-event">
                      <div className="minute">
                        {item.action_name + " : "}
                        {item.minute}'
                      </div>
                      <div className="opponent">{item.player_name}</div>

                      <div className="opponent">
                        {"(" + item.team_name + ")"}
                      </div>
                      {item.action_id == 2030 && ( //tackles
                        <div className="opponent">
                          {"vs " + item.opponent_name}
                        </div>
                      )}
                    </div>
                  }
                  trigger={
                    item.has_personalized_pin ? (
                      <div
                        className="point"
                        style={{
                          left: this.state.scale * parseFloat(item.pos_x),
                          bottom: this.state.scale * parseFloat(item.pos_y),
                          backgroundImage: `url(${pinURL})`,
                        }}
                      />
                    ) : (
                      <div
                        className="color-point-wrapper"
                        style={{
                          left: this.state.scale * parseFloat(item.pos_x),
                          bottom: this.state.scale * parseFloat(item.pos_y),
                        }}
                      >
                        <div
                          className="color-point"
                          style={{
                            backgroundColor: pinColors.find(
                              (el) => el.id == item.action_id
                            ).color,
                          }}
                        />
                      </div>
                    )
                  }
                />
              );
            })}

            {this.state.events
              .filter(
                (el) =>
                  el.action_id == 1031 ||
                  el.action_id == 1032 ||
                  el.action_id == 1040 ||
                  el.action_id == 1050
              )
              .map((item) => {
                return (
                  <>
                    <Popup
                      position="top center"
                      trigger={
                        <div
                          className="point"
                          style={{
                            left: this.state.scale * parseFloat(item.pos_x),
                            bottom: this.state.scale * parseFloat(item.pos_y),
                            backgroundImage: `url(${pass_start_pin})`,
                            zIndex: 33,
                          }}
                          id={`pass-${item.key}-start`}
                        />
                      }
                      content={
                        <div className="popup-container-event">
                          <div className="minute">
                            {item.action_name + " : "}
                            {item.minute}'
                          </div>

                          <div className="opponent">
                            {"from " + item.player_name}
                          </div>
                        </div>
                      }
                    />
                    <Popup
                      position="top center"
                      trigger={
                        <div
                          className="point"
                          style={{
                            left: this.state.scale * parseFloat(item.pos_end_x),
                            bottom:
                              this.state.scale * parseFloat(item.pos_end_y),
                            backgroundImage: `url(${pass_end_pin})`,
                            zIndex: 33,
                          }}
                          id={`pass-${item.key}-end`}
                        />
                      }
                      content={
                        <div className="popup-container-event">
                          <div className="minute">
                            {item.action_name + " : "}
                            {item.minute}'
                          </div>
                          {item.opponent_name && (
                            <div className="opponent">
                              {item.team_id == item.opponent_team_id
                                ? "to " + item.opponent_name
                                : "intercepted by " + item.opponent_name}
                            </div>
                          )}
                        </div>
                      }
                    />
                    <Xarrow
                      start={item.start_key}
                      startAnchor="middle"
                      endAnchor="middle"
                      end={item.end_key}
                      path="straight"
                      dashness={false}
                      strokeWidth={2}
                      headSize={5}
                      color={item.action_id == 1032 ? "red" : "#00d948"}
                    />
                  </>
                );
              })}
          </div>
          <div className="checkboxes-wrapper">
            <div className="column">
              <div className="checkboxes-subtitle">Faza ofensiva</div>
              <div className="checkboxes-container">
                {initialOptionsShots.map((item, index) => {
                  return (
                    <div className="element-container" key={index}>
                      <Checkbox
                        className="action-checkbox"
                        onChange={() => this.handleChangeCheckbox(item.id)}
                        checked={
                          this.state.selectedOptions.filter(
                            (el) => el === item.id
                          ).length > 0
                        }
                      />
                      <div className="action-name">{item.name}</div>
                      <div
                        className="legend-point"
                        style={{
                          backgroundImage: `url(${this.getPinName(
                            item.id.toString()
                          )})`,
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="column">
              <div className="checkboxes-subtitle">Faza defensiva</div>
              <div className="checkboxes-container">
                {initialOptionsDefensive.map((item, index) => {
                  return (
                    <div className="element-container" key={index}>
                      <Checkbox
                        className="action-checkbox"
                        onChange={() => this.handleChangeCheckbox(item.id)}
                        checked={
                          this.state.selectedOptions.filter(
                            (el) => el === item.id
                          ).length > 0
                        }
                      />
                      <div className="action-name">{item.name}</div>
                      {item.personalized_pin ? (
                        <div
                          className="legend-point"
                          style={{
                            backgroundImage: `url(${this.getPinName(
                              item.id.toString()
                            )})`,
                          }}
                        />
                      ) : (
                        <div
                          className="legend-point-color"
                          style={{ backgroundColor: item.color }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="column">
              <div className="checkboxes-subtitle">Tehnic</div>
              <div className="checkboxes-container">
                {initialOptionsTechnical.map((item, index) => (
                  <div className="element-container" key={index}>
                    <Checkbox
                      className="action-checkbox"
                      onChange={() => this.handleChangeCheckbox(item.id)}
                      checked={
                        this.state.selectedOptions.filter(
                          (el) => el === item.id
                        ).length > 0
                      }
                    />
                    <div className="action-name">{item.name}</div>
                    {item.personalized_pin ? (
                      <div
                        className="legend-point"
                        style={{
                          backgroundImage: `url(${this.getPinName(
                            item.id.toString()
                          )})`,
                        }}
                      />
                    ) : (
                      <div
                        className="legend-point-color"
                        style={{ backgroundColor: item.color }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MatchEventsPitch;
