import React, { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Button, Icon } from "semantic-ui-react";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import AddStaffForm from "./AddStaffForm";
import RolesList from "../RolesList/RolesList.js";
import { ReactComponent as EyeIcon } from "../../../../assets/icons/eye-white.svg";
import "./StaffMembers.scss";
import Loader from "react-loader-spinner";
import EditStaffDetails from "./EditStaffDetails";

function StaffMembers(props) {
  const [staffMembers, setStaffMembers] = useState([]);
  const [dialog, setDialog] = useState(props.openAddStaffPopup);
  const [listDialog, setListDialog] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [editStaffItem, setEditStaffItem] = useState({});

  const { languagePack } = props;

  useEffect(() => {
    getStaffMembers();
  }, []);

  const getStaffMembers = () => {
    axios
      .get(
        `club/get_staff_members?id_club=${props.id_club}&token=${props.token}`
      )
      .then((res) => {
        setStaffMembers(res.data);
        setDataFetched(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="card card-custom">
      <div className="staff-members-container">
        <div className="staff-members-header">
          <div className="staff-members-title">
            {languagePack["staff_members"]}
          </div>
          <div className="staff-members-buttons">
            <Button
              className="members-list-button dark-background"
              onClick={() => setListDialog(true)}
            >
              {languagePack["roles_list"]}
            </Button>
            <Button
              className="members-list-button"
              onClick={() => setDialog(true)}
            >
              {languagePack["add_member"]}
              <Icon name="add" className="add-member-button-icon" />
            </Button>
          </div>
        </div>
        <div className="staff-members-list">
          <div className="staff-member-row staff-members-table-columns">
            <div className="staff-member-name">
              {languagePack["staff_member_name"]}
            </div>
            <div className="staff-member-role">
              {languagePack["staff_member_role"]}
            </div>
            <div className="staff-member-role">
              {languagePack.staff_license}
            </div>
            <div className="staff-member-see-more"></div>
          </div>
          {!dataFetched ? (
            <div className="loader-container" style={{ marginTop: 50 }}>
              <Loader
                type="TailSpin"
                color={window.clubCustomization.primary_color}
                height={25}
                width={25}
              />
            </div>
          ) : (
            <>
              {staffMembers.map((item, index) => {
                return (
                  <div
                    className="staff-member-row"
                    style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "" }}
                    key={"staff-member-" + item.id_staff_member}
                  >
                    <div className="staff-member-name">
                      <Avatar
                        src={`${window.baseURL}uploads/staff_members/${item.id_staff_member}.jpg`}
                      />
                      <div
                        onClick={() =>
                          props.history.push(
                            // `/club/player/${player.id_player}?id_season=${this.state.id_season}`
                            `/club/staff/${item.id_staff_member}`
                          )
                        }
                        style={{
                          marginLeft: 10,
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                      >
                        {item.full_name}
                      </div>
                    </div>
                    <div className="staff-member-role">{item.user_role}</div>
                    <div className="staff-member-license">
                      {item.license != "Nicio licență" && item.license != null
                        ? item.license
                        : "-"}
                    </div>

                    <div className="staff-member-see-more">
                      <Button
                        className="member-see-more-button"
                        // onClick={() => setEditStaffItem(item)}
                        onClick={() =>
                          props.history.push(
                            // `/club/player/${player.id_player}?id_season=${this.state.id_season}`
                            `/club/staff/${item.id_staff_member}`
                          )
                        }
                      >
                        <EyeIcon />
                      </Button>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>

      <Dialog
        open={dialog}
        // onClose={() => setDialog(false)}
      >
        <DialogContent className="add-staff-wrapper">
          <AddStaffForm
            closePopup={() => setDialog(false)}
            id_club={props.id_club}
            refreshMembers={() => getStaffMembers()}
            languagePack={languagePack}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={listDialog} onClose={() => setListDialog(false)}>
        <DialogContent className="roles-list-wrapper">
          <RolesList
            closePopup={() => setListDialog(false)}
            id_club={props.id_club}
            languagePack={languagePack}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={Object.keys(editStaffItem).length > 0}
        // onClose={() => setEditStaffItem({})}
        maxWidth="xl"
      >
        <DialogContent>
          <EditStaffDetails
            closePopup={() => setEditStaffItem({})}
            refreshData={getStaffMembers}
            item={editStaffItem}
            languagePack={languagePack}
            club={props.id_club}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
}))(StaffMembers);
