import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../_core/MetronicLayout";

import Aside from "./aside/Aside";
import { LayoutInit } from "./LayoutInit";
import SubHeader from "./subheader/SubHeader";
import { QuickPanel } from "./extras/offcanvas/QuickPanel";
import { QuickUser } from "./extras/offcanvas/QuickUser";
import { QuickSearch } from "./extras/offcanvas/QuickSearch";
import { QuickNotification } from "./extras/offcanvas/QuickNotification";
import { QuickActions } from "./extras/offcanvas/QuickActions";
import { ScrollTop } from "./extras/ScrollTop";
import { AnimateLoading } from "../../_partials/controls";
import { useSelector } from "react-redux";
import { LayoutSplashScreen } from "../_core/MetronicSplashScreen";
import { useState } from 'react';
import TFBIcon from "../../../app/components/design-system/Icon/TFBIcon";
import TFBIconButton from "../../../app/components/design-system/IconButton/TFBIconButton";

export function Layout({ children }) {
  const uiService = useHtmlClassService();
  const {
    loader
  } = useSelector(({ layoutService }) => ({
    loader: layoutService.loader
  }));

  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      selfLayout: objectPath.get(uiService.config, "self.layout"),
      subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
      contentCssClasses: uiService.getClasses("content", true),
      contentContainerClasses: uiService.getClasses("content_container", true),
      contentExtended: objectPath.get(uiService.config, "content.extended"),
    };
  }, [uiService]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);


  const openMenu = () => {
    // console.log("click");
    let body = document.getElementById("kt_body");
    // body.classList.toggle('aside-minimize');
    body.classList.add('aside-minimize');
    setIsMenuOpen(true);
  };
  const closeMenu = () => {
    // console.log("click");
    let body = document.getElementById("kt_body");
    // body.classList.toggle('aside-minimize');
    body.classList.toggle('aside-minimize');
    setIsMenuOpen(false);
  };

  return layoutProps.selfLayout !== "blank" ? (
    <>
      {loader.length > 0 && <LayoutSplashScreen />}
      {/*begin::Main*/}

      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {isMenuOpen ? null : <Aside />}

          {/*begin::Wrapper*/}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            {/*begin::Content*/}
            <div
              id="kt_header"
              className={`header`}
            >
              <TFBIconButton
                id="close-mobile"
                name="x-small"
                color="green"
                className="kt_aside_toggle aside-toggle"
                onClick={closeMenu}
              />

              <div
                id="kt_header_container"
                className={`container-xxl d-flex align-items-center justify-content-between`}
              >
                <div
                  className={`d-flex d-lg-none align-items-center ms-n2 me-2`}
                >
                  <div
                    id="kt_aside_toggle"
                    className={`btn btn-icon btn-active-icon-primary`}
                  >

                    <span
                      className={`svg-icon svg-icon-1`}
                      onClick={closeMenu}
                    >
                      <TFBIcon name="hamburger-menu" color="black" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
            >
              <AnimateLoading />
              {layoutProps.subheaderDisplay && <SubHeader />}

              {/*begin::Entry*/}
              {layoutProps.contentExtended && <>{children}</>}

              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid">
                  {/*begin::Container*/}
                  <div className={layoutProps.contentContainerClasses}>
                    {children}
                  </div>
                  {/*end::Container*/}
                </div>
              )}

              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            {/* <Footer /> */}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      <QuickUser />
      <QuickPanel />
      <QuickNotification />
      <ScrollTop />
      <QuickSearch />
      <QuickActions />
      {/* <StickyToolbar /> */}
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}
