export const _bonusCurrency = {
    EUR: "Euro",
    RON: "Ron",
};

export const _addBonusPopupSteps = [
    {
        key: "_addBonusPopupSteps - Selecteaza tipul bonusului",
        value: "Tip bonus",
        translation: "Select_bonus_type",
    },
    {
        key: "_addBonusPopupSteps - Criterii acordare",
        value: "Criterii",
        translation: "Assignment_criterias",
    },
    { key: "_addBonusPopupSteps - Adauga", value: "Adauga", translation: "Add" },
];

export const bonus_receiver = [
    {
        key: 1,
        text: "Toti jucatorii",
        value: "Toti jucatorii",
        translation: "All_players",
    },
    {
        key: 2,
        text: "Jucatori titulari",
        value: "Jucatori titulari",
        translation: "Player_holders",
    },
    {
        key: 3,
        text: "Jucatori & Staff",
        value: "Jucatori & Staff",
        translation: "Player_and_Staff",
    },
    {
        key: 4,
        text: "Staff",
        value: "Staff",
        translation: "Staff_bonus",
    },
];

export const competitions = [
    { key: "L1", text: "Liga 1", value: "Liga 1", translation: "First_league" },
    {
        key: "CR",
        text: "Cupa Romaniei",
        value: "Cupa Romaniei",
        translation: "Romanian_Cup",
    },
    {
        key: "SCR",
        text: "Supercupa Romaniei",
        value: "Supercupa Romaniei",
        translation: "Romanian_supercup",
    },
    {
        key: "ALL",
        text: "Toate competitiile",
        value: "Toate competitiile",
        translation: "All_competitions",
    },
];

export const bonus_criterias_match = [
    {
        key: "win",
        text: "Rezultat final: Victorie",
        value: "Rezultat final: Victorie",
        translation: "Final_result_win",
    },
    {
        key: "draw",
        text: "Rezultat final: Egalitate",
        value: "Rezultat final: Egalitate",
        translation: "Final_result_draw",
    },
    {
        key: "goal",
        text: "Gol marcat",
        value: "Gol marcat",
        translation: "Goal_scored",
    },
    {
        key: "other",
        text: "Alt criteriu",
        value: "Alt criteriu",
        translation: "Other_crieteria",
    },
];

export const bonus_criterias_season = [
    {
        key: "Position",
        text: "Clasare in primele ... locuri",
        value: "Clasare in primele ... locuri",
        translation: "Bonus_position",
    },
    {
        key: "playoff",
        text: "Calificare in play-off",
        value: "Calificare in play-off",
        translation: "Qualification_in_the_play_off",
    },
    {
        key: "eurocup",
        text: "Clasare pe loc de cupe Europene",
        value: "Clasare pe loc de cupe Europene",
        translation: "Ranking_for_european_cup",
    },
    {
        key: "champscup",
        text: "Clasare pe loc de UEFA Champions League",
        value: "Clasare pe loc de UEFA Champions League",
        translation: "Ranking_for_uefa_champions_league",
    },
    {
        key: "cup",
        text: "Calificare in etapa Cupei Romaniei",
        value: "Calificare in etapa Cupei Romaniei",
        translation: "Qualification_in_the_romanian_cup",
    },
];

export const matches = [
    { key: 123, text: "UTA Arad-Poli Iasi", value: "UTA Arad-Poli Iasi" },
    { key: 234, text: "Poli Iasi-FCSB", value: "Poli Iasi-FCSB" },
    { key: 356, text: "CSU Craiova-Poli Iasi", value: "CSU Craiova-Poli Iasi" },
    {
        key: 442,
        text: "Poli Iasi- Astra Giurgiu",
        value: "Poli Iasi- Astra Giurgiu",
    },
];

export const cup_phases = [
    {
        key: "Final",
        text: "Finala",
        value: "Finala",
        translation: "Competiion_final",
    },
    {
        key: "Semifinal",
        text: "Semifinala",
        value: "Semifinala",
        translation: "Competiion_semi_final",
    },
    {
        key: "Quarter-final",
        text: "Sferturi de finala",
        value: "Sferturi de finala",
        translation: "Competiion_quarters_final",
    },
    {
        key: "Last 16",
        text: "Optimi de finala",
        value: "Optimi de finala",
        translation: "Competiion_eighths_final",
    },
    {
        key: "Last 32",
        text: "Saispezecimi",
        value: "Saispezecimi",
        translation: "Competiion_sixteeths_final",
    },
];

export const seasons = [
    { key: "20/21", value: "2020/2021", text: "2020/2021" },
    { key: "21/22", value: "2021/2022", text: "2021/2022" },
    { key: "22/23", value: "2022/2023", text: "2022/2023" },
];