import React from "react";
import axios from "axios";
import { Input, Button, Icon, Dropdown } from "semantic-ui-react";
import moment, { lang } from "moment";
import { store } from "react-notifications-component";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logout } from "./../../../utils/api";

class AddNewProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      currentSold: "",
      clients: [],
      selectedClient: "",
      currency: "EUR",
    };
  }
  componentDidMount = () => {
    this.getClientsDropdown();
  };

  getClientsDropdown = () => {
    axios
      .get(`finances/get_clients_dropdown?id_club=${this.props.id_club}`)
      .then((res) => this.setState({ clients: res.data }))
      .catch((err) => console.log(err));
  };

  addProvider = () => {
    const payload = {
      partner_name: this.state.selectedClient === "" ? this.state.name : "",
      partner_id: this.state.selectedClient,
      current_sold: this.state.currentSold,
      last_month: moment().format("MM") - 1,
      year: moment().format("YYYY"),
      type: "C",
      id_club: this.props.id_club,
      currency: this.state.currency,
    };
    axios.post("finances/add_new_partner", payload).then(() => {
      this.props.closePopup();
      this.props.refreshList();
      store.addNotification({
        title: this.props.languagePack['supplier_operations'],
        message: this.props.languagePack['supplier_success'],
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
    });
  };

  render() {
    const lastMonth = moment().format("MM") - 1;
    const { languagePack } = this.props;
    return (
      <div className="add-new-provider-container">
        <div className="form-row-add-provider">
          <Input
            placeholder={languagePack['add_name'] + ' ...'}
            fluid
            disabled={this.state.selectedClient !== ""}
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
        </div>
        <div className="or-row">{languagePack['or_lowercase']}</div>
        <div className="form-row-add-provider">
          <Dropdown
            placeholder={languagePack['select_existing_partners']}
            fluid
            options={this.state.clients}
            selection
            search
            value={this.state.selectedClient}
            onChange={(e, { value }) =>
              this.setState({ selectedClient: value })
            }
            clearable
          />
        </div>
        <div className="form-row-add-provider sb-row">
          <Input
            placeholder={languagePack['balance_at_end_month'] + ' ' + languagePack['month_' + lastMonth]}
            value={this.state.currentSold}
            onChange={(e) => this.setState({ currentSold: e.target.value })}
            type="number"
            style={{ width: "70%" }}
          />
          <div className="currency-selector">
            <div
              className="currency"
              style={{
                backgroundColor: this.state.currency === "EUR" ? "#b3b3b3" : "",
              }}
              onClick={() => this.setState({ currency: "EUR" })}
            >
              EUR
            </div>
            <div
              className="currency"
              style={{
                backgroundColor: this.state.currency === "RON" ? "#b3b3b3" : "",
              }}
              onClick={() => this.setState({ currency: "RON" })}
            >
              RON
            </div>
          </div>
        </div>

        <div className="buttons-container-add-provider">
          <Button
            className="add-provider-cancel-button"
            onClick={() => this.props.closePopup()}
          >
            {" "}
            <div className="button-content">
              {languagePack['admin_cancel']}
              <Icon name="x" className="op-cancel-icon" />
            </div>
          </Button>
          <Button
            className="add-provider-save-button"
            onClick={this.addProvider}
            disabled={this.state.name === "" || this.state.currentSold === ""}
          >
            {" "}
            <div className="button-content">
              {languagePack['admin_save']}
              <Icon name="add" className="add-icon" />
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(AddNewProvider);
