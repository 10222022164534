import React from "react";
import "./PlayersCosts.scss";
import Accomodation from "./Accomodation";
import { connect } from "react-redux";

class PlayersCosts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="player-costs-container">
        <div className="col-lg-6 col-xxl-6">
          <Accomodation id_club={this.props.currentUser.id_club} />
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(PlayersCosts);
