import React from "react";
import axios from "axios";
import Moment from "react-moment";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import "./AccountTransactions.scss";
import { Dropdown, Pagination, Icon, Input, Button } from "semantic-ui-react";
import AccountMonthly from "../../../components/charts/Financial/AccountMonthly";
import AccountBalance from "../../../components/charts/Financial/AccountBalance";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DatePickerComp from "../../../components/reusable/DateRange";
import dateFormat from "dateformat";

const options = [
  { key: 1, text: "Toate", value: "all" },
  { key: 2, text: "Incasari", value: "income" },
  { key: 3, text: "Cheltuieli", value: "outcome" },
  { key: 4, text: "Salarii jucatori", value: "Salariu jucator" },
  { key: 5, text: "Comisioane bancare", value: "Comision bancar" },
  { key: 6, text: "Contracte sponsorizare", value: "Contract sponsorizare" },
];

const ppp_options = [
  { key: 1, text: 10, value: 10 },
  { key: 2, text: 20, value: 20 },
  { key: 3, text: 50, value: 50 },
  { key: 4, text: 100, value: 100 },
  { key: 5, text: 500, value: 500 },
];

const periodOptions = [
  { key: 1, text: "Ultimele 7 zile", value: "last_week" },
  { key: 2, text: "Ultima luna", value: "last_month" },
  { key: 3, text: "Afiseaza toate", value: "all" },
];

class AccountTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      balance_value: 0,
      balance_currency: "",
      displayOption: "",
      page: 1,
      total_pages: 0,
      sortConfig: {
        type: "date",
        descending: true,
      },
      prod_per_page: 20,
      total_income: 0,
      total_outcome: 0,
      searchbar_input: "",

      dateFilterPopup: false,
      filter_date_start: "",
      filter_date_end: "",
    };
  }

  componentDidMount() {
    this.getTransactions();
  }

  getTransactions() {
    const sortDir = this.state.sortConfig.descending ? "desc" : "asc";
    const sortType = this.state.sortConfig.type;
    axios
      .get(
        `thinkout/get_transactions?
        display_option=${this.state.displayOption}&
        date_start=${
          this.state.filter_date_start !== ""
            ? dateFormat(this.state.filter_date_start, "yyyy-mm-dd")
            : ""
        }&
        date_end=${
          this.state.filter_date_end !== ""
            ? dateFormat(this.state.filter_date_end, "yyyy-mm-dd")
            : ""
        }&
        page=${this.state.page}&
        sort_direction=${sortDir}&
        sort_type=${sortType}&
        prod_per_page=${this.state.prod_per_page}&
        search=${this.state.searchbar_input}&
        id_club=${this.props.currentUser.id_club}
        `
      )
      .then((res) =>
        this.setState({
          transactions: res.data.transactions,
          balance_value: res.data.balance_value,
          balance_currency: res.data.balance_currency,
          total_pages: res.data.total_pages,
          total_income: res.data.total_income,
          total_outcome: res.data.total_outcome,
        })
      )
      .catch((err) => console.log(err));
  }

  formatNumber = (value) => {
    return (
      <NumberFormat
        value={value}
        displayType={"text"}
        thousandSeparator={true}
        decimalScale={2}
      />
    );
  };

  handleChangeDropdown = (e, value) => {
    this.setState(
      {
        [value.name]: value.value,
        page: 1,
      },
      this.getTransactions
    );
  };

  handlePaginationChange = (e, data) => {
    this.setState({ page: data.activePage }, this.getTransactions);
  };

  changeFilter = (type) => {
    if (this.state.sortConfig.type !== type) {
      this.setState(
        { sortConfig: { type: type, descending: false } },
        this.getTransactions
      );
    } else {
      this.setState(
        {
          sortConfig: {
            type: type,
            descending: !this.state.sortConfig.descending,
          },
        },
        this.getTransactions
      );
    }
  };

  handleSearchbarChange = (e) => {
    this.setState({ searchbar_input: e.target.value }, this.getTransactions);
  };

  render() {
    const { languagePack } = this.props;
    return (
      <>
        <div className="row" style={{ marginBottom: 20 }}>
          <div className="col-lg-6">
            <div className="card card-custom">
              <AccountBalance />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="card card-custom">
              <AccountMonthly />
            </div>
          </div>
        </div>
        <div className="card card-custom">
          <div className="account-transactions-container">
            <div
              className="account-transactions-header"
              style={{ marginBottom: 20 }}
            >
              <div className="account-transactions-title">
                {languagePack["transaction_history"]}
              </div>
              <div className="account-balance">
                {languagePack["current_balance"]}:
                <div className="account-balance-value">
                  {this.formatNumber(this.state.balance_value)}{" "}
                  {this.state.balance_currency}
                </div>
              </div>
            </div>
            <div
              className="account-transactions-header"
              style={{ marginBottom: 40 }}
            >
              <div className="searchbar">
                <Input
                  icon={{ name: "search", circular: true, link: true }}
                  placeholder={languagePack["search"]}
                  className="search-field"
                  onChange={this.handleSearchbarChange}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Dropdown
                  onChange={this.handleChangeDropdown}
                  options={options}
                  placeholder={languagePack["select_type"]}
                  selection
                  value={this.state.displayOption}
                  name="displayOption"
                  style={{ marginLeft: 20 }}
                  className="transactions-type-dropdown"
                />
                {/* <Dropdown
                  onChange={this.handleChangeDropdown}
                  options={periodOptions}
                  placeholder="Selecteaza perioada"
                  selection
                  value={this.state.periodOption}
                  name="periodOption"
                  style={{ marginLeft: 20 }}
                /> */}
                <Button
                  className="filter-interval-date"
                  onClick={() => this.setState({ dateFilterPopup: true })}
                  style={{ backgroundColor: "#666666" }}
                >
                  <div>
                    {languagePack["select_interval"]}
                    {this.state.filter_date_start !== "" &&
                      this.state.filter_date_end !== "" && (
                        <div className="interval">
                          <Moment format="DD.MM">
                            {this.state.filter_date_start}
                          </Moment>
                          -
                          <Moment format="DD.MM">
                            {this.state.filter_date_end}
                          </Moment>
                        </div>
                      )}
                  </div>
                  <Icon
                    name="calendar"
                    size="small"
                    className="filter-interval-icon"
                  />
                </Button>
              </div>
            </div>
            <div className="account-transactions-table">
              <div className="account-transactions-table-header">
                <div className="description-column">
                  {languagePack["transaction_description"]}
                </div>
                <div
                  className="date-column"
                  onClick={() => this.changeFilter("date")}
                  style={{ cursor: "pointer" }}
                >
                  {languagePack["transaction_date"]}
                  {this.state.sortConfig.type === "date" &&
                    (this.state.sortConfig.descending ? (
                      <Icon name="arrow down" />
                    ) : (
                      <Icon name="arrow up" />
                    ))}
                </div>
                <div className="category-column">
                  {languagePack["transaction_type"]}
                </div>
                <div
                  className="amount-column"
                  onClick={() => this.changeFilter("amount")}
                  style={{ cursor: "pointer" }}
                >
                  {languagePack["trasnaction_amount"]}
                  {this.state.sortConfig.type === "amount" &&
                    (this.state.sortConfig.descending ? (
                      <Icon name="arrow down" />
                    ) : (
                      <Icon name="arrow up" />
                    ))}
                </div>
              </div>
              <div className="account-transactions-list">
                {this.state.transactions.map((item, index) => {
                  return (
                    <div
                      className="account-transaction-row"
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                      }}
                    >
                      <div className="description-column">
                        <div>
                          {item.other_account_name !== ""
                            ? item.other_account_name
                            : item.transaction_description}
                        </div>
                        {item.other_account_name !== "" && (
                          <div className="transaction-description">
                            {item.transaction_description}
                          </div>
                        )}
                      </div>
                      <div className="date-column">
                        <Moment format="DD.MM.YYYY">
                          {item.transaction_date}
                        </Moment>
                      </div>
                      <div className="category-column">
                        {item.transaction_type_detailed !== ""
                          ? item.transaction_type_detailed
                          : item.transaction_type === "income"
                          ? "Incasare"
                          : "Plata"}
                      </div>
                      <div className="amount-column">
                        {item.transaction_type === "income" ? (
                          <div className="income">
                            +{this.formatNumber(item.transaction_amount)} RON
                          </div>
                        ) : (
                          <div className="outcome">
                            {this.formatNumber(item.transaction_amount)} RON
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="pagination-container">
                <div className="dropdown-ppp">
                  <div className="dropdown-label">
                    {languagePack["items_on_page"]}:
                  </div>
                  <Dropdown
                    onChange={this.handleChangeDropdown}
                    options={ppp_options}
                    selection
                    value={this.state.prod_per_page}
                    name="prod_per_page"
                    style={{ width: 100, minWidth: 100 }}
                  />
                </div>

                <Pagination
                  activePage={this.state.page}
                  onPageChange={this.handlePaginationChange}
                  totalPages={this.state.total_pages}
                />
                <div className="total">
                  <div
                    className="total-row"
                    style={{ color: window.clubCustomization.primary_color }}
                  >
                    {languagePack["total_income"]}:{" "}
                    {this.formatNumber(this.state.total_income)} RON
                  </div>
                  <div className="total-row" style={{ color: "red" }}>
                    {languagePack["total_expense"]}:{" "}
                    {this.formatNumber(Math.abs(this.state.total_outcome))} RON
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={this.state.dateFilterPopup}
          // onClose={() => this.setState({ dateFilterPopup: false })}
        >
          <DialogContent>
            <div className="date-picker-transactions-title">
              Selecteaza perioada
            </div>
            <div className="date-picker-transactions-container">
              <DatePickerComp
                setDate={(value) =>
                  this.setState({
                    filter_date_start: value.startDate,
                    filter_date_end: value.endDate,
                  })
                }
              />
            </div>
            <div className="date-filter-buttons-row">
              <Button
                className="filter-interval-date"
                onClick={() =>
                  this.setState({
                    dateFilterPopup: false,
                    filter_date_start: "",
                    filter_date_end: "",
                  })
                }
                style={{ backgroundColor: "#666666" }}
              >
                Anuleaza
                <Icon
                  name="ban"
                  size="small"
                  className="filter-interval-icon"
                />
              </Button>
              <Button
                className="filter-interval-date"
                style={{ backgroundColor: "#00d948" }}
                onClick={() => {
                  this.getTransactions();
                  this.setState({ dateFilterPopup: false });
                }}
              >
                Salveaza
                <Icon
                  name="check"
                  size="small"
                  className="filter-interval-icon"
                />
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(AccountTransactions);
