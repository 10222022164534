export const rootPages = {
  DASHBOARD: "dashboard",
  CLUB: "club",
  REPORTS: "reports",
  FINANCIAL: "financial",
  DOCUMENTS: "documents",
  SCOUTING: "scouting",
  MEDICAL: "medical",
  FANS: "fans",
  SITE: "site",
  TRAINING: "training",
  VIDEOGALLERY: "video-gallery",
  SHOP: "shop",
  APPLICATION: "application",
  USERS_MANAGEMENT: "users-management",
};

export const asideDisplayRootPage = {
  [rootPages.DASHBOARD]: false,
  [rootPages.CLUB]: true,
  [rootPages.REPORTS]: true,
  [rootPages.FINANCIAL]: true,
  [rootPages.DOCUMENTS]: true,
  [rootPages.SCOUTING]: true,
  [rootPages.MEDICAL]: true,
  [rootPages.FANS]: true,
  [rootPages.SITE]: true,
  [rootPages.TRAINING]: true,
  [rootPages.VIDEOGALLERY]: true,
};

export const currencies = {
  EUR: {
    translation: "EUR",
    uniqueSlug: "EUR",
    bonusUniqueSlug: "Euro",
    flag: "eu",
    iconName: "eur",
    key: "EUR",
    value: "EUR",
    text: "EURO",
  },
  RON: {
    translation: "RON",
    uniqueSlug: "RON",
    bonusUniqueSlug: "Ron",
    flag: "ro",
    key: "RON",
    value: "RON",
    text: "RON",
  },
  // ARS: {
  //   translation: "ARS",
  //   uniqueSlug: "ARS",
  //   bonusUniqueSlug: "Ars",
  //   flag: "ar",
  //   key: "ARS",
  //   value: "ARS",
  //   text: "ARS",
  // },
};
