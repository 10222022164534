import React from "react";
import Select from "react-select";
import { TSelect } from "../types";
import styles from "./TFBSelect.module.scss";

const TFBSelect = (props: TSelect) => {
  const { className, style, ...otherProps } = props;
  const classN = className ? className : "";

  return props.label ? (
    <div
      className={`${styles.selectGroup} custom-select-wrapper`}
      style={style?.component ?? {}}
    >
      {<h6 className={styles.selectLabel}>{props.label}</h6>}
      <Select
        className={`${styles.tfbSelect} ${classN}`}
        {...otherProps}
        menuPortalTarget={document.body}
        styles={{
          container: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: "#fafafa",
            width: "100%",
            ...style?.container,
          }),
          control: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: "#fafafa",
            minHeight: "50px",
            border: "none",
            "&:hover": {
              border: "none",
              boxShadow: "0 0 0 2px black",
            },
            ...style?.control,
          }),
          valueContainer: (provided: any, state: any) => ({
            ...provided,
            paddingLeft: "22px",
            fontSize: "14px",
            fontWeight: 700,
            ...style?.valueContainer,
          }),
          placeholder: (provided: any, state: any) => ({
            ...provided,
            color: "#b2b2b2",
            ...style?.placeholder,
          }),
          indicatorSeparator: (provided: any, state: any) => ({
            ...provided,
            display: "none",
            ...style?.indicatorSeparator,
          }),
          dropdownIndicator: (provided: any, state: any) => ({
            ...provided,
            ...style?.dropdownIndicator,
          }),
          menu: (provided: any, state: any) => ({
            ...provided,
            maxHeight: "350px",
          }),
          menuPortal: (provided: any, state: any) => ({
            ...provided,
            zIndex: 9999,
          }),
        }}
        menuPlacement="auto"
      />
    </div>
  ) : (
    <Select
      className={`${styles.tfbSelect} ${classN}`}
      {...otherProps}
      menuPortalTarget={document.body}
      styles={{
        container: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: "#fafafa",
          width: "100%",
          ...style?.container,
        }),
        control: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: "#fafafa",
          minHeight: "50px",
          border: "none",
          "&:hover": {
            border: "none",
            boxShadow: "0 0 0 2px black",
          },
          ...style?.control,
        }),
        valueContainer: (provided: any, state: any) => ({
          ...provided,
          paddingLeft: "22px",
          fontSize: "14px",
          fontWeight: 700,
          ...style?.valueContainer,
        }),
        placeholder: (provided: any, state: any) => ({
          ...provided,
          color: "#b2b2b2",
          ...style?.placeholder,
        }),
        indicatorSeparator: (provided: any, state: any) => ({
          ...provided,
          display: "none",
          ...style?.indicatorSeparator,
        }),
        dropdownIndicator: (provided: any, state: any) => ({
          ...provided,
          ...style?.dropdownIndicator,
        }),
        menu: (provided: any, state: any) => ({
          ...provided,
          maxHeight: "350px",
        }),
        menuPortal: (provided: any, state: any) => ({
          ...provided,
          zIndex: 9999,
        }),
      }}
      menuPlacement="auto"
    />
  );
};

export default TFBSelect;
