import React from "react";
import LastDirectMatch from "./LastDirectMatch";
import LastTrainings from "./LastTrainings";
import MatchBasicDetails from "../../../../components/data-providers/football-api/match/MatchBasicDetails";
import "./PrepareNextMatch.scss";
import UnavailablePlayers from "./UnavailablePlayer";
import LastMatches from "./LastMatches";

class PrepareNextMatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="prepare-next-match-wrapper">
        <div className="row">
          <div className="col-lg-6">
            <MatchBasicDetails id_match={this.props.match.params.id} />
            <div className="card card-custom" style={{ marginTop: 25 }}>
              <div className="prepare-next-match-container">
                <div className="prepare-next-match-title">
                  Ultimele antrenamente
                </div>
                <LastTrainings />
              </div>
            </div>

            <div className="card card-custom" style={{ marginTop: 25 }}>
              <div className="prepare-next-match-container">
                <div className="prepare-next-match-title">
                  Jucatori indisponibili
                </div>
                <UnavailablePlayers />
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="card card-custom">
              <div className="prepare-next-match-container">
                <div className="prepare-next-match-title">
                  Ultima confruntare directa
                </div>
                <LastDirectMatch id_match={this.props.match.params.id} />
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: 25 }}>
          <div className="col-lg-12">
            <LastMatches
              history={this.props.history}
              id_match={this.props.match.params.id}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PrepareNextMatch;
