import React from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Input } from "semantic-ui-react";

import Avatar from "@material-ui/core/Avatar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import "./Receipt.scss";
import { logout } from "./../../../utils/api";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton.tsx";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import { TFBTableCell, sxStickyColumn } from "../../../components/reusable/useStyles";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";

class Receipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      receipts: [],

      open_edit_dialog: false,
      editing_receipt: {},

      open_delete_dialog: false,
      deleting_receipt: {},
    };
  }

  componentDidMount = () => {
    this.getReceipts();
  };

  getReceipts = () => {
    axios
      .get(
        `finances/get_receipts_list?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => this.setState({ receipts: res.data }))
      .catch((err) => console.log(err));
  };

  openEditDialog = (data) => {
    this.setState({
      open_edit_dialog: true,
      editing_receipt: data,
    })
  }

  closeEditDialog = () => {
    this.setState({
      open_edit_dialog: false,
    }, () => {
      this.setState({
        editing_receipt: {},
      })
    })
  }

  changeReceiptSerial = (value) => {
    if (value.length > 3)
      return false;

    this.setState({
      editing_receipt: { ...this.state.editing_receipt, ...{ receipt_series: value } }
    });
  };

  handleDateChange = (value) => {
    this.setState({
      editing_receipt: { ...this.state.editing_receipt, ...{ receipt_date: value } }
    });
  };

  openDeleteDialog = (data) => {
    this.setState({
      open_delete_dialog: true,
      deleting_receipt: data,
    })
  }

  closeDeleteDialog = () => {
    this.setState({
      open_delete_dialog: false,
      deleting_receipt: {},
    })
  }

  updateReceipt = () => {
    const payload = {
      id_receipt: this.state.editing_receipt.id_receipt,
      receipt_series: this.state.editing_receipt.receipt_series,
      nr_receipt: this.state.editing_receipt.nr_receipt,
      receipt_date: this.state.editing_receipt.receipt_date,
      paid_by: this.state.editing_receipt.paid_by,
      receipt_description: this.state.editing_receipt.receipt_description,
    };

    axios
      .post("finances/update_receipt", payload)
      .then(res => {
        if (res.status == 200) {
          this.getReceipts();
          store.addNotification({
            title: this.props.languagePack.receipts,
            message: this.props.languagePack.success_save_receipt,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: this.props.languagePack.receipts,
            message: this.props.languagePack.fail_save_receipt,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        this.closeEditDialog();
      })
      .catch(e => console.log(e))
  }

  deleteReceipt = () => {
    const payload = {
      id_receipt: this.state.deleting_receipt.id_receipt,
    };

    axios
      .post("finances/delete_receipt", payload)
      .then(res => {
        if (res.data.success == 1) {
          this.getReceipts();
          store.addNotification({
            title: this.props.languagePack.receipts,
            message: this.props.languagePack.success_delete_receipt,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: this.props.languagePack.receipts,
            message: this.props.languagePack.fail_delete_receipt,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        this.closeDeleteDialog();
      })
      .catch(e => console.log(e))
  }

  render() {
    const { languagePack } = this.props;

    return (
      <div className="receipt-container">
        <TFBCard>
          <TFBCardHeader title={languagePack.receipts}></TFBCardHeader>
          <TFBCardBody>
            {this.state.receipts.length > 0 ? (
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TFBTableCell sx={sxStickyColumn}>
                        {languagePack.player}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.team}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.payer}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.series}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.num_and_date_receipt}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.category_cost_sum}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.payment_month}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.product_actions}
                      </TFBTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.receipts.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                      >
                        <TFBTableCell
                          align="center"
                          sx={sxStickyColumn}
                          style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                        >
                          <div className="receipt-avatar-player-name">
                            <Avatar
                              className="player-avatar"
                              style={{ cursor: "pointer" }}
                              src={row.img_url}
                              onClick={() =>
                                this.props.history.push("/club/player/" + row.id_player)
                              }
                            />
                            <span
                              className="cursor-pointer"
                              onClick={() =>
                                this.props.history.push("/club/player/" + row.id_player)
                              }
                            >
                              {row.player_name}
                            </span>
                          </div>
                        </TFBTableCell>
                        <TFBTableCell align="center">{row.team}</TFBTableCell>
                        <TFBTableCell align="center">
                          {row.paid_by}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {row.receipt_series}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {row.nr_receipt + " / " + moment(row.receipt_date).format("DD.MM.YYYY")}
                        </TFBTableCell>
                        <TFBTableCell align="center">{row.amount + " RON"}</TFBTableCell>
                        <TFBTableCell align="center">
                          {row.month_year}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          <div className="actions-btns-container">
                            <a
                              href={`https://api.thefootballbrain.app/finances/download_receipt?id_receipt=${row.id_receipt}&token=${this.props.token}`}
                            >
                              <TFBIconButton
                                name="arrow-down"
                                color="black"
                              />
                            </a>
                            <TFBIconButton
                              onClick={() => this.openEditDialog(row)}
                              name="edit"
                              color="darkGray"
                            />
                            <TFBIconButton
                              onClick={() => this.openDeleteDialog(row)}
                              name="delete"
                              color="darkGray"
                            />
                          </div>
                        </TFBTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <TFBPlaceholder text={languagePack.no_data_receipt} />
            )}
          </TFBCardBody>
        </TFBCard>

        {/* ----------------- EDIT RECEIPT ----------------*/}
        <TFBDialog
          className="pop-up-dialog-box"
          title={languagePack.edit_receipt}
          open={this.state.open_edit_dialog}
          closePopup={this.closeEditDialog}
          btnLeft={() => (
            <TFBButton
              color="darkGray"
              renderIcon={() => <TFBIcon name="cancel" />}
              onClick={this.closeEditDialog}
            >
              {languagePack.admin_cancel}
            </TFBButton>
          )}
          btnRight={() => (
            <TFBButton
              color="green"
              renderIcon={() => <TFBIcon name="check" />}
              disabled={this.state.editing_receipt?.receipt_series == "" || this.state.editing_receipt?.paid_by == "" || this.state.editing_receipt?.receipt_description == ""}
              onClick={this.updateReceipt}
            >
              {languagePack.admin_save}
            </TFBButton>
          )}
        >
          <div className="add-payment-row">
            <Input
              placeholder={languagePack.series}
              onKeyPress={(event) => {
                if (!/\b[a-zA-Z]\b/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              className="add-payment-receipt-serial"
              name="receipt_serial"
              onChange={(e) => {
                this.changeReceiptSerial(e.target.value);
              }}
              value={this.state.editing_receipt?.receipt_series ?? ""}
            />
          </div>
          <div className="add-payment-row">
            <input
              placeholder={languagePack.receipt_number}
              className="input-text"
              type="number"
              value={this.state.editing_receipt?.nr_receipt ?? ""}
              onChange={(e) => {
                this.setState({
                  editing_receipt: { ...this.state.editing_receipt, ...{ nr_receipt: e.target.value } }
                });
              }}
            />
          </div>
          <div className="add-payment-row">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="add-payment-row">
                <DatePicker
                  value={this.state.editing_receipt?.receipt_date ?? null}
                  onChange={this.handleDateChange}
                  ampm={false}
                  format="dd.MM.yyyy"
                  name="payment_date"
                  inputVariant="outlined"
                  className="date-input"
                  emptyLabel={languagePack.issue_date}
                />
                <TFBIcon
                  name="calendar"
                  color="gray"
                  className="calendar-icon"
                />
              </div>
            </MuiPickersUtilsProvider>
          </div>
          <div className="add-payment-row">
            <input
              className="input-text"
              type="text"
              value={this.state.editing_receipt?.paid_by ?? ""}
              onChange={(e) => {
                this.setState({
                  editing_receipt: { ...this.state.editing_receipt, ...{ paid_by: e.target.value } }
                });
              }}
              placeholder={languagePack.payer_name}
            />
          </div>
          <div className="add-payment-row" style={{ height: "auto" }}>
            <textarea
              className="input-text"
              rows={3}
              type="text"
              value={this.state.editing_receipt?.receipt_description ?? ""}
              onChange={(e) => {
                this.setState({
                  editing_receipt: { ...this.state.editing_receipt, ...{ receipt_description: e.target.value } }
                });
              }}
              placeholder={languagePack.receipt_description}
            />
          </div>
        </TFBDialog>

        {/* ----------------- DELETE RECEIPT ----------------*/}
        <TFBDialog
          className="pop-up-dialog-box"
          title={languagePack.delete_receipt}
          open={this.state.open_delete_dialog}
          closePopup={this.closeDeleteDialog}
          btnLeft={() => (
            <TFBButton
              color="darkGray"
              renderIcon={() => <TFBIcon name="cancel" />}
              onClick={this.closeDeleteDialog}
            >
              {languagePack.admin_cancel}
            </TFBButton>
          )}
          btnRight={() => (
            <TFBButton
              color="red"
              renderIcon={() => <TFBIcon name="delete" />}
              onClick={this.deleteReceipt}
            >
              {languagePack.admin_delete}
            </TFBButton>
          )}
        >
          <div className="pop-up-dialog-delete-text">
            {languagePack.delete_receipt_message}
          </div>
        </TFBDialog>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(Receipt);
