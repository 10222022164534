import React from "react";
import "./TrainingSections.scss";
import axios from "axios";
import Moment from "react-moment";
import { Icon, Button } from "semantic-ui-react";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddTrainingSections from "./AddTrainingSections";
import EditTrainingSection from "./EditTrainingSection";
import DeleteTrainingSection from "./DeleteTrainingSection";
import VideoTrainingSection from "./VideoTrainingSection";
import { store } from "react-notifications-component";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton.tsx";
import TFBButton from "../../../components/design-system/Button/TFBButton.tsx";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon.tsx";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../components/reusable/useStyles";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";

class TrainingSections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      training_sections: [],
      add_training_section: false,
      edit_training_section: false,
      delete_training_popup: false,

      video_play_dialog: false,
      video_to_play: "",
      image_view_dialog: false,
      image_to_view: "",

      image_to_delete_popup: false,
      video_to_delete_popup: false,

      id_section_to_delete: "",

      training_section_to_update: "",
    };
    this.inputOpenImageFileRef = React.createRef();
    this.inputOpenVideoFileRef = React.createRef();
    this.playVideoRef = React.createRef();
  }

  componentDidMount = () => {
    this.getTrainingSections();
  };

  getTrainingSections = () => {
    axios
      .get(
        `training/get_training_sections?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => this.setState({ training_sections: res.data }))
      .catch((err) => console.log(err));
  };

  handleAddImageFile = (e) => {
    let currentTrainingSections = this.state.training_sections;
    let updateTrainingScetionIndex = currentTrainingSections.findIndex(
      (elem) => elem.id == this.state.training_section_to_update.id
    );
    currentTrainingSections[updateTrainingScetionIndex].is_uploading_img = true;
    let file = e.target.files[0];
    this.setState({ training_sections: currentTrainingSections }, () => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const payload = {
          id_club: this.props.currentUser.id_club,
          id_training_section: this.state.training_section_to_update.id,
          new_image: reader.result,
        };
        this.inputOpenImageFileRef.current.value = "";
        const options = {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            if (percent < 100) {
              let currentTrainingSections = this.state.training_sections;
              let updateTrainingScetionIndex = currentTrainingSections.findIndex(
                (elem) => elem.id == this.state.training_section_to_update.id
              );
              currentTrainingSections[
                updateTrainingScetionIndex
              ].upload_percentage = percent;
              this.setState({ training_sections: currentTrainingSections });
            }
          },
          params: {
            token: this.props.token,
          },
          headers: {
            "content-type": "application/json",
          },
        };
        axios
          .post("training/add_image_for_training_section", payload, options)
          .then((res) => {
            this.setState({ training_section_to_update: "" });
            if (res.data == 1) {
              this.getTrainingSections();
              store.addNotification({
                title: "Sectiuni antrenamente",
                message: "Imaginea a fost adaugata cu succes!",
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                },
              });
            } else {
              let currentTrainingSections = this.state.training_sections;
              let updateTrainingScetionIndex = currentTrainingSections.findIndex(
                (elem) => elem.id == this.state.training_section_to_update.id
              );
              currentTrainingSections[
                updateTrainingScetionIndex
              ].is_uploading_img = false;
              currentTrainingSections[
                updateTrainingScetionIndex
              ].upload_percentage = 0;
              this.setState({ training_sections: currentTrainingSections });
              store.addNotification({
                title: "Sectiuni antrenamente",
                message: "Imaginea nu a fost adaugata cu succes!",
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                },
              });
            }
          })
          .catch((err) => console.log(err));
      };
    });
  };

  handleAddVideoFile = (e) => {
    let currentTrainingSections = this.state.training_sections;
    let updateTrainingScetionIndex = currentTrainingSections.findIndex(
      (elem) => elem.id == this.state.training_section_to_update.id
    );
    currentTrainingSections[
      updateTrainingScetionIndex
    ].is_uploading_video = true;
    let file = e.target.files[0];
    this.setState({ training_sections: currentTrainingSections }, () => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const fileSize = file.size / 1024 / 1024 / 1024; // in GB
        if (fileSize > 2) {
          let currentTrainingSections = this.state.training_sections;
          let updateTrainingScetionIndex = currentTrainingSections.findIndex(
            (elem) => elem.id == this.state.training_section_to_update.id
          );
          currentTrainingSections[
            updateTrainingScetionIndex
          ].is_uploading_video = false;
          this.setState({ training_sections: currentTrainingSections });
          store.addNotification({
            title: "Sectiuni antrenamente",
            message: "Marimea maxima a unui video trebuie sa fie 2GB!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          const payload = {
            id_club: this.props.currentUser.id_club,
            id_training_section: this.state.training_section_to_update.id,
            new_video: reader.result,
            type: file.type,
          };
          this.inputOpenVideoFileRef.current.value = "";
          const options = {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              let percent = Math.floor((loaded * 100) / total);
              if (percent < 100) {
                let currentTrainingSections = this.state.training_sections;
                let updateTrainingScetionIndex = currentTrainingSections.findIndex(
                  (elem) => elem.id == this.state.training_section_to_update.id
                );
                currentTrainingSections[
                  updateTrainingScetionIndex
                ].upload_percentage = percent;
                this.setState({ training_sections: currentTrainingSections });
              }
            },
            params: {
              token: this.props.token,
            },
            headers: {
              "content-type": "application/json",
            },
          };

          axios
            .post("training/add_video_for_training_section", payload, options)
            .then((res) => {
              this.setState({ training_section_to_update: "" });
              if (res.data == 1) {
                this.getTrainingSections();
                store.addNotification({
                  title: "Sectiuni antrenamente",
                  message: "Imaginea a fost adaugata cu succes!",
                  type: "success",
                  insert: "bottom",
                  container: "bottom-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 2000,
                  },
                });
              } else {
                let currentTrainingSections = this.state.training_sections;
                let updateTrainingScetionIndex = currentTrainingSections.findIndex(
                  (elem) => elem.id == this.state.training_section_to_update.id
                );
                currentTrainingSections[
                  updateTrainingScetionIndex
                ].is_uploading_video = false;
                currentTrainingSections[
                  updateTrainingScetionIndex
                ].upload_percentage = 0;
                this.setState({ training_sections: currentTrainingSections });
                store.addNotification({
                  title: "Sectiuni antrenamente",
                  message: "Imaginea nu a fost adaugata cu succes!",
                  type: "danger",
                  insert: "bottom",
                  container: "bottom-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 2000,
                  },
                });
              }
            })
            .catch((err) => console.log(err));
        }
      };
    });
  };

  handleClickAddImageFile = (section) => {
    this.setState({ training_section_to_update: section });
    this.inputOpenImageFileRef.current.click();
  };

  handleClickAddVideoFile = (section) => {
    this.setState({ training_section_to_update: section });
    this.inputOpenVideoFileRef.current.click();
  };

  deleteImage = () => {
    const payload = {
      id_club: this.props.currentUser.id_club,
      id_training_section: this.state.training_section_to_update.id,
    };
    axios
      .post("training/delete_image_for_training_section", payload)
      .then((res) => {
        this.setState({
          image_to_delete_popup: false,
          training_section_to_update: "",
        });
        if (res.data == 1) {
          this.getTrainingSections();
          store.addNotification({
            title: "Sectiuni antrenamente",
            message: "Imaginea a fost stearsa cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: "Sectiuni antrenamente",
            message: "Imaginea nu a fost stearsa cu succes!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  deleteVideo = () => {
    const payload = {
      id_club: this.props.currentUser.id_club,
      id_training_section: this.state.training_section_to_update.id,
    };
    axios
      .post("training/delete_video_for_training_section", payload)
      .then((res) => {
        this.setState({
          video_to_delete_popup: false,
          training_section_to_update: "",
        });
        if (res.data == 1) {
          this.getTrainingSections();
          store.addNotification({
            title: "Sectiuni antrenamente",
            message: "Fisierul video a fost stears cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: "Sectiuni antrenamente",
            message: "Fisierul video nu a fost stears cu succes!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <TFBCard>
        <TFBCardHeader title={languagePack.training_sections}>
          <TFBButton
            onClick={() => this.setState({ add_training_section: true })}
            endIcon="true"
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
          >
            {languagePack.add_training_sections}
          </TFBButton>
        </TFBCardHeader>
        <TFBCardBody>
          {this.state.training_sections == 0 ? (
            <TFBPlaceholder text="Nu sunt create secțiuni antrenamente" />
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn}>
                      {languagePack.designation}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.added_by}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.training_date}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.add_view_image}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.add_view_video}
                    </TFBTableCell>
                    <TFBTableCell align="right">
                      {languagePack.product_actions}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.training_sections.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      <TFBTableCell
                        component="th"
                        scope="row"
                        sx={sxStickyColumn}
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        {row.section_name}
                      </TFBTableCell>
                      <TFBTableCell align="center">{row.added_by}</TFBTableCell>
                      <TFBTableCell align="center">
                        {" "}
                        <Moment format="DD.MM.YYYY">{row.date_add}</Moment>
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {" "}
                        {row.is_uploading_img ? (
                          <div className="training-section-progress-bar-wrapper">
                            <div className="training-section-progress-bar">
                              <CircularProgressbar
                                value={row.upload_percentage}
                                text={`${row.upload_percentage}%`}
                              />
                            </div>
                          </div>
                        ) : row.error_img_src ? (
                          <div className="training-sections-add-button">
                            <TFBIconButton
                              style={{ position: "relative", right: "8px" }}
                              name="add"
                              onClick={() => this.handleClickAddImageFile(row)}
                              color="darkGray"
                            />
                          </div>
                        ) : (
                          <div className="training-schedule-image">
                            <div
                              className="image-training-schedule"
                              style={{
                                backgroundSize: "cover",
                                backgroundImage: `url(${
                                  row?.added_new_img_src
                                    ? row.added_new_img_src
                                    : `https://api.thefootballbrain.app/uploads/training_sections/${this.props.currentUser.id_club}/${row.id}.jpg`
                                })`,
                                height: "50px",
                                width: "73px",
                              }}
                              onClick={() =>
                                this.setState({
                                  image_view_dialog: true,
                                  currentSectionName: row.section_name,
                                  image_to_view: row,
                                })
                              }
                            ></div>
                            <Icon
                              name="close"
                              style={{ height: "100%", color: "#666666" }}
                              onClick={() =>
                                this.setState({
                                  image_to_delete_popup: true,
                                  training_section_to_update: row,
                                })
                              }
                            />
                          </div>
                        )}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.is_uploading_video ? (
                          <div className="training-section-progress-bar-wrapper">
                            <div className="training-section-progress-bar">
                              <CircularProgressbar
                                value={row.upload_percentage}
                                text={`${row.upload_percentage}%`}
                              />
                            </div>
                          </div>
                        ) : row.video_url == null ? (
                          <div className="training-sections-add-button">
                            <TFBIconButton
                              style={{ position: "relative", right: "3px" }}
                              name="add"
                              onClick={() => this.handleClickAddVideoFile(row)}
                              color="darkGray"
                            />
                          </div>
                        ) : (
                          <div className="training-schedule-video">
                            <Icon
                              name="play"
                              className="thumbanail-play-button"
                              onClick={() => this.playVideoRef.current.click()}
                            />
                            <div
                              ref={this.playVideoRef}
                              className="training-video-thumbnail"
                              onClick={() =>
                                this.setState({
                                  video_play_dialog: true,
                                  currentSectionName: row.section_name,
                                  video_to_play: row,
                                })
                              }
                            >
                              <video src={row.video_url} />
                            </div>
                            <Icon
                              name="close"
                              style={{ height: "100%", color: "#666666" }}
                              className="delete-video-icon"
                              onClick={() =>
                                this.setState({
                                  video_to_delete_popup: true,
                                  training_section_to_update: row,
                                })
                              }
                            />
                          </div>
                        )}
                      </TFBTableCell>

                      <TFBTableCell align="right">
                        <div className="training-section-actions-wrapper">
                          <TFBIconButton
                            name="edit"
                            onClick={() =>
                              this.setState({
                                edit_training_section: true,
                                training_section_to_update: row,
                              })
                            }
                            color="darkGray"
                          />
                          <TFBIconButton
                            name="delete"
                            onClick={() =>
                              this.setState({
                                delete_training_popup: true,
                                id_section_to_delete: row.id,
                              })
                            }
                            color="darkGray"
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <input
            ref={this.inputOpenImageFileRef}
            onChange={(e) =>
              this.handleAddImageFile(e, this.state.training_section_to_update)
            }
            type="file"
            accept="image/*"
            style={{ display: "none" }}
          />
          <input
            ref={this.inputOpenVideoFileRef}
            onChange={(e) =>
              this.handleAddVideoFile(e, this.state.training_section_to_update)
            }
            type="file"
            accept="video/*"
            style={{ display: "none" }}
          />
        </TFBCardBody>

        {/* ADD TRAINING SECTION */}
        <Dialog open={this.state.add_training_section}>
          <DialogContent>
            <AddTrainingSections
              closePopup={() => this.setState({ add_training_section: false })}
              id_club={this.props.currentUser.id_club}
              languagePack={this.props.languagePack}
              getTrainingSections={this.getTrainingSections}
            />
          </DialogContent>
        </Dialog>

        {/* EDIT TRAINING SECTION */}
        <Dialog open={this.state.edit_training_section}>
          <DialogContent>
            <EditTrainingSection
              closePopup={() =>
                this.setState({
                  edit_training_section: false,
                  training_section_to_update: "",
                })
              }
              id_club={this.props.currentUser.id_club}
              languagePack={this.props.languagePack}
              section_to_update={this.state.training_section_to_update}
              getTrainingSections={this.getTrainingSections}
            />
          </DialogContent>
        </Dialog>

        {/* DELETE TRAINING SECTION */}
        <Dialog open={this.state.delete_training_popup}>
          <DialogContent>
            <DeleteTrainingSection
              closePopup={() =>
                this.setState({
                  delete_training_popup: false,
                  id_section_to_delete: "",
                })
              }
              id_club={this.props.currentUser.id_club}
              id_section_to_delete={this.state.id_section_to_delete}
              languagePack={this.props.languagePack}
              getTrainingSections={this.getTrainingSections}
            />
          </DialogContent>
        </Dialog>

        {/* VIEW VIDEO TRAINING SECTION */}
        <Dialog open={this.state.video_play_dialog} maxWidth="lg">
          <div className="section-training-dialog">
            <div className="section-training-dialog-title-wrapper">
              <div className="section-training-dialog-title">
                {this.state.currentSectionName}
              </div>
              <div className="close-video">
                <TFBIconButton
                  name="x-small"
                  onClick={() =>
                    this.setState({
                      video_play_dialog: false,
                      video_to_play: "",
                    })
                  }
                  color="darkGray"
                />
              </div>
            </div>
          </div>
          <DialogContent
            style={{ overflow: "hidden", display: "flex", gap: "30px" }}
          >
            <VideoTrainingSection
              id={"video-player-" + this.state.video_to_play.id}
              src={this.state.video_to_play.video_url}
            />
          </DialogContent>
        </Dialog>

        {/* VIEW IMAGE TRAINING SECTION */}
        <Dialog open={this.state.image_view_dialog} maxWidth="lg">
          <div className="section-training-dialog">
            <div className="section-training-dialog-title-wrapper">
              <div className="section-training-dialog-title">
                {this.state.currentSectionName}
              </div>
              <div className="close-video">
                <Icon
                  name="close"
                  onClick={() =>
                    this.setState({
                      image_view_dialog: false,
                      image_to_view: "",
                    })
                  }
                  size="large"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <img
              src={
                this.state.image_to_view?.added_new_img_src
                  ? this.state.image_to_view.added_new_img_src
                  : `https://api.thefootballbrain.app/uploads/training_sections/${this.props.currentUser.id_club}/${this.state.image_to_view.id}.jpg`
              }
              alt="training_image"
              style={{ margin: "10px" }}
              className="image-in-dialog"
            />
          </div>
        </Dialog>

        {/* DELETE IMAGE TRAINING SECTION */}
        <Dialog open={this.state.image_to_delete_popup} maxWidth="lg">
          <div className="delete-team-section-image-popup-container">
            <div className="delete-team-section-image-header">
              <div className="delete-team-section-image-title">
                {"Ștergere imagine"}
              </div>
              <TFBIconButton
                onClick={() =>
                  this.setState({
                    image_to_delete_popup: false,
                    training_section_to_update: "",
                  })
                }
                name="x-small"
                color="darkGray"
              />
            </div>
            <div className="delete-team-section-text">
              {`Ești sigur că vrei să ștergi imaginea pentru secțiunea de
                antrenamente "${this.state.training_section_to_update.section_name}" ?`}
            </div>
            <div className="delete-team-section-buttons">
              <TFBButton
                onClick={() =>
                  this.setState({
                    image_to_delete_popup: false,
                    training_section_to_update: "",
                  })
                }
                endIcon="true"
                color="darkGray"
                style={{ width: "100%" }}
                renderIcon={() => <TFBIcon name="x-small" color="white" />}
              >
                {languagePack["admin_cancel"]}
              </TFBButton>

              <TFBButton
                onClick={this.deleteImage}
                endIcon="true"
                color="red"
                style={{ width: "100%" }}
                renderIcon={() => <TFBIcon name="delete" color="white" />}
              >
                {languagePack["admin_delete"]}
              </TFBButton>
            </div>
          </div>
        </Dialog>

        {/* DELETE VIDEO TRAINING SECTION */}
        <Dialog open={this.state.video_to_delete_popup} maxWidth="lg">
          <div className="delete-team-section-video-popup-container">
            <div className="delete-team-section-video-header">
              <div className="delete-team-section-video-title">
                {"Ștergere video"}
              </div>
              <TFBIconButton
                name="x-small"
                onClick={() =>
                  this.setState({
                    video_to_delete_popup: false,
                    training_section_to_update: "",
                  })
                }
                color="darkGray"
              />
            </div>
            <div className="delete-team-section-text">
              {`Ești sigur că vrei să ștergi videoclipul pentru secțiunea de
                antrenamente "${this.state.training_section_to_update.section_name}" ?`}
            </div>
            <div className="delete-team-section-buttons">
              <TFBButton
                onClick={() =>
                  this.setState({
                    video_to_delete_popup: false,
                    training_section_to_update: "",
                  })
                }
                endIcon="true"
                color="darkGray"
                style={{ width: "100%" }}
                renderIcon={() => <TFBIcon name="x-small" color="white" />}
              >
                {languagePack["admin_cancel"]}
              </TFBButton>

              <TFBButton
                onClick={this.deleteVideo}
                endIcon="true"
                color="red"
                style={{ width: "100%" }}
                renderIcon={() => <TFBIcon name="delete" color="white" />}
              >
                {languagePack["admin_delete"]}
              </TFBButton>
            </div>
          </div>
        </Dialog>
      </TFBCard>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(TrainingSections);
