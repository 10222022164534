import React from "react";
import { Icon } from "semantic-ui-react";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import { withRouter } from 'react-router';

class PolarSessionResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortConfig: {
        type: "",
        descending: false,
      },
    };
  }

  changeFilter = (type) => {
    if (this.state.sortConfig.type !== type) {
      this.setState({ sortConfig: { type: type, descending: false } });
    } else {
      this.setState({
        sortConfig: {
          type: type,
          descending: !this.state.sortConfig.descending,
        },
      });
    }
  };

  sortData = () => {
    const items = this.props.parameters;
    const sortOption = this.state.sortConfig;

    if (sortOption.type === "heart_rate_avg") {
      items.sort(function(a, b) {
        if (sortOption.descending) return b.heart_rate_avg - a.heart_rate_avg;
        else return a.heart_rate_avg - b.heart_rate_avg;
      });
    } else if (sortOption.type === "heart_rate_max") {
      items.sort(function(a, b) {
        if (sortOption.descending) return b.heart_rate_max - a.heart_rate_max;
        else return a.heart_rate_max - b.heart_rate_max;
      });
    } else if (sortOption.type === "distance_meters") {
      items.sort(function(a, b) {
        if (sortOption.descending) return b.distance_meters - a.distance_meters;
        else return a.distance_meters - b.distance_meters;
      });
    } else if (sortOption.type === "kcal") {
      items.sort(function(a, b) {
        if (sortOption.descending) return b.kcal - a.kcal;
        else return a.kcal - b.kcal;
      });
    } else if (sortOption.type === "sprint_counter") {
      items.sort(function(a, b) {
        if (sortOption.descending) return b.sprint_counter - a.sprint_counter;
        else return a.sprint_counter - b.sprint_counter;
      });
    } else if (sortOption.type === "speed_avg") {
      items.sort(function(a, b) {
        if (sortOption.descending) return b.speed_avg - a.speed_avg;
        else return a.speed_avg - b.speed_avg;
      });
    } else if (sortOption.type === "speed_max") {
      items.sort(function(a, b) {
        if (sortOption.descending) return b.speed_max - a.speed_max;
        else return a.speed_max - b.speed_max;
      });
    } else return items;
  };

  convertDistance(val_meters) {
    var km = val_meters / 1000;
    return km.toFixed(1);
  }

  render() {
    const { languagePack } = this.props;
    this.sortData();
    return (
      <div className="card card-custom">
        <div className="training-page-container">
          <div className="training-page-title">
            {languagePack["physical_tracking_results"]}
          </div>
          <div className="training-page-players-list">
            <div className="training-player-list-table-header">
              <div className="player-name-header">
                {languagePack["player_name"]}
              </div>
              <div className="player-double-parameter-header">
                <div className="row-1">{languagePack["heart_rate"]} (bpm)</div>
                <div className="row-2">
                  <div onClick={() => this.changeFilter("heart_rate_avg")}>
                    {languagePack["medium"]}
                    {this.state.sortConfig.type === "heart_rate_avg" &&
                      (this.state.sortConfig.descending ? (
                        <Icon name="arrow down" size="small" />
                      ) : (
                        <Icon name="arrow up" size="small" />
                      ))}
                  </div>
                  <div onClick={() => this.changeFilter("heart_rate_max")}>
                    /max
                    {this.state.sortConfig.type === "heart_rate_max" &&
                      (this.state.sortConfig.descending ? (
                        <Icon name="arrow down" size="small" />
                      ) : (
                        <Icon name="arrow up" size="small" />
                      ))}
                  </div>
                </div>
              </div>
              <div
                className="player-parameter-header"
                onClick={() => this.changeFilter("distance_meters")}
              >
                <div style={{ textAlign: "center" }}>
                  {languagePack["distance_covered"]} (km)
                </div>
                {this.state.sortConfig.type === "distance_meters" &&
                  (this.state.sortConfig.descending ? (
                    <Icon name="arrow down" size="small" />
                  ) : (
                    <Icon name="arrow up" size="small" />
                  ))}
              </div>
              <div
                className="player-parameter-header"
                onClick={() => this.changeFilter("kcal")}
              >
                <div style={{ textAlign: "center" }}>
                  {languagePack["kcal_spent"]}
                </div>
                {this.state.sortConfig.type === "kcal" &&
                  (this.state.sortConfig.descending ? (
                    <Icon name="arrow down" size="small" />
                  ) : (
                    <Icon name="arrow up" size="small" />
                  ))}
              </div>
              <div
                className="player-parameter-header"
                onClick={() => this.changeFilter("sprint_counter")}
              >
                <div style={{ textAlign: "center" }}>
                  {languagePack["sprint_counter"]}
                </div>
                {this.state.sortConfig.type === "sprint_counter" &&
                  (this.state.sortConfig.descending ? (
                    <Icon name="arrow down" size="small" />
                  ) : (
                    <Icon name="arrow up" size="small" />
                  ))}
              </div>
              <div className="player-double-parameter-header">
                <div className="row-1">{languagePack["speed"]} (km/h)</div>
                <div className="row-2">
                  <div onClick={() => this.changeFilter("speed_avg")}>
                    {languagePack["medium"]}
                  </div>
                  {this.state.sortConfig.type === "speed_avg" &&
                    (this.state.sortConfig.descending ? (
                      <Icon name="arrow down" size="small" />
                    ) : (
                      <Icon name="arrow up" size="small" />
                    ))}
                  <div onClick={() => this.changeFilter("speed_max")}>/max</div>
                  {this.state.sortConfig.type === "speed_max" &&
                    (this.state.sortConfig.descending ? (
                      <Icon name="arrow down" />
                    ) : (
                      <Icon name="arrow up" />
                    ))}
                </div>
              </div>
            </div>
            {this.props.parameters.map((item, index) => {
              return (
                <div
                  className="training-player-row"
                  style={{
                    backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                  }}
                >
                  <div
                    className="player-name"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.props.history.push(`/club/player/${item.id_player}`)}
                  >
                    <Avatar
                      src={`${window.baseURL}uploads/players/${item.id_player}.jpg`}
                      className="player-avatar"
                    />
                    <div className="name">{item.player_name}</div>
                  </div>
                  <div className="player-double-parameter">
                    <div className="first">{item.heart_rate_avg + "/ "}</div>
                    <div className="second">{item.heart_rate_max}</div>
                  </div>
                  <div className="player-parameter">
                    {this.convertDistance(item.distance_meters)}
                  </div>
                  <div className="player-parameter">{item.kcal}</div>
                  <div className="player-parameter">{item.sprint_counter}</div>
                  <div className="player-double-parameter">
                    <div className="first">{item.speed_avg + "/ "}</div>
                    <div className="second">{item.speed_max}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(PolarSessionResults));
