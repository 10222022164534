import React, { useState, useEffect } from "react";
import "./../Competitions/CompetitionPage.scss";
import edit from "../../../../assets/icons/edit.png";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import axios from "axios";
import { initial } from "lodash";

function EditTeamCompetition(props) {
  const [editTeamId, setEditPopup] = useState(
    props.editedTeam.id_manual_competition_team
  );
  const [newName, setNewName] = useState(props.editedTeam.team_name);
  const [initialPoints, setInitialPoints] = useState(
    props.editedTeam.initial_points
  );
  const [teamImage, setNewTeamImage] = useState(
    props.editedTeam.team_logo == null
      ? ""
      : `https://api.thefootballbrain.app/uploads/team_logos/${props.editedTeam.id_manual_competition_team}logo.png`
  );

  const { languagePack } = props;

  const handleChangeImage = (e) => {
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setNewTeamImage(reader.result);
    };
  };

  const editTeam = () => {
    const payload = {
      id_team: editTeamId,
      new_name: newName,
      new_image: teamImage,
      initial_points: initialPoints,
    };
    axios
      .post("match/edit_team", payload)
      .then(() => {
        props.getTeams();
        props.updateCompetitionMatches();
        props.updateCompetitionDetails();
        props.closePopup();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="edit-team-popup-container">
      <div className="edit-team-popup-title">{languagePack["edit_team"]}</div>
      <input
        className="edit-team-input"
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
      />
      <input
        value={initialPoints}
        // defaultValue={"0"}
        onChange={(e) => setInitialPoints(e.target.value)}
        placeholder={"Punctaj initial"}
        className="edit-team-input"
      />
      <div className="add-team-manual-image">
        <div className="image-input-container">
          <span>{languagePack["team_logo"]}</span>
          <input
            type="file"
            className="add-news-image-input"
            id="customFile"
            onChange={(e) => handleChangeImage(e)}
          />

          <label className="add-file-form-file-label" htmlFor="customFile">
            <div className="image-view">
              {teamImage !== "" ? (
                <img src={teamImage} />
              ) : (
                <span>
                  <Icon
                    name="arrow up"
                    className="cusor-pointer"
                    size="small"
                  />
                </span>
              )}
            </div>
          </label>
        </div>
      </div>
      <div className="edit-team-buttons-container">
        <Button
          className="edit-team-button"
          onClick={() => props.closePopup()}
          style={{ backgroundColor: "#666666" }}
        >
          {props.languagePack["admin_cancel"]}
          <Icon name="delete" className="white-icon" />
        </Button>
        <Button
          className="edit-team-button"
          onClick={() => editTeam()}
          style={{ backgroundColor: window.clubCustomization.primary_color }}
        >
          {props.languagePack["admin_save"]}
          <Icon name="check" className="white-icon" />
        </Button>
      </div>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(EditTeamCompetition);
