import React from "react";
import axios from "axios";
import "./PrepareNextMatch.scss";
import MatchLineup from "../../../../components/data-providers/football-api/match/MatchLineup";
import MatchStats from "../../../../components/data-providers/instat/match/MatchTeamStats";

const options = [
  { value: "players", text: "Echipe de start" },
  { value: "stats", text: "Statistici" },
  { value: "technical_parameters", text: "Parametri tehnici" },
  { value: "physical_parameters", text: "Parametri fizici" },
];

class LastDirectMatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOption: "players",
      last_match_details: {},
    };
  }

  componentDidMount = () => {
    axios
      .get(`match/last_direct_match?id_match=${this.props.id_match}`)
      .then((res) => this.setState({ last_match_details: res.data }))
      .catch((error) => console.log(error));
  };

  render() {
    return (
      <div className="last-direct-match-container">
        <div className="last-direct-match-menu">
          {options.map((item) => (
            <div
              className="menu-option"
              style={{
                backgroundColor:
                  this.state.menuOption === item.value ? "#fafafa" : "white",
              }}
              onClick={() => this.setState({ menuOption: item.value })}
            >
              {item.text}
            </div>
          ))}
        </div>
        {this.state.menuOption === "players" &&
          Object.keys(this.state.last_match_details).length > 0 && (
            <MatchLineup
              id_match={this.state.last_match_details.last_match.id_match_api}
            />
          )}
        {this.state.menuOption === "stats" &&
          Object.keys(this.state.last_match_details).length > 0 && (
            <div style={{ marginTop: 25 }}>
              <MatchStats
                id_match={this.state.last_match_details.last_match.id_match_api}
              />
            </div>
          )}
      </div>
    );
  }
}

export default LastDirectMatch;
