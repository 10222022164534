import React from "react";
import styles from "./TFBDialog.module.scss";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { TDialog } from "../types";
import TFBIconButton from "../IconButton/TFBIconButton";
import TFBIcon from "../Icon/TFBIcon";

const TFBDialog = ({
  open,
  onClose,
  closePopup,
  className,
  style,
  title,
  children,
  btnLeft,
  btnRight,
  btnCenter,
  fullWidth,
  maxWidth,
  scroll,
  stickyBtns,
  columnActionsMobile,
}: TDialog) => {
  return (
    <Dialog
      open={open}
      // onClose={onClose}
      className={styles.tfbDialog}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      disableEnforceFocus
      scroll={scroll}
    >
      <div className={`${className ? className : ""}`} style={style}>
        <DialogTitle disableTypography className={styles.tfbDialogTitle}>
          <h2 className={styles.header}>{title}</h2>
          <div className={styles.dialogCloseIcon} onClick={closePopup}>
            <TFBIcon name="close-popup" color="black" size="lg" />
          </div>
        </DialogTitle>
        <DialogContent className={`${styles.tfbDialogContent}`}>
          {children}
        </DialogContent>

        {/* <div className="tfb-dialog-action-wrapper"> */}
        <DialogActions
          className={`tfb-custom-dialog-actions ${styles.tfbDialogActions} ${
            stickyBtns ? styles.tfbDialogStickyActions : ""
          } ${columnActionsMobile ? styles.tfbActionsColumn : ""}`}
          style={
            (btnLeft !== undefined &&
              btnRight !== undefined &&
              btnCenter !== undefined) ||
            (btnLeft !== undefined && btnRight !== undefined)
              ? {}
              : btnLeft !== undefined
              ? { justifyContent: "flex-start" }
              : btnRight !== undefined
              ? { justifyContent: "flex-end" }
              : btnCenter !== undefined
              ? { justifyContent: "center" }
              : {}
          }
        >
          {btnLeft && (
            <div
              className={`tfb-custom-dialog-btn-left ${styles.btnLeft} ${
                btnLeft !== undefined &&
                btnRight !== undefined &&
                btnCenter !== undefined
                  ? styles.btnTFBThree
                  : styles.btnTFBTwo
              }`}
            >
              {btnLeft()}
            </div>
          )}
          {btnCenter && (
            <div
              className={`tfb-custom-dialog-btn-center ${styles.btnCenter} ${
                btnLeft !== undefined &&
                btnRight !== undefined &&
                btnCenter !== undefined
                  ? styles.btnTFBThree
                  : styles.btnTFBTwo
              }`}
            >
              {btnCenter()}
            </div>
          )}
          {btnRight && (
            <div
              className={`tfb-custom-dialog-btn-right ${styles.btnRight} ${
                btnLeft !== undefined &&
                btnRight !== undefined &&
                btnCenter !== undefined
                  ? styles.btnTFBThree
                  : styles.btnTFBTwo
              }`}
            >
              {btnRight()}
            </div>
          )}
        </DialogActions>
        {/* </div> */}
      </div>
    </Dialog>
  );
};

export default TFBDialog;
