import React, { useState } from "react";
import { Link, Switch, Redirect, Route } from "react-router-dom";
import { toAbsoluteUrl } from "./../../_metronic/_helpers";
import { ContentRoute } from "./../../_metronic/layout";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import "./../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import axios from "axios";

export default function AuthPage() {
  const { lang, languagePack } = useSelector(
    ({ layoutService: { lang, languagePack } }) => ({
      lang,
      languagePack,
    })
  );

  let defaultImage = toAbsoluteUrl("/media/logos/Login-logo.png");
  let defaultLoginImage = toAbsoluteUrl("/media/bg/Login-bg.jpg");
  // const [clubCustomization, setClubCustomization] = useState({
  //   language: 'en',
  //   image: '',
  //   login_image: '',
  //   clubName: 'The Football Brain',
  //   loader: true
  // });


  // if (clubCustomization.loader === true) {
  //   let hostname = window.location.hostname;
  //   // hostname = 'banfieldmatrix.com';
  //   if (hostname.indexOf('localhost') !== 0 && hostname.indexOf('thefootballbrain.app') !== 0) {

  //     //Get the id of the club, then all the others infos
  //     axios.get(
  //       `club/get_club_by_custom_domain?slug=${hostname}`
  //     )
  //       .then((res) => {
  //         let id_club = res?.data?.id_club;
  //         if (id_club != null) {
  //           setClubCustomization({
  //             language: res.data.default_lang,
  //             image: res.data.logo_login != null ? 'https://api.thefootballbrain.app/uploads/custom/' + id_club + '/' + res.data.logo_login : defaultImage,
  //             login_image: res.data.login_image != null ? 'https://api.thefootballbrain.app/uploads/custom/' + id_club + '/' + res.data.login_image : defaultLoginImage,
  //             clubName: res.data.club_name,
  //             loader: false
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setClubCustomization({
  //           language: 'en',
  //           image: defaultImage,
  //           login_image: defaultLoginImage,
  //           clubName: 'The Football Brain',
  //           loader: false
  //         });
  //       });
  //   }
  //   else {
  //     setClubCustomization({
  //       language: 'en',
  //       image: defaultImage,
  //       login_image: defaultLoginImage,
  //       clubName: 'The Football Brain',
  //       loader: false
  //     });
  //   }
  // }

  return (
    <IntlProvider
      locale={lang.toLowerCase()}
      // locale={clubCustomization.language}
      messages={languagePack.FORM_CONTROL}
    >
      {/* {clubCustomization.loader === false && */}
        <div className="d-flex flex-column flex-root">
          {/*begin::Login*/}
          <div
            className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
            id="kt_login"
          >
            {/*begin::Aside*/}
            <div
              className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
              style={{
                backgroundImage: `url(${window.clubCustomization.login_image})`,
              }}
            >
              {/*begin: Aside Container*/}
              <div className="d-flex flex-row-fluid flex-column justify-content-between">
                {/* start:: Aside header */}
                <Link to="/" className="flex-column-auto mt-5">

                </Link>
                {/* end:: Aside header */}

              </div>
              {/*end: Aside Container*/}
            </div>
            {/*begin::Aside*/}

            {/*begin::Content*/}
            <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
              <div className="cotnainer-logo" style={{ width: '20vw', marginTop: 100, marginLeft: 'auto', marginRight: 'auto' }}>
                <img
                  // alt={clubCustomization.clubName}
                  src={window.clubCustomization.logo_login}
                  style={{ width: '100%' }}
                />
              </div>

              {/* begin::Content body */}
              <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                <Switch>
                  <ContentRoute path="/auth/login" component={Login} />
                  <ContentRoute
                    path="/auth/forgot-password"
                    component={ForgotPassword}
                  />
                  <Route
                    exact
                    path="/auth"
                    render={() => <Redirect to="/auth/login" />}
                  />
                  <Route path="/" exact render={() => <Redirect to="/auth/login" />} />
                </Switch>
              </div>
              {/*end::Content body*/}

            </div>
            {/*end::Content*/}
          </div>
          {/*end::Login*/}
        </div>
      {/* } */}
    </IntlProvider>
  );
}
