import React from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import { connect } from "react-redux";
// import "../Instat.scss";
import Loader from "react-loader-spinner";
import dateFormat from "dateformat";
import { Icon, Flag, Button, Dropdown, TextArea } from "semantic-ui-react";
import Moment from "react-moment";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import { sxStickyColumn, TFBTableCell } from "../../../components/reusable/useStyles";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import moment from "moment";



class TrainingStatsWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table_data: [],
    };
  }

  componentDidMount = () => {
    this.getPlayerTrainings();
  };

  getPlayerTrainings = () => {
    axios
      .get(`player/get_player_training_stats?id_player=${this.props.id_player}`)
      .then((res) => {
        this.setState({
          table_data: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <TFBCard>
        <TFBCardHeader title={languagePack.training_statistics} />
        <TFBCardBody>
          {this.state.table_data.length > 0 ?
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn}>
                      {languagePack.training_date_team}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.was_present}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.mark}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.remarks}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.table_data.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                    >
                      <TFBTableCell
                        sx={sxStickyColumn}
                        style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                      >
                        {moment(row["date_start"]).format("DD.MM.yyyy")}<br></br>
                        <span className="green">{row["team_name"]}</span>
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.was_present == 1 ?
                          <TFBIcon name="add" color="green" /> :
                          <TFBIcon name="minus" color="red" />
                        }
                      </TFBTableCell>
                      <TFBTableCell align="center">{row.training_rating > 0 ? row.training_rating + "/5" : ""}</TFBTableCell>
                      <TFBTableCell align="center">
                        {row.training_comment}
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> :
            <TFBPlaceholder text={"Nu are statistici antrenament"} />
          }
        </TFBCardBody>
      </TFBCard>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(TrainingStatsWidget);
