import React from "react";
import { Icon, Button, TextArea, Progress } from "semantic-ui-react";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "./../../utils/api";
import axios from "axios";
import "./VideoGallery.styles.scss";
import Loader from "react-loader-spinner";
import { ProgressBar } from "react-bootstrap";

class AddVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doc_category: "",
      addedFile: "",
      doc_extra_notes: "",
      categories: [],
      error: "",
      loading: false,
      valid: "",
      video_name: "",
      uploadPercentage: 0,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  componentDidMount = () => {
    this.getCategories();
  };

  getCategories = () => {
    axios
      .get(
        `upload/get_file_categories?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => {
        this.setState({ categories: res.data });
      })
      .catch((err) => console.log(err));
  };

  onChange = (e) => {
    this.setState({
      addedFile: "",
    });

    const fileSize = e.target.files[0].size / 1024 / 1024 / 1024; // in GB
    if (fileSize > 2) {
      this.setState({ valid: "invalid" });
    } else {
      this.setState({ valid: "valid", addedFile: e.target.files[0] });
    }
  };

  async setLoader(value) {
    return new Promise((resolve) => {
      this.setState(
        {
          fileUploading: value,
        },
        () => {
          resolve(value);
        }
      );
    });
  }

  async onSubmit(e) {
    e.preventDefault();
    await this.setLoader(true);
    this.setState({ loading: true });
    let res = await this.uploadFile(this.state.addedFile);
    await this.setLoader(false);
    this.setState({ loading: false });
    this.setState(
      {
        doc_category: "",
        addedFile: "",
        doc_extra_notes: "",
      },
      this.props.refreshFiles
    );

    if (this.state.valid == "valid") {
      store.addNotification({
        title: this.props.languagePack["video_operation"],
        message: this.props.languagePack["video_upload_success"],
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
    }

    if (this.state.valid == "invalid") {
      store.addNotification({
        title: this.props.languagePack["video_operation"],
        message: this.props.languagePack["video_upload_failed"],
        type: "danger",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
    }
  }

  async uploadFile(file) {
    const formData = new FormData();
    formData.append("avatar", file);
    formData.append("fileName", this.state.video_name);
    formData.append("id_club", this.props.currentUser.id_club);
    formData.append("uploaded_by", this.props.currentUser.nickname);
    formData.append("category", 'video');
    formData.append("extra_notes", this.state.doc_extra_notes);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total)
        if (percent < 100) {
          this.setState({ uploadPercentage: percent })
        }
      },
      params: {
        token: this.props.token,
      },
      headers: {
        "content-type": "multipart/form-data",
      },
    }

    return await axios
      .post("upload/upload_video", formData, options)
      .then(async (res) => {
        this.setState({
          uploadPercentage: 100,
        }, () => {
          setTimeout(() => {
            this.setState({
              uploadPercentage: 0,
            })
          }, 1000)
        })
        this.props.closePopup();
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        }
        return res;
      })
      .catch(async (err) => {
        return { data: {} };
      });
  }

  addVideo = () => {

    const formData = new FormData();
    formData.append("video", this.state.addedFile);
    formData.append("fileName", this.state.video_name);
    formData.append("id_club", this.props.currentUser.id_club);
    formData.append("uploaded_by", this.props.currentUser.nickname);
    formData.append("category", 'video');
    formData.append("extra_notes", this.state.doc_extra_notes);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total)
        if (percent < 100) {
          this.setState({ uploadPercentage: percent })
        }
      },
      params: {
        token: this.props.token,
      },
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    this.setState({ loading: true });
    axios
      .post("upload/upload_video_to_digital_ocean", formData, options)
      .then((res) => {
        this.setState({
          uploadPercentage: 100,
          loading: false,
          doc_category: "",
          addedFile: "",
          doc_extra_notes: "",
        }, () => {
          this.props.refreshFiles();
          setTimeout(() => {
            this.setState({
              uploadPercentage: 0,
            })
          }, 1000)
        })
        this.props.closePopup();
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        }
        if (res.data == 1) {
          store.addNotification({
            title: this.props.languagePack["video_operation"],
            message: this.props.languagePack["video_upload_success"],
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: this.props.languagePack["video_operation"],
            message: this.props.languagePack["video_upload_failed"],
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });


  }

  render() {
    const { languagePack } = this.props;
    return (<>
      {
        this.state.loading ? (
          <div className="video-loader" >
            <div className="loader-container">
              <Loader type="TailSpin" color={window.clubCustomization.primary_color} height={50} width={50} />
              <div className="loading-message">
                {this.state.uploadPercentage > 0 && <ProgressBar style={{ width: "100%", margin: "15px 0px" }} now={this.state.uploadPercentage} active="true" label={`${this.state.uploadPercentage}%`} />}
                {"The video is uploading!"}
              </div>
            </div>
          </div>
        ) :
          <div className="add-file-container">
            <div className="add-file-form-row">
              <input
                className="add-video-title"
                placeholder={languagePack.video_title}
                onChange={(event) => {
                  this.setState({ video_name: event.target.value });
                }}
              />
            </div>
            <div
              className="add-file-form-row"
              style={{
                justifyContent: "space-between",
                paddingRight: 20,
                backgroundColor: "#fafafa",
              }}
            >
              <input
                type="file"
                className="add-file-form-file-input"
                id="customFile"
                accept="video/mp4"
                onChange={this.onChange}
                onClick={() => this.setState({ valid: "" })}
              />
              <label className="add-file-form-file-label" htmlFor="customFile" style={{ width: "100%" }}>
                {this.state.addedFile
                  ? this.state.addedFile.name
                  : languagePack.upload_video}
              </label>
              <Icon
                name="arrow up"
                className="cusor-pointer grey-button-icon"
                size="small"
              />
            </div>
            {this.state.valid == "invalid" &&
              <div className="add-file-form-row error-message-add-video">
                {"Fisierul trece peste limita de 2 GB. Incarca alt fisier!"}
              </div>
            }
            {/* <div className="add-file-form-row" style={{ height: 0 }}> */}
            {/* </div> */}
            <TextArea
              placeholder={languagePack.video_description}
              className="text-area-file-form"
              value={this.state.doc_extra_notes}
              onChange={(e) => this.setState({ doc_extra_notes: e.target.value })}
              name="doc_extra_notes"
            />
            {this.state.error != "" && (
              <div className="end-injury-error-row">{this.state.error}</div>
            )}

            <div className="add-file-buttons-container">
              <Button
                className="add-file-button cancel-button"
                onClick={() => this.props.closePopup()}
              >
                {languagePack["admin_cancel"]}
                <Icon name="ban" size="small" className="add-file-button-icon" />
              </Button>
              <Button
                className="add-file-button"
                disabled={this.state.fileUploading || !this.state.addedFile}
                onClick={this.addVideo}
              // onClick={this.onSubmit}
              >
                {languagePack["admin_add"]}
                <Icon name="add" size="small" className="add-file-button-icon" />
              </Button>
            </div>
          </div>
      }
    </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}

export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(AddVideo);
