import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const CircularLoader = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px', display: "flex", justifyContent: "center" }}>
      <CircularProgress size={40} />
    </div>
  );

  export default CircularLoader;