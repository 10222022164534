import React, { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Icon, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import "./Teams.scss";
import { useHistory } from "react-router-dom";
import { ReactComponent as EyeIcon } from "../../../../assets/icons/eye-white.svg";
import { ReactComponent as DollarIcon } from "../../../../assets/icons/dollar.svg";
import Loader from "react-loader-spinner";
import EditTeamForm from "./EditTeamForm";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBDatePicker from "../../../../components/design-system/DatePicker/TFBDatePicker";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import moment from "moment";

function ActiveTeams(props) {
  const [teams, setTeams] = useState(props.teamsList);
  const [editTeam, setEditTeam] = useState("");
  const [editDialog, setEditDialog] = useState(false);
  const [fetchFinished, setFetchFinished] = useState(props.teamsListFetched);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const history = useHistory();

  useEffect(() => {
    setTeams(props.teamsList);
  }, [props.teamsList]);

  useEffect(() => {
    getTeams();
  }, []);

  const getTeams = () => {
    axios
      .get(`club/get_teams_2?id_club=${props.id_club}&token=${props.token}`)
      .then((res) => {
        setTeams(Array.isArray(res.data) ? res.data : []);
        setFetchFinished(true);
      })
      .catch((err) => console.log(err));
  };
  const { languagePack } = props;

  const handleChangeStartDate = (date) => {
    setStartDate(date);
  };

  const handleChangeEndDate = (date) => {
    setEndDate(date);
  };

  const handleResetFilters = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const exportInactivePlayers = () => {
    let payload = {
      id_club: props.id_club,
      token: props.token,
    };

    if (startDate) {
      payload.start_date = moment(startDate).format("YYYY-MM-DD");
    }

    if (endDate) {
      payload.end_date = moment(endDate).format("YYYY-MM-DD");
    }

    const url = `https://api.thefootballbrain.app/club/export_inactive_players?id_club=${
      payload.id_club
    }${payload.start_date ? `&start_date=` + payload.start_date : ""}${
      payload.end_date ? `&end_date=` + payload.end_date : ""
    }&token=${payload.token}`;
    window.location.assign(url);
  };

  const exportActivePlayers = () => {
    let payload = {
      id_club: props.id_club,
      token: props.token,
    };

    if (startDate) {
      payload.start_date = moment(startDate).format("YYYY-MM-DD");
    }

    if (endDate) {
      payload.end_date = moment(endDate).format("YYYY-MM-DD");
    }

    const url = `https://api.thefootballbrain.app/club/export_active_players_added?id_club=${
      payload.id_club
    }${payload.start_date ? `&start_date=` + payload.start_date : ""}${
      payload.end_date ? `&end_date=` + payload.end_date : ""
    }&token=${payload.token}`;
    window.location.assign(url);
  };

  return (
    <div>
      <div className="active-teams-container" style={{ padding: "0px" }}>
        {!fetchFinished ? (
          <div className="loader-container" style={{ marginTop: 50 }}>
            <Loader
              type="TailSpin"
              color={window.clubCustomization.primary_color}
              height={40}
              width={40}
            />
          </div>
        ) : (
          <>
            {teams.length > 0 ? (
              <div className="active-teams-list">
                <div className="team-row active-teams-table-columns">
                  <div className="team-name">{languagePack["team_name"]}</div>
                  <div className="team-category">
                    {" "}
                    {languagePack["team_type"]}
                  </div>
                  <div className="team-nr-players">
                    {languagePack["number_players"]}
                  </div>
                  <div className="team-coach">{languagePack["team_coach"]}</div>
                  <div className="team-see-more"></div>
                </div>
                {teams.map((item, index) => {
                  return (
                    <div
                      className="team-container"
                      key={"active-team-" + item.id_team}
                    >
                      <div className="team-content">
                        <div
                          className="team-row active-teams-table-columns"
                          style={{
                            backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                          }}
                        >
                          <div className="team-name">
                            {languagePack["team_name"]}
                          </div>
                          <div className="team-category">
                            {" "}
                            {languagePack["team_type"]}
                          </div>
                          <div className="team-nr-players">
                            {languagePack["number_players"]}
                          </div>
                          <div className="team-coach">
                            {languagePack["team_coach"]}
                          </div>
                          <div className="team-see-more"></div>
                        </div>

                        <div
                          className="team-row"
                          key={item.id_team}
                          style={{
                            backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                          }}
                        >
                          <div
                            className="team-name"
                            onClick={() =>
                              history.push(`/club/team/${item.id_team}`)
                            }
                          >
                            {item.team_name}
                          </div>
                          <div className="team-category">{item.team_type}</div>
                          <div className="team-nr-players">
                            {item.number_players > 0
                              ? item.number_players
                              : "-"}
                          </div>
                          <div className="team-coach">{item.coach}</div>
                          <div className="team-see-more">
                            <div
                              className="team-see-more-button"
                              onClick={() =>
                                history.push(`/club/team/${item.id_team}`)
                              }
                            >
                              <EyeIcon />
                            </div>
                            {item.show_in_online_payments == 1 && (
                              <div
                                className="team-see-more-button"
                                onClick={() =>
                                  history.push(`/club/payments/${item.id_team}`)
                                }
                              >
                                <DollarIcon />
                              </div>
                            )}
                            <div
                              className="team-see-more-button"
                              onClick={() => {
                                setEditTeam(item.id_team);
                                setEditDialog(true);
                              }}
                            >
                              <Icon
                                name="edit"
                                style={{ color: "white", marginLeft: "4px" }}
                                alt="edit-galery"
                              />
                            </div>
                            <div
                              className="team-see-more-button"
                              onClick={() => {
                                history.push(
                                  `/club/medical-certificates/${item.id_team}`,
                                  {
                                    team_name: item.team_name,
                                  }
                                );
                              }}
                            >
                              <div className="icon-notification">
                                <div className="icon-heart">
                                  <Icon
                                    name="heartbeat"
                                    size="large"
                                    style={{ color: "white" }}
                                    alt="edit-galery"
                                  />
                                </div>
                                {item.players_without_certificates > 0 && (
                                  <div className="notification-container">
                                    <div className="notification-circle">
                                      <div className="notification-number">
                                        {item.players_without_certificates}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="active-teams-export-players-container">
                  <div className="ds-headline-2 title">
                    {languagePack.export_added_inactive_players}
                  </div>

                  <div className="filters-wrapper">
                    <div className="dates-filters">
                      <div className="players-date-pickers">
                        <TFBDatePicker
                          className="date-picker"
                          placeholder={languagePack.from}
                          label={languagePack.from}
                          value={startDate}
                          onChange={(date) => handleChangeStartDate(date)}
                        />
                      </div>

                      <span
                        style={{
                          marginTop: "18px",
                        }}
                      >
                        {"-"}
                      </span>

                      <div className="players-date-pickers">
                        <TFBDatePicker
                          className="date-picker"
                          placeholder={languagePack.until}
                          label={languagePack.until}
                          value={endDate}
                          onChange={(date) => handleChangeEndDate(date)}
                        />
                      </div>
                    </div>

                    <div className="filter-buttons">
                      <TFBButton
                        className="reset-button"
                        onClick={handleResetFilters}
                        endIcon="true"
                        color={"darkGray"}
                        renderIcon={() => <TFBIcon name={"cancel"} />}
                      >
                        {languagePack.Reset_filters}
                      </TFBButton>
                    </div>
                  </div>

                  <div className="export-buttons-wrapper">
                    <TFBButton
                      className="export-inactive-players"
                      onClick={exportInactivePlayers}
                    >
                      {languagePack.export_inactive_players}
                    </TFBButton>
                    <TFBButton
                      className="export-added-players"
                      onClick={exportActivePlayers}
                    >
                      {languagePack.export_added_players}
                    </TFBButton>
                  </div>
                </div>
              </div>
            ) : (
              <div className="placeholder-files">
                <Icon
                  name="search minus"
                  size="huge"
                  className="placeholder-icon"
                />
                <div className="placeholder-text">
                  {languagePack["no_teams_yet"]}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <Dialog open={editDialog}>
        <DialogContent className="add-team-wrapper">
          <EditTeamForm
            closePopup={() => setEditDialog(false)}
            id_club={props.id_club}
            refreshTeams={() => getTeams()}
            languagePack={languagePack}
            id_team={editTeam}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
}))(ActiveTeams);
