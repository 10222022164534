import React from "react";
import "./Sponsors.scss";
import axios from "axios";
import Moment from "react-moment";
import { Icon } from "semantic-ui-react";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye-white.svg";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";

class Sponsors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sponsors: [],
      activeIndexes: [],
      selectedYear: new Date().getFullYear() - 1,
      seasons: ["2020/2021", "2021/2022", "2022/2023"],
      total: "",
      total_unpaid: "",
      sortConfig: {
        type: "name",
        descending: false,
      },
    };
  }

  componentDidMount = () => {
    this.getSponsors(this.state.selectedYear);
  };

  getSponsors = (season) => {
    axios
      .get(
        `finances/get_sponsors_page?id_club=${this.props.currentUser.id_club}&season=${season}`
      )
      .then((res) =>
        this.setState({
          sponsors: res.data.sponsors,
          total: res.data.total,
          total_paid: res.data.total_paid,
        })
      )
      .catch((err) => console.log(err));
  };

  formatNumber = (value) => {
    return (
      <NumberFormat
        value={value}
        displayType={"text"}
        thousandSeparator={true}
        decimalScale={0}
      />
    );
  };

  addDefaultSrc = (event) => {
    event.target.src =
      "https://api.thefootballbrain.app/uploads/sponsor_logos/default.png";
  };

  sortData = () => {
    const items = this.state.sponsors;
    const sortOption = this.state.sortConfig;

    if (sortOption.type === "amount") {
      items.sort(function(a, b) {
        if (sortOption.descending) return b.total_amount - a.total_amount;
        else return a.total_amount - b.total_amount;
      });
    } else return items;
  };

  changeFilter = (type) => {
    if (this.state.sortConfig.type !== type) {
      this.setState({ sortConfig: { type: type, descending: false } });
    } else {
      this.setState({
        sortConfig: {
          type: type,
          descending: !this.state.sortConfig.descending,
        },
      });
    }
  };

  render() {
    this.sortData();
    return (
      <div className="card card-custom">
        <div className="sponsors-container">
          <div className="sponsors-header">
            <div className="sponsors-title">Sponsori</div>
            <div className="sponsor-row-toggler">
              {this.state.seasons.map((item) => {
                return (
                  <div
                    className={
                      this.state.selectedYear == item.split("/")[0]
                        ? "season-option selected-option"
                        : "season-option "
                    }
                    onClick={() => {
                      this.setState({ selectedYear: item.split("/")[0] });
                      this.getSponsors(item.split("/")[0]);
                    }}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="sponsors-contracts-table-header">
            <div className="name-column">Nume partener</div>
            <div className="common-column">Tip sponsorizare</div>
            <div className="common-column">Data inceperii</div>
            <div className="common-column">Data incheierii</div>
            <div
              className="common-column"
              onClick={() => this.changeFilter("amount")}
              style={{ cursor: "pointer" }}
            >
              Valoare totala
              {this.state.sortConfig.type === "amount" &&
                (this.state.sortConfig.descending ? (
                  <Icon name="arrow down" className="arrow-icon-sponsors" />
                ) : (
                  <Icon name="arrow up" className="arrow-icon-sponsors" />
                ))}
            </div>
            <div className="common-column">Valoare platita</div>
            <div className="common-column"></div>
          </div>

          <div className="sponsors-contracts-table">
            {this.state.sponsors.map((item, index) => {
              return (
                <>
                  <div
                    className="sponsor-row"
                    style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "" }}
                  >
                    <div
                      className="name-column"
                      onClick={() =>
                        this.props.history.push(
                          `/financial/sponsors/${item.id_sponsor}`
                        )
                      }
                    >
                      <div className="image-container">
                        <img
                          onError={this.addDefaultSrc}
                          src={`https://api.thefootballbrain.app/uploads/sponsor_logos/${item.id_sponsor}.png`}
                          alt="sponsor_logo"
                        />
                      </div>
                      {item.sponsor_name}
                    </div>
                    <div className="common-column">
                      {item.barter == 1 ? "Barter" : "Finantare"}
                    </div>
                    <div className="common-column">
                      {item.contract_date_start !== "-" &&
                      item.contract_date_start !== null ? (
                        <Moment format="DD.MM.YYYY">
                          {item.contract_date_start}
                        </Moment>
                      ) : (
                        "-"
                      )}
                    </div>
                    <div className="common-column">
                      {item.contract_date_end !== "-" &&
                      item.contract_date_end !== null ? (
                        <Moment format="DD.MM.YYYY">
                          {item.contract_date_end}
                        </Moment>
                      ) : (
                        "-"
                      )}
                    </div>
                    <div className="common-column">
                      {this.formatNumber(item.total_amount)}
                      {item.total_amount > 0 && (
                        <Icon
                          name="eur"
                          className="eur-icon-sponsor"
                          size="small"
                        />
                      )}
                    </div>
                    <div className="common-column">
                      {this.formatNumber(item.paid_amount)}
                      {item.paid_amount > 0 && (
                        <Icon
                          name="eur"
                          className="eur-icon-sponsor"
                          size="small"
                        />
                      )}
                    </div>
                    <div className="common-column">
                      <div
                        className="eye-container"
                        onClick={() =>
                          this.props.history.push(
                            `/financial/sponsors/${item.id_sponsor}`
                          )
                        }
                      >
                        <EyeIcon />
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            <div className="sponsor-row total-row">
              <div className="name-column">TOTAL FINANTARI</div>
              <div className="common-column"></div>
              <div className="common-column"></div>
              <div className="common-column"></div>
              <div className="common-column" style={{ color: "#00d948" }}>
                {this.formatNumber(this.state.total)}
                {this.state.total > 0 && (
                  <Icon
                    name="eur"
                    size="small"
                    className="eur-icon-bonus-sponsor"
                  />
                )}
              </div>
              <div className="common-column" style={{ color: "#00d948" }}>
                {this.formatNumber(this.state.total_paid)}
                {this.state.total_paid > 0 && (
                  <Icon
                    name="eur"
                    size="small"
                    className="eur-icon-bonus-sponsor"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(Sponsors);
