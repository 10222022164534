import React, { CSSProperties } from "react";
import styles from "./TFBCheckbox.module.scss";
import { TCheckbox } from "../types";

const TFBCheckbox = ({
  onChange,
  label,
  style,
  startLabel,
  checked = false,
  className,
  labelColor,
}: TCheckbox) => {
  const color = labelColor ? labelColor : "#b2b2b2";

  const labelStyle: CSSProperties = {
    color: color,
    fontWeight: "bold",
    fontSize: "14px",
    fontFamily: "Poppins",
    margin: 0,
    cursor: "pointer"
  };

  return (
    <div
      className={`${styles.tfbCheckboxWrapper} ${className ? className : ""}`}
      style={{ flexDirection: startLabel ? "row-reverse" : "row", ...style }}
    >
      <input type="checkbox" onChange={onChange} checked={checked} />

      <label
        onClick={onChange}
        className={`${styles.tfbCheckboxLabel}`}
        style={labelStyle}
      >
        {label}
      </label>
    </div>
  );
};

export default TFBCheckbox;
