import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

// export const useStyles = makeStyles({
//   sticky: {
//     position: "sticky",
//     left: 0,
//     background: "white",
//     zIndex: 999,
//   },
// });

export const TFBTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#B2B2B2",
    backgroundColor: theme.palette.common.white,
    fontFamily: "Poppins",
    fontSize: "1em",
    fontWeight: 700,
    borderBottom: 0,
    minHeight: 40,
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Poppins",
    fontSize: "1em",
    color: "#666666",
    fontWeight: 700,
    borderBottom: 0,
    minHeight: 55,
    fontSize: 14,
  },
}));

// export const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));

export const sxStickyColumn = {
  position: "sticky",
  left: 0,
  background: "white",
  zIndex: 5,
};
