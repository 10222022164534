import React from "react";
import "./PlanTraining.scss";
import {
  TimePicker,
  DatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { TextArea, Checkbox, Input } from "semantic-ui-react";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TacticBoard from "../TacticBoard/TacticBoard";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import { addMinutes } from "date-fns";

import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton.tsx";
import TFBButton from "../../../components/design-system/Button/TFBButton.tsx";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon.tsx";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";

import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../components/reusable/useStyles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

class AddTraining extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      training_date: null,
      training_details: "",
      training_location: "",
      startHour: null,
      start_hour_mobile: null,
      endHour: null,
      end_hour_mobile: null,
      id_team: [],

      venuesOptions: [],
      venues: [],
      add_new_location: false,
      add_venue_to_mng: false,

      addSection: false,
      tacticBoardId: "",
      items: [],
      selected: [],
      clubTeams: [],
      added_section_id: null,
      added_section_name: "",
      isWritingInput: "",
      currentTotalTime: 0,
      error: "",

      //Mobile
      currentTotalTimeMobile: 0,
      open_add_section: true,
      section_to_add: false,
      sectionOptions: [],
      selectedSection: "",
      table_row_sections: [],
    };
  }

  id2List = {
    droppable: "items",
    droppable2: "selected",
  };

  componentDidMount = () => {
    this.getExistingParameters(this.state.id_team);
    this.getClubTeams();
    this.getClubVenues(this.state.id_team);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.languagePack != this.props.languagePack) this.getClubVenues();
  }

  getExistingParameters = (id_team) => {
    axios
      .get(
        `training/get_existing_sections?id_club=${this.props.currentUser.id_club}&id_team=`
      )
      .then((res) => {
        let categories = Array(...res.data);
        let existentCategories = this.state.items.map((elem) => elem.id);
        categories = categories.filter(
          (elem) => !existentCategories.includes(elem.id)
        );

        let sectionOptions = categories.map(
          ({ id: value, content: label }) => ({
            value,
            label,
          })
        );
        this.setState({ selected: categories, sectionOptions: sectionOptions });
      })
      .catch((err) => console.log(err));
  };

  getClubTeams = () => {
    axios
      .get(
        `training/get_teams_dropdown?id_club=${this.props.currentUser.id_club}&all=0`
      )
      .then((res) => {
        let teams = [];
        res.data.map((item) => {
          return teams.push({
            value: item.value,
            label: item.text,
          });
        });
        this.setState({ clubTeams: teams });
      })
      .catch((err) => console.log(err));
  };

  getClubVenues = () => {
    axios
      .get(`club/get_venues?id_club=${this.props.currentUser.id_club}`)
      .then((res) => {
        let venuesArray = [
          { value: "0", label: this.props.languagePack.add_new_match_venue },
          ...res.data,
        ];
        this.setState({
          venuesOptions: venuesArray,
        });
      })
      .catch((err) => console.log(err));
  };

  handleDateChange = (value) => {
    this.setState({ training_date: value });
  };

  handleHourStartChange = (value) => {
    this.setState({ startHour: value }, this.calcTrainingEnd);
  };

  handleHourStartChangeMobile = (value) => {
    this.setState({ start_hour_mobile: value }, this.calcTrainingEndMobile);
  };

  calcTrainingEnd = () => {
    const setHourEnd = addMinutes(
      this.state.startHour,
      this.state.currentTotalTime
    );
    this.setState({ endHour: setHourEnd });
  };

  calcTrainingEndMobile = () => {
    const setHourEnd = addMinutes(
      this.state.start_hour_mobile,
      this.state.currentTotalTimeMobile
    );
    this.setState({ end_hour_mobile: setHourEnd });
  };

  handleHourEndChange = (value) => {
    this.setState({ endHour: value });
  };

  handleHourEndChangeMobile = (value) => {
    this.setState({ end_hour_mobile: value });
  };

  timeTotal = () => {
    let sum = this.state.items.reduce(function(prev, current) {
      return prev + +current.value;
    }, 0);
    this.setState({ currentTotalTime: sum }, () => {
      if (this.state.startHour != null) {
        this.calcTrainingEnd();
      }
    });
  };

  timeTotalMobile = () => {
    let tableData = this.state.table_row_sections;
    let durationList = [];
    tableData.forEach((row) => {
      durationList.push(Number(row.duration ?? 0));
    });
    let sumTime = durationList.reduce((a, b) => a + b, 0);

    this.setState({ currentTotalTimeMobile: sumTime }, () => {
      if (this.state.start_hour_mobile != null) {
        this.calcTrainingEndMobile();
      }
    });
  };

  validateEffectiveDuration = (index) => {
    let sectionsList = [...this.state.items];

    function checkDifference(duration, effectiveDuration) {
      if (parseFloat(effectiveDuration) > parseFloat(duration)) {
        sectionsList[index].wrongEffectiveDuration = true;
      } else {
        sectionsList[index].wrongEffectiveDuration = false;
      }
    }

    if (
      sectionsList[index].effective_duration &&
      sectionsList[index].effective_duration != ""
    ) {
      checkDifference(
        sectionsList[index].value,
        sectionsList[index].effective_duration
      );
    } else {
      sectionsList[index].wrongEffectiveDuration = false;
    }

    this.setState({ items: sectionsList });
  };

  validateEffectiveDurationMobile = (index) => {
    let sectionsList = [...this.state.table_row_sections];

    function checkDifference(duration, effectiveDuration) {
      if (parseFloat(effectiveDuration) > parseFloat(duration)) {
        sectionsList[index].wrongEffectiveDuration = true;
      } else {
        sectionsList[index].wrongEffectiveDuration = false;
      }
    }

    if (
      sectionsList[index].effective_duration &&
      sectionsList[index].effective_duration != ""
    ) {
      checkDifference(
        sectionsList[index].duration,
        sectionsList[index].effective_duration
      );
    } else {
      sectionsList[index].wrongEffectiveDuration = false;
    }

    this.setState({ table_row_sections: sectionsList });
  };

  getList = (id) => this.state[this.id2List[id]];

  onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === "droppable2") {
        state = { selected: items };
      }

      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({
        items: result.droppable,
        selected: result.droppable2,
      });
    }
  };

  handleChangeInput = (e) => {
    let elementsIndex = this.state.items.findIndex(
      (el) => el.id === this.state.isWritingInput
    );
    let newArray = [...this.state.items];

    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      value: e.target.value,
    };

    this.setState({ items: newArray }, () => {
      this.timeTotal();
      this.validateEffectiveDuration(elementsIndex);
    });
  };

  handleChangeInputEffective = (e) => {
    let elementsIndex = this.state.items.findIndex(
      (el) => el.id === this.state.isWritingInput
    );
    let newArray = [...this.state.items];

    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      effective_duration: e.target.value,
    };

    this.setState({ items: newArray }, () =>
      this.validateEffectiveDuration(elementsIndex)
    );
  };

  handleChangeMobileInput = (index, durationValue) => {
    let tableDataRows = this.state.table_row_sections;
    tableDataRows[index].duration = durationValue;
    this.setState({ table_row_sections: tableDataRows }, () => {
      this.timeTotalMobile();
      this.validateEffectiveDurationMobile(index);
    });
  };

  handleChangeMobileEffectiveDur = (index, durationValue) => {
    let tableDataRows = this.state.table_row_sections;
    tableDataRows[index].effective_duration = durationValue;
    this.setState({ table_row_sections: tableDataRows }, () =>
      this.validateEffectiveDurationMobile(index)
    );
  };

  removeRowFromTable = (row) => {
    if (row.value != "") {
      const { duration, ...rowRemove } = row;
      let tableData = this.state.table_row_sections.filter(
        (section) => section.value != row.value
      );
      let sectionOptions = [...this.state.sectionOptions, rowRemove];
      this.setState({
        sectionOptions: sectionOptions,
        table_row_sections: tableData,
      });
    } else {
      let tableData = this.state.table_row_sections.filter(
        (section) => section.value != row.value
      );

      this.setState({
        table_row_sections: tableData,
      });
    }
  };

  convertToHrsMin = (val) => {
    const hours = Math.floor(val / 60);
    const min = val % 60;
    if (hours > 0) return hours + " h," + min + " min";
    else return min + " min";
  };

  removeElementFromList = (val) => {
    var array = [...this.state.items]; // make a separate copy of the array
    var index = array.findIndex((item) => item.id === val);
    if (index !== -1) {
      let copy = array[index];
      array.splice(index, 1);
      this.setState(
        {
          items: array,
          selected: this.state.selected.concat(copy),
        },
        this.timeTotal
      );
    }
  };

  addSectionToTable = () => {
    let tableSections = [
      ...this.state.table_row_sections,
      this.state.selectedSection,
    ];
    let selectedSection = this.state.selectedSection;
    let sectionOptions = this.state.sectionOptions;
    let newSectionOptions = sectionOptions.filter(
      (section) => section.value != selectedSection.value
    );

    this.setState({
      section_to_add: false,
      open_add_section: true,
      selectedSection: "",
      table_row_sections: tableSections,
      sectionOptions: newSectionOptions,
    });
  };

  createNewSection = () => {
    const newItem = {
      id: this.state.added_section_name,
      content: this.state.added_section_name,
      value: "",
    };

    const data = new FormData();
    data.append("id_club", this.props.currentUser.id_club);
    data.append("id_user", this.props.currentUser.id_user);
    data.append("section_name", this.state.added_section_name);

    if (this.state.added_section_name != "") {
      axios
        .post("training/add_training_section", data)
        .then((res) => {
          if (res.status == 200 && res.statusText == "OK") {
            this.setState({
              selected: this.state.selected.concat(newItem),
              added_section_name: "",
              addSection: false,
              error: "",
            });

            store.addNotification({
              title: this.props.languagePack["section_add_title"],
              message: this.props.languagePack["section_added_success"],
              type: "success",
              insert: "bottom",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
              },
            });

            this.getExistingParameters(this.state.id_team);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  submitTraining = () => {
    const data = new FormData();
    data.append("id_user", this.props.currentUser.id_user);
    data.append("id_team", JSON.stringify(this.state.id_team));
    data.append("id_club", this.props.currentUser.id_club);
    data.append("date", moment(this.state.training_date).format("YYYY-MM-DD"));
    data.append("start_hour", moment(this.state.startHour).format("HH:mm"));
    data.append("end_hour", moment(this.state.endHour).format("HH:mm"));
    data.append("description", this.state.training_details);
    if (this.state.add_new_location) {
      data.append("new_venue_name", this.state.venues);
      data.append(
        "add_venue_to_mng",
        { false: "0", true: "1" }[this.state.add_venue_to_mng]
      );
    } else {
      data.append("id_venue", this.state.venues);
    }
    data.append("sections", JSON.stringify(this.state.items));

    const addTraning = (payload) => {
      axios
        .post("training/add_training", payload)
        .then((res) => {
          store.addNotification({
            title: this.props.languagePack["training_add_title"],
            message: this.props.languagePack["training_success"],
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
          this.setState({ error: "" });
          this.props.history.push("/training/plan");
        })
        .catch((err) => console.log(err));
    };

    //############### Desktop view
    //##############################

    // if (this.state.items.length > 0) {
    if (
      this.state.add_new_location
        ? this.state.startHour &&
          this.state.endHour &&
          this.state.training_date &&
          this.state.id_team.length > 0 &&
          this.state.venues != ""
        : this.state.startHour &&
          this.state.endHour &&
          this.state.training_date &&
          this.state.id_team.length > 0
    ) {
      addTraning(data);
    } else {
      this.setState({
        error: this.props.languagePack["all_fields_mandatory_error"],
      });
    }
    // } else {
    //   this.setState({
    //     error: this.props.languagePack["no_sections_added_error"],
    //   });
    // }
  };

  submitTrainingMobile = () => {
    const data = new FormData();
    data.append("id_user", this.props.currentUser.id_user);
    data.append("id_team", JSON.stringify(this.state.id_team));
    data.append("id_club", this.props.currentUser.id_club);
    data.append("date", moment(this.state.training_date).format("YYYY-MM-DD"));
    data.append(
      "start_hour",
      moment(this.state.start_hour_mobile).format("HH:mm")
    );
    data.append("end_hour", moment(this.state.end_hour_mobile).format("HH:mm"));
    data.append("description", this.state.training_details);
    if (this.state.add_new_location) {
      data.append("new_venue_name", this.state.venues);
      data.append(
        "add_venue_to_mng",
        { false: "0", true: "1" }[this.state.add_venue_to_mng]
      );
    } else {
      data.append("id_venue", this.state.venues);
    }

    const addTraning = (payload) => {
      axios
        .post("training/add_training", payload)
        .then((res) => {
          store.addNotification({
            title: this.props.languagePack["training_add_title"],
            message: this.props.languagePack["training_success"],
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
          this.setState({ error: "" });
          this.props.history.push("/training/plan");
        })
        .catch((err) => console.log(err));
    };

    //############### Mobile view
    //##############################
    const tableData = this.state.table_row_sections.map(
      ({
        value: id,
        label: content,
        duration: value,
        effective_duration: effective_duration,
      }) => ({
        id: id == "" ? "-1" : id,
        content,
        value: value ? value : "",
        effective_duration: effective_duration ? effective_duration : "",
      })
    );
    data.append("sections", JSON.stringify(tableData));

    // if (this.state.table_row_sections.length > 0) {
    if (
      this.state.add_new_location
        ? this.state.start_hour_mobile &&
          this.state.end_hour_mobile &&
          this.state.training_date &&
          this.state.id_team.length > 0 &&
          this.state.venues != ""
        : this.state.start_hour_mobile &&
          this.state.end_hour_mobile &&
          this.state.training_date &&
          this.state.id_team.length > 0
    ) {
      addTraning(data);
    } else {
      this.setState({
        error: this.props.languagePack["all_fields_mandatory_error"],
      });
    }
    // } else {
    //   this.setState({
    //     error: this.props.languagePack["no_sections_added_error"],
    //   });
    // }
  };

  render() {
    const { languagePack } = this.props;
    return (
      <>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div className="row add-training-asd">
            <div className="col-lg-6 ">
              <TFBCard className="add-plan-training-container">
                <TFBCardHeader title={languagePack["create_training"]} />
                <div className="add-training-row">
                  <div style={{ marginBottom: 15 }}>
                    <TFBSelect
                      placeholder={languagePack.select_team_teams}
                      isMulti={true}
                      options={this.state.clubTeams}
                      onChange={(selected) => {
                        this.setState({ id_team: selected });
                      }}
                    />
                  </div>
                </div>
                <div className="plan-training-subtitle">
                  {languagePack["add_training_sections"]}
                </div>
                <div className="mobile-add-training-table">
                  {this.state.table_row_sections.length > 0 && (
                    <TableContainer>
                      <Table aria-label="simple table" sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TFBTableCell>
                              {languagePack.team_name}
                            </TFBTableCell>
                            <TFBTableCell align="center">
                              {languagePack.duration}
                            </TFBTableCell>
                            <TFBTableCell align="center">
                              {languagePack.effective_duration}
                            </TFBTableCell>
                            <TFBTableCell align="right">
                              {languagePack.product_actions}
                            </TFBTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.table_row_sections.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                backgroundColor:
                                  index % 2 == 0 ? "#fafafa" : "white",
                              }}
                            >
                              <TFBTableCell
                                component="th"
                                scope="row"
                                style={{
                                  backgroundColor:
                                    index % 2 == 0 ? "#fafafa" : "white",
                                }}
                              >
                                {row.label}
                              </TFBTableCell>

                              <TFBTableCell align="center">
                                <Input
                                  placeholder={languagePack.duration_minutes}
                                  className="final-drop-row-input"
                                  onChange={(e) => {
                                    this.handleChangeMobileInput(
                                      index,
                                      e.target.value
                                    );
                                  }}
                                  value={
                                    this.state.table_row_sections[index]
                                      .duration ?? ""
                                  }
                                />
                              </TFBTableCell>

                              <TFBTableCell align="center">
                                <Input
                                  placeholder={languagePack.duration_minutes}
                                  className={`final-drop-row-input ${row.wrongEffectiveDuration &&
                                    "wrong-effective-duration"}`}
                                  onChange={(e) => {
                                    this.handleChangeMobileEffectiveDur(
                                      index,
                                      e.target.value
                                    );
                                  }}
                                  value={
                                    this.state.table_row_sections[index]
                                      .effective_duration ?? ""
                                  }
                                />
                              </TFBTableCell>

                              <TFBTableCell align="right">
                                <div className="plan-training-actions-wrapper">
                                  <TFBIconButton
                                    name="x-small"
                                    onClick={() => this.removeRowFromTable(row)}
                                    color="darkGray"
                                  />
                                </div>
                              </TFBTableCell>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TFBTableCell colSpan={3}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: "0px 25px",
                                }}
                              >
                                <div>
                                  {languagePack.total_training_duration}
                                </div>
                                <div>
                                  {" "}
                                  {this.convertToHrsMin(
                                    this.state.currentTotalTimeMobile
                                  )}
                                </div>
                              </div>
                            </TFBTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  {this.state.open_add_section && (
                    <div style={{ marginBottom: 15, marginTop: 15 }}>
                      <TFBButton
                        onClick={() => {
                          this.setState({
                            section_to_add: true,
                            open_add_section: false,
                          });
                        }}
                        endIcon="true"
                        color="green"
                        renderIcon={() => <TFBIcon name="add" />}
                        style={{ width: "100%" }}
                      >
                        {languagePack.add_section}
                      </TFBButton>
                    </div>
                  )}

                  {this.state.section_to_add && (
                    <>
                      <div style={{ marginBottom: 15, marginTop: 15 }}>
                        <TFBSelect
                          placeholder={languagePack.add_section}
                          options={this.state.sectionOptions}
                          onChange={(selected) => {
                            this.setState({
                              selectedSection: selected,
                            });
                          }}
                          onBlur={(e) => {
                            if (e.target.value.length > 0) {
                              this.setState({
                                selectedSection: {
                                  value: "",
                                  label: e.target.value,
                                },
                              });
                            }
                          }}
                          value={this.state.selectedSection}
                        />
                      </div>

                      <div style={{ marginBottom: 15, marginTop: 15 }}>
                        <TFBButton
                          onClick={this.addSectionToTable}
                          endIcon="true"
                          color="green"
                          renderIcon={() => <TFBIcon name="add" />}
                          style={{ width: "100%" }}
                          disabled={
                            this.state.section_to_add &&
                            this.state.selectedSection == ""
                          }
                        >
                          {languagePack.add_section}
                        </TFBButton>
                      </div>
                    </>
                  )}
                </div>

                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className="plan-training-final-drop-area desktop-add-training-droppable"
                    >
                      <div className="sections-list">
                        {this.state.items.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="plan-training-final-drop-row"
                              >
                                <div className="final-drop-row-name">
                                  {item.content}
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <TFBIconButton
                                      name="edit"
                                      onClick={() =>
                                        this.setState({
                                          tacticBoardId: item.id,
                                        })
                                      }
                                      color="darkGray"
                                    />
                                    <TFBIconButton
                                      name="x-small"
                                      onClick={() =>
                                        this.removeElementFromList(item.id)
                                      }
                                      color="darkGray"
                                    />
                                  </div>
                                </div>
                                <input
                                  className="final-drop-row-input"
                                  placeholder={languagePack.duration_minutes}
                                  onFocus={() =>
                                    this.setState({ isWritingInput: item.id })
                                  }
                                  onChange={this.handleChangeInput}
                                ></input>
                                <input
                                  className={`final-drop-row-input ${item.wrongEffectiveDuration &&
                                    "wrong-effective-duration"}`}
                                  placeholder={languagePack.effective_duration}
                                  onFocus={() =>
                                    this.setState({ isWritingInput: item.id })
                                  }
                                  onChange={(e) => {
                                    this.handleChangeInputEffective(e);
                                  }}
                                ></input>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                      {provided.placeholder}
                      <div className="final-drop-area-total-row">
                        <div className="total-text">
                          {languagePack["total_training_duration"]}
                        </div>
                        <div className="total-value">
                          {this.convertToHrsMin(this.state.currentTotalTime)}
                        </div>
                      </div>
                    </div>
                  )}
                </Droppable>
                <div className="plan-training-subtitle">
                  {languagePack["training_details"]}
                </div>
                <div className="add-training-row">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="plan-training-row">
                      <DatePicker
                        value={this.state.training_date}
                        onChange={this.handleDateChange}
                        emptyLabel={languagePack["training_date"]}
                        format="dd MMMM yyyy"
                        inputVariant="outlined"
                        className="plan-training-date-input"
                      />

                      <TimePicker
                        showTodayButton
                        ampm={false}
                        emptyLabel={languagePack["start_hour"]}
                        value={this.state.startHour}
                        minutesStep={5}
                        inputVariant="outlined"
                        className="plan-training-hour-input training-start-hour-desktop"
                        onChange={this.handleHourStartChange}
                      />

                      {/* Training Hour start mobile */}
                      <TimePicker
                        showTodayButton
                        ampm={false}
                        emptyLabel={languagePack["start_hour"]}
                        value={this.state.start_hour_mobile}
                        minutesStep={5}
                        inputVariant="outlined"
                        className="plan-training-hour-input training-start-hour-mobile"
                        onChange={this.handleHourStartChangeMobile}
                      />
                      {/* &&&&&&&&&&&&&&&&&&&&& */}

                      <TimePicker
                        showTodayButton
                        ampm={false}
                        emptyLabel={languagePack["end_hour"]}
                        value={this.state.endHour}
                        minutesStep={5}
                        inputVariant="outlined"
                        className="plan-training-hour-input training-end-hour-desktop"
                        onChange={this.handleHourEndChange}
                      />

                      {/* Training Hour end mobile */}
                      <TimePicker
                        showTodayButton
                        ampm={false}
                        emptyLabel={languagePack["end_hour"]}
                        value={this.state.end_hour_mobile}
                        minutesStep={5}
                        inputVariant="outlined"
                        className="plan-training-hour-input training-end-hour-mobile"
                        onChange={this.handleHourEndChangeMobile}
                      />
                      {/* &&&&&&&&&&&&&&&&&&&&& */}
                    </div>
                  </MuiPickersUtilsProvider>
                </div>
                {this.state.endHour &&
                  this.state.startHour &&
                  this.state.endHour < this.state.startHour && (
                    <div className="plan-training-error-row add-training-hours-error-desktop">
                      {languagePack["hours_error_training"]}
                    </div>
                  )}

                {this.state.end_hour_mobile &&
                  this.state.start_hour_mobile &&
                  this.state.end_hour_mobile < this.state.start_hour_mobile && (
                    <div className="plan-training-error-row add-training-hours-error-mobile">
                      {languagePack["hours_error_training"]}
                    </div>
                  )}

                <div className="add-venues-dropdown">
                  <TFBSelect
                    placeholder={languagePack["select_match_venue"]}
                    value={this.state.location}
                    options={this.state.venuesOptions}
                    onChange={(selected) => {
                      if (selected.value == "0") {
                        this.setState({ add_new_location: true });
                        this.setState({ venues: "" });
                      } else {
                        this.setState({ add_new_location: false });
                        this.setState({ venues: selected.value });
                      }
                    }}
                  />
                </div>
                {this.state.add_new_location && (
                  <>
                    <div className="add-venues-input">
                      <input
                        placeholder={languagePack.training_location}
                        className="round-input"
                        value={this.state.venues}
                        onChange={(e) =>
                          this.setState({ venues: e.target.value })
                        }
                      />
                    </div>
                    <div className="add-venues-checkbox">
                      <Checkbox
                        label={languagePack.add_location_to_field_management}
                        checked={this.state.add_venue_to_mng}
                        onChange={(e, data) => {
                          this.setState({
                            add_venue_to_mng: data.checked,
                          });
                        }}
                      />
                    </div>
                  </>
                )}

                <TextArea
                  placeholder={languagePack["training_description_details"]}
                  name="training_details"
                  value={this.state.training_details}
                  onChange={(e) =>
                    this.setState({ training_details: e.target.value })
                  }
                  className="plan-training-text-area"
                  style={{ marginTop: "12px", marginBottom: "12px" }}
                />
                {this.state.error != "" && (
                  <div className="plan-training-error-row">
                    {this.state.error}
                  </div>
                )}
                <div className="add-training-row" style={{ marginTop: 15 }}>
                  <TFBButton
                    className="submit-add-training-desktop"
                    style={{ width: "100%" }}
                    onClick={this.submitTraining}
                    color="green"
                    disabled={
                      this.state.training_date == null ||
                      this.state.startHour == null ||
                      this.state.endHour == null
                    }
                  >
                    {languagePack["save_training"]}
                    <TFBIcon name="check" />
                  </TFBButton>
                  <TFBButton
                    className="submit-add-training-mobile"
                    onClick={this.submitTrainingMobile}
                    color="green"
                    style={{ width: "100%" }}
                    disabled={
                      this.state.training_date == null ||
                      this.state.start_hour_mobile == null ||
                      this.state.end_hour_mobile == null
                    }
                  >
                    {languagePack["save_training"]}
                    <TFBIcon name="check" />
                  </TFBButton>
                </div>
              </TFBCard>
            </div>
            <div className="col-lg-6 desktop-add-training-draggable">
              <TFBCard className="add-plan-training-container">
                <TFBCardHeader title={languagePack["training_sections"]}>
                  <TFBButton
                    onClick={() =>
                      this.setState({ addSection: !this.state.addSection })
                    }
                    endIcon="true"
                    color="green"
                    renderIcon={() => <TFBIcon name="add" />}
                  >
                    {languagePack["create_new_section"]}
                  </TFBButton>
                </TFBCardHeader>

                {this.state.addSection && (
                  <div className="add-section-row">
                    <input
                      value={this.state.added_section_name}
                      onChange={(e) =>
                        this.setState({ added_section_name: e.target.value })
                      }
                      placeholder={languagePack["add_new_section_name"]}
                      className="add-section-input"
                    />
                    <div className="actions-btns-container">
                      <TFBIconButton
                        name="add"
                        color="green"
                        onClick={this.createNewSection}
                      />
                      <TFBIconButton
                        name="cancel"
                        color="darkGray"
                        onClick={() =>
                          this.setState({
                            addSection: false,
                            added_section_name: "",
                          })
                        }
                      />
                    </div>
                  </div>
                )}
                <Droppable
                  droppableId="droppable2"
                  isDropDisabled
                  direction="horizontal"
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className="existing-sections-list"
                    >
                      {this.state.selected.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="existing-section-container"
                            >
                              {item.content}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </TFBCard>
            </div>
          </div>
        </DragDropContext>

        <Dialog
          open={this.state.tacticBoardId !== ""}
          onClose={() => this.setState({ tacticBoardId: "" })}
          maxWidth="xl"
        >
          <DialogContent>
            <TacticBoard
              closePopup={() => this.setState({ tacticBoardId: "" })}
              section_id={this.state.tacticBoardId}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(AddTraining);
