import React, { useEffect, useState } from "react";
import "./Sponsors.scss";
import axios from "axios";
import { Icon } from "semantic-ui-react";
import NumberFormat from "react-number-format";

export default function SponsorBonuses(props) {
  const [bonuses, setBonuses] = useState([]);
  const [season, setSeason] = useState(new Date().getFullYear() - 1);


  function getBonuses(season) {
    axios
      .get(
        `finances/get_sponsor_bonuses?id_sponsor=${props.id_sponsor}&season=${season}`
      )
      .then((res) => setBonuses(res.data))
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getBonuses(season);
  }, []);

  function formatNumber(value) {
    return (
      <NumberFormat
        value={value}
        displayType={"text"}
        thousandSeparator={true}
        decimalScale={0}
      />
    );
  }

  return (
    <div className="sponsor-bonuses-container">
      <div className="sponsor-row-bonuses-header">
        <div className="sponsor-row-title">Bonusuri active</div>
        <div className="sponsor-row-toggler">
          {props.seasons.map(item => {
            return (
              <div className={season == item.split('/')[0] ? "season-option selected-option" : "season-option"} onClick={() => {
                setSeason(item.split('/')[0])
                getBonuses(item.split('/')[0])
              }}>{item}</div>
            )
          })}


        </div>
      </div>
      <div className="sponsor-row-bonuses-list">
        <div className="sponsor-payment-row-header">
          <div className="bonus-name" style={{ width: '75%' }}>Conditie bonus</div>
          <div className="bonus-amount" style={{ width: '25%', textAlign: 'center' }}>Valoare</div>

        </div>
        {bonuses.length > 0 ? <>

          {bonuses.map((item) => {
            return (
              <div className="sponsor-bonus-row">
                <div className="bonus-name" style={{ width: '75%' }}>{item.bonus_condition}</div>
                <div className="bonus-amount" style={{ width: '25%' }}>
                  {formatNumber(item.bonus_amount)}
                  <Icon
                    name="eur"
                    className="eur-icon-bonus-sponsor"
                    size="small"
                  />
                </div>
              </div>
            );
          })}</>
          : <div className="sponsor-bonuses-placeholder">Nu exista bonusuri pentru sezonul selectat</div>}
      </div>
    </div>
  );
}
