export default {
    // Video Gallery
    add_video: "Añadir video",
    video_description: "Descripción del video",
    upload_video: "Subir vídeo",
    video_title: "Titulo del Video",

    // Campaigns
    campaign_title: "Campañas",
    campaign_name: "Nombre de campaña",
    campaign_type: "Tipo",
    campaign_status: "Estado",
    campaign_audience: "Audiencia",
    campaign_last_edited: "Última editada",
    campaign_opens: "Abre",
    campaign_clicks: "Accedidas",
    campaign_status_filter: "Filtrar por estado",
    campaign_type_filter: "Filtrar por tipo",
    campaign_pagination: "Resultados por página",
    campaign_your_audience: "Tu audiencia",
    campaign_choose_audience: "Elegir audiencia",
    campaign_new_segment: "Nuevo segmento",
    campaign_add_new_condition: "Agregar nueva condición",
    campaign_save_segment: "Guardar segmento",
    campaign_sender: "Remitente",
    campaign_name: "Nomber",
    campaign_subject: "Sujeto",
    campaign_enter_subject: "Introduce el sujeto",
    campaign_content: "Contenido",
    campaign_main_title: "Título principal",
    campaign_preview_article: "Vista previa del artículo",
    campaign_schedule_campaign: "Programar campaña",
    campaign_send_later: "Envíalo más tarde",
    campaign_send_test_message: "Enviar mensaje de prueba",
    campaign_save_message: "Guardar mensaje",
    campaign_save_as_template: "Guardar como plantilla",
    campaign_cancel: "Cancelación",
    campaign_is_before: "Es antes",
    campaign_is_after: "Es despues",
    campaign_specific_date: "Una fecha específica",
    campaign_range: "Un rango",
    campaign_contact_must_match: "El contacto debe coincidir",
    campaign_gender: "Género",
    campaign_birthday: "Cumpleaños",
    campaign_date_added: "Fecha agregada",
    campaign_view_email: "Vista email",
    campaign_download: "Descargar",
    campaign_print: "Impresión",
    campaign_top_links: "Enlaces principales en los que se hizo clic",
    campaign_view_all_links: "Ver todo links",
    campaign_recipients: "Destinatarios",
    campaign_total_recipients: "Destinatarios totales",
    campaign_total_opens: "Abrió",
    campaign_total_clicked: "Hizo clic",
    campaign_total_bounced: "Rechazar",
    campaign_total_unsubscribed: "Dado de baja",
    campaign_delivered: "Entregado",
    email_campaign: "Campaña de e-mail",
    campaign_report: "Informe de campaña",
    campaign_view_all: "Ver todo",
    campaign_subscribers_most_opens: "Suscriptores con más aperturas",
    campaign_numbers_stats: "Números y estadísticas",
    campaign_abuse_reports: "Informes de abuso",
    campaign_last_clicked: "Último clic",
    campaign_total_clicks: "Total de clics",
    campaign_clicks_per_unique_opens: "Clics por aperturas únicas",
    campaign_forwarded: "Reenviado",
    campaign_last_opened: "Última apertura",
    campaign_total_opens: "Aperturas totales",
    campaign_successful_deliveries: "Entregas exitosas",


    //Fans section
    tax_redirectors_title: "Redirecciones de impuestos a las ganancias",
    tax_redirectors_total: "Redireccionamientos totales",
    tax_redirector_name: "Nombre y apellido",
    tax_redirector_register_date: "Fecha de Registro",
    tax_redirector_email: "Dirección de correo electrónico",
    tax_redirector_phone_number: "Número de teléfono",

    //Site administration
    about_us_title: "Sobre nosotros",
    upload_main_image: "Cargar la imagen principal",
    upload_another_main_image: "Cargar otra imagen principal",
    sections: "Secciones",
    section: "Sección",
    admin_add: "Agregar",
    admin_cancel: "Cancelar",
    admin_delete: "Borrar",
    upload_image: "Cargar imagen",
    upload_other_image: "Cargar otra imagen",
    admin_save: "Confirmar",
    admin_cancel_modif: "Descartar los cambios",
    admin_preview: "Vista previa de la pàgina",
    gallery_title: "Vista previa de la galería",
    gallery_photos_title: "Galería de fotos",
    gallery_videos_title: "Galería de vídeos",
    gallery_bio_title: "Biografía",
    gallery_add_button: "Agregar imagen / imágenes",
    gallery_add_button_videos: "Agregar video / videos",
    gallery_add_button_bio: "Agregar / editar biografía",
    gallery_placeholder: "Actualmente no ha subido ninguna imagen.",
    gallery_videos_placeholder: "Actualmente no has subido ningún video.",
    gallery_bio_placeholder: "Actualmente no has añadido ninguna biografía.",

    partners_title: "Socios y patrocinadores",
    add_partner_button: "Agregar socio",
    sponsor_name_form: "Nombre del socio",
    sponsor_site_form: "Sitio URL",
    sponsor_logo_form: "Cargar logo (.png)",
    sponsor_site_notif_title: "Administrar socios",
    sponsor_site_notif_success: "El socio se ha agregado correctamente",
    delete_partner: "Eliminar socio",
    delete_partner_popup_text:
        "¿Está seguro de que desea eliminar a este socio?",
    admin_confirm: "Confirmo",
    delete_sponsor_notif_text: "El socio se ha eliminado con éxito",

    admin_contact_title: "Dato de contacto",
    admin_contact_phone: "Número de teléfono",
    admin_contact_address: "Dirección",
    admin_contact_org_name: "Nombre de la Organización",
    admin_contact_save_changes: "Guardar cambios",
    admin_contact_notif_title: "Gestión de datos de contacto",
    admin_contact_notif_text_success: "¡Los cambios se han guardado con éxito!",

    gallery_delete_popup_title: "Eliminar imagen",
    gallery_delete_popup_text:
        "¿Estás seguro de que deseas eliminar esta imagen?",
    upload_images_popup_title: "Cargar imagenes",
    gallery_upload_select_file: "Seleccione archivo",
    gallery_upload_select_files: "Seleccione archivos",
    gallery_upload_nr_singular: " imagen cargada",
    gallery_upload_nr_plural: "imágenes cargadas",
    gallery_notification_title: "Galería",
    gallery_notification_success: "¡Archivos cargados con éxito!",
    file_remove_success_title: "Archivo eliminado con éxito.",
    general_error_message: "Ocurrió un error, inténtalo de nuevo más tarde.",
    news_page_title: "Últimas noticias agregadas",
    news_page_add_button: "Agregar noticias",
    news_page_header_title: "Título",
    news_page_header_tag: "Etiqueta",
    news_page_header_date: "Fecha de publicación",
    news_page_header_options: "Opciones",
    news_page_delete_popup_title: "Eliminar artículo",
    news_page_delete_popup_text:
        "¿Estás seguro de que deseas eliminar esta noticia? La acción es irreversible.",
    add_news_title: "Agregar noticias",
    add_news_article_title: "Título de la noticia",
    add_news_publish_button: "Publicar artículo",
    add_news_cancel_button: "Descartar artículo",
    article_preview: "Vista previa del artículo",
    edit_news_title: "Editar noticias",
    edit_news_delete_button: "Eliminar artículo",
    edit_section: "Editar sección",

    //Medical
    active_injuries_title: "Lesiones actuales",
    active_injuries_add_button: "Agregar lesión",
    active_injuries_player_name: "Nombre del jugador",
    active_injuries_type: "Tipo de lesión",
    active_injuries_injury_date: "Fecha de la lesión",
    active_injuries_estimated_recovery: "Recuperación estimada",
    injury_details: "Detalles de la lesión",
    add_injury_popup_title: "Agregar nueva lesión",
    injury_zone: "Zona",
    edit_details: "Editar detalles",
    treatment: "Tratamiento",
    injury_edit: "Editar",
    add_treatment: "Agregar tratamiento",
    end_injury: "Terminar lesión",
    select_team: "Selecciona un equipo",
    select_player: "Seleccionar jugador",
    select_zone: "Seleccionar zona",
    start_date: "Fecha de inicio",
    end_date: "Fecha final",
    return_date: "Fecha de retorno",
    treatment_details: "Detalles del tratamiento",
    injuries_history: "Historial de lesiones",
    absence_period: "Periodo de ausencia",
    no_treatment_found: "No se encontró tratamiento",
    total_treatment_cost: "Costo total del tratamiento",
    injury_date: "Fecha",
    injury_day: "día",
    injury_days: "días",
    total_cost: "Costo total",
    no_injuries_found:
        "No se encontraron lesiones durante el período seleccionado",
    injury_change_success: "La lesión seleccionada se ha modificado con éxito!",
    injury_close_success: "La lesión terminó en la fecha seleccionada",
    injury_delete_success: "La lesión seleccionada se ha eliminado con éxito!",
    treatment_delete_success:
        "El tratamiento seleccionado se ha eliminado con éxito!",
    injury_management_title: "Manejar lesiones",
    injury_added_success: "La lesión se agregó con éxito",
    treatment_edit_success: "El tratamiento seleccionado se editó con éxito!",
    cost: "Costo",
    staff_members: "Los miembros del personal",
    roles_list: "Lista de roles",
    add_member: "Añadir miembro",
    staff_member_name: "Nombre",
    staff_member_role: "Rol",
    edit_profile: "Editar perfil",
    staff_full_name: "Nombre y apellido",
    staff_description: "Descripción",
    edit_staff_member_popup_title: "Perfil del miembro del personal",
    edit_staff_member_popup_message: "Detalles guardados con éxito!",
    delete_staff_member_message:
        "El perfil seleccionado se ha eliminado correctamente!",
    popup_delete_staff_member_title: "Borrar perfil",
    popup_delete_staff_member_message:
        "¿Estás seguro de que deseas eliminar este perfil? La acción es irreversible.",
    add_new_role: "Agregar nuevo rol",
    add_role: "Agregar rol",
    manage_role_notification_title: "Roles de gestión del club",
    manage_role_notification_succes: "¡Rol agregado con exito!",
    manage_role_notification_fail:
        "¡La función seleccionada se ha eliminado con exito!",
    active_teams: "Equipos activos",
    add_team: "Agregar equipo",
    no_teams_yet: "Actualmente el club no tiene ningún equipo",
    team_type: "El tipo",
    team_name: "Nombre del equipo",
    team_logo: "Logo del equipo",
    number_players: "Numero de jugadores",
    team_coach: "Entrenador",
    team_coordinator: "Coordinador/Entrenador",
    teams_notification_title: "Gestionar equipos",
    teams_notification_success: "¡El equipo se agregó con éxito!",
    team_players: "Jugadores",
    team_performance: "El rendimiento del equipo",
    add_player: "Agregar jugador",
    PLAYER_NAME: "Nombre del jugador",
    NATIONALITY: "Nacionalidad",
    POSITION: "Posición",
    AGE: "Edad",
    MARKET_VALUE: "Valor de mercado",
    average: "Promedio",
    age_years: "Años",
    minutes_played: "Minutos jugados",
    no_players_placeholder: "Este equipo no tiene jugadores",
    edit_team_name: "Editar el nombre del equipo",
    birth_date: "Fecha de cumpleaños",

    //Player profile
    birthplace: "Fecha de cumpleaños",
    player_age: "Edad",
    weight: "Peso",
    height: "Altura",
    select_year: "Seleccionar año",
    section_access_forbidden: "No tienes acceso a esta sección",
    delete_player: "Eliminar jugador",
    delete_player_popup_text:
        "¿Estás seguro de que quieres eliminar a este jugador de este equipo? La acción es irreversible.",
    change_player_team: "Cambiar jugadores del equipo",
    select_new_team: "Seleccionar nuevo equipo",
    player_transactions_history: "Historial de transacciones realizadas",
    transaction_description: "Descripción",
    transaction_date: "Fecha de Transacción",
    transaction_type: "Tipo",
    trasnaction_amount: "Monto",
    total_expenses: "Gastos totales",
    contract_details: "Detalles del contrato",
    active_contract: "Contrato activo",
    active_bonuses: "Bonificaciones activas",
    expiry_date: "Fecha de expiración",
    monthly_wage: "Salario mensual",
    placeholder_bonuses: "Sin bonificación añadida",
    starting_date: "Fecha de inicio",
    shirt_number: "Número de camiseta",
    move_player: "Cambiar equipo",
    edit_photo: "Editar foto",

    //Aside main menu

    Club: "Mi club",
    dashboard: "Dashboard",
    trainings: "Sesiones de entrenamiento",
    Reports: "Informes",
    finances: "Finanzas",
    medical: "Médicos",
    documents: "Documentos",
    fans_relation: "Relación de fans",
    site_management: "Manejo de sitio",
    toggle_aside: "Alternar a un lado",
    Users: "Usuarios",
    shop: "Tienda",

    //Aside menu list

    player_contracts: "Contratos de jugadores",
    staff_contracts: "Contratos de personal",
    injuries: "Lesiones",
    tax_redirectors: "Redirectores de impuestos",
    news: "Noticias",
    cashflow: "Flujo de efectivo",
    transactions: "Actas",
    sponsors: "Patrocinadores",
    clients: "Clientes",
    providers: "Proveedores",
    wages_costs: "Costos salariales",
    match_costs: "Costes del partido",
    my_list: "Mi lista",
    plan_training: "Planificar la formación",
    trainings_history: "Historial de sesiones de entrenamiento",
    weight_control: "Control de peso",
    teams: "Equipos",
    matches_schedule: "Calendario de partidos",
    competitions: "Competiciones",
    contact_data: "Dato de contacto",
    fans_list: "Lista de fans",
    import_data: "Datos de importacion",
    categories: "Categorías",
    products: "Productos",
    orders: "Pedidos",

    //Quick user
    change_password: "Cambiar la contraseña",
    your_currency: "Moneda seleccionada",
    no_club: "Sin club",
    sign_out: "Cerrar sesión",
    user_profile: "Perfil del usuario",
    password_reset: "Restablecer la contraseña",
    account_settings: "Configuraciones de la cuenta",
    password_success_text: "La contraseña ha sido cambiada con éxito",

    //Dashboard

    end_of_month_sold: "Ventas a fin de mes",
    weekly_report: "Reporte semanal",
    sum: "Monto",
    period: "Período",
    position: "Posición",
    ranking_points: "Puntos",
    market_value_updates: "Actualizaciones de valor de mercado",
    increase: "Incrementos",
    current_value: "Valor actual",
    calendar_title: "Planificador de eventos",
    label_color: "Color de la etiqueta",
    description: "Descripción",
    event_details: "Detalles del evento",
    location: "Localización",
    hour: "Hora",

    //Matches list

    round: "Ronda",
    match: "Partido",
    select_competition: "Seleccionar competencia",
    past_matches: "Partidos pasados",
    future_matches: "Futuros partidos",
    match_events: "Eventos de partidos",
    starting_lineup: "Alineaciones iniciales",
    substitutes: "Suplentes",
    add_event: "Agregar jugador/jugadora",
    yellow_card: "Tarjeta amarilla",
    red_card: "Tarjeta roja",
    substitution: "Sustitución",
    goal: "Gol",
    event_successfully_added: "Evento agregado con éxito!",
    event_minute: "Minuto del evento",
    select_team: "Selecciona un equipo",
    select_event_type: "Seleccionar tipo de evento",
    select_player: "Seleccionar jugador",
    select_player_in: "Seleccionar jugador en",
    select_player_out: "Seleccionar jugador fuera",
    select_goal_type: "Seleccionar tipo de gol",
    single_goal: "Gol",
    goal_assist: "Gol + asistencia",
    select_scoring_player: "Seleccionar jugador goleador",
    select_assisting_player: "Seleccionar jugador asistente",
    player_lineup_successfully_added: "El jugador de la alineación se agregó con éxito!",
    player_sub_successfully_added: "Jugador de sustitución añadido con éxito!",


    //Team players list
    vfm:
        "VFM muestra si un jugador merece el salario que gana actualmente, comparándolo con su desempeño en el campo. Un índice alto significa que un jugador gana menos de lo que realmente se merece, en comparación con el nivel del equipo. Cuanto menor sea el índice, mayor será el contraste entre rendimiento y ganancia.",
    pta:
        "La PTA muestra si existe una correlación entre la cantidad de minutos jugados durante la temporada y el desempeño actual en el campo. Cuanto menor sea el índice, mayor será el contraste entre el rendimiento y el número de minutos (un jugador que ha jugado demasiado / muy pocos).",
    instat_index:
        "InStat Index ayuda a determinar la clase del jugador en función de un algoritmo automático que considera la contribución del jugador al éxito del equipo, la importancia de sus acciones, el nivel del oponente y el nivel del campeonato en el que juegan.",
    instat_index_title: "Instat índice",

    //Providers components

    current_season_stats: "Estadísticas de la temporada actual",
    match_sg: "partido",
    match_pl: "partidos",
    starting_lineup_matches_sg: "partido en el 11 inicial",
    starting_lineup_matches_pl: "partidos en el 11 inicial",
    minutes_played: "Minutos jugados",
    goals_scored_sg: "gol anotado",
    goals_scored_pl: "goles anotados",
    clean_sheets_sg: "hoja limpia",
    clean_sheets_pl: "hojas limpias",
    yellow_cards_sg: "tarjeta amarilla",
    yellow_cards_pl: "tarjetas amarillas",
    red_cards_sg: "tarjeta roja",
    red_cards_pl: "tarjetas rojas",
    add_name: "Ingrese el nombre",
    select_existing_partners: "Seleccionar partners existentes",
    balance_at_end_month: "Saldo al final del mes",
    or_lowercase: "o",
    supplier_operations: "Operaciones de proveedores",
    supplier_success: "El proveedor ha sido agregado exitosamente",
    add_supplier: "Agregar proveedor",

    //Trainings

    trainings_evolution: "Evolución de las sesiones de entrenamiento",
    physical_tracking_results: "Resultados del seguimiento físico",
    heart_rate: "Ritmo cardiaco",
    distance_covered: "Distancia recorrida",
    kcal_spent: "Calorías",
    sprint_counter: "Contador de sprint",
    speed: "Velocidad",
    medium: "Medio",
    trainings_history: "Historial de sesiones de entrenamiento",
    start_hour: "Hora de inicio",
    end_hour: "Hora final",
    training: "Sesión de entrenamiento",
    training_schedule: "Programa de entrenamiento",
    hours_interval: "Espacio horario",
    optimal_weight: "Peso óptimo",
    add_weight: "Agregar peso",
    measure_date: "Fecha de la medida",
    weight_value: "Valor",

    //Documents

    file_details: "Detalles del archivo",
    upload_file: "Cargar archivo",
    add_new_category: "Cargar nueva categoria",
    remove_contract: "Terminar contrato",
    remove_contract_date: "Fecha de expiración",
    remove_contract_confirm: "Confirmo",
    active_contracts: "Contratos activos",
    bonuses_list: "Lista de Bonificaciones",
    add_contract: "Agregar contrato",
    occupation: "Ocupación",
    choose_staff_member: "Elija miembro del personal",
    contract_bonuses: "Bonificaciones de contrato",
    signing_date: "Fecha de firma",
    add_contract_extra_bonuses_text:
        "¿Está seguro de que desea eliminar esta competencia? Se perderán todos los datos.",
    select_condition: "Seleccionar condición",
    add_condition_value: "Insertar valor de condición",
    add_condition: "Agregar condición",
    active_conditions: "Condiciones activas",
    bonus: "Prima",

    //Training plan

    create_training: "Crear sesión de entrenamiento",
    add_training_sections: "Agregar secciones",
    total_training_duration: "Tiempo Total",
    training_details: "Detalles de entrenamiento",
    hours_error_training:
        "La hora de finalización debe ser mayor que la hora de inicio",
    save_training: "Guardar sesión de entrenamiento",
    training_sections: "Secciones de entrenamiento",
    create_new_section: "Crear nueva sección",
    add_new_section_name: "Nuevo nombre de sección",
    add_training_session: "Agregar sesión de entrenamiento",
    training_add_title: "Gestión de entrenamientos",
    training_success: "Entrenamiento agregado con éxito!",
    section_add_title: "Gestión de secciones",
    section_added_success: "Sección agregada con éxito!",
    training_date: "Fecha",
    training_description_details: "Descripción/Detalles",

    //Matches

    match_date: "Fecha del partido",
    away_team: "Equipo visitante",
    home_team: "Equipo local",
    round_number: "Número",
    round_number_name: "Nombre del Partido",
    add_match: "Agregar partido",
    match_successfully_added: "¡Partido agregado con éxito!",
    competitions_actions: "Competiciones",
    new_team: "Nuevo equipo",
    clubs_team: "Equipos del club",
    add_competition: "Agregar competencia",
    competition_name: "Nombre de la competencia",
    competition_type: {
        name: "Tipo de competición",
        elements: {
            championship: "Campeonato",
            knockout: "Fase eliminatoria",
            group_stage: "Grupos + fase eliminatoria"
        }
    },
    teams_number: "Numero de equipos",
    ranking: "Clasificación",
    edit_team: "Editar equipo",
    points_short: "puntos",
    manage_competitions: "Gestionar competiciones",
    manage_competitions_success_changes: "Los cambios se guardaron con éxito",
    active_competitions: "Competiciones activas",
    competition_deleted_success: "Competencia eliminada con éxito",
    round_deleted_success: "Ronda eliminada con éxito",
    match_deleted_success: "Partido eliminado con éxito",
    match_delete_popup_title: "Eliminar partido",
    match_delete_popup_text:
        "¿Está seguro de que desea eliminar esta partido? Se perderán todos los datos.",
    fixture_table: "Fixture del torneo",
    delete_competition: "Eliminar competencia",
    delete_round: "Eliminar ronda",
    edit_round: "Editar ronda",
    round: "Jornada",
    new_round_name: "Nuevo nombre de ronda",
    delete_competition_text:
        "¿Está seguro de que desea eliminar esta competencia? Se perderán todos los datos.",
    edit_competition: "Editar competencia",
    edit_score: "Editar resultado",
    no_matches_for_selected_options:
        "No hay partidos disponibles para las opciones seleccionadas",

    player_tech_parameters: "Parámetros técnicos del jugador",
    parameters_list: "Lista de parámetros",
    teams_stats: "Estadísticas del equipo",
    administrate_teams: "Administrar equipos",
    administrate_teams_success: "El nombre del equipo se cambió correctamente!",
    administrate_teams_delete: "El equipo ha sido eliminado con éxito!",

    // Reports
    add_report: "Agregar informe",
    made_by: "Hecho por",
    player_comparison: "Comparación de jugadores",
    evaluated_params: "Parámetros evaluados",
    parameter: "Parámetro",
    unit_of_measure: "Unidad de medida",
    evaluator_name: "Nombre del evaluador",
    report_name: "Nombre del reporte",
    parameters_added: "parámetros agregados",
    select_parameter: "Seleccionar parámetro",
    create_new_parameter: "Crear nuevo parámetro",
    unit_of_measure: "Unidad de medida",
    add_parameter: "Agregar parámetro",
    evaluation_list: "Lista de evaluación",
    choose_player: "Elige un jugador",
    add_value: "Ingrese valor",
    add_data: "Ingrese los datos",
    evaluation: "Evaluación",
    player_added: "Jugador agregado",
    save_report: "Guardar reporte",
    add_parameter_warning_notification_title: "Agregar informe",
    add_parameter_warning_notification_text: "Seleccione un parámetro.",
    add_player_warning_notification_title: "Agregar informe",
    add_player_warning_notification_text:
        "Seleccione un jugador antes de agregar datos. Si no hay jugadores disponibles en la lista, asegúrese de haber seleccionado el equipo.",

    // Financial
    transaction_history: "Historial de transacciones",
    current_balance: "Saldo actual",
    select_interval: "Seleccionar intervalo",
    select_type: "Seleccionar tipo",
    items_on_page: "Elementos en la página",
    total_income: "Ingresos totales",
    total_expense: "Gastos total",

    // Scouting
    saved_shortlists: "Listas cortas guardadas",
    create_shortlist: "Crear lista reducida",
    shortlist_name: "Nombre de lista corta",
    formation: "Formación",
    selected_players: "Jugadores seleccionados",
    position: "Posición",
    date_created: "Fecha de creacion",
    shortlist_url: "Listas",

    //Products
    add_product: "Agregar producto",
    product_image: "Imagen del producto",
    product_name: "Nombre del producto",
    product_description: "Descripción",
    product_price: "Precio",
    product_status: "Estado",
    product_actions: "Comportamiento",
    product_is_active: "El producto está activo",

    //Orders
    order_id: "ID",
    order_date: "Fecha de orden",
    order_customer: "Cliente",
    order_shipping_method: "Método de envío",
    order_status: "Estado",
    order_amount: "Monto",
    order_actions: "Comportamiento",
    

    // Fan engagement
    combine_fan_profiles: "Combinar perfiles de los aficionados",
    filter_profiles: "Filtrar",

    // Months
    month_1: "enero",
    month_2: "febrero",
    month_3: "marzo",
    month_4: "abril",
    month_5: "mayo",
    month_6: "junio",
    month_7: "julio",
    month_8: "agosto",
    month_9: "septiembre",
    month_10: "octubre",
    month_11: "noviembre",
    month_12: "diciembre",

    //=========================================================================================================== Old translations
    test: "prueba",
    Name: "Nombre",
    player_contracts: "Contratos de jugadores",
    sponsor_contracts: "Contratos de patrocinador",
    contracts: "Contratos",
    bonuses: "Bonificaciones",
    add_new_file: "Agregar nuevo archivo",
    add_document: "Agregar documento",
    player_contract: "Contrato de jugador",
    add_player_contract: "Agregar contrato de jugador",
    file_name: "Nombre del archivo",
    category: "Categoría",
    date_add: "Fecha Agregada",
    added_by: "Añadido por",
    new_member: "Nuevo miembro",
    new_report: "Nuevo reporte",
    players: "Jugadores",

    budget: "Presupuesto",
    add_new_member: "Agregar nuevo miembro",

    cashflow: "Flujo de efectivo",
    user_profile: "Perfil del usuario",
    no_club: "Sin club",
    no_team: "Sin equipo",
    sign_out: "Desconectar",
    find_a_player: "Encontrar un jugador",
    position: "Posición",
    Nationality: "Nacionalidad",
    League: "Liga",
    Age: "Edad",
    Reset_filters: "Restablecer filtros",
    Advanced_search: "Búsqueda Avanzada",
    Add_to_My_List: "Agregar a mi lista",
    transactions: "Actas",

    Order_by: "Ordenar por",
    Discover_more: "Descubrir más",
    My_list: "Mi lista",
    Moneda: "Divisa",
    Cashflow: "Flujo de efectivo",
    There_are_no_records_to_display: "No hay nada para mostrar",
    Bonuses: "Bonificaciones",
    PLAYER_NAME: "Nombre del jugador",
    NATIONALITY: "Nacionalidad",
    POSITION: "Posición",
    AGE: "Edad",
    MARKET_VALUE: "Valor de mercado",
    Currency: "Divisa",
    Cancel: "Cancelar",
    Add: "Agregar",
    PROFIT: "EL BENEFICIO",
    SPENDING: "GASTO",
    Add_category_to: "Agregar categoría a",
    Players: "Jugadores",
    CashFlow: "Flujo de efectivo",
    transfer_history: "Historial de transferencias",
    finances: "Finanzas",
    overview: "Visión general",
    current_season: "Actual temporada",
    advanced_standings: "Clasificación avanzada",
    fixtures_schedule: "Accesorios",
    team_statistics: "Estadísticas del equipo",
    player_search: "Búsqueda de jugadores",
    search: "Buscar...",
    show: "¡A ver!",
    extra_notes: "Notas adicionales:",
    choose_categ: "Elegir la categoría",
    new_categ: "Nuevo nombre de categoría ...",
    active_crtierias: "Criterios activos:",
    Nationality: "Nacionalidad",
    League: "Liga",
    Position: "Posición",
    see_results: "Ver resultados",
    test_name: "Nombre de la prueba",
    evaluation_date: "Fecha de evaluación",
    evaluation_year: "Año",
    see_test_details: "Ver detalles de la prueba",
    players_eval: "Jugadores evaluados",
    latest_reports: "Últimos informes",

    team_type: "El tipo",
    team_name: "Nombre del equipo",
    number_players: "Numero de jugadores",
    see_more: "Ver más",

    Login_Account: "Inicio de sesión",
    Welcome_to: "Bienvenido a:",
    Privacy: "Privacidad",
    Legal: "Legales",
    Contact: "Contacto",
    Enter_your_username_and_password:
        "Ingrese su nombre de usuario y contraseña",

    Sign_In: "Iniciar sesión",
    Dont_have_an_account_yet: "¿Aún no tienes una cuenta?",
    Sign_Up_1: "¡Inscribirse!",
    Enter_your_details_to_create_your_account:
        "Ingrese sus datos para crear su cuenta",
    Full_name: "Nombre completo",
    Email: "email",
    User_name: "Nombre de usuario",
    Password: "Contraseña",
    Confirm_Password: "Confirmar Contraseña",
    I_aggree_with_terms_and_conditions: "Acepto los términos y condiciones",
    Choose_image: "Elegir imagen",
    Submit: "Submit",
    Required_field: "Campo requerido",
    Password_and_Confirm_Password_didnt_match:
        "Contraseña y Confirmar contraseña no coinciden",

    The_login_detail_is_incorrect:
        "Los datos de inicio de sesión son incorrectos",

    Add_team_bonus: "Agregar bono de equipo",
    Add_players_bonus: "Agregar bono de jugadores",
    Bonus_history: "Historial de bonificaciones",
    Total_sum: "Monto total",
    Bonus_name: "Nombre de bonificación",
    Bonus_type: "Tipo de bonificación",
    Bonus_condition: "Condición de bonificación",
    Payment_status: "Estado de pago",
    Date_added: "Fecha Agregada",
    EUR: "EUR",
    RON: "RON",
    Fullfiled: "Cumplido",
    In_pending: "Pendiente",
    Paid: "Pagado",
    Delete: "Borrar",
    Change_status: "Cambiar Estado",
    Add_bonus: "Agregar bonificación",
    For_a_match: "Para un partido",
    Selected_step: "Semana de partido seleccionada",
    Totol_assigned_sum: "Suma total asignada",
    GoBack: "Atrás",
    Bonus_beneficiary: "Beneficiario",
    Competition: "Competencia",
    For_the_full_season: "Por toda la temporada",
    Select_match: "Seleccionar partido",
    Assignment_criteria: "Criterios de asignación",
    Bonus_position: "Llega a los primeros ... lugares",
    All_competitions: "Todas las competiciones",
    placeholder_documents: "Aún no se han subido documentos",

    Assignment_criterias: "Criterios",
    Select_bonus_type: "Tipo de bonificación",
    select_cup_phase: "Fase de copa ...",

    Ranking: "Posición",
    Custom_duration: "Duración personalizada",

    Ranking_the_first_n_places: "Ranking en las primeras ... posiciones",
    Qualification_in_the_play_off: "Llegar a la play-off",
    Ranking_for_european_cup: "Colocación en la posición de Copas de Europa",
    Ranking_for_uefa_champions_league:
        "Colocación en la posición de la UEFA Champions League",
    Qualification_in_the_romanian_cup: "Colocación en la Copa de Rumania",
    Competiion_final: "Final",
    Competiion_semi_final: "Semifinal",
    Competiion_quarters_final: "Cuartos de final",
    Competiion_eighths_final: "Octavos de final",
    Competiion_sixteeths_final: "Diesciseisavos de final",
    All_players: "Todos los jugadores",
    Player_holders: "Alineación inicial",
    Player_and_Staff: "Jugadores y Personal",
    Individual: "Individual",
    First_league: "Primera liga",
    Romanian_Cup: "Copa de Rumania",
    Romanian_supercup: "Supercopa de Rumania",
    Goal_scored: "Gol anotado",
    Other_crieteria: "Otros criterios",
    Staff_bonus: "Personal",
    Another_bonus: "Otro receptor",

    Final_result_win: "Resultado final: Victoria",
    Final_result_draw: "Resultado final: Empate",
    Next: "Próximo",

    Add_contract_details: "Detalles del contrato",
    Contract_extra: "Condiciones extra",
    Upload_file: "Cargar archivo",
    player_name: "Nombre del jugador",
    contract_period: "Período de contrato",
    Other: "Otro",
    choose_team: "Elige equipo",
    team: "Equipo",
    average_rating: "Puntuación media",

    one_year: "Un año",
    one_year_half: "Un año y medio",
    two_years: "Dos años",
    two_years_half: "Dos años y medio",
    three_years: "Tres años",
    three_years_half: "Tres años y medio",
    four_years: "Cuatro años",
    four_years_half: "Cuatro años y medio",
    five_years: "Cinco años",
    five_years_half: "Cinco años y medio",

    add_new_event: "Agregar nuevo evento",
    add_event: "Añadir evento",
    event_name: "Nombre del evento",
    event_category: "Selecciona una categoría",
    starting_date: "Fecha de inicio",
    ending_date: "Fecha de finalización",
    month: "Mes",
    week: "Semana",
    day: "Día",
    today: "Hoy",
    new_categ_name: "Nuevo nombre de categoría",
    club_management: "Gestión del club",
    select_team: "Selecciona un equipo",
    physical_reports: "Informes físicos",
    technical_reports: "Informes técnicos",
    teams: "Equipos",
    see_profile: "Ver perfil",

    contract_details: "Detalles del contrato",
    active_contract: "Contratos activos",
    active_bonuses: "Bonificaciones activas",
    expiry_date: "Fecha de caducidad",
    monthly_wage: "Salario mensual",
    placeholder_bonuses: "No se agregaron bonificaciones",

    name: "Nombre",
    partner_name: "Nombre del socio",
    unpaid_amount: "Queda por pagar",
    paid_amount: "Monto de pago",
    last_payment: "Última Fecha de Pago",
    deadline_date: "Fecha límite",
    medical: "Médico",
    injuries: "Lesiones",

    ["Players & Staff"]: "Jugadores y Personal",
    ["Starting lineup"]: "Alineación inicial",
    ["All the players"]: "Todos los jugadores",

    your_currency: "Moneda seleccionada",
    this_will_overwrite_your_preferred_currency:
        "La acción actual cambiará la moneda seleccionada en la configuración de su cuenta. ¿Está seguro?",
    Confirm: "Confirmo",

    ["Left shoulder"]: "Hombro izquierdo",
    ["Right shoulder"]: "Hombro derecho",
    ["Left knee"]: "Rodilla izquierda",
    ["Right knee"]: "Rodilla derecha",
    ["Left thigh"]: "Muslo izquierdo",
    ["Right thigh"]: "Muslo derecho",
    ["Left shin"]: "Espinilla izquierda",
    ["Right shin"]: "Espinilla derecha",
    ["Back"]: "Atrás",
    ["Head"]: "Cabeza",
    ["Left ankle"]: "Tobillo izquierdo",
    ["Right ankle"]: "Tobillo derecho",
    ["Another zone"]: "Un'altra zona",

    ["Developer"]: "Desarrollador",
    ["Team manager"]: "Jefe de equipo",
    ["Antrenor"]: "Entrenador",
    ["Antrenor cu portarii"]: "Entrenador de porteros",
    ["Presedinte"]: "Presidente",
    ["Preparator fizic"]: "Preparador físico",
    ["Manager IT"]: "Gerente de IT",
    ["Manager Academia Poli Iasi"]: "Gerente de la Academia Poli Iasi",

    ["Goalkeeper"]: "Portero",
    ["Centre-Back"]: "Centro de la espalda",
    ["Left-Back"]: "Lateral izquierdo",
    ["Right-Back"]: "De vuelta",
    ["Defensive Midfield"]: "Mediocampo defensivo",
    ["Central Midfield"]: "Mediocentro",
    ["Right Midfield"]: "Mediocampo derecho",
    ["Left Midfield"]: "Mediocampo izquierdo",
    ["Attacking Midfield"]: "Mediocentro ofensivo",
    ["Left Winger"]: "Extremo izquierdo",
    ["Right Winger"]: "Extremo derecho",
    ["Second Striker"]: "Segundo delantero",
    ["Centre-Forward"]: "Delantero centro",
    ConnectionCannotBeRefreshed:
        "El próximo intento de actualización se permitirá de acuerdo con la próxima hora de actualización posible o el estado de la conexión es inactivo.",
    cur_: "",
    cur_Th: "T",
    cur_M: "M",
    cur_B: "B",
    cur_T: "T",

    "All documents": "Todos los documentos",
    "Players contracts": "Contratos de jugadores",
    "Sponsor contracts": "Contratos de patrocinador",

    //start
    Remove_from_my_list: "Quitar de mi lista",
    Budget: "Presupuesto",
    fast_search: "Busqueda rapida",
    total: "Total",
    auth_page_left_description: "",
    no_item: "Sin objeto.",
    Coach: "Entrenador",
    "Goalkeepers coach": "Entrenador de porteros",
    President: "Presidente",
    "Physical trainer": "Entrenador físico",
    "IT Manager": "Gerente de IT",
    "Poli Iasi Academy Manager": "Gerente de la Academia Poli Iasi",
    Bills: "Facturas",
    Various: "Diverso",
    "Training scheme": "Plan de formación",

    left_shoulder: "Hombro izquierdo",
    right_shoulder: "Hombro derecho",
    left_knee: "Rodilla izquierda",
    right_knee: "Rodilla derecha",
    left_thigh: "Muslo izquierdo",
    right_thigh: "Muslo derecho",
    left_shin: "Espinilla izquierda",
    right_shin: "Espinilla derecha",
    back: "Atrás",
    head: "Cabeza",
    left_ankle: "Tobillo izquierdo",
    right_ankle: "Tobillo derecho",

    active_teams: "Equipos activos",
    ranking_position: "Posición de pie",
    no_teams_yet: "El club no tiene equipos",
    staff_members: "Los miembros del personal",
    staff_member_name: "Nombre",
    staff_member_role: "El rol",
    no_staff_members_yet: "No se encontraron miembros del personal",
    team_coach: "Entrenador",
    "GENERAL.SOMETHING_WENT_WRONG":
        "¡Ups! Algo salió mal. Por favor, inténtelo de nuevo más tarde.",
    Error: "Error",

    Back_button: "Atrás",

    FORM_CONTROL: {
        "TRANSLATOR.SELECT": "Elige tu idioma",
        "MENU.NEW": "nuevo",
        "MENU.ACTIONS": "Comportamiento",
        "MENU.CREATE_POST": "Crear nueva publicación",
        "MENU.PAGES": "Paginas",
        "MENU.FEATURES": "Características",
        "MENU.APPS": "Aplicaciones",
        "MENU.DASHBOARD": "Dashboard",
        "AUTH.GENERAL.OR": "O",
        "AUTH.GENERAL.SUBMIT_BUTTON": "Ingresar",
        "AUTH.GENERAL.NO_ACCOUNT": "¿No tienes una cuenta?",
        "AUTH.GENERAL.SIGNUP_BUTTON": "Inscribirse",
        "AUTH.GENERAL.FORGOT_BUTTON": "Has olvidado tu contraseña",
        "AUTH.GENERAL.BACK_BUTTON": "Atrás",
        "AUTH.GENERAL.CANCEL_BUTTON": "Cancelar",
        "AUTH.GENERAL.PRIVACY": "Privacidad",
        "AUTH.GENERAL.LEGAL": "Legales",
        "AUTH.GENERAL.CONTACT": "Contacto",
        "AUTH.LOGIN.TITLE": "Inicio de sesión",
        "AUTH.LOGIN.BUTTON": "Iniciar sesión",
        "AUTH.FORGOT.TITLE": "¿Contraseña olvidada?",
        "AUTH.FORGOT.DESC":
            "Ingrese su correo electrónico para restablecer su contraseña",
        "AUTH.FORGOT.SUCCESS":
            "Su contraseña se ha restablecido correctamente.",
        "AUTH.REGISTER.TITLE": "Inscribirse",
        "AUTH.REGISTER.DESC": "Ingrese sus datos para crear su cuenta",
        "AUTH.REGISTER.SUCCESS": "Su cuenta se ha registrado correctamente.",
        "AUTH.INPUT.EMAIL": "Email",
        "AUTH.INPUT.FULLNAME": "Nombre completo",
        "AUTH.INPUT.PASSWORD": "Contraseña",
        "AUTH.INPUT.CONFIRM_PASSWORD": "Confirmar Contraseña",
        "AUTH.INPUT.USERNAME": "Nombre de usuario",
        "AUTH.VALIDATION.INVALID": "{nombre} no es válido",
        "AUTH.VALIDATION.REQUIRED": "{nombre} es requerido",
        "AUTH.VALIDATION.MIN_LENGTH":
            "La longitud mínima del correo electrónico es 3",
        "AUTH.VALIDATION.MAX_LENGTH":
            "La longitud máxima del correo electrónico es 50",
        "AUTH.VALIDATION.AGREEMENT_REQUIRED":
            "Se requiere aceptar los términos y condiciones",
        "AUTH.VALIDATION.NOT_FOUND": "No se encuentra el {nombre} solicitado",
        "AUTH.GENERAL.USER_NOT_FOUND": "Usuario no encontrado",
        "AUTH.GENERAL.USER_INACTIVE": "El usuario está inactivo",
        "AUTH.VALIDATION.INVALID_LOGIN":
            "Los datos de inicio de sesión son incorrectos",
        "AUTH.VALIDATION.INVALID_EMAIL": "Email inválido",
        "AUTH.GENERAL.SOMETHING_WENT_WRONG":
            "Ups! Algo salió mal. Por favor, inténtelo de nuevo más tarde.",
        "AUTH.VALIDATION.NO_USER_WITH_EMAIL":
            "Ningún usuario activo utiliza el email.",
        "AUTH.VALIDATION.REQUIRED_FIELD": "Campo requerido",
        "AUTH.VALIDATION.MIN_LENGTH_FIELD": "Longitud mínima del campo:",
        "AUTH.VALIDATION.MAX_LENGTH_FIELD": "Longitud máxima del campo:",
        "AUTH.VALIDATION.INVALID_FIELD": "El campo no es válido",
        "ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT":
            "Recuento de registros seleccionados: ",
        "ECOMMERCE.COMMON.ALL": "Todos",
        "AUTH.RESET_PASSWORD.TITLE": "Restablecer la contraseña",
        "AUTH.RESET_PASSWORD.TOKEN_EXPIRED": "Token caducado",
        "AUTH.GENERAL.EMPTY_TOKEN": "El token está vacío",
        "ECOMMERCE.COMMON.SUSPENDED": "Suspendido",
        "ECOMMERCE.COMMON.ACTIVE": "Activo",
        "ECOMMERCE.COMMON.FILTER": "Filtrar",
        "ECOMMERCE.COMMON.BY_STATUS": "por estado",
        "ECOMMERCE.COMMON.BY_TYPE": "por tipo",
        "ECOMMERCE.COMMON.BUSINESS": "Negocio",
        "ECOMMERCE.COMMON.INDIVIDUAL": "Individual",
        "ECOMMERCE.COMMON.SEARCH": "Buscar",
        "ECOMMERCE.COMMON.IN_ALL_FIELDS": "en todos los campos",
        "ECOMMERCE.ECOMMERCE": "eCommerce",
        "ECOMMERCE.CUSTOMERS.CUSTOMERS": "Clientes",
        "ECOMMERCE.CUSTOMERS.CUSTOMERS_LIST": "Lista de clientes",
        "ECOMMERCE.CUSTOMERS.NEW_CUSTOMER": "Nuevo cliente",
        "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.TITLE": "Eliminar cliente",
        "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.DESCRIPTION":
            "¿Está seguro de eliminar permanentemente a este cliente?",
        "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.WAIT_DESCRIPTION":
            "El cliente se está eliminando ...",
        "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.MESSAGE":
            "El cliente ha sido eliminado",
        "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.TITLE": "Eliminar clientes",
        "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.DESCRIPTION":
            "¿Está seguro de eliminar de forma permanente los clientes seleccionados?",
        "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.WAIT_DESCRIPTION":
            "Los clientes se están eliminando ...",
        "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.MESSAGE":
            "Se han eliminado los clientes seleccionados",
        "ECOMMERCE.CUSTOMERS.UPDATE_STATUS.TITLE":
            "El estado se ha actualizado para los clientes seleccionados",
        "ECOMMERCE.CUSTOMERS.UPDATE_STATUS.MESSAGE":
            "El estado de los clientes seleccionados se han actualizado con éxito",
        "ECOMMERCE.CUSTOMERS.EDIT.UPDATE_MESSAGE":
            "El cliente ha sido actualizado",
        "ECOMMERCE.CUSTOMERS.EDIT.ADD_MESSAGE": "El cliente ha sido creado",
    },

    verification_code: "Código de verificación",
    insert_verification_code:
        "Inserte el código de 6 dígitos enviado al número de teléfono asociado con esta cuenta",
    verification_code_no_receive_message: "No recibiste mensaje?",
    verification_code_send_again: "Enviar de nuevo",

    auth_code_expired: "El código de autenticación venció",
    auth_code_incorrect: "Código de autenticación incorrecto",
    auth_code_try_again: "Error de autenticación. Inténtalo de nuevo.",

    team_types_list: "Tipos de equipo",
    team_type_add: "Agregar tipo de equipo",
    team_type_add_short: "Agregar tipo",

    team_type_notification_title: "Tipos de equipo de club",
    team_type_notification_succes:
        "¡El tipo de equipo se agregó correctamente!",
    team_type_notification_fail:
        "¡El tipo seleccionado se ha eliminado correctamente!",

    tm_link_team: "Enlace de Transfermarkt (opcional)",
    minutes_played_uppercase: "Minutos jugados",
    all_fields_mandatory_error: "Todos los campos son obligatorios",
    no_sections_added_error:
        "Debes agregar al menos una sección de entrenamiento",
    rec_date_not_before_inj_date:
        "La fecha de recuperación no puede ser anterior a la fecha de la lesión",
    documents_upload_success: "Ha subido un nuevo documento!",
    video_upload_success: "You have added a new video!",
    video_upload_failed: "Your video must be up to 125 MB in size to upload!",
    video_delete_success: "Your video was successfully deleted!",
    video_operation: "Video operation",
    ACCESS_FORBIDDEN_ERROR: "Acceso Prohibido",
    new_category_empty_error:
        "El nombre de la nueva categoría no puede estar vacío",
    delete_team: "Eliminar equipo",
    season_date_start_add_team: "Fecha de inicio de la temporada actual",
    season_date_end_add_team: "Fecha de finalización de la temporada actual",
    deleteTeamMessage: "¿Estás seguro de que deseas eliminar este equipo?",
    deleteTeamMessage2:
        "Se perderán todos los jugadores y los datos relacionados.",
    add_optimal_weight: "Modificar el peso óptimo",

    auth_error_title: "Error de autenticación",
    auth_error_message: "Verifique los datos ingresados e intente nuevamente",
    reset_error_title: "Error de restablecimiento de contraseña",
    hello: "Hola",
    password_reset_email: "Tu nueva contraseña es",

    import_fans_title: "Importar datos sobre fans",
    select_fields_to_save: "Seleccione los campos a guardar",
    enter_source_name: "Ingrese el nombre de la fuente",
    enter_delimiter: "Ingrese el delimitador",
    sample_name_fans_file: "ej: Suscriptores del boletín",
    associated_with: "Asociado con",
    activity_date: "Fecha de la actividad",
    column: "Columna",
    social_media_activity: "Actividad de las redes sociales",

    // ----------------------------------Payments

    payments_title: "Pagos",
    table_name: "Nombre",
    table_months: {
        jan: "Enero",
        feb: "Febrero",
        mar: "Marzo",
        apr: "Abril",
        may: "Mayo",
        jun: "Junio",
        jul: "Junio",
        aug: "Agosto",
        sep: "Septiembre",
        oct: "Octubre",
        nov: "Noviembre",
        dec: "Diciembre"
    },
    add_payment_title: "Agregar pago para el jugador ",
    add_payment_select_month: "Seleccionar mes",
    add_payment_amount: "Suma",
    add_payment_type: {
        cash: "Efectivo",
        bank_transfer: "Transferencia bancaria"
    },
    add_payment_date: "Fecha de pago",
    add_payment_description: "Descripción (opcional)",
    add_payment_button_add: "Agregar pago",
    add_payment_button_cancel: "Cancelar",
    edit_payments_title: "Editar pagos para el jugador ",
    edit_payments_empty: "No hay pagos.",
    add_payment_overwrite_first: "¿Está seguro de que desea sobrescribir los datos sobre el pago en ",
    add_payment_overwrite_second: " para el jugador ",
    add_payment_button_overwrite: "Sí, sobrescribir pago",
    delete_payment_question: "¿Está seguro de que desea eliminar el pago?",
    delete_payment_button_delete: "Sí, eliminar",
    payment_successfully_added: "¡Pago agregado con éxito!",
    payment_successfully_deleted: "¡El pago seleccionado ha sido eliminado con éxito!"
};
