import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import "./MatchDetails.scss";
import goal_icon from "../../../../assets/icons/goal.png";
import substitute_in_icon from "../../../../assets/icons/match-events-types/substitute_in_arrow.png";
import substitute_out_icon from "../../../../assets/icons/match-events-types/substitute_out_arrow.png";
import { connect } from "react-redux";
import AddMatchEvent from "./AddMatchEvent";
import { Button, Icon } from "semantic-ui-react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "react-notifications-component";
import TFBCard from "../../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBPlaceholder from "../../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";

const generalEvaluationOptions = [
  {
    value: "1",
    label: "Foarte bine",
  },
  {
    value: "2",
    label: "Bine",
  },
  {
    value: "3",
    label: "Mediu",
  },
  {
    value: "4",
    label: "Slab",
  },
  {
    value: "5",
    label: "Foarte slab",
  },
];

function MatchEvents(props) {
  const {
    languagePack,
    token,
    matchDetails,
    matchTeams,
    teamsPlayers,
    hasAccessToDeleteHomeEvents,
    hasAccessToDeleteAwayEvents,
    updatePlayersRating,
  } = props;

  const [isLoad, setIsLoad] = useState(false);
  const [events, setEvents] = useState([]);
  const [addEventPopup, setAddEventPopup] = useState(false);
  const [deleteEventPopup, setDeleteEventPopup] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");

  const [weatherCondition, setWeatherCondition] = useState(
    matchDetails.weather_condition
  );
  const [pitchCondition, setPitchCondition] = useState(
    matchDetails.pitch_condition
  );
  const [textEditor, setTextEditor] = useState(matchDetails.match_description);
  const [generalEvaluation, setGeneralEvaluation] = useState(
    generalEvaluationOptions.find(
      (option) => option.value == matchDetails.match_rating
    )
  );

  const history = useHistory();
  const idParam = useParams();

  useEffect(() => {
    getManualMatchEvents();
  }, []);

  const getManualMatchEvents = () => {
    axios
      .get(`match/get_manual_match_events?id_match=${matchDetails.id_match}`)
      .then((res) => {
        setEvents(res.data.events);
        setIsLoad(true);
      })
      .catch((err) => console.log(err));
  };

  const deleteEvent = () => {
    axios
      .post(`match/delete_event`, { id_event: idToDelete })
      .then((res) => {
        store.addNotification({
          title: languagePack["fixtures_schedule"],
          message: "Evenimentul din meci a fost sters cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        getManualMatchEvents();
        closeDeletePopup();
        updatePlayersRating();
      })
      .catch((err) => console.log(err));
  };

  const saveGeneratorDetails = () => {
    let payload = {
      general_match_evaluation: generalEvaluation.value ?? null,
      weather_condition: weatherCondition,
      pitch_condition: pitchCondition,
      match_description: textEditor,
      id_match: idParam.id,
    };

    axios
      .post(`match/save_additional_details`, payload)
      .then((res) => {
        if (res.data == 1) {
          store.addNotification({
            title: languagePack.match_sg,
            message: languagePack.manage_competitions_success_changes,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: languagePack.match_sg,
            message: languagePack.manage_competitions_failed_changes,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const openDeletePopup = (id) => {
    setDeleteEventPopup(true);
    setIdToDelete(id);
  };

  const closeDeletePopup = () => {
    setDeleteEventPopup(false);
    setIdToDelete("");
  };

  return (
    isLoad && (
      <TFBCard>
        <TFBCardHeader title={languagePack["match_events"]}>
          <TFBButton
            color="green"
            onClick={() => setAddEventPopup(true)}
            renderIcon={() => <TFBIcon name="add" />}
          >
            {languagePack["add_event"]}
          </TFBButton>
        </TFBCardHeader>
        <TFBCardBody>
          <div className="match-events-container">
            {events.length > 0 && (
              <div className="events-list">
                {events.map((item, index) =>
                  matchDetails.id_team_home ==
                  item.id_manual_competition_team ? (
                    <div
                      className="event-row manual-match"
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                      key={"home-team-event-" + index}
                    >
                      <div className="event-info">
                        <div
                          className="minute"
                          style={{ borderRight: "solid 1px #e6e6e6" }}
                        >
                          {item.event_minute}'
                        </div>
                        <div
                          className="event-image"
                          style={{ marginLeft: 20, marginRight: 10 }}
                        >
                          {item.event_type === "Goal" && (
                            <div
                              className="goal-icon-container"
                              style={
                                item.autogoal == 1
                                  ? { backgroundColor: "#d91200" }
                                  : {}
                              }
                            >
                              <img src={goal_icon} alt="goal" />
                            </div>
                          )}
                          {item.event_type_detail === "Yellow card" &&
                            item.second_yellow == 0 && (
                              <div className="yellow-card-icon" />
                            )}
                          {item.event_type_detail === "Yellow card" &&
                            item.second_yellow == 1 && (
                              <div className="second-yellow-card-icon" />
                            )}
                          {item.event_type_detail === "Red card" && (
                            <div className="red-card-icon" />
                          )}
                          {item.event_type === "Substitution" && (
                            <img src={substitute_out_icon} alt="sub_out" />
                          )}
                        </div>
                        <div
                          className="player-name"
                          onClick={() =>
                            history.push("/club/player/" + item.id_player)
                          }
                        >
                          {item.player_name}
                        </div>
                        {item.event_type === "Substitution" && (
                          <>
                            <div
                              className="event-image"
                              style={{ marginLeft: 20, marginRight: 10 }}
                            >
                              <img src={substitute_in_icon} alt="sub_in" />
                            </div>
                            <div
                              className="player-assist"
                              onClick={() =>
                                history.push("/club/player/" + item.id_opponent)
                              }
                            >
                              {item.player_assist_name}
                            </div>
                          </>
                        )}
                        {item.player_assist_name &&
                          item.event_type !== "Substitution" && (
                            <div
                              className="player-assist"
                              onClick={() =>
                                history.push("/club/player/" + item.id_opponent)
                              }
                              style={{ marginLeft: "5px" }}
                            >
                              {"(" + item.player_assist_name + ")"}
                            </div>
                          )}
                      </div>
                      {hasAccessToDeleteHomeEvents && (
                        <div
                          className="delete-match-event"
                          onClick={() => openDeletePopup(item.id_event)}
                        >
                          <TFBIcon name="delete" color="gray" />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      className="event-row manual-match away-team"
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                      key={"away-team-event-" + index}
                    >
                      {hasAccessToDeleteAwayEvents && (
                        <div
                          className="delete-match-event"
                          onClick={() => openDeletePopup(item.id_event)}
                        >
                          <TFBIcon name="delete" color="gray" />
                        </div>
                      )}
                      <div className="event-info">
                        {item.player_assist_name &&
                          item.event_type !== "Substitution" && (
                            <div
                              className="player-assist"
                              style={{ marginRight: "5px" }}
                            >
                              {"(" + item.player_assist_name + ")"}
                            </div>
                          )}
                        {item.event_type === "Substitution" && (
                          <>
                            <div className="player-assist">
                              {item.player_assist_name}
                            </div>
                            <div
                              className="event-image"
                              style={{ marginLeft: 10, marginRight: 20 }}
                            >
                              <img src={substitute_in_icon} alt="sub_in" />
                            </div>
                          </>
                        )}
                        <div
                          className="player-name"
                          onClick={() =>
                            history.push("/club/player/" + item.id_player)
                          }
                        >
                          {item.player_name}
                        </div>
                        <div
                          className="event-image"
                          style={{ marginLeft: 10, marginRight: 20 }}
                        >
                          {item.event_type === "Goal" && (
                            <div
                              className="goal-icon-container"
                              style={
                                item.autogoal == 1
                                  ? { backgroundColor: "#d91200" }
                                  : {}
                              }
                            >
                              <img src={goal_icon} alt="goal" />
                            </div>
                          )}
                          {item.event_type_detail === "Yellow card" &&
                            item.second_yellow == 0 && (
                              <div className="yellow-card-icon" />
                            )}
                          {item.event_type_detail === "Yellow card" &&
                            item.second_yellow == 1 && (
                              <div className="second-yellow-card-icon" />
                            )}
                          {item.event_type_detail === "Red card" && (
                            <div className="red-card-icon" />
                          )}
                          {item.event_type === "Substitution" && (
                            <img src={substitute_out_icon} alt="sub_out" />
                          )}
                        </div>
                        <div
                          className="minute"
                          style={{
                            borderLeft: "solid 1px #e6e6e6",
                            textAlign: "end",
                          }}
                        >
                          {item.event_minute}'
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
          {events.length == 0 && (
            <TFBPlaceholder text={languagePack.no_data_events} />
          )}

          {/* <div className="aditional-inputs-report-generator">
            <div className="inputs-row">
              <TFBInput
                placeholder={languagePack.weather_condition}
                label={languagePack.weather_condition}
                fullWidth
                value={weatherCondition}
                onChange={(e) => setWeatherCondition(e.target.value)}
              />
              <TFBInput
                placeholder={languagePack.pitch_condition}
                label={languagePack.pitch_condition}
                fullWidth
                value={pitchCondition}
                onChange={(e) => setPitchCondition(e.target.value)}
              />
            </div>

            <div className="select-general-match-evaluation">
              <TFBSelect
                placeholder={languagePack.select_match_evaluation}
                label={languagePack.select_match_evaluation}
                options={generalEvaluationOptions}
                value={generalEvaluation}
                onChange={(selected) => setGeneralEvaluation(selected)}
              />
            </div>

            <div className="text-editor">
              <label className="ds-main-text-semi-bold ">
                {languagePack.game_play}
              </label>

              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "undo",
                    "redo",
                  ],
                }}
                data={textEditor}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  // console.log(data);
                  setTextEditor(data);
                }}
              />
            </div>

            <TFBButton
              onClick={saveGeneratorDetails}
              endIcon="true"
              color={"green"}
              className="save-generator-details"
              renderIcon={() => <TFBIcon name={"add"} />}
            >
              {languagePack.save_details}
            </TFBButton>
          </div> */}
        </TFBCardBody>

        <Dialog open={addEventPopup}>
          <DialogContent className="add-team-wrapper">
            <AddMatchEvent
              closePopup={() => setAddEventPopup(false)}
              teams={matchTeams}
              players={teamsPlayers}
              refreshEvents={() => getManualMatchEvents()}
              idMatch={matchDetails.id_match}
              token={token}
              matchDetails={matchDetails}
              updatePlayersRating={updatePlayersRating}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={deleteEventPopup} onClose={closeDeletePopup}>
          <DialogContent className="add-team-wrapper">
            <div>{languagePack.sure_delete_event}</div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeDeletePopup}
              style={{ backgroundColor: "#00d948", color: "white" }}
            >
              {languagePack.no}
            </Button>
            <Button onClick={deleteEvent} color="red">
              {languagePack.yes}
            </Button>
          </DialogActions>
        </Dialog>
      </TFBCard>
    )
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(MatchEvents);
