import React from "react";
import axios from "axios";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { Icon, Button, TextArea } from "semantic-ui-react";
import "../Club.styles.scss";
import "./PlayerProfile.scss";
import { connect } from "react-redux";
import moment from "moment";
import { ReactComponent as XlsLogo } from "./../../../assets/SVG/xls.svg";
import { ReactComponent as DocLogo } from "./../../../assets/SVG/doc.svg";
import { ReactComponent as PdfLogo } from "./../../../assets/SVG/pdf.svg";
import { ReactComponent as JpgLogo } from "./../../../assets/SVG/jpg.svg";
import { ReactComponent as PngLogo } from "./../../../assets/SVG/png.svg";
import { ReactComponent as TxtLogo } from "./../../../assets/SVG/txt.svg";
import { ReactComponent as CsvLogo } from "./../../../assets/SVG/csv.svg";
import { ReactComponent as ZipLogo } from "./../../../assets/SVG/zip.svg";
import { ReactComponent as VideoLogo } from "./../../../assets/SVG/video.svg";
import { ReactComponent as SearchLogo } from "./../../../assets/SVG/search.svg";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "react-notifications-component";

class DocumentsWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addDocOpenDialog: false,
      addedFile: "",
      doc_extra_notes: "",
      error: "",
      fileUploading: false,

      docs: [],

      open_delete_dialog: false,
      deleting_doc: "",
    };
  }

  componentDidMount = () => {
    this.getDocs();
  };

  getDocs = () => {
    axios
      .get(
        `upload/get_documents_for_entity_profile?type_entity=staff_member&id_entity=${this.props.id_staff_member}&id_club=${this.props.currentUser.id_club}`
      )
      .then((res) =>
        this.setState({
          docs: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  getFileTypeIcon = (value) => {
    if (value === "xlsx" || value === "xls")
      return <XlsLogo className="type-logo" />;
    if (value === "doc" || value === "docx")
      return <DocLogo className="type-logo" />;
    if (value === "pdf") return <PdfLogo className="type-logo" />;
    if (value === "jpg") return <JpgLogo className="type-logo" />;
    if (value === "png") return <PngLogo className="type-logo" />;
    if (value === "txt") return <TxtLogo className="type-logo" />;
    if (value === "csv") return <CsvLogo className="type-logo" />;
    if (
      value === "m4v" ||
      value === "avi" ||
      value === "mpg" ||
      value === "mp4"
    )
      return <VideoLogo className="type-logo" />;
    else return <SearchLogo className="type-logo" />;
  };

  closePopup = () => {
    this.setState({
      addDocOpenDialog: false,
      addedFile: "",
      doc_extra_notes: "",
      error: "",
    });
  };

  onFileChange = (e) => {
    this.setState({
      addedFile: e.target.files[0],
    });
  };

  addFile = () => {
    const file = this.state.addedFile;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("id_club", this.props.currentUser.id_club);
    formData.append("uploaded_by", this.props.currentUser.nickname);
    formData.append("extra_notes", this.state.doc_extra_notes);
    formData.append("entity", "staff_member");
    formData.append("id_entity", this.props.id_staff_member);
    formData.append("id_user", this.props.currentUser.id_user);

    axios
      .post("upload/upload_file_from_entity_profile", formData, {
        params: {
          token: this.props.token,
        },
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data == 1) {
          this.getDocs();
          this.closePopup();
          store.addNotification({
            title: "Document",
            message: this.props.languagePack["documents_upload_success"],
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          this.closePopup();
          store.addNotification({
            title: "Document",
            message: "Documentul nu a fost incarcat cu succes!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteDoc = () => {
    axios
      .post("upload/delete_document_from_entity_profile", {
        id_document: this.state.deleting_doc.id_document,
        token: this.props.token,
      })
      .then((res) => {
        this.setState({
          deleting_doc: "",
          open_delete_dialog: false,
        });
        if (res.data == 1) {
          this.getDocs();
          store.addNotification({
            title: "Document",
            message: "Documentul a fost sters cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: "Document",
            message: "Documentul nu a fost sters cu succes!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="card card-custom documents-widget-container">
        <div className="documents-header">
          <div className="documents-title">{languagePack.documents}</div>
          <div className="documents-add-doc">
            <Button
              className="documents-add-doc-btn green-background"
              onClick={() => this.setState({ addDocOpenDialog: true })}
            >
              <div className="documents-add-doc-btn-text">
                {languagePack.add_document}
              </div>
              <Icon name="add" className="add-section-button-icon" />
            </Button>
          </div>
        </div>
        {this.state.docs.length > 0 ? (
          <div className="documents-table">
            <div className="documents-table-header-row">
              <div className="doc-icon"></div>
              <div className="doc-tittle">{languagePack.designation}</div>
              <div className="doc-type">{languagePack.type}</div>
              <div className="doc-size">{languagePack.dimension}</div>
              <div className="doc-date-add">{languagePack.added_at}</div>
              <div className="doc-add-by">{languagePack.added_by}</div>
              <div className="doc-btns"></div>
            </div>
            <div className="documents-table-content">
              {this.state.docs.map((doc, index) => {
                return (
                  <div
                    className="documents-table-row"
                    style={{
                      backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                    }}
                    key={"player-doc-" + index}
                  >
                    <div className="doc-icon row1">
                      {this.getFileTypeIcon(
                        doc["document_name"].split(".").pop()
                      )}
                    </div>
                    <div className="doc-tittle row1">
                      {doc["document_name"]}
                    </div>
                    <div className="doc-type row1">{doc["type"]}</div>
                    <div className="doc-size row1">{doc["size"]}</div>
                    <div className="doc-date-add row1">
                      {moment(doc["date_add"]).format("DD.MM.YYYY")}
                    </div>
                    <div className="doc-add-by row1">{doc["uploaded_by"]}</div>
                    <div className="doc-btns row1">
                      <a
                        href={`https://api.thefootballbrain.app/upload/download_document_from_entity_profile?id_document=${doc.id_document}&token=${this.props.token}`}
                      >
                        <Icon name="download" className="edit-hover" />
                      </a>
                      <Icon
                        name="trash"
                        className="trash-hover"
                        onClick={() =>
                          this.setState({
                            deleting_doc: doc,
                            open_delete_dialog: true,
                          })
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="no-national-team-call-up-message">
            <Icon name="search minus" color="black" size="big" />
            <div className="no-national-team-call-up-message-text">
              {"Nu are documente"}
            </div>
          </div>
        )}

        {/* ADAUGARE FISIER */}
        <Dialog open={this.state.addDocOpenDialog}>
          <div className="bonus-options-list-popup-header">
            <div className="bonus-options-list-title">
              {languagePack["add_document"]}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={this.closePopup}
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>

          <DialogContent className="add-file-container-all">
            <div className="add-file-container">
              {/* <form onSubmit={this.onSubmit}> */}
              <div
                className="add-file-form-row"
                style={{
                  justifyContent: "space-between",
                  paddingRight: 20,
                  backgroundColor: "#fafafa",
                }}
              >
                <input
                  type="file"
                  className="add-file-form-file-input"
                  id="customFile"
                  onChange={this.onFileChange}
                />
                <label
                  className="add-file-form-file-label"
                  htmlFor="customFile"
                >
                  {this.state.addedFile
                    ? this.state.addedFile.name
                    : languagePack["upload_file"]}
                </label>
                <Icon
                  name="arrow up"
                  className="cusor-pointer grey-button-icon"
                  size="small"
                />
              </div>

              <TextArea
                placeholder={languagePack["file_details"]}
                className="text-area-file-form"
                value={this.state.doc_extra_notes}
                onChange={(e) =>
                  this.setState({ doc_extra_notes: e.target.value })
                }
                name="doc_extra_notes"
              />
              {this.state.error != "" && (
                <div className="end-injury-error-row">{this.state.error}</div>
              )}

              <div className="add-file-buttons-container">
                <Button
                  className="add-file-button cancel-button"
                  onClick={() => this.closePopup()}
                >
                  {languagePack["admin_cancel"]}
                  <Icon
                    name="ban"
                    size="small"
                    className="add-file-button-icon"
                  />
                </Button>
                <Button
                  className="add-file-button"
                  disabled={this.state.fileUploading || !this.state.addedFile}
                  onClick={this.addFile}
                >
                  {languagePack["admin_add"]}
                  <Icon
                    name="add"
                    size="small"
                    className="add-file-button-icon"
                  />
                </Button>
              </div>
              {/* </form> */}
            </div>
          </DialogContent>
        </Dialog>

        {/* STERGERE FISIER*/}
        <Dialog open={this.state.open_delete_dialog}>
          <div className="bonus-options-list-popup-header">
            <div className="bonus-options-list-title">
              {languagePack.delete_document}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={() =>
                  this.setState({ open_delete_dialog: false, deleting_doc: "" })
                }
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <DialogContent>
            <div className="delete-bonus-query">
              {/* {`Actiunea este ireversibila. Esti sigur/sigura ca vrei sa stergi documentul `}
              <span style={{ color: "var(--primaryColor)" }}>
                {this.state.deleting_doc.document_name}
              </span>
              {"?"} */}
              {languagePack.delete_document_message}
            </div>
            <div className="delete-bonus-buttons">
              <Button
                className="delete-bonus-button grey-btn"
                onClick={() =>
                  this.setState({ open_delete_dialog: false, deleting_doc: "" })
                }
              >
                <div className="button-content">
                  {languagePack["admin_cancel"]}
                </div>
                <Icon name="ban" className="white-icon" />
              </Button>
              <Button
                className="delete-bonus-button"
                color="red"
                onClick={this.deleteDoc}
              >
                <div className="button-content">{languagePack.yes_delete}</div>
                <Icon name="trash" className="white-icon" />
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(DocumentsWidget);
