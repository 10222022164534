import React from "react";
import axios from "axios";
import { logout } from "./../../../utils/api";
import "./OnlinePayments.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import Avatar from "@material-ui/core/Avatar";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import { TFBTableCell, sxStickyColumn } from "../../../components/reusable/useStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

class OnlinePayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
    };
  }

  componentDidMount = () => {
    this.getPayments();
  };

  getPayments = () => {
    axios
      .get(
        `finances/get_online_payments_list_2?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => this.setState({ payments: res.data.list }))
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;

    return (
      <div className="receipt-container">
        <TFBCard>
          <TFBCardHeader title={languagePack.online_payment_list} />
          <TFBCardBody>
            {this.state.payments.length > 0 ?
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TFBTableCell sx={sxStickyColumn}>
                        {languagePack.player_name}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.team}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.payment_method}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.payer}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.add_payment_amount}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.payment_month_description}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.date_hour_of_payment}
                      </TFBTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.payments.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                      >
                        <TFBTableCell
                          align="center"
                          sx={sxStickyColumn}
                          style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                        >
                          <div className="receipt-avatar-player-name">
                            <Avatar
                              className="player-avatar"
                              style={{ cursor: "pointer" }}
                              src={row.img_url}
                              onClick={() =>
                                this.props.history.push("/club/player/" + row.id_player)
                              }
                            />
                            <span
                              className="cursor-pointer"
                              onClick={() =>
                                this.props.history.push("/club/player/" + row.id_player)
                              }
                            >
                              {row.player_name}
                            </span>
                          </div>
                        </TFBTableCell>
                        <TFBTableCell align="center">{row.team_name}</TFBTableCell>
                        <TFBTableCell align="center">{payment_types[row["payment_type_label"]]}</TFBTableCell>
                        <TFBTableCell align="center">{row["customer_name"]}</TFBTableCell>
                        <TFBTableCell align="center">{row["payment_amount"] + " RON"}</TFBTableCell>
                        <TFBTableCell align="center">{row["about_payment"]}</TFBTableCell>
                        <TFBTableCell align="center">{moment(row["date_add"]).format("DD.MM.yyyy, HH:mm:ss")}</TFBTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> :
              <TFBPlaceholder text={languagePack.no_online_payments} />
            }
          </TFBCardBody>
        </TFBCard>
      </div>
    );
  }
}

const payment_types = {
  parents_app: "Aplicație parinți",
  bank_transfer: "Transfer bancar",
  cash: "Numerar",
  online_website: "Online - website",
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(OnlinePayments);
