import React from "react";
import "./MatchCostsEdit.scss";
import axios from "axios";
import { Button, Icon } from "semantic-ui-react";
import "./MatchCostsEdit.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import * as myConstants from "./../../../../app/utils/constants";
import { Redirect } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Loader from "react-loader-spinner";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import AddDepartment from "../Costs/AddDepartment";
import DepartmentsList from "../Costs/DepartmentsList";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (
  source,
  destination,
  droppableSource,
  droppableDestination,
  currency
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  let newItem = removed;
  if (!newItem?.currency) {
    newItem.currency = currency;
  }
  destClone.splice(droppableDestination.index, 0, newItem);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

class MatchCostsEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addSection: false,
      items: [],
      selected: [],
      added_section_name: "",
      isWritingInput: "",
      currentTotalTime: 0,
      error: "",
      rates: [],
      isLoaded: false,
      match_costs_data_is_loaded: false,
      id_team: "",
      hasAccessToMatchCosts: false,
      redirectToMatchesCosts: false,
      deleteCostCategory: false,
      deleteCostCategoryId: "",
      typesDialogOpen: false,
      dataFetched: false,
      match_name: "",
      selected_currency_total: props.currencyItemSelected.uniqueSlug,

      open_add_department: false,
      open_edit_departments: false,
      editing_departments: {},

      departments: [],
      costs_to_delete: [],
    };
  }

  id2List = {
    droppable: "items",
    droppable2: "selected",
  };

  componentDidMount = () => {
    this.hasAccessToMatch();
  };

  hasAccessToMatch = () => {
    axios
      .post(`finances/has_access_to_match_costs`, {
        id_club: this.props.currentUser.id_club,
        id_match: this.props.match.params.id,
      })
      .then((res) => {
        if (res.data.has_access == 1) {
          this.setState(
            {
              id_team: res.data.id_team,
              hasAccessToMatchCosts: true,
            },
            () => {
              this.getMatchName();
              this.getCurrency();
            }
          );
        } else {
          this.setState({
            redirectToMatchesCosts: true,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  setMatchesCostsData = () => {
    this.getDepartments();
    this.getExistingCostsCategories();
    this.getExistingCostsItems();
  };

  getDepartments = () => {
    axios
      .get(`finances/get_departments?id_club=${this.props.currentUser.id_club}`)
      .then((res) =>
        this.setState({
          departments: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  getMatchName = () => {
    axios
      .get(`finances/get_match_name?id_match=${this.props.match.params.id}`)
      .then((res) => this.setState({ match_name: res.data }))
      .catch((err) => console.log(err));
  };

  getExistingCostsCategories = () => {
    axios
      .get(
        `finances/get_expense_categories_2?id_match=${this.props.match.params.id}&id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => {
        let categories = Array(...res.data.list);
        let usedCategories = this.state.items.map(
          (elem) => elem.id
        );
        categories = categories.filter(
          (elem) => !usedCategories.includes(elem.id)
        );
        this.setState({ selected: categories });
      })
      .catch((err) => console.log(err));
  };

  getExistingCostsItems = () => {
    axios
      .get(
        `finances/get_expense_items_2?id_match=${this.props.match.params.id}&id_club=${this.props.currentUser.id_club}&id_team=${this.state.id_team}`
      )
      .then((res) => {
        let categories = this.state.selected;
        let usedCategories = [...res.data.list].map(
          (elem) => elem.id
        );
        categories = categories.filter(
          (elem) => !usedCategories.includes(elem.id)
        );
        this.setState({ items: res.data.list, selected: categories }, () => {
          this.timeTotal();
        })
      })
      .catch((err) => console.log(err));
  };

  timeTotal = () => {
    const this2 = this;
    let sum = this.state.items.reduce(function (prev, current) {
      const currency_rates = {
        EUR_RON: current.amount * this2.state.rates["RON"],
        RON_EUR: current.amount / this2.state.rates["RON"],
      };

      const current_value =
        current.amount != "" && current.amount != null
          ? current.currency == this2.state.selected_currency_total
            ? current.amount
            : currency_rates[
            current.currency + "_" + this2.state.selected_currency_total
            ]
          : 0;

      return Number(prev) + +Number(current_value);
    }, 0);
    this.setState({ currentTotalTime: sum.toFixed(2) });
  };

  getList = (id) => this.state[this.id2List[id]];

  onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === "droppable2") {
        state = { selected: items };
      }

      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination,
        this.props.currencyItemSelected.uniqueSlug
      );

      this.setState(
        {
          items: result.droppable,
          selected: result.droppable2,
        },
        this.timeTotal
      );
    }
  };

  handleChangeInput = (e) => {
    let elementsIndex = this.state.items.findIndex(
      (el) => el.id === this.state.isWritingInput
    );
    let newArray = [...this.state.items];

    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      amount: e.target.value,
    };

    this.setState({ items: newArray }, this.timeTotal);
  };

  updateCostItemCurrency = (currency, index) => {
    let newArray = [...this.state.items];

    newArray[index] = {
      ...newArray[index],
      currency: currency,
    };

    this.setState({ items: newArray }, this.timeTotal);
  };

  removeElementFromList = (val) => {
    var array = [...this.state.items]; // make a separate copy of the array
    var index = array.findIndex(
      (item) => item.id === val
    );
    if (index !== -1) {
      let copy = array[index];
      array.splice(index, 1);
      let costs_to_delete = [...this.state.costs_to_delete];
      if (copy?.id_cost_item) {
        costs_to_delete.push(copy);
      }
      this.setState(
        {
          costs_to_delete: costs_to_delete,
          items: array,
          selected: this.state.selected.concat(copy),
        },
        this.timeTotal
      );
    }
  };

  submitTraining = () => {
    const payload = {
      id_club: this.props.currentUser.id_club,
      id_match: this.props.match.params.id,
      match_name: this.state.match_name,
      sections: this.state.items,
      sections_to_delete: this.state.costs_to_delete.filter(item => !this.state.items.map(elem => elem.id).includes(item.id)),
    };

    axios
      .post("finances/save_match_cost", payload)
      .then((res) => {
        this.setMatchesCostsData();
        store.addNotification({
          title: this.props.languagePack["save_edit_match_costs"],
          message: "Cheltuielile de meci au fost salvate cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        this.setState({ error: "", costs_to_delete: [] });
      })
      .catch((err) => console.log(err));
  };

  getCurrency = () => {
    fetch("https://api.thefootballbrain.app/club/get_currency_rates")
      .then((res) => res.json())
      .then((data) =>
        this.setState(
          {
            rates: data,
            isLoaded: true,
          },
          this.setMatchesCostsData
        )
      );
  };

  openAddDepartment = () => {
    this.setState({ open_add_department: true })
  }

  closeAddDepartment = () => {
    this.setState({ open_add_department: false })
  }

  openEditDepartments = (departments) => {
    this.setState({ open_edit_departments: true, editing_departments: departments })
  }

  closeEditDepartments = () => {
    this.setState({ open_edit_departments: false, editing_departments: {} })
  }

  render() {
    const { languagePack } = this.props;

    if (this.state.redirectToMatchesCosts) {
      return <Redirect to="/club/match-costs" />;
    }

    return (
      <>
        {this.state.isLoaded && this.state.hasAccessToMatchCosts && (
          <>
            <div className="match-costs-edit-header">
              <div
                onClick={() => {
                  window.history.back();
                }}
                style={{ cursor: "pointer" }}
              >
                <TFBIcon
                  name="arrow-left"
                  color="black"
                  style={{ width: 30 }}
                />
              </div>
              <div className="add-training-page-title">
                {this.state.match_name}
              </div>
            </div>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <div className="row">
                <div className="col-lg-6 col-lg-padding">
                  <TFBCard className="edit-match-costs-container">
                    <TFBCardHeader title={languagePack["edit_match_costs"]} />
                    <TFBCardBody>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            className="edit-match-costs-final-drop-area"
                          >
                            <div className="sections-list">
                              {this.state.items.map((item, index) => (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="edit-match-costs-final-drop-row"
                                    >
                                      <div className="final-drop-row-name">
                                        {item.category_name}
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            onClick={() =>
                                              this.removeElementFromList(
                                                item.id
                                              )
                                            }
                                          >
                                            <TFBIcon
                                              name="x-small"
                                              color="black"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <input
                                        className="final-drop-row-input"
                                        type="number"
                                        placeholder={`${languagePack["category_cost_sum"]} (0.00)`}
                                        value={item.amount}
                                        onFocus={() =>
                                          this.setState({
                                            isWritingInput:
                                              item.id,
                                          })
                                        }
                                        onChange={this.handleChangeInput}
                                      />
                                      <div className="currency-container-options">
                                        <div
                                          className="currency-container"
                                          onClick={() =>
                                            this.updateCostItemCurrency(
                                              "EUR",
                                              index
                                            )
                                          }
                                        >
                                          <div className="checkbox-background">
                                            <div
                                              className={
                                                item.currency == "EUR"
                                                  ? "currency-checkbox currency-checkbox-selected checked-center"
                                                  : "currency-checkbox checked-center"
                                              }
                                            />
                                          </div>
                                          <div className="currency-name">
                                            {"EUR"}
                                          </div>
                                        </div>
                                        <div
                                          className="currency-container"
                                          onClick={() =>
                                            this.updateCostItemCurrency(
                                              "RON",
                                              index
                                            )
                                          }
                                        >
                                          <div className="checkbox-background">
                                            <div
                                              className={
                                                item.currency == "RON"
                                                  ? "currency-checkbox currency-checkbox-selected checked-center"
                                                  : "currency-checkbox checked-center"
                                              }
                                            />
                                          </div>
                                          <div className="currency-name">
                                            {"RON"}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            </div>
                            {provided.placeholder}
                            <div className="final-drop-area-total-row">
                              <div className="total-text">
                                {languagePack["total_cost_match"]}
                              </div>
                              <div className="currency-container-options">
                                <div
                                  className="currency-container"
                                  onClick={() =>
                                    this.setState(
                                      { selected_currency_total: "EUR" },
                                      this.timeTotal
                                    )
                                  }
                                >
                                  <div className="checkbox-background">
                                    <div
                                      className={
                                        this.state.selected_currency_total ==
                                          "EUR"
                                          ? "currency-checkbox currency-checkbox-selected checked-center"
                                          : "currency-checkbox checked-center"
                                      }
                                    />
                                  </div>
                                  <div className="currency-name">{"EUR"}</div>
                                </div>
                                <div
                                  className="currency-container"
                                  onClick={() =>
                                    this.setState(
                                      { selected_currency_total: "RON" },
                                      this.timeTotal
                                    )
                                  }
                                >
                                  <div className="checkbox-background">
                                    <div
                                      className={
                                        this.state.selected_currency_total ==
                                          "RON"
                                          ? "currency-checkbox currency-checkbox-selected checked-center"
                                          : "currency-checkbox checked-center"
                                      }
                                    />
                                  </div>
                                  <div className="currency-name">{"RON"}</div>
                                </div>
                                <div className="total-value">
                                  {this.state.currentTotalTime +
                                    " " +
                                    this.state.selected_currency_total}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Droppable>
                      <div className="add-training-row-btn">
                        <TFBButton
                          color="green"
                          onClick={this.submitTraining}
                          disabled={
                            this.state.items.length > 0 &&
                            !this.state.items.every(
                              (elem) => Number(elem.amount) > 0
                            )
                          }
                          style={{ width: "100%" }}
                          startIcon
                        >
                          {languagePack["save_edit_match_costs"]}
                          <TFBIcon name="check" color="white" />
                        </TFBButton>
                      </div>
                    </TFBCardBody>
                  </TFBCard>
                </div>
                <div className="col-lg-6 col-lg-padding">
                  <TFBCard className="plan-training-container-edit-costs">
                    <TFBCardHeader title={languagePack["costs_categories"]}>
                      <TFBButton
                        onClick={this.openEditDepartments}
                        disabled={this.state.selected.length == 0}
                      >
                        {languagePack.edit_departaments_list}
                      </TFBButton>
                      <TFBButton
                        color="green"
                        onClick={this.openAddDepartment}
                        renderIcon={() => <TFBIcon name="add" />}
                      >
                        {languagePack["add_departament"]}
                      </TFBButton>
                    </TFBCardHeader>
                    <TFBCardBody>
                      <Droppable
                        droppableId="droppable2"
                        isDropDisabled
                        direction="horizontal"
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            className="existing-sections-list"
                          >
                            {this.state.selected.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="existing-section-container"
                                  >
                                    {item.category_name}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </TFBCardBody>
                  </TFBCard>
                </div>
              </div>
            </DragDropContext>
          </>
        )}

        {/* ------------------- ADD DEPARTMENT ---------------- */}
        {this.state.open_add_department &&
          <AddDepartment
            isOpenPopup={this.state.open_add_department}
            closePopup={this.closeAddDepartment}
            refreshList={this.getExistingCostsCategories}
          />
        }

        {/* ------------------- EDIT DEPARTMENTS ---------------- */}
        {this.state.open_edit_departments &&
          <DepartmentsList
            isOpenPopup={this.state.open_edit_departments}
            closePopup={this.closeEditDepartments}
            refreshList={() => { this.getDepartments(); this.getExistingCostsCategories(); }}
            list={this.state.departments}
          />
        }
      </>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  currencyItemSelected:
    myConstants.currencies[auth?.user?.currency] || myConstants.currencies.EUR,
}))(MatchCostsEdit);
