import React, { useState, useEffect } from "react";
import axios from "axios";
import { logout } from "./../../../utils/api";
import { connect, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Moment from "react-moment";
// import AddReportForm from "./AddReportForm";
import DeleteEvaluation from "./DeleteEvaluation";
import { Button, Icon, Dropdown } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
// import "../Reports.styles.scss";
import "./Evaluation.scss";
import moment from "moment";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye-white.svg";
import delete_icon from "../../../assets/icons/delete.png";

export function EvaluationsPage(props) {
  const [reports, setReports] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [physical_reports_year, setPhyYear] = useState(moment().year());
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [wantToDeleteEvaluation, setWantToDeleteEvaluation] = useState({});

  const history = useHistory();

  useEffect(() => {
    getEvaluations();
  }, []);

  const getEvaluations = async (year) => {
    const result = await axios(
      // `report/get_phys_tests?token=${props.token}&year=${year}&id_club=${props.currentUser.id_club}`
      `report/get_evaluations_tests?id_club=${props.currentUser.id_club}`
    );
    if (result?.data?.go_to_login === 1) {
      props.logout(props.token);
    } else {
      setReports(result.data);
    }
  };

  function onChangePhyYear(e) {
    getEvaluations(e.target.value);
    setPhyYear(e.target.value);
  }

  const { languagePack } = useSelector(
    ({ layoutService: { languagePack } }) => ({
      languagePack,
    })
  );

  const currentYear = moment().year();
  const years = [
    { key: currentYear, value: currentYear, text: currentYear },
    { key: currentYear - 1, value: currentYear - 1, text: currentYear - 1 },
    { key: currentYear - 2, value: currentYear - 2, text: currentYear - 2 },
  ];

  return (
    <div className="reports-page-container">
      <div className="row">
        <div className="col-xxl">
          <div className="card card-custom">
            <div className="report-list-container">
              <div className="report-list-header">
                <div className="report-category-name">
                  {languagePack.players_evaluations}
                </div>
                <div className="header-buttons">
                  <Button
                    className="add-report-button"
                    onClick={() => history.push("/reports/evaluation-add")}
                  >
                    {languagePack.add_evaluation}

                    <Icon name="add" className="add-report-button-icon" />
                  </Button>
                </div>
              </div>
              <div className="reports-list">
                <div className="report-row list-column-names report-display-desktop">
                  <div className="name">{languagePack.test_name}</div>
                  <div className="report-row-column">{languagePack.team}</div>
                  <div className="report-row-column">
                    {languagePack.evaluation_year}
                  </div>
                  <div className="report-row-column">
                    {" "}
                    {languagePack.evaluation_date}
                  </div>
                  <div className="options-button"></div>
                </div>
                {reports.map((row, index) => {
                  return (
                    <div
                      className="report-row"
                      key={row.id_test}
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                      }}
                    >
                      <div className="report-mobile-row report-wrapper-name">
                        <div className="name report-display-mobile">
                          {languagePack.test_name}
                        </div>
                        <div className="name">{row.test_name}</div>
                      </div>
                      <div className="report-mobile-row report-wrapper-team">
                        <div className="report-row-column  report-display-mobile">
                          {languagePack.team}
                        </div>
                        <div className="report-row-column">{row.team_name}</div>
                      </div>
                      <div className="report-mobile-row report-wrapper-evaluation-year">
                        <div className="report-row-column report-display-mobile">
                          {languagePack.evaluation_year}
                        </div>
                        <div className="report-row-column">
                          <Moment format="YYYY">{row.date_start}</Moment>
                        </div>
                      </div>
                      {/* <div className="report-row-column"> */}
                      <div className="report-mobile-row report-wrapper-evaluation-date">
                        <div className="report-row-column  report-display-mobile">
                          {" "}
                          {languagePack.evaluation_date}
                        </div>
                        <div className="report-evaluation-date">
                          <Moment format="DD.MM">{row.date_start}</Moment>-
                          <Moment format="DD.MM">{row.date_end}</Moment>
                        </div>
                      </div>
                      {/* </div> */}

                      <div className="options-button">
                        <div
                          className="evaluations-delete-button trash-hover"
                          onClick={() => {
                            setOpenDeletePopup(true);
                            setWantToDeleteEvaluation(row);
                          }}
                        >
                          <Icon
                            name="trash alternate"
                            className="delete-icon"
                          />
                        </div>
                        <div
                          className="report-see-more-button"
                          onClick={() =>
                            history.push(
                              `/reports/evaluations_test/${row.id_test}`
                            )
                          }
                        >
                          <EyeIcon />
                        </div>
                        <div
                          className="evaluations-edit-button"
                          onClick={() => {
                            history.push(
                              `/reports/evaluations_test/edit/${row.id_test}-${row.id_team}`
                            );
                          }}
                        >
                          <Icon name="edit" className="edit-icon" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openDeletePopup}
        // onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <DialogContent>
          <DeleteEvaluation
            closePopup={() => setOpenDeletePopup(false)}
            getEvaluations={() => getEvaluations()}
            evaluationDelete={wantToDeleteEvaluation}
            id_club={props.currentUser.id_club}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(EvaluationsPage);
