import React from "react";
import IndexChart from "../../components/data-providers/instat/team/IndexChart";
import TeamSeasonStats from "../../components/data-providers/instat/team/TeamSeasonStats.jsx";
import LastMatches from "../../components/data-providers/instat/team/LastMatches";
import TeamPlayersTop from "../../components/data-providers/instat/team/TeamPlayersTop";
import { connect } from "react-redux";

class TeamPerformance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const id_team = this.props.match.params.id;
    const { languagePack } = this.props;
    return (
      <div>
        <div
          style={{
            fontSize: 25,
            color: "#000000",
            fontWeight: 700,
            marginBottom: 40,
          }}
        >
          {languagePack["team_performance"]}
        </div>
        <div className="row">
          <div className="col-lg-6 col-xx-6 widget">
            <LastMatches id_team={id_team} id_club={this.props.currentUser.id_club} languagePack={languagePack} />
          </div>
          <div className="col-lg-6 col-xx-6 widget">
            <TeamSeasonStats
              id_team={id_team}
              languagePack={languagePack}
              lang={this.props.lang}
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: 20 }}>
          <div className="col-lg-8 col-xxl-8 widget">
            <IndexChart id_team={id_team} id_club={this.props.currentUser.id_club} languagePack={languagePack} />
          </div>
          <div className="col-lg-4 col-xxl-4 widget">
            <TeamPlayersTop id_team={id_team} id_club={this.props.currentUser.id_club} languagePack={languagePack} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  lang: layoutService.lang,
  currentUser: auth.user,
}))(TeamPerformance);
