import React from "react";
import { Dropdown } from "semantic-ui-react";
import { DropdownProps } from "../types";
import styles from "./TFBDropdown.module.scss";

const TFBDropdown = ({
  className = "",
  color = "gray",
  selection,
  search,
  clearable,
  fluid,
  disabled,
  placeholder,
  name,
  options,
  value,
  onChange,
  onFocus,
  label,
}: DropdownProps) => {
  const cssColor = color;
  return label ? (
    <div className={styles.dropdownGroup}>
      <h6 className={styles.dropdownLabel}>{label}</h6>
      <Dropdown
        className={`${styles.tfbDropdown} ${className} ${styles[cssColor]} tfb-dropdown`}
        selection={selection}
        search={search}
        clearable={clearable}
        disabled={disabled}
        fluid={fluid}
        placeholder={placeholder}
        name={name}
        options={options}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
      />
    </div>
  ) : (
    <Dropdown
      className={`${styles.tfbDropdown} ${className} ${styles[cssColor]}`}
      selection={selection}
      search={search}
      clearable={clearable}
      disabled={disabled}
      fluid={fluid}
      placeholder={placeholder}
      name={name}
      options={options}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
};

export default TFBDropdown;
