import React from "react";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import axios from "axios";
import { store } from "react-notifications-component";

export default function DeleteEvent({
  closePopup,
  openDeletePopup,
  languagePack,
  id_video,
  id_video_tag,
  id_club,
  refreshTags,
  token,
  logout,
}) {
  const renderBtn = (position, onClick) => {
    return (
      <TFBButton
        onClick={onClick}
        endIcon={true}
        color={position == "left" ? "darkGray" : "red"}
        renderIcon={() => (
          <TFBIcon name={position == "left" ? "cancel" : "delete"} />
        )}
        prettier-ignore
      >
        {position == "left" ? languagePack.Cancel : languagePack.Delete}
      </TFBButton>
    );
  };

  const deleteTag = () => {
    let payload = {
      id_video: id_video,
      id_club: id_club,
      id_video_tag: id_video_tag,
      deleted: "1",
    };

    axios
      .post("upload/add_video_tag", payload, {
        params: { token: token },
        headers: { "content-type": "multipart/form-data" },
      })
      .then(async (res) => {
        if (res?.data?.go_to_login === 1) {
          logout(token);
        }
        if (res.data == 1) {
          refreshTags();
          store.addNotification({
            title: "Video editing",
            message: "Video tag was successfully deleted!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
            },
          });
        } else {
          store.addNotification({
            title: "Video editing",
            message: "Video tag deleting has failed!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
            },
          });
        }
        closePopup();
        return res;
      })
      .catch(async (err) => {
        return { data: {} };
      });
  };

  return (
    <TFBDialog
      className="pop-up-dialog-box prevent-select-text"
      title={languagePack.delete_tag}
      open={openDeletePopup}
      closePopup={closePopup}
      btnRight={() => renderBtn("right", () => deleteTag())}
      btnLeft={() => renderBtn("left", () => closePopup())}
    >
      <div style={{ fontWeight: 700, fontSize: "15px", textAlign: "center" }}>
        {languagePack.sure_delete_tag}
      </div>
    </TFBDialog>
  );
}
