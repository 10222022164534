import React from "react";
import axios from "axios";
import { logout } from "./../../../utils/api";
import "./BonusesMonitoring.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon, Flag, Button, Dropdown } from "semantic-ui-react";
import moment from "moment";
import NumberFormat from "react-number-format";

class BonusesMonitoring extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      id_season: "",
      current_season: "",
      seasons: [],
    };
  }

  componentDidMount = () => {
    this.getSeasons();
  };

  getSeasons = () => {
    axios
      .get(`club/get_seasons_by_club?id_club=${this.props.currentUser.id_club}`)
      .then((res) =>
        this.setState(
          {
            seasons: res.data.seasons,
            id_season: res.data.activeSeason,
            current_season: res.data.activeSeason,
          },
          () => {
            this.getPlayersBonuses();
          }
        )
      )
      .catch((err) => console.log(err));
  };

  getPlayersBonuses = () => {
    axios
      .get(
        `upload/get_players_bonuses?id_club=${this.props.currentUser.id_club}&id_season=${this.state.id_season}`
      )
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value }, this.getPlayersBonuses);
  };

  addDefaultSrc = (event) => {
    event.target.src = `https://api.thefootballbrain.app/uploads/players/placeholder.png`;
  };

  formatNumber = (value) => {
    // return (
    //     <NumberFormat
    //         value={value}
    //         displayType={"text"}
    //         thousandSeparator={true}
    //         decimalScale={0}
    //     />
    // );
    return parseFloat(value).toLocaleString("en-US");
  };

  generateBonusMonitoringReport = () => {
    const url = `https://api.thefootballbrain.app/upload/generate_players_bonuses_download?id_season=${this.state.id_season}&id_club=${this.props.currentUser.id_club}`;
    window.location.assign(url);
  };

  render() {
    const { languagePack } = this.props;

    return (
      <>
        <div className="season-select-bonuses-page">
          <Button
            className="add-team-button"
            onClick={this.generateBonusMonitoringReport}
          >
            {languagePack.download_in_format + " .pdf"}
          </Button>
          <Dropdown
            placeholder="Season"
            selection
            options={this.state.seasons}
            className="players-season-dropdown"
            value={this.state.id_season}
            onChange={this.handleChangeDropdown}
            name="id_season"
          />
        </div>
        {this.state.data.map((elem, index) => {
          return (
            <div
              className="row"
              style={{ display: "block", margin: "0 0 25px 0" }}
            >
              <div className="card card-custom">
                <div className="bonuses-monitoring-container">
                  <div className="player-data">
                    <div className="player-img-container">
                      <img
                        className="player-img"
                        src={`https://api.thefootballbrain.app/uploads/players/${elem.id_player}.jpg`}
                        onError={this.addDefaultSrc}
                      />
                    </div>
                    <div className="player-name">{elem.player_name}</div>
                  </div>
                  <div className="bonuses-table">
                    <div className="bonuses-table-header bonuses-table-desktop">
                      <div className="col-b bonus-name">
                        {languagePack.bonus}
                      </div>
                      <div className="col-b bonus-progress">
                        {languagePack.made_by}
                      </div>
                      <div className="col-b match-date-realization">
                        {languagePack.match_and_date_of_the_event}
                      </div>
                      <div className="col-b bonus-value">{"Valoare bonus"}</div>
                      <div className="col-b done-payments">
                        {languagePack.payments_made}
                      </div>
                      <div className="col-b total-made-payments">
                        {languagePack.total_paid}
                      </div>
                      <div className="col-b remaining-to-pay">
                        {languagePack.remaining_payment}
                      </div>
                    </div>
                    <div className="bonuses-table-list">
                      {elem.bonuses.length > 0 ? (
                        <>
                          {elem.bonuses.map((bonus, index) => {
                            const bonus_value = this.formatNumber(
                              bonus.bonus_value
                            );
                            return (
                              <div className="players-bonuses">
                                <div
                                  className="bonus-row"
                                  style={{
                                    backgroundColor:
                                      index % 2 == 0
                                        ? "#fafafa"
                                        : "transparent",
                                  }}
                                >
                                  <div className="bonuses-content wrapper-bonus-name">
                                    <div className="col-b bonus-name display-none-desktop bonus-header-color">
                                      {languagePack.bonus}
                                    </div>
                                    <div className="col-b bonus-name">
                                      {bonus.bonus_name}
                                    </div>
                                  </div>
                                  <div className="bonuses-content wrapper-bonus-progress">
                                    <div className="col-b bonus-progress display-none-desktop bonus-header-color">
                                      {languagePack.made_by}
                                    </div>
                                    <div
                                      className="col-b bonus-progress"
                                      style={{
                                        color:
                                          bonus.progress_type == "full"
                                            ? "var(--primaryColor)"
                                            : bonus.progress_type ==
                                                "no-recording" &&
                                              bonus.progress > 0
                                            ? "var(--primaryColor)"
                                            : "#666666",
                                      }}
                                    >
                                      {bonus.progress}
                                    </div>
                                  </div>

                                  <div className="bonuses-content wrapper-match-date-realization">
                                    <div className="col-b match-date-realization display-none-desktop bonus-header-color">
                                      {languagePack.match_and_date_of_the_event}
                                    </div>
                                    <div className="col-b match-date-realization">
                                      {bonus.match_date_realization.length >
                                      0 ? (
                                        bonus.match_date_realization.map(
                                          (match, index) => {
                                            return (
                                              <div
                                                className="match-data"
                                                style={{
                                                  marginBottom:
                                                    index ==
                                                    bonus.match_date_realization
                                                      .length -
                                                      1
                                                      ? "0"
                                                      : "10px",
                                                }}
                                              >
                                                {match.quantity + "x"}
                                                <span className="bonus-grey-text">
                                                  {" vs "}
                                                </span>
                                                {match.opponent_name}
                                                <img
                                                  className="match-opponent-img"
                                                  src={`https://instatscout.com/images/teams/180/${match.id_opponent}.png`}
                                                />
                                                {`(${match.match_score})`}
                                                <span className="bonus-grey-text">{`, ${moment(
                                                  match.match_date
                                                ).format("DD.MM.YYYY")}`}</span>
                                                {/* <div className="match-quantity">
                                                  {match.quantity + "x"}
                                                </div>
                                                <div className="match-vs">
                                                  {"vs"}
                                                </div>
                                                <div className="match-opponent">
                                                  {match.opponent_name}
                                                </div>
                                                <div>
                                                  <img
                                                    className="match-opponent-img"
                                                    src={`https://instatscout.com/images/teams/180/${match.id_opponent}.png`}
                                                  />
                                                </div>
                                                <div className="match-score">{`(${match.match_score})`}</div>
                                                <div className="match-date">{`, ${moment(
                                                  match.match_date
                                                ).format("DD.MM.YYYY")}`}</div> */}
                                              </div>
                                            );
                                          }
                                        )
                                      ) : (
                                        <div>-</div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="bonuses-content wrapper-bonus-value">
                                    <div className="col-b bonus-value display-none-desktop bonus-header-color">
                                      {languagePack.bonus_value}
                                    </div>
                                    <div className="col-b bonus-value">
                                      {bonus.bonus_type == "1"
                                        ? bonus_value + ` € / unitate`
                                        : bonus_value + ` €`}
                                    </div>
                                  </div>
                                  <div className="bonuses-content wrapper-done-payments">
                                    <div className="col-b done-payments display-none-desktop bonus-header-color">
                                      {languagePack.payments_made}
                                    </div>
                                    <div className="col-b done-payments">
                                      {bonus.made_payments.length > 0 ? (
                                        bonus.made_payments.map(
                                          (payment, index) => {
                                            return (
                                              <div
                                                className="bonus-payment-row"
                                                style={{
                                                  marginBottom:
                                                    index ==
                                                    bonus.made_payments.length -
                                                      1
                                                      ? "0"
                                                      : "10px",
                                                }}
                                              >
                                                <div className="payment-value">
                                                  {payment.payment_amount +
                                                    " €"}
                                                </div>
                                                <div className="payment-date">
                                                  {", " +
                                                    moment(
                                                      payment.payment_date
                                                    ).format("DD.MM.YYYY")}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )
                                      ) : (
                                        <div className="no-payments-made">
                                          -
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="bonuses-content wrapper-total-made-payments">
                                    <div className="col-b total-made-payments display-none-desktop bonus-header-color">
                                      {languagePack.total_paid}
                                    </div>
                                    <div className="col-b total-made-payments">
                                      {bonus.total_made_payments > 0
                                        ? bonus.total_made_payments + "€"
                                        : "0 €"}
                                    </div>
                                  </div>
                                  <div className="bonuses-content wrapper-remaining-to-pay">
                                    <div className="col-b remaining-to-pay display-none-desktop bonus-header-color">
                                      {languagePack.remaining_payment}
                                    </div>
                                    <div
                                      className="col-b remaining-to-pay"
                                      style={{
                                        color:
                                          bonus.remaining_to_pay > 0
                                            ? "#d91200"
                                            : "#666666",
                                      }}
                                    >
                                      {bonus.remaining_to_pay > 0
                                        ? bonus.remaining_to_pay + " €"
                                        : "0 €"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <div>{languagePack.there_are_no_records}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    // currencyItemSelected:
    //     myConstants.currencies[auth?.user?.currency] ||
    //     myConstants.currencies.EUR,
  }),
  mapDispatchToProps
)(BonusesMonitoring);
