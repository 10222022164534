import styled from "styled-components";

export const PlaceHolderWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  font-weight: 700;

  @media only screen and (max-width: 992px) {
    .nodata-placeholder-text {
      font-size: 12px;
    }

    .nodata-placeholder-icon{
      width: 25px;
      height: 25px;
    }
  }

  @media only screen and (min-width: 992px) {
    .nodata-placeholder-text {
      font-size: 14px;
    }

    .nodata-placeholder-icon{
      width: 50px;
      height: 50px'
    }
  }
`;
