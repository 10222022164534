import React from "react";
import { Icon } from "semantic-ui-react";

export default function EndOfMonthSold(props) {
  return (
    <>
      {props.end_month_values.length > 0 ? (
        <div className="partner-profile-table-container">
          <div className="end-month-sold-header">
            Balanta la finalul lunii - sold curent:{" "}
            {Math.round(props.current_sold, 2)}
          </div>
          <div className="partner-profile-table-row table-header-partner-profile">
            <div className="partner-profile-table-cell">Luna</div>
            <div className="partner-profile-table-cell">Valoare</div>
          </div>
          {props.end_month_values.map((row, index) => {
            return (
              <div
                className="partner-profile-table-row"
                key={row.id_current_sold}
                style={{ backgroundColor: index % 2 === 0 ? "#fafafa" : "" }}
              >
                <div className="partner-profile-table-cell">
                  {row.month} {row.year}
                </div>
                <div className="partner-profile-table-cell">
                  <div style={{ display: "flex", placeContent: "center" }}>
                    <div className="debts-table-amount">{row.value}</div>
                    {row.currency === "EUR" ? (
                      <Icon name="eur" color="black" className="mg--0" />
                    ) : (
                      "RON"
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <div className="partner-profile-table-container">
            <div className="end-month-sold-header">
              Balanta la finalul lunii - CLIENT
            </div>
            <div className="partner-profile-table-row table-header-partner-profile">
              <div className="partner-profile-table-cell">Luna</div>
              <div className="partner-profile-table-cell">Valoare</div>
            </div>
            {props.client_end_month_values.map((row, index) => {
              return (
                <div
                  className="partner-profile-table-row"
                  key={row.id_current_sold}
                  style={{ backgroundColor: index % 2 === 0 ? "#fafafa" : "" }}
                >
                  <div className="partner-profile-table-cell">
                    {row.month} {row.year}
                  </div>
                  <div className="partner-profile-table-cell">
                    <div style={{ display: "flex", placeContent: "center" }}>
                      <div className="debts-table-amount">{row.value}</div>
                      {row.currency === "EUR" ? (
                        <Icon name="eur" color="black" className="mg--0" />
                      ) : (
                        "RON"
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="partner-profile-table-container">
            <div className="end-month-sold-header">
              Balanta la finalul lunii - FURNIZOR
            </div>
            <div className="partner-profile-table-row table-header-partner-profile">
              <div className="partner-profile-table-cell">Luna</div>
              <div className="partner-profile-table-cell">Valoare</div>
            </div>
            {props.provider_end_month_values.map((row, index) => {
              return (
                <div
                  className="partner-profile-table-row"
                  key={row.id_current_sold}
                  style={{ backgroundColor: index % 2 === 0 ? "#fafafa" : "" }}
                >
                  <div className="partner-profile-table-cell">
                    {row.month} {row.year}
                  </div>
                  <div className="partner-profile-table-cell">
                    <div style={{ display: "flex", placeContent: "center" }}>
                      <div className="debts-table-amount">{row.value}</div>
                      {row.currency === "EUR" ? (
                        <Icon name="eur" color="black" className="mg--0" />
                      ) : (
                        "RON"
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
