import React from "react";
import { Form, Divider } from "semantic-ui-react";
import MakePayment from "./MakePayment";
import NewInvoice from "./NewInvoice";
import "../Providers/Providers.scss";
import { connect } from "react-redux";
import "./ElementOperations.scss";

class ElementOperations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_operation: "",
    };
  }

  refreshSection = () => {
    if (this.props.section === "debts") {
      this.props.getDebts();
    } else if (this.props.section === "sponsors") {
      this.props.getSponsors();
    }
    this.setState({ checkbox: "" });
  };

  render() {
    return (
      <div className="operations-container">
        <div className="options-operation-container">
          <div
            className="option-operation"
            onClick={() => this.setState({ selected_operation: "invoice" })}
            style={{
              backgroundColor:
                this.state.selected_operation === "invoice" ? "#fafafa" : "",
            }}
          >
            {this.props.section === "sponsors" ? (
              <div className="op-button-name">Emitere factura</div>
            ) : (
              <div className="op-button-name">Inregistrare factura</div>
            )}
          </div>
          <div
            className="option-operation"
            onClick={() => this.setState({ selected_operation: "payment" })}
            style={{
              backgroundColor:
                this.state.selected_operation === "payment" ? "#fafafa" : "",
            }}
          >
            {this.props.section === "sponsors" ? (
              <div className="op-button-name">Inregistrare plata</div>
            ) : (
              <div className="op-button-name">Efectuare plata</div>
            )}
          </div>
        </div>

        {this.state.selected_operation === "payment" && (
          <div style={{ backgroundColor: "#fafafa" }}>
            <MakePayment
              partner={this.props.partner}
              id_club={this.props.currentUser.id_club}
              closeSection={() => this.refreshSection()}
              section={this.props.section}
              closeOperationsPopup={() => this.props.closeOperationsPopup()}
            />
          </div>
        )}
        {this.state.selected_operation === "invoice" && (
          <div style={{ backgroundColor: "#fafafa" }}>
            <NewInvoice
              partner={this.props.partner}
              id_club={this.props.currentUser.id_club}
              closeSection={() => this.refreshSection()}
              section={this.props.section}
              closeOperationsPopup={() => this.props.closeOperationsPopup()}
            />
          </div>
        )}
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(ElementOperations);
