import React from "react";
import "./FansDashboard.scss";
import FacebookDashboard from "./Facebook/FacebookDashboard";
import InstagramDashboard from "./Instagram/InstagramDashboard";
import { Dropdown, Icon, Button } from "semantic-ui-react";
import axios from "axios";
import { connect } from "react-redux";
import YoutubeDashboard from "./Youtube/YoutubeDashboard";

const years_options = [
  {
    key: new Date().getFullYear(),
    text: new Date().getFullYear(),
    value: new Date().getFullYear(),
  },
  {
    key: new Date().getFullYear() - 1,
    text: new Date().getFullYear() - 1,
    value: new Date().getFullYear() - 1,
  },
  {
    key: new Date().getFullYear() - 2,
    text: new Date().getFullYear() - 2,
    value: new Date().getFullYear() - 2,
  },
];

class FansDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: new Date().getFullYear(),
      selected_network: "",
      selected_network_type: "",
      network_options: [],
    };
  }

  dropdownRef = React.createRef();

  componentDidMount = () => {
    this.getNetworks();
  };

  getNetworks = () => {
    axios
      .get(`fans/get_networks?id_club=${this.props.currentUser.id_club}`)
      .then((res) =>
        this.setState({
          network_options: res.data,
          selected_network: res.data[0]?.value,
          selected_network_type: res.data[0]?.network_type,
        })
      )
      .catch((err) => console.log(err));
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value });
  };

  handleChangeDropdownSocial = (e, value) => {
    this.setState({
      selected_network: value.value,
      selected_network_type: this.state.network_options.filter(
        (el) => el.value == value.value
      )[0].network_type,
    });
  };

  handleItemClick = (_e, x) => {
    this.dropdownRef.current.handleItemClick(_e, x);
  };

  render() {
    return (
      <>
        {this.state.network_options.length > 0 ? (
          <>
            <div className="fans-dashboard-filters">
              <div className="fans-dashboard-title">Social media dashboard</div>
              <div className="filters-container">
                <Dropdown
                  selection
                  value={this.state.selected_network}
                  onChange={this.handleChangeDropdownSocial}
                  className={
                    this.state.selected_network_type == "instagram"
                      ? "fans-dashboard-social-dropdown insta-bg"
                      : "fans-dashboard-social-dropdown"
                  }
                  text={
                    <div style={{ display: "flex", color: "white" }}>
                      <Icon
                        name={
                          this.state.selected_network_type == "facebook"
                            ? "facebook f"
                            : this.state.selected_network_type
                        }
                        style={{ marginRight: 5 }}
                      />
                      {
                        this.state.network_options.filter(
                          (el) => el.value == this.state.selected_network
                        )[0]?.text
                      }
                    </div>
                  }
                  style={{
                    backgroundColor: this.state.network_options.filter(
                      (el) => el.value == this.state.selected_network
                    )[0]?.color,

                    marginRight: 15,
                  }}
                  ref={this.dropdownRef}
                >
                  <Dropdown.Menu>
                    {this.state.network_options.map((item) => (
                      <Dropdown.Item
                        key={item.value}
                        value={item.value}
                        // text={item.text}
                        // icon={item.icon}
                        active={item.value === this.state.selected_network}
                        onClick={this.handleItemClick}
                      >
                        <Icon name={item.icon} color="black" />
                        {item.text}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown
                  selection
                  disabled={
                    this.state.selected_network_type == "instagram" ||
                    this.state.selected_network_type == "youtube"
                  }
                  options={years_options}
                  value={this.state.year}
                  onChange={this.handleChangeDropdown}
                  name="year"
                  className="fans-dashboard-year-dropdown"
                  style={{ backgroundColor: "#666666" }}
                />
                <Button
                  className="connect-account-button"
                  onClick={(e) =>
                    window.open(
                      `https://api.thefootballbrain.app/fans/login_url?id_club=${this.props.currentUser.id_club}&id_user=${this.props.currentUser.id_user}`,
                      "_blank"
                    )
                  }
                >
                  Connect account
                </Button>
              </div>
            </div>

            {this.state.selected_network_type == "facebook" && (
              <FacebookDashboard
                year={this.state.year}
                id_network={this.state.selected_network}
              />
            )}

            {this.state.selected_network_type == "instagram" && (
              <>
                <InstagramDashboard
                  year={this.state.year}
                  id_network={this.state.selected_network}
                />
              </>
            )}
            {this.state.selected_network_type == "youtube" && (
              <YoutubeDashboard id_club={this.props.currentUser.id_club} />
            )}
          </>
        ) : (
          <div className="no-networks-container">
            <div
              className="no-networks-button"
              onClick={(e) =>
                window.open(
                  `https://api.thefootballbrain.app/fans/login_url?id_club=${this.props.currentUser.id_club}&id_user=${this.props.currentUser.id_user}`,
                  "_blank"
                )
              }
            >
              <div>Connect Facebook and Instagram accounts</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                width="14px"
                height="10px"
                version={1.0}
                style={{
                  shapeRendering: "geometricPrecision",
                  textRendering: "geometricPrecision",
                  imageRendering: "optimizeQuality",
                  fillRule: "evenodd",
                  clipRule: "evenodd",
                }}
                viewBox="0 0 29955 20404"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <defs>
                  <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                      __html:
                        "\n   \n    .str0155 {stroke:#ffffff;stroke-width:5284.61;stroke-linecap:round;stroke-linejoin:round}\n    .fil0155 {fill:none}\n   \n  ",
                    }}
                  />
                </defs>
                <g id="Layer_x0020_1">
                  <metadata id="CorelCorpID_0Corel-Layer" />
                  <g id="_2630011971984">
                    <path className="fil0155 str0155" d="M2643 10203l24671 0" />
                    <path
                      className="fil0155 str0155"
                      d="M21086 2641l6228 7562 -6228 7559"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(FansDashboard);
