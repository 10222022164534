import React from "react"
import { Icon } from "semantic-ui-react"
import "./InitialSetUpPage.scss"
import { useHistory } from "react-router-dom";


export default function InitialSetUpPage() {

    const history = useHistory();

    return (
        <>
            <div className="row-setup">
                <div className="col-lg-6">
                    <div className="card card-custom">
                        <div className="elem-init"
                            onClick={() => history.push({
                                pathname: "/club",
                                state: {
                                    openAddTeam: true
                                }
                            })}>
                            <div className="elem-icon logo">
                                <Icon name="shield" size="large" />
                            </div>
                            <div className="elem-title">
                                {"Adauga echipa"}
                            </div>
                            <div className="elem-icon plus">
                                <Icon name="plus" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row-setup">
                <div className="col-lg-6">
                    <div className="card card-custom">
                        <div className="elem-init"
                            onClick={() => history.push({
                                pathname: "/club",
                                state: {
                                    openAddStaff: true
                                }
                            })}>
                            <div className="elem-icon logo">
                                <Icon name="user" size="large" />
                            </div>
                            <div className="elem-title">
                                {"Adauga membru staff"}
                            </div>
                            <div className="elem-icon plus">
                                <Icon name="plus" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row-setup">
                <div className="col-lg-6">
                    <div className="card card-custom">
                        <div className="elem-init">
                            <div className="elem-icon logo">
                                <Icon name="user circle" size="large" />
                            </div>
                            <div className="elem-title">
                                {"Adauga jucator"}
                            </div>
                            <div className="elem-icon plus">
                                <Icon name="plus" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row-setup">
                <div className="col-lg-6">
                    <div className="card card-custom">
                        <div className="elem-init"
                            onClick={() => history.push({
                                pathname: "/training/plan/add",
                            })}>
                            <div className="elem-icon logo">
                                {trainingSVG}
                            </div>
                            <div className="elem-title">
                                {"Adauga antrenament"}
                            </div>
                            <div className="elem-icon plus">
                                <Icon name="plus" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row-setup">
                <div className="col-lg-6">
                    <div className="card card-custom">
                        <div className="elem-init"
                        onClick={() => history.push({
                            pathname: "/club/competitions",
                            state: {
                                openAddCompetition: true
                            }
                        })}>
                            <div className="elem-icon logo">
                                <Icon name="winner" size="large" />
                            </div>
                            <div className="elem-title">
                                {"Adauga competitie"}
                            </div>
                            <div className="elem-icon plus">
                                <Icon name="plus" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row-setup">
                <div className="col-lg-6">
                    <div className="card card-custom">
                        <div className="elem-init">
                            <div className="elem-icon logo">
                                {matchSVG}
                            </div>
                            <div className="elem-title">
                                {"Adauga meci"}
                            </div>
                            <div className="elem-icon plus">
                                <Icon name="plus" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row-setup">
                <div className="col-lg-6">
                    <div className="card card-custom">
                        <div className="elem-init">
                            <div className="elem-icon logo">
                                <Icon name="percent" size="large" />
                            </div>
                            <div className="elem-title">
                                {"Configureaza formular 230 redirectionare impozit pe venit"}
                            </div>
                            <div className="elem-icon plus">
                                <Icon name="plus" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const trainingSVG =
    <span className="svg-icon svg-icon-lg elem-icon" style={{ width: "auto", color: "var(--primaryColor)" }}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            width="20px"
            height="27px"
            version="1.1"
            style={{
                shapeRendering: "geometricPrecision",
                textRendering: "geometricPrecision",
                imageRendering: "optimizeQuality",
                fillRule: "evenodd",
                clipRule: "evenodd",
            }}
            viewBox="0 0 76144 101361"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n   \n    .str099a {stroke:var(--primaryColor);stroke-width:9742.89;stroke-linecap:round;stroke-linejoin:round}\n    .str199a {stroke:var(--primaryColor);stroke-width:9742.89;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:19485.775001 29228.662501}\n    .fil0 {fill:none}}\n   \n  ",
                    }}
                />
            </defs>
            <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <g id="_2383571581264">
                    <polygon
                        className="fil0 str099a"
                        points="16877,64015 21726,64015 30402,96485 8205,96485 "
                    />
                    <line
                        className="fil0 str099a"
                        x1={4871}
                        y1={96485}
                        x2={33736}
                        y2={96485}
                    />
                    <line
                        className="fil0 str099a"
                        x1={12539}
                        y1={83497}
                        x2={26064}
                        y2={83497}
                    />
                </g>
                <path
                    className="fil0 str199a"
                    d="M69823 96489c4,-43843 -64955,-38975 -64955,-90935"
                />
                <polygon
                    className="fil0 str099a"
                    points="54414,4871 59263,4871 67939,37341 45742,37341 "
                />
                <line
                    className="fil0 str099a"
                    x1={42408}
                    y1={37341}
                    x2={71273}
                    y2={37341}
                />
                <line
                    className="fil0 str099a"
                    x1={50076}
                    y1={24353}
                    x2={63601}
                    y2={24353}
                />
            </g>
        </svg>
    </span>;

const matchSVG =
    <span className="svg-icon menu-icon">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            width="20px"
            height="13px"
            version="1.1"
            style={{
                shapeRendering: "geometricPrecision",
                textRendering: "geometricPrecision",
                imageRendering: "optimizeQuality",
                fillRule: "evenodd",
                clipRule: "evenodd",
            }}
            viewBox="0 0 130885 82695"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n   \n    .str022a {stroke:var(--primaryColor);stroke-width:17074;stroke-linecap:round;stroke-linejoin:round}\n    .fil0 {fill:none}\n   \n  ",
                    }}
                />
            </defs>
            <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <g id="_2249863582672">
                    <g>
                        <line
                            className="fil0 str022a"
                            x1={8530}
                            y1={74164}
                            x2={54482}
                            y2={8524}
                        />
                        <line
                            className="fil0 str022a"
                            x1={30955}
                            y1={65412}
                            x2={9081}
                            y2={50093}
                        />
                    </g>
                    <g>
                        <line
                            className="fil0 str022a"
                            x1={122355}
                            y1={74164}
                            x2={76396}
                            y2={8524}
                        />
                        <line
                            className="fil0 str022a"
                            x1={99930}
                            y1={65412}
                            x2={121804}
                            y2={50093}
                        />
                    </g>
                </g>
            </g>
        </svg>
    </span>