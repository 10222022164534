import React from "react";
import styles from "./TFBDatePicker.module.scss";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { TDatePicker } from "../types";
import TFBIcon from "../Icon/TFBIcon";

const TFBDatePicker = ({
  onChange,
  label,
  value,
  style,
  placeholder,
  className,
  name
}: TDatePicker) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {label ? (
        <div className={styles.tfbDateGroup}>
          <h6 className={styles.dateLabel}>{label}</h6>
          <div className={styles.tfbDatePickerContainer}>
            <DatePicker
              value={value}
              onChange={onChange}
              emptyLabel={placeholder}
              format="dd.MM.yyyy"
              inputVariant="outlined"
              className={`${styles.tfbDatePicker} ${className ? className : ""}`}
              style={style}
              name={name}
            />
            <TFBIcon name="calendar" color="gray" className={styles.tfbDatePikerIcon} />
          </div>
        </div>
      ) : (
        <div className={styles.tfbDatePickerContainer}>
          <DatePicker
            value={value}
            onChange={onChange}
            emptyLabel={placeholder}
            format="dd.MM.yyyy"
            inputVariant="outlined"
            className={`${styles.tfbDatePicker} ${className ? className : ""}`}
            style={style}
            name={name}
          />
          <TFBIcon name="calendar" color="gray" className={styles.tfbDatePikerIcon} />
        </div>
      )}
    </MuiPickersUtilsProvider>
  );
};

export default TFBDatePicker;
