import React from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import TFBIconButton from "../../components/design-system/IconButton/TFBIconButton";
import TFBButton from "../../components/design-system/Button/TFBButton";
import TFBIcon from "../../components/design-system/Icon/TFBIcon";

function DeleteUser(props) {
  const { languagePack } = props;

  function deleteUser() {
    const payload = {
      id_user: props.id_user_to_delete,
      token: props.token,
    };

    axios.post("user/delete_user", payload).then((resp) => {
      if (resp.data.success == 1) {
        store.addNotification({
          title: languagePack.users_management,
          message: languagePack.delete_user_success,
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
        props.refreshUsers();
        props.closePopup();
      }

      if (resp.data.success == 0) {
        store.addNotification({
          title: languagePack.users_management,
          message: languagePack.delete_user_danger,
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });

        props.closePopup();
      }
    });
  }

  return (
    <div className="delete-user-popup-container">
      <div className="delete-user-header">
        <div className="delete-user-title">{languagePack.delete_user}</div>

        <TFBIconButton
          name="x-small"
          color="darkGray"
          onClick={() => props.closePopup()}
        />
      </div>
      <div className="delete-user-text">
        {languagePack.delete_user_confirmation}
      </div>
      <div className="delete-user-buttons">
        <TFBButton
          endIcon={true}
          color="darkGray"
          onClick={() => props.closePopup()}
          renderIcon={() => <TFBIcon name="cancel" />}
          style={{ width: "100%" }}
        >
          {languagePack["admin_cancel"]}
        </TFBButton>

        <TFBButton
          color="red"
          onClick={() => deleteUser()}
          endIcon={true}
          renderIcon={() => <TFBIcon name="delete" />}
          style={{ width: "100%" }}
        >
          {languagePack.Delete}
        </TFBButton>
      </div>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(DeleteUser);
