import React from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import "./ReportCharts.scss";
import { Icon } from "semantic-ui-react";

class PlayerTechTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      results_list: [],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        title: {
          text: this.props.player_name,
          align: "left",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return value.toLocaleString();
            },
          },
        },
        colors: [window.clubCustomization.primary_color, "#666666"],
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val.toLocaleString();
            },
          },
        },
      },
    };
  }

  componentDidMount = () => {
    axios
      .get(`report/tech_test_result`, {
        params: {
          id_player: this.props.id_player,
          id_test: this.props.id_test,
        },
      })
      .then((res) => {
        this.setState((prevState) => ({
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.xaxis,
              categories: res.data.test_params,
            },
          },
          series: res.data.series,
          results_list: Array.isArray(res.data.results_list)
            ? res.data.results_list
            : [],
        }));
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <>
        <div>
          <Chart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={350}
            width={750}
          />
        </div>
        <div className="grades-list">
          {this.state.results_list.map((item) => {
            return (
              <div className="grade-row">
                <div className="param-name">{item.name}</div>
                <div className="param-foot">{item.foot}</div>
                <div className="param-grade">{item.grade}</div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default PlayerTechTest;
