import React from "react";
import { Icon, Dropdown, Button, Checkbox } from "semantic-ui-react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { store } from "react-notifications-component";
import axios from "axios";
import { connect } from "react-redux";
import { countries } from "../../../utils/countries";
import TFBDatePicker from "../../../components/design-system/DatePicker/TFBDatePicker";
import TFBPhoneInput from "../../../components/design-system/PhoneInput/TFBPhoneInput";
// import Checkbox from "@material-ui/core/Checkbox";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const positions_dropdown = [
  { key: "GK", value: "Goalkeeper", text: "Portar" },
  { key: "DEF", value: "Defender", text: "Fundas" },
  { key: "MID", value: "Midfielder", text: "Mijlocas" },
  { key: "ATT", value: "Forward", text: "Atacant" },
];

const main_foot_dropdown = [
  { key: "RT", value: "Right", text: "Picior drept" },
  { key: "LT", value: "Left", text: "Picior stang" },
  { key: "BOTH", value: "Both", text: "Ambele" },
];

const frfListDropdownU21 = [
  { key: 1, value: "A", text: "Lista A" },
  { key: 2, value: "B", text: "Lista B" },
  { key: 3, value: "0", text: "Nicio Lista" },
];

const frfListDropdown = [
  { key: 1, value: "A", text: "Lista A" },
  { key: 2, value: "0", text: "Nicio Lista" },
];

const frfListNoDob = [{ key: 1, value: "0", text: "Nicio Lista" }];

class EditPlayerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countriesOption: countries,

      newName: this.props.name,
      newPosition: this.props.position,
      newSecondPosition: this.props.player_position_alternative,
      newMainFoot: this.props.player_main_foot,
      newDob: this.props.birthday,
      newNumber: this.props.number,

      newPlayerIdentityCard: this.props.cnp,
      newPlayerFifaId: this.props.fifa_id,
      newPlayerFrfList: this.props.frf_list,

      newPlayerHeight: this.props.player_height,
      newPlayerWeight: this.props.player_weight,

      new_legitimation_date: this.props.legitimation_date,
      new_enrolment_date: this.props.enrolment_date,

      hasProfessionalLicense: this.props.has_professional_license,

      loaned_out: this.props.loaned_out,
      loaned_until: this.props.loaned_until,
      loaned_team: this.props.loaned_team,

      isU21: true,
      isOnFrfList: false,

      newBirthCountry:
        this.props.birth_country.length > 0
          ? countries.find((item) => item.text == this.props.birth_country)
              .value
          : "",
      newSecondCountry:
        countries.find((item) => item.text == this.props.second_country) !=
        undefined
          ? countries.find((item) => item.text == this.props.second_country)
              .value
          : "",

      isWrongNumber: false,
      playerPhoneNumber: this.props.phone_number,
    };
  }

  componentDidMount() {
    let countries2 = JSON.parse(JSON.stringify(countries));
    countries2.unshift({
      key: "noCountry",
      value: "noCountry",
      flag: "noCountry",
      text: "No country",
    });
    this.setState({
      countriesOption: countries2,
    });
    this.isOnFrfList();
    this.isU21();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.newDob !== this.state.newDob) {
      this.setState({ newPlayerFrfList: "" });
      this.isOnFrfList();
      this.isU21();
    }
  }

  isOnFrfList = () => {
    if (
      this.state.newPlayerFrfList == "Nicio Lista" ||
      this.state.newPlayerFrfList == "" ||
      this.state.newPlayerFrfList == "0"
    ) {
      this.setState({ isOnFrfList: false });
    } else {
      this.setState({ isOnFrfList: true });
    }
  };

  isU21 = () => {
    if (this.state.newDob != null) {
      const year = "2001";
      let birthdayDate = new Date(this.state.newDob);
      let u21 = new Date("01-01-" + year);

      birthdayDate <= u21
        ? this.setState({ isU21: false })
        : this.setState({ isU21: true });
    }
  };

  listFilter = () => {
    if (this.state.isU21) {
      return frfListDropdownU21;
    } else {
      return frfListDropdown;
    }
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value }, () => {
      this.isOnFrfList();
    });
  };

  handleChangeDate = (value) => {
    this.setState({ newDob: value }, () => {
      this.isU21();
      this.setState({ newPlayerFrfList: "" });
    });
  };

  handleChangeDateLoaned = (value) => {
    this.setState({ loaned_until: value });
  };

  getCountryName = (value) => {
    if (value && value != "noCountry") {
      const selected_country = countries.find((item) => item.value === value);
      return selected_country.text;
    } else return "";
  };

  validatePhone = (fieldName, value) => {
    let isWrongNumberStatic = this.state.isWrongNumber;

    if (value) {
      switch (fieldName) {
        case "phoneNumber":
          if (value.indexOf(7) == 3) {
            isWrongNumberStatic = !isValidPhoneNumber(value);
          } else {
            isWrongNumberStatic = true;
          }

          break;

        default:
          break;
      }

      this.setState({ isWrongNumber: isWrongNumberStatic });
    } else {
      this.setState({ isWrongNumber: false });
    }
  };

  saveChanges = () => {
    const payload = {
      id_club: this.props.currentUser.id_club,
      player_name: this.state.newName,
      birth_country: this.getCountryName(this.state.newBirthCountry),
      second_country: this.getCountryName(this.state.newSecondCountry),
      player_dob: this.state.newDob,
      player_position: this.state.newPosition,
      shirt_number: this.state.newNumber,
      id_player: this.props.id_player,
      player_position_alternative: this.state.newSecondPosition,
      player_main_foot: this.state.newMainFoot,

      cnp: this.state.newPlayerIdentityCard,
      fifa_id: this.state.newPlayerFifaId,
      frf_list: this.state.newPlayerFrfList,
      has_professional_license: this.state.hasProfessionalLicense,

      loaned_out: this.state.loaned_out,
      loaned_until: this.state.loaned_until,
      loaned_team: this.state.loaned_team,

      height: this.state.newPlayerHeight,
      weight: this.state.newPlayerWeight,

      legitimation_date: this.state.new_legitimation_date,
      enrolment_date: this.state.new_enrolment_date,
      phone_number:
        this.state.playerPhoneNumber != ""
          ? this.state.playerPhoneNumber
          : null,
    };

    axios
      .post("club/edit_player_profile_2", payload)
      .then((res) => {
        if (res.data.success_phone_number == 1) {
          store.addNotification({
            title: "Profil jucator",
            message: "Modificarile au fost salvate cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
          this.props.refreshData();
          this.props.closePopup();
        } else if (
          res.data.success_phone_number == 0 &&
          res.data.error_code == "used_phone_number_by_another_user"
        ) {
          store.addNotification({
            title: "Profil jucator",
            message:
              "Numărul de telefon este există deja pentru alt utilizator !",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else if (
          res.data.success_phone_number == 0 &&
          res.data.error_code == "used_phone_number_by_another_player"
        ) {
          store.addNotification({
            title: "Profil jucator",
            message: "Numărul de telefon este există deja pentru alt jucător !",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: "Profil jucator",
            message: "Modificarile nu au fost salvate !",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="edit-player-details-container">
        <div className="edit-details-header">
          <div className="edit-details-title">
            {languagePack["edit_details"]}
          </div>
          <Icon
            name="close"
            size="large"
            onClick={() => this.props.closePopup()}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="edit-details-row">
          <input
            placeholder={languagePack.tax_redirector_name}
            value={this.state.newName}
            onChange={(e) => this.setState({ newName: e.target.value })}
            type="text"
            className="edit-player-details-input"
          />
        </div>
        <div className="edit-details-row">
          <Dropdown
            placeholder={languagePack["POSITION"]}
            options={positions_dropdown}
            value={this.state.newPosition}
            selection
            name="newPosition"
            className="edit-player-details-dropdown"
            onChange={this.handleChangeDropdown}
          />

          <Dropdown
            placeholder={languagePack["SECONDARY_POSITION"]}
            options={positions_dropdown}
            value={this.state.newSecondPosition}
            selection
            name="newSecondPosition"
            className="edit-player-details-dropdown min-width-overwrite"
            onChange={this.handleChangeDropdown}
          />
        </div>
        <div className="edit-details-row">
          <Dropdown
            placeholder={languagePack["MAIN_FOOT"]}
            options={main_foot_dropdown}
            value={this.state.newMainFoot}
            selection
            name="newMainFoot"
            className="edit-player-details-dropdown min-width-overwrite"
            onChange={this.handleChangeDropdown}
          />
          <Dropdown
            placeholder={languagePack["NATIONALITY"]}
            options={countries}
            value={this.state.newBirthCountry}
            selection
            name="newBirthCountry"
            className="edit-player-details-dropdown min-width-overwrite"
            onChange={this.handleChangeDropdown}
          />
        </div>

        <div className="edit-details-row">
          <Dropdown
            placeholder={languagePack["NATIONALITY"]}
            options={this.state.countriesOption}
            value={this.state.newSecondCountry}
            selection
            name="newSecondCountry"
            className="edit-player-details-dropdown min-width-overwrite"
            onChange={this.handleChangeDropdown}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              value={this.state.newDob}
              onChange={this.handleChangeDate}
              emptyLabel={languagePack["birth_date"]}
              format="dd MMMM yyyy"
              className="edit-player-details-date width48"
            />
            <Icon
              name="calendar alternate outline"
              className="calendar-icon"
              size="small"
            />
          </MuiPickersUtilsProvider>
        </div>

        <div className="edit-details-row">
          <input
            value={this.state.newNumber}
            onChange={(e) => this.setState({ newNumber: e.target.value })}
            type="number"
            className="edit-player-details-input"
            placeholder={languagePack["shirt_number"]}
            style={{ width: "48%" }}
            min="0"
          />
          <Dropdown
            placeholder={"Lista FRF"}
            options={
              this.state.newDob == null ? frfListNoDob : this.listFilter()
            }
            value={this.state.newPlayerFrfList}
            selection
            name="newPlayerFrfList"
            className="add-player-dropdown add-player-add-frf-list edit-player-details-dropdown"
            onChange={this.handleChangeDropdown}
          />
        </div>

        <div className="edit-details-row">
          <input
            value={this.state.newPlayerIdentityCard}
            type="number"
            className="edit-player-details-input width48"
            onChange={(e) => {
              this.setState({
                newPlayerIdentityCard: e.target.value,
              });
            }}
            placeholder={languagePack.identity_card_id}
            min="0"
          />
          <input
            className="width48"
            value={this.state.newPlayerFifaId}
            // type="number"
            onChange={(e) => {
              this.setState({
                newPlayerFifaId: e.target.value,
              });
            }}
            placeholder={languagePack.fifa_id}
            // min="0"
          />
        </div>

        <div className="edit-details-row">
          <input
            value={this.state.newPlayerHeight}
            type="number"
            className="edit-player-details-input width48"
            onChange={(e) => {
              this.setState({
                newPlayerHeight: e.target.value,
              });
            }}
            placeholder={languagePack.height}
            min="0"
          />
          <input
            className="width48"
            inputmode="decimal"
            value={this.state.newPlayerWeight}
            type="number"
            onChange={(e) => {
              this.setState({
                newPlayerWeight: e.target.value,
              });
            }}
            placeholder={languagePack.weight}
            min="0"
          />
        </div>

        <div className="edit-details-row-ds" style={{ marginBottom: "25px" }}>
          <div style={{ width: "48%" }}>
            <TFBDatePicker
              value={this.state.new_legitimation_date}
              onChange={(value) =>
                this.setState({ new_legitimation_date: value })
              }
              placeholder={languagePack.legitimation_date}
              label={languagePack.legitimation_date}
              format="dd.MM.yyyy"
              style={{ width: "100%" }}
            />
          </div>

          <div style={{ width: "48%" }}>
            <TFBDatePicker
              value={this.state.new_enrolment_date}
              onChange={(value) => this.setState({ new_enrolment_date: value })}
              placeholder={languagePack.enrolment_date}
              label={languagePack.enrolment_date}
              format="dd.MM.yyyy"
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div className="edit-details-row-ds" style={{ marginTop: "20px" }}>
          <TFBPhoneInput
            initialValueFormat="national"
            defaultCountry="RO"
            countries={["RO"]}
            label={languagePack.tax_redirector_phone_number}
            placeholder={"Ex: 0712 345 678"}
            value={this.state.playerPhoneNumber}
            className="edit-player-phone-input"
            onChange={(e) => {
              this.setState({ playerPhoneNumber: e ?? "" });
              this.validatePhone("phoneNumber", e);
            }}
          />
        </div>

        {this.state.isWrongNumber &&
          this.state.playerPhoneNumber &&
          this.state.playerPhoneNumber.length > 0 && (
            <div className="text-danger">
              {languagePack.incorect_phone_number}
            </div>
          )}

        <div className="add-player-professional-license">
          <Checkbox
            label={languagePack.has_professional_license_issued}
            checked={this.state.hasProfessionalLicense == 0 ? false : true}
            onChange={(e, data) => {
              this.setState({
                hasProfessionalLicense: data.checked ? 1 : 0,
              });
            }}
          />
        </div>

        <div className="add-player-professional-license">
          <Checkbox
            label={languagePack.loaned}
            checked={this.state.loaned_out == 0 ? false : true}
            onChange={(e, data) => {
              this.setState({
                loaned_out: data.checked ? 1 : 0,
              });
              if (!data.checked) {
                this.setState({
                  loaned_team: "",
                  loaned_until: null,
                });
              }
            }}
          />
        </div>

        {this.state.loaned_out == "1" && (
          <div
            className="add-player-professional-license edit-details-row"
            style={{ display: "flex" }}
          >
            <input
              value={this.state.loaned_team}
              type="text"
              className="edit-player-details-input width48"
              style={{ height: "50px" }}
              onChange={(e) => {
                this.setState({
                  loaned_team: e.target.value,
                });
              }}
              placeholder={languagePack.on_loan}
            />

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={this.state.loaned_until}
                onChange={this.handleChangeDateLoaned}
                emptyLabel={languagePack.until}
                format="dd MMMM yyyy"
                className="edit-player-details-date width48"
              />
              <Icon
                name="calendar alternate outline"
                className="calendar-icon"
                size="small"
              />
            </MuiPickersUtilsProvider>
          </div>
        )}

        <div className="edit-details-row edit-details-row-buttons">
          <Button
            className="edit-details-button"
            style={{ backgroundColor: "#666666" }}
            onClick={() => this.props.closePopup()}
          >
            {languagePack["admin_cancel"]}
            <Icon name="ban" size="small" className="edit-details-icon" />
          </Button>
          <Button
            className="edit-details-button"
            onClick={this.saveChanges}
            disabled={this.state.isWrongNumber}
            style={{ backgroundColor: window.clubCustomization.primary_color }}
          >
            {languagePack["admin_save"]}
            <Icon name="check" size="small" className="edit-details-icon" />
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(EditPlayerDetails);
