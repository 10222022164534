import React from "react";
import { Icon, TextArea, Button } from "semantic-ui-react";
import { store } from "react-notifications-component";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import delete_icon from "../../../../assets/icons/delete.png";
import moment from "moment";

import "./StaffMembers.scss";

const licenseType = [
  {
    id: "0",
    license: "Nicio licență",
  },
  {
    id: "1",
    license: "UEFA B",
  },
  {
    id: "2",
    license: "UEFA A",
  },
  {
    id: "3",
    license: "UEFA A Youth",
  },
  {
    id: "4",
    license: "UEFA A GK",
  },
  {
    id: "5",
    license: "UEFA B GK",
  },
  {
    id: "6",
    license: "UEFA Pro",
  },
  {
    id: "7",
    license: "NON-UEFA",
  },
  {
    id: "8",
    license: "UEFA C",
  },
  {
    id: "9",
    license: "Carnet de antrenor",
  },
];

class EditStaffDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newName: this.props.item.full_name,
      newDateExpireLicense: this.props.item.date_expire_license,
      newLicense: this.props.item.license,
      newDescription: this.props.item.description,
      newImage: "",
      roleOptions: [],
      licenseOption: licenseType,
      newRole: this.props.item.user_role,
      wantToDeleteStaff: "",
    };
  }

  handleChangeDate = (value) => {
    this.setState({ newDateExpireLicense: value });
  };

  getRoles = () => {
    axios
      .get(`club/get_roles?id_club=${this.props.club}`)
      .then((res) => {
        this.setState({ roleOptions: Array.isArray(res.data) ? res.data : [] });
      })
      .catch((err) => console.log(err));
  };

  handleChangeImage = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState({
        fileSection: file,
        newImage: reader.result,
      });
    };
  };

  saveChanges = () => {
    const data = new FormData();
    data.append("id_staff", this.props.item.id_user);
    data.append("name", this.state.newName);
    data.append("role", this.state.newRole);
    data.append("image", this.state.newImage);
    data.append("description", this.state.newDescription);
    data.append("license", this.state.newLicense);
    data.append(
      "date_expire_license",
      this.state.newDateExpireLicense != null &&
        this.state.newDateExpireLicense != "Invalid date"
        ? moment(this.state.newDateExpireLicense).format("YYYY-MM-DD")
        : null
    );

    axios
      .post("club/edit_staff_member", data)
      .then(() => {
        store.addNotification({
          title: this.props.languagePack.edit_staff_member_popup_title,
          message: this.props.languagePack.edit_staff_member_popup_message,
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });

        this.props.refreshData();
        this.props.closePopup();
      })
      .catch((err) => console.log(err));
  };

  deleteStaffMember = () => {
    const payload = {
      id_staff_member: this.state.wantToDeleteStaff,
    };

    axios
      .post("club/delete_staff_member", payload)
      .then(() => {
        this.setState({ wantToDeleteStaff: "" });
        store.addNotification({
          title: this.props.languagePack.edit_staff_member_popup_title,
          message: this.props.languagePack.delete_staff_member_message,
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        // window.location.reload();
        this.props.refreshData();
        this.props.closePopup();
      })
      .catch((err) => console.log(err));
  };

  componentDidMount = () => {
    this.getRoles();
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="edit-staff-deteails-container">
        <div className="edit-staff-header">
          <div className="edit-staff-title">
            {languagePack["edit_profile"]}
            <Icon
              name="trash"
              size="small"
              onClick={() =>
                this.setState({ wantToDeleteStaff: this.props.item.id_user })
              }
            />
          </div>
          <Icon
            name="close"
            size="large"
            onClick={() => this.props.closePopup()}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="edit-staff-row">
          <input
            type="text"
            value={this.state.newName}
            onChange={(e) => this.setState({ newName: e.target.value })}
            placeholder={languagePack["staff_full_name"]}
            className="edit-staff-input"
          />
        </div>

        <div
          className="edit-staff-row"
          style={{ justifyContent: "space-between", paddingRight: 20 }}
        >
          <input
            type="file"
            className="edit-details-file-input"
            id="customFile"
            onChange={this.handleChangeImage}
          />
          <label className="edit-details-file-label" htmlFor="customFile">
            {this.state.fileSection
              ? this.state.fileSection.name
              : languagePack["upload_image"]}
          </label>
          <Icon name="arrow up" size="small" />
        </div>

        <div className="edit-staff-row">
          <select
            value={this.state.newRole}
            onChange={(e) => {
              this.setState({ newRole: e.target.value });
            }}
          >
            <option value="" disabled selected>
              {languagePack["staff_member_role"]}
            </option>
            {this.state.roleOptions.map((item) => {
              return (
                <option value={item.value} key={item.key}>
                  {item.text}
                </option>
              );
            })}
          </select>
        </div>

        <div className="edit-staff-row">
          <select
            value={this.state.newLicense}
            onChange={(e) => {
              this.setState({
                newLicense: e.target.value,
                newDateExpireLicense: null,
              });
            }}
          >
            <option value="" disabled selected>
              {languagePack.select_license}
            </option>
            {this.state.licenseOption.map((item) => {
              return (
                <option value={item.license} key={item.id}>
                  {item.license}
                </option>
              );
            })}
          </select>
        </div>

        {/* {this.state.newLicense != "NON-UEFA" && */}
        {this.state.newLicense != "Nicio licență" &&
        this.state.newLicense != null &&
        this.state.newLicense != "" ? (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="edit-staff-row-date">
              <DatePicker
                value={this.state.newDateExpireLicense}
                onChange={this.handleChangeDate}
                emptyLabel={"Dată expirare licență"}
                format="dd MMMM yyyy"
                className="edit-player-date-input"
              />
              <Icon
                name="calendar alternate outline"
                className="calendar-icon"
                size="small"
              />
            </div>
          </MuiPickersUtilsProvider>
        ) : (
          false
        )}

        <TextArea
          className="edit-staff-text-area"
          value={this.state.newDescription}
          onChange={(e) => this.setState({ newDescription: e.target.value })}
          placeholder={languagePack["staff_description"]}
        />
        <div className="edit-staff-row">
          <Button
            className="edit-details-button"
            style={{ backgroundColor: "#666666" }}
            onClick={() => this.props.closePopup()}
          >
            {languagePack["admin_cancel"]}
            <Icon name="ban" size="small" className="edit-details-icon" />
          </Button>
          <Button
            className="edit-details-button"
            style={{ backgroundColor: "#00d948" }}
            onClick={this.saveChanges}
          >
            {languagePack["admin_save"]}
            <Icon name="check" size="small" className="edit-details-icon" />
          </Button>
        </div>
        <Dialog
          open={this.state.wantToDeleteStaff !== ""}
          // onClose={() => this.setState({ wantToDeleteStaff: "" })}
        >
          <DialogContent className="news-options-delete-popup-container">
            <div className="news-options-delete-popup">
              <div className="header">
                {languagePack["news_page_delete_popup_title"]}
                <Icon
                  name="close"
                  onClick={() => this.setState({ wantToDeleteStaff: "" })}
                />
              </div>
              <div className="delete-message">
                {languagePack["news_page_delete_popup_text"]}
              </div>
              <div className="delete-buttons">
                <Button
                  className="news-options-button dark-background"
                  onClick={() => this.setState({ wantToDeleteStaff: "" })}
                >
                  {languagePack["admin_cancel"]}
                  <Icon name="close" className="news-options-button-icon" />
                </Button>
                <Button
                  className="news-options-button"
                  onClick={this.deleteStaffMember}
                >
                  {languagePack["admin_delete"]}
                  <Icon
                    name="trash alternate"
                    className="news-options-button-icon"
                  />
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default EditStaffDetails;
