import React from "react";
import axios from "axios";
import { Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "../../../utils/api";
import "./TrainingSections.scss";
import { store } from "react-notifications-component";

class EditTainingSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      section_name: this.props.section_to_update.section_name,
    };
  }

  editSection = () => {
    const payload = {
      section_name: this.state.section_name,
      id_section_to_update: this.props.section_to_update.id,
    };

    axios
      .post("training/edit_training_section", payload)
      .then((res) => {
        if (res.data == 1) {
          this.props.getTrainingSections();
          store.addNotification({
            title: "Secțiuni antrenamente",
            message: "Secțiunea a fost editată cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: "Secțiuni antrenamente",
            message: "Secțiunea a fost editată cu succes!",
            type: "warning",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        this.props.closePopup();
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="add-section-training-form">
        <div className="add-section-training-header">
          <div className="add-section-training-title">
            {languagePack.edit_section}
          </div>
          <div className="add-section-training-popup-close">
            {" "}
            <Icon
              name="close"
              className="close-icon-popup"
              onClick={() => this.props.closePopup()}
            />
          </div>
        </div>
        <div className="add-section-training-row">
          <input
            className="add-video-title"
            placeholder={languagePack.designation}
            value={this.state.section_name}
            onChange={(event) => {
              this.setState({ section_name: event.target.value });
            }}
          />
        </div>

        <div className="add-section-training-buttons">
          <Button
            className="custom-button-dual add-button"
            onClick={this.editSection}
            disabled={
              this.state.section_name == "" ||
              this.state.section_name == undefined ||
              this.state.section_name == null
            }
          >
            {" "}
            <div className="button-content">
              {languagePack.injury_edit}
              <Icon name="add" size="small" className="add-icon" />
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}

export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(EditTainingSection);
