import React from "react";
import {
  Card,
  Icon,
  Flag,
  Dropdown,
  Button,
  Checkbox,
} from "semantic-ui-react";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import ContractWidget from "./ContractWidget";
import BasicSeasonStats from "../../../components/data-providers/instat/player/BasicSeasonStats";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import EditPlayerDetails from "./EditPlayerDetails";
import { store } from "react-notifications-component";
import PlayerTransactions from "./PlayerTransactions";
import PlayerVideoHighlightsWidget from "./PlayerVideoHighlightsWidget";
import TrainingStatsWidget from "./TrainingStatsWidget";
import MedicalPlayerProfile from "../../Medical/MedicalPlayerProfile";
import ExpandedImage from "../../SiteAdmin/Photos/ExpandedImage";
import PaymentsToClub from "../../Financial/PaymentsToClub/PaymentsToClub";
import { Redirect } from "react-router-dom";
import NationalTeamCallUpWidget from "./NationalTeamCallUpWidget";
import BasicLastMatchesStats from "../../../components/data-providers/instat/player/BasicLastMatchesStats";
import TransferHistory from "./TransferHistory";
import DocumentsWidget from "./DocumentsWidget";
import PlayerRadarChart from "../../../components/data-providers/instat/player/PlayerRadarChart";
import Select from "react-select";
import { setURLParam } from "../../../utils/utilFunctions";
import Loader from "react-loader-spinner";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";

class PlayerProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicInfo: [],
      phys_report_category: "1",
      selected_test_id: "",
      past_tests: [],
      injury_dialog_opened: false,
      addedImage: "",

      seasons: [],
      current_season: "",
      selected_season: new URLSearchParams(this.props.location.search).get(
        "id_season"
      ),
      season_test: null,

      editDetailsPopup: false,
      deletePlayerPopup: false,
      movePlayerPopup: false,
      moveTeamNew: "",

      expandedImage: false,
      expandedImageSrc: "",

      show_payments_to_club: true,
      hasAccessToPlayer: false,
      redirectToTeams: false,

      club_teams: [],
      selected_new_team: null,
      keep_in_previous_teams: false,
      player_teams: [],
      selected_player_teams: [],

      delete_player_from_team_popup: false,
      delete_from_team: null,

      isLoading: true,
    };
  }
  dropdownRef = React.createRef();
  imgInput = React.createRef();

  componentDidMount = () => {
    this.hasAccessToPlayer();
  };

  hasAccessToPlayer = () => {
    axios
      .get(
        `user/has_access_to_player?id_player=${this.props.match.params.id}&id_user=${this.props.currentUser.id_user}`
      )
      .then((res) => {
        if (res.data == 1) {
          this.setState(
            {
              hasAccessToPlayer: true,
            },
            this.getSeasons
          );
        } else {
          this.setState({
            redirectToTeams: true,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getSeasons = () => {
    axios
      .get(
        `club/get_seasons_for_player?id_player=${this.props.match.params.id}&id_season=${this.state.selected_season}`
      )
      .then((res) => {
        const param_id_season = new URLSearchParams(
          this.props.location.search
        ).get("id_season");
        this.setState(
          {
            seasons: res.data,
            current_season: res.data[0].value,
            selected_season:
              param_id_season != null && param_id_season != undefined
                ? param_id_season
                : res?.data[0]?.value,
          },
          () => {
            this.getBasicInfo();
            this.getPastTests();
          }
        );
      })
      .catch((err) => console.log(err));
  };

  getBasicInfo = () => {
    this.setState({ isLoading: true }, () => {
      axios
        .get(
          `player/get_basic_info?id_player=${this.props.match.params.id}&id_season=${this.state.selected_season}`
        )
        .then((res) =>
          this.setState({
            basicInfo: res.data.basic_info,
            club_teams: res.data.club_teams,
            player_teams: res.data.player_teams,
            isLoading: false,
            addedImage: "",
          })
        )
        .catch((err) => console.log(err));
    });
  };

  getPastTests = () => {
    axios
      .get(`player/get_player_tests?id_player=${this.props.match.params.id}`)
      .then((res) => this.setState({ past_tests: res.data }))
      .catch((err) => console.log(err));
  };

  onChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value }, () => {
      if (value.name == "selected_season") {
        this.getBasicInfo();
        setURLParam("id_season", value.value);
      }
    });
  };

  handleItemClick = (_e, x) => {
    this.dropdownRef.current.handleItemClick(_e, x);
  };

  isAllowedFinancial = () => {
    return this.props.currentUser.user_permissions.FINANCIAL;
  };

  handleChangeImage = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState({
        fileSection: file,
        addedImage: reader.result,
      });
      this.imgInput.current.value = "";
    };
  };

  addDefaultSrc = (event) => {
    event.target.src = `https://api.thefootballbrain.app/uploads/players/placeholder.png`;
  };

  addImage = () => {
    const data = new FormData();
    data.append("image", this.state.addedImage);
    data.append("id_player", this.props.match.params.id);

    axios
      .post("player/add_player_image", data)
      .then(() => {
        this.getBasicInfo();
        store.addNotification({
          title: "Profil jucator",
          message: "Imaginea a fost incarcata cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  changeImage = () => {
    const data = new FormData();
    data.append("image", this.state.addedImage);
    data.append("id_player", this.props.match.params.id);

    axios
      .post("player/change_player_image", data)
      .then(() => {
        this.getBasicInfo();
        store.addNotification({
          title: "Profil jucator",
          message: "Imaginea a fost modificata cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  deletePlayer = () => {
    const id = this.props.match.params.id;
    axios.post(`player/delete_player?id_player=${id}`).then(() => {
      store.addNotification({
        title: "Profil jucator",
        message: "Jucatorul a fost eliminat cu succes!",
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
      this.props.history.goBack();
    });
  };

  movePlayer = () => {
    const payload = {
      id_player: this.props.match.params.id,
      id_new_team: this.state.selected_new_team.value,
      keep_in_previous_teams: this.state.keep_in_previous_teams,
      previous_teams: this.state.selected_player_teams,
      id_season: this.state.selected_season,
    };
    axios.post("player/move_player_to_another_team", payload).then((res) => {
      if (res.data == 1) {
        this.getBasicInfo();
        store.addNotification({
          title: "Profil jucator",
          message: "Echipa jucatorului a fost schimbata cu succes",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      } else {
        store.addNotification({
          title: "Profil jucator",
          message: "Echipa jucatorului nu a fost schimbata cu succes",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      }
      this.closeMovePlayerPopup();
    });
  };

  closeMovePlayerPopup = () => {
    this.setState({
      movePlayerPopup: false,
      selected_new_team: null,
      keep_in_previous_teams: false,
      selected_player_teams: this.state.player_teams,
    });
  };

  deletePlayerFromTeam = () => {
    const payload = {
      id_player: this.props.match.params.id,
      id_team: this.state.delete_from_team.value,
      id_season: this.state.selected_season,
    };
    axios.post(`player/delete_player_from_team`, payload).then((res) => {
      if (res.data == 1) {
        this.getBasicInfo();
        store.addNotification({
          title: "Profil jucator",
          message: "Jucatorul a fost eliminat cu succes din echipa!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      } else {
        store.addNotification({
          title: "Profil jucator",
          message: "Jucatorul nu a fost eliminat cu succes din echipa!",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      }
      this.closeDeletePlayerFromTeam();
    });
  };

  closeDeletePlayerFromTeam = () => {
    this.setState({
      delete_player_from_team_popup: false,
      delete_from_team: null,
    });
  };

  render() {
    if (this.state.redirectToTeams) {
      return <Redirect to="/club" />;
    }

    const id_player = this.props.match.params.id;
    const { languagePack } = this.props;
    const financialAccess = this.isAllowedFinancial();

    return (
      <>
        <div
          className="row dropdown-season-select"
          style={{
            justifyContent:
              this.state.basicInfo.inactive == 1 ? "space-between" : "flex-end",
          }}
        >
          {this.state.basicInfo.inactive == 1 && (
            <div className="inactive-container">
              <div className="inactive-text">
                {"Inactiv de la " +
                  moment(this.state.basicInfo.inactive_from_date).format(
                    "DD.MM.YYYY"
                  )}
                {this.state.basicInfo.inactive_reason != "" &&
                  `, ${this.state.basicInfo.inactive_reason}`}
              </div>
            </div>
          )}
          <Dropdown
            placeholder="Select season"
            selection
            value={this.state.selected_season}
            options={this.state.seasons}
            className="profile-season-dropdown"
            onChange={this.onChangeDropdown}
            name="selected_season"
          />
        </div>
        {this.state.hasAccessToPlayer && !this.state.isLoading ? (
          <div>
            <div className="player-season-teams-history">
              {this.state.player_teams.map((elem, index) => {
                return (
                  <div
                    key={"player-team-" + index}
                    className="player-team-container"
                  >
                    <div className="player-team-name">{elem.label}</div>
                    <div className="deleted-player-team-icon">
                      {this.state.player_teams.length > 1 &&
                        this.state.current_season ==
                          this.state.selected_season && (
                          <Icon
                            name="close"
                            className="icon-close-color"
                            size="large"
                            onClick={() => {
                              this.setState({
                                delete_player_from_team_popup: true,
                                delete_from_team: elem,
                              });
                            }}
                          />
                        )}
                    </div>
                  </div>
                );
              })}
              {this.state.player_teams.length > 0 &&
                this.state.current_season == this.state.selected_season && (
                  <Button
                    className="add-player-to-team-btn green-background"
                    onClick={() => this.setState({ movePlayerPopup: true })}
                  >
                    <div className="add-player-to-team-btn-text">
                      {languagePack.add_team}
                    </div>
                    <Icon name="add" className="add-player-to-team-btn-icon" />
                  </Button>
                )}
            </div>
            <div className="row">
              <div className="col-lg-6 col-lg-padding no-vertical-padding">
                <div className="row">
                  <div className="col-lg-4 col-lg-padding player-info-widget no-padding-horizontal-mobile-991">
                    {/* Selected season mobile ??*/}

                    <Card style={{ position: "relative" }}>
                      <div
                        style={{
                          height: 310,
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        {this.state.addedImage === "" ? (
                          <img
                            onError={this.addDefaultSrc}
                            src={this.state.basicInfo.img_url}
                            className="player-profile-img"
                            alt=""
                          />
                        ) : (
                          <img
                            src={this.state.addedImage}
                            alt=""
                            className="player-profile-img"
                          />
                        )}{" "}
                        {this.state.basicInfo.has_image == 0 ? (
                          <>
                            {this.state.addedImage === "" ? (
                              <label htmlFor="profileImage">
                                <div
                                  className="player-profile-add-image-button"
                                  title={languagePack["edit_photo"]}
                                >
                                  <Icon
                                    name="add"
                                    size="small"
                                    className="add-image-icon"
                                  />
                                </div>{" "}
                              </label>
                            ) : (
                              <>
                                <div
                                  className="cancel-player-profile-add-image-button"
                                  title={"Renunta"}
                                  onClick={() =>
                                    this.setState({ addedImage: "" })
                                  }
                                >
                                  <Icon
                                    name="cancel"
                                    size="small"
                                    className="add-image-icon"
                                  />
                                </div>
                                <div
                                  className="player-profile-add-image-button"
                                  title={languagePack["edit_photo"]}
                                  onClick={this.addImage}
                                >
                                  <Icon
                                    name="check"
                                    size="small"
                                    className="add-image-icon"
                                  />
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {this.state.addedImage === "" ? (
                              <label htmlFor="profileImage">
                                <div
                                  className="player-profile-add-image-button"
                                  title={languagePack["edit_photo"]}
                                >
                                  <Icon
                                    name="pencil"
                                    size="small"
                                    className="add-image-icon"
                                  />
                                </div>
                              </label>
                            ) : (
                              <>
                                <div
                                  className="cancel-player-profile-add-image-button"
                                  title={"Renunta"}
                                  onClick={() =>
                                    this.setState({ addedImage: "" })
                                  }
                                >
                                  <Icon
                                    name="cancel"
                                    size="small"
                                    className="add-image-icon"
                                  />
                                </div>
                                <div
                                  className="player-profile-add-image-button"
                                  title={languagePack["edit_photo"]}
                                  onClick={this.addImage}
                                >
                                  <Icon
                                    name="check"
                                    size="small"
                                    className="add-image-icon"
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}
                        <input
                          hidden
                          id="profileImage"
                          type="file"
                          ref={this.imgInput}
                          onChange={this.handleChangeImage}
                        />
                      </div>
                      <Card.Content
                        className="player-profile-content"
                        style={{ paddingTop: 10, borderTop: "none" }}
                      >
                        <Card.Header>
                          <div className="profile-name">
                            {this.state.basicInfo.player_name}
                          </div>
                          <div className="profile-position">
                            {this.state.basicInfo.player_position}
                            {this.state.basicInfo.shirt_number > 0 && (
                              <>
                                {"(#"}
                                {this.state.basicInfo.shirt_number}
                                {")"}
                              </>
                            )}
                          </div>
                        </Card.Header>
                        <Card.Description>
                          <div className="basic-info-row">
                            <div className="basic-info-title">
                              {languagePack["birthplace"]}:
                            </div>
                            <div className="basic-info-value">
                              <Flag name={this.state.basicInfo.flag_name} />
                              {this.state.basicInfo.birth_country}
                            </div>
                          </div>
                          <div className="basic-info-row">
                            <div className="basic-info-title">
                              {languagePack["player_age"]}:
                            </div>
                            <div className="basic-info-value">
                              {this.state.basicInfo.birthday ? (
                                <>
                                  {moment().diff(
                                    this.state.basicInfo.birthday,
                                    "years"
                                  )}
                                  {languagePack["age_years"]}
                                </>
                              ) : (
                                "-"
                              )}{" "}
                            </div>
                          </div>
                          <div className="basic-info-row">
                            <div className="basic-info-title">
                              {languagePack["weight"]}:
                            </div>
                            <div className="basic-info-value">
                              {this.state.basicInfo.weight > 0 ? (
                                <>{this.state.basicInfo.weight} kg</>
                              ) : (
                                "-"
                              )}
                            </div>
                          </div>
                          <div
                            className="basic-info-row"
                            style={{ marginBottom: 30 }}
                          >
                            <div className="basic-info-title">
                              {languagePack["height"]}:
                            </div>
                            <div className="basic-info-value">
                              {this.state.basicInfo.height > 0 ? (
                                <>{this.state.basicInfo.height / 100} m</>
                              ) : (
                                "-"
                              )}
                            </div>
                          </div>
                          <div
                            className="edit-player-details-profile"
                            onClick={() =>
                              this.setState({ editDetailsPopup: true })
                            }
                            title={languagePack["edit_details"]}
                          >
                            <Icon
                              name="pencil"
                              size="small"
                              className="edit-details-icon"
                            />
                          </div>
                          <div
                            className="delete-player-details-profile"
                            onClick={() =>
                              this.setState({ deletePlayerPopup: true })
                            }
                            title={languagePack["delete_player"]}
                          >
                            <Icon
                              name="trash"
                              size="small"
                              className="edit-details-icon"
                            />
                          </div>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </div>
                  <div className="col-lg-8 col-lg-padding no-padding-horizontal-mobile-991">
                    <TFBCard>
                      <TFBCardBody>
                        <div
                          // className="card card-custom"
                          style={{ height: "100%" }}
                        >
                          <ContractWidget
                            languagePack={this.props.languagePack}
                            id_player={this.props.match.params.id}
                            id_season={this.state.selected_season}
                            hasFinancialAccess={
                              financialAccess ||
                              !typeof financialAccess == "undefined"
                            }
                            basicInfo={this.state.basicInfo}
                          />
                        </div>
                      </TFBCardBody>
                    </TFBCard>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-lg-padding no-padding-horizontal-mobile-991">
                    <TransferHistory
                      id_player={id_player}
                      languagePack={languagePack}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-lg-padding">
                <div
                  className="col-lg-12 no-horizontal-padding"
                  style={{ height: "100%" }}
                >
                  <div className="card card-custom" style={{ height: "100%" }}>
                    <div className="profile-medical-title-container">
                      <div className="profile-medical-title">
                        <Icon name="doctor" size="large" color="black" />
                        <div>{"Medical"}</div>
                      </div>
                    </div>
                    {this.state.selected_season != null && (
                      <MedicalPlayerProfile
                        id_player={id_player}
                        id_season={this.state.selected_season}
                        setExpandImage={(param1, param2) => {
                          this.setState({
                            expandedImage: param1,
                            expandedImageSrc: param2,
                          });
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              {this.state.expandedImage && (
                <ExpandedImage
                  closeExpand={() => this.setState({ expandedImage: false })}
                  image={this.state.expandedImageSrc}
                />
              )}
            </div>
            <div className="row">
              <div className="col-lg-6 col-lg-padding">
                <PlayerTransactions
                  id_team={this.state.basicInfo.id_team}
                  currency={this.props.currentUser.currency}
                  id_club={this.props.currentUser.id_club}
                  id_player={this.props.match.params.id}
                  languagePack={languagePack}
                  has_access={
                    financialAccess || !typeof financialAccess == "undefined"
                  }
                />
              </div>
              {this.state.show_payments_to_club && (
                <div className="col-lg-6  col-lg-padding">
                  <div style={{ height: "100%" }}>
                    <PaymentsToClub
                      id_player={id_player}
                      id_season={this.state.selected_season}
                      languagePack={this.props.languagePack}
                      set_show_false={() => {
                        this.setState({
                          show_payments_to_club: false,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-lg-6 col-lg-padding">
                <BasicLastMatchesStats
                  id_player={id_player}
                  id_season={this.state.selected_season}
                  languagePack={this.props.languagePack}
                />
              </div>
              <div className="col-lg-6 col-lg-padding">
                <div className="card card-custom">
                  <BasicSeasonStats
                    id_player={id_player}
                    id_season={this.state.selected_season}
                    languagePack={this.props.languagePack}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-lg-padding">
                <div className="card card-custom">
                  <PlayerRadarChart
                    id_player={id_player}
                    id_club={this.props.currentUser.id_club}
                    id_season={this.state.selected_season}
                    languagePack={this.props.languagePack}
                    player_profile={this.state.basicInfo}
                    token={this.props.currentUser.token}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-lg-padding">
                <div className="card card-custom">
                  <NationalTeamCallUpWidget
                    id_player={this.props.match.params.id}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-lg-padding">
                <div className="card card-custom">
                  <DocumentsWidget id_player={this.props.match.params.id} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-lg-padding">
                <div className="card card-custom">
                  <PlayerVideoHighlightsWidget
                    id_player={this.props.match.params.id}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-lg-padding">
                <div className="card card-custom">
                  <TrainingStatsWidget id_player={this.props.match.params.id} />
                </div>
              </div>
            </div>

            {/* EDIT PLAYER */}
            <Dialog open={this.state.editDetailsPopup} maxWidth="xl">
              <DialogContent>
                <EditPlayerDetails
                  closePopup={() => this.setState({ editDetailsPopup: false })}
                  name={this.state.basicInfo.player_name}
                  position={this.state.basicInfo.player_position}
                  player_position_alternative={
                    this.state.basicInfo.player_position_alternative
                  }
                  player_main_foot={this.state.basicInfo.player_main_foot}
                  birthday={this.state.basicInfo.birthday}
                  birth_country={this.state.basicInfo.birth_country}
                  second_country={this.state.basicInfo.second_country}
                  number={
                    this.state.basicInfo.shirt_number > 0
                      ? this.state.basicInfo.shirt_number
                      : ""
                  }
                  id_player={this.props.match.params.id}
                  refreshData={this.getBasicInfo}
                  cnp={this.state.basicInfo.cnp}
                  fifa_id={this.state.basicInfo.fifa_id}
                  frf_list={this.state.basicInfo.frf_list}
                  loaned_out={this.state.basicInfo.loaned_out}
                  loaned_until={this.state.basicInfo.loaned_until}
                  loaned_team={this.state.basicInfo.loaned_team}
                  has_professional_license={
                    this.state.basicInfo.has_professional_license
                  }
                  player_height={this.state.basicInfo.height}
                  player_weight={this.state.basicInfo.weight}
                  legitimation_date={this.state.basicInfo.legitimation_date}
                  enrolment_date={this.state.basicInfo.enrolment_date}
                  phone_number={this.state.basicInfo.phone_number}
                />
              </DialogContent>
            </Dialog>

            {/* DELETE PLAYER */}
            <Dialog open={this.state.deletePlayerPopup} maxWidth="xl">
              <DialogContent>
                <div className="delete-player-popup-container">
                  <div className="delete-player-header">
                    <div className="delete-player-title">
                      {languagePack["delete_player"]}
                    </div>
                    <Icon
                      name="close"
                      size="large"
                      onClick={() =>
                        this.setState({ deletePlayerPopup: false })
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="delete-player-text">
                    {languagePack["delete_player_popup_text"]}
                  </div>
                  <div className="delete-player-buttons">
                    <Button
                      className="delete-player-button"
                      onClick={() =>
                        this.setState({ deletePlayerPopup: false })
                      }
                      style={{ backgroundColor: "#666666" }}
                    >
                      {languagePack["admin_cancel"]}
                      <Icon
                        name="ban"
                        size="small"
                        className="delete-player-icon"
                      />
                    </Button>
                    <Button
                      className="delete-player-button"
                      style={{ backgroundColor: "#00d948" }}
                      onClick={this.deletePlayer}
                    >
                      {languagePack["admin_delete"]}
                      <Icon
                        name="trash"
                        size="small"
                        className="delete-player-icon"
                      />
                    </Button>
                  </div>
                </div>
              </DialogContent>
            </Dialog>

            {/* ADD PLAYER TO ANOTHER TEAM */}
            <Dialog open={this.state.movePlayerPopup} fullWidth maxWidth="sm">
              <DialogContent>
                <div className="delete-player-popup-container">
                  <div className="delete-player-header">
                    <div className="delete-player-title">
                      {languagePack.add_player_to_another_team}
                    </div>
                    <Icon
                      name="close"
                      size="large"
                      onClick={this.closeMovePlayerPopup}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="move-player-row">
                    <Select
                      placeholder={languagePack["select_new_team"]}
                      value={this.state.selected_new_team}
                      options={this.state.club_teams}
                      onChange={(selected) =>
                        this.setState({
                          selected_new_team: selected,
                        })
                      }
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (provided, state) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                      }}
                    />
                  </div>
                  <div className="move-player-row">
                    <div className="add-player-professional-license no-padding">
                      <Checkbox
                        label={languagePack.keep_it_in_previous_teams}
                        checked={this.state.keep_in_previous_teams}
                        onChange={(e, data) => {
                          this.setState({
                            keep_in_previous_teams: data.checked,
                            selected_player_teams: this.state.player_teams,
                          });
                        }}
                      />
                    </div>
                    {this.state.keep_in_previous_teams && (
                      <div className="">
                        <Select
                          value={this.state.selected_player_teams}
                          options={this.state.player_teams}
                          onChange={(selected) =>
                            this.setState({
                              selected_player_teams: selected,
                              keep_in_previous_teams:
                                selected == null ? false : true,
                            })
                          }
                          isMulti
                          isClearable
                          isSearchable
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (provided, state) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="delete-player-buttons">
                    <Button
                      className="delete-player-button"
                      onClick={this.closeMovePlayerPopup}
                      style={{ backgroundColor: "#666666" }}
                    >
                      {languagePack["admin_cancel"]}
                      <Icon
                        name="ban"
                        size="small"
                        className="delete-player-icon"
                      />
                    </Button>
                    <Button
                      className="delete-player-button"
                      style={{ backgroundColor: "#00d948" }}
                      onClick={this.movePlayer}
                      disabled={
                        this.state.selected_new_team == null
                          ? true
                          : this.state.keep_in_previous_teams
                          ? this.state.selected_player_teams.length == 0
                          : false
                      }
                    >
                      {languagePack["admin_save"]}
                      <Icon
                        name="check"
                        size="small"
                        className="delete-player-icon"
                      />
                    </Button>
                  </div>
                </div>
              </DialogContent>
            </Dialog>

            {/* DELETE PLAYER FROM TEAM */}
            <Dialog
              open={this.state.delete_player_from_team_popup}
              maxWidth="xl"
            >
              <DialogContent>
                <div className="delete-player-popup-container">
                  <div className="delete-player-header">
                    <div className="delete-player-title">
                      {"Sterge jucator din echipa"}
                    </div>
                    <Icon
                      name="close"
                      size="large"
                      onClick={this.closeDeletePlayerFromTeam}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="delete-player-text">
                    {`Esti sigur ca vrei sa elimini acest jucator din echipa "${this.state.delete_from_team?.label}"? Actiunea este ireversibila.`}
                  </div>
                  <div className="delete-player-buttons">
                    <Button
                      className="delete-player-button"
                      onClick={this.closeDeletePlayerFromTeam}
                      style={{ backgroundColor: "#666666" }}
                    >
                      {languagePack["admin_cancel"]}
                      <Icon
                        name="ban"
                        size="small"
                        className="delete-player-icon"
                      />
                    </Button>
                    <Button
                      className="delete-player-button"
                      style={{ backgroundColor: "#00d948" }}
                      onClick={this.deletePlayerFromTeam}
                    >
                      {languagePack["admin_delete"]}
                      <Icon
                        name="trash"
                        size="small"
                        className="delete-player-icon"
                      />
                    </Button>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Loader
              type="TailSpin"
              color={window.clubCustomization.primary_color}
              height={50}
              width={50}
            />
          </div>
        )}
      </>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  lang: layoutService.lang,
  currentUser: auth.user,
}))(PlayerProfile);
