import React from "react";
import "./PlayersCosts.scss";
import axios from "axios";
import Moment from "react-moment";
import { Icon } from "semantic-ui-react";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";

class WagesDetailed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      players: [],
      totals: [],
    };
  }

  componentDidMount = () => {
    axios
      .get(
        `club/players_detailed_wages?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) =>
        this.setState({
          players: res.data.player_data,
          totals: res.data.totals,
        })
      )
      .catch((err) => console.log(err));
  };

  render() {
    const current_year = new Date().getFullYear();
    return (
      <div className="card card-custom">
        <div className="wages-detailed-container">
          <div className="wages-detailed-title">Contracte jucatori</div>
          <div className="player-wage-row desktop-header">
            <div className="player-name" style={{ color: "#b2b2b2" }}>
              Nume jucator{" "}
            </div>
            <div className="player-table-top">Varsta</div>
            <div className="player-table-top">Data incheierii</div>
            <div className="player-table-top">
              <div className="title">
                Salariu ({current_year - 1}/{current_year})
              </div>
              <div className="total">
                <div>
                  Total:{" "}
                  {Math.round((this.state.totals[current_year] / 1000) * 10) /
                    10}
                  K
                </div>
                <Icon name="eur" className="detailed-wages-eur-icon" />
              </div>
            </div>
            <div className="player-table-top">
              <div className="title">
                Salariu ({current_year}/{current_year + 1})
              </div>
              <div className="total">
                <div>
                  Total:{" "}
                  {Math.round(
                    (this.state.totals[current_year + 1] / 1000) * 10
                  ) / 10}
                  K
                </div>
                <Icon name="eur" className="detailed-wages-eur-icon" />
              </div>
            </div>
            <div className="player-table-top">
              <div className="title">
                Salariu ({current_year + 1}/{current_year + 2})
              </div>
              <div className="total">
                <div>
                  Total:{" "}
                  {Math.round(
                    (this.state.totals[current_year + 2] / 1000) * 10
                  ) / 10}
                  K
                </div>
                <Icon name="eur" className="detailed-wages-eur-icon" />
              </div>
            </div>
            <div className="player-table-top">
              <div className="title">
                Salariu ({current_year + 2}/{current_year + 3})
              </div>
              <div className="total">
                <div>
                  Total:{" "}
                  {Math.round(
                    (this.state.totals[current_year + 3] / 1000) * 10
                  ) / 10}
                  K
                </div>
                <Icon name="eur" className="detailed-wages-eur-icon" />
              </div>
            </div>
            {/* <div className="player-table-top">
              <div className="title">
                Salariu ({current_year + 3}/{current_year + 4})
              </div>
              <div className="total">
                <div>
                  Total:{" "}
                  {Math.round(
                    (this.state.totals[current_year + 4] / 1000) * 10
                  ) / 10}
                  K
                </div>
                <Icon name="eur" className="detailed-wages-eur-icon" />
              </div>
            </div> */}
          </div>
          <div className="players-list">
            {this.state.players.map((item, index) => {
              return (
                <div
                  className="player-wage-row"
                  key={item.id_player}
                  style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "" }}
                >
                  <div className="header-row">
                    <div className="player-wage-row mobile-header">
                      <div className="player-name" style={{ color: "#b2b2b2" }}>
                        Nume jucator{" "}
                      </div>
                      <div className="player-table-top">Varsta</div>
                      <div className="player-table-top">Data incheierii</div>
                      <div className="player-table-top">
                        <div className="title">
                          Salariu ({current_year - 1}/{current_year})
                        </div>
                        <div className="total">
                          <div>
                            Total:{" "}
                            {Math.round((this.state.totals[current_year] / 1000) * 10) /
                              10}
                            K
                          </div>
                          <Icon name="eur" className="detailed-wages-eur-icon" />
                        </div>
                      </div>
                      <div className="player-table-top">
                        <div className="title">
                          Salariu ({current_year}/{current_year + 1})
                        </div>
                        <div className="total">
                          <div>
                            Total:{" "}
                            {Math.round(
                              (this.state.totals[current_year + 1] / 1000) * 10
                            ) / 10}
                            K
                          </div>
                          <Icon name="eur" className="detailed-wages-eur-icon" />
                        </div>
                      </div>
                      <div className="player-table-top">
                        <div className="title">
                          Salariu ({current_year + 1}/{current_year + 2})
                        </div>
                        <div className="total">
                          <div>
                            Total:{" "}
                            {Math.round(
                              (this.state.totals[current_year + 2] / 1000) * 10
                            ) / 10}
                            K
                          </div>
                          <Icon name="eur" className="detailed-wages-eur-icon" />
                        </div>
                      </div>
                      <div className="player-table-top">
                        <div className="title">
                          Salariu ({current_year + 2}/{current_year + 3})
                        </div>
                        <div className="total">
                          <div>
                            Total:{" "}
                            {Math.round(
                              (this.state.totals[current_year + 3] / 1000) * 10
                            ) / 10}
                            K
                          </div>
                          <Icon name="eur" className="detailed-wages-eur-icon" />
                        </div>
                      </div>
                      {/* <div className="player-table-top">
                        <div className="title">
                          Salariu ({current_year + 3}/{current_year + 4})
                        </div>
                        <div className="total">
                          <div>
                            Total:{" "}
                            {Math.round(
                              (this.state.totals[current_year + 4] / 1000) * 10
                            ) / 10}
                            K
                          </div>
                          <Icon name="eur" className="detailed-wages-eur-icon" />
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="content-row">
                    <div
                      className="player-name"
                      onClick={() =>
                        this.props.history.push(`/club/player/${item.id_player}`)
                      }
                    >
                      <Avatar
                        src={`${window.baseURL}uploads/players/${item.id_player}.jpg`}
                        className="player-avatar"
                      />
                      {item.player_name}
                    </div>
                    <div className="player-month">
                      {item.age > 0 ? item.age : "-"}
                    </div>
                    <div className="player-month">
                      <Moment format="DD.MM.YYYY">
                        {item.contract_expire_date}
                      </Moment>
                    </div>
                    <div className="player-month">
                      {item.wages["2021"] ? (
                        <div className="amount">
                          <Icon name="eur" className="detailed-wages-eur-icon" />
                          {item.wages["2021"]} / luna
                        </div>
                      ) : (
                        "-"
                      )}
                    </div>
                    <div className="player-month">
                      {item.wages["2022"] ? (
                        <div className="amount">
                          <Icon name="eur" className="detailed-wages-eur-icon" />
                          {item.wages["2022"]} / luna
                        </div>
                      ) : (
                        "-"
                      )}
                    </div>
                    <div className="player-month">
                      {item.wages["2023"] ? (
                        <div className="amount">
                          <Icon name="eur" className="detailed-wages-eur-icon" />
                          {item.wages["2023"]} / luna
                        </div>
                      ) : (
                        "-"
                      )}
                    </div>
                    <div className="player-month">
                      {item.wages["2024"] ? (
                        <div className="amount">
                          <Icon name="eur" className="detailed-wages-eur-icon" />
                          {item.wages["2024"]} / luna
                        </div>
                      ) : (
                        "-"
                      )}
                    </div>
                    {/* <div className="player-month">
                      {item.wages["2025"] ? (
                        <div className="amount">
                          <Icon name="eur" className="detailed-wages-eur-icon" />
                          {item.wages["2022"]} / luna
                        </div>
                      ) : (
                        "-"
                      )}
                    </div> */}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="total-row">
            <div className="total-name">TOTAL</div>
            <div className="player-table-top">
              <div className="total">
                <div>
                  {Math.round((this.state.totals[current_year] / 1000) * 10) /
                    10}
                  K
                </div>
                <Icon name="eur" className="detailed-wages-eur-icon" />
              </div>
              <div className="title">
                ({current_year - 1}/{current_year})
              </div>
            </div>
            <div className="player-table-top">
              <div className="total">
                <div>
                  {Math.round(
                    (this.state.totals[current_year + 1] / 1000) * 10
                  ) / 10}
                  K
                </div>
                <Icon name="eur" className="detailed-wages-eur-icon" />
              </div>
              <div className="title">
                ({current_year}/{current_year + 1})
              </div>
            </div>
            <div className="player-table-top">
              <div className="total">
                <div>
                  {Math.round(
                    (this.state.totals[current_year + 2] / 1000) * 10
                  ) / 10}
                  K
                </div>
                <Icon name="eur" className="detailed-wages-eur-icon" />
              </div>
              <div className="title">
                ({current_year + 1}/{current_year + 2})
              </div>
            </div>
            <div className="player-table-top">
              <div className="total">
                <div>
                  {Math.round(
                    (this.state.totals[current_year + 3] / 1000) * 10
                  ) / 10}
                  K
                </div>
                <Icon name="eur" className="detailed-wages-eur-icon" />
              </div>
              <div className="title">
                ({current_year + 2}/{current_year + 3})
              </div>
            </div>
            {/* <div className="player-table-top">
              <div className="total">
                <div>
                  {Math.round(
                    (this.state.totals[current_year + 4] / 1000) * 10
                  ) / 10}
                  K
                </div>
                <Icon name="eur" className="detailed-wages-eur-icon" />
              </div>
              <div className="title">
                ({current_year + 3}/{current_year + 4})
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(WagesDetailed);
