import React, { useEffect, useState } from 'react';
import './PrepareNextMatch.scss';
import axios from 'axios';
import Moment from 'react-moment';
import { ReactComponent as EyeIcon } from "../../../../assets/icons/eye-white.svg";
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';

export default function LastTrainings(props) {
    const [trainings, setTrainings] = useState([]);
    const [players, setPlayers] = useState([]);
    const [dropdownOption, setDropdownOption] = useState("distance_meters");
    const history = useHistory();

    const options = [
        { value: 'heart_rate_avg', text: 'Puls mediu' },
        { value: 'heart_rate_max', text: 'Puls maxim' },
        { value: 'distance_meters', text: 'Distanta parcursa (km)' },
        { value: 'kilo_calories', text: 'Calorii consumate' },
        { value: 'sprint_counter', text: 'Numar de sprinturi' },
        { value: 'speed_avg_kmh', text: 'Viteza medie' },
        { value: 'speed_max_kmh', text: 'Viteza maxima' }
    ];

    useEffect(() => {
        getTrainings();
        getPlayers();
    }, [])

    useEffect(() => {
        getPlayers();
    }, [dropdownOption])

    const getTrainings = () => {
        axios.get(
            `training/get_training_sessions?id_club=1&selected_team=1&limit=5`
        )
            .then((res) => setTrainings(res.data))
            .catch((err) => console.log(err));
    }

    const getPlayers = () => {
        axios.get(
            `match/last_trainings_top?parameter=${dropdownOption}`
        )
            .then((res) => setPlayers(res.data))
            .catch((err) => console.log(err));
    }

    function handleChangeDropdown(e, value) {
        setDropdownOption(value.value);
    };

    return (
        <div className="last-trainings-container">
            <div className="last-trainings-table-header">
                <div className="training-date">Data</div>
                <div className="training-hour">Ora</div>
                <div className="training-options">Detalii</div>
            </div>
            {trainings.map((item, index) => {
                return (
                    <div className="training-session-row" style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                    }}>
                        <div className="training-date"><Moment format="DD.MM.YYYY">{item.date}</Moment></div>
                        <div className="training-hour">{item.hour_start}-{item.hour_end}</div>
                        <div className="training-options">
                            <div
                                className="eye-icon-container"
                                onClick={() =>
                                    history.push(
                                        `/training/history/${item.id_training}`
                                    )
                                }
                            >
                                <EyeIcon />
                            </div>
                        </div>
                    </div>
                )
            })}
            <div className="last-trainings-header">
                <div className="last-trainings-title">Top jucatori la ultimele 5 antrenamente</div>
                <Dropdown
                    options={options}
                    selection
                    value={dropdownOption}
                    onChange={handleChangeDropdown}
                    placeholder="Selecteaza parametru"
                    className="last-trainings-dropdown" />
            </div>

            <div className="last-trainings-table-header">
                <div className="position"></div>
                <div className="player-name">Nume</div>
                <div className="player-value">Valoare medie</div>
            </div>
            {players.map((item, index) => (
                <div className="training-session-row" style={{
                    backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                }}>
                    <div className="position">{index + 1}.</div>
                    <div className="player-name">{item.player_name}</div>
                    <div className="player-value">{dropdownOption === "distance_meters" ? (item.avg / 1000).toFixed(2) : item.avg}
                        {(dropdownOption === "speed_avg_kmh" || dropdownOption === "speed_max_kmh") && ' km/h'}
                        {(dropdownOption === "heart_rate_avg" || dropdownOption === "heart_rate_max") && ' bpm'}
                        {dropdownOption === "kilo_calories" && ' kcal'}
                        {dropdownOption === "distance_meters" && ' km'} </div>
                </div>


            ))}
        </div>
    )
}