import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { Button, Input } from "semantic-ui-react";
import "../../ClubProfile/Teams/Teams.scss";
import "./MatchDetails.scss";
import { store } from "react-notifications-component";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TFBCard from "../../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../../components/design-system/Card/TFBCardBody";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBIconButton from "../../../../components/design-system/IconButton/TFBIconButton";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";

function MatchLineup(props) {
  const {
    languagePack,
    matchDetails,
    teamsPlayers,
    hasAccessToDeleteHomeEvents,
    hasAccessToDeleteAwayEvents,
    hasAccessToEditMatch,
    updatePlayersRating,
  } = props;
  const history = useHistory();

  const [isLoad, setisLoad] = useState(false);
  const [playerHome, setPlayerHome] = useState(null);
  const [playerAway, setPlayerAway] = useState(null);
  const [playerHomeSub, setPlayerHomeSub] = useState(null);
  const [playerAwaySub, setPlayerAwaySub] = useState(null);

  const [idEventUpdate, setIdEventUpdate] = useState("");
  const [newPlayerNumber, setNewPlayerNumber] = useState("");
  const [deleteLineupEvent, setDeleteLineupEvent] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");

  const [lineups, setLineups] = useState({});
  const [referee1, setReferee1] = useState(matchDetails.referee ?? "");
  const [referee2, setReferee2] = useState(matchDetails.assistant_first ?? "");
  const [referee3, setReferee3] = useState(matchDetails.assistant_second ?? "");
  const [referee4, setReferee4] = useState(matchDetails.fourth_official ?? "");

  const inputNumberRef = useRef(null);

  useEffect(() => {
    setLineupsAndSubs();
  }, []);

  const setLineupsAndSubs = () => {
    axios
      .get(
        `match/get_manual_match_lineups?id_match=${matchDetails.id_match}&home_team_id=${matchDetails.id_team_home}&away_team_id=${matchDetails.id_team_away}`
      )
      .then((res) => {
        setLineups(res.data);
        setisLoad(true);
      })
      .catch((err) => console.log(err));
  };

  const addPlayer = (type, team) => {
    const playerDataId = {
      start: { home: playerHome?.value, away: playerAway?.value },
      sub: { home: playerHomeSub?.value, away: playerAwaySub?.value },
    };

    const playerDataName = {
      start: { home: playerHome?.label, away: playerAway?.label },
      sub: { home: playerHomeSub?.label, away: playerAwaySub?.label },
    };

    const idTeam = {
      home: matchDetails.own_team_home_id,
      away: matchDetails.own_team_away_id,
    };

    const idManualCompetitionTeam = {
      home: matchDetails.id_team_home,
      away: matchDetails.id_team_away,
    };

    const payload = {
      id_match: matchDetails.id_match,
      action_name: type == "start" ? "Starting player" : "Substitute player",
      minute: 0,
      half: 1,
      id_player: playerDataId[type][team],
      player_name: playerDataName[type][team],
      id_second_player: "",
      player_second_name: "",
      id_team: idTeam[team],
      id_manual_competition_team: idManualCompetitionTeam[team],
    };

    axios.post("match/add_manual_match_event", payload).then((res) => {
      if (res.data.success == 1) {
        setLineupsAndSubs();
        updatePlayersRating();

        store.addNotification({
          title: languagePack["fixtures_schedule"],
          message:
            type == "start"
              ? languagePack["player_lineup_successfully_added"]
              : languagePack["player_sub_successfully_added"],
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      } else {
        store.addNotification({
          title: languagePack["fixtures_schedule"],
          message: "Evenimentul nu a fost adaugat cu succes.",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      }
    });
  };

  const openDeleteLineupRow = (id) => {
    setIdToDelete(id);
    setDeleteLineupEvent(true);
  };

  const closeDeleteLineupRow = () => {
    setIdToDelete("");
    setDeleteLineupEvent(false);
  };

  const deleteLineupRow = () => {
    axios
      .post(`match/delete_event`, { id_event: idToDelete })
      .then((res) => {
        setLineupsAndSubs();
        updatePlayersRating();
        closeDeleteLineupRow();
        store.addNotification({
          title: languagePack["fixtures_schedule"],
          message: "Inregistrarea a fost stearsa cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  const saveReferees = () => {
    const payload = {
      referee: referee1,
      assistant_first: referee2,
      assistant_second: referee3,
      fourth_official: referee4,
      id_match: matchDetails.id_match,
    };
    axios
      .post(`match/save_referees`, payload)
      .then((res) => {
        setLineupsAndSubs();
        store.addNotification({
          title: languagePack["fixtures_schedule"],
          message: "Arbitrii au fost salvati cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  const changePlayerNumber = (id_event) => {
    setIdEventUpdate(id_event);
  };

  const handleChangeNumber = (e) => {
    setNewPlayerNumber(e.target.value);
  };

  const closeEditPlayerNumber = () => {
    setIdEventUpdate("");
    setNewPlayerNumber("");
  };

  const saveNewPlayerNumberInLineup = (old_number) => {
    if (old_number != newPlayerNumber) {
      const payload = {
        id_manual_match_event: idEventUpdate,
        player_number: newPlayerNumber,
        id_match: matchDetails.id_match,
      };
      axios
        .post(`match/save_new_player_number_for_lineup`, payload)
        .then((res) => {
          setLineupsAndSubs();
          closeEditPlayerNumber();
          store.addNotification({
            title: languagePack["fixtures_schedule"],
            message: "Noul numar a fost salvat cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        })
        .catch((err) => console.log(err));
    } else {
      closeEditPlayerNumber();
    }
  };

  const addPlayerHomeDiv = (
    <div className="player-lineup-row add-player-row">
      <TFBIconButton
        name="add"
        color="green"
        onClick={() => {
          addPlayer("start", "home");
          setPlayerHome(null);
        }}
        disabled={playerHome == null}
      />
      <TFBSelect
        placeholder={languagePack.select_player}
        options={
          teamsPlayers?.[matchDetails.id_team_home]
            ? [...teamsPlayers?.[matchDetails.id_team_home]].filter(player => ![...lineups?.home_team?.starting ?? [], ...lineups?.home_team?.substitutes ?? []].map(elem => elem.id_player).includes(player.value))
            : []
        }
        value={playerHome}
        onChange={(selected) => {
          setPlayerHome(selected);
        }}
      />
    </div>
  );

  const addPlayerHomeSubDiv = (
    <div className="player-lineup-row add-player-row">
      <TFBIconButton
        name="add"
        color="green"
        onClick={() => {
          addPlayer("sub", "home");
          setPlayerHomeSub(null);
        }}
        disabled={playerHomeSub == null}
      />
      <TFBSelect
        placeholder={languagePack.select_player}
        options={
          teamsPlayers?.[matchDetails.id_team_home]
            ? [...teamsPlayers?.[matchDetails.id_team_home]].filter(player => ![...lineups?.home_team?.starting ?? [], ...lineups?.home_team?.substitutes ?? []].map(elem => elem.id_player).includes(player.value))
            : []
        }
        value={playerHomeSub}
        onChange={(selected) => {
          setPlayerHomeSub(selected);
        }}
      />
    </div>
  );

  const addPlayerAwayDiv = (
    <div className="player-lineup-row add-player-row">
      <TFBSelect
        placeholder={languagePack.select_player}
        options={
          teamsPlayers?.[matchDetails.id_team_away]
            ? [...teamsPlayers?.[matchDetails.id_team_away]].filter(player => ![...lineups?.away_team?.starting ?? [], ...lineups?.away_team?.substitutes ?? []].map(elem => elem.id_player).includes(player.value))
            : []
        }
        value={playerAway}
        onChange={(selected) => {
          setPlayerAway(selected);
        }}
      />
      <TFBIconButton
        name="add"
        color="green"
        onClick={() => {
          addPlayer("start", "away");
          setPlayerAway(null);
        }}
        disabled={playerAway == null}
      />
    </div>
  );

  const addPlayerAwaySubDiv = (
    <div className="player-lineup-row add-player-row">
      <TFBSelect
        placeholder={languagePack.select_player}
        options={
          teamsPlayers?.[matchDetails.id_team_away]
            ? [...teamsPlayers?.[matchDetails.id_team_away]].filter(player => ![...lineups?.away_team?.starting ?? [], ...lineups?.away_team?.substitutes ?? []].map(elem => elem.id_player).includes(player.value))
            : []
        }
        value={playerAwaySub}
        onChange={(selected) => {
          setPlayerAwaySub(selected);
        }}
      />
      <TFBIconButton
        name="add"
        color="green"
        onClick={() => {
          addPlayer("sub", "away");
          setPlayerAwaySub(null);
        }}
        disabled={playerAwaySub == null}
      />
    </div>
  );

  return (
    <TFBCard>
      <TFBCardHeader title={languagePack["starting_lineup"]} />
      <TFBCardBody>
        {isLoad && (
          <div className="match-lineup-container">
            <div className="match-lineup-details-container">
              <div className="details-team-container left">
                <div className="logo-container team-home">
                  <img src={matchDetails.home_team_logo_url} alt="team_home" />
                </div>
                <div className="team-name">{matchDetails.home_team_name}</div>
              </div>
              <div className="vs">{"vs"}</div>
              <div className="details-team-container right">
                <div className="team-name">{matchDetails.away_team_name}</div>
                <div className="logo-container team-away">
                  <img src={matchDetails.away_team_logo_url} alt="team_away" />
                </div>
              </div>
            </div>
            <div className="match-lineup-list">
              <div className="column-49">
                {lineups.home_team.starting.map((item, index) => {
                  return (
                    <div
                      className="player-lineup-row manual-match"
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                      key={"home-starting-player" + index}
                    >
                      <div className="player-lineup-row-data">
                        {idEventUpdate != item.id_event ? (
                          <div
                            className="number number-left-part"
                            onClick={() => {
                              if (hasAccessToDeleteHomeEvents) {
                                changePlayerNumber(item.id_event);
                              }
                            }}
                          >
                            {item.number}
                          </div>
                        ) : (
                          <div className="player-number-change-container">
                            <Input
                              className="player-number-input"
                              value={newPlayerNumber}
                              onChange={handleChangeNumber}
                              ref={inputNumberRef}
                            />
                            <div
                              className="icon-container"
                              onClick={() =>
                                saveNewPlayerNumberInLineup(item.number)
                              }
                            >
                              <TFBIcon name="check" color="green" />
                            </div>
                            <div
                              className="icon-container"
                              onClick={closeEditPlayerNumber}
                            >
                              <TFBIcon name="x-small" color="red" />
                            </div>
                          </div>
                        )}
                        <div
                          className="name"
                          onClick={() =>
                            history.push("/club/player/" + item.id_player)
                          }
                        >
                          {item.name}
                        </div>
                      </div>
                      {hasAccessToDeleteHomeEvents &&
                        idEventUpdate != item.id_event && (
                          <div
                            className="player-lineup-row-delete"
                            onClick={() => openDeleteLineupRow(item.id_event)}
                          >
                            <TFBIcon name="delete" color="gray" />
                          </div>
                        )}
                    </div>
                  );
                })}
                {hasAccessToDeleteHomeEvents && addPlayerHomeDiv}
              </div>
              <div className="column-49">
                {lineups.away_team.starting.map((item, index) => {
                  return (
                    <div
                      className="player-lineup-row manual-match"
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                      key={"away-starting-player" + index}
                    >
                      {hasAccessToDeleteAwayEvents &&
                        idEventUpdate != item.id_event && (
                          <div
                            className="player-lineup-row-delete"
                            onClick={() => openDeleteLineupRow(item.id_event)}
                          >
                            <TFBIcon name="delete" color="gray" />
                          </div>
                        )}
                      <div className="player-lineup-row-data away">
                        <div
                          className="name"
                          onClick={() =>
                            history.push("/club/player/" + item.id_player)
                          }
                        >
                          {item.name}
                        </div>
                        {idEventUpdate != item.id_event ? (
                          <div
                            className="number number-right-part"
                            onClick={() => {
                              if (hasAccessToDeleteAwayEvents) {
                                changePlayerNumber(item.id_event);
                              }
                            }}
                          >
                            {item.number}
                          </div>
                        ) : (
                          <div className="player-number-change-container away">
                            <div
                              className="icon-container"
                              onClick={closeEditPlayerNumber}
                            >
                              <TFBIcon name="x-small" color="red" />
                            </div>
                            <div
                              className="icon-container"
                              onClick={() =>
                                saveNewPlayerNumberInLineup(item.number)
                              }
                            >
                              <TFBIcon name="check" color="green" />
                            </div>
                            <Input
                              className="player-number-input"
                              value={newPlayerNumber}
                              onChange={handleChangeNumber}
                              ref={inputNumberRef}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
                {hasAccessToDeleteAwayEvents && addPlayerAwayDiv}
              </div>
            </div>
            <div className="substitutes-title">
              {languagePack["substitutes"]}
            </div>
            <div className="match-lineup-list">
              <div className="column-49">
                {hasAccessToDeleteHomeEvents && addPlayerHomeSubDiv}
                {lineups.home_team.substitutes.map((item, index) => {
                  return (
                    <div
                      className="player-lineup-row manual-match"
                      style={{
                        backgroundColor: index % 2 == 0 ? "white" : "#fafafa",
                      }}
                      key={"home-substitute-player" + index}
                    >
                      <div className="player-lineup-row-data">
                        {idEventUpdate != item.id_event ? (
                          <div
                            className="number number-left-part"
                            onClick={() => {
                              if (hasAccessToDeleteHomeEvents) {
                                changePlayerNumber(item.id_event);
                              }
                            }}
                          >
                            {item.number}
                          </div>
                        ) : (
                          <div className="player-number-change-container">
                            <Input
                              className="player-number-input"
                              value={newPlayerNumber}
                              onChange={handleChangeNumber}
                              ref={inputNumberRef}
                            />
                            <div
                              className="icon-container"
                              onClick={() =>
                                saveNewPlayerNumberInLineup(item.number)
                              }
                            >
                              <TFBIcon name="check" color="green" />
                            </div>
                            <div
                              className="icon-container"
                              onClick={closeEditPlayerNumber}
                            >
                              <TFBIcon name="x-small" color="red" />
                            </div>
                          </div>
                        )}
                        <div
                          className="name"
                          onClick={() =>
                            history.push("/club/player/" + item.id_player)
                          }
                        >
                          {item.name}
                        </div>
                      </div>
                      {hasAccessToDeleteHomeEvents &&
                        idEventUpdate != item.id_event && (
                          <div
                            className="player-lineup-row-delete"
                            onClick={() => openDeleteLineupRow(item.id_event)}
                          >
                            <TFBIcon name="delete" color="gray" />
                          </div>
                        )}
                    </div>
                  );
                })}
              </div>
              <div className="column-49">
                {hasAccessToDeleteAwayEvents && addPlayerAwaySubDiv}
                {lineups.away_team.substitutes.map((item, index) => {
                  return (
                    <div
                      className="player-lineup-row manual-match"
                      style={{
                        backgroundColor: index % 2 == 0 ? "#white" : "#fafafa",
                      }}
                      key={"away-substitute-player" + index}
                    >
                      {hasAccessToDeleteAwayEvents &&
                        idEventUpdate != item.id_event && (
                          <div
                            className="player-lineup-row-delete"
                            onClick={() => openDeleteLineupRow(item.id_event)}
                          >
                            <TFBIcon name="delete" color="gray" />
                          </div>
                        )}
                      <div className="player-lineup-row-data away">
                        <div
                          className="name"
                          onClick={() =>
                            history.push("/club/player/" + item.id_player)
                          }
                        >
                          {item.name}
                        </div>
                        {idEventUpdate != item.id_event ? (
                          <div
                            className="number number-right-part"
                            onClick={() => {
                              if (hasAccessToDeleteAwayEvents) {
                                changePlayerNumber(item.id_event);
                              }
                            }}
                          >
                            {item.number}
                          </div>
                        ) : (
                          <div className="player-number-change-container away">
                            <div
                              className="icon-container"
                              onClick={closeEditPlayerNumber}
                            >
                              <TFBIcon name="x-small" color="red" />
                            </div>
                            <div
                              className="icon-container"
                              onClick={() =>
                                saveNewPlayerNumberInLineup(item.number)
                              }
                            >
                              <TFBIcon name="check" color="green" />
                            </div>
                            <Input
                              className="player-number-input"
                              value={newPlayerNumber}
                              onChange={handleChangeNumber}
                              ref={inputNumberRef}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="substitutes-title">{languagePack.refrees}</div>
            {hasAccessToEditMatch ? (
              <div className="referees-list">
                <div className="referee-line">
                  <div className="referee-type">A:</div>
                  <div className="referee-input">
                    <input
                      type="text"
                      value={referee1}
                      onChange={(e) => setReferee1(e.target.value)}
                      placeholder={languagePack.central_referee}
                      className="add-team-input"
                    />
                  </div>
                </div>
                <div className="referee-line">
                  <div className="referee-type">A1:</div>
                  <div className="referee-input">
                    <input
                      type="text"
                      value={referee2}
                      onChange={(e) => setReferee2(e.target.value)}
                      placeholder={languagePack.assistant_referee}
                      className="add-team-input"
                    />
                  </div>
                </div>
                <div className="referee-line">
                  <div className="referee-type">A2:</div>
                  <div className="referee-input">
                    <input
                      type="text"
                      value={referee3}
                      onChange={(e) => setReferee3(e.target.value)}
                      placeholder={languagePack.assistant_referee}
                      className="add-team-input"
                    />
                  </div>
                </div>
                <div className="referee-line">
                  <div className="referee-type">R:</div>
                  <div className="referee-input">
                    <input
                      type="text"
                      value={referee4}
                      onChange={(e) => setReferee4(e.target.value)}
                      placeholder={languagePack.reserve_referee}
                      className="add-team-input"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="referees-list">
                <div className="referee-line">
                  <div className="referee-type">A:</div>
                  <div className="referee-input">
                    <div className="add-team-input">{referee1}</div>
                  </div>
                </div>
                <div className="referee-line">
                  <div className="referee-type">A1:</div>
                  <div className="referee-input">
                    <div className="add-team-input">{referee2}</div>
                  </div>
                </div>
                <div className="referee-line">
                  <div className="referee-type">A2:</div>
                  <div className="referee-input">
                    <div className="add-team-input">{referee3}</div>
                  </div>
                </div>
                <div className="referee-line">
                  <div className="referee-type">R:</div>
                  <div className="referee-input">
                    <div className="add-team-input">{referee4}</div>
                  </div>
                </div>
              </div>
            )}
            {hasAccessToEditMatch && (
              <div className="save-referees">
                <TFBButton
                  color="green"
                  onClick={saveReferees}
                  renderIcon={() => <TFBIcon name="check" />}
                >
                  {languagePack.save_refrees}
                </TFBButton>
              </div>
            )}
          </div>
        )}
      </TFBCardBody>
      <Dialog open={deleteLineupEvent} onClose={closeDeleteLineupRow}>
        <DialogContent className="add-team-wrapper">
          <div>
            {"Esti sigur ca vrei sa stergi evenimentul din echipele de start?"}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDeleteLineupRow}
            style={{ backgroundColor: "#00d948", color: "white" }}
          >
            {"Nu"}
          </Button>
          <Button onClick={deleteLineupRow} color="red">
            {"Da, sterge"}
          </Button>
        </DialogActions>
      </Dialog>
    </TFBCard>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(MatchLineup);
