import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router-dom";

import "./BasicLastMatchesStats.scss";

import TFBCard from "../../../design-system/Card/TFBCard";
import TFBCardHeader from "../../../design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../design-system/Card/TFBCardBody";
import TFBPlaceholder from "../../../design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBIconButton from "../../../design-system/IconButton/TFBIconButton";
import { sxStickyColumn, TFBTableCell } from "../../../reusable/useStyles";

export default function BasicLastMatchesStats(props) {

  const { languagePack } = props;
  const history = useHistory();

  const [stats, setStats] = useState({});

  useEffect(() => {
    const getStats = () => {
      axios
        .get(`instat/player_basic_last_macthes_stats?id_player=${props.id_player}&id_season=${props.id_season}`)
        .then((res) => {
          setStats(res.data);
        });
    };
    getStats();
  }, []);

  return (
    <TFBCard style={{ height: "100%" }}>
      <TFBCardHeader title={languagePack.last_matches_stats} />
      <TFBCardBody>
        {stats.length > 0 ?
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TFBTableCell sx={sxStickyColumn}>
                    {languagePack.match}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.score}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.minutes_played}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.goals_scored_pl}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.key_pass}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.yellow_cards_pl}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.red_cards_pl}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.player_match_rating}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.product_actions}
                  </TFBTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stats.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                  >
                    <TFBTableCell
                      sx={sxStickyColumn}
                      style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                    >
                      {row["match"]}<br></br>
                      <span style={{ color: "var(--primaryColor)" }}>
                        {moment(row["match_date"]).format("DD.MM.yyyy")}
                      </span>
                    </TFBTableCell>
                    <TFBTableCell align="center">{row.score}</TFBTableCell>
                    <TFBTableCell align="center">{row.played_minutes}</TFBTableCell>
                    <TFBTableCell align="center">
                      <div className="player-last-matches-stat">
                        <div className={`stat ${row["scored_goals"] > 0 ? "green" : ""}`}>{row["scored_goals"]}</div>
                      </div>
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      <div className="player-last-matches-stat">
                        <div className={`stat ${row["assits"] > 0 ? "green" : ""}`}>{row["assits"]}</div>
                      </div>
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      <div className="player-last-matches-stat">
                        <div className={`stat ${row["yellow_cards"] > 0 ? "yellow" : ""}`}>{row["yellow_cards"]}</div>
                      </div>
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      <div className="player-last-matches-stat">
                        <div className={`stat ${row["red_cards"] > 0 ? "red" : ""}`}>{row["red_cards"]}</div>
                      </div>
                    </TFBTableCell>
                    <TFBTableCell align="center">{row.match_rating > 0 ? row.match_rating + "/5" : "-"}</TFBTableCell>
                    <TFBTableCell>
                      <div className="actions-btns-container">
                        <TFBIconButton
                          name="view"
                          color="darkGray"
                          onClick={() =>
                            history.push(`/club/match/${row.id_match}`)
                          }
                        />
                      </div>
                    </TFBTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> :
          <TFBPlaceholder text={languagePack.no_matches} />
        }
      </TFBCardBody>
    </TFBCard>
  );
}
