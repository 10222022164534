import React from "react";
import "./PlanTraining.scss";
import {
  TimePicker,
  DatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { TextArea, Input } from "semantic-ui-react";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TacticBoard from "../TacticBoard/TacticBoard";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { store } from "react-notifications-component";
import { connect } from "react-redux";

import { addMinutes } from "date-fns";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton.tsx";
import TFBButton from "../../../components/design-system/Button/TFBButton.tsx";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon.tsx";
import TFBCard from "../../../components/design-system/Card/TFBCard";

import { TFBTableCell } from "../../../components/reusable/useStyles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

class EditTraining extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      team: null,
      selected: [],
      items: [],
      date_start: null,
      date_start_mobile: null,
      date_end: null,
      date_end_mobile: null,
      description: "",
      venues: [],
      addSection: false,
      tacticBoardId: "",
      added_section_name: "",
      isWritingInput: "",
      currentTotalTime: 0,
      error: "",
      venue: null,

      table_row_sections: [],
      open_add_section: true,
      sectionOptions: [],
      selectedSection: "",

      section_to_add: false,
      currentTotalTimeMobile: 0,
    };
  }

  id2List = {
    droppable: "items",
    droppable2: "selected",
  };

  componentDidMount = () => {
    this.getClubVenues();
  };

  getTrainingData = () => {
    axios
      .get(
        `training/get_data_for_training_session_update?id_training=${this.props.match.params.id}`
      )
      .then((res) => {
        let sectionOptions = res.data.all_training_sections.map(
          ({ id, content, value }) => ({
            value: id,
            label: content,
            duration: "",
          })
        );

        let selectedTrainingSections = res.data.selected_training_sections.map(
          ({ id, content, value, effective_duration }) => ({
            value: id,
            label: content,
            duration: value,
            effective_duration: effective_duration,
          })
        );

        this.setState(
          {
            team: res.data.team,
            selected: res.data.all_training_sections,
            sectionOptions: sectionOptions,
            table_row_sections: selectedTrainingSections,
            items: res.data.selected_training_sections,
            date_start: new Date(
              moment(res.data.date_start).format("MM/DD/yyyy HH:mm:ss")
            ),
            date_start_mobile: new Date(
              moment(res.data.date_start).format("MM/DD/yyyy HH:mm:ss")
            ),
            date_end: new Date(
              moment(res.data.date_end).format("MM/DD/yyyy HH:mm:ss")
            ),
            date_end_mobile: new Date(
              moment(res.data.date_end).format("MM/DD/yyyy HH:mm:ss")
            ),
            description:
              res.data.description == null ? "" : res.data.description,
            venue:
              res.data.id_location > 0
                ? this.state.venues.filter(
                    (elem) => elem.value == res.data.id_location
                  )[0]
                : null,
          },
          () => {
            this.timeTotal();
            this.timeTotalMobile();

            let allSection = [...this.state.items];
            allSection.forEach((section, index) => {
              this.validateEffectiveDuration(index);
            });

            let allSectionMobile = [...this.state.table_row_sections];
            allSectionMobile.forEach((section, index) => {
              this.validateEffectiveDurationMobile(index);
            });
          }
        );
      })
      .catch((err) => console.log(err));
  };

  getExistingParameters = (id_team) => {
    axios
      .get(
        `training/get_existing_sections?id_club=${this.props.currentUser.id_club}&id_team=`
      )
      .then((res) => {
        let categories = Array(...res.data);
        let existentCategories = this.state.items.map((elem) => elem.id);
        categories = categories.filter(
          (elem) => !existentCategories.includes(elem.id)
        );

        let sectionOptions = categories.map(
          ({ id: value, content: label }) => ({
            value,
            label,
          })
        );
        this.setState({ selected: categories, sectionOptions: sectionOptions });
      })
      .catch((err) => console.log(err));
  };

  getClubVenues = () => {
    axios
      .get(`club/get_venues?id_club=${this.props.currentUser.id_club}`)
      .then((res) => {
        this.setState(
          {
            venues: res.data,
          },
          this.getTrainingData
        );
      })
      .catch((err) => console.log(err));
  };

  handleDateChange = (value) => {
    this.setState({ date_start: value });
  };

  handleHourStartChange = (value) => {
    this.setState({ date_start: value }, this.calcTrainingEnd);
  };

  handleHourStartChangeMobile = (value) => {
    this.setState({ date_start_mobile: value }, this.calcTrainingEndMobile);
  };

  handleChangeMobileInput = (index, durationValue) => {
    let tableDataRows = this.state.table_row_sections;
    tableDataRows[index].duration = durationValue;
    this.setState({ table_row_sections: tableDataRows }, () => {
      this.timeTotalMobile();
      this.validateEffectiveDurationMobile(index);
    });
  };

  handleChangeMobileEffectiveDur = (index, durationValue) => {
    let tableDataRows = this.state.table_row_sections;
    tableDataRows[index].effective_duration = durationValue;
    this.setState({ table_row_sections: tableDataRows }, () =>
      this.validateEffectiveDurationMobile(index)
    );
  };

  calcTrainingEndMobile = () => {
    const setHourEnd = addMinutes(
      this.state.date_start_mobile,
      this.state.currentTotalTimeMobile
    );
    this.setState({ date_end_mobile: setHourEnd });
  };

  timeTotalMobile = () => {
    let tableData = this.state.table_row_sections;
    let durationList = [];
    tableData.forEach((row) => {
      durationList.push(Number(row.duration ?? 0));
    });
    let sumTime = durationList.reduce((a, b) => a + b, 0);

    this.setState({ currentTotalTimeMobile: sumTime }, () => {
      if (this.state.date_start_mobile != null) {
        this.calcTrainingEndMobile();
      }
    });
  };

  validateEffectiveDuration = (index) => {
    this.setState((prevState) => {
      const sectionsList = [...prevState.items];

      function checkDifference(duration, effectiveDuration) {
        if (parseFloat(effectiveDuration) > parseFloat(duration)) {
          sectionsList[index].wrongEffectiveDuration = true;
        } else {
          sectionsList[index].wrongEffectiveDuration = false;
        }
      }

      if (
        sectionsList[index].effective_duration &&
        sectionsList[index].effective_duration !== ""
      ) {
        checkDifference(
          sectionsList[index].value,
          sectionsList[index].effective_duration
        );
      } else {
        sectionsList[index].wrongEffectiveDuration = false;
      }

      return { items: sectionsList };
    });
  };

  validateEffectiveDurationMobile = (index) => {
    this.setState((prevState) => {
      const sectionsList = [...prevState.table_row_sections];

      function checkDifference(duration, effectiveDuration) {
        if (parseFloat(effectiveDuration) > parseFloat(duration)) {
          sectionsList[index].wrongEffectiveDuration = true;
        } else {
          sectionsList[index].wrongEffectiveDuration = false;
        }
      }

      if (
        sectionsList[index].effective_duration &&
        sectionsList[index].effective_duration !== ""
      ) {
        checkDifference(
          sectionsList[index].duration,
          sectionsList[index].effective_duration
        );
      } else {
        sectionsList[index].wrongEffectiveDuration = false;
      }

      return { table_row_sections: sectionsList };
    });
  };

  calcTrainingEnd = () => {
    const setHourEnd = addMinutes(
      this.state.date_start,
      this.state.currentTotalTime
    );
    this.setState({ date_end: setHourEnd });
  };

  handleHourEndChange = (value) => {
    this.setState({ date_end: value });
  };

  timeTotal = () => {
    let sum = this.state.items.reduce(function(prev, current) {
      return prev + +current.value;
    }, 0);
    this.setState({ currentTotalTime: sum }, this.calcTrainingEnd);
  };

  getList = (id) => this.state[this.id2List[id]];

  onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === "droppable2") {
        state = { selected: items };
      }

      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({
        items: result.droppable,
        selected: result.droppable2,
      });
    }
  };

  handleChangeInput = (e) => {
    let elementsIndex = this.state.items.findIndex(
      (el) => el.id === this.state.isWritingInput
    );
    let newArray = [...this.state.items];

    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      value: e.target.value,
    };

    this.setState({ items: newArray }, () => {
      this.timeTotal();
      this.validateEffectiveDuration(elementsIndex);
    });
  };

  handleChangeInputEffective = (e) => {
    let elementsIndex = this.state.items.findIndex(
      (el) => el.id === this.state.isWritingInput
    );
    let newArray = [...this.state.items];

    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      effective_duration: e.target.value,
    };

    this.setState({ items: newArray }, () =>
      this.validateEffectiveDuration(elementsIndex)
    );
  };

  convertToHrsMin = (val) => {
    const hours = Math.floor(val / 60);
    const min = val % 60;
    if (hours > 0) return hours + " h," + min + " min";
    else return min + " min";
  };

  removeElementFromList = (val) => {
    var array = [...this.state.items]; // make a separate copy of the array
    var index = array.findIndex((item) => item.id === val);
    if (index !== -1) {
      let copy = array[index];
      array.splice(index, 1);
      this.setState(
        {
          items: array,
          selected: this.state.selected.concat(copy),
        },
        this.timeTotal
      );
    }
  };

  createNewSection = () => {
    const newItem = {
      id: this.state.added_section_name,
      content: this.state.added_section_name,
      value: "",
    };
    const data = new FormData();
    data.append("id_club", this.props.currentUser.id_club);
    data.append("id_user", this.props.currentUser.id_user);
    data.append("section_name", this.state.added_section_name);

    if (this.state.added_section_name != "") {
      axios
        .post("training/add_training_section", data)
        .then((res) => {
          if (res.status == 200 && res.statusText == "OK") {
            this.setState({
              selected: this.state.selected.concat(newItem),
              added_section_name: "",
              addSection: false,
              error: "",
            });
            store.addNotification({
              title: this.props.languagePack["section_add_title"],
              message: this.props.languagePack["section_added_success"],
              type: "success",
              insert: "bottom",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
              },
            });
            this.getExistingParameters(this.state.id_team);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  addSectionToTable = () => {
    let tableSections = [
      ...this.state.table_row_sections,
      this.state.selectedSection,
    ];
    let selectedSection = this.state.selectedSection;
    let sectionOptions = this.state.sectionOptions;
    let newSectionOptions = sectionOptions.filter(
      (section) => section.value != selectedSection.value
    );

    this.setState({
      section_to_add: false,
      open_add_section: true,
      selectedSection: "",
      table_row_sections: tableSections,
      sectionOptions: newSectionOptions,
    });
  };

  removeRowFromTable = (row) => {
    if (row.value != "") {
      const { duration, ...rowRemove } = row;
      let tableData = this.state.table_row_sections.filter(
        (section) => section.value != row.value
      );
      let sectionOptions = [...this.state.sectionOptions, rowRemove];
      this.setState({
        sectionOptions: sectionOptions,
        table_row_sections: tableData,
      });
    } else {
      let tableData = this.state.table_row_sections.filter(
        (section) => section.value != row.value
      );

      this.setState({
        table_row_sections: tableData,
      });
    }
  };

  submitTraining = () => {
    const data = new FormData();

    data.append("id_user", this.props.currentUser.id_user);
    data.append("id_training", this.props.match.params.id);
    data.append("id_team", JSON.stringify(this.state.team.value));
    data.append("id_club", this.props.currentUser.id_club);
    data.append("date", moment(this.state.date_start).format("YYYY-MM-DD"));
    data.append("start_hour", moment(this.state.date_start).format("HH:mm"));
    data.append("end_hour", moment(this.state.date_end).format("HH:mm"));
    data.append("description", this.state.description);
    data.append("sections", JSON.stringify(this.state.items));

    if (this.state.venue != null) {
      data.append("location", this.state.venue.value);
    } else {
      data.append("location", null);
    }

    // if (this.state.items.length > 0) {
    if (this.state.date_start && this.state.date_end) {
      axios
        .post("training/update_training", data)
        .then((res) => {
          store.addNotification({
            title: this.props.languagePack["training_add_title"],
            message: this.props.languagePack["training_success"],
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
          this.setState({ error: "" });
          this.props.history.push("/training/plan");
        })
        .catch((err) => console.log(err));
    } else {
      this.setState({
        error: this.props.languagePack["all_fields_mandatory_error"],
      });
    }
    // } else {
    //   this.setState({
    //     error: this.props.languagePack["no_sections_added_error"],
    //   });
    // }
  };

  submitTrainingMobile = () => {
    const data = new FormData();
    const tableData = this.state.table_row_sections.map(
      ({
        value: id,
        label: content,
        duration: value,
        effective_duration: effective_duration,
      }) => ({
        id: id == "" ? "-1" : id,
        content,
        value: value ? value : "",
        effective_duration: effective_duration ? effective_duration : "",
      })
    );

    data.append("id_user", this.props.currentUser.id_user);
    data.append("id_training", this.props.match.params.id);
    data.append("id_team", JSON.stringify(this.state.team.value));
    data.append("id_club", this.props.currentUser.id_club);
    data.append(
      "date",
      moment(this.state.date_start_mobile).format("YYYY-MM-DD")
    );
    data.append(
      "start_hour",
      moment(this.state.date_start_mobile).format("HH:mm")
    );
    data.append("end_hour", moment(this.state.date_end_mobile).format("HH:mm"));
    data.append("description", this.state.description);
    data.append("sections", JSON.stringify(tableData));

    if (this.state.venue != null) {
      data.append("location", this.state.venue.value);
    }

    // if (this.state.table_row_sections.length > 0) {
    if (this.state.date_start_mobile && this.state.date_end_mobile) {
      axios
        .post("training/update_training", data)
        .then((res) => {
          store.addNotification({
            title: this.props.languagePack["training_add_title"],
            message: this.props.languagePack["training_success"],
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
          this.setState({ error: "" });
          this.props.history.push("/training/plan");
        })
        .catch((err) => console.log(err));
    } else {
      this.setState({
        error: this.props.languagePack["all_fields_mandatory_error"],
      });
    }
    // } else {
    //   this.setState({
    //     error: this.props.languagePack["no_sections_added_error"],
    //   });
    // }
  };

  render() {
    const { languagePack } = this.props;
    return (
      <>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div className="row">
            <div className="col-lg-6 px-0">
              <TFBCard className="edit-plan-training-container">
                <TFBCardHeader title={languagePack.edit_training} />
                <div className="add-training-row">
                  <div style={{ marginBottom: 15 }}>
                    <TFBSelect
                      placeholder="Selecteaza echipa/echipele"
                      value={this.state.team}
                      isDisabled={true}
                    />
                  </div>
                </div>
                <div className="plan-training-subtitle pt-10">
                  {languagePack["add_training_sections"]}
                </div>
                <div className="mobile-edit-training-table">
                  {this.state.table_row_sections.length > 0 && (
                    <TableContainer>
                      <Table aria-label="simple table" sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TFBTableCell>{"Nume echipa"}</TFBTableCell>
                            <TFBTableCell align="center">
                              {languagePack.duration}
                            </TFBTableCell>
                            <TFBTableCell align="center">
                              {languagePack.effective_duration}
                            </TFBTableCell>
                            <TFBTableCell align="right">
                              {languagePack.product_actions}
                            </TFBTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.table_row_sections.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                backgroundColor:
                                  index % 2 == 0 ? "#fafafa" : "white",
                              }}
                            >
                              <TFBTableCell
                                component="th"
                                scope="row"
                                style={{
                                  backgroundColor:
                                    index % 2 == 0 ? "#fafafa" : "white",
                                }}
                              >
                                {row.label}
                              </TFBTableCell>

                              <TFBTableCell align="center">
                                <Input
                                  placeholder={languagePack.duration_minutes}
                                  className={`final-drop-row-input`}
                                  onChange={(e) =>
                                    this.handleChangeMobileInput(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  value={
                                    this.state.table_row_sections[index]
                                      .duration ?? ""
                                  }
                                />
                              </TFBTableCell>

                              <TFBTableCell align="center">
                                <Input
                                  placeholder={languagePack.duration_minutes}
                                  className={`final-drop-row-input ${row.wrongEffectiveDuration &&
                                    "wrong-effective-duration"}`}
                                  onChange={(e) =>
                                    this.handleChangeMobileEffectiveDur(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  value={
                                    this.state.table_row_sections[index]
                                      .effective_duration ?? ""
                                  }
                                />
                              </TFBTableCell>

                              <TFBTableCell align="right">
                                <div className="plan-training-actions-wrapper">
                                  <TFBIconButton
                                    name="x-small"
                                    onClick={() => this.removeRowFromTable(row)}
                                    color="darkGray"
                                  />
                                </div>
                              </TFBTableCell>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TFBTableCell colSpan={3}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: "0px 25px",
                                }}
                              >
                                <div>
                                  {languagePack.total_training_duration}
                                </div>
                                <div>
                                  {" "}
                                  {this.convertToHrsMin(
                                    this.state.currentTotalTimeMobile
                                  )}
                                </div>
                              </div>
                            </TFBTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  {this.state.open_add_section && (
                    <div style={{ marginBottom: 15, marginTop: 15 }}>
                      <TFBButton
                        onClick={() => {
                          this.setState({
                            section_to_add: true,
                            open_add_section: false,
                          });
                        }}
                        endIcon="true"
                        color="green"
                        renderIcon={() => <TFBIcon name="add" />}
                        style={{ width: "100%" }}
                      >
                        {languagePack.add_section}
                      </TFBButton>
                    </div>
                  )}

                  {this.state.section_to_add && (
                    <>
                      <div style={{ marginBottom: 15, marginTop: 15 }}>
                        <TFBSelect
                          placeholder={languagePack.add_section}
                          options={this.state.sectionOptions}
                          onChange={(selected) => {
                            this.setState({
                              selectedSection: selected,
                            });
                          }}
                          onBlur={(e) => {
                            if (e.target.value.length > 0) {
                              this.setState({
                                selectedSection: {
                                  value: "",
                                  label: e.target.value,
                                },
                              });
                            }
                          }}
                          value={this.state.selectedSection}
                        />
                      </div>

                      <div style={{ marginBottom: 15, marginTop: 15 }}>
                        <TFBButton
                          onClick={this.addSectionToTable}
                          endIcon="true"
                          color="green"
                          renderIcon={() => <TFBIcon name="add" />}
                          style={{ width: "100%" }}
                          disabled={
                            this.state.section_to_add &&
                            this.state.selectedSection == ""
                          }
                        >
                          {languagePack.add_section}
                        </TFBButton>
                      </div>
                    </>
                  )}
                </div>

                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className="plan-training-final-drop-area desktop-edit-training-droppable"
                    >
                      <div className="sections-list">
                        {this.state.items.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="plan-training-final-drop-row"
                              >
                                <div className="final-drop-row-name">
                                  {item.content}
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <TFBIconButton
                                      name="edit"
                                      onClick={() =>
                                        this.setState({
                                          tacticBoardId: item.id,
                                        })
                                      }
                                      color="darkGray"
                                    />

                                    <TFBIconButton
                                      name="x-small"
                                      onClick={() =>
                                        this.removeElementFromList(item.id)
                                      }
                                      color="darkGray"
                                    />
                                  </div>
                                </div>
                                <input
                                  className="final-drop-row-input"
                                  placeholder={languagePack.duration_minutes}
                                  value={item.value}
                                  onFocus={() =>
                                    this.setState({ isWritingInput: item.id })
                                  }
                                  onChange={this.handleChangeInput}
                                ></input>
                                <input
                                  className={`final-drop-row-input ${item.wrongEffectiveDuration &&
                                    "wrong-effective-duration"}`}
                                  placeholder={languagePack.effective_duration}
                                  value={item.effective_duration}
                                  onFocus={() =>
                                    this.setState({ isWritingInput: item.id })
                                  }
                                  onChange={this.handleChangeInputEffective}
                                ></input>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                      {provided.placeholder}
                      <div className="final-drop-area-total-row">
                        <div className="total-text">
                          {languagePack["total_training_duration"]}
                        </div>
                        <div className="total-value">
                          {this.convertToHrsMin(this.state.currentTotalTime)}
                        </div>
                      </div>
                    </div>
                  )}
                </Droppable>
                <div className="plan-training-subtitle pt-10">
                  {languagePack["training_details"]}
                </div>
                <div className="add-training-row">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="plan-training-row">
                      <DatePicker
                        value={this.state.date_start}
                        onChange={this.handleDateChange}
                        emptyLabel={languagePack["training_date"]}
                        format="dd.MM.yyyy"
                        inputVariant="outlined"
                        className="plan-training-date-input"
                      />
                      <TimePicker
                        showTodayButton
                        ampm={false}
                        emptyLabel={languagePack["start_hour"]}
                        value={this.state.date_start}
                        minutesStep={5}
                        inputVariant="outlined"
                        className="plan-training-hour-input training-start-hour-desktop"
                        onChange={this.handleHourStartChange}
                      />
                      {/* Training Hour start mobile */}
                      <TimePicker
                        showTodayButton
                        ampm={false}
                        emptyLabel={languagePack["start_hour"]}
                        value={this.state.date_start_mobile}
                        minutesStep={5}
                        inputVariant="outlined"
                        className="plan-training-hour-input training-start-hour-mobile"
                        onChange={this.handleHourStartChangeMobile}
                      />
                      {/* &&&&&&&&&&&&&&&&&&&&& */}

                      <TimePicker
                        showTodayButton
                        ampm={false}
                        emptyLabel={languagePack["end_hour"]}
                        value={this.state.date_end}
                        minutesStep={5}
                        inputVariant="outlined"
                        className="plan-training-hour-input training-end-hour-desktop"
                        onChange={this.handleHourEndChange}
                      />

                      {/* Training Hour end mobile */}
                      <TimePicker
                        showTodayButton
                        ampm={false}
                        emptyLabel={languagePack["end_hour"]}
                        value={this.state.date_end_mobile}
                        minutesStep={5}
                        inputVariant="outlined"
                        className="plan-training-hour-input training-end-hour-mobile"
                        onChange={this.handleHourStartChangeMobile}
                      />
                      {/* &&&&&&&&&&&&&&&&&&&&& */}
                    </div>
                  </MuiPickersUtilsProvider>
                </div>
                {this.state.date_end &&
                  this.state.date_start &&
                  this.state.date_end < this.state.date_start && (
                    <div className="plan-training-error-row add-training-hours-error-desktop">
                      {languagePack["hours_error_training"]}
                    </div>
                  )}
                {this.state.date_end_mobile &&
                  this.state.date_start_mobile &&
                  this.state.date_end_mobile < this.state.date_start_mobile && (
                    <div className="plan-training-error-row add-training-hours-error-mobile">
                      {languagePack["hours_error_training"]}
                    </div>
                  )}
                <div className="add-venues-dropdown">
                  <TFBSelect
                    placeholder={languagePack.select_match_venue}
                    value={this.state.venue}
                    options={this.state.venues}
                    onChange={(selected) => this.setState({ venue: selected })}
                    isClearable={true}
                  />
                </div>
                <TextArea
                  placeholder={languagePack["training_description_details"]}
                  name="training_details"
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                  className="plan-training-text-area"
                  style={{ marginTop: "12px", marginBottom: "12px" }}
                />
                {this.state.error != "" && (
                  <div className="plan-training-error-row">
                    {this.state.error}
                  </div>
                )}
                <div
                  className="add-training-row"
                  style={{ marginTop: this.state.error == "" ? 65 : 15 }}
                >
                  <TFBButton
                    className="submit-add-training-desktop"
                    style={{ width: "100%" }}
                    onClick={this.submitTraining}
                    color="green"
                    disabled={
                      // this.state.items.length == 0 ||
                      this.state.date_start == null ||
                      this.state.date_end == null
                    }
                  >
                    {languagePack["save_training"]}
                    <TFBIcon name="check" />
                  </TFBButton>

                  <TFBButton
                    className="submit-add-training-mobile"
                    style={{ width: "100%" }}
                    onClick={this.submitTrainingMobile}
                    color="green"
                    disabled={
                      // this.state.table_row_sections.length == 0 ||
                      this.state.date_start_mobile == null ||
                      this.state.date_end_mobile == null
                    }
                  >
                    {languagePack["save_training"]}
                    <TFBIcon name="check" />
                  </TFBButton>
                </div>
              </TFBCard>
            </div>
            <div className="col-lg-6 desktop-edit-training-draggable">
              <TFBCard className="edit-plan-training-container">
                <TFBCardHeader title={languagePack["training_sections"]}>
                  <TFBButton
                    color="green"
                    onClick={() =>
                      this.setState({ addSection: !this.state.addSection })
                    }
                  >
                    {languagePack["create_new_section"]}
                    <TFBIcon name="add" />
                  </TFBButton>
                </TFBCardHeader>
                <TFBCardBody>
                  {this.state.addSection && (
                    <div className="add-section-row">
                      <input
                        value={this.state.added_section_name}
                        onChange={(e) =>
                          this.setState({ added_section_name: e.target.value })
                        }
                        placeholder={languagePack["add_new_section_name"]}
                        className="add-section-input"
                      />
                      <div className="actions-btns-container">
                        <TFBIconButton
                          name="add"
                          color="green"
                          onClick={this.createNewSection}
                        />
                        <TFBIconButton
                          name="cancel"
                          color="darkGray"
                          onClick={() =>
                            this.setState({
                              addSection: false,
                              added_section_name: "",
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                  <Droppable
                    droppableId="droppable2"
                    isDropDisabled
                    direction="horizontal"
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        className="existing-sections-list"
                      >
                        {this.state.selected.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="existing-section-container"
                              >
                                {item.content}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </TFBCardBody>
              </TFBCard>
            </div>
          </div>
        </DragDropContext>
        <Dialog
          open={this.state.tacticBoardId !== ""}
          onClose={() => this.setState({ tacticBoardId: "" })}
          maxWidth="xl"
        >
          <DialogContent>
            <TacticBoard
              closePopup={() => this.setState({ tacticBoardId: "" })}
              section_id={this.state.tacticBoardId}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(EditTraining);
