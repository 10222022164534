import React, { useState, useEffect } from "react";
import "./../MatchDetails.scss";
import "./MatchSquad.scss";
import { connect } from "react-redux";
import TFBCard from "../../../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../../components/design-system/Icon/TFBIcon";
import TFBPlaceholder from "../../../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useParams } from "react-router-dom";
import {
  TFBTableCell,
  sxStickyColumn,
} from "./../../../../../components/reusable/useStyles";
import { useHistory } from "react-router-dom";
import TFBIconButton from "../../../../../components/design-system/IconButton/TFBIconButton";
import AddPlayerPopup from "./AddPlayerPopup";
import DeletePlayerPopup from "./DeletePlayerPopup";
import TFBPlayerWithAvatar from "../../../../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";
import TFBInput from "../../../../../components/design-system/Input/TFBInput";
import { store } from "react-notifications-component";

function MatchSquad(props) {
  const { languagePack, idMatch } = props;
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [players, setPlayers] = useState([]);
  const [addPlayerPopup, setAddPlayerPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [idPlayerToDelete, setIdPlayerToDelete] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    getSquadList();
  }, []);

  //   ================ GET DATA =============================
  //==========================================================

  const getSquadList = () => {
    axios
      .get(
        `match/get_match_squad?id_match=${idMatch}&token=${props.currentUser.token}`
      )
      .then((res) => {
        setPlayers(res.data.list);
        setIsLoaded(true);
      })
      .catch((err) => console.log(err));
  };

  //   ================ SAVE CHANGES =============================
  //==============================================================

  const saveMatchSquad = () => {
    let playersList = [];
    [...players].forEach((player) => {
      playersList.push({
        id_manual_match_player: player.id_manual_match_player,
        match_comment: player.match_comment
          ? String(player.match_comment).trim()
          : "",
      });
    });

    const payload = { players: playersList };

    axios
      .post("match/update_match_squad_details", payload)
      .then((res) => {
        if (res.status == 200) {
          getSquadList();
          store.addNotification({
            title: languagePack.match_pl,
            message: languagePack.the_match_lot_was_successfully_saved,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: languagePack.match_pl,
            message: languagePack.fail_the_match_lot_was_successfully_saved,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <TFBCard>
      {/* ============= CARD HEADER ================ */}
      {/* ========================================================== */}

      <TFBCardHeader title={languagePack.match_squad}>
        <TFBButton
          color="green"
          endIcon={true}
          renderIcon={() => <TFBIcon name="add" />}
          onClick={() => {
            setAddPlayerPopup(true);
          }}
        >
          {languagePack.add_player}
        </TFBButton>
      </TFBCardHeader>

      {/* ============= CARD BODY ================ */}
      {/* ========================================================== */}

      <TFBCardBody>
        {isLoaded && players.length > 0 ? (
          <div className="match-squad-content">
            <TableContainer>
              <Table aria-label="simple table">
                {/* ===============  TABLE HEADER ============== */}
                {/* ========================================================== */}

                <TableHead>
                  <TableRow>
                    <TFBTableCell style={{ maxWidth: "800px" }}>
                      {languagePack.player}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.remarks}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.presence}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.product_actions}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>

                {/* ===============  TABLE CONTENT ============== */}
                {/* ========================================================== */}
                <TableBody>
                  {players.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      <TFBTableCell
                        component="th"
                        scope="row"
                        style={{
                          minWidth: "200px",
                          width: "50%",
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        <TFBPlayerWithAvatar
                          playerName={row.player_name}
                          avatarUrl={row.img_url}
                          onClick={() =>
                            history.push("/club/player/" + row.id_player)
                          }
                        />
                      </TFBTableCell>
                      <TFBTableCell align="center" style={{ width: "30%" }}>
                        <div className="player-observations">
                          <TFBInput
                            className="text-input"
                            placeholder={languagePack.add_remarks}
                            name="remark"
                            value={row.match_comment || ""}
                            onChange={(e) => {
                              let data = [...players];
                              data[index].match_comment = e.target.value;
                              setPlayers(data);
                            }}
                          />
                        </div>
                      </TFBTableCell>
                      <TFBTableCell
                        align="center"
                        style={{ minWidth: "127px", width: "20%" }}
                      >
                        <TFBIcon
                          name={row.will_attend == 1 ? "check" : "x-small"}
                          color={row.will_attend == 1 ? "green" : "black"}
                        />
                      </TFBTableCell>
                      <TFBTableCell>
                        <div className="actions-btns-container">
                          <TFBIconButton
                            name="delete"
                            color="darkGray"
                            onClick={() => {
                              setOpenDeletePopup(true);
                              setIdPlayerToDelete(row.id_player);
                            }}
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div className="save-match-squad">
              <TFBButton
                color="green"
                onClick={saveMatchSquad}
                renderIcon={() => <TFBIcon name="check" />}
              >
                {languagePack.save_match_squad}
              </TFBButton>
            </div>
          </div>
        ) : (
          //============= NO DATA PLACEHOLDER ===============
          //=================================================
          <TFBPlaceholder text={languagePack.no_players} />
        )}
      </TFBCardBody>

      {/* ===============  Add player to squad popup ================ */}
      {/* ========================================================== */}

      <AddPlayerPopup
        languagePack={languagePack}
        closePopup={() => setAddPlayerPopup(false)}
        isOpenPopup={addPlayerPopup}
        token={props.currentUser.token}
        id_match={id}
        id_user={props.currentUser.id_user}
        refreshSquad={getSquadList}
      />

      {/* ================ DELETE PLAYER POPUP =====================*/}
      {/* ========================================================== */}

      <DeletePlayerPopup
        closePopup={() => {
          setOpenDeletePopup(false);
          setIdPlayerToDelete("");
        }}
        openDeletePopup={openDeletePopup}
        languagePack={languagePack}
        id_match={id}
        id_player_to_delete={idPlayerToDelete}
        refreshSquad={getSquadList}
      />
    </TFBCard>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(MatchSquad);
