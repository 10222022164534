export default {
  //no data placeholder
  no_data_activ_contract: "Nu are nici un contract activ",
  no_data_active_license: "Nu are licență activă",

  // Video Gallery
  add_video: "Adauga video",
  video_description: "Descriere video",
  upload_video: "Incarca video",
  video_title: "Titlu video",

  assist: "Pasa de gol",
  delete_tag: "Ștergere tag",
  sure_delete_tag: "Ești sigur că vrei să ștergi acest tag ?",
  video_event: "Evenimente video",
  select_all: "Selectează toate",
  starting_moment: "Moment început",
  ending_moment: "Moment sfârșit",
  event_type: "Tip eveniment",
  tag_title: "Titlu etichetă",
  tag_details: "Detalii etichetă",
  enter_details: "Introdu detalii...",
  search_video: "Caută video",
  add_summary_title: "Introdu titlu rezumat",
  create_summary: "Creează rezumat",

  vide_time_input_error:
    "Ai introdus o valoare mai mare decât durata videoclipului",
  date_time_difference_error:
    "Valoarea de la Moment început trebuie să fie mai mică decât valoarea de la Moment sfârșit",
  success_video_added: "Eticheta a fost adăugată cu scces !",
  failed_video_added: "Eticheta nu a fost adăugată !",
  success_video_edited: "Eticheta a fost editată cu scces !",
  failed_video_edited: "Eticheta nu a fost editată !",

  // Campaigns
  campaign_title: "Campanii",
  campaign_type: "Tip",
  campaign_status: "Status",
  campaign_audience: "Audienta",
  campaign_last_edited: "Ultima editata",
  campaign_opens: "Deschise",
  campaign_clicks: "Accesate",
  campaign_status_filter: "Filtreaza dupa status",
  campaign_type_filter: "Filtreaza dupa tip",
  campaign_pagination: "Rezultate pe pagina",
  campaign_your_audience: "Publicul tau",
  campaign_choose_audience: "Alege publicul",
  campaign_new_segment: "Segment nou",
  campaign_add_new_condition: "Adauga o noua conditie",
  campaign_save_segment: "Salveaza segmentul",
  campaign_sender: "Expeditor",
  campaign_name: "Nume",
  campaign_subject: "Subiect",
  campaign_enter_subject: "Introduceti subiectul",
  campaign_content: "Continut",
  campaign_main_title: "Titlu principal",
  campaign_preview_article: "Previzualizare articol",
  campaign_schedule_campaign: "Programare campanie",
  campaign_send_later: "Trimite mai tarziu",
  campaign_send_test_message: "Trimite mesaj de test",
  campaign_save_message: "Salvare mesaj",
  campaign_save_as_template: "Salvare sablon",
  campaign_cancel: "Anulare",
  campaign_is_before: "Este inainte de",
  campaign_is_after: "Este dupa",
  campaign_specific_date: "O data specifica",
  campaign_range: "Un interval",
  campaign_contact_must_match: "Contactul trebuie sa se potriveasca",
  campaign_gender: "Gen",
  campaign_birthday: "Data nasterii",
  campaign_date_added: "Data adaugata",
  campaign_view_email: "Vezi email",
  campaign_download: "Descarca",
  campaign_print: "Printeaza",
  campaign_top_links: "Top link-uri accesate",
  campaign_view_all_links: "Vezi toate link-urile",
  campaign_recipients: "Destinatari",
  campaign_total_recipients: "Total destinatari",
  campaign_total_opens: "Deschise",
  campaign_total_clicked: "Accesate",
  campaign_total_bounced: "Respinse",
  campaign_total_unsubscribed: "Dezabonati",
  campaign_delivered: "Trimis",
  email_campaign: "Campanie prin e-mail",
  campaign_report: "Raport campanie",
  campaign_view_all: "Vezi tot",
  campaign_subscribers_most_opens: "Abonati cu cele mai multe deschideri",
  campaign_numbers_stats: "Numere si statistici",
  campaign_abuse_reports: "Raportari pentru abuz",
  campaign_last_clicked: "Ultima accesare",
  campaign_total_clicks: "Total accesari",
  campaign_clicks_per_unique_opens: "Numar de clicuri per deschideri unice",
  campaign_forwarded: "Redirectionate",
  campaign_last_opened: "Ultima deschidere",
  campaign_total_opens: "Total deschideri",
  campaign_successful_deliveries: "Trimise cu success",

  //Fans section
  tax_redirectors_title: "Redirectionari impozit pe profit",
  tax_redirectors_total: "Total redirectionari",
  tax_redirector_name: "Nume si prenume",
  tax_redirector_register_date: "Data inscrierii",
  tax_redirector_email: "Adresa de e-mail",
  tax_redirector_phone_number: "Număr de telefon",
  incorect_phone_number: "Număr de telefon incorect",

  //Administrare site
  about_us_title: "Despre  noi",
  upload_main_image: "Incarca imagine principala",
  upload_another_main_image: "Incarca alta imagine principala",
  sections: "Sectiuni",
  section: "Sectiune",
  admin_add: "Adauga",
  admin_cancel: "Anuleaza",
  admin_delete: "Sterge",
  upload_image: "Incarca imagine",
  upload_other_image: "Incarca alta imagine",
  admin_save: "Salvează",
  admin_cancel_modif: "Anuleaza modificari",
  admin_preview: "Preview rubrica",
  gallery_title: "Preview galerie foto",
  gallery_photos_title: "Galerie foto",
  gallery_videos_title: "Galerie video",
  gallery_bio_title: "Biografie",
  gallery_add_button: "Incarca imagine / imagini",
  gallery_add_button_images: "Incarca imagine / imagini",
  gallery_add_button_videos: "Incarca video / video-uri",
  gallery_add_button_bio: "Incarca / editeaza biografia",
  gallery_placeholder: "Momentan nu ai incarcat nicio imagine.",
  gallery_videos_placeholder: "Momentan nu ai incarcat niciun video.",
  gallery_bio_placeholder: "Momentan nu ai incarcat nicio biografie.",
  file_remove_success_title: "Fisierul a fost sters cu succes.",
  general_error_message:
    "A aparut o eroare, va rugam sa incercati din nou mai tarziu.",

  partners_title: "Parteneri & sponsori",
  add_partner_button: "Adauga partener",
  sponsor_name_form: "Nume sponsor",
  sponsor_site_form: "Link catre site",
  sponsor_logo_form: "Incarca logo (.png)",
  sponsor_site_notif_title: "Gestionare parteneri",
  sponsor_site_notif_success: "Partenerul a fost adaugat cu succes",
  sponsor_site_notif_fail: "Gestionare parteneri",
  delete_partner: "Sterge partener",
  delete_partner_popup_text: "Esti sigur ca vrei sa elimini acest partner?",
  admin_confirm: "Confirma",
  delete_sponsor_notif_text: "Partenerul a fost eliminat cu succes",

  admin_contact_title: "Date de contact",
  admin_contact_phone: "Număr de telefon",
  admin_contact_address: "Adresa",
  admin_contact_org_name: "Nume organizatie",
  admin_contact_save_changes: "Salveaza modificarile",
  admin_contact_notif_title: "Gestionare date contact",
  admin_contact_notif_text_success: "Modificarile au fost salvate cu succes!",

  gallery_delete_popup_title: "Sterge imagine",
  gallery_delete_popup_title_video: "Sterge video",
  gallery_delete_popup_text: "Esti sigur ca vrei sa stergi aceasta imagine?",
  gallery_delete_popup_text_video: "Esti sigur ca vrei sa stergi acest video?",
  upload_images_popup_title: "Incarca imagini",
  upload_videos_popup_title: "Incarca videouri",
  gallery_upload_select_file: "Selecteaza fisier",
  gallery_upload_select_files: "Selecteaza fisiere",
  gallery_upload_nr_singular: " imagine incarcata",
  gallery_upload_nr_plural: "imagini incarcate",
  gallery_notification_title: "Galerie foto-video",
  gallery_notification_success: "Fisierele au fost incarcate cu succes!",
  gallery_notification_fail: "Fisierele nu au fost incarcate cu succes!",
  gallery_notification_partial_upload:
    "Fisierele au fost incarcate partial cu succes!",
  gallery_notification_max_size_upload:
    "Dimensiunea maxima a fisierului este 2 GB!",

  player_gallery_no_images: "Nu sunt imagini.",
  player_gallery_no_videos: "Nu sunt videouri.",
  player_gallery_delete_file_success: "Fisierul a fost sters cu succes.",
  player_gallery_delete_file_fail: "Fisierul nu a fost sters cu succes.",

  edit_biography: "Editeaza biografie",

  news_page_title: "Ultimele stiri adaugate",
  news_page_add_button: "Adauga stire",
  news_page_header_title: "Titlu",
  news_page_header_tag: "Tag",
  news_page_header_date: "Data publicarii",
  news_page_header_options: "Optiuni",

  news_page_delete_popup_title: "Sterge articol",
  news_page_delete_popup_text:
    "Esti sigur ca doresti sa elimini aceasta stire? Actiunea este ireversibila.",

  add_news_title: "Adauga stire",
  add_news_article_title: "Titlu stire",
  add_news_publish_button: "Publica articol",
  add_news_cancel_button: "Anuleaza articol",
  article_preview: "Preview articol",

  edit_news_title: "Editeaza stire",
  edit_news_delete_button: "Sterge articol",
  edit_section: "Editeaza sectiune",

  //Medical

  expired: "Expirat",
  medical_certificate: "Adeverință medicală",
  expires_in_days: "Expira in (zile)",
  visa_expiration_date: "Dată expirare viză",
  date_of_examination: "Dată examinare",
  medical_certificate: "Certificat medical",
  medical_certificates: "Certificate medicale",
  no_medical_certificates: "Nu sunt certificate medicale.",
  add_medical_certificate: "Adaugă certificat",
  date_of_certificate_examination: "Dată examinare adeverință",
  expiry_date_examination: "Dată expirare adeverință",
  proof_of_medical_certificate: "Dovadă adeverință medicală",
  date_of_medical_tests_examination: "Dată examinare analize medicale",
  expiry_date_medical_tests: "Dată expirare analize medicale",
  proof_of_medical_tests: "Dovadă analize medicale",

  select_iamge: "Selectează imaginea",
  files: "Fisiere",
  file: "Fisier",

  active_injuries_title: "Accidentari curente",
  active_injuries_add_button: "Adauga accidentare",
  active_injuries_player_name: "Nume jucator",
  active_injuries_type: "Tip accidentare",
  active_injuries_injury_date: "Data accidentarii",
  active_injuries_estimated_recovery: "Revenire estimata",
  injury_details: "Detalii accidentare",
  add_injury_popup_title: "Adauga accidentare noua",
  injury_zone: "Zona",
  injury_date: "Data",
  edit_details: "Editeaza detalii",
  treatment: "Tratament",
  injury_edit: "Editează",
  add_treatment: "Adauga tratament",
  end_injury: "Incheiere accidentare",

  select_team: "Selecteaza echipa",
  select_team_teams: "Selecteaza echipa/echipele",
  select_player: "Selecteaza jucator",
  select_zone: "Selecteaza zona",
  select_evaluator: "Selecteaza evaluator",
  select_parameters_category: "Selectează categorie parametrii",
  categories_list: "Listă categorii",
  add_new_subdivision: "Adaugă o nouă sub-diviziune",
  add_new_subcategory: "Adaugă o nouă sub-categorie",
  selected_categories: "Categorii selectate",

  start_date: "Dată începere",
  end_date: "Dată încheiere",
  return_date: "Data revenirii",
  treatment_details: "Detalii tratament",

  ["Left shoulder"]: "Umăr stâng",
  ["Right shoulder"]: "Umăr drept",
  ["Left knee"]: "Genunchi stâng",
  ["Right knee"]: "Genunchi drept",
  ["Left thigh"]: "Coapsă stângă",
  ["Right thigh"]: "Coapsă dreaptă",
  ["Left shin"]: "Tibie stângă",
  ["Right shin"]: "Tibie dreaptă",
  ["Back"]: "Spate",
  ["Head"]: "Cap",
  ["Left ankle"]: "Gleznă stângă",
  ["Right ankle"]: "Gleznă drept",
  ["Another zone"]: "Alta zona",

  injuries_history: "Istoric accidentari",
  absence_period: "Perioada de absenta",
  no_treatment_found: "Nu a fost gasit niciun tratament",
  total_treatment_cost: "Cost total tratamente",
  injury_date: "Data",
  injury_day: "zi",
  injury_days: "zile",
  total_cost: "Cost total",
  no_injuries_found: "Nicio accidentare inregistrata in perioada selectata",
  injury_change_success: "Accidentarea selectata a fost modificata cu succes!",
  injury_close_success: "Accidentarea a fost incheiata la data selectata",
  injury_delete_success: "Accidentarea selectata a fost eliminata cu succes!",
  treatment_delete_success: "Tratamentul selectat a fost eliminat cu succes!",
  injury_management_title: "Gestionare accidentari",
  injury_added_success: "Accidentarea a fost adaugata cu succes",
  treatment_edit_success: "Tratamentul selectat a fost editat cu succes!",
  cost: "Cost",

  //CLUB - Staff members

  license_file: "Fișier licență",
  select_license: "Selectează licența",
  without_license: "Nicio licență",
  designation: "Denumire",
  subcategory_designation: "Denumire subcategorie",
  type: "Tip",
  dimension: "Dimensiune",
  added_at: "Adăugat la",

  add_certificate: "Adaugă adeverință",
  add_medical_tests: "Adaugă buletin de analize",

  staff_members: "Membri staff",
  roles_list: "Lista roluri",
  add_member: "Adauga membru",
  staff_member_name: "Nume",
  staff_member_role: "Rol",
  edit_profile: "Editeaza profil",
  staff_full_name: "Nume si prenume",
  staff_description: "Descriere",
  edit_staff_member_popup_title: "Profil membru staff",
  edit_staff_member_popup_message: "Detaliile au fost salvate cu succes!",
  delete_staff_member_message: "Profilul a fost sters cu succes!",
  popup_delete_staff_member_title: "Stergere profil",
  popup_delete_staff_member_message:
    "Sigur doriti sa eliminati acest profil? Actiunea este ireversibila.",
  add_new_role: "Adauga rol nou",
  add_role: "Adauga rol",

  staff_license: "Licență",
  manage_role_notification_title: "Gestionare roluri in club",
  manage_role_notification_succes: "Rolul a fost adaugat cu succes!",
  manage_role_notification_fail: "Rolul selectat a fost eliminat cu succes!",

  active_teams: "Echipe active",
  add_team: "Adauga echipa",
  no_teams_yet: "Clubul nu are momentan nicio echipa",
  team_type: "Categorie",
  team_name: "Nume echipa",
  team_logo: "Logo echipa",
  number_players: "Numar de jucatori",
  team_coach: "Antrenor",
  team_coordinator: "Coordonator/Antrenor",

  teams_notification_title: "Management echipe",
  teams_notification_success: "Echipa a fost adaugata cu succes!",

  //Team players list

  edit_training: "Editează antrenament",
  add_player_to_training: "Adaugă jucator la antrenament",
  change_instat_id: "Modificare ID Instat pentru ",
  no_records: "Nu are înregistrări",
  keep_it_in_previous_teams: "Păstrează-l și în echipele anterioare",
  add_player_to_another_team: "Adaugă jucatorul la o altă echipă",
  fifa_id: "Fifa ID",
  remarks: "Observații",
  add_remarks: "Introdu observație",
  mark: "Nota",
  mark_for_all: "Nota pentru toti",
  was_present: "Prezent",
  training_statistics: "Statistici antrenament",
  all_present: "Toți prezenți",
  no_video_events: "Nu are evenimente video",
  player_video_clips: "Fragmente video ale jucătorului",
  add_document: "Adăugare document",
  activity_type: "Tip activitate",
  age_group: "Grupa de vârstă",
  national_team: "Echipa națională",
  call_up_date: "Dată convocare",
  select_national_team: "Selectează naționala",
  no_national_team_call_up: "Nu are convocari",
  add_national_team_call_up: "Adăugare convocare",
  add_national_team_call_up_popup: "Adaugă convocare la echipa națională",
  national_team_calls: "Convocări la națională",
  delete_career_record: "Șterge înregistrare carieră",
  delete_national_call_up: "Șterge convocare națională",
  delete_national_call_up_message:
    "Actiunea este ireversibila. Esti sigur/sigura ca vrei sa stergi inregistrarea din cariera pentru ",
  for_competition: " pentru competiția ",
  no_data_evaluation: "Jucătorul nu are evaluări",
  transaction_type: "Tip tranzacție",
  payment_history: "Istoric plăți",
  medical_visa: "Vize medicale",
  no_data_medical_visa: "Nicio viza medicala adaugata in sezonul selectat",
  key_pass: "Pase decisive",
  score: "Scor",
  last_matches_stats: "Statistici ultimele meciuri",
  add_cost: "Adăugare cost",
  cost_history: "Istoric costuri",
  change_career: "Modificare carieră",
  legitimation_period: "Perioada legitimare",
  club_name: "Club",
  add_in_career: "Adăugare în carieră",
  career: "Carieră",
  has_professional_license_issued: "Are licență profesionistă emisă",
  identity_card_id: "CNP",
  team_players: "Jucatori",
  team_performance: "Performanta echipei",
  add_player: "Adauga jucator",
  PLAYER_NAME: "Nume jucator",
  NATIONALITY: "Nationalitate",
  POSITION: "Pozitie",
  SECONDARY_POSITION: "Pozitie secundara",
  MAIN_FOOT: "Picior de baza",
  AGE: "Varsta",
  MARKET_VALUE: "Valoare de piata",
  average: "Media",
  age_years: "ani",
  minutes_played: "Minute jucate",
  no_players_placeholder: "Echipa nu are momentan niciun jucator",
  edit_team_name: "Editeaza nume echipa",
  birth_date: "Data nașterii",
  vfm:
    "VFM arata daca un jucator merita salariul pe care il castiga in prezent, comparandu-l cu performanta sa pe teren. Un indice ridicat inseamna ca un jucator castiga mai putin decat merita de fapt, comparativ cu nivelul echipei. Cu cat indicele este mai mic, cu atat este mai mare contrastul dintre performanta si castig.",
  pta:
    "PTA arata daca exista o corelatie intre numarul de minute jucate in timpul sezonului si performanta actuala pe teren. Cu cat este mai mic indexul, cu atat este mai mare contrastul dintre performanta si numarul de minute (un jucator care a jucat prea mult / prea putin).",
  instat_index:
    "InStat Index ajuta la determinarea clasei jucatorului pe baza unui algoritm automat care ia in considerare contributia acestuia la succesul echipei, semnificatia actiunilor sale, nivelul adversarului si nivelul campionatului in care joaca.",
  instat_index_title: "Instat index",
  on_loan: "Imprumutat la ",
  loaned: "Împrumutat",
  until_loan: " pana la ",

  //Player profile

  birthplace: "Locul nasterii",
  player_age: "Varsta",
  weight: "Greutate",
  height: "Înalțime",
  select_year: "Selecteaza an",
  section_access_forbidden: "Nu ai acces la aceasta sectiune",

  delete_player: "Ștergere jucator",
  delete_player_popup_text:
    "Esti sigur ca vrei sa elimini acest jucator din toate echipele? Actiunea este ireversibila.",
  change_player_team: "Schimba echipa jucator",
  select_new_team: "Selecteaza echipa noua",

  player_transactions_history: "Istoric tranzactii efectuate",
  transaction_description: "Descriere",
  transaction_date: "Data efectuarii",
  transaction_type: "Tip",
  trasnaction_amount: "Valoare",
  total_expenses: "Total cheltuieli",
  no_player_transactions: "Nu sunt plati.",

  contract_details: "Detalii contractuale",
  active_contract: "Contract activ",
  active_bonuses: "Bonusuri active",
  expiry_date: "Data expirarii",
  monthly_wage: "Salariu lunar",
  placeholder_bonuses: "Niciun bonus adaugat",
  shirt_number: "Numar pe tricou",
  move_player: "Schimba echipa",
  edit_photo: "Editeaza poza",

  //Aside main menu

  Club: "Clubul meu",
  dashboard: "Panou principal",
  trainings: "Antrenamente",
  Reports: "Rapoarte",
  finances: "Financiar",
  medical: "Medical",
  documents: "Documente",
  fans_relation: "Relatia cu suporterii",
  site_management: "Administrare site",
  admin_parent_app: "Administrare aplicație părinți",
  toggle_aside: "Închide/deschide fereastră",
  Users: "Utilizatori",
  shop: "Magazin",

  //Aside menu list

  financial_aside_title: "Financiar",
  reports_aside_title: "Rapoarte",

  player_contracts: "Contracte jucători",
  staff_contracts: "Contracte staff",
  injuries: "Accidentari",
  tax_redirectors: "Redirectionari impozit",
  news: "Stiri",
  cashflow: "Flux Numerar",
  transactions: "Tranzactii",
  sponsors: "Sponsori",
  clients: "Clienti",
  providers: "Furnizori",
  wages_costs: "Cheltuieli salariale",
  match_costs: "Cheltuieli de meci",
  suspension_status: "Situație suspendări",
  goals_stats: "Statistici goluri",
  ab_list: "Liste A/B",
  bonuses_monitoring: "Monitorizare bonusuri",
  costs: "Cheltuieli",
  edit_costs: "Editează cheltuială",
  online_payment_list: "Listă plați online",
  no_online_payments: "Nu sunt plati online.",
  necessity_reports: "Referate de necesitate",
  upload_bank_account_statements: "Încarcă extras de cont",
  upload_bank_account_statements_amount_column:
    "Selectează coloana pe care vrei să o asociezi cu sumele de plată:",
  upload_bank_account_statements_date_column:
    "Selectează coloana pe care vrei să o asociezi cu data tranzacției:",
  upload_bank_account_statements_payer_column:
    "Selectează coloana care conține numele plătitorului:",
  select_column: "Selectează coloană",

  receipts: "Chitanțe",
  my_list: "Lista mea",
  add_recurrent_training: "Adaugă antrenament recurent",

  presence: "Prezență",
  duration_minutes: "Durată (min)",
  duration: "Durată",
  confirmed_presence: "Prezență confirmată",
  plan_training: "Planifica antrenament",
  trainings_history: "Istoric antrenamente",
  weight_control: "Monitorizare greutate",
  training_sections: "Secțiuni antrenamente",
  attendance_report: "Raport prezență",
  show_presence: "Afișează prezență",

  physical_tests: "Teste fizice",
  add_physical_tests: "Adaugă test fizic",

  evaluation_reports: "Evaluări",
  teams: "Echipe",
  calendar: "Calendar",
  shop: "Shop",
  matches_schedule: "Program meciuri",
  competitions: "Competitii",

  end_recurrent: "Încheierea recurenței",
  start_recurrent: "Începerea recurenței",
  day_of_the_week: "Ziua săptămanii",
  select_venue: "Selectează terenul/terenurile",
  is_recurrent: "Este recurent",
  event_name: "Denumire eveniment",
  add_event_for_venue: "Adauga eveniment pentru teren",
  has_subdivison: "Are sub-diviziuni",
  subdivison_name: "Denumire sub-diviziune",
  venue_name: "Denumire teren",
  venue_list: "Listă terenuri",
  edit_training: "Editează teren",
  recurring_until: "Recurent până",
  add_venue: "Adaugă teren",
  delete_venue:
    "Acțiunea este ireversibilă. Ești sigur/sigură că vrei să ștergi terenul ",
  edit_category_list: "Editare listă categorii",
  venue_management: "Gestionare terenuri",
  contact_data: "Date de contact",
  social_media_connect: "Conectare social media",
  fans_list: "Lista suporteri",
  import_data: "Importa date",
  campaigns: "Campanii",
  categories: "Categorii",
  users_list: "Listă utilizatori",
  users_notifications: "Notificări utilizatori",
  edit_match_costs: "Editare cheltuieli meci",
  total_cost_match: "Total costuri",
  costs_categories: "Categorii de costuri",
  add_cost_category: "Adaugare categorie",
  add_new_category_name: "Introdu nume categorie noua",
  category_cost_sum: "Suma",
  save_edit_match_costs: "Salvare costuri meci",
  products: "Produse",
  orders: "Comenzi",
  categories: "Categorii",

  //Quick user
  change_password: "Schimba parola",
  your_currency: "Monedă selectată",
  no_club: "Niciun club",
  sign_out: "Delogare",
  user_profile: "Profil Utilizator",
  password_reset: "Resetare parola",
  account_settings: "Setari cont",
  password_success_text: "Parola a fost modificata cu succes",

  //Dashboard

  end_of_month_sold: "Sold la sfarsitul lunii",
  weekly_report: "Raport saptamanal",
  sum: "Suma",
  period: "Perioada",
  position: "Pozitie",
  ranking_points: "Punctaj",
  market_value_updates: "Modificari cota de piata",
  increase: "Crestere",
  current_value: "Valoare curenta",
  calendar_title: "Planificator evenimente",
  label_color: "Culoare eticheta",
  description: "Descriere",
  event_details: "Detalii eveniment",
  location: "Locatie",
  hour: "Ora",

  //Matches list

  no_matches: "Nu sunt meciuri.",
  reserve_referee: "Arbitru asistent",
  assistant_referee: "Arbitru asistent",
  central_referee: "Arbitru central",
  refrees: "Arbitrii",
  save_refrees: "Salvează arbitrii",
  no_data_documents: "Nu are documente",
  no_data_events: "Nu sunt evenimente",
  first_half: "Prima repriză",
  second_half: "A doua repriză",
  generate_match_report: "Generează raportul meciului",
  full_time_result: "Rezultat final",
  half_time_result: "Rezultat pauză",
  cost_category_list: "Listă categorii de costuri",
  edit_category_list: "Editare listă categorii",
  edit_match: "Editare meci",
  select_type_of_activity: "Selectează tip activitate",
  round: "Etapa",
  match: "Meciul",
  select_competition: "Selecteaza competitia",
  select_age_group: "Selecteaza grupa de varsta",
  select_group: "Selectează grupa",
  select_match_venue: "Selecteaza locatia",
  add_new_match_venue: "Adauga locatie noua",
  new_match_venue: "Locație meci",
  past_matches: "Meciuri trecute",
  future_matches: "Meciuri viitoare",
  match_events: "Evenimente meci",
  starting_lineup: "Echipele de start",
  substitutes: "Rezerve",
  add_event: "Adaugă eveniment",
  yellow_card: "Cartonas galben",
  red_card: "Cartonas rosu",
  substitution: "Substitutie",
  goal: "Gol",
  event_successfully_added: "Evenimentul a fost adaugat cu succes!",
  event_minute: "Minutul evenimentului",
  select_team: "Selecteaza echipa",
  select_event_type: "Selecteaza tipul evenimentului",
  select_match_type: "Selecteaza tipul meciului",
  select_player: "Selecteaza jucatorul",
  select_player_in: "Selecteaza jucatorul in",
  select_player_out: "Selecteaza jcuatorul out",
  select_goal_type: "Selecteaza tipul golului",
  single_goal: "Gol",
  goal_assist: "Gol + pasa de gol",
  select_scoring_player: "Selecteaza jucatorul marcator",
  select_assisting_player: "Selecteaza jucatorul pasator",
  player_lineup_successfully_added: "Jucator de start adaugat cu succes!",
  player_sub_successfully_added: "Jucator de rezerva adaugat cu succes!",
  players_match_rating: "Notare jucatori",
  no_players: "Nu sunt jucatori.",
  save_players_rating: "Salvare note jucatori",
  select_match_evaluation: "Selectează evaluarea meciului",
  pitch_condition: "Starea terenului",
  weather_condition: "Condiții meteo",
  game_play: "DESFĂȘURAREA JOCULUI (Puncte slabe / Puncte forte)",
  save_details: "Salvează detalii",

  //Providers components

  yellow: "Galbene",
  teams_from_competition: "Echipele din competiție",
  add_location_to_field_management:
    "Adaugă locația la managementul terenurilor",
  date_and_time_are_approximate: "Data și ora sunt aproximative",
  minutes_per_time: "Minute pe repriză",
  match_from_competition: "Din competiție",
  frendly_match: "Meci amical",
  current_season_stats: "Statistici in sezonul curent",
  match_sg: "Meci",
  match_pl: "Meciuri",
  starting_lineup_matches_sg: "Meci ca titular",
  starting_lineup_matches_pl: "Meciuri ca titular",
  goals_scored_sg: "Gol inscris",
  goals_scored_pl: "Goluri inscrise",
  clean_sheets_sg: "Meci fara gol primit",
  clean_sheets_pl: "Meciuri fara gol primit",
  yellow_cards_sg: "Cartonas galben",
  yellow_cards_pl: "Cartonase galbene",
  red_cards_sg: "Cartonas rosu",
  red_cards_pl: "Cartonase rosii",
  player_match_rating: "Nota",
  assist_sg: "Assist",
  assist_pl: "Assisturi",
  season_total: "Total sezon",
  season_avg: "Medie sezon",
  parameter_list: "Listă parametri",
  player_actions: "Acțiuni personale",
  select_match: "Selectează meci",
  attack: "Faza ofensivă",
  defence: "Faza defensivă",
  technical: "Tehnic",
  add_name: "Introdu numele",
  select_existing_partners: "Selecteaza din partenerii deja existenti",
  balance_at_end_month: "Balanta la finalul lunii",
  or_lowercase: "sau",
  supplier_operations: "Operatiuni furnizori",
  supplier_success: "Furnizorul a fost adaugat cu succes",
  add_supplier: "Adauga furnizor",
  //Trainings

  trainings_evolution: "Evolutie la antrenamente",
  physical_tracking_results: "Rezultate monitorizare fizica",
  heart_rate: "Puls",
  distance_covered: "Distanta parcursa",
  kcal_spent: "Calorii consumate",
  sprint_counter: "Nr sprinturi",
  speed: "Viteza",
  medium: "mediu",
  training_location: "Locație antrenament",
  trainings_history: "Istoric antrenamente",
  start_hour: "Ora inceperii",
  end_hour: "Ora incheierii",
  training: "Antrenament ",
  training_schedule: "Program antrenament",
  hours_interval: "Interval orar",
  optimal_weight: "Greutate optima",
  add_weight: "Adauga greutate",
  measure_date: "Data masurarii",
  weight_value: "Valoare",

  //Documents

  total_payment_at_present: "Total de plată (la momentul actual):",
  edit_player_contract: "Editare contract jucător",
  edit_staff_contract: "Editare contract staff",
  file_details: "Detalii fisier",
  upload_file: "Incarca fisier",
  add_new_category: "Adauga categorie noua",
  remove_contract: "Reziliaza contract",
  remove_contract_date: "Data rezilierii",
  remove_contract_confirm: "Confirma reziliere",
  active_contracts: "Contracte active",
  bonuses_list: "Lista bonusuri",
  add_contract: "Adauga contract",
  occupation: "Functie",
  choose_staff_member: "Alege membru staff",
  contract_bonuses: "Bonusuri contractuale",
  signing_date: "Data semnarii",
  add_contract_extra_bonuses_text:
    "Pentru a adauga conditii si prime suplimentare consultati sectiunea de bonusuri",
  select_condition: "Selecteaza conditie",
  add_condition_value: "Introdu valoare conditie",
  add_condition: "Adauga conditie",
  active_conditions: "Conditii active",
  bonus: "Bonus",

  // team settings
  team_management: "Administrare echipe",
  number_order: "Număr ordine",
  tax_value: "Valoare taxă",
  display_on_website: "Afișare pe site",
  display_for_online_payments: "Afișare la plăți online",
  save_settings: "Salvează setări",

  // team settings
  staff_management: "Administrare staff",

  // Sponsors
  sponsor_name: "Nume sponsor",

  //Matches

  match_date: "Data evenimentului",
  away_team: "Echipa oaspete",
  home_team: "Echipa gazda",
  round_number: "Numar etapa",
  round_number_name: "Numar/nume etapa",
  match_format: "Formatul meciului",
  add_match_format: "Introdu Formatul meciului",
  date_and_time_are_approximate: "Data și ora sunt aproximative",
  select_match_location: "Selectează locația meciului",
  add_match: "Adauga meci",
  match_successfully_added: "Meciul a fost adaugat cu succes!",
  competitions_actions: "Operatiuni competitii",
  new_team: "Echipa noua",
  clubs_team: "Echipa clubului",
  add_competition: "Adauga competitie",
  competition_name: "Nume competitie",
  competition_has_more_cat: "Competitia are mai multe categorii de varsta",
  number_of_groups: "Numarul de grupe",
  competition_type: {
    name: "Tipul competitiei",
    elements: {
      championship: "Campionat",
      knockout: "Faza eliminatorie",
      group_stage: "Grupe + faza eliminatorie",
    },
  },
  teams_number: "Numar de echipe",
  ranking: "Clasament",
  edit_team: "Editeaza echipa",
  points_short: "Pct.",
  manage_competitions: "Gestioneaza competitii",
  manage_competitions_success_changes: "Modificarile au fost salvate cu succes",
  manage_competitions_failed_changes: "Modificarile nu au fost salvate",
  active_competitions: "Competitii active",
  competition_deleted_success: "Competitia a fost stearsa cu succes",
  round_deleted_success: "Etapa a fost stearsa cu succes",
  match_deleted_success: "Meciul a fost sters cu succes",
  match_delete_popup_title: "Sterge meci",
  match_delete_popup_text:
    "Esti sigur ca doresti sa stergi acest meci? Toate datele aferente vor fi pierdute.",
  fixture_table: "Tablou competitie",
  delete_competition: "Sterge competitie",
  delete_round: "Sterge etapa",
  edit_round: "Editeaza etapa",
  round: "Etapa",
  new_round_name: "Nume nou etapa",
  delete_competition_text:
    "Esti sigur ca doresti sa stergi aceasta competitie? Toate datele aferente vor fi pierdute.",
  edit_competition: "Editeaza competitie",
  edit_score: "Editeaza scor",
  no_matches_for_selected_options:
    "Nu exista niciun meci pentru optiunile selectate",
  administrate_teams: "Gestionare echipe",
  administrate_teams_success: "Numele echipei a fost modificat cu succes!",
  administrate_teams_delete: "Echipa a fost stearsa cu succes!",

  //Training plan

  create_training: "Creeaza antrenament",
  add_training_sections: "Adauga sectiuni antrenament",

  total_training_duration: "Total durată antrenament",
  training_details: "Detalii antrenament",
  hours_error_training: "Ora incheierii nu poate fi inaintea orei de incepere",
  save_training: "Salveaza antrenament",
  training_sections: "Sectiuni antrenament",
  create_new_section: "Creeaza sectiune noua",
  add_new_section_name: "Introdu nume sectiune noua",
  add_training_session: "Adauga antrenament",
  training_add_title: "Gestionare antrenament",
  training_success: "Antrenamentul a fost adaugat cu succes!",
  section_add_title: "Gestionare sectiuni",
  section_added_success: "Sectiunea a fost adaugata cu succes!",
  training_date: "Data",
  training_date_team: "Data si echipa",
  training_description_details: "Descriere/Detalii",
  add_view_image: "Adaugă/vezi imagine",
  add_view_video: "Adaugă/vezi video",
  add_section: "Adaugă secțiune",

  player_tech_parameters: "Parametri tehnici jucator",
  parameters_list: "Lista de parametri",
  teams_stats: "Teams stats",

  // Reports

  delete_evaluation: "Șterge evaluare",
  delete_evalaution_message:
    "Ești sigur că dorești să ștergi această evaluare? Toate datele aferente vor fi pierdute.",
  delete_physical_test_message:
    "Ești sigur că dorești să ștergi aceast test fizic? Toate datele aferente vor fi pierdute.",
  delete_category_message:
    "Acțiunea este ireversibilă. Ești sigur/sigură că vrei să ștergi categoria ",
  edit_evaluation: "Editează Evaluare",
  evaluation_name: "Nume evaluare",
  generate_evaluation_table: "Generează tabel evaluare",
  add_player_evaluation: "Adaugă jucător la evaluare",
  evaluation_table: "Tabel evaluări",
  add_evaluation: "Adaugă evaluare",
  players_evaluations: "Evaluări jucători",
  no_data_report_physical:
    "Adauga cel putin un parametru si o echipa, apoi adauga un jucator pentru a vedea lista cu evaluari",
  value: "Valoare",
  add_report: "Adauga raport",
  made_by: "Realizat de",
  player_comparison: "Comparație jucători",
  evaluated_params: "Parametri evaluați",
  parameter: "Parametru",
  unit_of_measure: "Unitate de măsură",
  evaluator_name: "Nume evaluator",
  report_name: "Nume report",
  parameters_added: "parametri adaugati",
  select_parameter: "Selecteaza parametru",
  create_new_parameter: "Creeaza parametru nou",
  unit_of_measure: "Unitate de masura",
  add_parameter: "Adauga parametru",
  evaluation_list: "Lista evaluari",
  choose_player: "Alege jucator",
  add_value: "Introdu valoare",
  add_data: "Introdu datele",
  evaluation: "Evaluare",
  player_added: "Jucator adaugat",
  save_report: "Salveaza raport",
  add_parameter_warning_notification_title: "Adaugare raport",
  add_parameter_warning_notification_text:
    "Selectati un parametru inainte de adaugare.",
  add_player_warning_notification_title: "Adaugare raport",
  add_player_warning_notification_text:
    "Selectati un jucator inainte de adaugare. Daca nu exista jucatori in lista, asigurati-va ca ati selectat o echipa.",

  // Financial
  transaction_history: "Istoric tranzacţii",
  current_balance: "Sold curent",
  select_interval: "Selectează interval",
  select_type: "Selectează tipul",
  items_on_page: "Tranzacții pe pagină",
  total_income: "Total încasări",
  total_expense: "Total cheltuieli",

  // Fan engagement
  combine_fan_profiles: "Unește profiluri",
  filter_profiles: "Filtrează",
  firstname_lastname: "Nume și prenume",
  phone_no: "Număr de telefon",
  age: "Vârstă",
  years: "ani",
  general_info: "Date generale",
  full_name: "Nume complet",
  birthday: "Data nașterii",
  fan_profile: "Profil fan",
  address: "Adresă",
  activity: "Activitate",

  // Scouting
  saved_shortlists: "Liste salvate",
  create_shortlist: "Creează listă",
  shortlist_name: "Nume listă",
  formation: "Formație",
  selected_players: "Jucători selectați",
  position: "Poziție",
  date_created: "Data",
  shortlist_url: "Liste",

  // Users management admin
  users_management: "Gestiune utilizatori",
  users_management_menu: "Gestiune utilizatori",
  account_creation_date: "Data creării contului",
  team_access: "Acces echipe",
  access_module: "Acces module",
  add_user: "Adaugă utilizator",
  active_user_success: "Utilizatorul este acum activ",
  inactive_user_success: "Utilizatorul este acum inactiv",
  active_user_danger: "Utilizatorul  a rămas activ",
  inactive_user_danger: "Utilizatorul a rămas inactiv",
  activate: "Activează",
  disable: "Dezactivează",
  activate_user: "Activează utiliztor",
  disable_user: "Dezactivează utiliztor",
  disable_user_confirmation:
    "Ești sigur că dorești să dezactivezi acest utilizator?",
  activate_user_confirmation:
    "Ești sigur că dorești să activezi acest utilizator?",
  delete_user: "Șterge utilizator",
  delete_user_confirmation:
    "Ești sigur că dorești să ștergi acest utilizator? Toate datele aferente vor fi pierdute",
  delete_user_success: "Utilizatorul a fost șters cu succes",
  delete_user_danger: "Utilizatorul nu a fost șters",
  yes: "Da",
  no: "Nu",
  wrong_phone_number: "Număr de telefon greșit",
  wrong_email: "Adresa de email greșită",
  all_teams: "Toate echipele",
  some_teams: "Anumite echipe",
  edit_user: "Editează utilizator",
  apply: "Aplică",
  edit_user_success: "Utilizatorul a fost modificat cu succes",
  edit_user_danger: "Utilizatorul nu a fost modificat",

  //Products
  add_product: "Adaugă produs",
  product_image: "Poză produs",
  product_name: "Nume produs",
  product_description: "Descriere",
  product_price: "Preț",
  product_status: "Status",
  product_actions: "Acțiuni",
  product_is_active: "Produsul este activ",
  product_delete_confirmation: "Confirmare stergere",
  product_delete_message:
    "Sunteți sigur că doriți să ștergeți acest produs? Acțiunea este ireversibilă.",
  product_edit: "Editare produs",
  active: "Activ",
  inactive: "Inactiv",
  no_products: "Nu sunt produse",
  add_product_name: "Introdu nume produs",
  add_product_description: "Introdu descriere produs",
  add_product_price: "Introdu pret produs",
  choose_categories: "Alege categoriile",
  choose_sizes: "Alege marimile",
  success_add_product: "Produsul a fost incarcata cu succes!",
  fail_add_product: "Produsul nu a fost incarcata cu succes!",
  success_update_product: "Produsul a fost actualizata cu succes!",
  fail_update_product: "Produsul nu a fost actualizata cu succes!",
  success_delete_product: "Produsul a fost stearsa cu succes!",
  fail_delete_product: "Produsul nu a fost stearsa cu succes!",

  //Categories
  add_category: "Adaugă categorie",
  edit_category: "Editează categorie",
  category_image: "Poza categorie",
  category_name: "Nume categorie",
  add_category_name: "Introdu nume categorie",
  category_actions: "Actiuni",
  category_is_active: "Categoria este activa",
  category_delete_confirmation: "Confirmare stergere",
  category_delete_message:
    "Sunteți sigur că doriți să ștergeți aceasta categorie? Acțiunea este ireversibilă.",
  category_edit: "Editare categorie",
  no_categories: "Nu sunt categorii",
  success_add_category: "Categoria a fost incarcata cu succes!",
  fail_add_category: "Categoria nu a fost incarcata cu succes!",
  success_update_category: "Categoria a fost actualizata cu succes!",
  fail_update_category: "Categoria nu a fost actualizata cu succes!",
  success_delete_category: "Categoria a fost stearsa cu succes!",
  fail_delete_category: "Categoria nu a fost stearsa cu succes!",
  without_image: "Fara poza",

  //Orders
  order_id: "ID",
  order_date: "Dată comandă",
  order_customer: "Client",
  order_shipping_method: "Metodă livrare",
  order_payment_method: "Metodă plata",
  order_status: "Status",
  order_amount: "Valoare",
  order_actions: "Actiuni",
  order_details: "Detalii comandă",
  order_product: "Produs",
  order_quantity: "Cantitate",
  order_unit_price: "Pret unitar",
  order_wrapper: "Ambalaj",
  order_vat: "TVA",
  order_subtotal: "Subtotal",
  order_phone: "Nr. telefon",
  order_mail: "E-mail",
  order_address: "Adresă",
  order_message: "Mesaj comandă",
  order_payment_type: "Tip plată",
  order_delivery_cost: "Cost livrare",
  order_cancel: "Anulare comandă",
  order_cancel_message:
    "Sunteți sigur/ă că doriți anularea comenzii? Menționați mai jos motivul:",
  order_cancel_reason: "Motiv...",
  order_cancel_dismiss: "Renunta",
  order_delete_alert:
    "Comanda trebuie ca mai întâi să fie anulată pentru a putea fi ștearsă",
  no_orders: "Nu sunt comenzi",
  order_details: "Detalii comanda",
  order_delivery_types: {
    delivery: "Livrare",
    pickup: "Ridicare personala",
  },
  order_payment_types: {
    online: "Online",
    cash: "Numerar",
  },
  confirm_order: "Confirmat",
  cancel_order: "Anulare",
  close_order: "Inchide",
  deliver_order: "Livrat",
  order_delete_message:
    "Sunteți sigur că doriți să ștergeți această comandă? Acțiunea este ireversibilă.",
  success_delete_order: "Comanda a fost stearsa cu succes!",
  fail_delete_order: "Comanda nu a fost stearsa cu succes!",
  success_confirm_order: "Comanda a fost confirmata cu succes!",
  fail_confirm_order: "Comanda nu a fost confirmata cu succes!",
  success_cancel_order: "Comanda a fost anulata cu succes!",
  fail_cancel_order: "Comanda nu a fost anulata cu succes!",
  success_deliver_order: "Comanda a fost livrata cu succes!",
  fail_deliver_order: "Comanda nu a fost livrata cu succes!",

  //Sizes
  sizes: "Mărimi",
  size: "Mărime",
  add_size: "Adaugă mărime",
  edit_size: "Editare mărime",
  size_delete_message:
    "Sunteți sigur că doriți să ștergeți această mărime? Acțiunea este ireversibilă.",
  no_sizes: "Nu există mărimi",
  add_size_name: "Introdu marimea",
  success_add_size: "Marimea a fost incarcata cu succes!",
  fail_add_size: "Marimea nu a fost incarcata cu succes!",
  success_update_size: "Marimea a fost actualizata cu succes!",
  fail_update_size: "Marimea nu a fost actualizata cu succes!",
  success_delete_size: "Marimea a fost stearsa cu succes!",
  fail_delete_size: "Marimea nu a fost stearsa cu succes!",

  // Months
  month_1: "ianuarie",
  month_2: "februarie",
  month_3: "martie",
  month_4: "aprilie",
  month_5: "mai",
  month_6: "iunie",
  month_7: "iulie",
  month_8: "august",
  month_9: "septembrie",
  month_10: "octombrie",
  month_11: "noiembrie",
  month_12: "decembrie",
  //========================================================================================================================= Old translations

  Name: "Nume",
  sponsor_contracts: "Contracte sponsori",
  contracts: "Contracte",
  bonuses: "Bonusuri",
  add_new_file: "Adaugă un fișier",
  add_document: "Adaugă document",
  delete_document: "Șterge document",
  player_contract: "Contract jucător",
  add_player_contract: "Adaugă contract jucător",
  file_name: "Nume fișier",
  category: "Categorie",
  date_add: "Dată adăugare",
  added_by: "Adăugat de",
  new_member: "Membru nou",
  new_report: "Raport nou",
  players: "Jucători",

  budget: "Buget",
  add_new_member: "Adaugă membru nou",
  no_team: "Nicio echipa",
  find_a_player: "Caută jucător",
  position: "Poziție",
  Nationality: "Naționalitate",
  League: "Liga",
  Age: "Vârstă",
  Reset_filters: "Resetează filtre",
  Advanced_search: "Căutare avansată",
  Add_to_My_List: "Adaugă la lista mea",
  Remove_from_my_list: "Șterge din lista mea",
  Order_by: "Ordonează dupa",
  Discover_more: "Descoperă mai multe",
  My_list: "Lista mea",
  Moneda: "Moneda",
  Cashflow: "Flux Numerar",
  There_are_no_records_to_display: "Niciun rezultat.",
  Budget: "Buget",

  Currency: "Monedă",
  Cancel: "Anulează",
  Add: "Adaugă",
  PROFIT: "ÎNCASĂRI",
  SPENDING: "Cheltuieli",
  Add_category_to: "Adaugă categoria la",
  Players: "Jucători",
  transfer_history: "Istoric transferuri",

  overview: "Imagine de ansamblu",
  current_season: "Sezonul curent",
  advanced_standings: "Clasament detaliat",
  fixtures_schedule: "Program meciuri",
  team_statistics: "Statistici echipa",
  player_search: "Cauta jucatori",
  fast_search: "Căutare rapidă",
  total: "Total",
  search: "Cauta...",
  show: "Afiseaza",
  extra_notes: "Note suplimentare:",
  choose_categ: "Choose category",
  new_categ: "Adauga categorie noua...",
  active_crtierias: "Criterii active:",
  Nationality: "Nationalitate",
  League: "Competitie",
  Position: "Pozitie",
  see_results: "Vezi rezultate",
  test_name: "Numele testului",
  evaluation_year: "An",
  see_test_details: "Vezi detaliile testului",

  see_more: "Vezi mai multe",
  players_eval: "Jucatori evaluati",
  latest_reports: "Ultimele rapoarte",

  Login_Account: "Logare cont",
  Welcome_to: "Bun venit la",
  auth_page_left_description: "",
  Privacy: "Confidențialitate",
  Legal: "Termeni și condiții",
  Contact: "Contact",
  Enter_your_username_and_password: "Completează parola și email-ul tău",

  Sign_In: "Logare",
  Dont_have_an_account_yet: "Nu ai cont încă?",
  Sign_Up_1: "Înregistrează-te!",
  Enter_your_details_to_create_your_account:
    "Completează formularul de înregistrare",
  Full_name: "Nume întreg",
  Email: "Email",
  User_name: "Nume utilizator",
  Password: "Parola",
  Confirm_Password: "Confirmare Parolă",
  I_aggree_with_terms_and_conditions: "Sunt de acord cu termenii și condițiile",
  Choose_image: "Încarcă imagine",
  image: "imagine",
  video: "video",
  videos: "Videouri",
  Submit: "Trimite",
  Required_field: "Câmp obligatoriu",
  Password_and_Confirm_Password_didnt_match: "Parolele nu se potrivesc",
  The_login_detail_is_incorrect: "Date invalide",

  Add_team_bonus: "Adaugă bonus echipă",
  Add_players_bonus: "Adaugă bonus jucători",
  Bonus_history: "Istoric bonusuri",
  Total_sum: "Suma totală",
  Bonus_name: "Nume bonus",
  Bonus_type: "Tip bonus",
  Bonus_condition: "Condiție bonus",
  Payment_status: "Status plată",
  Date_added: "Data adăugării",
  EUR: "EURO",
  RON: "RON",
  Fullfiled: "Îndeplinită",
  In_pending: "În așteptare",
  Paid: "Plătit",
  Delete: "Șterge",
  Change_status: "Modifică status",
  Add_bonus: "Adaugă bonus ",
  For_a_match: "Pentru un meci",
  Selected_step: "Etapă selectată",
  Totol_assigned_sum: "Sumă totală acordată",
  GoBack: "Înapoi",
  Bonus_beneficiary: "Beneficiar bonus",
  Competition: "Competiție",
  For_the_full_season: "Pentru intreg sezonul",
  Select_match: "Selectează meci",
  Assignment_criteria: "Criteriu acordare",
  Assignment_criterias: "Criterii",
  Select_bonus_type: "Tip bonus",
  Bonus_position: "Clasare in primele ... pozitii",
  All_competitions: "Toate competitiile",
  placeholder_documents: "Niciun document incărcat momentan",

  Ranking: "Poziție în clasament",
  Custom_duration: "Durată personalizată",

  Ranking_the_first_n_places: "Clasare in primele ... locuri",
  Qualification_in_the_play_off: "Calificare in play-off",
  Ranking_for_european_cup: "Clasare pe loc de cupe Europene",
  Ranking_for_uefa_champions_league: "Clasare pe loc de UEFA Champions League",
  Qualification_in_the_romanian_cup: "Calificare in etapa Cupei Romaniei",
  Competiion_final: "Finala",
  Competiion_semi_final: "Semifinala",
  Competiion_quarters_final: "Sferturi de finala",
  Competiion_eighths_final: "Optimi de finala",
  Competiion_sixteeths_final: "Saispezecimi",
  All_players: "Toti jucatorii",
  Player_holders: "Jucatori titulari",
  Player_and_Staff: "Jucatori & Staff",
  Individual: "Individual",
  First_league: "Liga 1",
  Romanian_Cup: "Cupa Romaniei",
  Romanian_supercup: "Supercupa Romaniei",
  Goal_scored: "Gol marcat",
  Other_crieteria: "Alt criteriu",
  Staff_bonus: "Staff",
  Another_bonus: "Alt destinatar",
  select_cup_phase: "Etapa cupei...",

  Final_result_win: "Rezultat final: Victorie",
  Final_result_draw: "Rezultat final: Egalitate",
  Next: "Următor",

  Add_contract_details: "Detaliile contractului",
  Contract_extra: "Conditii suplimentare",
  Upload_file: "Incarca document",
  player_name: "Nume jucator",
  contract_period: "Durata contract",
  Other: "Alta",
  choose_team: "Alege echipa",
  team: "Echipa",
  evaluation_date: "Data evaluării",
  average_rating: "Media evaluarilor",
  show_on_loan: "Jucatori imprumutati",

  one_year: "1 an",
  one_year_half: "1 an si jumatate",
  two_years: "2 ani",
  two_years_half: "2 ani si jumatate",
  three_years: "3 ani",
  three_years_half: "3 ani si jumatate",
  four_years: "4 ani",
  four_years_half: "4 ani si jumatate",
  five_years: "5 ani",
  five_years_half: "5 ani si jumatate",

  sure_delete_event: "Ești sigur că vrei să ștergi evenimentul din meci?",

  add_new_event: "Adauga eveniment nou",
  add_event: "Adauga eveniment",
  event_name: "Nume eveniment",
  event_category: "Alege categorie",
  starting_date: "Data inceperii",
  ending_date: "Data incheierii",
  month: "Luna",
  week: "Saptamana",
  day: "Zi",
  today: "Astazi",
  new_categ_name: "Nume categorie noua",
  club_management: "Management club",
  select_team: "Selecteaza echipa",

  physical_reports: "Rapoarte fizice",
  technical_reports: "Rapoarte tehnice",

  see_profile: "Vezi profilul",

  no_item: "Niciun element",

  name: "Nume",
  partner_name: "Nume partener",
  unpaid_amount: "Suma restanta",
  paid_amount: "Suma platita",
  last_payment: "Data ultimei plati",
  deadline_date: "Data scadenta",

  injuries: "Accidentari",

  ["Players & Staff"]: "Jucători & Staff",
  ["Starting lineup"]: "Jucători titulari",
  ["All the players"]: "Toți jucătorii",

  this_will_overwrite_your_preferred_currency:
    "Această acțiune va schimba moneda selectată în setările contului dumneavoastră. Sunteți sigur?",
  Confirm: "Confirm",

  ["Developer"]: "Dezvoltator",
  ["Team manager"]: "Manager de echipă",
  ["Coach"]: "Antrenor",
  ["Goalkeepers coach"]: "Antrenor cu portarii",
  ["President"]: "Presedinte",
  ["Physical trainer"]: "Preparator fizic",
  ["IT Manager"]: "Manager IT",
  ["Poli Iasi Academy Manager"]: "Manager Academia Poli Iasi",

  ["Goalkeeper"]: "Portar",
  ["Centre-Back"]: "Fundaș Central",
  ["Left-Back"]: "Fundaș Stânga",
  ["Right-Back"]: "Fundaș Dreapta",
  ["Defensive Midfield"]: "Mijlocaș Defensiv",
  ["Central Midfield"]: "Mijlocaș Central",
  ["Right Midfield"]: "Mijlocaș Dreapta",
  ["Left Midfield"]: "Mijlocaș Stânga",
  ["Attacking Midfield"]: "Mijlocaș Ofensiv",
  ["Left Winger"]: "Extremă Stânga",
  ["Right Winger"]: "Extremă Dreapta",
  ["Second Striker"]: "Al Doilea Atacant",
  ["Centre-Forward"]: "Atacant Central",
  ConnectionCannotBeRefreshed:
    "Conexiune invalidă sau o actualizare recenta a avut deja loc și va fi posibilă mai târziu.",
  cur_: "",
  cur_Th: " Mii",
  cur_M: " Milioane",
  cur_B: " Miliarde",
  cur_T: " Bilioane",

  "All documents": "Toate documentele",
  "Players contracts": "Contracte jucatori",
  "Sponsor contracts": "Contracte sponsori",

  Bills: "Facturi",
  Various: "Diverse",
  "Training scheme": "Schema antrenamente",

  left_shoulder: "Umar stang",
  right_shoulder: "Umar drept",
  left_knee: "Genunchi stang",
  right_knee: "Genunchi drept",
  left_thigh: "Coapsa stanga",
  right_thigh: "Coapsa dreapta",
  left_shin: "Tibia stanga",
  right_shin: "Tibia dreapta",
  back: "Spate",
  head: "Cap",
  left_ankle: "Glezna stanga",
  right_ankle: "Glezna dreapta",

  "GENERAL.SOMETHING_WENT_WRONG":
    "Ups! A apărut o eroare. Te rugăm să reîncerci mai târziu.",
  Error: "Eroare",

  Back_button: "Inapoi",

  FORM_CONTROL: {
    "TRANSLATOR.SELECT": "Selectează limba",
    "MENU.NEW": "Nou",
    "MENU.ACTIONS": "Acțiuni",
    "MENU.CREATE_POST": "Crează o nouă postare",
    "MENU.PAGES": "Pagini",
    "MENU.FEATURES": "Features",
    "MENU.APPS": "Aplicații",
    "MENU.DASHBOARD": "Panou",
    "AUTH.GENERAL.OR": "Sau",
    "AUTH.GENERAL.SUBMIT_BUTTON": "Trimite",
    "AUTH.GENERAL.NO_ACCOUNT": "Nu ai un cont?",
    "AUTH.GENERAL.SIGNUP_BUTTON": "Înregistrează-te",
    "AUTH.GENERAL.FORGOT_BUTTON": "Am uitat parola",
    "AUTH.GENERAL.BACK_BUTTON": "Înapoi",
    "AUTH.GENERAL.CANCEL_BUTTON": "Anulează",
    "AUTH.GENERAL.PRIVACY": "Confidențialitate",
    "AUTH.GENERAL.LEGAL": "Termeni și condiții",
    "AUTH.GENERAL.CONTACT": "Contact",
    "AUTH.LOGIN.TITLE": "Logare Cont",
    "AUTH.LOGIN.BUTTON": "Logare",
    "AUTH.FORGOT.TITLE": "Ai uitat parola?",
    "AUTH.FORGOT.DESC": "Completează email-ul tău pentru a reseta parola",
    "AUTH.FORGOT.SUCCESS": "Parola ta a fost resetată cu succes.",
    "AUTH.REGISTER.TITLE": "Înregistrare",
    "AUTH.REGISTER.DESC": "Completează detaliile tale pentru a crea un cont",
    "AUTH.REGISTER.SUCCESS": "Contul tău a fost creat cu succes.",
    "AUTH.INPUT.EMAIL": "E-mail",
    "AUTH.INPUT.FULLNAME": "Nume Întreg",
    "AUTH.INPUT.PASSWORD": "Parolă",
    "AUTH.INPUT.CONFIRM_PASSWORD": "Confirmare Parolă",
    "AUTH.INPUT.USERNAME": "Nickname",
    "AUTH.VALIDATION.INVALID": "{name} nu e valid",
    "AUTH.VALIDATION.REQUIRED": "{name} e câmp obligatoriu",
    "AUTH.VALIDATION.MIN_LENGTH":
      "Câmpul trebuie sa aiba lungime minima de 3 caractere",
    "AUTH.VALIDATION.MAX_LENGTH":
      "Câmpul trebuie sa aiba lungime maximă de 50 caractere",
    "AUTH.VALIDATION.AGREEMENT_REQUIRED":
      "Termenii & condițiile este câmp obligatoriu",
    "AUTH.VALIDATION.NOT_FOUND": "Valoare {name}-ului nu a fost găsită",

    "AUTH.GENERAL.USER_NOT_FOUND": "Utilizatorul asociat nu a fost găsit",
    "AUTH.GENERAL.USER_INACTIVE": "Utilizatorul asociat nu a activ",

    "AUTH.VALIDATION.INVALID_LOGIN": "Logarea este invalidă",
    "AUTH.VALIDATION.INVALID_EMAIL": "Email invalid",
    "AUTH.GENERAL.SOMETHING_WENT_WRONG":
      "Ups! A apărut o eroare. Te rugăm să reîncerci mai târziu.",

    "AUTH.VALIDATION.NO_USER_WITH_EMAIL":
      "Email-ul nu este folosit de niciun utilizator activ",
    "AUTH.VALIDATION.REQUIRED_FIELD": "Câmp obligatoriu",
    "AUTH.VALIDATION.MIN_LENGTH_FIELD":
      "Câmpul are nevoie de o lungime minimă de:",
    "AUTH.VALIDATION.MAX_LENGTH_FIELD":
      "Câmpul are nevoie de o lungime maximă de:",
    "AUTH.VALIDATION.INVALID_FIELD": "Câmpul nu este valid",
    "ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT": "Numarul itemelor: ",
    "ECOMMERCE.COMMON.ALL": "Toate",
    "AUTH.RESET_PASSWORD.TITLE": "Resetează parola",
    "AUTH.RESET_PASSWORD.TOKEN_EXPIRED": "Token-ul a expirat",
    "AUTH.GENERAL.EMPTY_TOKEN": "Token-ul de resetare este gol",
    "ECOMMERCE.COMMON.SUSPENDED": "Suspendat",
    "ECOMMERCE.COMMON.ACTIVE": "Activ",
    "ECOMMERCE.COMMON.FILTER": "Filtrează",
    "ECOMMERCE.COMMON.BY_STATUS": "in funcție de status",
    "ECOMMERCE.COMMON.BY_TYPE": "in funcție de tip",
    "ECOMMERCE.COMMON.BUSINESS": "Business",
    "ECOMMERCE.COMMON.INDIVIDUAL": "Individual",
    "ECOMMERCE.COMMON.SEARCH": "Căutare",
    "ECOMMERCE.COMMON.IN_ALL_FIELDS": "în toate câmpurile",
    "ECOMMERCE.ECOMMERCE": "eCommerce",
    "ECOMMERCE.CUSTOMERS.CUSTOMERS": "Clienți",
    "ECOMMERCE.CUSTOMERS.CUSTOMERS_LIST": "Listă Clienți",
    "ECOMMERCE.CUSTOMERS.NEW_CUSTOMER": "Client nou",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.TITLE": "Ștergere Client",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.DESCRIPTION":
      "Ești sigur că dorești ștergerea definitivă a acestui client?",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.WAIT_DESCRIPTION":
      "Client in curs de ștergere...",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.MESSAGE":
      "Clientul a fost șters",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.TITLE": "Ștergere Client",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.DESCRIPTION":
      "Ești sigur că dorești ștergerea definitivă a clienților selectați?",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.WAIT_DESCRIPTION":
      "Clienți in curs de ștergere...",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.MESSAGE":
      "Clienții selectați au fost șterși",
    "ECOMMERCE.CUSTOMERS.UPDATE_STATUS.TITLE":
      "Statusul clienților selectați au fost actualizate cu succes",
    "ECOMMERCE.CUSTOMERS.UPDATE_STATUS.MESSAGE":
      "Datele clienților selectați au fost actualizate cu succes",
    "ECOMMERCE.CUSTOMERS.EDIT.UPDATE_MESSAGE": "Date client actualizate",
    "ECOMMERCE.CUSTOMERS.EDIT.ADD_MESSAGE": "Client creat",
  },

  verification_code: "Cod de verificare",
  insert_verification_code:
    "Introdu codul de 6 cifre trimis la numarul de telefon asociat contului",
  verification_code_no_receive_message: "Nu ai primit mesajul?",
  verification_code_send_again: "Trimite din nou",

  auth_code_expired: "Codul de autentificare a expirat",
  auth_code_incorrect: "Codul introdus este incorect",
  auth_code_try_again: "Eroare la autentificare. Incearca din nou",

  team_types_list: "Tipuri de echipe",
  team_type_add: "Adauga tip nou",
  team_type_add_short: "Adauga tip",

  team_type_notification_title: "Tipuri de echipe",
  team_type_notification_succes: "Tip de echipa adaugat cu succes!",
  team_type_notification_fail: "Tipul selectat a fost eliminat cu succes.",

  tm_link_team: "Link Transfermarkt (optional)",
  minutes_played_uppercase: "Minute jucate",
  all_fields_mandatory_error: "Toate campurile sunt obligatorii",
  no_sections_added_error:
    "Trebuie sa adaugi cel putin o sectiune in programul de antrenament",
  rec_date_not_before_inj_date:
    "Data revenirii nu poate fi inaintea datei accidentarii",
  documents_upload_success: "Documentul a fost incarcat cu succes!",
  video_upload_success: "Videoclipul a fost incarcat cu succes!",
  video_upload_failed:
    "Videoclipul trebuie sa aiba maxim 125MB pentru incarcare!",
  video_delete_success: "Videoclipul a fost sters cu succes!",
  video_operation: "Operatiune videoclip",
  ACCESS_FORBIDDEN_ERROR: "Acces interzis pe aceasta pagina",
  new_category_empty_error: "Numele categoriei adaugate nu poate fi gol",
  delete_team: "Sterge echipa",
  season_date_start_add_team: "Data inceput sezon curent",
  season_date_end_add_team: "Data final sezon curent",
  deleteTeamMessage: "Esti sigur ca vrei sa stergi aceasta echipa?",
  deleteTeamMessage2: "Toate datele aferente vor fi pierdute",
  add_optimal_weight: "Modifica greutate optima",

  auth_error_title: "Eroare la autentificare",
  auth_error_message: "Verificati datele introduse si incercati din nou",
  reset_error_title: "Eroare resetare parola",
  hello: "Salut",
  password_reset_email: "Noua ta parola este",

  import_fans_title: "Importa date despre suporteri",
  select_fields_to_save: "Selecteaza campurile pe care doresti sa le salvezi",
  enter_source_name: "Introdu numele sursei",
  enter_delimiter: "Introdu delimitator",
  sample_name_fans_file: "ex: Abonare newsletter",
  associated_with: "Asociat cu",
  activity_date: "Data efectuarii activitatii",
  column: "Coloana",
  social_media_activity: "Activitate social media",

  // Costs

  add_transaction: "Adaugă tranzacție",
  select_cost_category: "Selectează categorie costuri",
  add_new_cost_category: "Adaugă o nouă categorie de costuri",
  edit_departaments_list: "Editare lista departamente",
  add_departament: "Adaugă departament",
  department_name: "Denumire Departament",
  edit_departament: "Editează departament",
  add_spending: "Adaugă cheltuială",
  department: "Departament",
  subdepartment: "Subdepartament",
  period: "Perioada",
  tags: "Etichete",
  date_format: "zz/ll/aaaa",
  player: "Jucător",
  apply_filters: "Aplică filtre",
  departments_list: "Listă departamente",
  has_subdepartments: "Are subdepartamente",
  subdepartment_designation: "Denumire subdepartament",
  add_new_subdepartment: "Adaugă un nou subdepartament",
  add_tag: "Adaugă etichetă",
  entity_tag: "entitate etichetă",
  staff: "Staff",
  select: "Selectează",
  select_department: "Selectează departament",
  select_subdepartment: "Selectează subdepartament",
  select_tag_type: "Selectează tipul etichetei",
  no_costs: "Nu sunt cheltuieli",
  cancel_filters: "Anuleaza filtre",
  success_add_department: "Departamentul a fost adaugat cu succes!",
  fail_add_department: "Departamentul nu a fost adaugat cu succes!",
  no_departments: "Nu sunt departamente",
  success_update_department: "Departamentul a fost actualizat cu succes!",
  fail_update_department: "Departamentul nu a fost actualizat cu succes!",
  success_delete_department: "Departamentul a fost sters cu succes!",
  fail_delete_department: "Departamentul nu a fost sters cu succes!",
  department_delete_message:
    "Esti sigur/a ca vrei sa stergi acest departament? Actiunea este ireversibila.",
  success_add_cost: "Noua cheltuiala a fost adaugata cu suscces!",
  fail_add_cost: "Noua cheltuiala nu a fost adaugata cu suscces!",
  success_update_cost: "Cheltuiala a fost actualizata cu suscces!",
  fail_update_cost: "Cheltuiala nu a fost actualizata cu suscces!",
  cost_delete_message:
    "Esti sigur/sigura ca vrei sa stergi cheltuiala? Actiunea este ireversibila.",
  success_delete_cost: "Cheltuiala a fost sters cu succes!",
  fail_delete_cost: "Cheltuiala nu a fost sters cu succes!",
  delete_cost: "Sterge cheltuiala",
  delete_cost_message:
    "Acțiunea este ireversibilă. Ești sigur/sigură că vrei să ștergi costul ",

  // necessity reports

  add_necessity_report: "Adaugă referat",
  criteria_number: "Număr criteriu",
  registration_number: "Nr. de înregistrare",
  purchase_category: "Categorie achiziție",

  // receipt
  download_receipt: "Descarcă chitanța",
  no_data_receipt: "Nu sunt plati online.",

  // mobile app admin
  add_parent: "Adaugă părinte",
  edit_player: "Editează jucător",
  edit_relative: "Editează rudă",
  player_players_name: "Numele jucătorului/jucătorilor",
  current_status_payment: "Status plată recurentă",
  first_name: "Prenume",
  last_name: "Nume",
  player_relative: "Relația cu jucătorul",
  notif_edit_external_user: "Editare utilizator extern",
  edit_user_success_message: "Utilizatorul a fost editat cu succes!",
  edit_user_error_message: "Utilizatorul nu a fost editat !",
  delete_relative: "Ștergere rudă",
  delete_mobile_user_text:
    "Ești sigur că dorești să ștergi aceast utilizator? Toate datele aferente vor fi pierdute.",
  notif_delete_mobile_user_title: "Ștergere utilizator mobil",
  notif_delete_mobile_user_success_message:
    "Utilizatorul a fost șters cu succes !",
  notif_delete_mobile_user_failed_message: "Utilizatorul nu a fost șters !",
  desactivate_user: "Dezactivează utilizator mobil",
  desactivate_user_popup_text:
    "Ești sigur că dorești să dezactivezi acest utilizator?",
  desactivate: "Dezactivează",
  activate_user: "Activează utilizator mobil",
  activate_user_popup_text:
    "Ești sigur că dorești să activezi acest utilizator?",
  activate: "Activează",

  notif_desactivate_mobile_user_success_message:
    "Utilizatorul a fost dezactivat cu succes !",
  notif_desactivate_mobile_user_failed_message:
    "Utilizatorul nu a fost dezactivat  !",
  notif_activate_mobile_user_success_message:
    "Utilizatorul a fost activat cu succes !",
  notif_activate_mobile_user_failed_message:
    "Utilizatorul nu a fost activat  !",

  // users notifications
  next_step: "Pasul următor",
  previous_step: "Pasul anterior",
  send_notification: "Trimite notificarea",
  create_notification: "Creează notificare",
  sent_notification: "Notificare trimisă",
  date_hour_of_send: "Data și ora trimiterii",
  notification_send_by: "Trimisă de către",
  notification_title: "Titlu notificare",
  notification_message: "Mesaj notificare",
  notification_group: "Grupa",
  plyers_names: "Nume jucători",
  audience: "Audiența",
  delete_notification: "Șterge notificare",
  delete_notification_message:
    "Ești sigur că vrei să ștergi această notificare ?",
  details_notification_sent: "Detalii notificare trimisă",
  create_notification: "Creare notificare",
  step_one_selecte_audience: "Pasul 1 - selectează audiența",
  all_groups: "Toate grupele",
  step_two_select_audience: "Pasul 2 - compune mesajul",
  your_message: "Mesajul tău",

  notification_title_message: "Notificări utilizatori",
  notification_sucess_message: "Notificarea a fost trimisă cu succes !",
  notification_failed_message: "Notificarea nu a fost trimisă !",
  notification_delete_sucess_message: "Notificarea a fost ștearsă cu succes !",
  notification_delete_failed_message: "Notificarea nu a fost ștearsă !",

  // ----------------------------------Payments

  date_hour_of_payment: "Data și ora plății",
  payment_month: "Luna plătitoare",
  payment_month_description: "Luna plătitoare / Descriere",
  payer: "Plătitor",
  payment_method: "Metodă plată",
  payments_title: "Plăți",
  table_name: "Nume",
  table_months: {
    jan: "Ianuarie",
    feb: "Februarie",
    mar: "Martie",
    apr: "Aprilie",
    may: "Mai",
    jun: "Iunie",
    jul: "Iulie",
    aug: "August",
    sep: "Septembrie",
    oct: "Octombrie",
    nov: "Noiembrie",
    dec: "Decembrie",
  },
  add_payment_title: "Adauga plata pentru jucatorul ",
  add_payment_select_month: "Selecteaza luna",
  add_payment_amount: "Suma",
  add_payment_type: {
    cash: "Numerar",
    bank_transfer: "Transfer bancar",
  },
  add_payment_date: "Data platii",
  add_payment_description: "Descriere (optional)",
  add_payment_button_add: "Adauga plata",
  add_payment_button_cancel: "Anulare",
  edit_payments_title: "Editeaza platile pentru jucatorul ",
  edit_payments_empty: "Nu sunt plati inregistrate.",
  add_payment_overwrite_first:
    "Esti sigur ca vrei sa suprascriti datele despre plata din luna ",
  add_payment_overwrite_second: " pentru jucatorul ",
  add_payment_button_overwrite: "Da, suprascrie datele",
  delete_payment_question: "Esti sigur ca vrei sa stergi plata?",
  delete_payment_button_delete: "Da, sterge",
  payment_successfully_added: "Plata adaugata cu succes!",
  payment_successfully_deleted: "Plata selectata a fost eliminata cu succes!",

  //Match - notification message
  matches_module: "Meciuri",
  success_save_players_rating: "Notarea jucatorilor a fost salvata cu succes!",
  fail_save_players_rating: "Notarea jucatorilor nu a fost salvata cu succes!",
  the_match_lot_was_successfully_saved:
    "Lotul meciului a fost salvat cu succes!",
  fail_the_match_lot_was_successfully_saved:
    "Lotul meciului nu a fost salvat cu succes!",
  the_player_has_been_removed_from_the_squad: "Jucătorul a fost șters din lot",
  fail_the_player_has_been_removed_from_the_squad:
    "Jucătorul nu a fost șters din lot",
  players_successfully_added_to_the_squad:
    "Jucătorii au fost adăugați cu succes în lot !",
  players_failed_added_to_the_squad: "Jucătorii nu au fost adăugați în lot !",

  //Receipt
  success_save_receipt: "Chitanta a fost modificata cu success!",
  fail_save_receipt: "Chitanta nu a fost modificata cu success!",
  success_delete_receipt: "Chitanta a fost stearsa cu success!",
  fail_delete_receipt: "Chitanta nu a fost stearsa cu success!",

  num_and_date_receipt: "Numar si data emitere",
  edit_receipt: "Editare chitanta",
  series: "Seria",
  receipt_number: "Numar chitanta",
  issue_date: "Data emitere",
  payer_name: "Nume plătitor",
  receipt_description: "Descriere chitanta",
  delete_receipt: "Stergere chitanta",
  delete_receipt_message:
    "Esti sigur/sigura ca vrei sa stergi chitanta? Actiunea este ireversibila.",
  delete_document_message:
    "Ești sigur/sigură că vrei sa ștergi documentul? Acțiunea este ireversibilă.",

  // STATISTICS GOALS

  goals_statistics: "Statistici goluri",
  show_statistics: "Afișează",
  invalid_period_time:
    "Data inițială trebuie sa fie mai mare decât data de final",

  free_kick: "Fază fixă",
  cross: "Centrare",
  direct_play: "Joc direct",
  combination_play: "Atac combinat",
  counterattack: "Contraatac",
  penalty: "Penalty",
  another: "Altele",

  scored_goals_first_half: "Goluri repriza 1",
  scored_goals_second_half: "Goluri repriza 2",
  conceded_goals_first_half: "Goluri repriza 1",
  conceded_goals_second_half: "Goluri repriza 2",

  scored_goals_by_category: "Goluri marcate - tipuri goluri",
  conceded_goals_by_category: "Goluri primite - tipuri goluri",
  scored_goals_per_half: "Goluri marcate / repriză",
  conceded_goals_per_half: "Goluri primite / repriză",
  "-": "-",
  no_data_found: "-",

  //WEBSITE SETTINGS
  website_settings: "Setari website",
  teams_settings: "Setari echipe",
  staff_settings: "Setari staff",
  online_payments_settings: "Setari plati online",
  enable_online_payments_module: "Activare modul de plati online pe site",
  only_online_payments: "Doar pagina pentru plati online",
  iframe: "Activare mod <i>iframe</i> pentru pagina de plati online",
  stripe_fee_passed_to_customer:
    "Taxa de procesare Stripe este achitata de client",
  client_edit_monthly_tax: "Taxa lunara poate fi editata de client",

  payment_types_settings: "Setari tipuri de plata",
  enable_another_tax: "Afisare optiune <i>Alte plati</i>",
  enable_payment_request: "Afisare optiune <i>Cereri de plata</i>",

  enrolment_request_settings: "Setari cereri de inscriere",
  enable_enrolment_form: "Activare cereri de inscriere pe site",
  privacy_policy: "Politica de confidențialitate",

  // Enrolment requests
  enrolment_request: "Cereri de înscriere",
  edit_request: "Editează cerere",
  child_name: "Prenume copil",
  child_surname: "Nume copil",
  request_date: "Data cererii",
  adult_name: "Prenume adult",
  adult_surname: "Prenume adult",
  child: "Copil",
  no_requests: "Nu sunt cereri",
  delete_request: "Șterge cerere",
  delete_request_message: "Ești sigur ca vrei să ștergi această cerere?",
  accept: "Acceptă",
  success_enrolment_request: "Cererea a fost acceptată",
  failed_enrolment_request: "Cererea nu a fost acceptată",

  //PAYMENT REQUEST
  payment_request: "Cereri de plata",
  payers: "Platitori",
  no_payment_request: "Nu sunt inregistrate cereri de plata.",

  add_payment_request: "Adauga cerere de plata",
  add_payment_details: "Introdu detalii plata",
  payment_request_title: "Denumire plata",
  enter_payment_request_title: "Introdu denumire",
  payment_request_amount: "Valoare plata",
  enter_payment_request_amount: "Introdu valoare",
  choose_players: "Alege jucatori",
  choose_team: "Selecteaza echipa",
  all_players: "Toti jucatorii",

  success_add_payment_request: "Cererea de plata a fost adaugata cu succes!",
  fail_add_payment_request: "Cererea de plata nu a fost adaugata cu succes!",

  success_delete_payment_request: "Cererea de plata a fost stearsa cu success!",
  fail_delete_payment_request: "Cererea de plata nu a fost stearsa cu success!",
  delete_payment_request: "Stergere cerere de plata",
  delete_payment_request_message:
    "Esti sigur/sigura ca vrei sa stergi cererea de plata? Actiunea este ireversibila.",

  amount_to_pay: "Suma de incasat",
  amount_received: "Suma incasata",
  payment_types: {
    cash: "numerar",
    bank_transfer: "transfer bancar",
    parents_app: "aplicație părinți",
    online_website: "online website",
  },
  add_players: "Adauga jucatori",

  success_add_players_to_payment_request:
    "Jucatorii au fost adaugati cu succes!",
  fail_add_players_to_payment_request:
    "Jucatorii nu au fost adaugati cu succes!",

  edit_amount_to_pay: "Editeaza suma de plata",
  edit_payment_request: "Editeaza cerere de plata",

  add_payment: "Adauga plata",
  payment_type: "Tipul platii",
  choose_payment_type: "Alege tipul platii",
  payment_date: "Data platii",
  choose_payment_date: "Alege data platii",

  success_edit_payment_request_amount:
    "Suma de plata a fost actualizata cu succes!",
  fail_edit_payment_request_amount:
    "Suma de plata nu a fost actualizata cu succes!",

  success_edit_payment_request:
    "Cererea de plata a fost actualizata cu succes!",
  fail_edit_payment_request:
    "Cererea de plata nu a fost actualizata cu succes!",

  success_add_payment: "Plata a fost adaugata cu succes!",
  fail_add_payment: "Plata nu a fost adaugata cu succes!",

  delete_player_payment_request: "Stergere cererea de plata a jucatorului",
  delete_player_payment_request_message:
    "Esti sigur/sigura ca vrei sa stergi cererea de plata a jucatorului? Actiunea este ireversibila.",

  // MATCH SQUAD
  match_squad: "Lot meci",
  save_match_squad: "Salvare lot meci",
  delete_player_from_match_squad:
    "Ești sigur că vrei să ștergi acest jucător din lot ?",

  // Club export inactive/dded players filters

  from: "De la...",
  until: "Până la...",
  export_inactive_players: "Export jucători inactivi",
  export_added_players: "Export jucatori adăugați",
  export_added_inactive_players: "Export jucători adăugați/inactivi",
  export_added_inactive_players: "Export jucători adăugați/inactivi",
  export_player_list: "Exportă lisă jucători",
  born_between: "Născut între...",
  and_between: "Și între...",
  select_data_types_included_in_the_list:
    "Selectează tipuri de date incluse în listă",

  //Players export list
  player_dob: "Data nașterii",
  cnp: "CNP",
  medical_certificate_validity: "Valabilitate viza medicala",
  export: "Exportă",
  all_filters: "Toate filtrele",
  hide_inactiv_players: "Ascunde jucători inactivi",
  legitimation_date: "Dată legitimare",
  enrolment_date: "Dată înscriere",

  //Player profile

  compare_two_players: "Comparație 2 jucători",
  first_player: "Primul jucător",
  second_player: "Al doilea jucător",
  player_atleast_one_evaluation:
    "Jucătorul selectat trebuie sa aibă cel puțin o evaluare",

  used_phone_number_by_another_user:
    "Numărul de telefon este există deja pentru alt utilizator !",
  used_phone_number_by_another_player:
    "Numărul de telefon este există deja pentru alt jucător !",

  insert_shirt_number: "Intordu numărul de pe tricoul jucătorului",

  // competitions
  select_age_category: "Selectează categoria de vârstă",

  //PRIVACY POLICY
  no_privacy_policy: "Nu este adaugata politica de confidențialitate",
  success_save_privacy_policy:
    "Politica de confidențialitate a fost salvata cu succes.",

  edit_age_group_competition: "Editează grupa de vârstă a competiției",
  add_age_group_competition: "Adaugă grupa de vârstă a competiției",
  age_group_name: "Nume grupă",
  competition_success_added: "Competiția a fost adăugată cu succes !",
  additional_info_generate_raport: "Informații adiționale generare raport",
  effective_duration: "Durată efectivă",
  delete_staff_member: "Ștergere membru staff",
  delete_staff_member_text:
    "Ești sigur că vrei sa elimini acest membru al staff-ului? Acțiunea este ireversibilă.",
  training_session_successfully_deleted:
    "Sesiunea de antrenament a fost ștearsă cu succes",
  training_session_failed_deleted: "Training session not deleted",
  delete_training: "Ștergere antrenament",
  delete_training_text:
    "Ești sigur că dorești să ștergi acest antrenament? Toate datele aferente vor fi pierdute.",

  //RPE TRAINING
  rpe_details_saved_successfully:
    "Detaliile RPE despre antrenament au fost salvate cu succes!",
  rpe_details_saved_failed:
    "Detaliile RPE despre antrenament NU au fost salvate!",
  show_trainings: "Afișează antrenamente",
  show_training: "Afișează antrenament",
  see_presence_list: "Vezi fișă prezență",
  save_data: "Salvează date",
  rpe_before: "RPE înainte",
  rpe_after: "RPE după",
  global_accumulation: "Acumulare globală",
  time_min: "Timp",
  variability_between_workouts: "Variabilitate între antrenamente",
  injuruy_general: "Accidentare",
  kg_before: "Kg înainte",
  kg_after: "Kg după",
  training_intensity: "Intensitate antrenament",

  add_player_to_future_training_sessions:
    "Adaugă jucătorul la viitoarele antrenamente deja programate ale echipei ",
  delete_player_from_training:
    "Ești sigur că dorești să elimini jucătorul din acest antrenament? Toate datele aferente vor fi pierdute.",
  player_deleted_successfully: "Jucătorul a fost eliminat cu succes",
  player_deleted_failed: "Jucătorul nu a fost elminat din antrenament",
  delete_section: "Șterge secțiune",
  delete_section_text:
    "Ești sigur că dorești să ștergi această secțiune? Toate datele aferente vor fi pierdute.",
  section_deleted_successfully: "Secțiunea a fost ștearsă cu succes !",
  section_deleted_failed: "Secțiunea NU a fost ștearsă !",
  start_date_greater_than_end_date:
    "Data inițială trebuie sa fie mai mare decât data de final",
  no_attendances_in_the_selected_period:
    "Nu sunt prezențe în perioada selectată",
  download_in_format: "Descărcare în format",
  match_and_date_of_the_event: "Meciul și data realizării",
  payments_made: "Plați efectuate",
  total_paid: "Total plătit",
  remaining_payment: "Rest de plată",
  bonus_value: "Valoare bonus",
  there_are_no_records: "Nu sunt înregistrări",
  necessity_report_suscces: "Referatul de necesitate a fost adaugăt cu succes!",
  necessity_report_fail: "Referatul de necesitate nu a fost adaugăt !",
  registration_number: "Număr de înregistrare",
  date_of_referral: "Dată referat",
  add_the_necessity_report: "Adaugă referat de necesitate",
  delete_necessity_report: "Șterge raport de necesitate",
  delete_necessity_report_text:
    "Ești sigur/sigură că dorești să ștergi aceast raport? Toate datele aferente vor fi pierdute.",
  edit_necessity_report: "Editare referat de necesitate",
  approval_disapproval_of_necessity_report:
    "Aprobare/dezaprobare raport de necesitate",
  necessity_report_reason: "Motiv ",
  in_case_of_disapproval_is_mandatory_field:
    "(în caz de dezaprobare este câmp obligatoriu)",
  add_bonus_active_conditions: "Introdu bonus pentru condițiile active",
  contract_operations: "Operațiuni contracte",
  bonus_added_successfully: "Un nou bonus a fost adaugat cu succes!",
  delete_contract: "Șterge contracte",
  delete_contract_text:
    "Actiunea este ireversibila. Esti sigur/sigura ca vrei sa stergi contractul pentru jucatorul ",
  yes_delete: "Da, șterge",
  delete_contract_staff_text:
    "Actiunea este ireversibila. Esti sigur/sigura ca vrei sa stergi contractul pentru staff-ul ",
  delete_bonus: "Șterge bonus",
  delete_bonus_text:
    "Acțiunea este ireversibilă. Sunteți sigur că doriți să ștergeți bonusul?",
  child_birthday: "Data nașterii copil",
  for_the_department: "Pentru departamentul",
  already_expense_for_match: "exista deja o cheltuială cu tag pentru meciul",

  edit_cost_types: {
    Staff: "Staff",
    Meci: "Meci",
    Jucator: "Jucator",
    Echipa: "Echipa",
  },
};
