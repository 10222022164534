import React from "react";
import axios from "axios";
import "./VideoPlayer.styles.scss";
import { logout } from "./../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon, Button } from "semantic-ui-react";
import { store } from "react-notifications-component";
import Loader from "react-loader-spinner";

class AddEventVideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      video: this.props.video,
      start: 0,
      end: 0,
      video_tag_title: "",
      video_tag_description: "",
      video_tag_details: [
        {
          date_add: "",
          deleted: "0",
          id_player: "",
          id_team: "",
          id_video_tag: "",
          id_video_tag_categ: "",
          id_video_tag_detail: "",
          player_name: "",
          tag_categ_name: "",
        },
      ],
    };
  }

  toHHMMSS(secs) {
    const sec = parseInt(secs, 10);
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - hours * 3600) / 60);
    let seconds = sec - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  }

  saveEvent() {
    const payload = {
      id_club: this.props.club,
      id_video: this.props.id_video,
      deleted: "0",
      second_start: this.state.start,
      second_final: this.state.end,
      video_tag_title: this.state.video_tag_title,
      video_tag_description: this.state.video_tag_description,
      video_tag_details: this.state.video_tag_details,
    };

    this.props.resetState();
    this.props.showLoading();
    axios
      .post("upload/add_video_tag", payload, {
        params: { token: this.props.token },
        headers: { "content-type": "multipart/form-data" },
      })
      .then(async (res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        }
        if (res.data == 1) {
          this.props.getTags();
          store.addNotification({
            title: "Video editing",
            message: "Video tag was successfully added!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
            },
          });
        } else {
          store.addNotification({
            title: "Video editing",
            message: "Video tag adding has failed!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
            },
          });
        }
      })
      .catch(async (err) => {
        return { data: {} };
      });
  }

  render() {
    const { languagePack } = this.props;
    return (
      <div
        className="video-event-container tags-video-container"
        style={{ paddingBottom: "15px", width: "100%" }}
      >
        <div className="video-event-row">
          <button
            className="button"
            onClick={() => {
              this.setState({ start: this.state.video.currentTime });
            }}
          >
            Get Start Time
          </button>
          <input
            className="time"
            value={this.toHHMMSS(this.state.start)}
            placeholder="Start: "
          />
          <button
            className="button"
            onClick={() => {
              this.setState({ end: this.state.video.currentTime });
            }}
          >
            Get End Time
          </button>
          <input
            className="time"
            value={this.toHHMMSS(this.state.end)}
            placeholder="End: "
          />
        </div>
        <div className="video-event-row">
          <select
            className="ui selection dropdown event-type"
            value={this.state.video_tag_details[0].tag_categ_name}
            onChange={(event) => {
              let video_tag_details_update = this.state.video_tag_details;
              video_tag_details_update[0].tag_categ_name = event.target.value;
              this.setState({ video_tag_details: video_tag_details_update });
            }}
          >
            <option value="" disabled selected>
              {languagePack.select_type}
            </option>
            {this.props.types_events.map((elem) => {
              return <option value={elem}>{elem}</option>;
            })}
          </select>
          <select
            className="ui selection dropdown event-type"
            value={this.state.video_tag_details[0].player_name}
            onChange={(e) => {
              let video_tag_details_update = this.state.video_tag_details;
              video_tag_details_update[0].id_team = e.target.selectedOptions[0].getAttribute(
                "id_team"
              );
              video_tag_details_update[0].id_player = e.target.selectedOptions[0].getAttribute(
                "id_player"
              );
              video_tag_details_update[0].player_name = e.target.value;
              this.setState({ video_tag_details: video_tag_details_update });
            }}
          >
            <option value="" disabled selected>
              {languagePack.select_player}
            </option>
            {this.props.players.map((elem) => {
              return (
                <option
                  value={elem.player_name}
                  id_team={elem.id_team}
                  id_player={elem.id_player}
                >
                  {elem.player_name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="video-title-description">
          <input
            className="description"
            value={this.state.video_tag_title}
            onChange={(event) => {
              this.setState({ video_tag_title: event.target.value });
            }}
            placeholder="Title"
          />
          <textarea
            className="description"
            value={this.state.video_tag_description}
            onChange={(event) => {
              this.setState({ video_tag_description: event.target.value });
            }}
            placeholder="Description"
          />
        </div>
        {this.state.video_tag_details.map((elem, index_tag) => {
          if (index_tag > 0 && elem.deleted == "0") {
            return (
              <div
                className="generated-row video-event-row"
                key={"tag_additional" + index_tag}
              >
                <select
                  className="ui selection dropdown event-type"
                  value={elem.tag_categ_name}
                  onChange={(event) => {
                    let video_tag_details_update = this.state.video_tag_details;
                    video_tag_details_update[index_tag].tag_categ_name =
                      event.target.value;
                    this.setState({
                      video_tag_details: video_tag_details_update,
                    });
                  }}
                >
                  <option value="" disabled selected>
                    {languagePack.select_type}
                  </option>
                  {this.props.types_events.map((elem) => {
                    return <option value={elem}>{elem}</option>;
                  })}
                </select>
                <select
                  className="ui selection dropdown event-type"
                  value={elem.player_name}
                  onChange={(e) => {
                    let video_tag_details_update = this.state.video_tag_details;
                    video_tag_details_update[
                      index_tag
                    ].id_team = e.target.selectedOptions[0].getAttribute(
                      "id_team"
                    );
                    video_tag_details_update[
                      index_tag
                    ].id_player = e.target.selectedOptions[0].getAttribute(
                      "id_player"
                    );
                    video_tag_details_update[index_tag].player_name =
                      e.target.value;
                    this.setState({
                      video_tag_details: video_tag_details_update,
                    });
                  }}
                >
                  <option value="" disabled selected>
                    {languagePack.select_player}
                  </option>
                  {this.props.players.map((elem) => {
                    return (
                      <option
                        value={elem.player_name}
                        id_team={elem.id_team}
                        id_player={elem.id_player}
                      >
                        {elem.player_name}
                      </option>
                    );
                  })}
                </select>
                <div
                  className="button-container"
                  style={{ padding: "0", height: "auto" }}
                >
                  <button
                    className="additional-button"
                    onClick={() => {
                      let video_tag_details_update = this.state
                        .video_tag_details;
                      video_tag_details_update.splice(index_tag, 1);
                      this.setState({
                        video_tag_details: video_tag_details_update,
                      });
                    }}
                  >
                    <Icon
                      className="additional-icon"
                      color="red"
                      name="close"
                      size="small"
                      style={{ cursor: "pointer" }}
                    />
                  </button>
                </div>
              </div>
            );
          }
        })}
        <div className="plus-button">
          <button
            className="additional-button"
            onClick={() => {
              let video_tag_details_update = this.state.video_tag_details;
              let new_tag_details = {
                date_add: "",
                deleted: "0",
                id_player: "",
                id_team: "",
                id_video_tag: "",
                id_video_tag_categ: "",
                id_video_tag_detail: "",
                player_name: "",
                tag_categ_name: "",
              };
              video_tag_details_update.push(new_tag_details);
              this.setState({ video_tag_details: video_tag_details_update });
            }}
          >
            <Icon
              className="additional-icon"
              name="add"
              size="small"
              style={{ cursor: "pointer" }}
            />
          </button>
        </div>
        <div className="video-event-row button-row">
          <Button
            className="button"
            style={{ marginRight: "25px" }}
            disabled={
              this.state.video_tag_title == "" ||
              this.state.video_tag_details[0].tag_categ_name == ""
              // ||
              // this.state.video_tag_details[0].player_name == ""
            }
            onClick={() => {
              this.saveEvent();
            }}
          >
            Add Event
          </Button>
          <button
            className="button"
            style={{ backgroundColor: "red" }}
            onClick={() => {
              this.props.resetState();
            }}
          >
            Close
          </button>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ logout }, dispatch);
}

export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(AddEventVideoPlayer);
