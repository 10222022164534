import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { logout } from "./../../../utils/api";
import moment from "moment";
import { Icon, Button } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import "./MedicalCertificates.scss";
import AddMedicalCertificate from "./AddMedicalCertificate";
import dateFormat from "dateformat";
import ExpandedImage from "../../SiteAdmin/Photos/ExpandedImage";
import { Redirect } from "react-router-dom";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import { TFBTableCell, sxStickyColumn } from "../../../components/reusable/useStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddMedicalTests from "./AddMedicalTests";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";

class MedicalCertificates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seasons: [],
      id_season: "",
      current_season: "",
      table_data: [],
      team_name: "",
      open_add_certificate_popup: false,
      open_add_medical_tests: false,
      add_row: "",
      expandedImage: false,
      expandedImageSrc: "",

      hasAccessToTeam: false,
      redirectToTeams: false,
    };
  }

  componentDidMount = () => {
    this.hasAccessToTeam();
  };

  hasAccessToTeam = () => {
    axios
      .get(
        `user/has_access_to_team?id_team=${this.props.match.params.id}&id_user=${this.props.currentUser.id_user}`
      )
      .then((res) => {
        if (res.data == 1) {
          this.setState({
            hasAccessToTeam: true,
          });
          this.getSeasons();
          this.getPlayersAndCertificatesTable();
        } else {
          this.setState({
            redirectToTeams: true,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getSeasons = () => {
    axios
      .get(`club/get_seasons?id_team=${this.props.match.params.id}`)
      .then((res) =>
        this.setState({
          seasons: res.data.seasons,
          id_season: res.data.activeSeason,
          current_season: res.data.activeSeason,
        })
      )
      .catch((err) => console.log(err));
  };

  getPlayersAndCertificatesTable = () => {
    axios
      .get(
        `club/get_team_medical_certificates_2?id_team=${this.props.match.params.id}&id_user=${this.props.currentUser.id_user}&id_club=${this.props.currentUser.id_club}&token=${this.props.token}`
      )
      .then((res) => {
        this.setState({
          table_data: res.data.players ?? [],
          team_name: res.data.team_name,
        });
      })
      .catch((err) => console.log(err));
  };

  openAddCertificate = () => {
    this.setState({
      open_add_certificate_popup: true,
    });
  }

  closeAddCertificate = () => {
    this.setState({
      open_add_certificate_popup: false,
    });
  }

  openAddMedicalTests = () => {
    this.setState({
      open_add_medical_tests: true,
    });
  }

  closeAddMedicalTests = () => {
    this.setState({
      open_add_medical_tests: false,
    });
  }

  render() {
    const { languagePack } = this.props;

    if (this.state.redirectToTeams) {
      return <Redirect to="/club" />;
    }

    return (
      <>
        {this.state.hasAccessToTeam && (
          <TFBCard>
            <TFBCardHeader title={languagePack.medical_certificates + " - " + this.state.team_name}>
              <TFBButton
                color="green"
                renderIcon={() => <TFBIcon name="add" />}
                onClick={this.openAddCertificate}
              >
                {languagePack.add_medical_certificate}
              </TFBButton>
              <TFBButton
                color="green"
                renderIcon={() => <TFBIcon name="add" />}
                onClick={this.openAddMedicalTests}
              >
                {languagePack.add_medical_tests}
              </TFBButton>
            </TFBCardHeader>
            <TFBCardBody>
              {this.state.table_data.length > 0 ?
                <TableContainer>
                  <Table faria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TFBTableCell sx={sxStickyColumn}>
                          {languagePack.active_injuries_player_name}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {languagePack.date_of_certificate_examination}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {languagePack.expiry_date_examination}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {languagePack.proof_of_medical_certificate}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {languagePack.date_of_medical_tests_examination}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {languagePack.expiry_date_medical_tests}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {languagePack.proof_of_medical_tests}
                        </TFBTableCell>
                        <TFBTableCell>
                        </TFBTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.table_data.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                        >
                          <TFBTableCell
                            sx={sxStickyColumn}
                            style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                          >
                            <div className="cursor-pointer"
                              onClick={() => {
                                this.props.history.push(
                                  `/club/player/${row.id_player}?id_season=${this.state.id_season}`
                                );
                              }}
                            >
                              {row.player_name}
                            </div>
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            <div style={{ color: row.date_last_examination == null ? "red" : "" }}>
                              {row.date_last_examination == null ? "-" : moment(row.date_last_examination).format("DD.MM.YYYY")}
                            </div>
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            <div style={{ color: row.date_expire == null || moment(row.date_expire) < moment() ? "red" : "" }}>
                              {row.date_expire == null ? "-" : moment(row.date_expire).format("DD.MM.YYYY")}
                            </div>
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            <div style={{ color: row.certificate_img == null ? "red" : "" }}>
                              {row.certificate_img == null || row.certificate_img == "" ?
                                "-" :
                                // <div className="cursor-pointer"
                                //   onClick={() => {
                                //     this.setState({
                                //       expandedImage: true,
                                //       expandedImageSrc: row.certificate_url,
                                //     });
                                //   }}
                                // >
                                //   <TFBIcon color="black" />
                                // </div>
                                <div className="actions-btns-container">
                                  <TFBIconButton
                                    name="enlarge"
                                    color="black"
                                    onClick={() => {
                                      this.setState({
                                        expandedImage: true,
                                        expandedImageSrc: row.certificate_url,
                                      });
                                    }}
                                  />
                                </div>
                              }
                            </div>
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            <div style={{ color: row.date_last_examination_medical_tests == null ? "red" : "" }}>
                              {row.date_last_examination_medical_tests == null ? "-" : moment(row.date_last_examination_medical_tests).format("DD.MM.YYYY")}
                            </div>
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            <div style={{ color: row.date_expire_medical_tests == null || moment(row.date_expire_medical_tests) < moment() ? "red" : "" }}>
                              {row.date_expire_medical_tests == null ? "-" : moment(row.date_expire_medical_tests).format("DD.MM.YYYY")}
                            </div>
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            <div style={{ color: row.certificate_img_medical_tests == null ? "red" : "" }}>
                              {row.certificate_img_medical_tests == null || row.certificate_img_medical_tests == "" ?
                                "-" :
                                <a className="actions-btns-container" href={`https://api.thefootballbrain.app/club/download_medical_tests?url=${row.medical_tests_url}&doc_name=${row.certificate_img_medical_tests}&player_name=${row.player_name}&token=${this.props.token}`}>
                                  <TFBIconButton name="arrow-down" color="black" />
                                </a>
                              }
                            </div>
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            <div className="actions-btns-container" style={{ justifyContent: "flex-end" }}>
                              {(row.date_expire == null || moment(row.date_expire) < moment()) && <TFBIcon name="x-large" color="red" />}
                            </div>
                          </TFBTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer> :
                <TFBPlaceholder text={languagePack.no_medical_certificates} />
              }
            </TFBCardBody>
            {this.state.expandedImage && (
              <ExpandedImage
                closeExpand={() => this.setState({ expandedImage: false })}
                image={this.state.expandedImageSrc}
              />
            )}
            {this.state.open_add_certificate_popup &&
              <AddMedicalCertificate
                isOpenPopup={this.state.open_add_certificate_popup}
                closePopup={this.closeAddCertificate}
                refreshList={this.getPlayersAndCertificatesTable}
                id={this.props.match.params.id}
              />
            }
            {this.state.open_add_medical_tests &&
              <AddMedicalTests
                isOpenPopup={this.state.open_add_medical_tests}
                closePopup={this.closeAddMedicalTests}
                refreshList={this.getPlayersAndCertificatesTable}
                id={this.props.match.params.id}
              />
            }
          </TFBCard>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(MedicalCertificates);
