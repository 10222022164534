import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { logout } from "./../../../utils/api";
import moment from "moment";
import { bindActionCreators } from "redux";
import "./../Financial.scss";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBDropdown from "../../../components/design-system/Dropdown/TFBDropdown";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TFBTableCell, sxStickyColumn } from "../../../components/reusable/useStyles";

class PaymentsToClub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seasons: [],
      id_season: "",
      current_season: "",
      table_data: [],
      team_name: "",
      open_add_certificate_popup: false,
      add_row: "",
      expandedImage: false,
      expandedImageSrc: "",

      year: "toate",
      payment_years: [],
      months: [
        "Ianuarie",
        "Februarie",
        "Martie",
        "Aprilie",
        "Mai",
        "Iunie",
        "Iulie",
        "August",
        "Septembrie",
        "Octombrie",
        "Noiembrie",
        "Decembrie",
      ],
    };
  }

  componentDidMount = () => {
    this.getYears();
    this.getPlayersPayments();
  };

  getYears = () => {
    axios
      .get(
        `payment/get_player_payments_years?id_player=${this.props.id_player}`
      )
      .then((res) =>
        this.setState({
          payment_years: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  getPlayersPayments = () => {
    axios
      .get(
        `payment/get_player_payments_2?id_player=${this.props.id_player}&year=${this.state.year}`
      )
      .then((res) => {
        this.setState({
          table_data: res.data.list,
        });
      })
      .catch((err) => console.log(err));
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value }, this.getPlayersPayments);
  };

  render() {
    const { languagePack } = this.props;

    return (
      <TFBCard style={{ height: "100%" }}>
        <TFBCardHeader title={languagePack.payment_history}>
          <TFBDropdown
            placeholder="Anul"
            selection
            options={this.state.payment_years}
            name="year"
            color="gray"
            value={this.state.year}
            onChange={this.handleChangeDropdown}
          />
        </TFBCardHeader>
        <TFBCardBody>
          {this.state.table_data.length > 0 ?
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn}>
                      {languagePack.transactions}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.transaction_type}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.transaction_date}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.category_cost_sum}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.table_data.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                    >
                      <TFBTableCell
                        sx={sxStickyColumn}
                        style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                      >
                        {row.about_payment}
                      </TFBTableCell>
                      <TFBTableCell align="center">{languagePack.payment_types[row.payment_type_label]}</TFBTableCell>
                      <TFBTableCell align="center">
                        {moment(row.date_add).format("DD.MM.YYYY")}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.payment_amount + " RON"}
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> :
            <TFBPlaceholder text={languagePack.no_player_transactions} />}
        </TFBCardBody>
      </TFBCard>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(PaymentsToClub);
