import React from "react";
import TFBIcon from "../Icon/TFBIcon";
import { NoDataPlaceholder } from "../types";
import { PlaceHolderWrapper } from "./styles";

const TFBPlaceholder = ({ text, style }: NoDataPlaceholder) => {
  return (
    <PlaceHolderWrapper style={style}>
      <TFBIcon
        name="zoom-out"
        color="black"
        className="nodata-placeholder-icon"
      />
      <div className="nodata-placeholder-text"> {text} </div>
    </PlaceHolderWrapper>
  );
};

export default TFBPlaceholder;
