import React from "react";
import "./SendEmail.scss";
import { connect } from "react-redux";

class SendEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mail_title: "",
    };
  }
  render() {
    return (
      <div className="row">
        <div className="col-lg-6">
          {" "}
          <div className="card card-custom">
            <div className="send-email-container">
              <div className="send-email-header">
                <div className="send-email-title">Trimite email</div>
              </div>
              <div className="send-email-row">
                <input
                  className="email-title-input"
                  value={this.state.mail_title}
                  onChange={(e) =>
                    this.setState({ mail_title: e.target.value })
                  }
                  placeholder="Subiect"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          {" "}
          <div className="card card-custom">
            <div className="send-email-container">
              <div className="send-email-header">
                <div className="send-email-title">Preview continut</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(SendEmail);
