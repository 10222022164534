import React from "react";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { Icon, Button, Dropdown } from "semantic-ui-react";
import axios from "axios";
import * as myConstants from "./../../../../app/utils/constants";
import ElementOperations from "../Partner/ElementOperations";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "react-notifications-component";
import TotalDebtsChart from "../../../components/charts/Financial/TotalDebtsChart";
import delete_icon from "../../../assets/icons/furnizori-delete.svg";
import card_icon from "../../../assets/icons/furnizori-card.svg";
import history_icon from "../../../assets/icons/furnizori-istoric.svg";
import AddNewProvider from "./AddNewProvider";

const currencies = [
  { key: "EUR", value: "EUR", text: "EURO" },
  { key: "RON", value: "RON", text: "RON" },
];

class Providers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      debts: [],
      total_unpaid: "",
      deletePopup: false,
      wantToDeleteItem: [],
      wantToEditItem: [],
      total: "",
      rates: [],
      editElementPopup: false,
      sortConfig: {
        type: "name",
        descending: false,
      },
      selectedCurrency: "",
      addProviderPopup: false,
    };
  }

  componentDidMount = () => {
    this.getDebts();
    this.getCurrency();
  };

  getCurrency = () => {
    fetch("https://api.thefootballbrain.app/club/get_currency_rates")
      .then((res) => res.json())
      .then((data) => this.setState({ rates: data }));
  };

  getDebts = () => {
    axios
      .get(`finances/get_debts?id_club=${this.props.currentUser.id_club}`)
      .then((res) =>
        this.setState({
          debts: Array.isArray(res.data.debts) ? res.data.debts : [],
          total_ron: res.data.total_ron,
          total_eur: res.data.total_eur,
        })
      )
      .catch((err) => console.log(err));
  };

  deleteDebt = () => {
    const payment = {
      id: this.state.wantToDeleteItem.id,
    };
    axios
      .post("finances/delete_debt", payment)
      .then(() => {
        this.getDebts();
        this.setState({ deletePopup: false });
        store.addNotification({
          title: "Operatiuni financiare",
          message: "Furnizorul a fost eliminat cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  handleChangePaymentType = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  formatNumber = (value) => {
    return (
      <NumberFormat
        // value={this.convertCurrency(value)}
        value={value}
        displayType={"text"}
        decimalScale={2}
        thousandSeparator={true}
      />
    );
  };

  changeFilter = (type) => {
    if (this.state.sortConfig.type !== type) {
      this.setState({ sortConfig: { type: type, descending: false } });
    } else {
      this.setState({
        sortConfig: {
          type: type,
          descending: !this.state.sortConfig.descending,
        },
      });
    }
  };

  sortData = () => {
    const items = this.state.debts;
    const sortOption = this.state.sortConfig;
    const rates = this.state.rates;

    function convertCurrency(value, currency) {
      if (currency === "EUR") {
        return value;
      } else {
        return value / rates["RON"];
      }
    }

    if (sortOption.type === "amount") {
      items.sort(function(a, b) {
        if (sortOption.descending)
          return (
            convertCurrency(b.total_amount, b.currency) -
            convertCurrency(a.total_amount, a.currency)
          );
        else
          return (
            convertCurrency(a.total_amount, a.currency) -
            convertCurrency(b.total_amount, b.currency)
          );
      });
    }
    if (sortOption.type === "name") {
      items.sort(function(a, b) {
        if (sortOption.descending)
          return b.partner_name.localeCompare(a.partner_name);
        else return a.partner_name.localeCompare(b.partner_name);
      });
    } else return items;
  };

  convertedCurrency = (value, selectedCurr, Curr) => {
    const rates = this.state.rates;
    if (selectedCurr === Curr) return Math.round(value);
    else if (selectedCurr === "EUR" && Curr === "RON") {
      return Math.round(value / rates["RON"]);
    } else if (selectedCurr === "RON" && Curr === "EUR") {
      return Math.round(value * rates["RON"]);
    }
  };

  render() {
    const { languagePack } = this.props;
    const selectedCurrencyIcon =
      this.state.selectedCurrency === "EUR" ? (
        <Icon name="eur" color="black" className="mg--0" />
      ) : (
        <span style={{ fontWeight: 700, marginLeft: 5 }}> RON </span>
      );

    this.sortData();

    return (
      <div className="card card-custom">
        <div className="debts-header">
          <div className="debts-table-title">Furnizori</div>
          <div className="providers-header-right">
            <Dropdown
              placeholder="Selecteaza moneda"
              selection
              options={currencies}
              value={this.state.selectedCurrency}
              clearable
              onChange={(e, { value }) =>
                this.setState({ selectedCurrency: value })
              }
            />
            <Button
              className="add-provider-button"
              onClick={() => this.setState({ addProviderPopup: true })}
            >
              <div className="provider-button-content">
                Adauga
                <Icon name="add" className="white-icon" />
              </div>
            </Button>
          </div>
        </div>
        <div className="debts-table-container">
          <div className="debts-table-header">
            <div
              className="name-column"
              onClick={() => this.changeFilter("name")}
            >
              {languagePack.partner_name}
              {this.state.sortConfig.type === "name" &&
                (this.state.sortConfig.descending ? (
                  <Icon name="arrow down" />
                ) : (
                  <Icon name="arrow up" />
                ))}
            </div>
            <div
              className="sum-column"
              onClick={() => this.changeFilter("amount")}
              style={{ cursor: "pointer" }}
            >
              {languagePack.unpaid_amount}
              {this.state.sortConfig.type === "amount" &&
                (this.state.sortConfig.descending ? (
                  <Icon name="arrow down" />
                ) : (
                  <Icon name="arrow up" />
                ))}
            </div>
            <div className="date-column">{languagePack.last_payment}</div>
            <div className="actions-column">Actiuni</div>
          </div>
          <div className="debts-list">
            {this.state.debts.map((item, index) => {
              return (
                <div
                  className="debt-row"
                  style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "" }}
                  key={index}
                >
                  <div
                    className="name-column uppercase"
                    onClick={() =>
                      this.props.history.push(`/financial/partner/${item.id}`)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {item.partner_name}
                  </div>
                  <div className="sum-column">
                    <div>
                      {this.state.selectedCurrency === ""
                        ? this.formatNumber(Math.round(item.total_amount))
                        : this.formatNumber(
                            this.convertedCurrency(
                              item.total_amount,
                              this.state.selectedCurrency,
                              item.currency
                            )
                          )}
                    </div>
                    {this.state.selectedCurrency === "" ? (
                      <>
                        {item.currency === "EUR" ? (
                          <Icon name="eur" className="mg--0 currency-icon" />
                        ) : (
                          <div style={{ marginLeft: 5 }}>RON</div>
                        )}{" "}
                      </>
                    ) : (
                      selectedCurrencyIcon
                    )}
                  </div>
                  <div className="date-column">
                    {item.last_payment ? (
                      <Moment format="DD.MM.YYYY">{item.last_payment}</Moment>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="actions-column">
                    <span className="svg-icon-debts">
                      <img
                        src={card_icon}
                        alt="card"
                        onClick={() => {
                          const item_data = {
                            partner_name: item.partner_name,
                            id: item.id,
                            total_amount: item.total_amount,
                            currency: item.currency,
                          };
                          this.setState({
                            editElementPopup: true,
                            wantToEditItem: item_data,
                          });
                        }}
                      />
                    </span>
                    <span className="svg-icon-debts">
                      <img
                        src={history_icon}
                        alt="history"
                        onClick={() =>
                          this.props.history.push(
                            `/financial/partner/${item.id}`
                          )
                        }
                      />
                    </span>
                    <span className="svg-icon-debts">
                      <img
                        src={delete_icon}
                        alt="delete"
                        onClick={() => {
                          const item_data = {
                            name: item.debt_name,
                            id: item.id,
                            unpaid: item.total_amount,
                            currency: item.currency,
                          };
                          this.setState({
                            deletePopup: true,
                            wantToDeleteItem: item_data,
                          });
                        }}
                      />
                    </span>
                  </div>
                </div>
              );
            })}
            <div className="debt-row green-color">
              <div className="name-column uppercase">Total:</div>
              <div className="total-column">
                {this.state.selectedCurrency === "EUR" ? (
                  <div className="debts-total-row">
                    {this.formatNumber(Math.round(this.state.total_eur))}{" "}
                    <Icon
                      name="eur"
                      className=" currency-icon mg--0 green-color"
                    />
                  </div>
                ) : (
                  <>
                    {this.state.selectedCurrency === "RON" ? (
                      <div className="debts-total-row">
                        {this.formatNumber(Math.round(this.state.total_ron))}
                        <div style={{ marginLeft: 5 }}>RON</div>
                      </div>
                    ) : (
                      <>
                        <div className="debts-total-row">
                          {this.formatNumber(Math.round(this.state.total_eur))}{" "}
                          <Icon
                            name="eur"
                            className=" currency-icon mg--0 green-color"
                          />
                        </div>
                        <div className="debts-total-row">
                          {this.formatNumber(Math.round(this.state.total_ron))}
                          <div style={{ marginLeft: 5 }}>RON</div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <Dialog
          maxWidth="md"
          open={this.state.deletePopup}
          // onClose={() => this.setState({ deletePopup: false })}
          aria-labelledby="responsive-dialog-title"
        >
          <div className="element-operations-popup-title">Sterge element</div>

          <DialogContent>
            <div className="provider-delete-popup">
              <div className="row-1">
                Esti sigur ca vrei sa elimini aceast furnizor?{" "}
              </div>
              <div className="row-2">Datoria nu este platita integral</div>
              <div className="row-3">
                Suma restanta:{" "}
                <div style={{ marginLeft: 5 }}>
                  {this.formatNumber(this.state.wantToDeleteItem.unpaid)}
                </div>
                {this.state.wantToDeleteItem.currency === "EUR" ? (
                  <Icon name="eur" color="black" className="mg--0" />
                ) : (
                  <div style={{ marginLeft: 5 }}>RON</div>
                )}
              </div>
              <div className="delete-popup-buttons-container">
                <Button
                  className="delete-popup-button-cancel"
                  onClick={() => this.setState({ deletePopup: false })}
                >
                  {languagePack["admin_cancel"]}
                </Button>
                <Button
                  className="delete-popup-button-delete"
                  onClick={this.deleteDebt}
                >
                  {languagePack["admin_delete"]}
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          maxWidth="md"
          open={this.state.editElementPopup}
          // onClose={() => this.setState({ editElementPopup: false })}
          aria-labelledby="responsive-dialog-title"
        >
          <div className="element-operations-popup-title">
            {this.state.wantToEditItem.partner_name}
          </div>

          <DialogContent>
            <ElementOperations
              partner={this.state.wantToEditItem}
              section="debts"
              getDebts={() => this.getDebts()}
              closeOperationsPopup={() =>
                this.setState({ editElementPopup: false })
              }
            />
          </DialogContent>
        </Dialog>

        <Dialog
          maxWidth="md"
          open={this.state.addProviderPopup}
          // onClose={() => this.setState({ addProviderPopup: false })}
          aria-labelledby="responsive-dialog-title"
        >
          <div className="element-operations-popup-title">
            {languagePack["add_supplier"]}
          </div>

          <DialogContent>
            <AddNewProvider
              closePopup={() => this.setState({ addProviderPopup: false })}
              id_club={this.props.currentUser.id_club}
              refreshList={this.getDebts}
            />
          </DialogContent>
        </Dialog>
        {/* <div className="row" style={{ paddingTop: 20 }}>
          <TotalDebtsChart />
        </div> */}
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  currencyItemSelected:
    myConstants.currencies[auth?.user?.currency] || myConstants.currencies.EUR,
}))(Providers);
