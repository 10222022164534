import React from "react";
import { Dropdown, Button, Icon, TextArea } from "semantic-ui-react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import Select from "react-select";
import { connect } from "react-redux";
import { store } from "react-notifications-component";

class AddInjuryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown_teams: [],
      dropdown_players: [],
      dropdown_zones: [],
      selected_team: "",
      selected_player: null,
      selected_zone: "",
      injury_date: null,
      est_recovery_date: null,
      error: "",
      description: "",
      another_zone: "",
    };
  }

  dropdownRef = React.createRef();

  componentDidMount = () => {
    this.get_teams();
    this.get_zones();
  };

  handleChangeDropdownZone = (e, value) => {
    this.setState({ [value.name]: value.value });
  };

  handleChangeDropdown = (selected, value) => {
    this.setState({ [value.name]: selected });
  };

  handleChangeDropdownTeam = (selected, value) => {
    this.setState({ [value.name]: selected }, () => {
      this.get_players();
    });
  };

  handleDateChange = (value) => {
    this.setState({ injury_date: value });
  };

  handleRecDateChange = (value) => {
    this.setState({ est_recovery_date: value });
  };

  get_teams = () => {
    axios
      .get("upload/get_teams_dropdown_injury", {
        params: {
          token: this.props.token,
        },
      })
      .then((res) => {
        res.data.shift();
        this.setState({ dropdown_teams: res.data });
      })
      .catch((err) => console.log(err));
  };

  get_players = () => {
    axios
      .get(`upload/get_players_dropdown_injury`, {
        params: {
          id_team: this.state.selected_team.value,
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState({ dropdown_players: res.data });
        }
      })
      .catch((err) => console.log(err));
  };

  get_zones = () => {
    axios
      .get("medical/get_injury_zones")
      .then((res) => {
        this.setState({ dropdown_zones: res.data });
      })
      .catch((err) => console.log(err));
  };

  addNewInjury = () => {
    const injury = {
      id_player: this.state.selected_player.value,
      injury_date: this.state.injury_date,
      injury_type: this.state.selected_zone,
      estimated_recovery_date: this.state.est_recovery_date,
      injury_description: this.state.description,
    };

    if (this.state.selected_zone == 0) {
      injury.another_type = this.state.another_zone;
    }

    if (
      this.state.selected_zone.length > 0 &&
      this.state.selected_player.label.length > 0
    ) {
      if (injury.estimated_recovery_date > injury.injury_date) {
        axios.post("medical/add_injury", injury).then(() => {
          this.props.update_players();
          this.props.close_popup(false);
          store.addNotification({
            title: this.props.languagePack["injury_management_title"],
            message: this.props.languagePack["injury_added_success"],
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        });
      } else {
        this.setState({
          error: this.props.languagePack["rec_date_not_before_inj_date"],
        });
      }
    } else {
      this.setState({
        error: this.props.languagePack["all_fields_mandatory_error"],
      });
    }
  };

  handleItemClick = (_e, x) => {
    this.dropdownRef.current.handleItemClick(_e, x);
  };

  render() {
    const { languagePack } = this.props;
    const selected_zone = this.state.dropdown_zones.find(
      (item) => item.value === this.state.selected_zone
    );
    return (
      <div className="add-injury-form-container">
        <div className="add-injury-row">
          <Select
            placeholder={languagePack["select_team"]}
            value={this.state.selected_team}
            options={this.state.dropdown_teams}
            name="selected_team"
            onChange={(selected, value) => {
              this.handleChangeDropdownTeam(selected, value);
              if (this.state.selected_team.value != selected.value) {
                this.setState({ selected_player: null });
              }
            }}
            styles={{
              container: (provided, state) => ({
                ...provided,
                backgroundColor: "#fafafa",
                width: "100%",
              }),
              control: (provided, state) => ({
                ...provided,
                backgroundColor: "#fafafa",
                height: "50px",
                border: "none",
                "&:hover": {
                  border: "none",
                  boxShadow: "0 0 0 2px black",
                },
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                paddingLeft: "22px",
                fontSize: "14px",
                fontWeight: 700,
              }),
              placeholder: (provided, state) => ({
                ...provided,
                color: "#b2b2b2",
              }),
              indicatorSeparator: (provided, state) => ({
                ...provided,
                display: "none",
              }),
              menu: (provided, state) => ({
                ...provided,
                maxHeight: "350px",
              }),
              menuPortal: (provided, state) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            menuPortalTarget={document.body}
          />
        </div>
        <div className="add-injury-row">
          <Select
            placeholder={languagePack["select_player"]}
            disabled={!this.state.selected_team.value}
            value={this.state.selected_player}
            options={this.state.dropdown_players}
            name="selected_player"
            onChange={(selected, value) => {
              this.handleChangeDropdown(selected, value);
            }}
            styles={{
              container: (provided, state) => ({
                ...provided,
                backgroundColor: "#fafafa",
                width: "100%",
              }),
              control: (provided, state) => ({
                ...provided,
                backgroundColor: "#fafafa",
                height: "50px",
                border: "none",
                "&:hover": {
                  border: "none",
                  boxShadow: "0 0 0 2px black",
                },
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                paddingLeft: "22px",
                fontSize: "14px",
                fontWeight: 700,
              }),
              placeholder: (provided, state) => ({
                ...provided,
                color: "#b2b2b2",
              }),
              indicatorSeparator: (provided, state) => ({
                ...provided,
                display: "none",
              }),
              menu: (provided, state) => ({
                ...provided,
                maxHeight: "350px",
              }),
              menuPortal: (provided, state) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            menuPortalTarget={document.body}
          />
        </div>
        <div className="add-injury-row">
          <Dropdown
            placeholder={languagePack["select_zone"]}
            fluid
            selection
            className="add-injury-custom-dropdown"
            value={this.state.selected_zone}
            name="selected_zone"
            text={selected_zone ? languagePack[selected_zone.key] : ""}
            onChange={this.handleChangeDropdownZone}
            ref={this.dropdownRef}
          >
            <Dropdown.Menu>
              {this.state.dropdown_zones.map((item) => (
                <Dropdown.Item
                  key={item.value}
                  value={item.value}
                  text={languagePack[item.key]}
                  active={item.value === this.state.selected_zone}
                  onClick={this.handleItemClick}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {selected_zone?.value == 0 && (
          <div className="add-injury-row">
            <input
              placeholder={"Denumire zona"}
              className="injury-date-input"
              value={this.state.another_zone}
              onChange={(e) => this.setState({ another_zone: e.target.value })}
            />
          </div>
        )}
        <div
          className="add-injury-row"
          style={{ minHeight: "50px", height: "inherit" }}
        >
          <TextArea
            placeholder={languagePack["injury_details"]}
            className="text-area-edit-injury"
            value={this.state.description}
            onChange={(e) => this.setState({ description: e.target.value })}
          />
        </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="add-injury-row">
            <DatePicker
              value={this.state.injury_date}
              onChange={this.handleDateChange}
              format="dd MMMM yyyy"
              name="injury_date"
              inputVariant="outlined"
              className="injury-date-input"
              emptyLabel={languagePack["active_injuries_injury_date"]}
            />
            <Icon
              name="calendar alternate outline"
              className="calendar-icon"
              size="small"
            />
          </div>
          <div className="add-injury-row">
            <DatePicker
              value={this.state.est_recovery_date}
              onChange={this.handleRecDateChange}
              format="dd MMMM yyyy"
              name="est_recovery_date"
              inputVariant="outlined"
              className="injury-date-input"
              emptyLabel={languagePack["active_injuries_estimated_recovery"]}
            />
            <Icon
              name="calendar alternate outline"
              className="calendar-icon"
              size="small"
            />
          </div>
        </MuiPickersUtilsProvider>
        <div className="error-container">{this.state.error}</div>

        <div className="injury-edit-delete-buttons">
          <Button
            className="custom-button-dual add-treatment-button"
            onClick={this.addNewInjury}
            style={{ height: 50 }}
            disabled={
              !(
                this.state.selected_player &&
                this.state.selected_zone.length > 0 &&
                this.state.injury_date
              ) &&
              !(this.state.selected_zone == 0 && this.state.another_zone != "")
            }
          >
            <div className="button-content">
              {languagePack["admin_add"]}
              <Icon name="add" className="add-icon" />
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(AddInjuryForm);
