import React, { useState, useEffect } from "react";
import "./CompetitionPage.scss";
import edit from "../../../../assets/icons/edit.png";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import AddTeam from "../AddTeam/AddTeam";
import axios from "axios";
import EditTeamCompetition from "../AddTeam/EditTeamCompetition";

function CompetitionTeams(props) {
  const { languagePack } = props;
  const [addTeamPopup, setAddTeamPopup] = useState(false);
  const [editTeamPopup, setEditTeamPopup] = useState(false);
  const [deleteTeamPopup, setDeleteTeamPopup] = useState(false);
  const [teams, setTeams] = useState([]);
  const [editedTeam, setEditedTeam] = useState({});
  const [deletedTeam, setDeletedTeam] = useState("");

  useEffect(() => {
    getCompetitionTeams();
  }, []);

  const getCompetitionTeams = () => {
    axios
      .get(`match/get_competition_teams?id_competition=${props.id_competition}`)
      .then((res) => {
        setTeams(res.data);
      })
      .catch((err) => console.log(err));
  };

  const deleteTeam = () => {
    axios
      .post(`match/delete_team`, {
        id_team: deletedTeam,
      })
      .then((res) => {
        getCompetitionTeams();
        props.updateCompetitionDetails();
        props.updateCompetitionMatches();
        setDeletedTeam("");
        setDeleteTeamPopup(false);
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div className="competition-page-matches-header">
        <div className="competition-page-matches-title">
          {/* {languagePack["matches_schedule"]} */}
          {languagePack.teams_from_competition}
        </div>
        {props.hasAccessToEditCompetition && (
          <Button
            className="add-match-button"
            onClick={() => setAddTeamPopup(true)}
          >
            {/* {languagePack["add_match"]} */}
            {languagePack.add_team}
            <Icon name="add" className="white-icon" />
          </Button>
        )}
      </div>
      <div className="competition-teams-container">
        {teams.map((elem, index) => {
          return (
            <div key={"competition-team-" + index} className="team-container">
              <div className="logo-container">
                <img
                  src={`https://api.thefootballbrain.app/uploads/team_logos/${elem.id_manual_competition_team}logo.png`}
                  alt="team_logo"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src =
                      "https://api.thefootballbrain.app/uploads/placeholder_team.png";
                  }}
                />
              </div>
              <div className="team-name">{elem.team_name}</div>
              {props.hasAccessToEditCompetition && (
                <div className="modify-buttons">
                  <Icon
                    className="button btn-edit"
                    name="pencil"
                    onClick={() => {
                      setEditedTeam(elem);
                      setEditTeamPopup(true);
                    }}
                  />
                  <Icon
                    className="button btn-delete"
                    name="trash alternate"
                    onClick={() => {
                      setDeletedTeam(elem.id_manual_competition_team);
                      setDeleteTeamPopup(true);
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>

      <Dialog
        open={addTeamPopup}
        // onClose={() => setAddTeamPopup(false)}
      >
        <DialogContent className="add-team-manual-wrapper">
          <AddTeam
            id_competition={props.id_competition}
            closePopup={() => setAddTeamPopup(false)}
            updateCompetitionDetails={() => props.updateCompetitionDetails()}
            getTeams={() => {
              getCompetitionTeams();
              // if (this.state.competition_type === "group-stage") {
              // this.getDetails();
              // }
            }}
            // groups={this.state.groups}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={editTeamPopup}
        // onClose={() => setEditTeamPopup(false)}
      >
        <DialogContent className="add-team-manual-wrapper">
          <EditTeamCompetition
            // id_competition={props.id_competition}
            editedTeam={editedTeam}
            closePopup={() => setEditTeamPopup(false)}
            getTeams={() => {
              getCompetitionTeams();
            }}
            updateCompetitionDetails={() => props.updateCompetitionDetails()}
            updateCompetitionMatches={() => props.updateCompetitionMatches()}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={deleteTeamPopup}
        // onClose={() => setDeleteTeamPopup(false)}
      >
        <DialogContent className="add-team-manual-wrapper">
          <div className="edit-team-popup-container">
            <div className="edit-team-popup-title">
              {/* {languagePack["edit_team"]} */}
              {"Delete competition team"}
            </div>
            <div className="delete-popup-message">
              {"Are you sure you want to remove this team from competition?"}
            </div>
            <div className="edit-team-buttons-container">
              <Button
                className="edit-team-button"
                onClick={() => setDeleteTeamPopup(false)}
                style={{ backgroundColor: "#666666" }}
              >
                {props.languagePack["admin_cancel"]}
                <Icon name="delete" className="white-icon" />
              </Button>
              <Button
                className="edit-team-button"
                onClick={() => deleteTeam()}
                style={{ backgroundColor: "red" }}
              >
                {/* {props.languagePack['admin_save']} */}
                {"Yes, delete."}
                <Icon name="trash alternate" className="white-icon" />
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(CompetitionTeams);
