import React, { useEffect, useState } from "react";
import "./Sponsors.scss";
import axios from "axios";
import { Icon } from "semantic-ui-react";
import NumberFormat from "react-number-format";
import Moment from "react-moment";

export default function SponsorPayment(props) {
    const [season, setSeason] = useState(new Date().getFullYear() - 1);
    const [payments, setPayments] = useState([]);

    function getPayments(season) {
        axios
            .get(
                `finances/get_sponsor_payments?id_sponsor=${props.id_sponsor}&season=${season}`
            )
            .then((res) => setPayments(res.data))
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        getPayments(season);
    }, []);

    function formatNumber(value) {
        return (
            <NumberFormat
                value={value}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={0}
            />
        );
    }

    return (
        <div className="sponsor-payment-container">
            <div className="sponsor-row-bonuses-header">
                <div className="sponsor-row-title">Transe de plata previzionate</div>
                <div className="sponsor-row-toggler">
                    {props.seasons.map(item => {
                        return (
                            <div className={season == item.split('/')[0] ? "season-option selected-option" : "season-option"} onClick={() => {
                                setSeason(item.split('/')[0])
                                getPayments(item.split('/')[0])
                            }}>{item}</div>
                        )
                    })}
                </div>
            </div>
            <div className="sponsor-payments-list">
                <div className="sponsor-payment-row-header">
                    <div className="value-column">Valoare</div>
                    <div className="date-column">Termen de plata</div>
                    <div className="date-column">Data efectuarii platii</div>
                </div>
                {payments.map((item, index) => {
                    return (
                        <div className="sponsor-payment-row" style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}>
                            <div className="value-column">{formatNumber(item.payment_amount)}<Icon name="eur" className="sponsor-payment-icon" /></div>
                            <div className="date-column"><Moment format="DD.MM.YYYY">{item.payment_estimated_date}</Moment></div>
                            <div className="date-column">{item.payment_date ? <Moment format="DD.MM.YYYY">{item.payment_date}</Moment> : "-"}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}