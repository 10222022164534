import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import "./Players.scss";
import moment from "moment";
import Loader from "react-loader-spinner";
import _ from "lodash";
import TFBPlaceholder from "../../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import SearchFilters from "./SearchFilters";
import PlayersTable from "./PlayersTable";

function ActivePlayers(props) {
  const [players, setPlayers] = useState([]);
  const [allPlayers, setAllPlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fetchFinished, setFetchFinished] = useState(false);
  const [hideInactivePlayers, setHideInactivePlayers] = useState(true);

  // ================ USE EFFECTS =============================
  useEffect(() => {
    const playersArray = [...players];
    const allPlayersChecked = playersArray.every(
      (player) => player.checked == true
    );
    if (allPlayersChecked) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [players]);

  useEffect(() => {
    setFetchFinished(false);
    getPlayers();
  }, [hideInactivePlayers]);

  useEffect(() => {
    sendData();
  }, [players, allChecked]);

  // ========= GET DATA =================

  const getPlayers = () => {
    axios
      .get(
        `club/get_club_players_2?id_club=${props.id_club}&token=${
          props.token
        }&loaned_out=${props.show_on_loan ? "1" : "0"}`
      )
      .then((res) => {
        let playersList = res.data.list.map((player) => {
          return {
            ...player,
            checked: false,
          };
        });

        if (hideInactivePlayers) {
          playersList = playersList.filter((player) => player.inactive == 0);
        }

        setPlayers(Array.isArray(playersList) ? playersList : []);
        setAllPlayers(Array.isArray(playersList) ? playersList : []);
        setFetchFinished((previousState) => (previousState = true));
      })
      .catch((err) => console.log(err));
  };

  // =========== HANDLERS =================================

  const handleSearchChange = (e, { value }) => {
    let new_players = [];
    let allPlayersArray = allPlayers;
    if (value != "") {
      allPlayersArray
        .filter(function(item) {
          return item.player_name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(
              value
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            );
        })
        .map(function(item) {
          new_players.push(item);
          return item;
        });
      setPlayers(Array.isArray(new_players) ? new_players : []);
    } else {
      setPlayers(allPlayersArray);
    }
  };

  const handleChangeStartDate = (date) => {
    setStartDate(date);
  };

  const handleChangeEndDate = (date) => {
    setEndDate(date);
  };

  const handleApplyFilters = () => {
    let playersState = [...allPlayers];
    let filteredPlayers;

    // ====== both dates are selected =======
    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      filteredPlayers = playersState.filter((player) => {
        const birthDate = moment(player.player_dob, "DD.MM.YYYY");

        return (
          player.player_dob != "-" &&
          birthDate.isAfter(start) &&
          birthDate.isBefore(end)
        );
      });
    }

    // ====== none of dates are selected =======
    if (!startDate && !endDate) {
      const start = moment("1960-01-01");
      const end = moment("2070-01-01");

      filteredPlayers = playersState.filter((player) => {
        const birthDate = moment(player.player_dob, "DD.MM.YYYY");

        return (
          player.player_dob != "-" &&
          birthDate.isAfter(start) &&
          birthDate.isBefore(end)
        );
      });
    }

    // ====== end date is selected =======
    if (!startDate && endDate) {
      const end = moment(endDate, "DD.MM.YYYY");
      filteredPlayers = playersState.filter((player) => {
        const birthDate = moment(player.player_dob, "DD.MM.YYYY");
        return player.player_dob != "-" && birthDate.isBefore(end);
      });
    }

    // ====== start date is selected =======
    if (startDate && !endDate) {
      const start = moment(startDate, "DD.MM.YYYY");

      filteredPlayers = playersState.filter((player) => {
        const birthDate = moment(player.player_dob, "DD.MM.YYYY");
        return player.player_dob != "-" && birthDate.isAfter(start);
      });
    }

    setPlayers(filteredPlayers);
    setAllChecked(false);
  };

  const handleResetFilters = () => {
    setPlayers(allPlayers);
    setAllChecked(false);
    setStartDate(null);
    setEndDate(null);
  };

  const handleHeaderCheckbox = (e) => {
    let playersArray = players;
    if (e.target.checked == true) {
      playersArray.map((player) => {
        return (player.checked = true);
      });
    } else {
      playersArray.map((player) => {
        return (player.checked = false);
      });
    }
    setAllChecked(e.target.checked);
    setPlayers(playersArray);
  };

  const handlePlayerCheckbox = (index) => {
    let playersArray = [...players];
    playersArray[index].checked = !playersArray[index].checked;
    setPlayers(playersArray);
  };

  // ============= TRANSFER DATA TO PARENT =================

  const sendData = () => {
    let playersIds = allPlayers
      .filter((player, index) => {
        return player.checked;
      })
      .map((player) => {
        return player.id_player;
      });
    props.transferData(playersIds);
  };

  const { languagePack } = props;
  return (
    <div className="active-players-content">
      {!fetchFinished ? (
        // ==================== LOADER CONTAINER ===================
        <div className="loader-container">
          <Loader
            type="TailSpin"
            color={window.clubCustomization.primary_color}
            height={40}
            width={40}
          />
        </div>
      ) : (
        <>
          {/* ========== SUBHEADER FILTERS SEARCH/DATEPICKERS/BUTTONS =================== */}
          <SearchFilters
            isLoading={isLoading}
            startDate={startDate}
            endDate={endDate}
            hideInactivePlayers={hideInactivePlayers}
            handleChangeInactivePlayers={() =>
              setHideInactivePlayers(!hideInactivePlayers)
            }
            handleSearchChange={handleSearchChange}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleApplyFilters={handleApplyFilters}
            handleResetFilters={handleResetFilters}
            languagePack={languagePack}
          />

          {/* =================  TABLE PLAYERS CONTENT =================== */}
          {players.length > 0 ? (
            <PlayersTable
              players={players}
              allChecked={allChecked}
              handleHeaderCheckbox={handleHeaderCheckbox}
              handlePlayerCheckbox={handlePlayerCheckbox}
              languagePack={languagePack}
            />
          ) : (
            <TFBPlaceholder
              text={languagePack["There_are_no_records_to_display"]}
            />
          )}
        </>
      )}
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(ActivePlayers);
