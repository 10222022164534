export default {
  // Video Gallery
  add_video: "Aggiungi video",
  video_description: "Descrizione video",
  upload_video: "Carica video",
  video_title: "Titolo del video",

  // Campaigns
  campaign_title: "Campagne",
  campaign_name: "Nome della campagna",
  campaign_type: "Tipo",
  campaign_status: "Stato",
  campaign_audience: "Pubblico",
  campaign_last_edited: "Ultima modificata",
  campaign_opens: "Aperte",
  campaign_clicks: "Acceduto",
  campaign_status_filter: "Filtra per stato",
  campaign_type_filter: "Filtra per tipo",
  campaign_pagination: "Risultati per pagina",
  campaign_your_audience: "Il tuo pubblico",
  campaign_choose_audience: "Scegli pubblico",
  campaign_new_segment: "Nuovo segmento",
  campaign_add_new_condition: "Aggiungi una nuova condizione",
  campaign_save_segment: "Salva segmento",
  campaign_sender: "Mittente",
  campaign_name: "Nome",
  campaign_subject: "Soggetto",
  campaign_enter_subject: "Introdurre soggetto",
  campaign_content: "Contenuto",
  campaign_main_title: "Titolo principale",
  campaign_preview_article: "Articolo in anteprima",
  campaign_schedule_campaign: "Programmare la campagna",
  campaign_send_later: "Invia più tardi",
  campaign_send_test_message: "Invia messaggio di prova",
  campaign_save_message: "Salva messaggio",
  campaign_save_as_template: "Salva come modello",
  campaign_cancel: "Annulla",
  campaign_is_before: "È prima",
  campaign_is_after: "È dopo",
  campaign_specific_date: "Una data precisa",
  campaign_range: "Una gamma",
  campaign_contact_must_match: "Il contatto deve corrispondere",
  campaign_gender: "Genere",
  campaign_birthday: "Compleanno",
  campaign_date_added: "Data aggiunta",
  campaign_view_email: "Visualizzazione email",
  campaign_download: "Scarica",
  campaign_print: "Pressare",
  campaign_top_links: "Collegamenti principali cliccati",
  campaign_view_all_links: "Visualizza tutti i collegamenti",
  campaign_recipients: "Destinatari",
  campaign_total_recipients: "Destinatari totali",
  campaign_total_opens: "Ha aperto",
  campaign_total_clicked: "Cliccato",
  campaign_total_bounced: "Rifiutare",
  campaign_total_unsubscribed: "annullato l'iscrizione",
  campaign_delivered: "Consegnato",
  email_campaign: "Campagna di e-mail",
  campaign_report: "Rapporto della campagna",
  campaign_view_all: "Mostra tutto",
  campaign_subscribers_most_opens:
    "Abbonati con la maggior parte delle aperture",
  campaign_numbers_stats: "Numeri e statistiche",
  campaign_abuse_reports: "Rapporti di abuso",
  campaign_last_clicked: "Ultimo clic",
  campaign_total_clicks: "Clic totali",
  campaign_clicks_per_unique_opens: "Clic per aperture uniche",
  campaign_forwarded: "Inoltrato",
  campaign_last_opened: "Ultima apertura",
  campaign_total_opens: "Totale si apre",
  campaign_successful_deliveries: "Consegne di successo",

  //Fans section
  tax_redirectors_title: "Reindirizzamento dell'imposta sui profitti",
  tax_redirectors_total: "Reindirizzamenti totali",
  tax_redirector_name: "Nome e cognome",
  tax_redirector_register_date: "Data di registrazione",
  tax_redirector_email: "Indirizzo email",
  tax_redirector_phone_number: "Numero di telefono",

  //Site administration
  about_us_title: "Chi siamo",
  upload_main_image: "Carica l'immagine principale",
  upload_another_main_image: "Carica un'altra immagine principale",
  sections: "Sezioni",
  section: "Sezione",
  admin_add: "Aggiungere",
  admin_cancel: "Annulla",
  admin_delete: "Elimina",
  upload_image: "Carica immagine",
  upload_other_image: "Carica un'altra immagine",
  admin_save: "Salva",
  admin_cancel_modif: "Non salvare le modifiche",
  admin_preview: "Pagina di anteprima",
  gallery_title: "Galleria di anteprima",
  gallery_photos_title: "Galleria di foto",
  gallery_videos_title: "Galleria video",
  gallery_bio_title: "Biografia",
  gallery_add_button: "Aggiungi immagine/immagini",
  gallery_add_button_videos: "Aggiungi video / video",
  gallery_add_button_bio: "Aggiungi/modifica biografia",
  gallery_placeholder: "Al momento non hai caricato alcuna immagine.",
  gallery_videos_placeholder: "Al momento non hai caricato nessun video.",
  gallery_bio_placeholder: "Al momento non hai aggiunto alcuna biografia.",

  partners_title: "Partner e sponsor",
  add_partner_button: "Aggiungi partner",
  sponsor_name_form: "Nome dello sponsor",
  sponsor_site_form: "URL del sito",
  sponsor_logo_form: "Carica logo (.png)",
  sponsor_site_notif_title: "Gestire i partner",
  sponsor_site_notif_success: "Il partner è stato aggiunto con successo",
  delete_partner: "Elimina partner",
  delete_partner_popup_text: "Sei sicuro di voler rimuovere questo partner?",
  admin_confirm: "Confermare",
  delete_sponsor_notif_text: "Il partner è stato rimosso con successo",

  admin_contact_title: "Informazioni di contatto",
  admin_contact_phone: "Informazioni di contatto",
  admin_contact_address: "Indirizzo",
  admin_contact_org_name: "Nome dell'organizzazione",
  admin_contact_save_changes: "Salvare le modifiche",
  admin_contact_notif_title: "Gestione dei dati di contatto",
  admin_contact_notif_text_success:
    "Le modifiche sono state salvate con successo!",

  gallery_delete_popup_title: "Elimina immagine",
  gallery_delete_popup_text: "Sei sicuro di voler eliminare questa immagine?",
  upload_images_popup_title: "Carica immagini",
  gallery_upload_select_file: "Seleziona il file",
  gallery_upload_select_files: "Seleziona i file",
  gallery_upload_nr_singular: " immagine caricata",
  gallery_upload_nr_plural: "immagine caricata",
  gallery_notification_title: "Galleria",
  gallery_notification_success: "File caricati con successo!",
  file_remove_success_title: "File rimosso con successo.",
  general_error_message: "Si è verificato un errore, riprova più tardi.",
  news_page_title: "Ultime notizie aggiunte",
  news_page_add_button: "Aggiungi notizie",
  news_page_header_title: "Titolo",
  news_page_header_tag: "Etichetta",
  news_page_header_date: "Data di pubblicazione",
  news_page_header_options: "Opzioni",
  news_page_delete_popup_title: "Elimina articolo",
  news_page_delete_popup_text:
    "Sei sicuro di voler rimuovere questa notizia? L'azione è irreversibile.",
  add_news_title: "Aggiungi notizie",
  add_news_article_title: "Titolo della notizia",
  add_news_publish_button: "Pubblica articolo",
  add_news_cancel_button: "Elimina articolo",
  article_preview: "Anteprima articolo",
  edit_news_title: "Modifica notizie",
  edit_news_delete_button: "Elimina articolo",
  edit_section: "Modifica sezione",

  //Medical
  active_injuries_title: "Lesioni attuali",
  active_injuries_add_button: "Aggiungi danno",
  active_injuries_player_name: "Nome del giocatore",
  active_injuries_type: "Tipo di lesione",
  active_injuries_injury_date: "Data dell'infortunio",
  active_injuries_estimated_recovery: "Recupero stimato",
  injury_details: "Dettagli sulla lesione",
  add_injury_popup_title: "Aggiungi nuovo infortunio",
  injury_zone: "Zona",
  edit_details: "Modifica i dettagli",
  treatment: "Trattamento",
  injury_edit: "Modificare",
  add_treatment: "Aggiungi trattamento",
  end_injury: "Fine infortunio",
  select_team: "Selezionare squadra",
  select_player: "Seleziona giocatore",
  select_zone: "Seleziona zona",
  start_date: "Data d'inizio",
  end_date: "Data di fine",
  return_date: "Data di ritorno",
  treatment_details: "Dettagli del trattamento",
  injuries_history: "Storia degli infortuni",
  absence_period: "Periodo di assenza",
  no_treatment_found: "Nessun trattamento trovato",
  total_treatment_cost: "Costo totale del trattamento",
  injury_date: "Data",
  injury_day: "giorno",
  injury_days: "giorni",
  total_cost: "Costo totale",
  no_injuries_found:
    "Nessun infortunio riscontrato durante il periodo selezionato",
  injury_change_success:
    "La lesione selezionata è stata modificata con successo!",
  injury_close_success: "L'infortunio è terminato nella data prescelta",
  injury_delete_success: "La lesione selezionata è stata rimossa con successo!",
  treatment_delete_success:
    "Il trattamento selezionato è stato rimosso con successo!",
  injury_management_title: "Gestire gli infortuni",
  injury_added_success: "L'infortunio è stato aggiunto con successo",
  treatment_edit_success:
    "Il trattamento selezionato è stato modificato con successo!",
  cost: "Costo",
  staff_members: "Membri dello staff",
  roles_list: "Elenco ruoli",
  add_member: "Aggiungi membro",
  staff_member_name: "Nome",
  staff_member_role: "Ruolo",
  edit_profile: "Modifica Profilo",
  staff_full_name: "Nome e cognome",
  staff_description: "Descrizione",
  edit_staff_member_popup_title: "Profilo del membro del personale",
  edit_staff_member_popup_message: "Dettagli salvati con successo!",
  delete_staff_member_message:
    "Il profilo selezionato è stato rimosso con successo!",
  popup_delete_staff_member_title: "Elimina profilo",
  popup_delete_staff_member_message:
    "Sei sicuro di voler rimuovere questo profilo? L'azione è irreversibile.",
  add_new_role: "Aggiungi nuovo ruolo",
  add_role: "Aggiungi ruolo",
  manage_role_notification_title: "Ruoli di gestione del club",
  manage_role_notification_succes: "Ruolo aggiunto con successo!",
  manage_role_notification_fail:
    "Il ruolo selezionato è stato rimosso con successo!",
  active_teams: "Squadre attive",
  add_team: "Aggiungi squadra",
  no_teams_yet: "Il club al momento non ha alcuna squadra",
  team_type: "Tipo",
  team_name: "Nome della squadra",
  number_players: "Numero di giocatori",
  team_coach: "Allenatore",
  team_coordinator: "Coordinatore/allenatore",
  teams_notification_title: "Gestisci squadre",
  teams_notification_success: "Squadra aggiunta con successo!",
  team_players: "Giocatori",
  team_performance: "Prestazione di squadra",
  add_player: "Aggiungi giocatore",
  PLAYER_NAME: "Nome del giocatore",
  NATIONALITY: "Nationality",
  POSITION: "Posizione",
  AGE: "Età",
  MARKET_VALUE: "Valore di mercato",
  average: "Average",
  age_years: "anni",
  minutes_played: "Minuti giocati",
  no_players_placeholder: "Questa squadra non ha giocatori",
  edit_team_name: "Modifica nome squadra",
  birth_date: "Data di nascita",

  //Player profile
  birthplace: "Luogo di nascita",
  player_age: "Età",
  weight: "Il peso",
  height: "Altezza",
  select_year: "Seleziona l'anno",
  section_access_forbidden: "Non hai accesso a questa sezione",
  delete_player: "Elimina giocatore",
  delete_player_popup_text:
    "Sei sicuro di voler rimuovere questo giocatore da questa squadra? L'azione è irreversibile.",
  change_player_team: "Cambia la squadra del giocatore",
  select_new_team: "Seleziona nuova squadra",
  player_transactions_history: "Cronologia delle operazioni eseguite",
  transaction_description: "Descrizione",
  transaction_date: "Data della transazione",
  transaction_type: "Tipo",
  trasnaction_amount: "Quantità",
  total_expenses: "Spese totali",
  contract_details: "Dettagli del contratto",
  active_contract: "Active contract",
  active_bonuses: "Bonus attivi",
  expiry_date: "Data di scadenza",
  monthly_wage: "Salario mensile",
  placeholder_bonuses: "Nessun bonus aggiunto",
  starting_date: "Data d'inizio",
  shirt_number: "Numero di maglia",
  move_player: "Cambia squadra",
  edit_photo: "Modifica foto",

  //Aside main menu

  Club: "Il mio club",
  dashboard: "Pannello di controllo",
  trainings: "Sessione di allenamento",
  Reports: "Rapporti",
  finances: "Finanziario",
  medical: "Medico",
  documents: "Documenti",
  fans_relation: "Fidanzamento dei tifosi",
  site_management: "Amministrazione del sito",
  toggle_aside: "Fai da parte",
  Users: "Users",
  shop: "Negozio",

  //Aside menu list

  player_contracts: "Contratti dei giocatori",
  staff_contracts: "Contratti del personale",
  injuries: "Lesioni",
  tax_redirectors: "Reindirizzatori fiscali",
  news: "Notizia",
  cashflow: "Flusso monetario",
  transactions: "Transazioni",
  sponsors: "Sponsor",
  clients: "Clienti",
  providers: "Fornitori",
  wages_costs: "Costi salariali",
  match_costs: "Match costs",
  my_list: "La mia lista",
  plan_training: "Pianifica formazione",
  trainings_history: "Training sessions history",
  weight_control: "Controllo del peso",
  teams: "Squadre",
  matches_schedule: "Calendario delle partite",
  competitions: "concorsi",
  contact_data: "Informazioni di contatto",
  fans_list: "Fans list",
  import_data: "Importa dati",
  categories: "Categorie",
  products: "Prodotti",
  orders: "Ordini",

  //Quick user
  change_password: "Cambia la password",
  your_currency: "Valuta selezionata",
  no_club: "Senza club",
  sign_out: "Disconnettersi",
  user_profile: "Profilo utente",
  password_reset: "Resetta la password",
  account_settings: "Impostazioni dell'account",
  password_success_text: "La password è stata modificata con successo",

  //Team players list
  vfm:
    "VFM mostra se un giocatore merita lo stipendio che guadagna attualmente, confrontandolo con la sua prestazione in campo. Un indice alto significa che un giocatore guadagna meno di quanto effettivamente meriti, confrontandolo con il livello di squadra. Più basso è l'indice, più alto è il contrasto tra prestazioni e guadagno.",
  pta:
    "PTA mostra se c'è una correlazione tra il numero di minuti giocati durante la stagione e l'attuale prestazione in campo. Più basso è l'indice, maggiore è il contrasto tra prestazione e numero di minuti (un giocatore che ha giocato troppo/troppo pochi).",
  instat_index:
    "InStat Index aiuta a determinare la classe del giocatore sulla base di un algoritmo automatico che considera il contributo del giocatore al successo della squadra, il significato delle sue azioni, il livello dell'avversario e il livello del campionato in cui gioca.",
  instat_index_title: "Indice Instat",

  //Dashboard

  end_of_month_sold: "Saldo di fine mese",
  weekly_report: "Rapporto settimanale",
  sum: "Quantità",
  period: "Periodo",
  position: "Posizione",
  ranking_points: "Punti",
  market_value_updates: "Aggiornamenti valore di mercato",
  increase: "Aumento",
  current_value: "Valore corrente",
  calendar_title: "Pianificatore di eventi",
  label_color: "Colore etichetta",
  description: "Descrizione",
  event_details: "Dettagli dell'evento",
  location: "Posizione",
  hour: "Ora",

  //Matches list

  round: "Il giro",
  match: "Incontro",
  select_competition: "Seleziona la competizione",
  past_matches: "Partite passate",
  future_matches: "Partite future",
  match_events: "Eventi delle partite",
  starting_lineup: "Formazioni iniziali",
  substitutes: "Panchina",
  add_event: "Aggiungi giocatore",
  yellow_card: "Carta gialla",
  red_card: "Cartellino rosso",
  substitution: "Sostituzione",
  goal: "Il goal",
  event_successfully_added: "Evento aggiunto con successo!",
  event_minute: "Minuto dell'evento",
  select_team: "Selezionare squadra",
  select_event_type: "Seleziona il tipo di evento",
  select_player: "Seleziona giocatore",
  select_player_in: "Seleziona giocatore in",
  select_player_out: "Seleziona il giocatore fuori",
  select_goal_type: "Seleziona il tipo di il goal",
  single_goal: "Il goal",
  goal_assist: "Gol + assist",
  select_scoring_player: "Seleziona il giocatore che ha segnato",
  select_assisting_player: "Seleziona giocatore assistente",
  player_lineup_successfully_added:
    "Giocatore in formazione aggiunto con successo!",
  player_sub_successfully_added:
    "Giocatore di sostituzione aggiunto con successo!",

  //Providers components

  current_season_stats: "Statistiche della stagione in corso",
  match_sg: "incontro",
  match_pl: "partite",
  starting_lineup_matches_sg: "partita in partenza 11",
  starting_lineup_matches_pl: "partite in partenza 11",
  minutes_played: "minuti giocati",
  goals_scored_sg: "goal segnato",
  goals_scored_pl: "goal segnati",
  clean_sheets_sg: "foglio pulito",
  clean_sheets_pl: "lenzuola pulite",
  yellow_cards_sg: "Carta gialla",
  yellow_cards_pl: "Cartellini gialli",
  red_cards_sg: "Cartellino rosso",
  red_cards_pl: "Cartellini rossi",
  assist_sg: "assistere",
  assist_pl: "assiste",
  season_total: "Totale stagione",
  season_avg: "Media stagionale",
  parameter_list: "Elenco dei parametri",
  player_actions: "Azioni individuali del giocatore",
  select_match: "Seleziona partita",
  attack: "attacco",
  defence: "Difesa",
  technical: "Tecnico",
  add_name: "Inserisci il nome",
  select_existing_partners: "Seleziona dai partner esistenti",
  balance_at_end_month: "Saldo a fine mese",
  or_lowercase: "o",
  supplier_operations: "Operazioni del fornitore",
  supplier_success: "Il fornitore è stato aggiunto con successo",
  add_supplier: "Aggiungi fornitore",
  //Trainings

  trainings_evolution: "Evoluzione delle sessioni di allenamento",
  physical_tracking_results: "Risultati del monitoraggio fisico",
  heart_rate: "Frequenza cardiaca",
  distance_covered: "Distanza ricoperta",
  kcal_spent: "calorie",
  sprint_counter: "Contatore sprint",
  speed: "Velocità",
  medium: "avg",

  trainings_history: "Cronologia delle sessioni di allenamento",
  start_hour: "Ora di inizio",
  end_hour: "Ora di fine",
  training: "Sessione di allenamento ",
  training_schedule: "Programma di allenamento",
  hours_interval: "Fascia oraria",
  optimal_weight: "Peso ottimale",
  add_weight: "Aggiungi peso",
  measure_date: "Data della misura",
  weight_value: "Valore",

  //Documents

  file_details: "Dettagli del file",
  upload_file: "Caricare un file",
  add_new_category: "Aggiungi una nuova categoria",
  remove_contract: "Fine contratto",
  remove_contract_date: "Data di cessazione",
  remove_contract_confirm: "Confermare",
  active_contracts: "Contratti attivi",
  bonuses_list: "Elenco dei bonus",
  add_contract: "Elenco dei bonus",
  occupation: "Ruolo",
  choose_staff_member: "Scegli un membro dello staff",
  contract_bonuses: "Bonus contrattuali",
  signing_date: "Data della firma",
  add_contract_extra_bonuses_text:
    "Per aggiungere ulteriori bonus e condizioni vedere la sezione bonus",
  select_condition: "Seleziona condizione",
  add_condition_value: "Inserisci il valore della condizione",
  add_condition: "Aggiungi condizione",
  active_conditions: "Condizioni attive",
  bonus: "Bonus",

  //Training plan

  create_training: "Crea sessione di allenamento",
  add_training_sections: "Aggiungi sezioni",
  total_training_duration: "Total time",
  training_details: "Dettagli sull'allenamento",
  hours_error_training: "The end hour must be greater than the start hour",
  save_training: "Salva sessione di allenamento",
  training_sections: "Sezioni di formazione",
  create_new_section: "Crea una nuova sezione",
  add_new_section_name: "Nuovo nome della sezione",
  add_training_session: "Aggiungi sessione di allenamento",
  training_add_title: "Gestione della formazione",
  training_success: "Formazione aggiunta con successo!",
  section_add_title: "Gestione della sezione",
  section_added_success: "Sezione aggiunta con successo!",
  training_date: "Data",
  training_description_details: "Descrizione/Dettagli",

  //Matches

  match_date: "Data della partita",
  away_team: "Squadra in trasferta",
  home_team: "Squadra di casa",
  round_number: "Numero tondo",
  round_number_name: "Numero/nome tondo",
  add_match: "Aggiunta partita",
  match_successfully_added: "Partita aggiunta con successo!",
  competitions_actions: "Operazioni di concorsi",
  new_team: "Nuova squadra",
  clubs_team: "La squadra del club",
  add_competition: "Aggiungi concorrenza",
  competition_name: "Nome del concorso",
  competition_type: {
    name: "Tipo di competizione",
    elements: {
      championship: "Campionato",
      knockout: "Fase eliminatoria",
      group_stage: "Gruppi + fase eliminatoria",
    },
  },
  teams_number: "Numero di squadre",
  ranking: "classifica",
  edit_team: "Modifica squadra",
  points_short: "Pts.",
  manage_competitions: "Manage competitions",
  manage_competitions_success_changes: "Modifiche salvate con successo",
  active_competitions: "Competizioni attive",
  competition_deleted_success: "Concorso rimosso con successo",
  round_deleted_success: "Round rimosso con successo",
  match_deleted_success: "Partita rimossa con successo",
  match_delete_popup_title: "Elimina partita",
  match_delete_popup_text:
    "Sei sicuro di voler rimuovere questa partita? Tutti i dati andranno persi.",
  fixture_table: "Girone del torneo",
  delete_competition: "Elimina concorrenza",
  delete_round: "Elimina round",
  edit_round: "Modifica round",
  round: "Giornata",
  new_round_name: "Nuovo nome tondo",
  delete_competition_text:
    "Sei sicuro di voler rimuovere questo concorso? Tutti i dati andranno persi.",
  edit_competition: "Modifica competizione",
  edit_score: "Modifica punteggio",
  no_matches_for_selected_options:
    "Nessuna corrispondenza disponibile per le opzioni selezionate",

  player_tech_parameters: "Parametri tecnici del giocatore",
  parameters_list: "Elenco dei parametri",
  teams_stats: "Statistiche della squadra",
  administrate_teams: "Gestire i team",
  administrate_teams_success: "Il nome della squadra è cambiato con successo!",
  administrate_teams_delete: "La squadra è stata eliminata con successo!",

  // Reports
  add_report: "Aggiungi rapporto",
  made_by: "Fatto da",
  player_comparison: "Confronto giocatori",
  evaluated_params: "Parametri valutati",
  parameter: "Parametro",
  unit_of_measure: "Unità di misura",
  evaluator_name: "Nome valutatore",
  report_name: "Nome rapporto",
  parameters_added: "parametri aggiunti",
  select_parameter: "Select parameter",
  create_new_parameter: "Crea nuovo parametro",
  unit_of_measure: "Unità",
  add_parameter: "Aggiungi parametro",
  evaluation_list: "Elenco di valutazione",
  choose_player: "Scegli un giocatore",
  add_value: "Inserisci il valore",
  add_data: "Inserisci i dati",
  evaluation: "Valutazione",
  player_added: "Giocatore aggiunto",
  save_report: "Salva report",
  add_parameter_warning_notification_title: "Aggiungi rapporto",
  add_parameter_warning_notification_text: "Seleziona un parametro.",
  add_player_warning_notification_title: "Aggiungi rapporto",
  add_player_warning_notification_text:
    " Seleziona un giocatore prima di aggiungere dati. Se non ci sono giocatori disponibili nell'elenco, assicurati di aver selezionato la squadra.",

  // Financial
  transaction_history: "Cronologia delle transazioni ",
  current_balance: "Bilancio corrente",
  select_interval: "Seleziona intervallo",
  select_type: "Seleziona il tipo",
  items_on_page: "Articoli a pagina",
  total_income: "Reddito totale",
  total_expense: "Total expense",

  // Fan engagement
  combine_fan_profiles: "Combina i profili dei fan",
  filter_profiles: "Filtro",
  firstname_lastname: "Nome",
  phone_no: "Numero di telefono ",
  age: "Età",
  years: "anni",
  general_info: "Informazioni generali",
  full_name: "Nome e cognome",
  birthday: "Compleanno",
  fan_profile: "Profilo del fan ",
  address: "Indirizzo",
  activity: " Attività ",

  // Scouting
  saved_shortlists: "Liste salvate ",
  create_shortlist: "Crea lista ristretta ",
  shortlist_name: "Nome della lista ristretta ",
  formation: "Formazione",
  selected_players: "Giocatori selezionati ",
  position: "Posizione",
  date_created: "Data di creazione",
  shortlist_url: " Liste ",

  //Products
  add_product: "Aggiungi prodotto",
  product_image: "Immagine del prodotto",
  product_name: "Nome del prodotto",
  product_description: "Descrizione",
  product_price: "Prezzo",
  product_status: "Stato",
  product_actions: "Azioni",
  product_is_active: "Il prodotto è attivo",

  //Orders
  order_id: "ID",
  order_date: "data dell'ordine",
  order_customer: "Cliente",
  order_shipping_method: "Metodo di spedizione",
  order_status: "Stato",
  order_amount: "Quantità",
  order_actions: "Azioni",

  // Months
  month_1: "gennaio",
  month_2: "febbraio",
  month_3: "marzo",
  month_4: "aprile",
  month_5: "maggio",
  month_6: "giugno",
  month_7: "iuglio",
  month_8: "augusto",
  month_9: "settembre",
  month_10: "ottobre",
  month_11: "novembre",
  month_12: "dicembre",

  //=========================================================================================================== Old translations
  test: "test",
  Name: "Nome",
  player_contracts: "Contratti dei giocatori",
  sponsor_contracts: "Contratti di sponsor",
  contracts: "Contratti",
  bonuses: "Bonus",
  add_new_file: "Aggiungi nuovo file",
  add_document: "Aggiungi documento",
  player_contract: "Contratto del giocatore",
  add_player_contract: "Aggiungi contratto giocatore",
  file_name: "Nome del file",
  category: "Categoria",
  date_add: "Data aggiunta",
  added_by: "Aggiunto da",
  new_member: "Nuovo membro",
  new_report: "Nuovo rapporto",
  players: "Giocatori",

  budget: "Budget",
  add_new_member: "Aggiungi nuovo membro",

  cashflow: "Flusso monetario",
  user_profile: "Profilo utente",
  no_club: "Nessun club",
  no_team: "Nessuna squadra",
  sign_out: "Disconnessione ",
  find_a_player: "Trova un giocatore",
  position: "Posizione",
  Nationality: "Nazionalità",
  League: "Lega",
  Age: "Età",
  Reset_filters: "Reimposta filtri",
  Advanced_search: "Ricerca Avanzata ",
  Add_to_My_List: "Aggiungi alla mia lista ",
  transactions: "Transazioni",

  Order_by: "Ordinato da",
  Discover_more: "Scopri di più ",
  My_list: "La mia lista",
  Moneda: "Valuta",
  Cashflow: "Flusso monetario",
  There_are_no_records_to_display: "Non ci sono record da visualizzare ",
  Bonuses: "Bonuses",
  PLAYER_NAME: "Nome del giocatore",
  NATIONALITY: "Nazionalità",
  POSITION: "Posizione",
  AGE: "Età",
  MARKET_VALUE: "Valore di mercato",
  Currency: "Valuta",
  Cancel: "Annulla",
  Add: "Aggiungere",
  PROFIT: "PROFITTO",
  SPENDING: "SPESA",
  Add_category_to: "Aggiungi categoria a",
  Players: "Giocatori",
  CashFlow: "Flusso monetario",
  transfer_history: "Cronologia trasferimenti",
  finances: "Finanze",
  overview: "Panoramica",
  current_season: "Stagione in corso",
  advanced_standings: "Classifica avanzata",
  fixtures_schedule: "Infissi",
  team_statistics: "Statistiche di squadra",
  player_search: "Ricerca giocatore",
  search: "Ricerca...",
  show: "Schermo",
  extra_notes: "Note aggiuntive:",
  choose_categ: "Scegli la categoria",
  new_categ: "Nuovo nome della categoria...",
  active_crtierias: "Criteri attivi:",
  Nationality: "Nazionalità",
  League: "Lega",
  Position: "Posizione",
  see_results: "Vedi i risultati",
  test_name: "Nome del test",
  evaluation_date: "Data di valutazione",
  evaluation_year: "Anno",
  see_test_details: "Vedi i dettagli del test",
  players_eval: "Giocatori valutati",
  latest_reports: "Ultimi rapporti",

  team_type: "Tipo",
  team_name: "Nome della squadra",
  team_logo: "Logo della squadra",
  number_players: "Numero di giocatori",
  see_more: "Vedi altro",

  Login_Account: "Accedi all'account",
  Welcome_to: "Benvenuto a",
  Privacy: "La vita privada",
  Legal: "Legittimo",
  Contact: "Contatto",
  Enter_your_username_and_password: "Inserisci il tuo nome utente e password",

  Sign_In: "Registrazione",
  Dont_have_an_account_yet: "Non hai ancora un conto?",
  Sign_Up_1: "Iscriviti!",
  Enter_your_details_to_create_your_account:
    "Inserisci i tuoi dati per creare il tuo account",
  Full_name: "Nome e cognome",
  Email: "email",
  User_name: "Nome utente",
  Password: "Parola",
  Confirm_Password: "Conferma parola",
  I_aggree_with_terms_and_conditions: "Accetto i Termini e condizioni",
  Choose_image: "Scegli immagine",
  Submit: "Invia",
  Required_field: "Campo obbligatorio",
  Password_and_Confirm_Password_didnt_match:
    "Parola e Conferma parola non corrispondevano",

  The_login_detail_is_incorrect: "I dati di accesso non sono corretti",

  Add_team_bonus: "Aggiungi bonus squadra",
  Add_players_bonus: "Aggiungi bonus giocatori",
  Bonus_history: "Cronologia bonus",
  Total_sum: "Importo totale",
  Bonus_name: "Nome bonus",
  Bonus_type: "Tipo di bonus",
  Bonus_condition: "Condizione bonus",
  Payment_status: "Stato del pagamento",
  Date_added: "Data aggiunta",
  EUR: "EUR",
  RON: "RON",
  Fullfiled: "Soddisfatto",
  In_pending: "In attesa di",
  Paid: "Pagato",
  Delete: "Elimina",
  Change_status: "Cambiare stato",
  Add_bonus: "Aggiungi bonus",
  For_a_match: "Per una partita",
  Selected_step: "Settimana della partita selezionata",
  Totol_assigned_sum: "Somma totale assegnata",
  GoBack: "Di ritorno",
  Bonus_beneficiary: "Beneficiario del bonus",
  Competition: "Concorrenza",
  For_the_full_season: "Per tutta la stagione",
  Select_match: "Seleziona partita",
  Assignment_criteria: "Criteri di assegnazione",
  Bonus_position: "Raggiungi i primi... posti",
  All_competitions: "Tutte le competizioni",
  placeholder_documents: "Nessun documento ancora caricato",

  Assignment_criterias: "Criteri",
  Select_bonus_type: "Tipo di bonus",
  select_cup_phase: "Fase di Coppa...",

  Ranking: "Posizione",
  Custom_duration: "Durata personalizzata",

  Ranking_the_first_n_places: "Classifica nelle prime ... posizioni",
  Qualification_in_the_play_off: "Portata play-off",
  Ranking_for_european_cup: "Piazzamento in Coppa dei Campioni",
  Ranking_for_uefa_champions_league: "Posizionamento in UEFA Champions League",
  Qualification_in_the_romanian_cup:
    "Piazzamento all'interno della Coppa di Romania",
  Competiion_final: "Finale",
  Competiion_semi_final: "Semifinale",
  Competiion_quarters_final: "Quarti di finale",
  Competiion_eighths_final: "Ultimi 16",
  Competiion_sixteeths_final: "Ultimi 32",
  All_players: "Tutti i giocatori",
  Player_holders: "Formazione iniziale",
  Player_and_Staff: "Giocatori e staff",
  Individual: "Individuale",
  First_league: "Prima campionato",
  Romanian_Cup: "Coppa di Romania",
  Romanian_supercup: "Supercoppa di Romania",
  Goal_scored: "Gol segnato",
  Other_crieteria: "Altri criteri",
  Staff_bonus: "Personale",
  Another_bonus: "Un altro ricevitore",

  Final_result_win: "Risultato finale: vittoria",
  Final_result_draw: "Risultato finale: pareggio",
  Next: "Prossimo",

  Add_contract_details: "Dettagli del contratto",
  Contract_extra: "Condizioni extra",
  Upload_file: "Caricare un file",
  player_name: "Nome del giocatore",
  contract_period: "Durata del contratto",
  Other: "Altro",
  choose_team: "Scegli squadra",
  team: "Squadra",
  average_rating: "Voto medio",

  one_year: "Un anno",
  one_year_half: "Un anno e mezzo",
  two_years: "Due anni",
  two_years_half: "Due anni e mezzo",
  three_years: "Tre anni",
  three_years_half: "Tre anni e mezzo",
  four_years: "Quattro anni",
  four_years_half: "Four years and a half",
  five_years: "Cinque anni",
  five_years_half: "Cinque anni e mezzo",

  add_new_event: "Aggiungi nuovo evento",
  add_event: "Add event",
  event_name: "Nome dell'evento",
  event_category: "Seleziona categoria",
  starting_date: "Data di inizio",
  ending_date: "Data di fine",
  month: "Mese",
  week: "Settimana",
  day: "Giorno",
  today: "Oggi",
  new_categ_name: "Nome nuova categoria",
  club_management: "Gestione del circolo",
  select_team: "Selezionare squadra",
  physical_reports: "Rapporti fisici",
  technical_reports: "Rapporti tecnici",
  teams: "Squadre",
  see_profile: "Vedi profilo",

  contract_details: "Dettagli del contratto",
  active_contract: "Contratto attivo",
  active_bonuses: "Bonus attivi",
  expiry_date: "Data di scadenza",
  monthly_wage: "Salario mensile",
  placeholder_bonuses: "Nessun bonus aggiunto",

  name: "Nome",
  partner_name: "Nome del partner",
  unpaid_amount: "Lasciato pagare",
  paid_amount: "Importo pagato",
  last_payment: "Data ultimo pagamento",
  deadline_date: "Data di scadenza",
  medical: "Medico",
  injuries: "Lesioni",

  ["Giocatori e staff"]: "Giocatori e staff",
  ["Formazione iniziale"]: "Formazione iniziale",
  ["Tutti i giocatori"]: "Tutti i giocatori",

  your_currency: "Valuta selezionata",
  this_will_overwrite_your_preferred_currency:
    "L'azione corrente cambierà la valuta selezionata dalle impostazioni del tuo account. Sei sicuro?",
  Confirm: "Confermare",

  ["Left shoulder"]: "Left shoulder",
  ["Right shoulder"]: "Right shoulder",
  ["Left knee"]: "Left knee",
  ["Right knee"]: "Right knee",
  ["Left thigh"]: "Left thigh",
  ["Right thigh"]: "Right thigh",
  ["Left shin"]: "Left shin",
  ["Right shin"]: "Right shin",
  ["Back"]: "Back",
  ["Head"]: "Head",
  ["Left ankle"]: "Left ankle",
  ["Right ankle"]: "Right ankle",
  ["Another zone"]: "Otra zona",

  ["Developer"]: "Developer",
  ["Team manager"]: "Team manager",
  ["Antrenor"]: "Antrenor",
  ["Antrenor cu portarii"]: "Antrenor cu portarii",
  ["Presedinte"]: "Presedinte",
  ["Preparator fizic"]: "Preparator fizic",
  ["Manager IT"]: "Manager IT",
  ["Manager Academia Poli Iasi"]: "Manager Academia Poli Iasi",

  ["Goalkeeper"]: "Goalkeeper",
  ["Centre-Back"]: "Centre-Back",
  ["Left-Back"]: "Left-Back",
  ["Right-Back"]: "Right-Back",
  ["Defensive Midfield"]: "Defensive Midfield",
  ["Central Midfield"]: "Central Midfield",
  ["Right Midfield"]: "Right Midfield",
  ["Left Midfield"]: "Left Midfield",
  ["Attacking Midfield"]: "Attacking Midfield",
  ["Left Winger"]: "Left Winger",
  ["Right Winger"]: "Right Winger",
  ["Second Striker"]: "Second Striker",
  ["Centre-Forward"]: "Centre-Forward",
  ConnectionCannotBeRefreshed:
    "The next refresh attempt will be allowed according to next refresh possible time or connection’s status is inactive.",
  cur_: "",
  cur_Th: "T",
  cur_M: "M",
  cur_B: "B",
  cur_T: "T",

  "All documents": "All documents",
  "Players contracts": "Players contracts",
  "Sponsor contracts": "Sponsor contracts",

  //start
  Remove_from_my_list: "Remove from my list",
  Budget: "Budget",
  fast_search: "Fast search",
  total: "Total",
  auth_page_left_description: "",
  no_item: "No item.",
  Coach: "Coach",
  "Goalkeepers coach": "Goalkeepers coach",
  President: "President",
  "Physical trainer": "Physical trainer",
  "IT Manager": "IT Manager",
  "Poli Iasi Academy Manager": "Poli Iasi Academy Manager",
  Bills: "Facturi",
  Various: "Diverse",
  "Training scheme": "Training scheme",

  left_shoulder: "Left shoulder",
  right_shoulder: "Right shoulder",
  left_knee: "Left knee",
  right_knee: "Right knee",
  left_thigh: "Left thigh",
  right_thigh: "Right thigh",
  left_shin: "Left shin",
  right_shin: "Right shin",
  back: "Back",
  head: "Head",
  left_ankle: "Left ankle",
  right_ankle: "Right ankle",

  active_teams: "Active teams",
  ranking_position: "Standing position",
  no_teams_yet: "The club has no teams",
  staff_members: "Staff members",
  staff_member_name: "Name",
  staff_member_role: "Role",
  no_staff_members_yet: "No staff members found",
  team_coach: "Coach",
  "GENERAL.SOMETHING_WENT_WRONG":
    "Ups! Something went wrong. Please try again later.",
  Error: "Error",

  Back_button: "Back",

  FORM_CONTROL: {
    "TRANSLATOR.SELECT": "Select your language",
    "MENU.NEW": "new",
    "MENU.ACTIONS": "Actions",
    "MENU.CREATE_POST": "Create New Post",
    "MENU.PAGES": "Pages",
    "MENU.FEATURES": "Features",
    "MENU.APPS": "Apps",
    "MENU.DASHBOARD": "Dashboard",
    "AUTH.GENERAL.OR": "Or",
    "AUTH.GENERAL.SUBMIT_BUTTON": "Submit",
    "AUTH.GENERAL.NO_ACCOUNT": "Don't have an account?",
    "AUTH.GENERAL.SIGNUP_BUTTON": "Sign Up",
    "AUTH.GENERAL.FORGOT_BUTTON": "Forgot Password",
    "AUTH.GENERAL.BACK_BUTTON": "Back",
    "AUTH.GENERAL.CANCEL_BUTTON": "Cancel",
    "AUTH.GENERAL.PRIVACY": "Privacy",
    "AUTH.GENERAL.LEGAL": "Legal",
    "AUTH.GENERAL.CONTACT": "Contact",
    "AUTH.LOGIN.TITLE": "Login Account",
    "AUTH.LOGIN.BUTTON": "Sign In",
    "AUTH.FORGOT.TITLE": "Forgotten Password?",
    "AUTH.FORGOT.DESC": "Enter your email to reset your password",
    "AUTH.FORGOT.SUCCESS": "Your password has been successfully reset.",
    "AUTH.REGISTER.TITLE": "Sign Up",
    "AUTH.REGISTER.DESC": "Enter your details to create your account",
    "AUTH.REGISTER.SUCCESS": "Your account has been successfuly registered.",
    "AUTH.INPUT.EMAIL": "Email",
    "AUTH.INPUT.FULLNAME": "Fullname",
    "AUTH.INPUT.PASSWORD": "Password",
    "AUTH.INPUT.CONFIRM_PASSWORD": "Confirm Password",
    "AUTH.INPUT.USERNAME": "Username",
    "AUTH.VALIDATION.INVALID": "{name} is not valid",
    "AUTH.VALIDATION.REQUIRED": "{name} is required",
    "AUTH.VALIDATION.MIN_LENGTH": "Email minimum length is 3",
    "AUTH.VALIDATION.MAX_LENGTH": "Email max length is 50",
    "AUTH.VALIDATION.AGREEMENT_REQUIRED":
      "Accepting terms & conditions are required",
    "AUTH.VALIDATION.NOT_FOUND": "The requested {name} is not found",
    "AUTH.GENERAL.USER_NOT_FOUND": "User not found",
    "AUTH.GENERAL.USER_INACTIVE": "User is inactive",
    "AUTH.VALIDATION.INVALID_LOGIN": "The login detail is incorrect",
    "AUTH.VALIDATION.INVALID_EMAIL": "Email invalid",
    "AUTH.GENERAL.SOMETHING_WENT_WRONG":
      "Ups! Something went wrong. Please try again later.",
    "AUTH.VALIDATION.NO_USER_WITH_EMAIL":
      "The email is not used by any active user",
    "AUTH.VALIDATION.REQUIRED_FIELD": "Required field",
    "AUTH.VALIDATION.MIN_LENGTH_FIELD": "Minimum field length:",
    "AUTH.VALIDATION.MAX_LENGTH_FIELD": "Maximum field length:",
    "AUTH.VALIDATION.INVALID_FIELD": "Field is not valid",
    "ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT": "Selected records count: ",
    "ECOMMERCE.COMMON.ALL": "All",
    "AUTH.RESET_PASSWORD.TITLE": "Reset Password",
    "AUTH.RESET_PASSWORD.TOKEN_EXPIRED": "Token expired",
    "AUTH.GENERAL.EMPTY_TOKEN": "Token is empty",
    "ECOMMERCE.COMMON.SUSPENDED": "Suspended",
    "ECOMMERCE.COMMON.ACTIVE": "Active",
    "ECOMMERCE.COMMON.FILTER": "Filter",
    "ECOMMERCE.COMMON.BY_STATUS": "by Status",
    "ECOMMERCE.COMMON.BY_TYPE": "by Type",
    "ECOMMERCE.COMMON.BUSINESS": "Business",
    "ECOMMERCE.COMMON.INDIVIDUAL": "Individual",
    "ECOMMERCE.COMMON.SEARCH": "Search",
    "ECOMMERCE.COMMON.IN_ALL_FIELDS": "in all fields",
    "ECOMMERCE.ECOMMERCE": "eCommerce",
    "ECOMMERCE.CUSTOMERS.CUSTOMERS": "Customers",
    "ECOMMERCE.CUSTOMERS.CUSTOMERS_LIST": "Customers list",
    "ECOMMERCE.CUSTOMERS.NEW_CUSTOMER": "New Customer",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.TITLE": "Customer Delete",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.DESCRIPTION":
      "Are you sure to permanently delete this customer?",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.WAIT_DESCRIPTION":
      "Customer is deleting...",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.MESSAGE":
      "Customer has been deleted",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.TITLE": "Customers Delete",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.DESCRIPTION":
      "Are you sure to permanently delete selected customers?",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.WAIT_DESCRIPTION":
      "Customers are deleting...",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.MESSAGE":
      "Selected customers have been deleted",
    "ECOMMERCE.CUSTOMERS.UPDATE_STATUS.TITLE":
      "Status has been updated for selected customers",
    "ECOMMERCE.CUSTOMERS.UPDATE_STATUS.MESSAGE":
      "Selected customers status have successfully been updated",
    "ECOMMERCE.CUSTOMERS.EDIT.UPDATE_MESSAGE": "Customer has been updated",
    "ECOMMERCE.CUSTOMERS.EDIT.ADD_MESSAGE": "Customer has been created",
  },

  verification_code: "Verification code",
  insert_verification_code:
    "Insert the 6-digit code sent to the phone number associated with this account",
  verification_code_no_receive_message: "Did not receive message?",
  verification_code_send_again: "Send again",

  auth_code_expired: "Authentication code expired",
  auth_code_incorrect: "Authentication code incorrect",
  auth_code_try_again: "Authentication error. Please try again.",

  team_types_list: "Team types",
  team_type_add: "Add new type",
  team_type_add_short: "Add type",

  team_type_notification_title: "Club team types",
  team_type_notification_succes: "Team type added successfully!",
  team_type_notification_fail: "Selected type has been successfully removed!",

  tm_link_team: "Transfermarkt link (optional)",
  minutes_played_uppercase: "Minutes played",
  all_fields_mandatory_error: "All the fields are mandatory",
  no_sections_added_error: "You must add at least one training section",
  rec_date_not_before_inj_date: "Recovery date can't be before injury date",
  documents_upload_success: "You have added a new file!",
  video_upload_success: "You have added a new video!",
  video_upload_failed: "Your video must be up to 125 MB in size to upload!",
  video_delete_success: "Your video was successfully deleted!",
  video_operation: "Video operation",
  ACCESS_FORBIDDEN_ERROR: "Access forbidden",
  new_category_empty_error: "New category name can't be empty",
  delete_team: "Delete team",
  season_date_start_add_team: "Current season start date",
  season_date_end_add_team: "Current season end date",
  deleteTeamMessage: "Are you sure you want to delete this team?",
  deleteTeamMessage2: "All players and related data will be lost.",
  add_optimal_weight: "Modify optimal weight",

  auth_error_title: "Errore di autenticazione",
  auth_error_message: "Si prega di controllare i dati inseriti e riprovare",
  reset_error_title: "Errore di reimpostazione della password",
  hello: "Ciao",
  password_reset_email: "La tua nuova password è",

  import_fans_title: "Importa i dati sui sostenitori",
  select_fields_to_save: "Seleziona i campi da salvare",
  enter_source_name: "Inserisci il nome della fonte",
  enter_delimiter: "Inserisci il delimitatore",
  sample_name_fans_file: "es: Iscritti alla Newsletter",
  associated_with: "Associato a",
  activity_date: "Data dell'attività",
  column: "Colonna",
  social_media_activity: "Attività sui social media",

  // ----------------------------------Payments

  payments_title: "Pagamenti",
  table_name: "Nome",
  table_months: {
    jan: "Gennaio",
    feb: "Febbraio",
    mar: "Marzo",
    apr: "Aprile",
    may: "Maggio",
    jun: "Giugno",
    jul: "Luglio",
    aug: "Agosto",
    sep: "Settembre",
    oct: "Ottobre",
    nov: "Novembre",
    dec: "Dicembre",
  },
  add_payment_title: "Aggiungi pagamento per giocatore ",
  add_payment_select_month: "Seleziona mese",
  add_payment_amount: "Somma",
  add_payment_type: {
    cash: "Contanti",
    bank_transfer: "Bonifico bancario",
  },
  add_payment_date: "Data di pagamento",
  add_payment_description: "Descrizione (opzionale)",
  add_payment_button_add: "Aggiungi pagamento",
  add_payment_button_cancel: "Annulla",
  edit_payments_title: "Modifica pagamenti per giocatore ",
  edit_payments_empty: "Non ci sono pagamenti.",
  add_payment_overwrite_first:
    "Sei sicuro di voler sovrascrivere i dati sul pagamento in ",
  add_payment_overwrite_second: " per giocatore ",
  add_payment_button_overwrite: "Sì, sovrascrivi il pagamento",
  delete_payment_question: "Sei sicuro di voler eliminare il pagamento?",
  delete_payment_button_delete: "Sì, elimina",
  payment_successfully_added: "Pagamento aggiunto con successo!",
  payment_successfully_deleted:
    "Il pagamento selezionato è stato rimosso con successo!",
};
