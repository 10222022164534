import React from "react";
import axios from "axios";
import "./Photos.scss";
import { Button, Icon } from "semantic-ui-react";
import Loader from "react-loader-spinner";
import { store } from "react-notifications-component";
import trash from "../../../assets/icons/delete.png";
import expand from "../../../assets/icons/expand.png";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddPhotos from "./AddPhotos";
import ExpandedImage from "./ExpandedImage";
import { connect } from "react-redux";

class Photos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      dataFetched: false,
      wantToDeleteItem: "",
      addFilesPopup: false,
      expandedImage: false,
      expandedImageSrc: "",
    };
  }

  componentDidMount = () => {
    this.getImages();
  };

  getImages = () => {
    axios
      .get(`site/get_images?id_club=${this.props.currentUser.id_club}`)
      .then((res) => this.setState({ files: res.data, dataFetched: true }))
      .catch((err) => console.log(err));
  };

  deletePhoto = () => {
    axios
      .post(`site/delete_image?id=${this.state.wantToDeleteItem}`)
      .then((res) => {
        if (res.data.success == 1) {
          store.addNotification({
            title: "Galerie foto",
            message: "Fotografia a fost stearsa cu succes",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
          this.getImages();
          this.setState({ wantToDeleteItem: "" });
        } else {
          store.addNotification({
            title: "Galerie foto",
            message: "Eroare la stergerea fotografiei",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      });
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="card card-custom">
        <div className="site-photos-container">
          <div className="site-photos-header">
            <div className="site-photos-title">
              {languagePack["gallery_title"]}
            </div>
            <Button
              onClick={() => this.setState({ addFilesPopup: true })}
              className="file-add-images-button"
            >
              {languagePack["gallery_add_button"]}
              <Icon name="arrow up" className="file-add-images-icon" />
            </Button>
          </div>
          <div className="site-photos-list-container">
            {!this.state.dataFetched ? (
              <div className="loader-container" style={{ paddingTop: 75 }}>
                <Loader
                  type="TailSpin"
                  color={window.clubCustomization.primary_color}
                  height={50}
                  width={50}
                />
              </div>
            ) : (
              <>
                {this.state.files.length === 0 ? (
                  <div className="placeholder-photos">
                    {languagePack["gallery_placeholder"]}
                  </div>
                ) : (
                  <div className="site-photos-list">
                    {this.state.files.map((item, index) => {
                      return (
                        <div className="image-element-container" key={"image-" + index}>
                          <div className="image-container">
                            <img
                              src={`https://api.thefootballbrain.app/uploads/site/photos/${this.props.currentUser.id_club}/${item.image_name}`}
                              alt=""
                              className="photo"
                              onClick={() =>
                                this.setState({
                                  expandedImage: true,
                                  expandedImageSrc: `https://api.thefootballbrain.app/uploads/site/photos/${this.props.currentUser.id_club}/${item.image_name}`,
                                })
                              }
                            />

                            <div
                              className="expand-button"
                              onClick={() =>
                                this.setState({
                                  expandedImage: true,
                                  expandedImageSrc: `https://api.thefootballbrain.app/uploads/site/photos/${this.props.currentUser.id_club}/${item.image_name}`,
                                })
                              }
                            >
                              <img src={expand} alt="" />
                            </div>
                          </div>
                          <div className="image-name">{item.image_name}</div>
                          <div
                            className="delete-button"
                            onClick={() =>
                              this.setState({ wantToDeleteItem: item.id_image })
                            }
                          >
                            <img
                              src={trash}
                              alt="delete"
                              className="delete-icon-image"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {this.state.expandedImage && (
          <ExpandedImage
            closeExpand={() => this.setState({ expandedImage: false })}
            image={this.state.expandedImageSrc}
          />
        )}

        <Dialog
          open={this.state.wantToDeleteItem !== ""}
        // onClose={() => this.setState({ wantToDeleteItem: "" })}
        >
          <DialogContent className="photos-delete-popup-container">
            <div className="photos-delete-popup">
              <div className="header">
                <div className="header-title">
                  {languagePack["gallery_delete_popup_title"]}
                </div>
                <Icon
                  name="close"
                  className="header-photos-delete-icon"
                  onClick={() => this.setState({ wantToDeleteItem: "" })}
                />
              </div>
              <div className="delete-message">
                {languagePack["gallery_delete_popup_text"]}
              </div>
              <div className="delete-buttons">
                <Button
                  className="news-options-button dark-background"
                  onClick={() => this.setState({ wantToDeleteItem: "" })}
                >
                  {languagePack["admin_cancel"]}
                  <Icon name="ban" className="news-options-button-icon" />
                </Button>
                <Button
                  className="news-options-button"
                  onClick={this.deletePhoto}
                >
                  {languagePack["admin_delete"]}
                  <Icon
                    name="trash alternate"
                    className="news-options-button-icon"
                  />
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.addFilesPopup}
        // onClose={() => this.setState({ addFilesPopup: false })}
        >
          <DialogContent className="photos-delete-popup-container">
            <div className="photos-delete-popup">
              <div className="header">
                <div className="header-title">
                  {languagePack["upload_images_popup_title"]}
                </div>
                <Icon
                  name="close"
                  className="header-photos-delete-icon"
                  onClick={() => this.setState({ addFilesPopup: false })}
                />
              </div>
              <AddPhotos
                closePopup={() => this.setState({ addFilesPopup: false })}
                refreshPhotos={() => this.getImages()}
                id_club={this.props.currentUser.id_club}
                languagePack={this.props.languagePack}
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(Photos);
