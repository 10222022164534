import {authReduxFunctions} from "./../../redux/functions/authReduxFunctions";
import * as auth from "./../../redux/authRedux";
import Axios from "axios";

export function logout(token) {
    return function(dispatch) {
        return authReduxFunctions(dispatch).logout({token});
    };
}

export function login(user) {
    return function(dispatch) {
        authReduxFunctions(dispatch).login(user);
    }
}

export function updateUserLanguage(user, currency) {
    return function(dispatch) {
        
        const oldCurrency = user.currency;
        if(oldCurrency === currency) return new Promise((resolve, reject) => resolve({}));

        dispatch(auth.actions.login({...user, currency }));
        return Axios.post("user/change_currency", {
            currency
        }, {
            params: {
                token: user.token
            }
        }).then(({data}) => {
            if(data?.success !== 1) {
                dispatch(auth.actions.login({...user, currency: oldCurrency }));
                if(data?.go_to_login === 1) {
                    logout(user.token)(dispatch);
                }
                return new Promise((resolve, reject) => reject(data));
            }
            return new Promise((resolve, reject) => resolve(data));
        }).catch((errr) => {
            dispatch(auth.actions.login({...user, currency: oldCurrency }));
            return new Promise((resolve, reject) => reject(errr));
        })
    }
}