import React, { useState, useEffect } from "react";
import "./PlayersCosts.scss";
import axios from "axios";
import { Button, Icon } from "semantic-ui-react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddRentForm from "./AddRentForm";

export default function Accomodation(props) {
  const [rents, setRents] = useState([]);
  const [total, setTotal] = useState(0);
  const [addRent, setAddRent] = useState(false);

  useEffect(() => {
    getRents();
  }, []);

  function getRents() {
    axios
      .get("finances/get_rents?id_club=1")
      .then((res) => {
        setRents(res.data.rents);
        setTotal(res.data.total);
      })
      .catch((err) => console.log(err));
  }
  return (
    <div className="card card-custsom">
      <div className="accomodation-container">
        <div className="accomodation-header">
          <div className="accomodation-title">Cheltuieli chirii</div>
          <Button className="add-rent-button" onClick={() => setAddRent(true)}>
            <div className="button-content">
              Adauga{" "}
              <Icon name="add" size="small" className="add-rent-white-icon" />
            </div>
          </Button>
        </div>
        <div className="accomodation-list">
          {rents.map((item, index) => {
            return (
              <div
                className="rent-row"
                style={{ backgroundColor: index % 2 === 0 ? "#fafafa" : "" }}
              >
                <div className="rent-player-name">{item.player_name}</div>
                <div className="rent-value">
                  <Icon name="eur" size="small" className="rent-eur-icon" />
                  {item.value}
                  /luna
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="rent-row"
          style={{ marginTop: 20, backgroundColor: "#666666" }}
        >
          <div className="rent-player-name" style={{ color: "white" }}>
            Total:
          </div>
          <div className="rent-value">
            <Icon name="eur" size="small" className="rent-eur-icon" />
            {total}
            /luna
          </div>
        </div>
      </div>

      <Dialog
        open={addRent}
        // onClose={() => setAddRent(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <div
          className="bonus-options-list-popup-header"
          style={{ paddingLeft: 50 }}
        >
          <div className="bonus-options-list-title">Adauga chirie jucator</div>
          <div className="bonus-options-list-close-icon">
            {" "}
            <Icon
              name="close"
              onClick={() => setAddRent(false)}
              size="large"
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <DialogContent>
          <AddRentForm
            closePopup={() => setAddRent(false)}
            refreshList={() => getRents()}
            id_club={props.id_club}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
