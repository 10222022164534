import React from "react";
import { Button, Icon, Input } from "semantic-ui-react";
import "./ElementOperations.scss";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { store } from "react-notifications-component";
import TextField from "@material-ui/core/TextField";

class NewInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoice_amount: "",
      invoice_date: null,
      invoice_description: "",
    };
  }

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDateChange = (value) => {
    this.setState({ invoice_date: value });
  };

  submitInvoice = () => {
    const invoice_details = {
      id_partner: this.props.partner.id,
      id_club: this.props.id_club,
      transaction_type:
        this.props.section === "debts" ? "provider_invoice" : "client_invoice",
      transaction_value: this.state.invoice_amount,
      transaction_currency: this.props.partner.currency,
      transaction_date: this.state.invoice_date,
      transaction_description: this.state.invoice_description,
    };
    axios.post("finances/add_invoice", invoice_details).then(() => {
      this.props.closeSection();
      store.addNotification({
        title: "Operatiuni financiare",
        message: "Factura a fost inregistrata cu succes!",
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
    });
  };

  render() {
    return (
      <div className="form-elements-container">
        <div className="operation-form-row">
          <div className="input-container">
            <Input
              placeholder="Suma..."
              value={this.state.invoice_amount}
              name="invoice_amount"
              onChange={this.handleChangeInput}
              className="invoice-operation-input"
            />
          </div>
          <div className="input-container">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={this.state.invoice_date}
                onChange={this.handleDateChange}
                label="Data emiterii"
                format="dd MMMM yyyy"
                inputVariant="outlined"
                className="invoice-operation-input"
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="operation-form-row">
          <TextField
            className="operation-textfield"
            id="outlined-multiline-static"
            multiline
            rows={3}
            variant="outlined"
            placeholder="Descriere tranzactie"
            fullWidth
            value={this.state.invoice_description}
            name="invoice_description"
            onChange={this.handleChangeInput}
          />
        </div>

        <div className="operation-form-row buttons-container-operations">
          <Button
            className="operation-cancel-button"
            onClick={() => this.props.closeOperationsPopup()}
          >
            {" "}
            <div className="button-content">
              Anuleaza
              <Icon name="x" className="op-cancel-icon" />
            </div>
          </Button>
          <Button
            className="operation-save-button"
            onClick={this.submitInvoice}
          >
            {" "}
            <div className="button-content">
              Salveaza
              <Icon name="add" className="add-icon" />
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default NewInvoice;
