import React, { useState } from "react";
import axios from "axios";
import "./AddAgeGroup.scss";
import { Icon, Button, Dropdown, Input } from "semantic-ui-react";
import { store } from "react-notifications-component";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";

const ageCategorieOptions = [
  {
    value: 0,
    label: "Seniori",
  },
  {
    value: 1,
    label: "U6",
  },
  {
    value: 2,
    label: "U7",
  },
  {
    value: 3,
    label: "U8",
  },
  {
    value: 4,
    label: "U9",
  },
  {
    value: 5,
    label: "U10",
  },
  {
    value: 6,
    label: "U11",
  },
  {
    value: 7,
    label: "U12",
  },
  {
    value: 8,
    label: "U13",
  },
  {
    value: 9,
    label: "U14",
  },
  {
    value: 10,
    label: "U15",
  },
  {
    value: 11,
    label: "U16",
  },
  {
    value: 12,
    label: "U17",
  },
  {
    value: 13,
    label: "U18",
  },
  {
    value: 14,
    label: "U19",
  },
  {
    value: 15,
    label: "U20",
  },
  {
    value: 16,
    label: "U21",
  },
  {
    value: 17,
    label: "U22",
  },
  {
    value: 18,
    label: "U23",
  },
];

function EditChildCompetition(props) {
  const { languagePack } = props;
  const comp_types = [
    {
      key: 1,
      value: "championship",
      text: languagePack.competition_type.elements["championship"],
    },
    {
      key: 2,
      value: "knockout",
      text: languagePack.competition_type.elements["knockout"],
    },
    {
      key: 3,
      value: "group-stage",
      text: languagePack.competition_type.elements["group_stage"],
    },
  ];

  const [name, setName] = useState(props.competition.competition_name);
  const [type, setType] = useState(props.competition.competition_type);
  const [nrTeams, setNrTeams] = useState(props.competition.teams_number);
  const [nrGroups, setNrGroups] = useState(props.competition.groups_number);
  const [dateStart, setDateStart] = useState(props.competition.date_start);
  const [dateEnd, setDateEnd] = useState(props.competition.date_end);
  const [minutesPerTime, setMinutesPerTime] = useState(
    props.competition.minutes_per_time
  );

  const [ageCategory, setAgeCategory] = useState(
    ageCategorieOptions.find(
      (option) => option.label == props.competition.category
    )
  );

  const changeMinutesPerTime = (e) => {
    if (
      e.length > 2 ||
      (e.length == 2 && Number(e) > 45) ||
      (e.length == 1 && Number(e) < 1)
    )
      return false;

    setMinutesPerTime(e);
  };

  const handleChangeInput = (e) => {
    setName(e.target.value);
  };

  const handleChangeDropdown = (e, value) => {
    setType(value.value);
  };

  const handleChangeInputGroups = (e) => {
    setNrGroups(e.target.value);
  };

  const handleChangeInputTeams = (e) => {
    setNrTeams(e.target.value);
  };

  function saveCompetition() {
    const competition = {
      id_competition: props.competition.id_competition,
      competition_name: name,
      competition_type: type,
      teams_number: nrTeams,
      groups_number: nrGroups,
      date_start: dateStart,
      date_end: dateEnd,
    };

    if (minutesPerTime != "") {
      competition.minutes_per_time = minutesPerTime;
    }

    if (ageCategory) {
      competition.category = ageCategory.label;
    }

    axios.post("match/edit_competition", competition).then(() => {
      store.addNotification({
        title: languagePack["manage_competitions"],
        message: languagePack["manage_competitions_success_changes"],
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      props.closePopup();
      props.getCompetitions();
    });
  }

  return (
    <div className="add-group-container">
      <div className="add-group-header">
        <div className="add-group-title">
          {languagePack.edit_age_group_competition}
        </div>
        <Icon
          name="close"
          className="close"
          size="large"
          onClick={() => props.closePopup()}
        />
      </div>
      <div className="add-group-row">
        <input
          placeholder={languagePack.age_group_name}
          className="add-group-input"
          name="name"
          value={name}
          onChange={handleChangeInput}
        />
      </div>
      <div className="add-group-row">
        <input
          placeholder={languagePack["teams_number"]}
          type="number"
          className="add-group-input"
          name="nr_teams"
          value={nrTeams}
          onChange={handleChangeInputTeams}
        />
      </div>

      <div className="add-group-row">
        <Dropdown
          selection
          placeholder={languagePack.competition_type["name"]}
          name="type"
          value={type}
          options={comp_types}
          onChange={handleChangeDropdown}
          className="add-group-dropdown"
        />
      </div>
      {type == "group-stage" && (
        <div className="add-group-row">
          <input
            placeholder={"Number of groups"}
            type="number"
            className="add-group-input"
            name="nr_groups"
            value={nrGroups}
            onChange={handleChangeInputGroups}
          />
        </div>
      )}
      <div className="add-group-row">
        <Input
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          placeholder={languagePack.minutes_per_time}
          className="add-value-input-time edit-child-comp-minutes-per-time-input"
          name="time"
          onChange={(e) => {
            changeMinutesPerTime(e.target.value);
          }}
          value={minutesPerTime ?? ""}
        />
      </div>
      {props.competition.multiple_age_groups == "0" && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="add-group-row">
            <DatePicker
              value={dateStart}
              onChange={(value) => setDateStart(value)}
              format="dd MMMM yyyy"
              name="date_start"
              inputVariant="outlined"
              className="group-date-input"
              emptyLabel={languagePack["starting_date"]}
            />
            <Icon
              name="calendar alternate outline"
              className="calendar-icon"
              size="small"
            />
          </div>
          <div className="add-group-row">
            <DatePicker
              value={dateEnd}
              onChange={(value) => setDateEnd(value)}
              format="dd MMMM yyyy"
              name="date_end"
              inputVariant="outlined"
              className="group-date-input"
              emptyLabel={languagePack["ending_date"]}
            />
            <Icon
              name="calendar alternate outline"
              className="calendar-icon"
              size="small"
            />
          </div>

          <div
            className="select-age-category"
            style={{ marginTop: "25px", marginBottom: "25px" }}
          >
            <TFBSelect
              placeholder={languagePack.select_age_category}
              label={languagePack.select_age_category}
              options={ageCategorieOptions}
              value={ageCategory}
              onChange={(selected) => setAgeCategory(selected)}
            />
          </div>
        </MuiPickersUtilsProvider>
      )}
      <div className="add-group-buttons">
        <Button className="cancel-button" onClick={() => props.closePopup()}>
          {languagePack["admin_cancel"]}
          <Icon name="close" className="white-icon" />
        </Button>
        <Button
          className="add-group-button"
          onClick={saveCompetition}
          disabled={!(name.length > 0 && type.length > 0)}
        >
          {languagePack["admin_add"]}
          <Icon name="add" className="white-icon" />
        </Button>
      </div>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(EditChildCompetition);
