import React, { useState, useEffect } from "react";
import "./MatchDetails.scss";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import TFBCard from "../../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBPlaceholder from "../../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
    TFBTableCell,
    sxStickyColumn,
} from "./../../../../components/reusable/useStyles";
import Select from "react-select";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";


function PlayersMatchRatingWidget(props) {
    const {
        languagePack,
        idMatch,
        teamsArray,
        doUpdate,
    } = props;

    const marks = [
        {
            value: "1",
            label: "1/5",
        },
        {
            value: "2",
            label: "2/5",
        },
        {
            value: "3",
            label: "3/5",
        },
        {
            value: "4",
            label: "4/5",
        },
        {
            value: "5",
            label: "5/5",
        },
    ];

    const history = useHistory();

    const [isLoaded, setIsLoaded] = useState(false);
    const [players, setPlayers] = useState([]);
    const [markForAll, setMarkForAll] = useState(null);

    useEffect(() => {
        getPlayersRating();
    }, [doUpdate]);

    const getPlayersRating = () => {
        axios
            .get(`match/get_manual_match_players_rating?id_match=${idMatch}&teams=${Array.of(teamsArray).join(",")}`)
            .then((res) => {
                setPlayers(res.data.players);
                setMarkForAll(res.data.same_rating_for_all)
                setIsLoaded(true);
            })
            .catch((err) => console.log(err));
    };

    const savePlayersRating = () => {
        let result = [];
        players.forEach(elem => {
            result.push({
                id_manual_match_event: elem.id_manual_match_event,
                match_rating: elem.match_rating != null ? elem.match_rating?.value : null,
                rating_remark: String(elem.rating_remark).trim(),
            })
        });

        axios
            .post("match/save_manual_match_players_rating", result)
            .then(res => {
                if (res.status == 200) {
                    getPlayersRating();
                    store.addNotification({
                        title: languagePack.matches_module,
                        message: languagePack.success_save_players_rating,
                        type: "success",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                } else {
                    store.addNotification({
                        title: languagePack.matches_module,
                        message: languagePack.fail_save_players_rating,
                        type: "danger",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                }
            })
            .catch(e => console.log(e))
    }

    return (
        <TFBCard>
            <TFBCardHeader title={languagePack.players_match_rating}>
            </TFBCardHeader>
            <TFBCardBody>
                {isLoaded && players.length > 0 ?
                    <div>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TFBTableCell
                                            sx={sxStickyColumn}
                                            style={{ maxWidth: "800px" }}
                                        >
                                            {languagePack.player}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                            {languagePack.remarks}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                            <div className="qualifying col1">
                                                <Select
                                                    className="select-mark"
                                                    placeholder={languagePack.mark_for_all}
                                                    isMulti={false}
                                                    options={marks}
                                                    value={markForAll}
                                                    onChange={(selected) => {
                                                        let data = players;
                                                        data.forEach((elem) => {
                                                            elem.match_rating = selected;
                                                        });
                                                        setMarkForAll(selected);
                                                    }}
                                                    styles={{
                                                        menu: (provided, state) => ({
                                                            ...provided,
                                                            color: "hsl(0,0%,20%)",
                                                        }),
                                                        menuPortal: (provided, state) => ({
                                                            ...provided,
                                                            zIndex: 9999,
                                                        }),
                                                    }}
                                                    menuPortalTarget={document.body}
                                                />
                                            </div>
                                        </TFBTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {players.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                                        >
                                            <TFBTableCell
                                                component="th"
                                                scope="row"
                                                sx={sxStickyColumn}
                                                style={{
                                                    minWidth: "200px",
                                                    width: "50%",
                                                    backgroundColor:
                                                        index % 2 == 0 ? "#fafafa" : "white",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "5px",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div
                                                        className="player-photo cursor-pointer"
                                                        onClick={() =>
                                                            history.push("/club/player/" + row.id_player)
                                                        }
                                                    >
                                                        <Avatar
                                                            className="player-avatar"
                                                            src={row.img_url}
                                                        />
                                                    </div>
                                                    <div className="player-error-container">
                                                        <div
                                                            className="player-name cursor-pointer"
                                                            style={{ color: "#666666" }}
                                                            onClick={() =>
                                                                history.push("/club/player/" + row.id_player)
                                                            }
                                                        >
                                                            {row.player_name}
                                                        </div>
                                                    </div>
                                                </div>
                                            </TFBTableCell>
                                            <TFBTableCell
                                                align="center"
                                                style={{ width: "30%" }}
                                            >
                                                <div className="presence-training-detail-mark">
                                                    <input
                                                        className="text-input"
                                                        placeholder={languagePack.add_remarks}
                                                        name="remark"
                                                        value={row.rating_remark}
                                                        onChange={(e) => {
                                                            let data = [...players];
                                                            data[index].rating_remark = e.target.value;
                                                            setPlayers(data);
                                                        }}
                                                    />
                                                </div>
                                            </TFBTableCell>
                                            <TFBTableCell
                                                align="center"
                                                style={{ minWidth: "127px", width: "20%" }}
                                            >
                                                <Select
                                                    className="select-mark"
                                                    placeholder={languagePack.mark}
                                                    isMulti={false}
                                                    options={marks}
                                                    value={row.match_rating}
                                                    isClearable={true}
                                                    onChange={(selected) => {
                                                        let data = [...players];
                                                        data[index].match_rating = selected;
                                                        let same_marks = true;
                                                        //selected is null
                                                        if (selected == null) {
                                                            same_marks = false;
                                                        } else {
                                                            //check if markForAll is same with anothers
                                                            if (markForAll != null) {
                                                                data.forEach((elem) => {
                                                                    if (elem.match_rating != null) {
                                                                        if (elem.match_rating.value !== markForAll?.value) {
                                                                            same_marks = false;
                                                                            return;
                                                                        }
                                                                    } else {
                                                                        same_marks = false;
                                                                        return;
                                                                    }
                                                                });
                                                            } else {
                                                                //get the array with marks != null
                                                                let notNullRating = data.filter(elem => elem.match_rating != null);
                                                                if (data.length > notNullRating.length) {
                                                                    same_marks = false;
                                                                } else {
                                                                    //check if the other marks are same as last one selected
                                                                    data.forEach((elem) => {
                                                                        if (elem.match_rating.value !== selected?.value) {
                                                                            same_marks = false;
                                                                            return;
                                                                        }
                                                                    });
                                                                }
                                                            }
                                                        }

                                                        if (!same_marks) {
                                                            setMarkForAll(null);
                                                        } else {
                                                            setMarkForAll(selected);
                                                        }
                                                        setPlayers(data)
                                                    }}
                                                    styles={{
                                                        menu: (provided, state) => ({
                                                            ...provided,
                                                            color: "hsl(0,0%,20%)",
                                                        }),
                                                        menuPortal: (provided, state) => ({
                                                            ...provided,
                                                            zIndex: 9999,
                                                        }),
                                                    }}
                                                    menuPortalTarget={document.body}
                                                />
                                            </TFBTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="under-table-buttons-container">
                            <TFBButton
                                color="green"
                                onClick={savePlayersRating}
                                renderIcon={() => <TFBIcon name="check" />}
                            >
                                {languagePack.save_players_rating}
                            </TFBButton>
                        </div>
                    </div> :
                    <TFBPlaceholder text={languagePack.no_players} />
                }
            </TFBCardBody>
        </TFBCard>
    );
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
}))(PlayersMatchRatingWidget);