import React from "react";
import Chart from "react-apexcharts";
import axios from "axios";

class TotalDebtsChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      options: {
        chart: {
          type: "area",
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },

        title: {
          text: "Total restante furnizori",
          align: "left",
        },
        labels: [],
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          opposite: false,
          labels: {
            formatter: function(value) {
              return value.toLocaleString();
            },
          },
        },
        legend: {
          horizontalAlign: "left",
        },
      },
    };
  }

  componentDidMount = () => {
    axios.get(`finances/total_debts_chart?id_club=1`).then((res) => {
      this.setState((prevState) => ({
        options: {
          ...prevState.options,
          labels: res.data.labels,
        },
        series: res.data.series,
      }));
    });
  };
  render() {
    return (
      <div className="col-lg-6 col-xxl-6">
        <div className="card card-custom" style={{ padding: 10 }}>
          <div id="chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="area"
              height={350}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TotalDebtsChart;
