import React from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import CircularLoader from "../../../../components/reusable/Loader";
import { Button, Dropdown } from "semantic-ui-react";
import { logout } from "../../../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./CardsManagement.scss";
import yellow_red from "./../../../../assets/yellow_red.png";

class CardsManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: new Date().getFullYear(),
      rates: [],
      data: [],
      data_brut: [],
      isFetching: true,
      editingRow: "",
      month: "",
      month_name: "",
      sum: "",
      payment_type: this.props.languagePack.add_payment_type["cash"],
      date_of_payment: null,
      description: "",
      openAddPaymentDialog: false,
      openEditPaymentDialog: false,
      openMessageDialog: false,
      openDeleteDialog: false,
      payments_to_delete: [],
      id_payment_delete: "",
      id_payment_overwrite: "",
      columns: [],

      competitions: [],
      selected_competition: "",
    };
  }

  componentDidMount = () => {
    this.getCompetitons();
  };

  getCompetitons = () => {
    axios
      .get(
        `rapidapi/get_competitions_for_dashboard?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => {
        this.setState({ competitions: res.data });
        if (res.data.length > 0) {
          this.setState(
            { selected_competition: res.data[0].value },
            this.getCardsManagementData
          );
        }
      })
      .catch((error) => console.log(error));
  };

  getCardsManagementData = () => {
    this.setState({ isFetching: true });
    axios
      .get(
        `match/get_cards_management_upgrade?id_club=${this.props.currentUser.id_club}&id_competition=${this.state.selected_competition}`
      )
      .then((res) => {
        this.setState({
          data: res.data.table_data,
          columns: res.data.columns,
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({
          isFetching: false,
        });
      });
  };

  generateCardsManagementReport = () => {
    const url = `https://api.thefootballbrain.app/match/generate_cards_situation_report_download?id_club=${this.props.currentUser.id_club}&id_competition=${this.state.selected_competition}`;
    window.location.assign(url);
  };

  handleChangeDropdownCompetition = (e, value) => {
    this.setState(
      {
        selected_competition: value.value,
      },
      this.getCardsManagementData
    );
  };

  render() {
    const { languagePack } = this.props;

    let columns = [
      {
        name: languagePack["table_name"],
        selector: "player_name",
        // sortable: true,
        grow: 2,
      },
      {
        center: true,
        name: languagePack.yellow,
        selector: "total",
        style: {
          backgroundColor: "#EEEEEE",
        },
      },
    ];
    this.state.columns.map((elem, index) => {
      columns.push({
        center: true,
        name: elem.name,
        selector: elem.selector,
        cell: (row) => {
          return row[elem.selector].minutes;
        },
        conditionalCellStyles: [
          {
            when: (row) =>
              row[elem.selector].color == "red" &&
              !row[elem.selector].both_cards,
            style: {
              backgroundColor: "red",
              color: "white",
            },
          },
          {
            when: (row) =>
              row[elem.selector].color == "yellow" &&
              !row[elem.selector].both_cards,
            style: {
              backgroundColor: "yellow",
              color: "black",
            },
          },
          {
            when: (row) =>
              row[elem.selector].color == "white" &&
              !row[elem.selector].both_cards,
            style: {
              backgroundColor: "transparent",
              color: "black",
            },
          },
          {
            when: (row) =>
              row[elem.selector].color == "black" &&
              !row[elem.selector].both_cards,
            style: {
              backgroundColor: "black",
              color: "white",
            },
          },
          {
            when: (row) => row[elem.selector].both_cards,
            style: {
              background: `url(${yellow_red})`,
              color: "black",
              backgroundPosition: "center",
            },
          },
        ],
      });
    });

    return (
      <div className="card card-custom">
        <div className="payments-container cards-management">
          <div
            className="football-api-match-title cards-management-generate"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              className="add-team-button"
              onClick={this.generateCardsManagementReport}
            >
              {`${languagePack.campaign_download} .pdf`}
            </Button>
            <Dropdown
              placeholder={"Selecteaza competitia"}
              selection
              options={this.state.competitions}
              onChange={this.handleChangeDropdownCompetition}
              name="chosen_competition"
              value={this.state.selected_competition}
              className="players-season-dropdown"
            />
          </div>
          <DataTable
            center
            columns={columns}
            data={this.state.data}
            highlightOnHover
            customStyles={customStyles}
            progressPending={this.state.isFetching}
            progressComponent={<CircularLoader />}
          />
        </div>
      </div>
    );
  }
}

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "rgb(250 250 250)",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  header: {
    style: {
      marginBottom: "20px",
    },
  },
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
};
const mapStateToProps = ({ layoutService: { languagePack }, auth }) => ({
  languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
});
export default connect(mapStateToProps, mapDispatchToProps)(CardsManagement);
