import LayoutServiceActions from "./layout.service.types";
import axios from "axios";
import { toAbsoluteUrl } from "./../../_metronic/_helpers";

import languages from "../../app/utils/languages";
import ro from "../../app/utils/lang/ro";
import es from "../../app/utils/lang/es";
import en from "../../app/utils/lang/en";
import it from "../../app/utils/lang/it";

axios.defaults.baseURL = "https://api.thefootballbrain.app/";

const setDiff = {};
Object.entries(en).forEach(([name, translation]) => {
    if (!ro[name]) {
        setDiff[name] = translation;
    }
});

const translations = languages.reduce(
    (final, item) => ({
        ...final,
        [item.lang]: {
            ...item,
            languagePack: { en, ro, es, it }[item.lang],
        },
    }),
    {}
);

let defaultLang = "es";
let hostname = window.location.hostname;

if (
    hostname.indexOf("localhost") !== 0 &&
    hostname.indexOf("thefootballbrain.app") !== 0
) {
    //Get the id of the club, then all the others infos
    axios
        .get(`club/get_club_by_custom_domain?slug=${hostname}`)
        .then((res) => {
            let id_club = res?.data?.id_club;
            if (id_club != null) {
                defaultLang = res.data.default_lang;
            }
        })
        .catch((err) => {
            console.log(err);
        });
    if (!defaultLang || Object.keys(translations).indexOf(defaultLang) === -1) {
        defaultLang = "en";
    }
} else {
    defaultLang = "en";
    if (!defaultLang || Object.keys(translations).indexOf(defaultLang) === -1) {
        defaultLang = "en";
    }
}

const initialState = {
    languagePack: translations[defaultLang].languagePack,
    loader: [],
    lang: translations[defaultLang].lang,
    languages,
};

const layoutServiceReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case LayoutServiceActions.SET_LANGUAGE:
            return {
                ...state,
                languagePack: translations[payload.lang].languagePack,
                lang: payload.lang,
            };
        case LayoutServiceActions.SET_CLUB:
            return {
                ...state,
                club: payload.club,
            };
        case LayoutServiceActions.SET_LOADER:
            return {
                ...state,
                loader: payload.value
                    ? [...state.loader, { key: payload.key, value: true }]
                    : state.loader.filter((item) => item.key !== payload.key),
            };
        default:
            return state;
    }
};

export default layoutServiceReducer;
