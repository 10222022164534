import React from "react";
import "./Competitions.scss";
import { Button, Icon } from "semantic-ui-react";
import axios from "axios";
import Moment from "react-moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddCompetition from "./AddCompetition";
import DeleteCompetition from "./DeleteCompetition";
import EditCompetition from "./EditCompetition";
import AddAgeGroup from "./AddAgeGroup";
import delete_icon from "../../../../assets/icons/delete.png";
import edit_icon from "../../../../assets/icons/edit.png";

import { connect } from "react-redux";
import EditChildCompetition from "./EditChildCompetition";
import permissions from "./../../../../../app/utils/permissions";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";

class CompetitionElemInList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      extendParentComp: false,
    };
  }

  render() {
    const { languagePack, index, item } = this.props;

    return (
      <>
        <div
          className="competitions-manual-row"
          style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "" }}
        >
          <div className="content-row">
            {/* <div className="competition-more">
              {item.multiple_age_groups == "1" && item.sub_comps.length > 0 && (
                <button
                  className="more-button"
                  onClick={() => {
                    this.setState({
                      extendParentComp: !this.state.extendParentComp,
                    });
                  }}
                >
                  {!this.state.extendParentComp && (
                    <Icon
                      className="more-icon extend"
                      name="caret right"
                      size="small"
                      style={{ cursor: "pointer" }}
                    />
                  )}
                  {this.state.extendParentComp && (
                    <Icon
                      className="more-icon"
                      name="caret down"
                      size="small"
                      style={{
                        cursor: "pointer",
                        color: "var(--primaryColor)",
                      }}
                    />
                  )}
                </button>
              )}
            </div> */}

            <div className="competition-name-wrapper">
              <div className="competition-name mobile-head-competition">
                {languagePack["competition_name"]}
              </div>
              <div
                className="competition-name"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (item.multiple_age_groups == "0") {
                    this.props.history.push(`/club/competitions/${item.key}`);
                  } else {
                    this.props.history.push(
                      `/club/competitions/info/${item.key}`
                    );
                  }
                }}
              >
                {item.text}
              </div>
            </div>

            <div className="competition-number-teams-wrapper">
              <div className="competition-number-teams mobile-head-competition">
                {languagePack["teams_number"]}
              </div>
              <div className="competition-number-teams">
                {item.teams_number !== "0" && item.teams_number}
              </div>
            </div>
            <div className="competition-date-start-wrapper">
              <div className="competition-date mobile-head-competition">
                {languagePack["starting_date"]}
              </div>
              <div className="competition-date">
                {item.date_start ? (
                  <Moment format="DD.MM.YYYY">{item.date_start}</Moment>
                ) : (
                  "-"
                )}
              </div>
            </div>

            <div className="competition-date-end-wrapper">
              <div className="competition-date mobile-head-competition">
                {languagePack["ending_date"]}
              </div>
              <div className="competition-date">
                {item.date_end ? (
                  <Moment format="DD.MM.YYYY">{item.date_end}</Moment>
                ) : (
                  "-"
                )}
              </div>
            </div>
            {item.sub_comps.map((item, num) => {
              return (
                this.state.extendParentComp && (
                  <div
                    className="extended-competition-mobile"
                    key={"child-competition-" + num}
                  >
                    <div
                      className="competitions-manual-row"
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                      }}
                    >
                      <div className="content-row extended-competition">
                        <div className="competition-more"></div>

                        <div className="competition-name-wrapper">
                          <div className="competition-name-child mobile-head-competition mobile-head-competition">
                            {"Age group name competition"}
                          </div>
                          <div
                            className="competition-name-child"
                            onClick={() => {
                              this.props.history.push(
                                `/club/competitions/${item.id_competition}`
                              );
                            }}
                          >
                            {item.competition_name}
                          </div>
                        </div>

                        <div className="competition-number-teams-wrapper">
                          <div className="competition-number-teams mobile-head-competition mobile-head-competition">
                            {languagePack["teams_number"]}
                          </div>
                          <div className="competition-number-teams-child">
                            {item.teams_number}
                          </div>
                        </div>

                        <div className="competition-number-teams-child-wrapper">
                          <div className="competition-number-teams-child mobile-head-competition mobile-head-competition">
                            {"Competition type / Number of groups"}
                          </div>
                          <div className="competition-date">
                            {item.competition_type +
                              (item.groups_number !== null
                                ? " / " + item.groups_number
                                : "")}
                          </div>
                        </div>

                        <div className="competition-date-start-wrapper">
                          <div className="competition-date mobile-head-competition mobile-head-competition">
                            {languagePack["starting_date"]}
                          </div>
                          <div className="competition-date">
                            {item.date_start ? (
                              <Moment format="DD.MM.YYYY">
                                {item.date_start}
                              </Moment>
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>

                        <div className="competition-date-end-wrapper">
                          <div className="competition-date mobile-head-competition mobile-head-competition">
                            {languagePack["ending_date"]}
                          </div>
                          <div className="competition-date">
                            {item.date_end ? (
                              <Moment format="DD.MM.YYYY">
                                {item.date_end}
                              </Moment>
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>

                        <div
                          className="competition-options"
                          onClick={() => {
                            this.props.editGroupAge(item);
                          }}
                        >
                          <button type="button" className="btn btn-secondary">
                            <img src={edit_icon} alt="edit" />
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                              this.props.deleteGroupAge(item);
                            }}
                          >
                            <img src={delete_icon} alt="delete" />
                          </button>
                          <button
                            className="additional-button btn btn-secondary"
                            onClick={() =>
                              this.props.history.push(
                                `/club/competitions/prizes/${item.id_competition}`
                              )
                            }
                          >
                            <Icon
                              className="additional-icon"
                              name="winner"
                              size="large"
                              style={{ cursor: "pointer" }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
            <div className="competition-options">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  if (item.multiple_age_groups == "0") {
                    this.props.history.push(
                      `/club/competitions/${this.props.item.key}`
                    );
                  } else {
                    this.props.history.push(
                      `/club/competitions/info/${this.props.item.key}`
                    );
                  }
                }}
              >
                <TFBIcon color="black" name="view" />
              </button>
              {(item.competition_id_club == this.props.currentUser.id_club ||
                this.props.currentUser.user_permissions[
                  permissions.EDIT_COMMON_COMPETITION
                ]) && (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      this.props.editComp(item);
                    }}
                  >
                    <img src={edit_icon} alt="edit" />
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      this.props.deleteComp(item);
                    }}
                  >
                    <img
                      src={delete_icon}
                      alt="edit"
                      // style={{ marginLeft: 20 }}
                    />
                  </button>
                  {item.multiple_age_groups != "0" && (
                    <button
                      className="additional-button btn btn-secondary"
                      onClick={() => {
                        this.props.addGroupAge(item);
                        this.setState({
                          extendParentComp: true,
                        });
                      }}
                    >
                      <Icon
                        className="additional-icon"
                        name="add"
                        size="large"
                        style={{ cursor: "pointer" }}
                      />
                    </button>
                  )}
                  <div className="competition-more">
                    {item.multiple_age_groups == "1" &&
                      item.sub_comps.length > 0 && (
                        <button
                          className="more-button"
                          onClick={() => {
                            this.setState({
                              extendParentComp: !this.state.extendParentComp,
                            });
                          }}
                        >
                          {!this.state.extendParentComp && (
                            <div className="btn btn-secondary">
                              <Icon
                                className="more-icon extend"
                                name="caret left"
                                size="large"
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          )}
                          {this.state.extendParentComp && (
                            <div className="btn btn-secondary caret-up-position">
                              <Icon
                                className="more-icon"
                                name="caret up"
                                size="large"
                                style={{
                                  cursor: "pointer",
                                  color: "var(--primaryColor)",
                                }}
                              />
                            </div>
                          )}
                        </button>
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {item.sub_comps.length > 0 && this.state.extendParentComp && (
          <div
            className="competitions-manual-row-header-extended desktop-header"
            style={{
              paddingBottom: "0px",
              backgroundColor: index % 2 == 0 ? "#fafafa" : "",
            }}
          >
            <div className="competition-more"></div>
            <div className="competition-name-child">
              {"Age group name competition"}
            </div>
            <div className="competition-date">
              {languagePack["teams_number"]}
            </div>
            <div className="competition-number-teams-child">
              {"Competition type / Number of groups"}
            </div>
            <div className="competition-date">
              {languagePack["starting_date"]}
            </div>
            <div className="competition-date">
              {languagePack["ending_date"]}
            </div>
            <div className="competition-options">
              {languagePack["news_page_header_options"]}
            </div>
          </div>
        )}
        {item.sub_comps.map((item, num) => {
          return (
            this.state.extendParentComp && (
              <div
                className="extended-competition-desktop"
                key={"child-competition-desktop-" + num}
              >
                <div
                  className="competitions-manual-row"
                  style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "" }}
                >
                  <div className="content-row extended-competition">
                    <div className="competition-more"></div>

                    <div className="competition-name-wrapper">
                      <div className="competition-name-child mobile-head-competition mobile-head-competition">
                        {"Age group name competition"}
                      </div>
                      <div
                        className="competition-name-child"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.props.history.push(
                            `/club/competitions/${item.id_competition}`
                          );
                        }}
                      >
                        {item.competition_name}
                      </div>
                    </div>

                    <div className="competition-number-teams-wrapper">
                      <div className="competition-number-teams mobile-head-competition mobile-head-competition">
                        {languagePack["teams_number"]}
                      </div>
                      <div className="competition-number-teams-child">
                        {item.teams_number}
                      </div>
                    </div>

                    <div className="competition-number-teams-child-wrapper">
                      <div className="competition-number-teams-child mobile-head-competition mobile-head-competition">
                        {"Competition type / Number of groups"}
                      </div>
                      <div className="competition-date">
                        {item.competition_type +
                          (item.groups_number !== null
                            ? " / " + item.groups_number
                            : "")}
                      </div>
                    </div>

                    <div className="competition-date-start-wrapper">
                      <div className="competition-date mobile-head-competition mobile-head-competition">
                        {languagePack["starting_date"]}
                      </div>
                      <div className="competition-date">
                        {item.date_start ? (
                          <Moment format="DD.MM.YYYY">{item.date_start}</Moment>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>

                    <div className="competition-date-end-wrapper">
                      <div className="competition-date mobile-head-competition mobile-head-competition">
                        {languagePack["ending_date"]}
                      </div>
                      <div className="competition-date">
                        {item.date_end ? (
                          <Moment format="DD.MM.YYYY">{item.date_end}</Moment>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>

                    <div className="competition-options">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          this.props.history.push(
                            `/club/competitions/${item.id_competition}`
                          );
                        }}
                      >
                        <TFBIcon color="black" name="view" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          this.props.editGroupAge(item);
                        }}
                      >
                        <img src={edit_icon} alt="edit" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          this.props.deleteGroupAge(item);
                        }}
                      >
                        <img
                          src={delete_icon}
                          alt="delete"
                          // style={{ marginLeft: 20 }}
                        />
                      </button>
                      <button
                        className="additional-button btn btn-secondary winner-position"
                        onClick={() =>
                          this.props.history.push(
                            `/club/competitions/prizes/${item.id_competition}`
                          )
                        }
                      >
                        <Icon
                          className="additional-icon"
                          name="winner"
                          size="large"
                          style={{ cursor: "pointer" }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          );
        })}
      </>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(CompetitionElemInList);
