import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { Layout, LayoutSplashScreen } from "../_metronic/layout";
import BasePage from "./BasePage";
import AuthPage from "./Auth/AuthPage";
import { IntlProvider } from "react-intl";

import { checkToken } from "../redux/authCrud";
import { logout, login } from "./utils/api";

import { useHistory } from "react-router-dom";
import axios from "axios";

export function Routes() {
  const { isAuth, lang, languagePack, token, user } = useSelector(
    ({ auth, layoutService: { lang, languagePack } }) => ({
      isAuth: auth?.user?.token,
      lang,
      token: auth?.user?.token,
      languagePack,
      user: auth?.user,
    })
  );
  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    if (isAuth) {
      return history.listen((location) => {
        let array = location.pathname.split("/");
        const payload = {
          token: token,
          id_user: user.id_user,
          id_club: user.id_club,
          full_pathname: location.pathname,
          module: array.length > 1 ? array[1] : null,
          sub_module: array.length > 2 ? array[2] : null,
          id_entity: array.length > 3 ? array[3] : null,
        }
        axios
          .post("user/add_access_log", payload)
          .catch(err => console.log(err))
      })
    }
    return;
  }, [history])

  const [messages, setMessages] = useState(languagePack.FORM_CONTROL);

  function checkLoggedUser() {
    return checkToken({ token }).then(({ data }) => {
      if (data.success === 1) {
        login(data.user)(dispatch);
      } else {
        logout({ token })(dispatch);
      }
    });
  }

  useLayoutEffect(() => {
    setMessages(languagePack.FORM_CONTROL);
  }, [lang]);

  useEffect(() => {
    setTimeout(() => {
      if (token) {
        checkLoggedUser();
      }
    }, 5000);
  }, []);

  const user_permissions =
    user?.user_permissions && typeof user.user_permissions === "object"
      ? user.user_permissions
      : null;

  return (
    <IntlProvider locale="en" messages={messages}>
      <Switch>
        {!isAuth ? (
          <>
            <Route path="/auth" component={AuthPage} />
            <Route path="/" render={() => <Redirect to="/auth" />} />
          </>
        ) : user_permissions ? (
          <>
            <Layout>
              <BasePage user_permissions={user_permissions} />
            </Layout>
          </>
        ) : (
          <LayoutSplashScreen />
        )}
      </Switch>
    </IntlProvider>
  );
}
