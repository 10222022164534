import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Moment from "react-moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import EditTransactionDetails from "./EditTransactionDetails";

export default function LastTransactions(props) {
  const [activeIndexes, setActiveIndexes] = useState([]);
  const [editDialog, setEditDialog] = useState(false);
  const [editedTransaction, setEditedTransaction] = useState({});

  function expandRow(value) {
    if (activeIndexes.includes(value)) {
      setActiveIndexes((oldArray) => oldArray.filter((x) => x != value));
    } else {
      setActiveIndexes((oldArray) => [...oldArray, value]);
    }
  }

  return (
    <div className="partner-profile-table-container">
      <div className="end-month-sold-header">Istoric tranzactii</div>
      {props.transactions.length > 0 ? (
        <>
          <div className="partner-profile-table-row table-header-partner-profile">
            <div className="partner-profile-table-cell last-trans-profile column-left-align">
              Valoare
            </div>
            <div className="partner-profile-table-cell last-trans-profile column-center-align">
              Tip
            </div>
            <div className="partner-profile-table-cell last-trans-profile column-end-align">
              Data efectuarii
            </div>
            <div style={{ width: "16%", textAlign: "end" }}></div>
          </div>
          {props.transactions.map((row, index) => {
            return (
              <>
                <div
                  className="partner-profile-table-row"
                  key={row.id_transaction}
                  style={{ backgroundColor: index % 2 === 0 ? "#fafafa" : "" }}
                >
                  <div
                    className="partner-profile-table-cell last-trans-profile column-left-align"
                    onClick={() => expandRow(row.id_transaction)}
                  >
                    {row.transaction_value}
                    {row.currency === "EUR" ? (
                      <Icon
                        name="eur"
                        className="eur-icon-transaction mg--0"
                      />
                    ) : (
                      <div style={{ marginLeft: 5 }}>RON</div>
                    )}
                  </div>
                  <div className="partner-profile-table-cell last-trans-profile column-center-align">
                    {row.type.includes("payment")
                      ? "Plata efectuata"
                      : "Facturare noua"}
                  </div>
                  <div className="partner-profile-table-cell last-trans-profile column-end-align">
                    <Moment format="DD.MM.YYYY">{row.transaction_date}</Moment>
                  </div>
                  <div
                    style={{
                      width: "16%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      name="edit"
                      className="transaction-edit-icon"
                      size="large"
                      onClick={() => {
                        setEditDialog(true);
                        setEditedTransaction(row);
                      }}
                    />
                    {activeIndexes.includes(row.id_transaction) ? (
                      <ExpandLessIcon
                        className="expand-icon-transactions"
                        onClick={() => expandRow(row.id_transaction)}
                      />
                    ) : (
                      <div>
                        {row.description.length > 0 && (
                          <ExpandMoreIcon
                            className="expand-icon-transactions"
                            onClick={() => expandRow(row.id_transaction)}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {activeIndexes.includes(row.id_transaction) && (
                  <div
                    className="expanded-transaction-container"
                    style={{
                      backgroundColor: index % 2 === 0 ? "#fafafa" : "",
                    }}
                  >
                    {row.description}
                  </div>
                )}
              </>
            );
          })}
        </>
      ) : (
        <div className="partner-no-transactions-placeholder">
          Nicio tranzactie inregistrata momentan
        </div>
      )}

      <Dialog
        open={editDialog}
        maxWidth="xl"
        // onClose={() => setEditDialog(false)}
      >
        <div className="add-team-popup-header">
          <div className="add-team-popup-title">Editeaza tranzactie</div>
          <div className="add-team-popup-close">
            {" "}
            <Icon
              name="close"
              className="close-icon-popup"
              onClick={() => setEditDialog(false)}
            />
          </div>
        </div>
        <DialogContent>
          <EditTransactionDetails
            closeDialog={() => setEditDialog(false)}
            initialDate={editedTransaction.transaction_date}
            initialValue={editedTransaction.transaction_value}
            initialDescription={editedTransaction.description}
            id_transaction={editedTransaction.id_transaction}
            refreshData={() => props.refreshData()}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
