import React, { ReactElement, ReactNode } from "react";
import { ButtonProps } from "../types";
import styles from "./TFBButton.module.scss";

const TFBButton = ({
  disabled = false,
  children,
  color = "darkGray",
  onClick,
  startIcon,
  endIcon = true,
  size = "lg",
  className,
  renderIcon,
  style,
}: ButtonProps) => {
  let icon;
  if (renderIcon) {
    icon = renderIcon();
  } else {
    icon = "";
  }
  const cssColor = color;
  const cssSize = size;
  return (
    <button
      className={`tfb-button-custom ${styles.tfbbutton} ${
        className ? className : ""
      } ${styles[cssColor]} ${styles[cssSize]} ${
        disabled ? styles.disabledBtn : ""
      }`}
      disabled={disabled}
      onClick={onClick}
      style={style}
    >
      <div
        className={styles.tfbbuttonContent}
        style={{ flexDirection: startIcon ? "row-reverse" : "row" }}
      >
        {children}
        {(startIcon || endIcon) && icon}
      </div>
    </button>
  );
};
export default TFBButton;
