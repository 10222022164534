import React from "react";
import axios from "axios";
import { Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/ro";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "./Goals.scss";
import Loader from "react-loader-spinner";

import TFBCard from "../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../components/design-system/Card/TFBCardHeader";
import TFBDropdown from "../../components/design-system/Dropdown/TFBDropdown";
import TFBButton from "../../components/design-system/Button/TFBButton";
import TFBIcon from "../../components/design-system/Icon/TFBIcon";
import DonutChart from "./DonutChart.tsx";

class Goals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      teamOptions: [],
      competitonsOptions: [],
      idSelectedTeam: "",
      idSelectedCompetition: "",

      dateStart: moment(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      ).format("YYYY-MM-DD"),
      dateEnd: moment(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      ).format("YYYY-MM-DD"),

      conceded_goals_by_category: {},
      conceded_goals_per_half: {},
      scored_goals_by_category: {},
      scored_goals_per_half: {},
    };
  }

  componentDidMount = async () => {
    await this.getTeamsDropdown();
    await this.getCompetitionsDropdown();
    this.getChartsData(
      this.state.idSelectedTeam,
      this.state.idSelectedCompetition,
      this.state.dateStart,
      this.state.dateEnd
    );
  };

  getTeamsDropdown = async () => {
    await axios
      .get(
        `club/get_teams_dropdown?id_club=${this.props.currentUser.id_club}&all=0&id_user=${this.props.currentUser.id_user}&token=${this.props.token}`
      )
      .then((res) => {
        this.setState({
          teamOptions: res.data,
          idSelectedTeam: res.data[0].value,
        });
      });
  };

  getCompetitionsDropdown = async () => {
    await axios
      .get(
        `club/get_competitions_dropdown?id_club=${this.props.currentUser.id_club}&all=1&id_user=${this.props.currentUser.id_user}&token=${this.props.token}`
      )
      .then((res) => {
        this.setState({
          competitonsOptions: res.data,
          idSelectedCompetition: res.data[0].value,
        });
      });
  };

  getChartsData = (
    idSelectedTeam,
    idSelectedCompetition,
    dateStart,
    dateEnd
  ) => {
    axios
      .get(
        `club/get_goals_stats?id_team=${idSelectedTeam}&id_competition=${idSelectedCompetition}&id_club=${this.props.currentUser.id_club}&id_user=${this.props.currentUser.id_user}&date_start=${dateStart}&date_end=${dateEnd}&token=${this.props.token}`
      )
      .then((res) => {
        const charts = res.data.charts;
        this.setState((prevState) => ({
          ...prevState,
          conceded_goals_by_category: charts.conceded_goals_by_category,
          conceded_goals_per_half: charts.conceded_goals_per_half,
          scored_goals_by_category: charts.scored_goals_by_category,
          scored_goals_per_half: charts.scored_goals_per_half,
          isLoading: false,
        }));
      });
  };

  handleChangeTeam = (e, value) => {
    this.setState((prevState) => ({
      ...prevState,
      idSelectedTeam: value.value,
    }));
  };

  handleChangeCompetition = (e, value) => {
    this.setState((prevState) => ({
      ...prevState,
      idSelectedCompetition: value.value,
    }));
  };

  handleChangeDateStart = (selectedDate) => {
    this.setState((prevState) => ({
      ...prevState,
      dateStart: moment(selectedDate).format("YYYY-MM-DD"),
    }));
  };

  handleChangeDateEnd = (selectedDate) => {
    this.setState((prevState) => ({
      ...prevState,
      dateEnd: moment(selectedDate).format("YYYY-MM-DD"),
    }));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <TFBCard>
        <TFBCardHeader title={languagePack.goals_statistics}></TFBCardHeader>
        {/* ----------------------CHARTS FILTERS----------------------------- */}
        <div className="goals-statistic-filter-header">
          <TFBDropdown
            placeholder={languagePack["select_team"]}
            selection={true}
            options={this.state.teamOptions}
            className="teams dropdown"
            value={this.state.idSelectedTeam}
            onChange={this.handleChangeTeam}
          />

          <TFBDropdown
            placeholder={"Competiție"}
            selection={true}
            options={this.state.competitonsOptions}
            className="competition dropdown"
            value={this.state.idSelectedCompetition}
            onChange={this.handleChangeCompetition}
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="goal-stats-date-pickers">
              <div className="select-date-wrapper">
                <DatePicker
                  value={this.state.dateStart}
                  onChange={(selected) => this.handleChangeDateStart(selected)}
                  emptyLabel={"Dată început"}
                  format="dd.MM.yyyy"
                  inputVariant="outlined"
                  className="goals-stats-date-input"
                />
                <TFBIcon
                  name="calendar"
                  color="black"
                  className="calendar-icon"
                />
              </div>
              {"-"}
              <div className="select-date-wrapper">
                <DatePicker
                  value={this.state.dateEnd}
                  onChange={(selected) => this.handleChangeDateEnd(selected)}
                  emptyLabel={"Dată sfârșit"}
                  format="dd.MM.yyyy"
                  inputVariant="outlined"
                  className="goals-stats-date-input"
                />

                <TFBIcon
                  name="calendar"
                  color="black"
                  className="calendar-icon"
                />
              </div>
            </div>
          </MuiPickersUtilsProvider>

          <TFBButton
            color="green"
            className="show-summary"
            onClick={() => {
              this.setState({ isLoading: true });
              this.getChartsData(
                this.state.idSelectedTeam,
                this.state.idSelectedCompetition,
                this.state.dateStart,
                this.state.dateEnd
              );
            }}
            disabled={this.state.dateEnd < this.state.dateStart}
          >
            {languagePack.show_statistics}
          </TFBButton>
        </div>

        <TFBCardBody>
          {/* ----------------------HANDLERS INVALID FILTERS & LOADING DATA----------------------------- */}
          {this.state.isLoading ? (
            <div
              style={{
                textAlign: "center",
                marginBottom: "20px",
                topBottom: "30px",
              }}
            >
              <Loader
                type="TailSpin"
                color={window.clubCustomization.primary_color}
                height={60}
                width={60}
              />
            </div>
          ) : (
            this.state.dateEnd < this.state.dateStart && (
              <div className="invalid-data-stats-call-up-message">
                <Icon name="exclamation" color="red" size="big" />
                <div className="invalid-data-stats-up-message-text">
                  {languagePack.invalid_period_time}
                </div>
              </div>
            )
          )}

          {/* ----------------------CHARTS----------------------------- */}
          <div className="stats-row-charts">
            {!this.state.isLoading &&
              this.state.dateEnd > this.state.dateStart && (
                <>
                  <div className="charts-row">
                    {
                      <div id="chart11">
                        <DonutChart
                          series={this.state.scored_goals_by_category?.series}
                          labels={this.state.scored_goals_by_category?.labels}
                          chartTitle={languagePack.scored_goals_by_category}
                          colors={this.state.scored_goals_by_category?.colors}
                          languagePack={this.props.languagePack}
                        />
                      </div>
                    }

                    {
                      <div id="chart22">
                        <DonutChart
                          series={this.state.conceded_goals_by_category?.series}
                          labels={this.state.conceded_goals_by_category?.labels}
                          chartTitle={languagePack.conceded_goals_by_category}
                          colors={this.state.conceded_goals_by_category?.colors}
                          languagePack={this.props.languagePack}
                        />
                      </div>
                    }
                  </div>

                  <div className="charts-row">
                    {
                      <div id="chart33">
                        <DonutChart
                          series={this.state.scored_goals_per_half?.series}
                          labels={this.state.scored_goals_per_half?.labels}
                          chartTitle={languagePack.scored_goals_per_half}
                          colors={this.state.scored_goals_per_half?.colors}
                          languagePack={this.props.languagePack}
                        />
                      </div>
                    }

                    {
                      <div id="chart44">
                        <DonutChart
                          series={this.state.conceded_goals_per_half?.series}
                          labels={this.state.conceded_goals_per_half?.labels}
                          chartTitle={languagePack.conceded_goals_per_half}
                          colors={this.state.conceded_goals_per_half?.colors}
                          languagePack={this.props.languagePack}
                        />
                      </div>
                    }
                  </div>
                </>
              )}
          </div>
        </TFBCardBody>
      </TFBCard>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(Goals);
