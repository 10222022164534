export default {
    MEDICAL: "MEDICAL",
    FINANCIAL: "FINANCIAL",
    DASHBOARD: "DASHBOARD",
    CLUB: "CLUB",
    DOCUMENTS: "DOCUMENTS",
    SCOUTING: "SCOUTING",
    REPORTS: "REPORTS",
    FANS: "FANS",
    SITE: "SITE",
    VIDEOGALLERY: "VIDEOGALLERY",
    ANY: "ANY",
    EDIT_COMMON_COMPETITION: "EDIT_COMMON_COMPETITION",
    APPROVE_NECESSITY_REPORT: "APPROVE_NECESSITY_REPORT",
    SHOP: "SHOP",
    BONUSES_MONITORING: "BONUSES_MONITORING",
    COSTS: "COSTS",
    ONLINE_PAYMENTS: "ONLINE_PAYMENTS",
    NECESSITY_REPORTS: "NECESSITY_REPORTS",
    IMPORT_FROM_STATEMENT: "IMPORT_FROM_STATEMENT",
    PARENTS_APP: "PARENTS_APP",
    CLUB_ADMIN: "CLUB_ADMIN",
}