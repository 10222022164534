import React from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import CircularLoader from "../../../components/reusable/Loader";
import { Icon, TextArea, Checkbox, Input } from "semantic-ui-react";
import NumberFormat from "react-number-format";
import { logout } from "../../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as constants from "../../../utils/constants";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogContent } from "@material-ui/core";
import { store } from "react-notifications-component";
import "./Payments.scss";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Redirect } from "react-router-dom";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import { TableContainer } from "@material-ui/core";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  sxStickyColumn,
  TFBTableCell,
} from "../../../components/reusable/useStyles";

const paymentType = {
  cash: "numerar",
  bank_transfer: "transfer bancar",
  parents_app: "aplicație părinți",
  online_website: "online website",
};

class Payments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: new Date().getFullYear(),
      rates: [],
      data: [],
      data_brut: [],
      isFetching: true,
      editingRow: "",
      sum: "",
      payment_type: null,
      date_of_payment: null,
      description: "",
      openAddPaymentDialog: false,
      openEditPaymentDialog: false,
      openMessageDialog: false,
      openDeleteDialog: false,
      payments_to_delete: [],
      id_payment_delete: "",
      id_payment_overwrite: "",
      add_receipt_checkbox: false,
      generate_receipt: false,
      receipt_payer: "",
      nr_receipt: 0,
      receipt_name: "",
      receipt_serial: "",
      monthIsSelected: false,
      months: [
        {
          value: "1",
          key: "january",
          text: this.props.languagePack.table_months["jan"],
          id: "jan",
        },
        {
          value: "2",
          key: "february",
          text: this.props.languagePack.table_months["feb"],
          id: "feb",
        },
        {
          value: "3",
          key: "march",
          text: this.props.languagePack.table_months["mar"],
          id: "mar",
        },
        {
          value: "4",
          key: "april",
          text: this.props.languagePack.table_months["apr"],
          id: "apr",
        },
        {
          value: "5",
          key: "may",
          text: this.props.languagePack.table_months["may"],
          id: "may",
        },
        {
          value: "6",
          key: "june",
          text: this.props.languagePack.table_months["jun"],
          id: "jun",
        },
        {
          value: "7",
          key: "july",
          text: this.props.languagePack.table_months["jul"],
          id: "jul",
        },
        {
          value: "8",
          key: "august",
          text: this.props.languagePack.table_months["aug"],
          id: "aug",
        },
        {
          value: "9",
          key: "september",
          text: this.props.languagePack.table_months["sep"],
          id: "sep",
        },
        {
          value: "10",
          key: "october",
          text: this.props.languagePack.table_months["oct"],
          id: "oct",
        },
        {
          value: "11",
          key: "november",
          text: this.props.languagePack.table_months["nov"],
          id: "nov",
        },
        {
          value: "12",
          key: "december",
          text: this.props.languagePack.table_months["dec"],
          id: "dec",
        },
      ],
      hasAccessToTeam: false,
      redirectToTeams: false,

      selected_month: null,
      player_monthly_tax: "",
      player_first_month_year_for_payment: null,
      tax_reason: "",
    };
  }

  componentDidMount = () => {
    this.hasAccessToTeam();
  };

  hasAccessToTeam = () => {
    axios
      .get(
        `user/has_access_to_team?id_team=${this.props.match.params.id}&id_user=${this.props.currentUser.id_user}`
      )
      .then((res) => {
        if (res.data == 1) {
          this.setState({
            hasAccessToTeam: true,
          });
          this.getPayments();
        } else {
          this.setState({
            redirectToTeams: true,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getPayments = () => {
    const id_team = this.props.match.params.id;
    this.setState({ isFetching: true });
    axios
      .get(`payment/get_team_payments_new`, {
        params: {
          id_team,
          token: this.props.token,
          payment_year: this.state.selectedYear,
        },
      })
      .then((res) => {
        let data = [];
        res.data.players.map((item, index) => {
          let obj = {};
          obj = {
            id: item.id_player,
            name: item.player_name,
            payments_lenght: item.payments.length,
            inactive_player: item.inactive,
            monthly_tax: item.monthly_tax,
            tax_reason: item.tax_reason,
            first_month_year_for_payment: item.first_month_year_for_payment,
          };
          item.payments.map((line, index) => {
            obj = {
              ...obj,
              ...{
                [this.state.months[line.payment_month - 1].key]:
                  line.payment_amount,
                [`${
                  this.state.months[line.payment_month - 1].key
                }_payment_type`]: line.payment_type_label,
              },
              // payment_type_label: line.payment_type_label,
            };
          });
          data.push(obj);
        });
        this.setState({
          data: data,
          data_brut: res.data.players,
          nr_receipt: Number(res.data.last_nr_receipt),
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({
          isFetching: false,
        });
      });
  };

  onChangeYear = (inc) => {
    this.setState(
      {
        selectedYear: this.state.selectedYear + inc,
      },
      () => {
        this.getPayments();
      }
    );
  };

  changeReceiptSerial = (value) => {
    if (value.length > 3) return false;

    this.setState((prevState) => ({
      ...prevState,
      receipt_serial: value,
    }));
  };

  formatNumber = (value) => {
    return (
      <NumberFormat
        value={Math.round(this.convertCurrency(value))}
        displayType={"text"}
        thousandSeparator={true}
      />
    );
  };

  getCurrency = () => {
    fetch("https://api.thefootballbrain.app/club/get_currency_rates")
      .then((res) => res.json())
      .then((data) => this.setState({ rates: data }));
  };

  convertCurrency = (value) => {
    if (this.props.currency === constants.currencies.EUR.uniqueSlug) {
      return value;
    } else {
      return value * this.state.rates[this.props.currency];
    }
  };

  closeAddPaymentPopup = () => {
    this.setState({
      openAddPaymentDialog: false,
      selected_month: null,
      monthIsSelected: false,
      sum: "",
      payment_type: this.props.languagePack.add_payment_type["cash"],
      date_of_payment: null,
      description: "",
    });
  };

  closeEditPaymentPopup = () => {
    this.setState({
      openEditPaymentDialog: false,
      player_monthly_tax: "",
      tax_reason: "",
      player_first_month_year_for_payment: null,
    });
  };

  closeMessageDialog = () => {
    this.setState({
      openMessageDialog: false,
    });
  };

  closeDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
    });
  };

  addPayment = () => {
    let payload = {
      id_player: this.state.editingRow.id,
      payment_type: this.state.payment_type?.label,
      payment_type_label: this.state.payment_type?.value,
      payment_month: this.state.selected_month?.value,
      payment_year: this.state.selectedYear,
      payment_description: this.state.description,
      payment_amount: this.state.sum,
      payment_payed_date: this.state.date_of_payment,
      id_payment: this.state.id_payment_overwrite,
      id_club: this.props.currentUser.id_club,
      generate_receipt: { true: "1", false: "0" }[this.state.generate_receipt],
    };

    if (this.state.generate_receipt) {
      payload.receipt_payer = this.state.receipt_payer;
      payload.receipt_name = this.state.receipt_name;
      payload.receipt_serial = this.state.receipt_serial;
      payload.nr_receipt = this.state.nr_receipt;
    }

    axios.post("payment/payment_add_manual_new", payload).then(() => {
      this.setState({
        id_payment_overwrite: "",
        openMessageDialog: false,

        receipt_payer: "",
        receipt_name: "",
        generate_receipt: false,
        add_receipt_checkbox: false,
        selected_month: null,
        monthIsSelected: false,
      });
      this.closeAddPaymentPopup();
      this.getPayments();
      store.addNotification({
        title: this.props.languagePack["fixtures_schedule"],
        message: this.props.languagePack["payment_successfully_added"],
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
    });
  };

  checkAddPayment = () => {
    if (
      this.state.editingRow.hasOwnProperty(
        this.state.months[this.state.selected_month?.value - 1].key
      )
    ) {
      let elem = this.state.data_brut.find((obj) => {
        return obj.id_player == this.state.editingRow.id;
      });
      let payment = elem.payments.find((obj) => {
        return obj.payment_month == this.state.selected_month?.value;
      });
      this.setState({
        openMessageDialog: true,
        id_payment_overwrite: payment.id_payment,
      });
      return false;
    }
    return true;
  };

  deletePayment = () => {
    axios
      .post("payment/payment_delete", {
        id_payment: this.state.id_payment_delete,
      })
      .then(() => {
        this.setState({
          openDeleteDialog: false,
          openEditPaymentDialog: false,
          id_payment_delete: "",
        });
        this.getPayments();
        store.addNotification({
          title: this.props.languagePack["fixtures_schedule"],
          message: this.props.languagePack["payment_successfully_deleted"],
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      });
  };

  savePlayerMonthlyTax = () => {
    axios
      .post("payment/update_player_monthly_tax", {
        id_player: this.state.editingRow.id,
        monthly_tax: this.state.player_monthly_tax,
        tax_reason: this.state.tax_reason,
        first_month_year_for_payment: this.state
          .player_first_month_year_for_payment,
      })
      .then((res) => {
        if (res.data.success) {
          this.closeEditPaymentPopup();
          this.getPayments();
          store.addNotification({
            title: this.props.languagePack["payments_title"],
            message: "Taxa lunara a fost salvata cu success!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: this.props.languagePack["payments_title"],
            message: "Taxa lunara nu a fost salvata cu success!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      });
  };

  handleDateChange = (value) => {
    this.setState({
      date_of_payment: value,
    });
  };

  handleChangeFirstMonthYearForPayment = (value) => {
    this.setState({
      player_first_month_year_for_payment: value,
    });
  };

  handleChangeInput = (e) => {
    this.setState({
      description: e.target.value,
    });
  };

  setPaymentsToDelete = () => {
    let elem = this.state.data_brut.find((obj) => {
      return obj.id_player == this.state.editingRow.id;
    });
    elem.payments.sort(
      (a, b) => parseInt(a.payment_month) - parseInt(b.payment_month)
    );
    this.setState({
      payments_to_delete: elem.payments,
    });
  };

  handleChangeCheckbox = () => {
    const { languagePack } = this.props;
    if (!this.state.generate_receipt) {
      this.setState({
        generate_receipt: !this.state.generate_receipt,
        receipt_name: `Taxă luna ${
          languagePack.table_months[
            this.state.months[this.state.selected_month?.value - 1].id
          ]
        } - ${new Date().getFullYear()}`,
      });
    } else {
      this.setState({
        generate_receipt: !this.state.generate_receipt,
        receipt_name: "",
        receipt_payer: "",
      });
    }
  };

  render() {
    const { languagePack } = this.props;

    let paymentTypes = [
      { value: "cash", label: languagePack.add_payment_type["cash"] },
      {
        value: "bank_transfer",
        label: languagePack.add_payment_type["bank_transfer"],
      },
    ];

    if (this.state.redirectToTeams) {
      return <Redirect to="/club" />;
    }

    const addButtonDisabled = !(
      this.state.selected_month != null &&
      !this.state.sum == "" &&
      this.state.date_of_payment !== null
    );
    const columnsExpandedCashflow = [
      {
        name: languagePack["table_name"],
        selector: "name",
        grow: window.innerWidth > 768 ? 2 : 1,
        compact: window.innerWidth > 768 ? false : true,
        conditionalCellStyles: [
          {
            when: (row) => row.inactive_player == "1",
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "black",
              filter: "opacity(0.2)",
            },
          },
        ],
        cell: (row) => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>{row.name}</div>

                {row.tax_reason && row.tax_reason != "" && (
                  <div
                    className="ds-mini-text-bold"
                    style={{ marginBottom: "5px", color: "red" }}
                  >
                    {row.tax_reason}
                  </div>
                )}
              </div>
            </>
          );
        },
      },
      {
        name: "",
        selector: "add",
        sortable: false,
        center: true,
        compact: window.innerWidth > 768 ? false : true,
        cell: (row) => {
          return (
            row.inactive_player == 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    this.setState({
                      editingRow: row,
                      openAddPaymentDialog: true,
                    });
                  }}
                >
                  <TFBIcon name="add" color="green" />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    this.setState(
                      {
                        editingRow: row,
                        openEditPaymentDialog: true,
                        player_monthly_tax: row.monthly_tax ?? "",
                        player_first_month_year_for_payment:
                          row.first_month_year_for_payment ?? null,
                      },
                      () => this.setPaymentsToDelete()
                    );
                  }}
                >
                  <TFBIcon name="edit" color="black" />
                </div>
              </div>
            )
          );
        },
        conditionalCellStyles: [
          {
            when: (row) => row.inactive_player == "1",
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
              filter: "opacity(0.3)",
            },
          },
        ],
      },
      {
        center: true,
        name: "Taxa lunara",
        selector: "monthly_tax",
        compact: window.innerWidth > 768 ? false : true,
        cell: (row) => row.monthly_tax,
        conditionalCellStyles: [
          {
            when: (row) => row.inactive_player == "1",
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
              filter: "opacity(0.3)",
            },
          },
        ],
      },
      {
        center: true,
        name: languagePack.table_months["jan"],
        selector: "january",
        _hasEditableCell: true,
        compact: window.innerWidth > 768 ? false : true,
        cell: (row) => {
          if (
            row.first_month_year_for_payment != null
              ? new Date(this.state.selectedYear + "-01-01") <
                new Date(row.first_month_year_for_payment).setDate(1)
              : false
          )
            return "-";
          else if (
            this.state.selectedYear < new Date().getFullYear() &&
            !row.hasOwnProperty("january")
          )
            return 0;
          else if (
            !row.hasOwnProperty("january") &&
            new Date().getMonth() + 1 >= 1 &&
            this.state.selectedYear == new Date().getFullYear()
          )
            return 0;
          else
            return (
              <>
                {row.january ? (
                  <div className="payment-value-type-wrapper">
                    <div>{row.january}</div>
                    <div className="payment-text">
                      {paymentType[row.january_payment_type]}
                    </div>
                  </div>
                ) : (
                  row.january
                )}
              </>
            );
        },
        conditionalCellStyles: [
          {
            when: (row) =>
              this.state.selectedYear == new Date().getFullYear()
                ? new Date().getMonth() + 1 > 1 &&
                  !row.hasOwnProperty("january")
                : this.state.selectedYear < new Date().getFullYear(),
            style: {
              backgroundColor: "rgba(242, 38, 19, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.january > 0,
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.inactive_player == "1",
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
              filter: "opacity(0.3)",
            },
          },
          {
            when: (row) => row.inactive_player != "1" && row.monthly_tax == 0,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
          {
            when: (row) =>
              row.first_month_year_for_payment != null
                ? new Date(this.state.selectedYear + "-01-01") <
                  new Date(row.first_month_year_for_payment).setDate(1)
                : false,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
        ],
      },
      {
        center: true,
        name: languagePack.table_months["feb"],
        selector: "february",
        _hasEditableCell: true,
        compact: window.innerWidth > 768 ? false : true,
        cell: (row) => {
          if (
            row.first_month_year_for_payment != null
              ? new Date(this.state.selectedYear + "-02-01") <
                new Date(row.first_month_year_for_payment).setDate(1)
              : false
          )
            return "-";
          else if (
            this.state.selectedYear < new Date().getFullYear() &&
            !row.hasOwnProperty("february")
          )
            return 0;
          else if (
            !row.hasOwnProperty("february") &&
            new Date().getMonth() + 1 >= 2 &&
            this.state.selectedYear == new Date().getFullYear()
          )
            return 0;
          else
            return (
              <>
                {row.february ? (
                  <div className="payment-value-type-wrapper">
                    <div>{row.february}</div>
                    <div className="payment-text">
                      {paymentType[row.february_payment_type]}
                    </div>
                  </div>
                ) : (
                  row.february
                )}
              </>
            );
        },
        conditionalCellStyles: [
          {
            when: (row) =>
              this.state.selectedYear == new Date().getFullYear()
                ? new Date().getMonth() + 1 > 2 &&
                  !row.hasOwnProperty("february")
                : this.state.selectedYear < new Date().getFullYear(),
            style: {
              backgroundColor: "rgba(242, 38, 19, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.february > 0,
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.inactive_player == "1",
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
              filter: "opacity(0.3)",
            },
          },
          {
            when: (row) => row.inactive_player != "1" && row.monthly_tax == 0,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
          {
            when: (row) =>
              row.first_month_year_for_payment != null
                ? new Date(this.state.selectedYear + "-02-01") <
                  new Date(row.first_month_year_for_payment).setDate(1)
                : false,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
        ],
      },
      {
        center: true,
        name: languagePack.table_months["mar"],
        selector: "march",
        _hasEditableCell: true,
        compact: window.innerWidth > 768 ? false : true,
        cell: (row) => {
          if (
            row.first_month_year_for_payment != null
              ? new Date(this.state.selectedYear + "-03-01") <
                new Date(row.first_month_year_for_payment).setDate(1)
              : false
          )
            return "-";
          else if (
            this.state.selectedYear < new Date().getFullYear() &&
            !row.hasOwnProperty("march")
          )
            return 0;
          else if (
            !row.hasOwnProperty("march") &&
            new Date().getMonth() + 1 >= 3 &&
            this.state.selectedYear == new Date().getFullYear()
          )
            return 0;
          else
            return (
              <>
                {row.march ? (
                  <div className="payment-value-type-wrapper">
                    <div>{row.march}</div>
                    <div className="payment-text">
                      {paymentType[row.march_payment_type]}
                    </div>
                  </div>
                ) : (
                  row.march
                )}
              </>
            );
        },
        conditionalCellStyles: [
          {
            when: (row) =>
              this.state.selectedYear == new Date().getFullYear()
                ? new Date().getMonth() + 1 > 3 && !row.hasOwnProperty("march")
                : this.state.selectedYear < new Date().getFullYear(),
            style: {
              backgroundColor: "rgba(242, 38, 19, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.march > 0,
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.inactive_player == "1",
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
              filter: "opacity(0.3)",
            },
          },
          {
            when: (row) => row.inactive_player != "1" && row.monthly_tax == 0,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
          {
            when: (row) =>
              row.first_month_year_for_payment != null
                ? new Date(this.state.selectedYear + "-03-01") <
                  new Date(row.first_month_year_for_payment).setDate(1)
                : false,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
        ],
      },
      {
        center: true,
        name: languagePack.table_months["apr"],
        selector: "april",
        _hasEditableCell: true,
        compact: window.innerWidth > 768 ? false : true,
        cell: (row) => {
          if (
            row.first_month_year_for_payment != null
              ? new Date(this.state.selectedYear + "-04-01") <
                new Date(row.first_month_year_for_payment).setDate(1)
              : false
          )
            return "-";
          else if (
            this.state.selectedYear < new Date().getFullYear() &&
            !row.hasOwnProperty("april")
          )
            return 0;
          else if (
            !row.hasOwnProperty("april") &&
            new Date().getMonth() + 1 >= 4 &&
            this.state.selectedYear == new Date().getFullYear()
          )
            return 0;
          else
            return (
              <>
                {row.april ? (
                  <div className="payment-value-type-wrapper">
                    <div>{row.april}</div>
                    <div className="payment-text">
                      {paymentType[row.april_payment_type]}
                    </div>
                  </div>
                ) : (
                  row.april
                )}
              </>
            );
        },
        conditionalCellStyles: [
          {
            when: (row) =>
              this.state.selectedYear == new Date().getFullYear()
                ? new Date().getMonth() + 1 > 4 && !row.hasOwnProperty("april")
                : this.state.selectedYear < new Date().getFullYear(),
            style: {
              backgroundColor: "rgba(242, 38, 19, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.april > 0,
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.inactive_player == "1",
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
              filter: "opacity(0.3)",
            },
          },
          {
            when: (row) => row.inactive_player != "1" && row.monthly_tax == 0,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
          {
            when: (row) =>
              row.first_month_year_for_payment != null
                ? new Date(this.state.selectedYear + "-04-01") <
                  new Date(row.first_month_year_for_payment).setDate(1)
                : false,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
        ],
      },
      {
        center: true,
        name: languagePack.table_months["may"],
        selector: "may",
        _hasEditableCell: true,
        compact: window.innerWidth > 768 ? false : true,
        cell: (row) => {
          if (
            row.first_month_year_for_payment != null
              ? new Date(this.state.selectedYear + "-05-01") <
                new Date(row.first_month_year_for_payment).setDate(1)
              : false
          )
            return "-";
          else if (
            this.state.selectedYear < new Date().getFullYear() &&
            !row.hasOwnProperty("may")
          )
            return 0;
          else if (
            !row.hasOwnProperty("may") &&
            new Date().getMonth() + 1 >= 5 &&
            this.state.selectedYear == new Date().getFullYear()
          )
            return 0;
          else
            return (
              <>
                {row.may ? (
                  <div className="payment-value-type-wrapper">
                    <div>{row.may}</div>
                    <div className="payment-text">
                      {paymentType[row.may_payment_type]}
                    </div>
                  </div>
                ) : (
                  row.may
                )}
              </>
            );
        },
        conditionalCellStyles: [
          {
            when: (row) =>
              this.state.selectedYear == new Date().getFullYear()
                ? new Date().getMonth() + 1 > 5 && !row.hasOwnProperty("may")
                : this.state.selectedYear < new Date().getFullYear(),
            style: {
              backgroundColor: "rgba(242, 38, 19, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.may > 0,
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.inactive_player == "1",
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
              filter: "opacity(0.3)",
            },
          },
          {
            when: (row) => row.inactive_player != "1" && row.monthly_tax == 0,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
          {
            when: (row) =>
              row.first_month_year_for_payment != null
                ? new Date(this.state.selectedYear + "-05-01") <
                  new Date(row.first_month_year_for_payment).setDate(1)
                : false,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
        ],
      },
      {
        center: true,
        name: languagePack.table_months["jun"],
        selector: "june",
        _hasEditableCell: true,
        compact: window.innerWidth > 768 ? false : true,
        cell: (row) => {
          if (
            row.first_month_year_for_payment != null
              ? new Date(this.state.selectedYear + "-06-01") <
                new Date(row.first_month_year_for_payment).setDate(1)
              : false
          )
            return "-";
          else if (
            this.state.selectedYear < new Date().getFullYear() &&
            !row.hasOwnProperty("june")
          )
            return 0;
          else if (
            !row.hasOwnProperty("june") &&
            new Date().getMonth() + 1 >= 6 &&
            this.state.selectedYear == new Date().getFullYear()
          )
            return 0;
          else
            return (
              <>
                {row.june ? (
                  <div className="payment-value-type-wrapper">
                    <div>{row.june}</div>
                    <div className="payment-text">
                      {paymentType[row.june_payment_type]}
                    </div>
                  </div>
                ) : (
                  row.june
                )}
              </>
            );
        },
        conditionalCellStyles: [
          {
            when: (row) =>
              this.state.selectedYear == new Date().getFullYear()
                ? new Date().getMonth() + 1 > 6 && !row.hasOwnProperty("june")
                : this.state.selectedYear < new Date().getFullYear(),
            style: {
              backgroundColor: "rgba(242, 38, 19, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.june > 0,
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.inactive_player == "1",
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
              filter: "opacity(0.3)",
            },
          },
          {
            when: (row) => row.inactive_player != "1" && row.monthly_tax == 0,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
          {
            when: (row) =>
              row.first_month_year_for_payment != null
                ? new Date(this.state.selectedYear + "-06-01") <
                  new Date(row.first_month_year_for_payment).setDate(1)
                : false,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
        ],
      },
      {
        center: true,
        name: languagePack.table_months["jul"],
        selector: "july",
        _hasEditableCell: true,
        compact: window.innerWidth > 768 ? false : true,
        cell: (row) => {
          if (
            row.first_month_year_for_payment != null
              ? new Date(this.state.selectedYear + "-07-01") <
                new Date(row.first_month_year_for_payment).setDate(1)
              : false
          )
            return "-";
          else if (
            this.state.selectedYear < new Date().getFullYear() &&
            !row.hasOwnProperty("july")
          )
            return 0;
          else if (
            !row.hasOwnProperty("july") &&
            new Date().getMonth() + 1 >= 7 &&
            this.state.selectedYear == new Date().getFullYear()
          )
            return 0;
          else
            return (
              <>
                {row.july ? (
                  <div className="payment-value-type-wrapper">
                    <div>{row.july}</div>
                    <div className="payment-text">
                      {paymentType[row.july_payment_type]}
                    </div>
                  </div>
                ) : (
                  row.july
                )}
              </>
            );
        },
        conditionalCellStyles: [
          {
            when: (row) =>
              this.state.selectedYear == new Date().getFullYear()
                ? new Date().getMonth() + 1 > 7 && !row.hasOwnProperty("july")
                : this.state.selectedYear < new Date().getFullYear(),
            style: {
              backgroundColor: "rgba(242, 38, 19, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.july > 0,
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.inactive_player == "1",
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
              filter: "opacity(0.3)",
            },
          },
          {
            when: (row) => row.inactive_player != "1" && row.monthly_tax == 0,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
          {
            when: (row) =>
              row.first_month_year_for_payment != null
                ? new Date(this.state.selectedYear + "-07-01") <
                  new Date(row.first_month_year_for_payment).setDate(1)
                : false,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
        ],
      },
      {
        center: true,
        name: languagePack.table_months["aug"],
        selector: "august",
        _hasEditableCell: true,
        compact: window.innerWidth > 768 ? false : true,
        cell: (row) => {
          if (
            row.first_month_year_for_payment != null
              ? new Date(this.state.selectedYear + "-08-01") <
                new Date(row.first_month_year_for_payment).setDate(1)
              : false
          )
            return "-";
          else if (
            this.state.selectedYear < new Date().getFullYear() &&
            !row.hasOwnProperty("august")
          )
            return 0;
          else if (
            !row.hasOwnProperty("august") &&
            new Date().getMonth() + 1 >= 8 &&
            this.state.selectedYear == new Date().getFullYear()
          )
            return 0;
          else
            return (
              <>
                {row.august ? (
                  <div className="payment-value-type-wrapper">
                    <div>{row.august}</div>
                    <div className="payment-text">
                      {paymentType[row.august_type_label]}
                    </div>
                  </div>
                ) : (
                  row.august
                )}
              </>
            );
        },
        conditionalCellStyles: [
          {
            when: (row) =>
              this.state.selectedYear == new Date().getFullYear()
                ? new Date().getMonth() + 1 > 8 && !row.hasOwnProperty("august")
                : this.state.selectedYear < new Date().getFullYear(),
            style: {
              backgroundColor: "rgba(242, 38, 19, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.august > 0,
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.inactive_player == "1",
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
              filter: "opacity(0.3)",
            },
          },
          {
            when: (row) => row.inactive_player != "1" && row.monthly_tax == 0,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
          {
            when: (row) =>
              row.first_month_year_for_payment != null
                ? new Date(this.state.selectedYear + "-08-01") <
                  new Date(row.first_month_year_for_payment).setDate(1)
                : false,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
        ],
      },
      {
        center: true,
        name: languagePack.table_months["sep"],
        selector: "september",
        _hasEditableCell: true,
        compact: window.innerWidth > 768 ? false : true,
        cell: (row) => {
          if (
            row.first_month_year_for_payment != null
              ? new Date(this.state.selectedYear + "-09-01") <
                new Date(row.first_month_year_for_payment).setDate(1)
              : false
          )
            return "-";
          else if (
            this.state.selectedYear < new Date().getFullYear() &&
            !row.hasOwnProperty("september")
          )
            return 0;
          else if (
            !row.hasOwnProperty("september") &&
            new Date().getMonth() + 1 >= 9 &&
            this.state.selectedYear == new Date().getFullYear()
          )
            return 0;
          else
            return (
              <>
                {row.september ? (
                  <div className="payment-value-type-wrapper">
                    <div>{row.september}</div>
                    <div className="payment-text">
                      {paymentType[row.september_payment_type]}
                    </div>
                  </div>
                ) : (
                  row.september
                )}
              </>
            );
        },
        conditionalCellStyles: [
          {
            when: (row) =>
              this.state.selectedYear == new Date().getFullYear()
                ? new Date().getMonth() + 1 > 9 &&
                  !row.hasOwnProperty("september")
                : this.state.selectedYear < new Date().getFullYear(),
            style: {
              backgroundColor: "rgba(242, 38, 19, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.september > 0,
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.inactive_player == "1",
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
              filter: "opacity(0.3)",
            },
          },
          {
            when: (row) => row.inactive_player != "1" && row.monthly_tax == 0,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
          {
            when: (row) =>
              row.first_month_year_for_payment != null
                ? new Date(this.state.selectedYear + "-09-01") <
                  new Date(row.first_month_year_for_payment).setDate(1)
                : false,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
        ],
      },
      {
        center: true,
        name: languagePack.table_months["oct"],
        selector: "october",
        _hasEditableCell: true,
        compact: window.innerWidth > 768 ? false : true,
        cell: (row) => {
          if (
            row.first_month_year_for_payment != null
              ? new Date(this.state.selectedYear + "-10-01") <
                new Date(row.first_month_year_for_payment).setDate(1)
              : false
          )
            return "-";
          else if (
            this.state.selectedYear < new Date().getFullYear() &&
            !row.hasOwnProperty("october")
          )
            return 0;
          else if (
            !row.hasOwnProperty("october") &&
            new Date().getMonth() + 1 >= 10 &&
            this.state.selectedYear == new Date().getFullYear()
          )
            return 0;
          else
            return (
              <>
                {row.october ? (
                  <div className="payment-value-type-wrapper">
                    <div>{row.october}</div>
                    <div className="payment-text">
                      {paymentType[row.october_payment_type]}
                    </div>
                  </div>
                ) : (
                  row.october
                )}
              </>
            );
        },
        conditionalCellStyles: [
          {
            when: (row) =>
              this.state.selectedYear == new Date().getFullYear()
                ? new Date().getMonth() + 1 > 10 &&
                  !row.hasOwnProperty("october")
                : this.state.selectedYear < new Date().getFullYear(),
            style: {
              backgroundColor: "rgba(242, 38, 19, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.october > 0,
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.inactive_player == "1",
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
              filter: "opacity(0.3)",
            },
          },
          {
            when: (row) => row.inactive_player != "1" && row.monthly_tax == 0,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
          {
            when: (row) =>
              row.first_month_year_for_payment != null
                ? new Date(this.state.selectedYear + "-10-01") <
                  new Date(row.first_month_year_for_payment).setDate(1)
                : false,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
        ],
      },
      {
        center: true,
        name: languagePack.table_months["nov"],
        selector: "november",
        _hasEditableCell: true,
        compact: window.innerWidth > 768 ? false : true,
        cell: (row) => {
          if (
            row.first_month_year_for_payment != null
              ? new Date(this.state.selectedYear + "-11-01") <
                new Date(row.first_month_year_for_payment).setDate(1)
              : false
          )
            return "-";
          else if (
            this.state.selectedYear < new Date().getFullYear() &&
            !row.hasOwnProperty("november")
          )
            return 0;
          else if (
            !row.hasOwnProperty("november") &&
            new Date().getMonth() + 1 >= 11 &&
            this.state.selectedYear == new Date().getFullYear()
          )
            return 0;
          else
            return (
              <>
                {row.november ? (
                  <div className="payment-value-type-wrapper">
                    <div>{row.november}</div>
                    <div className="payment-text">
                      {paymentType[row.november_payment_type]}
                    </div>
                  </div>
                ) : (
                  row.november
                )}
              </>
            );
        },
        conditionalCellStyles: [
          {
            when: (row) =>
              this.state.selectedYear == new Date().getFullYear()
                ? new Date().getMonth() + 1 > 11 &&
                  !row.hasOwnProperty("november")
                : this.state.selectedYear < new Date().getFullYear(),
            style: {
              backgroundColor: "rgba(242, 38, 19, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.november > 0,
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.inactive_player == "1",
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
              filter: "opacity(0.3)",
            },
          },
          {
            when: (row) => row.inactive_player != "1" && row.monthly_tax == 0,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
          {
            when: (row) =>
              row.first_month_year_for_payment != null
                ? new Date(this.state.selectedYear + "-11-01") <
                  new Date(row.first_month_year_for_payment).setDate(1)
                : false,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
        ],
      },
      {
        center: true,
        name: languagePack.table_months["dec"],
        selector: "december",
        _hasEditableCell: true,
        compact: window.innerWidth > 768 ? false : true,
        cell: (row) => {
          if (
            row.first_month_year_for_payment != null
              ? new Date(this.state.selectedYear + "-12-01") <
                new Date(row.first_month_year_for_payment).setDate(1)
              : false
          )
            return "-";
          else if (
            this.state.selectedYear < new Date().getFullYear() &&
            !row.hasOwnProperty("december")
          )
            return 0;
          else if (
            !row.hasOwnProperty("december") &&
            new Date().getMonth() + 1 >= 12 &&
            this.state.selectedYear == new Date().getFullYear()
          )
            return 0;
          else
            return (
              <>
                {row.december ? (
                  <div className="payment-value-type-wrapper">
                    <div>{row.december}</div>
                    <div className="payment-text">
                      {paymentType[row.december_payment_type]}
                    </div>
                  </div>
                ) : (
                  row.december
                )}
              </>
            );
        },
        conditionalCellStyles: [
          {
            when: (row) =>
              this.state.selectedYear == new Date().getFullYear()
                ? new Date().getMonth() + 1 > 12 &&
                  !row.hasOwnProperty("december")
                : this.state.selectedYear < new Date().getFullYear(),
            style: {
              backgroundColor: "rgba(242, 38, 19, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.december > 0,
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
            },
          },
          {
            when: (row) => row.inactive_player == "1",
            style: {
              backgroundColor: "rgba(63, 195, 128, 0.9)",
              color: "white",
              filter: "opacity(0.3)",
            },
          },
          {
            when: (row) => row.inactive_player != "1" && row.monthly_tax == 0,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
          {
            when: (row) =>
              row.first_month_year_for_payment != null
                ? new Date(this.state.selectedYear + "-12-01") <
                  new Date(row.first_month_year_for_payment).setDate(1)
                : false,
            style: {
              backgroundColor: "white",
              color: "black",
            },
          },
        ],
      },
    ];

    return (
      <>
        {this.state.hasAccessToTeam && (
          <TFBCard>
            <TFBCardHeader title={languagePack["payments_title"]}>
              <div className="team-payments-navigate-year-container">
                <TFBIconButton
                  name="arrow-left"
                  color="green"
                  onClick={() => this.onChangeYear(-1)}
                  disabled={
                    !(this.state.selectedYear > new Date().getFullYear() - 2)
                  }
                />
                <div className="year-text">{this.state.selectedYear}</div>
                <TFBIconButton
                  name="arrow-right"
                  color="green"
                  onClick={() => this.onChangeYear(1)}
                  disabled={
                    !(this.state.selectedYear < new Date().getFullYear() + 2)
                  }
                />
              </div>
            </TFBCardHeader>
            <DataTable
              noHeader
              center
              columns={columnsExpandedCashflow}
              data={this.state.data}
              highlightOnHover
              customStyles={customStyles}
              progressPending={this.state.isFetching}
              progressComponent={<CircularLoader />}
            />

            {/* ----------------- ADD PAYMENT ----------------*/}
            <Dialog
              open={this.state.openAddPaymentDialog}
              onClose={this.closeAddPaymentPopup}
            >
              <DialogTitle id="form-dialog-title">
                <div className="add-payment-header">
                  <div className="add-payment-title">
                    {languagePack["add_payment_title"] + " "}
                    <span style={{ color: "var(--primaryColor)" }}>
                      {this.state.editingRow.name}
                    </span>
                  </div>
                  <Icon
                    name="close"
                    className="close-icon-popup"
                    style={{ margin: "auto" }}
                    onClick={() => {
                      this.setState({
                        receipt_payer: "",
                        receipt_name: "",
                        generate_receipt: false,
                        add_receipt_checkbox: false,
                      });
                      this.closeAddPaymentPopup();
                    }}
                  />
                </div>
              </DialogTitle>
              <DialogContent>
                <div className="add-payment-row">
                  <TFBSelect
                    placeholder={languagePack["add_payment_select_month"]}
                    value={this.state.selected_month}
                    options={this.state.months.map((item) => ({
                      value: item.value,
                      label: languagePack.table_months[item.id],
                    }))}
                    onChange={(selected) =>
                      this.setState({
                        selected_month: selected,
                        monthIsSelected: true,
                      })
                    }
                  />
                </div>
                {this.state.monthIsSelected && (
                  <>
                    <div className="add-payment-row">
                      <input
                        className="input-text"
                        type="number"
                        value={this.state.sum}
                        onChange={(e) => {
                          this.setState({
                            sum: e.target.value,
                          });
                        }}
                        placeholder={languagePack["add_payment_amount"]}
                      />
                    </div>
                    <div className="add-payment-row">
                      <TFBSelect
                        placeholder="Selecteaza tip plată"
                        options={paymentTypes}
                        value={this.state.payment_type}
                        onChange={(selected) => {
                          if (selected?.value == "cash") {
                            this.setState({
                              payment_type: selected,
                              add_receipt_checkbox: true,
                            });
                          } else {
                            this.setState({
                              payment_type: selected,
                              add_receipt_checkbox: false,
                            });
                          }
                        }}
                      />
                    </div>
                    {this.state.add_receipt_checkbox && (
                      <div className="add-payment-row">
                        <Checkbox
                          label={"Generează chitanță"}
                          className="action-checkbox"
                          onChange={this.handleChangeCheckbox}
                          checked={this.state.generate_receipt}
                          style={{ display: "flex" }}
                        />
                      </div>
                    )}

                    {this.state.generate_receipt && (
                      <>
                        <div className="add-payment-row">
                          <input
                            className="input-text"
                            type="text"
                            defaultValue={`Taxă luna ${
                              languagePack.table_months[
                                this.state.months[
                                  this.state.selected_month?.value - 1
                                ].id
                              ]
                            } - ${new Date().getFullYear()}`}
                            onChange={(e) => {
                              this.setState({
                                receipt_name: e.target.value,
                              });
                            }}
                            placeholder={"Nume taxă"}
                          />
                        </div>
                        <div className="add-payment-row">
                          <input
                            className="input-text"
                            type="text"
                            value={this.state.receipt_payer}
                            onChange={(e) => {
                              this.setState({
                                receipt_payer: e.target.value,
                              });
                            }}
                            placeholder={"Nume plătitor"}
                          />
                        </div>

                        <div className="add-payment-row">
                          <Input
                            onKeyPress={(event) => {
                              if (!/\b[a-zA-Z]\b/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            placeholder="Seria"
                            className="add-payment-receipt-serial"
                            name="receipt_serial"
                            onChange={(e) => {
                              this.changeReceiptSerial(e.target.value);
                            }}
                            value={this.state.receipt_serial ?? ""}
                          />
                        </div>
                        <div className="add-payment-row">
                          <input
                            className="input-text"
                            type="number"
                            value={this.state.nr_receipt}
                            onChange={(e) => {
                              this.setState({
                                nr_receipt: e.target.value,
                              });
                            }}
                            placeholder={"Numar chitanta"}
                          />
                        </div>
                      </>
                    )}
                    <div className="add-payment-row">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="add-payment-row">
                          <DatePicker
                            value={this.state.date_of_payment}
                            onChange={this.handleDateChange}
                            ampm={false}
                            format="dd MMMM yyyy"
                            name="payment_date"
                            inputVariant="outlined"
                            className="date-input"
                            emptyLabel={languagePack["add_payment_date"]}
                          />
                          <Icon
                            name="calendar alternate outline"
                            className="calendar-icon"
                            size="small"
                          />
                        </div>
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="add-payment-row">
                      <TextArea
                        placeholder={languagePack["add_payment_description"]}
                        name="newEventDescription"
                        value={this.state.description}
                        onChange={this.handleChangeInput}
                        className="text-area-add-event"
                      />
                    </div>
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <TFBButton
                  color="darkGray"
                  onClick={() => {
                    this.setState({
                      receipt_payer: "",
                      receipt_name: "",
                      generate_receipt: false,
                      add_receipt_checkbox: false,
                    });
                    this.closeAddPaymentPopup();
                  }}
                  renderIcon={() => <TFBIcon name="cancel" />}
                >
                  {languagePack["add_payment_button_cancel"]}
                </TFBButton>
                <TFBButton
                  color="green"
                  onClick={() => {
                    if (this.checkAddPayment()) this.addPayment();
                  }}
                  disabled={
                    this.state.generate_receipt
                      ? addButtonDisabled ||
                        this.state.payment_type == null ||
                        this.state.nr_receipt == "" ||
                        this.state.receipt_payer == "" ||
                        this.state.receipt_name == ""
                      : addButtonDisabled || this.state.payment_type == null
                  }
                  renderIcon={() => <TFBIcon name="add" />}
                >
                  {languagePack["add_payment_button_add"]}
                </TFBButton>
              </DialogActions>
            </Dialog>

            {/* ----------------- EDIT PAYMENTS ----------------*/}
            <Dialog
              open={this.state.openEditPaymentDialog}
              onClose={this.closeEditPaymentPopup}
            >
              <DialogTitle id="form-dialog-title">
                <div className="add-payment-header">
                  <div className="add-payment-title">
                    {languagePack["edit_payments_title"] + " "}
                    <span style={{ color: "var(--primaryColor)" }}>
                      {this.state.editingRow.name}
                    </span>
                  </div>
                  <Icon
                    name="close"
                    className="close-icon-popup"
                    style={{ margin: "auto" }}
                    onClick={this.closeEditPaymentPopup}
                  />
                </div>
              </DialogTitle>
              <DialogContent>
                <div style={{ paddingBottom: "16px" }}>
                  <div className="add-payment-row">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className="add-payment-row">
                        <DatePicker
                          value={this.state.player_first_month_year_for_payment}
                          openTo="month"
                          views={["year", "month"]}
                          emptyLabel="Luna si anul primei cotizatii"
                          onChange={this.handleChangeFirstMonthYearForPayment}
                          inputVariant="outlined"
                          className="date-input"
                        />
                        <Icon
                          name="calendar alternate outline"
                          className="calendar-icon"
                          size="small"
                        />
                      </div>
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="add-payment-row">
                    <input
                      className="input-text"
                      type="number"
                      value={this.state.player_monthly_tax}
                      onChange={(e) => {
                        this.setState({
                          player_monthly_tax: e.target.value,
                        });
                      }}
                      placeholder={"Taxa lunara"}
                    />
                  </div>
                  <div className="add-payment-row">
                    <input
                      className="input-text"
                      type="text"
                      value={this.state.editingRow.tax_reason}
                      onChange={(e) => {
                        this.setState({
                          tax_reason: e.target.value,
                          editingRow: {
                            ...this.state.editingRow,
                            tax_reason: e.target.value,
                          },
                        });
                      }}
                      placeholder={"Motivare taxă"}
                    />
                  </div>
                  {this.state.payments_to_delete.length == 0 ? (
                    <div className="edit-payments-pop-up-payments-list">
                      <TFBPlaceholder
                        text={languagePack["edit_payments_empty"]}
                      />
                    </div>
                  ) : (
                    <div className="edit-payments-pop-up-payments-list">
                      <div className="title">{"Lista plati lunare"}</div>
                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TFBTableCell sx={sxStickyColumn}>
                                {"Luna"}
                              </TFBTableCell>
                              <TFBTableCell>{"Suma"}</TFBTableCell>
                              <TFBTableCell>{"Tip plata"}</TFBTableCell>
                              <TFBTableCell align="center">
                                {"Actiuni"}
                              </TFBTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.payments_to_delete.map(
                              (payment, index) => (
                                <TableRow
                                  key={"player-payment-" + index}
                                  sx={{
                                    backgroundColor:
                                      index % 2 == 0
                                        ? "var(--lineBackgroundGray)"
                                        : "white",
                                  }}
                                >
                                  <TFBTableCell
                                    component="th"
                                    scope="row"
                                    sx={[
                                      sxStickyColumn,
                                      {
                                        backgroundColor:
                                          index % 2 == 0
                                            ? "var(--lineBackgroundGray)"
                                            : "white",
                                      },
                                    ]}
                                  >
                                    {
                                      languagePack.table_months[
                                        this.state.months[
                                          payment.payment_month - 1
                                        ].id
                                      ]
                                    }
                                  </TFBTableCell>
                                  <TFBTableCell>
                                    {payment.payment_amount + " RON"}
                                  </TFBTableCell>
                                  <TFBTableCell>
                                    {paymentType[payment.payment_type_label]}
                                  </TFBTableCell>
                                  <TFBTableCell>
                                    <div className="actions-btns-container">
                                      {![
                                        "parents_app",
                                        "online_website",
                                      ].includes(
                                        payment.payment_type_label
                                      ) && (
                                        <TFBIconButton
                                          name="delete"
                                          color="darkGray"
                                          onClick={() => {
                                            this.setState({
                                              openDeleteDialog: true,
                                              id_payment_delete:
                                                payment.id_payment,
                                            });
                                          }}
                                        />
                                      )}
                                    </div>
                                  </TFBTableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <TFBButton
                  color="darkGray"
                  onClick={this.closeEditPaymentPopup}
                  renderIcon={() => <TFBIcon name="cancel" />}
                >
                  {languagePack["add_payment_button_cancel"]}
                </TFBButton>
                <TFBButton
                  color="green"
                  onClick={this.savePlayerMonthlyTax}
                  disabled={this.state.player_monthly_tax == ""}
                  renderIcon={() => <TFBIcon name="check" />}
                >
                  {languagePack["admin_save"]}
                </TFBButton>
              </DialogActions>
            </Dialog>

            {/* ----------------- UPDATE/OVERWRITE PAYMENT ----------------*/}
            <Dialog
              open={this.state.openMessageDialog}
              onClose={this.closeMessageDialog}
            >
              <DialogContent>
                <div className="display-payment-row">
                  {languagePack["add_payment_overwrite_first"] +
                    this.state.selected_month?.label +
                    languagePack["add_payment_overwrite_second"] +
                    this.state.editingRow.name +
                    "?"}
                </div>
              </DialogContent>
              <DialogActions>
                <TFBButton
                  color="darkGray"
                  onClick={() => {
                    this.setState({
                      openMessageDialog: false,
                    });
                  }}
                  renderIcon={() => <TFBIcon name="cancel" />}
                >
                  {languagePack["add_payment_button_cancel"]}
                </TFBButton>
                <TFBButton
                  color="green"
                  onClick={this.addPayment}
                  style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                  renderIcon={() => <TFBIcon name="check" />}
                >
                  {languagePack["add_payment_button_overwrite"]}
                </TFBButton>
              </DialogActions>
            </Dialog>

            {/* ----------------- DELETE PAYMENT ----------------*/}
            <Dialog
              open={this.state.openDeleteDialog}
              onClose={this.closeDeleteDialog}
            >
              <DialogContent>
                <div className="display-payment-row">
                  {languagePack["delete_payment_question"]}
                </div>
              </DialogContent>
              <DialogActions>
                <TFBButton
                  color="darkGray"
                  onClick={() => {
                    this.setState({
                      openDeleteDialog: false,
                      id_payment_delete: "",
                    });
                  }}
                  renderIcon={() => <TFBIcon name="cancel" />}
                >
                  {languagePack["add_payment_button_cancel"]}
                </TFBButton>
                <TFBButton
                  color="red"
                  onClick={this.deletePayment}
                  renderIcon={() => <TFBIcon name="delete" />}
                >
                  {languagePack["delete_payment_button_delete"]}
                </TFBButton>
              </DialogActions>
            </Dialog>
          </TFBCard>
        )}
      </>
    );
  }
}

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "rgb(250 250 250)",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  header: {
    style: {
      marginBottom: "20px",
    },
  },
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
};
const mapStateToProps = ({ layoutService: { languagePack }, auth }) => ({
  languagePack,
  token: auth?.user?.token,
  currentUser: auth.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(Payments);
