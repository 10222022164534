import React, { Component } from "react";

export default class Shop extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { languagePack } = this.props;
    return <div>Shop</div>;
  }
}
