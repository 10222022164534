import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import { TFBTableCell } from "../../../components/reusable/useStyles";
import "./EnrolmentRequests.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import DeleteRequest from "./DeleteRequest";
import EditRequest from "./EditRequest";
import AcceptRequest from "./AcceptRequest";

class EnrolmentRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requests: [],
      deleteRequestPopup: false,
      enrolmentDataToUpdate: "",
      enrolmentDataToAccept: "",
      editRequestPopup: false,
      id_request_to_update: "",
      id_request_to_delete: "",
      id_request_to_accept: "",
      acceptEnrolmentPopup: false,
    };
  }

  componentDidMount() {
    this.getRequests();
  }

  getRequests = () => {
    axios
      .get(
        `/club/get_enrolment_request_list?id_club=${this.props.currentUser.id_club}&id_user=${this.props.currentUser.id_user}&token=${this.props.currentUser.token}`
      )
      .then((res) => {
        this.setState({ requests: res.data.list });
      })
      .catch((err) => console.log(err));
  };

  cancelEnrolment = () =>
    this.setState({
      acceptEnrolmentPopup: false,
    });

  render() {
    const { languagePack } = this.props;

    const acceptedEnrolmentStyle = {
      width: "clamp(125px, 60%, 125px)",
      justifyContent: "flex-end",
      display: "flex",
    };

    return (
      <TFBCard>
        <TFBCardHeader title={languagePack.enrolment_request}></TFBCardHeader>

        {this.state.requests.length > 0 ? (
          <TFBCardBody>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                {/* ===============  TABLE COLUMNS NAMES ================================ */}
                <TableHead>
                  <TableRow>
                    <TFBTableCell>{languagePack.child}</TFBTableCell>
                    <TFBTableCell>{languagePack.request_date}</TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.birth_date}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.player_relative}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.tax_redirector_phone_number}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.Email}
                    </TFBTableCell>

                    <TFBTableCell align="center">
                      {languagePack.product_actions}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>

                {/* ===============  TABLE DATA ================================ */}
                <TableBody>
                  {this.state.requests.map((row, index) => (
                    <TableRow
                      key={"enrolment-requests" + index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      <TFBTableCell align="left">
                        {`${row.player_name} ${row.player_surname}`}
                      </TFBTableCell>
                      <TFBTableCell align="left">
                        {moment(row.date_add).format("DD.MM.YYYY")}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {moment(row.player_dob).format("DD.MM.YYYY")}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p
                            style={{ margin: 0 }}
                          >{`${row.parent_name} ${row.parent_surname}`}</p>
                          <p>{row.relationship.label}</p>
                        </div>
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.parent_phone_number}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.parent_email}
                      </TFBTableCell>

                      <TFBTableCell align="center">
                        <div className="actions-btns-container">
                          {row.approved == 0 && (
                            <>
                              <TFBIconButton
                                name="thumb-up"
                                color="darkGray"
                                onClick={() =>
                                  this.setState({
                                    acceptEnrolmentPopup: true,
                                    enrolmentDataToAccept: row,
                                    id_request_to_accept:
                                      row.id_enrolment_request,
                                  })
                                }
                              />
                              <TFBIconButton
                                name="edit"
                                color="darkGray"
                                onClick={() =>
                                  this.setState({
                                    editRequestPopup: true,
                                    id_request_to_update:
                                      row.id_enrolment_request,
                                    enrolmentDataToUpdate: row,
                                  })
                                }
                              />
                            </>
                          )}

                          <div
                            className="request-approved"
                            style={
                              row.approved == 1 ? acceptedEnrolmentStyle : {}
                            }
                          >
                            <TFBIconButton
                              name="delete"
                              color="darkGray"
                              onClick={() =>
                                this.setState({
                                  deleteRequestPopup: true,
                                  id_request_to_delete:
                                    row.id_enrolment_request,
                                })
                              }
                            />
                          </div>
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TFBCardBody>
        ) : (
          <TFBPlaceholder text={languagePack.no_requests} />
        )}

        {/* ==================  DELETE REQUEST POPUP ============================= */}
        <Dialog open={this.state.deleteRequestPopup} maxWidth="sm" fullWidth>
          <DialogContent>
            <DeleteRequest
              refreshRequests={this.getRequests}
              id_request_to_delete={this.state.id_request_to_delete}
              token={this.props.currentUser.token}
              id_club={this.props.currentUser.id_club}
              id_user={this.props.currentUser.id_user}
              closePopup={() =>
                this.setState({
                  deleteRequestPopup: false,
                  id_request_to_delete: "",
                })
              }
            />
          </DialogContent>
        </Dialog>

        {/* ==================  EDIT REQUEST POPUP ============================= */}
        <Dialog open={this.state.editRequestPopup} maxWidth="sm" fullWidth>
          <div className="user-management-edit-popup-header">
            <div className="user-management-edit-title">
              {languagePack.edit_request}
            </div>
            <div className="user-management-edit-close-icon">
              <TFBIconButton
                name="x-small"
                color="darkGray"
                onClick={() => {
                  this.getRequests();
                  this.setState({ editRequestPopup: false });
                }}
              />
            </div>
          </div>
          <DialogContent>
            <EditRequest
              id_club={this.props.currentUser.id_club}
              id_user={this.props.currentUser.id_user}
              enrolmentDataToUpdate={this.state.enrolmentDataToUpdate}
              refreshRequests={() => this.getRequests()}
              id_request_to_update={this.state.id_request_to_update}
              token={this.props.currentUser.token}
              languagePack={languagePack}
              closePopup={() =>
                this.setState({
                  editRequestPopup: false,
                  id_request_to_update: "",
                  enrolmentDataToUpdate: "",
                })
              }
            />
          </DialogContent>
        </Dialog>

        {/* ==================  ACCEPT REQUEST POPUP ============================= */}

        {this.state.acceptEnrolmentPopup && (
          <AcceptRequest
            id_club={this.props.currentUser.id_club}
            id_user={this.props.currentUser.id_user}
            token={this.props.currentUser.token}
            id_request_to_accept={this.state.id_request_to_accept}
            enrolmentDataToAccept={this.state.enrolmentDataToAccept}
            dialogOnClose={() =>
              this.setState({
                acceptEnrolmentPopup: false,
              })
            }
            refreshRequests={() => this.getRequests()}
            openDialog={this.state.acceptEnrolmentPopup}
            cancelEnrolment={() => this.cancelEnrolment}
          />
        )}
      </TFBCard>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(EnrolmentRequests);
