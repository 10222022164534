import React from "react";
import "./PrepareNextMatch.scss";
import axios from "axios";
import Moment from "react-moment";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";

class LastMatches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: [],
      param_options_dropdown: [],

      players: [],
      headers: [],
      selectedParameter: "",
      isLoading: false,
    };
  }

  componentDidMount = () => {
    this.getLastMatches();
    this.getLastMatchesTop();
  };

  getLastMatches = () => {
    axios
      .get(
        `rapidapi/get_last_matches?id_club=${this.props.currentUser.id_club}&last=3`
      )
      .then((res) => this.setState({ matches: res.data }));
  };

  getLastMatchesTop = () => {
    this.setState({ isLoading: true });
    axios
      .get(
        `instat/last_matches_players_top?id_club=${this.props.currentUser.id_club}&id_parameter=${this.state.selectedParameter}&lang=${this.props.lang}`
      )
      .then((res) =>
        this.setState({
          players: res.data.values,
          headers: res.data.header_names,
          param_options_dropdown: res.data.dropdown_parameters,
          isLoading: false,
        })
      )
      .catch((err) => console.log(err));
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value }, this.getLastMatchesTop);
  };

  render() {
    return (
      <div className="card card-custom">
        <div className="last-matches-container">
          <div className="last-matches-title">Ultimele 3 meciuri</div>
          <div className="last-matches-list">
            {this.state.matches.map((item) => (
              <div className="match-box">
                <div className="round-container">
                  {item.competition}, {item.match_round}
                </div>
                <div className="score-container">
                  <div className="logo-container">
                    <img src={item.team_home_logo} alt="home" />
                  </div>
                  <div className="score">
                    {item.goals_home}:{item.goals_away}
                  </div>
                  <div className="logo-container">
                    <img src={item.team_away_logo} alt="away" />
                  </div>
                </div>
                <div className="date-container">
                  <Moment format="DD.MM.YY, HH:mm">{item.match_date}</Moment>
                </div>

                <div
                  className="see-more-button"
                  onClick={() =>
                    this.props.history.push(`/club/match/${item.id_match}`)
                  }
                >
                  Vezi mai multe
                </div>
              </div>
            ))}
          </div>
          <div className="last-matches-header">
            <div className="last-matches-title" style={{ marginTop: 50 }}>
              Top jucatori in ultimele partide
            </div>
            <Dropdown
              selection
              name="selectedParameter"
              className="last-matches-param-dropdown"
              value={this.state.selectedParameter}
              onChange={this.handleChangeDropdown}
              placeholder="Selecteaza parametru"
              options={this.state.param_options_dropdown}
            />
          </div>
          {this.state.isLoading ? (
            <div style={{ textAlign: "center" }}>
              <Loader type="TailSpin" color={window.clubCustomization.primary_color} height={40} width={40} />
            </div>
          ) : (
            <div className="last-matches-players-table">
              <div className="table-header">
                <div className="player-name">Nume jucator</div>
                {this.state.headers.map((item) => (
                  <div className="match-value">vs {item}</div>
                ))}
                <div className="average">Medie</div>
              </div>
              {this.state.players.map((item, index) => (
                <div
                  className="player-row"
                  style={{
                    backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                  }}
                >
                  <div className="player-name">{item.player_name}</div>
                  {item.matches_values.map((el) => (
                    <div className="match-value">{el}</div>
                  ))}
                  <div className="average">{item.average}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  lang: layoutService.lang,
  currentUser: auth.user,
}))(LastMatches);
