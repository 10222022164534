import React from "react";
import "./Evaluation.scss";
import { TextArea } from "semantic-ui-react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Icon, Button, Checkbox } from "semantic-ui-react";
import delete_icon from "../../../assets/icons/delete.png";
import arrow_down from "../../../assets/icons/arrow-down.png";
import axios from "axios";
import { logout } from "./../../../utils/api";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import Select from "react-select";
import AddEvaluationCategory from "./AddEvaluationCategory";
import EditCategory from "./EditCategory";

import DataTable from "react-data-table-component";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import language from "react-syntax-highlighter/dist/esm/languages/hljs/1c";
import { Category } from "@material-ui/icons";

class Evaluation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      evaluation_name: null,
      evaluation_description: "",
      evaluation_start_date: null,
      evaluation_end_date: null,
      evaluator: null,

      teams_dropdown: [],
      evaluators_dropdown: [],

      categoriesOptions: [],
      selectedTeams: [],
      teamPlayer: [],

      selectedCategories: [],
      subCategories: [],
      addEvaluationCategory: false,
      editCategory: false,

      testColumns: [],
      testData: [],
    };
  }

  componentDidMount = () => {
    this.getDropdownTeams();
    this.getDropdownEvaluators();

    this.getCategories();
  };

  getCategories = () => {
    axios
      .get(
        `report/get_evaluation_parameters_categories?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => this.setState({ categoriesOptions: res.data }))
      .catch((err) => console.log(err));
  };

  getTeamPlayers = (selectedTeams) => {
    this.setState({ selectedTeams: selectedTeams });

    let arrayOfIdTeams = this.state.selectedTeams.map(function(team) {
      return team.value; // Get team id from teams object
    });

    let arrayOfTeamsIdCurrent = [];

    if (selectedTeams.length > 0) {
      arrayOfTeamsIdCurrent = selectedTeams.map(function(currentTeam) {
        return currentTeam.value; // Get team id for current Teams
      });
    }

    //get call only for teams that not exist in select
    if (selectedTeams.length > arrayOfIdTeams.length) {
      selectedTeams.forEach((team) => {
        if (!arrayOfIdTeams.includes(team.value)) {
          axios
            .get(`club/get_players`, {
              params: {
                id_team: team.value,
                token: this.props.token,
              },
            })
            .then((res) => {
              team.checked = true;
              let players = res.data.players;

              players.forEach((player) => {
                player.checked = true;
              });

              const payload = {
                team: team,
                players: players,
              };
              let previousState = this.state.teamPlayer;
              previousState.push(payload);
              this.setState({
                teamPlayer: previousState,
              });
            })
            .catch((err) => console.log(err));
        } else {
        }
      });
    } else {
      // delete team from array
      arrayOfIdTeams.forEach((team, index) => {
        if (arrayOfTeamsIdCurrent.indexOf(team) == -1) {
          let previousState = this.state.teamPlayer;
          previousState.splice(index, 1); // function for deleting team based on index
          this.setState({ teamPlayer: previousState }); // set new state with new array of teams
        } else {
        }
      });
    }
  };

  getSubCategories = (selectedCategories) => {
    this.setState({
      selectedCategories: selectedCategories,
    });
    var arrayOfCategories = this.state.selectedCategories.map(function(obj) {
      return obj.value;
    });
    var arrayOfCategoriesCurrent = [];

    if (selectedCategories.length > 0) {
      arrayOfCategoriesCurrent = selectedCategories.map(function(obj) {
        return obj.value;
      });
    }
    if (selectedCategories.length > arrayOfCategories.length) {
      selectedCategories.forEach((category) => {
        if (!arrayOfCategories.includes(category.value)) {
          axios
            .get(
              `report/get_evaluation_parameters?id_club=${this.props.currentUser.id_club}&category=${category.value}`
            )
            .then((res) => {
              category.checked = true;
              let subcategories = res.data;

              subcategories.forEach((subcategory) => {
                subcategory.checked = true;
              });
              const payload = {
                category: category,
                subcategories: subcategories,
              };
              let previousState = this.state.subCategories;
              previousState.push(payload);

              this.setState({
                subCategories: previousState,
              });
            })
            .catch((err) => console.log(err));
        } else {
        }
      });
    } else {
      arrayOfCategories.forEach((category, index) => {
        if (arrayOfCategoriesCurrent.indexOf(category) == -1) {
          let previousState = this.state.subCategories;
          previousState.splice(index, 1);
          this.setState({
            subCategories: previousState,
          });
        } else {
        }
      });
    }
  };

  getDropdownTeams = () => {
    axios
      .get(`report/get_teams?id_club=${this.props.currentUser.id_club}`)
      .then((res) => {
        const data = res.data.map((item) => ({
          value: item.value,
          label: item.team_name,
        }));
        this.setState({ teams_dropdown: data });
      })
      .catch((err) => console.log(err));
  };

  getDropdownEvaluators = () => {
    axios
      .get(`report/get_evaluators?id_club=${this.props.currentUser.id_club}`)
      .then((res) => {
        const data = res.data.map((item) => ({
          value: item.value,
          label: item.member_name,
        }));
        this.setState({ evaluators_dropdown: data });
      })
      .catch((err) => console.log(err));
  };

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDateChangeStart = (value) => {
    this.setState({ evaluation_start_date: value });
  };

  handleDateChangeEnd = (value) => {
    this.setState({ evaluation_end_date: value });
  };

  showNotification = (title, message, type, duration = 3000) => {
    store.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: duration,
      },
    });
  };

  changeCheckedCategory = (index, data) => {
    let currentSubCategories = this.state.subCategories;
    currentSubCategories[index].category.checked = data.checked;
    if (!data.checked) {
      currentSubCategories[index].subcategories.forEach((subcategory) => {
        subcategory.checked = false;
      });
    } else {
      currentSubCategories[index].subcategories.forEach((subcategory) => {
        subcategory.checked = true;
      });
    }
    this.setState({ subCategories: currentSubCategories });
  };

  changeCheckedSubCategory = (indexCategory, indexSubCategory, data) => {
    let currentSubCategories = this.state.subCategories;
    currentSubCategories[indexCategory].subcategories[
      indexSubCategory
    ].checked = data.checked;
    if (!data.checked) {
      currentSubCategories[indexCategory].category.checked = false;
    } else {
      let checkAll = currentSubCategories[indexCategory].subcategories.every(
        (elem) => {
          return elem.checked;
        }
      );
      if (checkAll) {
        currentSubCategories[indexCategory].category.checked = true;
      }
    }
    this.setState({ subCategories: currentSubCategories });
  };

  changeCheckedTeam = (index, data) => {
    let currentTeam = this.state.teamPlayer;
    currentTeam[index].team.checked = data.checked;
    if (!data.checked) {
      currentTeam[index].players.forEach((player) => {
        player.checked = false;
      });
    } else {
      currentTeam[index].players.forEach((player) => {
        player.checked = true;
      });
    }
    this.setState({ teamPlayer: currentTeam });
  };

  changeCheckedPlayer = (indexTeam, indexPlayer, data) => {
    let currentPlayers = this.state.teamPlayer;
    currentPlayers[indexTeam].players[indexPlayer].checked = data.checked;
    if (!data.checked) {
      currentPlayers[indexTeam].team.checked = false;
    } else {
      let checkAll = currentPlayers[indexTeam].players.every((elem) => {
        return elem.checked;
      });
      if (checkAll) {
        currentPlayers[indexTeam].team.checked = true;
      }
    }
    this.setState({ teamPlayer: currentPlayers });
  };

  handleInputMark = (value, idPlayer, idSubcategory, idCategory) => {
    let currentState = this.state.testData;

    const index = currentState
      .map((object) => object.id_player)
      .indexOf(idPlayer);
    const description = currentState[index].marks[idSubcategory].description;
    const infoMark = {
      mark: value,
      description: description,
      id_category: idCategory,
      id_subcategory: idSubcategory,
    };
    currentState[index].marks[idSubcategory] = infoMark;

    this.setState({ testData: currentState });
  };

  handleInputDescription = (value, idPlayer, idSubcategory, idCategory) => {
    let currentState = this.state.testData;

    const index = currentState
      .map((object) => object.id_player)
      .indexOf(idPlayer);

    const mark = currentState[index].marks[idSubcategory].mark;
    const infoMark = {
      mark: mark,
      description: value,
      id_category: idCategory,
      id_subcategory: idSubcategory,
    };
    currentState[index].marks[idSubcategory] = infoMark;

    this.setState({ testData: currentState });
  };

  generateDataTable = () => {
    //generate columns name
    const { languagePack: languagePack1 } = this.props;
    let columns = [];
    columns.push({
      name: languagePack1.player_name,
      selector: "name",
    });
    columns.push({
      name: languagePack1.team,
      selector: "team",
    });

    this.state.subCategories.map((elem, indexCategory) => {
      elem.subcategories.map((subcategory, indexSubcategory) => {
        if (subcategory.checked) {
          columns.push({
            name: `${elem.category.label} : ${subcategory.label}`,
            selector: subcategory.value,
            cell: (row) => (
              <>
                <div className="input-evaluation-wrapper">
                  <input
                    type="number"
                    min={1}
                    max={5}
                    className="input-evaluation-marks"
                    id={"player-mark-" + row.id_player}
                    id_player={row.id_player}
                    id_category={elem.category.value}
                    id_sub_category={subcategory.value}
                    defaultValue={row.marks[subcategory.value].mark}
                    onChange={(event) => {
                      this.handleInputMark(
                        event.target.value,
                        row.id_player,
                        subcategory.value,
                        elem.category.value
                      );
                    }}
                  />
                  <input
                    type="text"
                    className="input-evaluation-description"
                    id={"player-mark-description-" + row.id_player}
                    id_player={row.id_player}
                    id_category={elem.category.value}
                    id_sub_category={subcategory.value}
                    defaultValue={row.marks[subcategory.value].description}
                    onChange={(event) => {
                      this.handleInputDescription(
                        event.target.value,
                        row.id_player,
                        subcategory.value,
                        elem.category.value
                      );
                    }}
                  />
                </div>
              </>
            ),
          });
        }
      });
    });

    if (this.state.testData.length > 0) {
      let currentTestData = [];
      // Get prev subcategories ID
      let prevSubcategories = [];

      for (const [key, value] of Object.entries(this.state.testData[0].marks)) {
        prevSubcategories.push(key);
      }
      // Get current subcategories ID
      let currentSubcategories = [];
      this.state.subCategories.map((category) => {
        category.subcategories.map((subcategory) => {
          if (subcategory.checked) {
            currentSubcategories.push({
              id_subcategory: subcategory.value,
              id_category: category.category.value,
            });
          }
        });
      });

      // Get prev players ID
      let prevPlayers = this.state.testData.map((player) => player.id_player);

      // Get current players ID
      let currentPlayers = [];
      this.state.teamPlayer.map((team) => {
        team.players.map((player) => {
          if (player.checked) currentPlayers.push(player.id_player);
        });
      });

      //Iterate players
      currentPlayers.forEach((idPlayer) => {
        if (prevPlayers.indexOf(idPlayer) > -1) {
          // Check if player id is on previous Player array
          const playerPrevInfo = this.state.testData.find(
            (player) => player.id_player == idPlayer
          );

          let dataPlayer = {
            id_player: playerPrevInfo.id_player,
            name: playerPrevInfo.name,
            team: playerPrevInfo.team,
            id_team: playerPrevInfo.id_team,
          };
          let marks = {};

          currentSubcategories.forEach((elem) => {
            if (prevSubcategories.indexOf(elem.id_subcategory) > -1) {
              const acctualMark =
                playerPrevInfo.marks[elem.id_subcategory].mark;
              const acctualDescription =
                playerPrevInfo.marks[elem.id_subcategory].description;

              let inputData = {
                mark: acctualMark,
                description: acctualDescription,
                id_category: elem.id_category,
                id_subcategory: elem.id_subcategory,
              };

              marks[elem.id_subcategory] = inputData;
            } else {
              let inputData = {
                mark: "",
                description: "",
                id_category: elem.id_category,
                id_subcategory: elem.id_subcategory,
              };
              marks[elem.id_subcategory] = inputData;
            }
          });

          dataPlayer.marks = marks;
          currentTestData.push(dataPlayer);
        } else {
          //  player id is not on previous Player array
          let infoPlayer = "";
          this.state.teamPlayer.map((team) => {
            team.players.find((player) => {
              if (player.id_player == idPlayer) {
                infoPlayer = player;
                return;
              }
            });
            if (infoPlayer != "") {
              return;
            }
          });

          let dataPlayer = {
            id_player: infoPlayer.id_player,
            name: infoPlayer.player_name,
            team: infoPlayer.team_name,
            id_team: infoPlayer.id_team,
          };
          let marks = {};

          currentSubcategories.forEach((elem) => {
            let inputData = {
              mark: "",
              description: "",
              id_category: elem.id_category,
              id_subcategory: elem.id_subcategory,
            };
            marks[elem.id_subcategory] = inputData;
          });
          dataPlayer.marks = marks;
          currentTestData.push(dataPlayer);
        }
      });
      this.setState({
        testData: currentTestData,
      });
    } else {
      let data = [];
      this.state.teamPlayer.map((team, indexTeam) => {
        team.players.map((player, indexPlayer) => {
          if (player.checked) {
            let dataPlayer = {
              name: player.player_name,
              team: player.team_name,
              id_team: team.team.value,
              id_player: player.id_player,
            };
            let marks = {};
            this.state.subCategories.map((elem, indexCategory) => {
              elem.subcategories.map((subcategory, indexSubcategory) => {
                if (subcategory.checked) {
                  let inputData = {
                    mark: "",
                    description: "",
                    id_category: elem.category.value,
                    id_subcategory: subcategory.value,
                  };
                  marks[subcategory.value] = inputData;
                }
              });
            });

            dataPlayer.marks = marks;

            data.push(dataPlayer);
          }
        });
      });

      this.setState({
        testData: data,
      });
    }
    this.setState({
      testColumns: columns,
    });
  };

  handleSaveRaport = () => {
    let tableData = [];

    this.state.testData.forEach((player) => {
      //iterate marks
      let marksArray = [];
      for (const [key, value] of Object.entries(player.marks)) {
        marksArray.push(player.marks[key]);
      }

      let dataPlayer = {
        id_player: player.id_player,
        id_team: player.id_team,
        marks: marksArray,
      };
      tableData.push(dataPlayer);
    });

    let total_parameters = 0;
    this.state.subCategories.forEach((category) => {
      category.subcategories.forEach((subcat) => {
        if (subcat.checked) {
          total_parameters++;
        }
      });
    });
    const payload = {
      id_club: this.props.currentUser.id_club,
      teams: this.state.selectedTeams.map((team) => team.value),
      evaluation_test_name: this.state.evaluation_name,
      evaluation_description: this.state.evaluation_description,
      id_evaluator: this.state.evaluator?.value
        ? this.state.evaluator.value
        : null,
      evaluation_test_start: this.state.evaluation_start_date,
      evaluation_test_end: this.state.evaluation_end_date,
      evaluation_table_data: tableData,
      total_parameters: total_parameters * this.state.testData.length,
    };

    axios
      .post("/report/add_evaluation", payload)
      .then((res) => {
        if (res.data == 1) {
          store.addNotification({
            title: "Evaluare raport",
            message: "Evaluarea a fost adaugata cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
          this.props.history.push(`/reports/evaluation`);
        } else {
          store.addNotification({
            title: "Evaluare raport",
            message: "Evaluarea nu a fost adaugata cu succes!",
            type: "warning",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;

    return (
      <div className="row">
        <div className="col-lg-6">
          <div className="card card-custom">
            <div className="add-report-container">
              <div className="add-report-header">
                <div className="add-report-title">
                  {languagePack.add_evaluation}
                </div>
              </div>
              <div className="add-report-form">
                <div className="add-report-row">
                  <div style={{ width: "46%" }}>
                    <Select
                      placeholder={languagePack.select_team_teams}
                      isMulti={true}
                      options={this.state.teams_dropdown}
                      value={this.state.selectedTeams}
                      onChange={(selected) => {
                        selected != null
                          ? this.getTeamPlayers(selected)
                          : this.getTeamPlayers([]);
                      }}
                    />
                  </div>

                  <div style={{ width: "46%" }}>
                    <Select
                      placeholder={languagePack.select_evaluator}
                      options={this.state.evaluators_dropdown}
                      className="basic-single"
                      classNamePrefix="select"
                      value={this.state.evaluator}
                      onChange={(selected) =>
                        this.setState({ evaluator: selected })
                      }
                      isClearable={true}
                      isSearchable={true}
                    />
                  </div>
                </div>

                <div className="add-report-row">
                  <input
                    value={this.state.evaluation_name}
                    onChange={this.handleChangeInput}
                    className="add-report-input"
                    name="evaluation_name"
                    placeholder={languagePack.evaluation_name}
                  />
                </div>

                <TextArea
                  value={this.state.evaluation_description}
                  onChange={this.handleChangeInput}
                  className="add-report-text-area"
                  name="evaluation_description"
                  placeholder={languagePack["staff_description"]}
                />

                <div className="add-report-row">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="add-report-date-group">
                      <DateTimePicker
                        value={this.state.evaluation_start_date}
                        onChange={this.handleDateChangeStart}
                        format="dd MMMM,  HH:mm"
                        name="treatment_date_start"
                        className="report-date-input"
                        inputVariant="outlined"
                        emptyLabel={languagePack["starting_date"]}
                      />
                      <Icon
                        name="calendar alternate outline"
                        className="calendar-icon"
                        size="small"
                      />
                    </div>
                    <div className="add-report-date-group">
                      <DateTimePicker
                        value={this.state.evaluation_end_date}
                        onChange={this.handleDateChangeEnd}
                        format="dd MMMM,  HH:mm"
                        name="treatment_date_end"
                        inputVariant="outlined"
                        className="report-date-input"
                        emptyLabel={languagePack["ending_date"]}
                      />
                      <Icon
                        name="calendar alternate outline"
                        className="calendar-icon"
                        size="small"
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="parameters-subtitle">
                {this.state.subCategories.length}{" "}
                {languagePack.selected_categories}
                <div className="add-report-header">
                  <Button
                    onClick={() => this.setState({ editCategory: true })}
                    className="add-player-button"
                    style={{ backgroundColor: "#666666" }}
                  >
                    {languagePack.edit_category_list}
                  </Button>
                  <Button
                    onClick={() =>
                      this.setState({ addEvaluationCategory: true })
                    }
                    className="add-player-button"
                  >
                    {languagePack.add_category}
                    <Icon name="add" className="add-player-button-icon" />
                  </Button>
                </div>
              </div>

              <div className="add-param-row">
                <Select
                  placeholder={languagePack.select_parameters_category}
                  isMulti={true}
                  options={this.state.categoriesOptions}
                  value={this.state.selectedCategories}
                  onChange={(selected) => {
                    selected != null
                      ? this.getSubCategories(selected)
                      : this.getSubCategories([]);
                  }}
                />
              </div>

              <div className="table-content">
                {this.state.subCategories.map((item, index) => {
                  return (
                    <div key={"subCateg-" + index} className="table-row">
                      <div className="evaluation-category-name">
                        <div
                          className="player-name"
                          style={{ fontSize: "20px" }}
                        >
                          {item.category.label}
                        </div>

                        {/* CHECKBOX */}
                        <div className="add-player-row">
                          <div className="add-checkbox-input">
                            <Checkbox
                              checked={item.category.checked}
                              onChange={(event, data) => {
                                this.changeCheckedCategory(index, data);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="sub-categories-row">
                        {item.subcategories.map((elem, index2) => {
                          return (
                            <div
                              key={"subCateg1-" + index2}
                              className="sub-category"
                            >
                              <div className="evaluation-category-name">
                                <div className="player-name">{elem.label}</div>
                              </div>

                              {/* CHECKBOX */}
                              <div className="add-player-row">
                                <div className="add-checkbox-input">
                                  <Checkbox
                                    checked={elem.checked}
                                    onChange={(event, data) => {
                                      this.changeCheckedSubCategory(
                                        index,
                                        index2,
                                        data
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="players-list">
                {this.state.selectedTeams.length > 0 &&
                  this.state.teamPlayer.map((team, index) => {
                    return (
                      <div key={"team-" + index}>
                        <div className="evaluation-add-player-header">
                          <div className="evaluation-team-name">
                            <div className="team-name">{team.team.label}</div>
                            <div className="add-checkbox-input evaluation-team-checkbox">
                              <Checkbox
                                checked={team.team.checked}
                                onChange={(event, data) => {
                                  this.changeCheckedTeam(index, data);
                                }}
                              />
                            </div>
                          </div>

                          {index == 0 && (
                            <div className="players-list-table-header">
                              <div style={{ width: "50%" }}>
                                {languagePack["player_name"]}
                              </div>
                              <div style={{ width: "50%" }}>
                                {languagePack.add_player_evaluation}
                              </div>
                            </div>
                          )}
                        </div>
                        {team.players?.map((player, index2) => {
                          return (
                            <div
                              key={"team-" + index + "-player" + "-" + index2}
                            >
                              <div
                                className="evaluation-row"
                                style={{
                                  backgroundColor:
                                    index2 % 2 == 0 ? "#fafafa" : "",
                                  alignItems: "baseline",
                                }}
                              >
                                <div className="player-name">
                                  <img
                                    className="player-avatar"
                                    alt="avatar"
                                    src={`${window.baseURL}uploads/players/${player.id_player}.jpg`}
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = `${window.baseURL}uploads/players/placeholder.png`;
                                    }}
                                  />
                                  <div className="name">
                                    {player.player_name}
                                  </div>
                                </div>
                                <div className="evaluation-team-name evaluation-player-checkbox-wrapper">
                                  <div className="add-checkbox-input">
                                    <Checkbox
                                      checked={player.checked}
                                      onChange={(event, data) => {
                                        this.changeCheckedPlayer(
                                          index,
                                          index2,
                                          data
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                {this.state.selectedTeams.length > 0 &&
                  this.state.selectedCategories.length > 0 && (
                    <div className="final-report-submit-button-row">
                      <Button
                        className="final-report-submit-button"
                        onClick={this.generateDataTable}
                      >
                        {languagePack.generate_evaluation_table}
                        <Icon
                          name="add"
                          className="final-report-submit-button-icon"
                        />{" "}
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card card-custom">
            <div className="add-report-container">
              <div className="add-report-header">
                <div className="add-report-title">
                  {languagePack["evaluation_list"]}:
                </div>
              </div>
              <DataTable
                title={
                  <div className="payments-header">
                    {languagePack.evaluation_table}
                  </div>
                }
                center
                columns={this.state.testColumns}
                data={this.state.testData}
                highlightOnHover
                customStyles={customStyles}
                fixedHeader
                fixedHeaderScrollHeight="max-content"
              />
              {this.state.selectedTeams.length > 0 &&
                this.state.selectedCategories.length > 0 && (
                  <div className="final-report-submit-button-row">
                    <Button
                      className="final-report-submit-button"
                      onClick={this.handleSaveRaport}
                      disabled={
                        this.state.evaluator == null ||
                        this.state.evaluation_name == null ||
                        this.state.evaluation_start_date == null ||
                        this.state.evaluation_end_date == null
                      }
                    >
                      {languagePack["save_report"]}{" "}
                      <Icon
                        name="add"
                        className="final-report-submit-button-icon"
                      />{" "}
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </div>

        {/* EDIT CATEGORY POPUP */}
        <Dialog
          open={this.state.editCategory}
          //   onClose={() => this.setState({ editCategory: false })}
        >
          <DialogContent>
            <EditCategory
              closePopup={() => this.setState({ editCategory: false })}
              id_club={this.props.currentUser.id_club}
              languagePack={this.props.languagePack}
              categories={this.state.categoriesOptions}
              getCategories={this.getCategories}
              getSubCategories={this.getSubCategories}
            />
          </DialogContent>
        </Dialog>

        {/* ADD CATEGORY DIALOG */}
        <Dialog open={this.state.addEvaluationCategory}>
          <DialogContent>
            <AddEvaluationCategory
              closePopup={() => this.setState({ addEvaluationCategory: false })}
              id_club={this.props.currentUser.id_club}
              languagePack={this.props.languagePack}
              getCategories={this.getCategories}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "rgb(250 250 250)",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  header: {
    style: {
      marginBottom: "20px",
    },
  },
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(Evaluation);
