import React, { Component } from "react";
import "./AddTeam.scss";
import { Icon, Button } from "semantic-ui-react";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import Select from "react-select";

class AddTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamOption: "new",
      newTeamName: "",
      teamName: "",
      teamImage: "",
      clubTeams: [],
      group: "",
      id_team: "",
      initialPoints: "0",
    };
  }

  addTeam = () => {
    const payload = {
      id_competition: this.props.id_competition,
      team_name:
        this.state.teamOption === "new"
          ? this.state.newTeamName
          : this.state.teamName,
      own_team: this.state.teamOption === "new" ? "0" : "1",
      image: this.state.teamImage,
      id_competition_group: this.state.group,
      id_team: this.state.teamOption === "new" ? "" : this.state.id_team,
      initial_points: this.state.initialPoints,
    };
    axios
      .post("match/add_team", payload)
      .then((res) => {
        if (res.data.success == 1) {
          this.props.closePopup();
          this.props.getTeams();
          this.props.updateCompetitionDetails();
          store.addNotification({
            title: this.props.languagePack["competitions_actions"],
            // message: res.data.error,
            message: "Echipa a fost adaugata cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: this.props.languagePack["competitions_actions"],
            message: res.data.error,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  componentDidMount = () => {
    this.getClubTeams();
  };

  getClubTeams = () => {
    axios
      .get(`match/get_club_teams?id_club=${this.props.currentUser.id_club}`)
      .then((res) => this.setState({ clubTeams: res.data }))
      .catch((err) => console.log(err));
  };

  handleChangeImage = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState({
        fileSection: file,
        teamImage: reader.result,
      });
    };
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div
        className="add-team-manual-container"
        style={{ paddingBottom: "12px" }}
      >
        <div className="add-team-manual-title">{languagePack["add_team"]}</div>
        <div className="add-team-manual-options">
          <div
            className="add-team-option"
            onClick={() => this.setState({ teamOption: "new" })}
            style={{
              backgroundColor: this.state.teamOption === "new" ? "#fafafa" : "",
            }}
          >
            <div>{languagePack["new_team"]}</div>
          </div>
          <div
            className="add-team-option"
            onClick={() => this.setState({ teamOption: "club" })}
            style={{
              backgroundColor:
                this.state.teamOption === "club" ? "#fafafa" : "",
            }}
          >
            <div>{languagePack["clubs_team"]}</div>
          </div>
        </div>
        <div className="add-team-container-input">
          {this.state.teamOption === "new" ? (
            <div>
              <input
                value={this.state.newTeamName}
                onChange={(e) => this.setState({ newTeamName: e.target.value })}
                placeholder={languagePack["team_name"]}
                className="add-team-input"
              />
              <input
                value={this.state.initialPoints}
                // defaultValue={"0"}
                onChange={(e) =>
                  this.setState({ initialPoints: e.target.value })
                }
                placeholder={"Punctaj initial"}
                className="add-team-input"
              />
            </div>
          ) : (
            <div className="club-team-dropdown">
              <select
                id="team-select"
                value={this.state.teamName}
                onChange={(e) => {
                  this.setState({
                    id_team: e.target.selectedOptions[0].getAttribute(
                      "id-team"
                    ),
                    teamName: e.target.value,
                  });
                  // this.setState({

                  // });
                }}
              >
                <option value="" disabled selected>
                  {languagePack["choose_team"]}
                </option>
                {this.state.clubTeams.map((item) => {
                  return (
                    <option
                      value={item.team_name}
                      key={item.id_team}
                      id-team={item.id_team}
                    >
                      {item.team_name}
                    </option>
                  );
                })}
              </select>
              <input
                value={this.state.initialPoints}
                // defaultValue={"0"}
                onChange={(e) =>
                  this.setState({ initialPoints: e.target.value })
                }
                placeholder={"Punctaj initial"}
                className="add-team-input"
              />
            </div>
          )}
          <div className="add-team-manual-image">
            <div className="image-input-container">
              <span>{languagePack["team_logo"]}</span>
              <input
                type="file"
                className="add-news-image-input"
                id="customFile"
                onChange={this.handleChangeImage}
                accept="image/*"
              />
              <label className="add-file-form-file-label" htmlFor="customFile">
                <div className="image-view">
                  {this.state.teamImage !== "" ? (
                    <img src={this.state.teamImage} />
                  ) : (
                    <span>
                      <Icon
                        name="arrow up"
                        className="cusor-pointer"
                        size="small"
                      />
                    </span>
                  )}
                </div>
              </label>
            </div>
          </div>
          {/* {this.props.groups.length > 0 ?
            <div className="group-dropdown club-team-dropdown">
              <select
                value={this.state.group}
                onChange={(e) => {
                  this.setState({ group: e.target.value })
                }}
              >
                <option value="" disabled selected>
                  {"Choose group"}
                </option>
                {this.props.groups.map((item) => {
                  return (
                    <option value={item.value} key={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </div>
            // </div>
            : null} */}
          <div className="add-team-manual-buttons">
            <Button
              className="add-team-button cancel-button"
              onClick={() => this.props.closePopup()}
            >
              {languagePack["admin_cancel"]}
              <Icon name="cancel" className="white-icon" />
            </Button>
            <Button
              className="add-team-button"
              disabled={
                this.state.teamOption == "new"
                  ? this.state.newTeamName == ""
                  : this.state.teamName == ""
              }
              onClick={this.addTeam}
            >
              {languagePack["admin_add"]}
              <Icon name="add" className="white-icon" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(AddTeam);
