export default {
  //no data placeholder
  no_data_activ_contract: "No active contract",
  no_data_active_license: "No active license",

  // Video Gallery
  add_video: "Add video",
  video_description: "Video description",
  upload_video: "Upload video",
  video_title: "Video title",

  assist: "Assist",
  delete_tag: "Delete tag",
  sure_delete_tag: "Are you sure you want to delete this tag?",
  video_event: "Video event",
  select_all: "Select all",
  starting_moment: "Starting moment",
  ending_moment: "Ending moment",
  event_type: "Event type",
  tag_title: "Tag title",
  tag_details: "Tag details",
  enter_details: "Enter details...",
  search_video: "Search video",
  add_summary_title: "Add summary title",
  create_summary: "Create summary",
  vide_time_input_error:
    "You entered a value greater than the duration of the video",
  date_time_difference_error:
    "The value at the Start Moment must be less than the value at the End Moment",

  success_video_added: "Video tag was successfully added!",
  failed_video_added: "Video tag adding has failed!",
  success_video_edited: "Video tag was successfully edited!",
  failed_video_edited: "Video tag editing has failed!",

  //staff members

  license_file: "License file",
  without_license: "Without license",
  select_license: "Select license",
  staff_license: "License",
  add_certificate: "Add certificate",
  add_medical_tests: "Add medical tests",

  designation: "Name",
  subcategory_designation: "Subcategory name",
  type: "Type",
  dimension: "Dimension",
  added_at: "Added at",

  // Campaigns
  campaign_title: "Campaigns",
  campaign_name: "Campaign Name",
  campaign_type: "Type",
  campaign_status: "Status",
  campaign_audience: "Audience",
  campaign_last_edited: "Last edited",
  campaign_opens: "Opens",
  campaign_clicks: "Clicks",
  campaign_status_filter: "Filter by status",
  campaign_type_filter: "Filter by type",
  campaign_pagination: "Results per page",
  campaign_your_audience: "Your audience",
  campaign_choose_audience: "Choose audience",
  campaign_new_segment: "New segment",
  campaign_add_new_condition: "Add new condition",
  campaign_save_segment: "Save segment",
  campaign_sender: "Sender",
  campaign_name: "Name",
  campaign_subject: "Subject",
  campaign_enter_subject: "Enter subject",
  campaign_content: "Content",
  campaign_main_title: "Main title",
  campaign_preview_article: "Preview article",
  campaign_schedule_campaign: "Schedule campaign",
  campaign_send_later: "Send later",
  campaign_send_test_message: "Send test message",
  campaign_save_message: "Save message",
  campaign_save_as_template: "Save as template",
  campaign_cancel: "Cancel",
  campaign_is_before: "Is before",
  campaign_is_after: "Is after",
  campaign_specific_date: "A specific date",
  campaign_range: "A range",
  campaign_contact_must_match: "Contact must match",
  campaign_gender: "Gender",
  campaign_birthday: "Birthday",
  campaign_date_added: "Date added",
  campaign_view_email: "View email",
  campaign_download: "Download",
  campaign_print: "Print",
  campaign_top_links: "Top links clicked",
  campaign_view_all_links: "View all links",
  campaign_recipients: "Recipients",
  campaign_total_recipients: "Total recipients",
  campaign_total_opens: "Opened",
  campaign_total_clicked: "Clicked",
  campaign_total_bounced: "Bounced",
  campaign_total_unsubscribed: "Unsubscribed",
  campaign_delivered: "Delivered",
  email_campaign: "E-mail campaign",
  campaign_report: "Campaign report",
  campaign_view_all: "View all",
  campaign_subscribers_most_opens: "Subscribers with most opens",
  campaign_numbers_stats: "Numbers and stats",
  campaign_abuse_reports: "Abuse reports",
  campaign_last_clicked: "Last clicked",
  campaign_total_clicks: "Total clicks",
  campaign_clicks_per_unique_opens: "Clicks per unique opens",
  campaign_forwarded: "Forwarded",
  campaign_last_opened: "Last opened",
  campaign_total_opens: "Total opens",
  campaign_successful_deliveries: "Successful deliveries",

  //Fans section
  tax_redirectors_title: "Profit tax redirects",
  tax_redirectors_total: "Total redirects",
  tax_redirector_name: "First and last name",
  tax_redirector_register_date: "Registration date",
  tax_redirector_email: "Email address",
  tax_redirector_phone_number: "Phone number",
  incorect_phone_number: "Incorrect phone number",

  //Site administration
  about_us_title: "About us",
  upload_main_image: "Upload main image",
  upload_another_main_image: "Upload another main image",
  sections: "Sections",
  section: "Section",
  admin_add: "Add",
  admin_cancel: "Cancel",
  admin_delete: "Delete",
  upload_image: "Upload image",
  upload_other_image: "Upload other image",
  admin_save: "Save",
  admin_cancel_modif: "Discard changes",
  admin_preview: "Preview page",
  gallery_title: "Preview gallery",
  gallery_photos_title: "Photos gallery",
  gallery_videos_title: "Videos gallery",
  gallery_bio_title: "Biography",
  gallery_add_button: "Add image / images",
  gallery_add_button_images: "Add image / images",
  gallery_add_button_videos: "Add video / videos",
  gallery_add_button_bio: "Add / edit biography",
  gallery_placeholder: "You have currently not uploaded any images.",
  gallery_videos_placeholder: "You have currently not uploaded any videos.",
  gallery_bio_placeholder: "You have currently not added any biography.",
  file_remove_success_title: "File removed successfully.",
  general_error_message: "An error occurred, please try again later.",

  partners_title: "Partners & sponsors",
  add_partner_button: "Add partner",
  sponsor_name_form: "Sponsor name",
  sponsor_site_form: "Site URL",
  sponsor_logo_form: "Upload logo (.png)",
  sponsor_site_notif_title: "Manage partners",
  sponsor_site_notif_success: "Partenerul a fost adaugat cu succes",
  delete_partner: "Delete partner",
  delete_partner_popup_text: "Are you sure you want to remove this partner?",
  admin_confirm: "Confirm",
  delete_sponsor_notif_text: "The partner has been removed successfully",

  admin_contact_title: "Contact data",
  admin_contact_phone: "Phone number",
  admin_contact_address: "Address",
  admin_contact_org_name: "Organization name",
  admin_contact_save_changes: "Save changes",
  admin_contact_notif_title: "Contact data management",
  admin_contact_notif_text_success: "Changes have been saved successfully!",

  gallery_delete_popup_title: "Delete image",
  gallery_delete_popup_title_video: "Delete video",
  gallery_delete_popup_text: "Are you sure you want to delete this image?",
  gallery_delete_popup_text_video:
    "Are you sure you want to delete this video?",
  upload_images_popup_title: "Upload images",
  upload_videos_popup_title: "Upload videos",
  upload_bio_popup_title: "Add / edit biography",
  gallery_upload_select_file: "Select file",
  gallery_upload_select_files: "Select files",
  gallery_upload_nr_singular: " image uploaded",
  gallery_upload_nr_plural: "images uploaded",
  gallery_notification_title: "Gallery",
  gallery_notification_success: "Files were uploaded successfully!",
  gallery_notification_fail: "Files weren't uploaded successfully!",
  gallery_notification_partial_upload:
    "Files were partially uploaded successfully!",
  gallery_notification_max_size_upload: "Files maximum accepted size is 2 GB!",

  player_gallery_no_images: "No photos found.",
  player_gallery_no_videos: "No videos found.",
  player_gallery_delete_image_success: "Photo was successfully deleted.",
  player_gallery_delete_image_fail: "Photo wasn't successfully deleted.",

  edit_biography: "Edit biography",

  news_page_title: "Latest news added",
  news_page_add_button: "Add news",
  news_page_header_title: "Title",
  news_page_header_tag: "Tag",
  news_page_header_date: "Date of publication",
  news_page_header_options: "Options",
  news_page_delete_popup_title: "Delete article",
  news_page_delete_popup_text:
    "Are you sure you want to remove this news? The action is irreversible.",
  add_news_title: "Add news",
  add_news_article_title: "News title",
  add_news_publish_button: "Publish article",
  add_news_cancel_button: "Discard article",
  article_preview: "Preview article",
  edit_news_title: "Edit news",
  edit_news_delete_button: "Delete article",
  edit_section: "Edit section",

  //Medical

  expired: "Expired",
  medical_certificate: "Medical certificate",
  expires_in_days: "Expires in (days)",
  visa_expiration_date: "Visa expiration date",
  date_of_examination: "Date of examination",
  medical_certificate: "Medical certificate",
  medical_certificates: "Medical certificates",
  no_medical_certificates: "No medical certificates found.",
  add_medical_certificate: "Add certificate",
  date_of_certificate_examination: "Date of certificate examination",
  expiry_date_examination: "Expiry date of certificate examination",
  proof_of_medical_certificate: "Proof of medical certificate",
  date_of_medical_tests_examination: "Date of medical tests",
  expiry_date_medical_tests: "Date of expiry medical tests",
  proof_of_medical_tests: "Proof of medical tests",

  select_iamge: "Select image",
  files: "Files",
  file: "File",

  active_injuries_title: "Current injuries",
  active_injuries_add_button: "Add injury",
  active_injuries_player_name: "Player name",
  active_injuries_type: "Type of injury",
  active_injuries_injury_date: "Date of injury",
  active_injuries_estimated_recovery: "Estimated recovery",
  injury_details: "Injury details",
  add_injury_popup_title: "Add new injury",
  injury_zone: "Zone",
  edit_details: "Edit details",
  treatment: "Treatment",
  injury_edit: "Edit",
  add_treatment: "Add treatment",
  end_injury: "End injury",

  select_team: "Select team",
  select_team_teams: "Select team/teams",
  select_player: "Select player",
  select_zone: "Select zone",
  select_evaluator: "Select reviewer",
  select_parameters_category: "Select parameters category",
  categories_list: "Categories list",
  add_new_subdivision: "Add new subdivison",
  add_new_subcategory: "Add new subcategory",
  selected_categories: "Selected categories",

  start_date: "Start date",
  end_date: "End date",
  return_date: "Date of return",
  treatment_details: "Treatment details",
  injuries_history: "Injuries history",
  absence_period: "Period of absence",
  no_treatment_found: "No treatment found",
  total_treatment_cost: "Total treatment cost",
  injury_date: "Date",
  injury_day: "day",
  injury_days: "days",
  total_cost: "Total cost",
  no_injuries_found: "No injury found during the selected period",
  injury_change_success: "The selected injury has been successfully modified!",
  injury_close_success: "The injury ended on the selected date",
  injury_delete_success: "The selected injury has been successfully removed!",
  treatment_delete_success:
    "The selected treatment has been successfully removed!",
  injury_management_title: "Manage injuries",
  injury_added_success: "The injury was successfully added",
  treatment_edit_success: "The selected treatment was successfully edited!",
  cost: "Cost",
  staff_members: "Staff members",
  roles_list: "Roles list",
  add_member: "Add member",
  staff_member_name: "Name",
  staff_member_role: "Role",
  edit_profile: "Edit profile",
  staff_full_name: "First and last name",
  staff_description: "Description",
  edit_staff_member_popup_title: "Staff member profile",
  edit_staff_member_popup_message: "Details saved successfully!",
  delete_staff_member_message:
    "Selected profile has been successfully removed!",
  popup_delete_staff_member_title: "Delete profile",
  popup_delete_staff_member_message:
    "Are you sure you want to remove this profile? The action is irreversible.",
  add_new_role: "Add new role",
  add_role: "Add role",
  manage_role_notification_title: "Club management roles",
  manage_role_notification_succes: "Role added successfully!",
  manage_role_notification_fail: "Selected role has been successfully removed!",
  active_teams: "Active teams",
  add_team: "Add team",
  no_teams_yet: "The club does not currently have any team",
  team_type: "Type",
  team_name: "Team name",
  team_logo: "Team logo",
  number_players: "Number of players",
  team_coach: "Coach",
  team_coordinator: "Coordinator/Coach",
  teams_notification_title: "Manage teams",
  teams_notification_success: "Team added successfully!",
  team_players: "Players",
  team_performance: "Team performance",
  add_player: "Add player",
  PLAYER_NAME: "Player name",
  NATIONALITY: "Nationality",
  POSITION: "Position",
  SECONDARY_POSITION: "Secondary position",
  MAIN_FOOT: "Main foot",
  AGE: "Age",
  MARKET_VALUE: "Market value",
  average: "Average",
  age_years: "years",
  minutes_played: "Minutes played",
  no_players_placeholder: "This team has no players",
  edit_team_name: "Edit team name",
  birth_date: "Date of birth",
  on_loan: "On loan at ",
  loaned: "Loaned",
  until_loan: " until ",

  //Player profile

  change_instat_id: "Change instat id for ",
  no_records: "No records",
  keep_it_in_previous_teams: "Keep player in previous teams",
  add_player_to_another_team: "Add player to another team",
  fifa_id: "Fifa ID",
  remarks: "Remarks",
  add_remarks: "Add remark",
  mark: "Mark",
  mark_for_all: "Mark for all",
  was_present: "Was present",
  training_statistics: "Training statistics",
  all_present: "All present",
  no_video_events: "No video events",
  player_video_clips: "Player video snippets",
  add_document: "Add document",
  delete_document: "Delete document",
  activity_type: "Activity type",
  age_group: "Age group",
  national_team: "National team",
  select_type_of_activity: "Select type of activity",
  call_up_date: "Call up date",
  select_national_team: "Select national team",
  add_national_team_call_up_popup: "Add national team call-up",
  no_national_team_call_up: "Has no national team call-ups",
  add_national_team_call_up: "Add call up",
  national_team_calls: "National team calls",
  delete_career_record: "Delete career record",
  delete_national_call_up: "Delete national call-up",
  delete_national_call_up_message:
    "The action is irreversible. Are you sure you want to delete the career record for ",
  for_competition: " for competition ",
  no_data_evaluation: "Player has no ratings",
  birthplace: "Place of birth",
  player_age: "Age",
  weight: "Weight",
  height: "Height",
  select_year: "Select year",
  section_access_forbidden: "You do not have access to this section",
  delete_player: "Delete player",
  delete_player_popup_text:
    "Are you sure you want to remove this player from this team? The action is irreversible.",
  change_player_team: "Change the player team",
  select_new_team: "Select new team",
  player_transactions_history: "History of transactions performed",
  transaction_description: "Description",
  transaction_date: "Transaction date",
  transaction_type: "Type",
  trasnaction_amount: "Amount",
  total_expenses: "Total expenses",
  no_player_transactions: "No payments found.",

  contract_details: "Contract details",
  active_contract: "Active contract",
  active_bonuses: "Bonuses active",
  expiry_date: "Expiry date",
  monthly_wage: "Monthly wage",
  placeholder_bonuses: "No bonus added",
  starting_date: "Start date",
  shirt_number: "Shirt number",
  move_player: "Change team",
  edit_photo: "Edit photo",

  //Aside main menu

  Club: "My club",
  dashboard: "Dashboard",
  trainings: "Training sessions",
  Reports: "Reports",
  finances: "Financial",
  medical: "Medical",
  documents: "Documents",
  fans_relation: "Fan engagement",
  site_management: "Site management",
  admin_parent_app: "Admin parents app",
  toggle_aside: "Toggle aside",
  Users: "Users",
  shop: "Shop",

  //Aside menu list

  financial_aside_title: "Financial",
  reports_aside_title: "Reports",

  player_contracts: "Player contracts",
  staff_contracts: "Staff contracts",
  injuries: "Injuries",
  tax_redirectors: "Tax redirectors",
  news: "News",
  cashflow: "Cashflow",
  transactions: "Transactions",
  sponsors: "Sponsors",
  clients: "Clients",
  providers: "Providers",
  wages_costs: "Wages costs",
  match_costs: "Match costs",
  suspension_status: "Suspension status",
  goals_stats: "Goals stats",
  ab_list: "A/B List",
  bonuses_monitoring: "Bonuses monitoring",
  costs: "Costs",
  edit_costs: "Edit spendings",
  online_payment_list: "Online payment list",
  no_online_payments: "No online payments was found.",
  necessity_reports: "Necessity reports",
  upload_bank_account_statements: "Upload bank account statements",
  upload_bank_account_statements_amount_column:
    "Select the column you want to associate with payment amounts:",
  upload_bank_account_statements_date_column:
    "Select the column you want to associate with the transaction date:",
  upload_bank_account_statements_payer_column:
    "Select the column containing the name of the payer:",
  select_column: "Select column",

  receipts: "Receipts",
  my_list: "My list",
  end_recurrent: "End of recurrent day",
  start_recurrent: "Start of recurrent day",
  day_of_the_week: "Day of the Week",

  presence: "Presence",
  duration_minutes: "Duration (min)",
  duration: "Duration",
  confirmed_presence: "Confirmed presence",
  add_recurrent_training: "Add Recurrent Training",
  plan_training: "Plan training",
  trainings_history: "Training sessions history",
  weight_control: "Weight control",
  training_sections: "Training sections",
  attendance_report: "Attendance report",
  show_presence: "Show presence",

  physical_tests: "Physical tests",
  add_physical_tests: "Add physical test",

  evaluation_reports: "Evaluations",
  teams: "Teams",
  calendar: "Calendar",
  shop: "Magazin",
  matches_schedule: "Matches schedule",
  competitions: "Competitions",

  select_venue: "Select venue/venues",
  is_recurrent: "Is recurring",
  event_name: "Event name",
  add_event_for_venue: "Add event for venue",
  has_subdivison: "Has subdivisions",
  subdivison_name: "Subdivison name",
  venue_name: "Venue name",
  venue_list: "Venue list",
  edit_training: "Edit training",
  recurring_until: "Recurring until",
  add_venue: "Add venue",
  delete_venue:
    "The action is irreversible. Are you sure you want to delete venue ",
  edit_category_list: "Edit category list",
  venue_management: "Venue management",
  contact_data: "Contact data",
  social_media_connect: "Social media connect",
  fans_list: "Fans list",
  import_data: "Import data",
  campaigns: "Campaigns",
  categories: "Categories",
  users_list: "Users list",
  users_notifications: "Users notifications",
  edit_match_costs: "Edit match costs",
  total_cost_match: "Total costs",
  costs_categories: "Costs categories",
  add_cost_category: "Add category",
  add_new_category_name: "New category name",
  category_cost_sum: "Amount",
  save_edit_match_costs: "Save match costs",
  products: "Products",
  orders: "Orders",
  categories: "Categories",

  //Quick user
  change_password: "Change password",
  your_currency: "Selected currency",
  no_club: "Without club",
  sign_out: "Logout",
  user_profile: "User profile",
  password_reset: "Reset password",
  account_settings: "Account settings",
  password_success_text: "The password has been changed succesfully",

  //Team players list

  edit_training: "Edit training",
  add_player_to_training: "Add player to training",
  transaction_type: "Transaction type",
  payment_history: "Payment history",
  medical_visa: "Medical visa",
  no_data_medical_visa: "No medical visa added in the selected season",
  key_pass: "Key pass",
  score: "Score",
  last_matches_stats: "Last matches statistics",
  add_cost: "Add cost",
  cost_history: "Cost history",
  change_career: "Change career",
  legitimation_period: "Legitimation period",
  club_name: "Club",
  add_in_career: "Add in career",
  career: "Career",
  has_professional_license_issued: "Has professional license issued",
  identity_card_id: "Identity card ID",
  vfm:
    "VFM shows whether a player deserves the salary he currently earns, comparing it with his performance on the field. A high index means that a player earns less than he actually deserves, comparing with the team level. The lower the index, the higher the contrast between performance and gain. ",
  pta:
    "PTA shows if there is a corelation between the number of minutes played during the season and the current performance on the pitch. The lower the index, the  higher the contrast between performance and number of minutes (a player that has played too much/too few).",
  instat_index:
    "InStat Index helps to determine the player's class based on an automatic algorithm that considers the player’s contribution to the team’s success, the significance of their actions, opponent’s level and the level of the championship they play in.",
  instat_index_title: "Instat index",

  //Dashboard

  end_of_month_sold: "End of the month balance",
  weekly_report: "Weekly report",
  sum: "Amount",
  period: "Period",
  position: "Position",
  ranking_points: "Points",
  market_value_updates: "Market value updates",
  increase: "Increase",
  current_value: "Current value",
  calendar_title: "Events planner",
  label_color: "Label color",
  description: "Description",
  event_details: "Event details",
  location: "Location",
  hour: "Hour",
  select_match_evaluation: "Select match evaluation",
  pitch_condition: "Pitch condition",
  weather_condition: "Weather condition",
  game_play: "Game play (Weaknesses / Strengths)",
  save_details: "Save details",

  //Matches list

  no_matches: "No matches found.",
  reserve_referee: "Reserve referee",
  assistant_referee: "Assistant referee",
  central_referee: "Central refree",
  refrees: "refrees",
  save_refrees: "Save refree",
  no_data_documents: "No documents found",
  no_data_events: "No events found",
  first_half: "First half",
  second_half: "Second half",
  generate_match_report: "Generate match report",
  full_time_result: "Full time result",
  half_time_result: "Halft time result",
  cost_category_list: "Costs category list",
  edit_category_list: "Edit category list",
  edit_match: "Edit match",
  round: "Round",
  match: "Match",
  select_competition: "Select competition",
  select_age_group: "Select age group",
  select_group: "Select group",
  select_match_venue: "Select location",
  add_new_match_venue: "Add new location",
  new_match_venue: "Match location",
  past_matches: "Past matches",
  future_matches: "Future matches",
  match_events: "Match events",
  starting_lineup: "Starting lineups",
  substitutes: "Substitutes",
  add_event: "Add event",
  yellow_card: "Yellow card",
  red_card: "Red card",
  substitution: "Substitution",
  goal: "Goal",
  event_successfully_added: "Event added successfully!",
  event_minute: "Event minute",
  select_team: "Select team",
  select_event_type: "Select event type",
  select_match_type: "Select match type",
  select_player: "Select player",
  select_player_in: "Select player in",
  select_player_out: "Select player out",
  select_goal_type: "Select goal type",
  single_goal: "Single goal",
  goal_assist: "Goal + assist",
  select_scoring_player: "Select scoring player",
  select_assisting_player: "Select assisting player",
  player_lineup_successfully_added: "Lineup player added successfully!",
  player_sub_successfully_added: "Substitution player added successfully!",
  players_match_rating: "Players rating",
  no_players: "There are no players.",
  save_players_rating: "Save players rating",

  //Providers components

  yellow: "Yellows",
  teams_from_competition: "Teams from competition",
  add_location_to_field_management: "Add location to field management",
  date_and_time_are_approximate: "Date and time are approximate",
  minutes_per_time: "Minutes per half",
  match_from_competition: "Competition match",
  frendly_match: "Frendly",
  current_season_stats: "Current season stats",
  match_sg: "Match",
  match_pl: "Matches",
  starting_lineup_matches_sg: "Match in starting 11",
  starting_lineup_matches_pl: "Matches in starting 11",
  goals_scored_sg: "Goal scored",
  goals_scored_pl: "Goals scored",
  clean_sheets_sg: "Clean sheet",
  clean_sheets_pl: "Clean sheets",
  yellow_cards_sg: "Yellow card",
  yellow_cards_pl: "Yellow cards",
  red_cards_sg: "Red card",
  red_cards_pl: "Red cards",
  player_match_rating: "Rating",
  assist_sg: "Assist",
  assist_pl: "Assists",
  season_total: "Season total",
  season_avg: "Season average",
  parameter_list: "Parameters list",
  player_actions: "Player individual actions",
  select_match: "Select match",
  attack: "Attack",
  defence: "Defence",
  technical: "Technical",
  add_name: "Enter the name",
  select_existing_partners: "Select from existing partners",
  balance_at_end_month: "Balance at the end of the month",
  or_lowercase: "or",
  supplier_operations: "Supplier operations",
  supplier_success: "The supplier has been successfully added",
  add_supplier: "Add supplier",
  //Trainings

  trainings_evolution: "Training sessions evolution",
  physical_tracking_results: "Physical tracking results",
  heart_rate: "Heart rate",
  distance_covered: "Distance covered",
  kcal_spent: "Calories",
  sprint_counter: "Sprint counter",
  speed: "Speed",
  medium: "avg",
  training_location: "Training location",
  trainings_history: "Training sessions history",
  start_hour: "Start hour",
  end_hour: "End hour",
  training: "Training session ",
  training_schedule: "Training schedule",
  hours_interval: "Time slot",
  optimal_weight: "Optimal weight",
  add_weight: "Add weight",
  measure_date: "Measure date",
  weight_value: "Value",

  //Documents

  total_payment_at_present: "Total payment (at present)",
  edit_player_contract: "Edit player contract",
  edit_staff_contract: "Edit staff contract",
  file_details: "File details",
  upload_file: "Upload file",
  add_new_category: "Add new category",
  remove_contract: "End contract",
  remove_contract_date: "End of contract date",
  remove_contract_confirm: "Confirm",
  active_contracts: "Active contracts",
  bonuses_list: "Bonuses list",
  add_contract: "Add contract",
  occupation: "Role",
  choose_staff_member: "Choose staff member",
  contract_bonuses: "Contract bonuses",
  signing_date: "Signing date",
  add_contract_extra_bonuses_text:
    "To add additional bonuses and conditions see the bonuses section",
  select_condition: "Select condition",
  add_condition_value: "Insert condition value",
  add_condition: "Add condition",
  active_conditions: "Active conditions",
  bonus: "Bonus",

  //Training plan

  create_training: "Create training session",
  add_training_sections: "Add sections",
  total_training_duration: "Total time",
  training_details: "Training details",
  hours_error_training: "The end hour must be greater than the start hour",
  save_training: "Save training session",
  training_sections: "Training sections",
  create_new_section: "Create new section",
  add_new_section_name: "New section name",
  add_training_session: "Add training session",
  training_add_title: "Training management",
  training_success: "Training successfully added!",
  section_add_title: "Section management",
  section_added_success: "Section successfully added!",
  training_date: "Date",
  training_date_team: "Date and team",
  training_description_details: "Description/Details",
  add_view_image: "Add/view image",
  add_view_video: "Add/view video",
  add_section: "Add section",

  // team settings
  team_management: "Team management",
  number_order: "Order number",
  tax_value: "Tax value",
  display_on_website: "Display on website",
  display_for_online_payments: "Display for online payments",
  save_settings: "Save settings",

  // team settings
  staff_management: "Staff management",

  // Sponsors
  sponsor_name: "Sponsor name",

  //Matches

  match_date: "Match date",
  away_team: "Away team",
  home_team: "Home team",
  round_number: "Round number",
  round_number_name: "Round number/name",
  match_format: "Match format",
  add_match_format: "Add match format",
  date_and_time_are_approximate: "Date and time are approximate",
  select_match_location: "Selected match location",
  add_match: "Add match",
  match_successfully_added: "Match added successfully!",
  competitions_actions: "Competitions operations",
  new_team: "New team",
  clubs_team: "Club's team",
  add_competition: "Add competition",
  competition_name: "Competition name",
  competition_has_more_cat: "Competition has more age categories",
  number_of_groups: "Number of groups",
  competition_type: {
    name: "Competition type",
    elements: {
      championship: "Championship",
      knockout: "Knockout",
      group_stage: "Groups + knockout",
    },
  },
  teams_number: "Number of teams",
  ranking: "Ranking",
  edit_team: "Edit team",
  points_short: "Pts.",
  manage_competitions: "Manage competitions",
  manage_competitions_success_changes: "Changes saved successfully",
  manage_competitions_failed_changes: "Changes has not been saved",
  active_competitions: "Active competitions",
  competition_deleted_success: "Competition removed successfully",
  round_deleted_success: "Round removed successfully",
  match_deleted_success: "Match removed successfully",
  match_delete_popup_title: "Delete match",
  match_delete_popup_text:
    "Are you sure you want to remove this match? The action is irreversible.",
  fixture_table: "Tournament bracket",
  delete_competition: "Delete competition",
  delete_round: "Delete round",
  edit_round: "Edit round",
  round: "Round",
  new_round_name: "New round name",
  delete_competition_text:
    "Are you sure you want to remove this competition? All the data will be lost.",
  edit_competition: "Edit competition",
  edit_score: "Edit score",
  no_matches_for_selected_options:
    "No match available for the selected options",

  player_tech_parameters: "Player technical parameters",
  parameters_list: "Parameters list",
  teams_stats: "Team stats",
  administrate_teams: "Manage teams",
  administrate_teams_success: "Team name changed successfully!",
  administrate_teams_delete: "The team has been successfully deleted!",

  // Reports

  delete_evaluation: "Delete evaluation",
  delete_evalaution_message:
    "Are you sure you want to delete this evaluation? All related data will be lost.",
  delete_physical_test_message:
    "Are you sure you want to delete this physical test? All related data will be lost.",
  delete_category_message:
    "The action is irreversible. Are you sure you want to delete category ",
  edit_evaluation: "Edit Evaluation",
  evaluation_name: "Evaluation name",
  generate_evaluation_table: "Generate evaluation table",
  add_player_evaluation: "Add player to evaluation",
  evaluation_table: "Evaluation table",
  add_evaluation: "Add evaluation",
  players_evaluations: "Players evaluations",
  no_data_report_physical:
    "Add at least one parameter and one team, then add a player to see the rating list",
  value: "Value",
  add_report: "Add report",
  made_by: "Made by",
  player_comparison: "Player comparison",
  evaluated_params: "Evaluated parameters",
  parameter: "Parameter",
  unit_of_measure: "Unit of measure",
  evaluator_name: "Evaluator name",
  report_name: "Report name",
  parameters_added: "parameters added",
  select_parameter: "Select parameter",
  create_new_parameter: "Create new parameter",
  unit_of_measure: "Unit",
  add_parameter: "Add parameter",
  evaluation_list: "Evaluation list",
  choose_player: "Choose a player",
  add_value: "Enter value",
  add_data: "Enter the data",
  evaluation: "Assessment",
  player_added: "Player added",
  save_report: "Save report",
  add_parameter_warning_notification_title: "Add report",
  add_parameter_warning_notification_text: "Please select a parameter.",
  add_player_warning_notification_title: "Add report",
  add_player_warning_notification_text:
    "Please select a player before adding data. If there are no players available on the list, make sure you have selected the team.",

  // Financial
  transaction_history: "Transaction history",
  current_balance: "Current balance",
  select_interval: "Select interval",
  select_type: "Select type",
  items_on_page: "Items on page",
  total_income: "Total income",
  total_expense: "Total expense",

  // Fan engagement
  combine_fan_profiles: "Combine fan profiles",
  filter_profiles: "Filter",
  firstname_lastname: "Name",
  phone_no: "Phone number",
  age: "Age",
  years: "years",
  general_info: "General info",
  full_name: "Full name",
  birthday: "Birthday",
  fan_profile: "Fan profile",
  address: "Address",
  activity: "Activity",

  // Scouting
  saved_shortlists: "Saved shortlists",
  create_shortlist: "Create shortlist",
  shortlist_name: "Shortlist name",
  formation: "Formation",
  selected_players: "Selected players",
  position: "Position",
  date_created: "Date created",
  shortlist_url: "Shortlists",

  // Users management admin
  users_management: "Users management",
  users_management_menu: "Users management",
  account_creation_date: "Account creation date",
  team_access: "Team access",
  access_module: "Access module",
  add_user: "Add user",
  active_user_success: "User is now active",
  inactive_user_success: "User is now inactive",
  activate: "Activează",
  disable: "Disable",
  activate_user: "Activate user",
  disable_user: "Disable user",
  disable_user_confirmation: "Are you sure you want to disable this user?",
  activate_user_confirmation: "Are you sure you want to activate this user?",
  delete_user: "Delete user",
  delete_user_confirmation:
    "Are you sure you want to delete this user? All related data will be lost",
  delete_user_success: "User has been successfully deleted",
  delete_user_danger: "User has not been deleted",
  yes: "Yes",
  no: "No",
  wrong_phone_number: "Wrong phone number",
  wrong_email: "Wrong email",
  all_teams: "All teams",
  some_teams: "Some teams",
  edit_user: "Edit user",
  apply: "Apply",
  edit_user_success: "User was successfully updated",
  edit_user_danger: "User was not updated",

  //Products
  add_product: "Add product",
  product_image: "Product photo",
  product_name: "Product name",
  product_description: "Product description",
  product_price: "Price",
  product_status: "Status",
  product_actions: "Actions",
  product_is_active: "Product is active",
  product_delete_confirmation: "Delete confirmation",
  product_delete_message:
    "Are you sure you want to delete this product? The action is irreversible.",
  product_edit: "Edit product",
  active: "Active",
  inactive: "Inactive",
  no_products: "No products found",
  add_product_name: "Add product name",
  add_product_description: "Add product description",
  add_product_price: "Add product price",
  choose_categories: "Choose categories",
  choose_sizes: "Choose sizes",
  success_add_product: "Product was successfully added!",
  fail_add_product: "Product wasn't successfully added!",
  success_update_product: "Product was successfully updated!",
  fail_update_product: "Product wasn't successfully updated!",
  success_delete_product: "Product was successfully deleted!",
  fail_delete_product: "Product wasn't successfully deleted!",

  //Categories
  add_category: "Add category",
  edit_category: "Edit category",
  category_image: "Category photo",
  category_name: "Category name",
  add_category_name: "Add category name",
  category_actions: "Actions",
  category_is_active: "Category is active",
  category_delete_confirmation: "Delete confirmation",
  category_delete_message:
    "Are you sure you want to delete this category? The action is irreversible.",
  category_edit: "Edit category",
  no_categories: "No found categories",
  success_add_category: "Category was successfully added!",
  fail_add_category: "Category wasn't successfully added!",
  success_update_category: "Category was successfully updated!",
  fail_update_category: "Category wasn't successfully updated!",
  success_delete_category: "Category was successfully deleted!",
  fail_delete_category: "Category wasn't successfully deleted!",
  without_image: "Without photo",

  //Orders
  order_id: "ID",
  order_date: "Order date",
  order_customer: "Customer",
  order_shipping_method: "Shipping method",
  order_payment_method: "Payment method",
  order_status: "Status",
  order_amount: "Amount",
  order_actions: "Actions",
  order_details: "Order details",
  order_product: "Product",
  order_quantity: "Quantity",
  order_unit_price: "Unit price",
  order_wrapper: "Wrapper",
  order_vat: "VAT",
  order_subtotal: "Subtotal",
  order_phone: "Phone number",
  order_mail: "E-mail",
  order_address: "Address",
  order_message: "Order message",
  order_payment_type: "Payment type",
  order_delivery_cost: "Delivery cost",
  order_cancel: "Anulare comanda",
  order_cancel_message:
    "Are you sure you want to cancel the order? Please state your reason below:",
  order_cancel_reason: "Reason...",
  order_cancel_dismiss: "Dismiss",
  order_delete_alert: "You must cancel the order before it can be deleted",
  no_orders: "No orders found",
  order_details: "Order details",
  order_delivery_types: {
    delivery: "Delivery",
    pickup: "Pickup",
  },
  order_payment_types: {
    online: "Online",
    cash: "Cash",
  },
  confirm_order: "Confirmed",
  cancel_order: "Cancel",
  close_order: "Close",
  deliver_order: "Delivered",
  order_delete_message:
    "Are you sure you want to delete this order? The action is irreversible.",
  success_delete_order: "Order was successfully deleted!",
  fail_delete_order: "Order wasn't successfully deleted!",
  success_confirm_order: "Order was successfully confirmed!",
  fail_confirm_order: "Order wasn't successfully confirmed!",
  success_cancel_order: "Order was successfully canceled!",
  fail_cancel_order: "Order wasn't successfully canceled!",
  success_deliver_order: "Order was successfully delivered!",
  fail_deliver_order: "Order wasn't successfully delivered!",

  //Sizes
  sizes: "Sizes",
  size: "Size",
  add_size: "Add size",
  edit_size: "Edit size",
  size_delete_message:
    "Are you sure you want to delete this size? The action is irreversible.",
  no_sizes: "No sizes found",
  add_size_name: "Add size",
  success_add_size: "Size was successfully added!",
  fail_add_size: "Size wasn't successfully added!",
  success_update_size: "Size was successfully updated!",
  fail_update_size: "Size wasn't successfully updated!",
  success_delete_size: "Size was successfully deleted!",
  fail_delete_size: "Size wasn't successfully deleted!",

  // Months
  month_1: "january",
  month_2: "february",
  month_3: "march",
  month_4: "april",
  month_5: "may",
  month_6: "june",
  month_7: "july",
  month_8: "august",
  month_9: "september",
  month_10: "october",
  month_11: "november",
  month_12: "december",

  //=========================================================================================================== Old translations
  test: "test",
  Name: "Name",
  player_contracts: "Player contracts",
  sponsor_contracts: "Sponsor contracts",
  contracts: "Contracts",
  bonuses: "Bonuses",
  add_new_file: "Add new file",
  add_document: "Add document",
  player_contract: "Player contract",
  add_player_contract: "Add player contract",
  file_name: "File name",
  category: "Category",
  date_add: "Date added",
  added_by: "Added by",
  new_member: "New Member",
  new_report: "New Report",
  players: "Players",

  budget: "Budget",
  add_new_member: "Add new member",

  cashflow: "Cashflow",
  user_profile: "User Profile",
  no_club: "No club",
  no_team: "No team",
  sign_out: "Sign out ",
  find_a_player: "Find a player",
  position: "Position",
  Nationality: "Nationality",
  League: "League",
  Age: "Age",
  Reset_filters: "Reset filters",
  Advanced_search: "Advanced search ",
  Add_to_My_List: "Add to my list ",
  transactions: "Transactions",

  Order_by: "Order by",
  Discover_more: "Discover More ",
  My_list: "My list",
  Moneda: "Currency",
  Cashflow: "Cashflow",
  There_are_no_records_to_display: "There are no records to display ",
  Bonuses: "Bonuses",
  PLAYER_NAME: "Player name",
  NATIONALITY: "Nationality",
  POSITION: "Position",
  AGE: "Age",
  MARKET_VALUE: "Market value",
  Currency: "Currency",
  Cancel: "Cancel",
  Add: "Add",
  PROFIT: "PROFIT",
  SPENDING: "Spending",
  Add_category_to: "Add category to",
  Players: "Players",
  CashFlow: "Cashflow",
  transfer_history: "Transfer history",
  finances: "Finances",
  overview: "Overview",
  current_season: "Current season",
  advanced_standings: "Advanced standings",
  fixtures_schedule: "Fixtures",
  team_statistics: "Team statistics",
  player_search: "Player search",
  search: "Search...",
  show: "Display",
  extra_notes: "Extra notes:",
  choose_categ: "Choose category",
  new_categ: "New category name...",
  active_crtierias: "Active criterias:",
  Nationality: "Nationality",
  League: "League",
  Position: "Position",
  see_results: "See results",
  test_name: "Test name",
  evaluation_date: "Evaluation date",
  evaluation_year: "Year",
  see_test_details: "See test details",
  players_eval: "Evaluated players",
  latest_reports: "Latest reports",

  team_type: "Type",
  team_name: "Team name",
  number_players: "Number of players",
  see_more: "See more",

  Login_Account: "Login Account",
  Welcome_to: "Welcome to",
  Privacy: "Privacy",
  Legal: "Legal",
  Contact: "Contact",
  Enter_your_username_and_password: "Enter your username and password",

  Sign_In: "Sign In",
  Dont_have_an_account_yet: "Don't have an account yet?",
  Sign_Up_1: "Sign Up!",
  Enter_your_details_to_create_your_account:
    "Enter your details to create your account",
  Full_name: "Full name",
  Email: "Email",
  User_name: "User name",
  Password: "Password",
  Confirm_Password: "Confirm Password",
  I_aggree_with_terms_and_conditions: "I agree the Terms & Conditions",
  Choose_image: "Choose image",
  image: "Image",
  video: "Video",
  videos: "Videos",
  Submit: "Submit",
  Required_field: "Required field",
  Password_and_Confirm_Password_didnt_match:
    "Password and Confirm Password didn't match",

  The_login_detail_is_incorrect: "The login detail is incorrect",

  Add_team_bonus: "Add team bonus",
  Add_players_bonus: "Add players bonus",
  Bonus_history: "Bonus history",
  Total_sum: "Total amount",
  Bonus_name: "Bonus name",
  Bonus_type: "Bonus Type",
  Bonus_condition: "Bonus Condition",
  Payment_status: "Payment status",
  Date_added: "Date added",
  EUR: "EUR",
  RON: "RON",
  Fullfiled: "Fulfilled",
  In_pending: "Pending",
  Paid: "Paid",
  Delete: "Delete",
  Change_status: "Change status",
  Add_bonus: "Add bonus",
  For_a_match: "For a match",
  Selected_step: "Selected match week",
  Totol_assigned_sum: "Total assigned sum",
  Back: "Back",
  Bonus_beneficiary: "Bonus beneficiary",
  Competition: "Competition",
  For_the_full_season: "For the entire season",
  Select_match: "Select match",
  Assignment_criteria: "Assignment criteria",
  Bonus_position: "Reach the first ... places",
  All_competitions: "All competitions",
  placeholder_documents: "No documents uploaded yet",

  Assignment_criterias: "Criterias",
  Select_bonus_type: "Bonus type",
  select_cup_phase: "Cup phase...",

  Ranking: "Position",
  Custom_duration: "Custom duration",

  Ranking_the_first_n_places: "Ranking in the first ... positions",
  Qualification_in_the_play_off: "Reach play-off",
  Ranking_for_european_cup: "Placement on European Cups position",
  Ranking_for_uefa_champions_league:
    "Placement on UEFA Champions League position",
  Qualification_in_the_romanian_cup: "Placement within Romanian Cup",
  Competiion_final: "Final",
  Competiion_semi_final: "Semifinal",
  Competiion_quarters_final: "Quarter-final",
  Competiion_eighths_final: "Last 16",
  Competiion_sixteeths_final: "Last 32",
  All_players: "All players",
  Player_holders: "Starting lineup",
  Player_and_Staff: "Players & Staff",
  Individual: "Individual",
  First_league: "First league",
  Romanian_Cup: "Romanian Cup",
  Romanian_supercup: "Romanian Supercup",
  Goal_scored: "Goal scored",
  Other_crieteria: "Other criteria",
  Staff_bonus: "Staff",
  Another_bonus: "Another receiver",

  Final_result_win: "Final result: Win",
  Final_result_draw: "Final result: Draw",
  Next: "Next",

  Add_contract_details: "Contract details",
  Contract_extra: "Eextra conditions",
  Upload_file: "Upload file",
  player_name: "Player name",
  contract_period: "Contract period",
  Other: "Other",
  choose_team: "Choose team",
  team: "Team",
  average_rating: "Average rating",
  show_on_loan: "Players on loan",

  one_year: "One year",
  one_year_half: "One year and a half",
  two_years: "Two years",
  two_years_half: "Two years and a half",
  three_years: "Three years",
  three_years_half: "Three years and a half",
  four_years: "Four years",
  four_years_half: "Four years and a half",
  five_years: "Five years",
  five_years_half: "Five years and a half",

  sure_delete_event:
    "Are you sure you want to delete the event from the match ?",

  add_new_event: "Add new event",
  add_event: "Add event",
  event_name: "Event name",
  event_category: "Select category",
  starting_date: "Starting date",
  ending_date: "Ending date",
  month: "Month",
  week: "Week",
  day: "Day",
  today: "Today",
  new_categ_name: "New category name",
  club_management: "Club management",
  select_team: "Select team",
  physical_reports: "Physical reports",
  technical_reports: "Technical reports",
  teams: "Teams",
  see_profile: "See profile",

  contract_details: "Contract details",
  active_contract: "Active contract",
  active_bonuses: "Active bonuses",
  expiry_date: "Expiration date",
  monthly_wage: "Monthly wage",
  placeholder_bonuses: "No bonuses added",

  name: "Name",
  partner_name: "Partner name",
  unpaid_amount: "Left to pay",
  paid_amount: "Paid amount",
  last_payment: "Last payment date",
  deadline_date: "Deadline date",
  medical: "Medical",
  injuries: "Injuries",

  ["Players & Staff"]: "Players & Staff",
  ["Starting lineup"]: "Starting lineup",
  ["All the players"]: "All the players",

  your_currency: "Selected currency",
  this_will_overwrite_your_preferred_currency:
    "The current action will change the selected currency from your account settings. Are you sure?",
  Confirm: "Confirm",

  ["Left shoulder"]: "Left shoulder",
  ["Right shoulder"]: "Right shoulder",
  ["Left knee"]: "Left knee",
  ["Right knee"]: "Right knee",
  ["Left thigh"]: "Left thigh",
  ["Right thigh"]: "Right thigh",
  ["Left shin"]: "Left shin",
  ["Right shin"]: "Right shin",
  ["Back"]: "Back",
  ["Head"]: "Head",
  ["Left ankle"]: "Left ankle",
  ["Right ankle"]: "Right ankle",
  ["Another zone"]: "Another zone",

  ["Developer"]: "Developer",
  ["Team manager"]: "Team manager",
  ["Antrenor"]: "Antrenor",
  ["Antrenor cu portarii"]: "Antrenor cu portarii",
  ["Presedinte"]: "Presedinte",
  ["Preparator fizic"]: "Preparator fizic",
  ["Manager IT"]: "Manager IT",
  ["Manager Academia Poli Iasi"]: "Manager Academia Poli Iasi",

  ["Goalkeeper"]: "Goalkeeper",
  ["Centre-Back"]: "Centre-Back",
  ["Left-Back"]: "Left-Back",
  ["Right-Back"]: "Right-Back",
  ["Defensive Midfield"]: "Defensive Midfield",
  ["Central Midfield"]: "Central Midfield",
  ["Right Midfield"]: "Right Midfield",
  ["Left Midfield"]: "Left Midfield",
  ["Attacking Midfield"]: "Attacking Midfield",
  ["Left Winger"]: "Left Winger",
  ["Right Winger"]: "Right Winger",
  ["Second Striker"]: "Second Striker",
  ["Centre-Forward"]: "Centre-Forward",
  ConnectionCannotBeRefreshed:
    "The next refresh attempt will be allowed according to next refresh possible time or connection’s status is inactive.",
  cur_: "",
  cur_Th: "T",
  cur_M: "M",
  cur_B: "B",
  cur_T: "T",

  "All documents": "All documents",
  "Players contracts": "Players contracts",
  "Sponsor contracts": "Sponsor contracts",

  //start
  Remove_from_my_list: "Remove from my list",
  Budget: "Budget",
  fast_search: "Fast search",
  video_search: "Search video by name",
  total: "Total",
  auth_page_left_description: "",
  no_item: "No item.",
  Coach: "Coach",
  "Goalkeepers coach": "Goalkeepers coach",
  President: "President",
  "Physical trainer": "Physical trainer",
  "IT Manager": "IT Manager",
  "Poli Iasi Academy Manager": "Poli Iasi Academy Manager",
  Bills: "Facturi",
  Various: "Diverse",
  "Training scheme": "Training scheme",

  left_shoulder: "Left shoulder",
  right_shoulder: "Right shoulder",
  left_knee: "Left knee",
  right_knee: "Right knee",
  left_thigh: "Left thigh",
  right_thigh: "Right thigh",
  left_shin: "Left shin",
  right_shin: "Right shin",
  back: "Back",
  head: "Head",
  left_ankle: "Left ankle",
  right_ankle: "Right ankle",

  active_teams: "Active teams",
  ranking_position: "Standing position",
  no_teams_yet: "The club has no teams",
  staff_members: "Staff members",
  staff_member_name: "Name",
  staff_member_role: "Role",
  no_staff_members_yet: "No staff members found",
  team_coach: "Coach",
  "GENERAL.SOMETHING_WENT_WRONG":
    "Ups! Something went wrong. Please try again later.",
  Error: "Error",

  Back_button: "Back",

  FORM_CONTROL: {
    "TRANSLATOR.SELECT": "Select your language",
    "MENU.NEW": "new",
    "MENU.ACTIONS": "Actions",
    "MENU.CREATE_POST": "Create New Post",
    "MENU.PAGES": "Pages",
    "MENU.FEATURES": "Features",
    "MENU.APPS": "Apps",
    "MENU.DASHBOARD": "Dashboard",
    "AUTH.GENERAL.OR": "Or",
    "AUTH.GENERAL.SUBMIT_BUTTON": "Submit",
    "AUTH.GENERAL.NO_ACCOUNT": "Don't have an account?",
    "AUTH.GENERAL.SIGNUP_BUTTON": "Sign Up",
    "AUTH.GENERAL.FORGOT_BUTTON": "Forgot Password",
    "AUTH.GENERAL.BACK_BUTTON": "Back",
    "AUTH.GENERAL.CANCEL_BUTTON": "Cancel",
    "AUTH.GENERAL.PRIVACY": "Privacy",
    "AUTH.GENERAL.LEGAL": "Legal",
    "AUTH.GENERAL.CONTACT": "Contact",
    "AUTH.LOGIN.TITLE": "Login Account",
    "AUTH.LOGIN.BUTTON": "Sign In",
    "AUTH.FORGOT.TITLE": "Forgotten Password?",
    "AUTH.FORGOT.DESC": "Enter your email to reset your password",
    "AUTH.FORGOT.SUCCESS": "Your password has been successfully reset.",
    "AUTH.REGISTER.TITLE": "Sign Up",
    "AUTH.REGISTER.DESC": "Enter your details to create your account",
    "AUTH.REGISTER.SUCCESS": "Your account has been successfuly registered.",
    "AUTH.INPUT.EMAIL": "Email",
    "AUTH.INPUT.FULLNAME": "Fullname",
    "AUTH.INPUT.PASSWORD": "Password",
    "AUTH.INPUT.CONFIRM_PASSWORD": "Confirm Password",
    "AUTH.INPUT.USERNAME": "Username",
    "AUTH.VALIDATION.INVALID": "{name} is not valid",
    "AUTH.VALIDATION.REQUIRED": "{name} is required",
    "AUTH.VALIDATION.MIN_LENGTH": "Email minimum length is 3",
    "AUTH.VALIDATION.MAX_LENGTH": "Email max length is 50",
    "AUTH.VALIDATION.AGREEMENT_REQUIRED":
      "Accepting terms & conditions are required",
    "AUTH.VALIDATION.NOT_FOUND": "The requested {name} is not found",
    "AUTH.GENERAL.USER_NOT_FOUND": "User not found",
    "AUTH.GENERAL.USER_INACTIVE": "User is inactive",
    "AUTH.VALIDATION.INVALID_LOGIN": "The login detail is incorrect",
    "AUTH.VALIDATION.INVALID_EMAIL": "Email invalid",
    "AUTH.GENERAL.SOMETHING_WENT_WRONG":
      "Ups! Something went wrong. Please try again later.",
    "AUTH.VALIDATION.NO_USER_WITH_EMAIL":
      "The email is not used by any active user",
    "AUTH.VALIDATION.REQUIRED_FIELD": "Required field",
    "AUTH.VALIDATION.MIN_LENGTH_FIELD": "Minimum field length:",
    "AUTH.VALIDATION.MAX_LENGTH_FIELD": "Maximum field length:",
    "AUTH.VALIDATION.INVALID_FIELD": "Field is not valid",
    "ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT": "Selected records count: ",
    "ECOMMERCE.COMMON.ALL": "All",
    "AUTH.RESET_PASSWORD.TITLE": "Reset Password",
    "AUTH.RESET_PASSWORD.TOKEN_EXPIRED": "Token expired",
    "AUTH.GENERAL.EMPTY_TOKEN": "Token is empty",
    "ECOMMERCE.COMMON.SUSPENDED": "Suspended",
    "ECOMMERCE.COMMON.ACTIVE": "Active",
    "ECOMMERCE.COMMON.FILTER": "Filter",
    "ECOMMERCE.COMMON.BY_STATUS": "by Status",
    "ECOMMERCE.COMMON.BY_TYPE": "by Type",
    "ECOMMERCE.COMMON.BUSINESS": "Business",
    "ECOMMERCE.COMMON.INDIVIDUAL": "Individual",
    "ECOMMERCE.COMMON.SEARCH": "Search",
    "ECOMMERCE.COMMON.IN_ALL_FIELDS": "in all fields",
    "ECOMMERCE.ECOMMERCE": "eCommerce",
    "ECOMMERCE.CUSTOMERS.CUSTOMERS": "Customers",
    "ECOMMERCE.CUSTOMERS.CUSTOMERS_LIST": "Customers list",
    "ECOMMERCE.CUSTOMERS.NEW_CUSTOMER": "New Customer",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.TITLE": "Customer Delete",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.DESCRIPTION":
      "Are you sure to permanently delete this customer?",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.WAIT_DESCRIPTION":
      "Customer is deleting...",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.MESSAGE":
      "Customer has been deleted",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.TITLE": "Customers Delete",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.DESCRIPTION":
      "Are you sure to permanently delete selected customers?",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.WAIT_DESCRIPTION":
      "Customers are deleting...",
    "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.MESSAGE":
      "Selected customers have been deleted",
    "ECOMMERCE.CUSTOMERS.UPDATE_STATUS.TITLE":
      "Status has been updated for selected customers",
    "ECOMMERCE.CUSTOMERS.UPDATE_STATUS.MESSAGE":
      "Selected customers status have successfully been updated",
    "ECOMMERCE.CUSTOMERS.EDIT.UPDATE_MESSAGE": "Customer has been updated",
    "ECOMMERCE.CUSTOMERS.EDIT.ADD_MESSAGE": "Customer has been created",
  },

  verification_code: "Verification code",
  insert_verification_code:
    "Insert the 6-digit code sent to the phone number associated with this account",
  verification_code_no_receive_message: "Did not receive message?",
  verification_code_send_again: "Send again",

  auth_code_expired: "Authentication code expired",
  auth_code_incorrect: "Authentication code incorrect",
  auth_code_try_again: "Authentication error. Please try again.",

  team_types_list: "Team types",
  team_type_add: "Add new type",
  team_type_add_short: "Add type",

  team_type_notification_title: "Club team types",
  team_type_notification_succes: "Team type added successfully!",
  team_type_notification_fail: "Selected type has been successfully removed!",

  tm_link_team: "Transfermarkt link (optional)",
  minutes_played_uppercase: "Minutes played",
  all_fields_mandatory_error: "All the fields are mandatory",
  no_sections_added_error: "You must add at least one training section",
  rec_date_not_before_inj_date: "Recovery date can't be before injury date",
  documents_upload_success: "You have added a new file!",
  video_upload_success: "You have added a new video!",
  video_upload_failed: "Your video must be up to 125 MB in size to upload!",
  video_delete_success: "Your video was successfully deleted!",
  video_operation: "Video operation",
  ACCESS_FORBIDDEN_ERROR: "Access forbidden",
  new_category_empty_error: "New category name can't be empty",
  delete_team: "Delete team",
  season_date_start_add_team: "Current season start date",
  season_date_end_add_team: "Current season end date",
  deleteTeamMessage: "Are you sure you want to delete this team?",
  deleteTeamMessage2: "All players and related data will be lost.",
  add_optimal_weight: "Modify optimal weight",

  auth_error_title: "Authentication error",
  auth_error_message: "Please check the entered data and try again",
  reset_error_title: "Reset password error",
  hello: "Hello",
  password_reset_email: "Your new password is",

  import_fans_title: "Import data about fans",
  select_fields_to_save: "Select the fields to be saved",
  enter_source_name: "Enter the source name",
  enter_delimiter: "Enter the delimiter",
  sample_name_fans_file: "e.g: Newsletter subscribers",
  associated_with: "Associated with",
  activity_date: "Date of the activity",
  column: "Colomn",
  social_media_activity: "Social media activity",

  // Costs

  add_transaction: "Add transaction",
  select_cost_category: "Select cost category",
  add_new_cost_category: "Add new cost category",
  edit_departaments_list: "Edit departments list",
  add_departament: "Add department",
  edit_departament: "Edit department",
  department_name: "Department name",
  add_spending: "Add spending",
  department: "Department",
  subdepartment: "Subdepartment",
  period: "Period",
  tags: "Tags",
  date_format: "dd/mm/yyyy",
  player: "Player",
  apply_filters: "Apply filters",
  departments_list: "Departments list",
  has_subdepartments: "Has subdepartments",
  subdepartment_designation: "Subdepartment name",
  add_new_subdepartment: "Add new substartment",
  add_tag: "Add tag",
  entity_tag: "entity tag",
  staff: "Staff",
  select: "Select",
  select_department: "Select department",
  select_subdepartment: "Select subdepartment",
  select_tag_type: "Select tag type",
  no_costs: "No costs found",
  cancel_filters: "Cancel filters",
  success_add_department: "Department was successfully added!",
  fail_add_department: "Department wasn't successfully added!",
  no_departments: "No departments found",
  success_update_department: "Department was successfully updated!",
  fail_update_department: "Department wasn't successfully updated!",
  success_delete_department: "Department was successfully deleted!",
  fail_delete_department: "Department wasn't successfully deleted!",
  department_delete_message:
    "Are you sure you want to delete this department? The action is irreversible.",
  success_add_cost: "New cost was successfully added!",
  fail_add_cost: "New cost wasn't successfully added!",
  success_update_cost: "Cost was successfully updated!",
  fail_update_cost: "Cost wasn't successfully updated!",
  cost_delete_message:
    "Are you sure you want to delete this cost? The action is irreversible.",
  success_delete_cost: "Cost was successfully deleted!",
  fail_delete_cost: "Cost wasn't successfully deleted!",
  delete_cost: "Delete cost",
  delete_cost_message:
    "The action is irreversible. Are you sure you want to delete the cost ",

  // necessity reports
  add_necessity_report: "Add report",
  criteria_number: "Criteria number",
  registration_number: "Registration number",
  purchase_category: "Purchase category",

  // receipt
  download_receipt: "Download receipt",
  no_data_receipt: "There are no online payments.",

  // mobile app admin
  add_parent: "Add parent",
  edit_player: "Edit player",
  edit_relative: "Edit relative",
  player_players_name: "Player/Players name",
  current_status_payment: "Recurrent payment status",
  first_name: "First name",
  last_name: "Last name",
  player_relative: "Player relative",
  notif_edit_external_user: "Edit mobile user",
  edit_user_success_message: "User has been edited successfully !",
  edit_user_error_message: "User has not been edited !",
  delete_relative: "Delete relative",
  delete_mobile_user_text:
    "Are you sure you want to delete this user? All related data will be lost.",
  notif_delete_mobile_user_title: "Delete mobile user",
  notif_delete_mobile_user_message: "User has been deleted successfully !",
  notif_delete_mobile_user_failed_message: "User has not been deleted!",
  desactivate_user: "Desactivate mobile user",
  desactivate_user_popup_text: "Are you sure you want to disable this user?",
  desactivate: "Desactivate",
  activate_user: "Activate mobile user",
  activate_user_popup_text: "Are you sure you want to activate this user?",
  activate: "Activate",

  notif_desactivate_mobile_user_success_message:
    "User has been successfully deactivated!",
  notif_desactivate_mobile_user_failed_message:
    "User has not been deactivated !",
  notif_activate_mobile_user_success_message:
    "User has been successfully activated!",
  notif_activate_mobile_user_failed_message: "User has not been activated !",

  // users notifications
  next_step: "Next step",
  previous_step: "Previous step",
  send_notification: "Send notification",
  create_notification: "Create notification",
  sent_notification: "Sent notification",
  date_hour_of_send: "Date and hour of send",
  notification_send_by: "Send by",
  notification_title: "Title of notification",
  notification_message: "Notification message",
  notification_group: "Group",
  plyers_names: "Players names",
  audience: "Audience",
  delete_notification: "Delete notification",
  delete_notification_message:
    "Are you sure you want to delete this notification?",
  details_notification_sent: "Details notification sent",
  create_notification: "Create notification",
  step_one_selecte_audience: "Step 1 - select audience",
  all_groups: "All groups",
  step_two_select_audience: "Step 2 - write message",
  your_message: "Your message",

  notification_title_message: "User notification",
  notification_sucess_message: "Notification was successfully sent !",
  notification_failed_message: "Notification sending has failed !",
  notification_delete_sucess_message: "Notification was successfully deleted !",
  notification_delete_failed_message: "Notification deleting has failed !",

  // ----------------------------------Payments

  date_hour_of_payment: "Date and time of payment",
  payment_month: "Payment month",
  payment_month_description: "Payment month / Description",
  payer: "Payer",
  payment_method: "Payment Method",
  payments_title: "Payments",
  table_name: "Name",
  table_months: {
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December",
  },
  add_payment_title: "Add payment for player ",
  add_payment_select_month: "Select month",
  add_payment_amount: "Amount",
  add_payment_type: {
    cash: "Cash",
    bank_transfer: "Bank transfer",
  },
  add_payment_date: "Payment date",
  add_payment_description: "Description (optional)",
  add_payment_button_add: "Add payment",
  add_payment_button_cancel: "Cancel",
  edit_payments_title: "Edit payments for player ",
  edit_payments_empty: "There no payments.",
  add_payment_overwrite_first:
    "Are you sure that you want to overwrite data about payment in ",
  add_payment_overwrite_second: " for player ",
  add_payment_button_overwrite: "Yes, overwrite payment",
  delete_payment_question: "Are you sure that you want to delete the payment?",
  delete_payment_button_delete: "Yes, delete",
  payment_successfully_added: "Payment added successfully!",
  payment_successfully_deleted:
    "The selected payment has been successfully removed!",

  //Match - notification message
  matches_module: "Matches",
  success_save_players_rating: "Players rating was successfully saved!",
  fail_save_players_rating: "Players rating wasn't successfully saved!",
  the_match_lot_was_successfully_saved:
    "The match squad was successfully saved!",
  fail_the_match_lot_was_successfully_saved:
    "The match squad was not successfully saved!",
  the_player_has_been_removed_from_the_squad:
    "The player has been removed from the squad !",
  fail_the_player_has_been_removed_from_the_squad:
    "The player has not been removed from the squad !",
  players_successfully_added_to_the_squad:
    "Players successfully added to the squad !",
  players_failed_added_to_the_squad: "Players failed added to the squad !",

  //Receipt
  success_save_receipt: "The receipt was successfully modified!",
  fail_save_receipt: "The receipt wasn't successfully modified!",
  success_delete_receipt: "The receipt was successfully deleted!",
  fail_delete_receipt: "The receipt wasn't successfully deleted!",

  num_and_date_receipt: "Number and issue date",
  edit_receipt: "Edit receipt",
  series: "Series",
  receipt_number: "Receipt number",
  issue_date: "Issue date",
  payer_name: "Payer name",
  receipt_description: "Receipt description",
  delete_receipt: "Delete receipt",
  delete_receipt_message:
    "Are you sure that you want to delete the receipt? The action is irreversible.",
  delete_document_message:
    "Are you sure that you want to delete the document? The action is irreversible.",

  // STATISTICS GOALS
  goals_statistics: "Golas statistics",
  show_statistics: "Show stats",
  invalid_period_time: "Start date must be greater than end date",

  free_kick: "Free Kick",
  cross: "Cross",
  direct_play: "Direct Play",
  combination_play: "Combination",
  counterattack: "Counterattack",
  penalty: "Penalty",
  another: "Others",

  scored_goals_first_half: "Goals first half",
  scored_goals_second_half: "Goals second half",
  conceded_goals_first_half: "Goals first half",
  conceded_goals_second_half: "Goals second half",

  scored_goals_by_category: "Scoared goals by category",
  conceded_goals_by_category: "Conceded goals by category",
  scored_goals_per_half: "Scored goals / half",
  conceded_goals_per_half: "Conceded goals / half",
  "-": "-",
  no_data_found: "-",

  //WEBSITE SETTINGS
  website_settings: "Website settings",
  teams_settings: "Teams settings",
  staff_settings: "Staff settings",
  online_payments_settings: "Online payments settings",
  enable_online_payments_module: "Enable online payments module on site",
  only_online_payments: "Only online payments page",
  iframe: "Enable <i>iframe</i> mode for online payments page",
  stripe_fee_passed_to_customer: "Stripe fee is passed to customer",
  client_edit_monthly_tax: "Monthly tax can be edited by client",

  payment_types_settings: "Payment types settings",
  enable_another_tax: "Show <i>Another payment</i> option",
  enable_payment_request: "Show <i>Payment requests</i> option",

  enrolment_request_settings: "Enrolment request settings",
  enable_enrolment_form: "Enable enrolment request module on site",
  privacy_policy: "Privacy policy",

  // Enrolment requests
  enrolment_request: "Enrolment request",
  edit_request: "Edit request",
  child_name: "Child name",
  child_surname: "Child surname",
  request_date: "Request date",
  adult_name: "Adult name",
  adult_surname: "Adult surname",
  child: "Child",
  no_requests: "No requests",
  delete_request: "Delete request",
  delete_request_message: "You are sure you want to delete this request?",
  accept: "Accept",
  success_enrolment_request: "Request has been accepted",
  failed_enrolment_request: "Request has not been accepted",

  //PAYMENT REQUEST
  payment_request: "Cereri de plata",
  payers: "Platitori",
  no_payment_request: "There are no payment requests.",

  add_payment_request: "Add payment request",
  add_payment_details: "Enter payment details",
  payment_request_title: "Payment name",
  enter_payment_request_title: "Enter name",
  payment_request_amount: "Payment value",
  enter_payment_request_amount: "Enter value",
  choose_players: "Choose players",
  choose_team: "Select the team",
  all_players: "All players",

  success_add_payment_request: "The payment request was successfully added!",
  fail_add_payment_request: "The payment request was successfully added!",

  success_delete_payment_request:
    "The payment request was successfully deleted!",
  fail_delete_payment_request:
    "The payment request wasn't successfully deleted!",
  delete_payment_request: "Delete payment request",
  delete_payment_request_message:
    "Are you sure that you want to delete the payment request? The action is irreversible.",

  amount_to_pay: "Amount to be collected",
  amount_received: "Suma received",
  payment_types: {
    cash: "cash",
    bank_transfer: "bank tansfer",
    parents_app: "parents app",
    online_website: "online website",
  },
  add_players: "Add players",

  success_add_players_to_payment_request:
    "The players were successfully added!",
  fail_add_players_to_payment_request:
    "The players weren't successfully added!",

  edit_amount_to_pay: "Edit amount to pay",
  edit_payment_request: "Edit payment request",

  add_payment: "Add payment",
  payment_type: "Payment type",
  choose_payment_type: "Choose payment type",
  payment_date: "Payment date",
  choose_payment_date: "Choose payment date",

  success_edit_payment_request_amount: "The amount was successfully updated!",
  fail_edit_payment_request_amount: "The amount wasn't successfully updated!",

  success_add_payment: "The payment was successfully added!",
  fail_add_payment: "The payment wasn't successfully added!",

  delete_player_payment_request: "Delete payment request for player",
  delete_player_payment_request_message:
    "Are you sure that you want to delete the payment request for player? The action is irreversible.",

  // MATCH SQUAD
  match_squad: "Match squad",
  save_match_squad: "Save match squad",
  delete_player_from_match_squad:
    "Are you sure you want to remove this player from the match squad?",

  // Club export inactive/dded players filters

  from: "From...",
  until: "Until...",
  export_inactive_players: "Export inactive players",
  export_added_players: "Export added players",
  export_added_inactive_players: "Export added/inactive players",
  export_player_list: "Export player list",
  born_between: "Born between",
  and_between: "And...",
  select_data_types_included_in_the_list:
    "Select data types included in the list",

  //Players export list
  player_dob: "Date of birthday",
  cnp: "Identity card",
  medical_certificate_validity: "Medical certificate validity",
  export: "Export",
  all_filters: "All filters",
  hide_inactiv_players: "Hide inactiv players",
  legitimation_date: "Legitimation date",
  enrolment_date: "Enrolment date",

  //Player profile

  compare_two_players: "Compare 2 players",
  first_player: "First player",
  second_player: "Second player",
  player_atleast_one_evaluation:
    "The selected player must have at least one rating",

  used_phone_number_by_another_user:
    "The phone number already exists for another user !",
  used_phone_number_by_another_player:
    "The phone number already exists for another player !",

  insert_shirt_number: "Insert shirt number",

  // competitions
  select_age_category: "Select age category",

  //PRIVACY POLICY
  no_privacy_policy: "No privacy policy found",
  success_save_privacy_policy: "Privacy policy was successfully saved.",

  edit_age_group_competition: "Edit age group competition",
  add_age_group_competition: "Add grupa de vârstă a competiției",
  age_group_name: "Group name",
  competition_success_added: "Competition was successfully added",
  additional_info_generate_raport: "Additional report generation information",
  effective_duration: "Effective duration",
  delete_staff_member: "Delete staff member",
  delete_staff_member_text:
    "Are you sure you want to remove this staff member? The action is irreversible.",
  training_session_successfully_deleted:
    "The training session was successfully deleted !",
  training_session_failed_deleted:
    "Sesiunea de antrenament nu a fost ștearsă !",
  delete_training: "Delete training session",
  delete_training_text:
    "Are you sure you want to delete this training? All related data will be lost.",

  //RPE TRAINING
  rpe_details_saved_successfully:
    "RPE training details have been successfully saved !",
  rpe_details_saved_failed: "RPE training details have NOT been saved!",
  show_trainings: "Show trainings",
  show_training: "Show training",
  see_presence_list: "See attendance sheet",
  save_data: "Save data",
  rpe_before: "RPE before",
  rpe_after: "RPE after",
  global_accumulation: "Global accumulation",
  time_min: "Time",
  variability_between_workouts: "Variability between workouts",
  injuruy_general: "Injury",
  kg_before: "Kg before",
  kg_after: "Kg after",
  training_intensity: "Training intensity",

  add_player_to_future_training_sessions:
    "Add the player to the team's already scheduled future training sessions ",
  delete_player_from_training:
    "Are you sure you want to remove the player from this training? All related data will be lost.",
  player_deleted_successfully: "Player deleted successfully",
  player_deleted_failed: "Player deleted failed from training",
  delete_section: "Delete section",
  delete_section_text:
    "Are you sure you want to delete this section? All related data will be lost.",
  section_deleted_successfully: "The section has been successfully deleted!",
  section_deleted_failed: "The section has NOT been deleted!",
  start_date_greater_than_end_date: "Start date must be greater than end date",
  no_attendances_in_the_selected_period:
    "There are no attendances in the selected period",
  download_in_format: "Download in format",
  match_and_date_of_the_event: "Match and date of the event",
  payments_made: "Payments made",
  total_paid: "Total paid",
  remaining_payment: "Remaining payment",
  bonus_value: "Bonus value",
  there_are_no_records: "There are no records.",

  necessity_report_suscces: "The necessity report was added with suscces!",
  necessity_report_fail: "The necessity report has not been added!",
  registration_number: "Registration number",
  date_of_referral: "Date of referral",
  add_the_necessity_report: "Add the necessity report",
  delete_necessity_report: "Șterge raport de necesitate",
  delete_necessity_report_text:
    "Are you sure you want to delete this report? All related data will be lost.",
  edit_necessity_report: "Editing of the necessity report",
  approval_disapproval_of_necessity_report:
    "Approval/disapproval of necessity report",
  necessity_report_reason: "Reason ",
  in_case_of_disapproval_is_mandatory_field:
    "(in case of disapproval is mandatory field)",
  add_bonus_active_conditions: "Enter bonus for active conditions",
  contract_operations: "Contract operations",
  bonus_added_successfully: "A new bonus has been successfully added!",
  delete_contract: "Delete contract",
  delete_contract_text:
    "The action is irreversible. Are you sure you want to delete the contract for the player ",
  yes_delete: "Yes, delete",
  delete_contract_staff_text:
    "The action is irreversible. Are you sure you want to delete the contract for staff ",
  delete_bonus: "Delete bonus",
  delete_bonus_text:
    "The action is irreversible. Are you sure you want to delete the bonus?",
  child_birthday: "Child date of birth",
  for_the_department: "For department",
  already_expense_for_match: "there is already an expense tag for the match",

  edit_cost_types: {
    Staff: "Staff",
    Meci: "Match",
    Jucator: "Player",
    Echipa: "Team",
  },
};
