import React from "react";
import { Dropdown, Icon, Button } from "semantic-ui-react";
import Moment from "react-moment";
import axios from "axios";
import { connect } from "react-redux";
import { logout } from "./../../utils/api";
import Avatar from "@material-ui/core/Avatar";
import { bindActionCreators } from "redux";
import ContractBonuses from "./ContractBonuses";
import NumberFormat from "react-number-format";
import * as myConstants from "./../../../app/utils/constants";
import BonusOptionsList from "./BonusOptionsList";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import arrow_down from "../../assets/icons/arrow-down.png";
import arrow_up from "../../assets/icons/arrow-up.png";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye-white.svg";
import AddContract from "./AddPlayerContract";
import EditPlayerContract from "./EditPlayerContract";
import { store } from "react-notifications-component";

class PlayerContracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: [],
      contractDialogOpened: false,
      editContractDialogOpened: false,
      contractToEdit: "",
      contractToEditPlayer: "",
      teams_dropdown: [],
      selected_team: "",
      activeIndexes: [],
      expandedRows: [],
      rates: [],
      bonusListOpened: false,
      sortConfig: {
        type: "",
        descending: false,
      },
      uploaded_rates: false,

      deletePlayerContractOpen: false,
      deletingContract: "",
    };
  }

  componentDidMount = () => {
    this.get_teams();
    // this.getContracts();
    this.getCurrency();
  };

  getContracts = () => {
    axios
      .get(
        `upload/get_contracts?id_team=${this.state.selected_team}&id_club=${this.props.currentUser.id_club}`
      )
      .then((res) =>
        this.setState({ contracts: Array.isArray(res.data) ? res.data : [] })
      )
      .catch((err) => console.log(err));
  };

  get_teams = () => {
    axios
      .get(`upload/get_teams_dropdown`, {
        params: {
          id_club: this.props.currentUser.id_club,
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState({
            teams_dropdown: res.data,
          });
          if (res.data.length > 0) {
            this.setState(
              {
                selected_team: res.data[0].value,
              },
              () => {
                this.getContracts();
              }
            );
          }
        }
      })
      .catch((err) => console.log(err));
  };

  formatNumber = (value, currency) => {
    return (
      <NumberFormat
        value={this.convertCurrency(value, currency)}
        displayType={"text"}
        thousandSeparator={"."}
        decimalSeparator={","}
        decimalScale={2}
        fixedDecimalScale={2}
      />
    );
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ selected_team: value.value }, this.getContracts);
  };

  handleRowClick(rowId) {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowId)
      : currentExpandedRows.concat(rowId);

    this.setState({ expandedRows: newExpandedRows });
  }

  getCurrency = () => {
    fetch("https://api.thefootballbrain.app/club/get_currency_rates")
      .then((res) => res.json())
      .then((data) =>
        this.setState(
          {
            rates: data,
            uploaded_rates: true,
          },
          this.getContracts
        )
      );
  };

  convertCurrency = (value, currency) => {
    const user_currency = this.props.currencyItemSelected.uniqueSlug;
    if (user_currency == currency) {
      return value;
    } else {
      if (user_currency == "EUR") {
        return value / this.state.rates[currency];
      } else {
        return value * this.state.rates[user_currency];
      }
    }
  };

  changeFilter = (type) => {
    if (this.state.sortConfig.type !== type) {
      this.setState({ sortConfig: { type: type, descending: false } });
    } else {
      this.setState({
        sortConfig: {
          type: type,
          descending: !this.state.sortConfig.descending,
        },
      });
    }
  };

  addDefaultSrc = (event) => {
    event.target.src = `https://api.thefootballbrain.app/uploads/club_logos/${this.props.currentUser.id_club}.png`;
  };

  sortData = () => {
    const items = this.state.contracts;
    const sortOption = this.state.sortConfig;

    if (sortOption.type === "wage") {
      items.sort(function(a, b) {
        if (sortOption.descending) return b.monthly_wage - a.monthly_wage;
        else return a.monthly_wage - b.monthly_wage;
      });
    }
    if (sortOption.type === "name") {
      items.sort(function(a, b) {
        return sortOption.descending
          ? b.player_name.localeCompare(a.player_name)
          : a.player_name.localeCompare(b.player_name);
      });
    }
    if (sortOption.type === "date_start") {
      items.sort(function(a, b) {
        if (sortOption.descending)
          return new Date(b.date_start) - new Date(a.date_start);
        else return new Date(a.date_start) - new Date(b.date_start);
      });
    }
    if (sortOption.type === "date_end") {
      items.sort(function(a, b) {
        if (sortOption.descending)
          return new Date(b.date_end) - new Date(a.date_end);
        else return new Date(a.date_end) - new Date(b.date_end);
      });
    } else return items;
  };

  openDeleteDialog = (contract) => {
    this.setState({
      deletePlayerContractOpen: true,
      deletingContract: contract,
    });
  };

  closeDeleteDialog = () => {
    this.setState({
      deletePlayerContractOpen: false,
      deletingContract: "",
    });
  };

  deleteContract = () => {
    axios
      .post(
        "upload/delete_contract",
        { id_contract: this.state.deletingContract.id_contract },
        {
          params: {
            token: this.props.token,
          },
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then(async (res) => {
        if (res.data == 1) {
          this.getContracts();
          this.closeDeleteDialog();
          store.addNotification({
            title: "Document operation",
            message: "Ai sters contractul pentru jucator cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: "Document operation",
            message: "S-a produs o eroare. Incearca din nou!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch(async (err) => {
        return { data: {} };
      });
  };

  render() {
    const iconOsCurrency =
      this.props.currencyItemSelected.uniqueSlug === "EUR" ? (
        <Icon name="eur" color="black" className="mg--0" />
      ) : (
        <span className="font-weight-bold"> RON </span>
      );
    const { languagePack } = this.props;
    this.sortData();

    return (
      <div className="card card-custom">
        <div className="contracts-section">
          <div className="contracts-table-header">
            <div className="contracts-section-title">
              {languagePack["active_contracts"]}
            </div>
            <div className="contracts-section-buttons">
              <Button
                onClick={() => this.setState({ bonusListOpened: true })}
                className="bonus-list-button"
                style={{ backgroundColor: "#666666" }}
              >
                {languagePack["bonuses_list"]}
              </Button>

              <Button
                className="bonus-list-button"
                onClick={() => this.setState({ contractDialogOpened: true })}
                style={{ backgroundColor: "#666666" }}
              >
                {languagePack["add_contract"]}
              </Button>

              <Dropdown
                className="contracts-teams-dropdown"
                placeholder={languagePack["select_team"]}
                options={this.state.teams_dropdown}
                selection
                value={this.state.selected_team}
                onChange={this.handleChangeDropdown}
              />
            </div>
          </div>

          <div className="contracts-list-container">
            <div className="contracts-row-table-column-names">
              <div
                className="contract-player-name"
                onClick={() => this.changeFilter("name")}
                style={{ cursor: "pointer", display: "block" }}
              >
                {languagePack["player_name"]}
                {this.state.sortConfig.type === "name" &&
                  (this.state.sortConfig.descending ? (
                    <Icon name="arrow down" />
                  ) : (
                    <Icon name="arrow up" />
                  ))}
              </div>
              <div className="contract-player-team">{languagePack["team"]}</div>
              <div
                className="contract-date-start"
                onClick={() => this.changeFilter("date_start")}
                style={{ cursor: "pointer" }}
              >
                {languagePack["starting_date"]}
                {this.state.sortConfig.type === "date_start" &&
                  (this.state.sortConfig.descending ? (
                    <Icon name="arrow down" />
                  ) : (
                    <Icon name="arrow up" />
                  ))}
              </div>
              <div
                className="contract-date-end"
                onClick={() => this.changeFilter("date_end")}
                style={{ cursor: "pointer" }}
              >
                {languagePack["ending_date"]}
                {this.state.sortConfig.type === "date_end" &&
                  (this.state.sortConfig.descending ? (
                    <Icon name="arrow down" />
                  ) : (
                    <Icon name="arrow up" />
                  ))}
              </div>
              <div
                className="contract-wage"
                onClick={() => this.changeFilter("wage")}
                style={{ cursor: "pointer" }}
              >
                {languagePack["monthly_wage"]}
                {this.state.sortConfig.type === "wage" &&
                  (this.state.sortConfig.descending ? (
                    <Icon name="arrow down" />
                  ) : (
                    <Icon name="arrow up" />
                  ))}
              </div>
              <div className="contract-options"></div>
            </div>
            {this.state.uploaded_rates ? (
              this.state.contracts.map((row, index) => {
                return (
                  <div key={"player-contract-row-" + index}>
                    <div className="contracts-table-container">
                      <div className="contracts-table-content">
                        <div
                          className="contracts-row-table-column-names"
                          style={{
                            backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                          }}
                        >
                          <div className="contract-player-name">
                            {languagePack["player_name"]}
                          </div>
                          <div className="contract-player-team">
                            {languagePack["team"]}
                          </div>
                          <div
                            className="contract-date-start"
                            onClick={() => this.changeFilter("date_start")}
                            style={{ cursor: "pointer" }}
                          >
                            {languagePack["starting_date"]}
                            {this.state.sortConfig.type === "date_start" &&
                              (this.state.sortConfig.descending ? (
                                <Icon name="arrow down" />
                              ) : (
                                <Icon name="arrow up" />
                              ))}
                          </div>
                          <div
                            className="contract-date-end"
                            onClick={() => this.changeFilter("date_end")}
                            style={{ cursor: "pointer" }}
                          >
                            {languagePack["ending_date"]}
                            {this.state.sortConfig.type === "date_end" &&
                              (this.state.sortConfig.descending ? (
                                <Icon name="arrow down" />
                              ) : (
                                <Icon name="arrow up" />
                              ))}
                          </div>
                          <div
                            className="contract-wage"
                            onClick={() => this.changeFilter("wage")}
                            style={{ cursor: "pointer" }}
                          >
                            {languagePack["monthly_wage"]}
                            {this.state.sortConfig.type === "wage" &&
                              (this.state.sortConfig.descending ? (
                                <Icon name="arrow down" />
                              ) : (
                                <Icon name="arrow up" />
                              ))}
                          </div>
                          <div className="contract-options"></div>
                        </div>
                        <div
                          className="contracts-row"
                          key={row.id_contract}
                          style={{
                            backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                          }}
                        >
                          <div className="contract-player-name">
                            <Avatar
                              src={`${window.baseURL}uploads/players/${row.id_player}.jpg`}
                            />
                            <div
                              className="name"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.props.history.push(
                                  `/club/player/${row.id_player}`
                                )
                              }
                            >
                              {" "}
                              {row.player_name}
                            </div>
                          </div>
                          <div className="contract-player-team">
                            <img
                              alt=""
                              src={`${window.baseURL}uploads/team_logos/${row.id_team}.png`}
                              style={{
                                width: 18,
                                height: 18,
                                marginRight: 5,
                              }}
                              onError={this.addDefaultSrc}
                            />
                            <div className="team">{row.player_team}</div>
                          </div>
                          <div className="contract-date-start">
                            {" "}
                            <Moment format="DD.MM.YYYY">
                              {row.date_start}
                            </Moment>
                          </div>
                          <div className="contract-date-end">
                            {" "}
                            <Moment format="DD.MM.YYYY">{row.date_end}</Moment>
                          </div>
                          <div className="contract-wage">
                            {this.formatNumber(row.monthly_wage, row.currency)}
                            {iconOsCurrency}
                          </div>
                          <div className="contract-options">
                            {row.contract_path != null ? (
                              <a
                                style={{ color: "black" }}
                                className="download-contract"
                                href={`https://api.thefootballbrain.app/upload/download_contract?path=${row.contract_path}`}
                              >
                                <Icon name="download" color="black" />
                              </a>
                            ) : (
                              <div style={{ width: "20px" }}></div>
                            )}
                            <div className="delete-contract">
                              <Icon
                                name="trash alternate"
                                className="delete-contract-icon trash-hover"
                                onClick={() => {
                                  this.openDeleteDialog(row);
                                }}
                              />
                            </div>
                            <div
                              className="contract-see-more-button"
                              onClick={() =>
                                this.setState({
                                  editContractDialogOpened: true,
                                  contractToEdit: row.id_contract,
                                  contractToEditPlayer: row.player_name,
                                })
                              }
                            >
                              <Icon
                                name="edit"
                                className="edit-contract-icon"
                              />
                            </div>
                            <div
                              className="expanded-arrow-button"
                              onClick={() =>
                                this.handleRowClick(row.id_contract)
                              }
                              style={{ marginRight: 10 }}
                            >
                              {this.state.expandedRows.includes(
                                row.id_contract
                              ) ? (
                                <img src={arrow_up} alt="arrow_up" />
                              ) : (
                                <img src={arrow_down} alt="arrow_down" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.expandedRows.includes(row.id_contract) && (
                      <div
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                        }}
                      >
                        <ContractBonuses
                          id_player={row.id_player}
                          id_contract={row.id_contract}
                          rates={this.state.rates}
                          type={"player"}
                          languagePack={languagePack}
                        />
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>

          {/* ADAUGARE CONTRACT JUCATOR */}
          <Dialog
            open={this.state.contractDialogOpened}
            onClose={() => this.setState({ contractDialogOpened: false })}
          >
            <div
              className="bonus-options-list-popup-header"
              style={{ paddingLeft: 50 }}
            >
              <div className="bonus-options-list-title">
                {languagePack["player_contract"]}
              </div>
              <div className="bonus-options-list-close-icon">
                {" "}
                <Icon
                  name="close"
                  onClick={() => this.setState({ contractDialogOpened: false })}
                  size="large"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <DialogContent className="add-file-container-all">
              <AddContract
                refreshFiles={this.getContracts}
                closePopup={() =>
                  this.setState({ contractDialogOpened: false })
                }
              />
            </DialogContent>
          </Dialog>

          {/* EDITARE CONTRACT JUCATOR */}
          <Dialog
            open={this.state.editContractDialogOpened}
            // onClose={() => this.setState({ editContractDialogOpened: false })}
          >
            <div
              className="bonus-options-list-popup-header"
              style={{ paddingLeft: 50 }}
            >
              <div className="bonus-options-list-title">
                {languagePack.edit_player_contract}
              </div>
              <div className="bonus-options-list-close-icon">
                {" "}
                <Icon
                  name="close"
                  onClick={() =>
                    this.setState({
                      editContractDialogOpened: false,
                      contractToEdit: "",
                      contractToEditPlayer: "",
                    })
                  }
                  size="large"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <DialogContent className="add-file-container-all">
              <EditPlayerContract
                refreshFiles={this.getContracts}
                contractId={this.state.contractToEdit}
                playerName={this.state.contractToEditPlayer}
                closePopup={() =>
                  this.setState({
                    editContractDialogOpened: false,
                    contractToEdit: "",
                    contractToEditPlayer: "",
                  })
                }
              />
            </DialogContent>
          </Dialog>
          {/* ADAUGARE CONTRACT JUCATOR FINAL*/}

          <Dialog open={this.state.bonusListOpened} fullWidth maxWidth="sm">
            <DialogContent>
              <div className="bonus-options-list-popup-header">
                <div className="bonus-options-list-title">
                  {languagePack["bonuses_list"]}
                </div>
                <div className="bonus-options-list-close-icon">
                  {" "}
                  <Icon
                    name="close"
                    onClick={() => this.setState({ bonusListOpened: false })}
                    size="large"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <BonusOptionsList />
            </DialogContent>
          </Dialog>

          {/* STERGERE CONTRACT JUCATOR*/}
          <Dialog open={this.state.deletePlayerContractOpen}>
            <div className="bonus-options-list-popup-header">
              <div className="bonus-options-list-title">
                {languagePack.delete_contract}
              </div>
              <div className="bonus-options-list-close-icon">
                {" "}
                <Icon
                  name="close"
                  onClick={this.closeDeleteDialog}
                  size="large"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <DialogContent>
              <div className="delete-bonus-query">
                {languagePack.delete_contract_text}
                <span
                  style={{
                    color: "var(--primaryColor)",
                    textTransform: "uppercase",
                  }}
                >
                  {this.state.deletingContract.player_name}
                </span>
                {"?"}
              </div>
              <div className="delete-bonus-buttons">
                <Button
                  className="delete-bonus-button grey-btn"
                  onClick={this.closeDeleteDialog}
                >
                  <div className="button-content">
                    {languagePack["admin_cancel"]}
                  </div>
                  <Icon name="ban" className="white-icon" />
                </Button>
                <Button
                  className="delete-bonus-button"
                  color="red"
                  onClick={this.deleteContract}
                >
                  <div className="button-content">
                    {languagePack.yes_delete}
                  </div>
                  <Icon name="trash" className="white-icon" />
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyItemSelected:
      myConstants.currencies[auth?.user?.currency] ||
      myConstants.currencies.EUR,
  }),
  mapDispatchToProps
)(PlayerContracts);
