import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { logout } from "./../../../utils/api";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton.tsx";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import { TFBTableCell, sxStickyColumn } from "../../../components/reusable/useStyles";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import AddPaymentRequest from "./AddPaymentRequest";
import DeletePaymentRequest from "./DeletePaymentRequest";
import EditPaymentRequest from "./EditPaymentRequest";

class PaymentRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payment_requests: [],

            add_payment_request_dialog: false,

            edit_payment_request_dialog: false,
            editing_payment_request: {},

            delete_payment_request_dialog: false,
            deleting_payment_request: {},

        };
    }

    componentDidMount = () => {
        this.getPaymentRequests();
    };

    getPaymentRequests = () => {
        axios
            .get(
                `finances/get_payment_request_list?token=${this.props.token}`
            )
            .then((res) => this.setState({ payment_requests: res.data.list }))
            .catch((err) => console.log(err));
    };

    openAddPaymentRequestDialog = () => {
        this.setState({
            add_payment_request_dialog: true,
        })
    }

    closeAddPaymentRequestDialog = () => {
        this.setState({
            add_payment_request_dialog: false,
        })
    }

    openEditPaymentRequestDialog = (data) => {
        this.setState({
            edit_payment_request_dialog: true,
            editing_payment_request: data,
        })
    }

    closeEditPaymentRequestDialog = () => {
        this.setState({
            edit_payment_request_dialog: false,
            editing_payment_request: {},
        })
    }

    openDeletePaymentRequestDialog = (data) => {
        this.setState({
            delete_payment_request_dialog: true,
            deleting_payment_request: data,
        })
    }

    closeDeletePaymentRequestDialog = () => {
        this.setState({
            delete_payment_request_dialog: false,
            deleting_payment_request: {},
        })
    }

    render() {
        const { languagePack } = this.props;

        return (
            <div className="receipt-container">
                <TFBCard>
                    <TFBCardHeader title={languagePack.payment_request}>
                        <TFBButton
                            color="green"
                            renderIcon={() => <TFBIcon name="add" />}
                            onClick={this.openAddPaymentRequestDialog}
                        >
                            {languagePack.add_payment_request}
                        </TFBButton>
                    </TFBCardHeader>
                    <TFBCardBody>
                        {this.state.payment_requests.length > 0 ? (
                            <TableContainer>
                                <Table faria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TFBTableCell sx={sxStickyColumn}>
                                                {languagePack.designation}
                                            </TFBTableCell>
                                            <TFBTableCell align="left">
                                                {languagePack.payers}
                                            </TFBTableCell>
                                            <TFBTableCell align="right">
                                                {languagePack.product_actions}
                                            </TFBTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.payment_requests.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                                            >
                                                <TFBTableCell
                                                    sx={sxStickyColumn}
                                                    style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                                                >
                                                    {row.title}
                                                </TFBTableCell>
                                                <TFBTableCell align="left">
                                                    <div
                                                        className="plan-training-confirmed-presence"
                                                        style={{ justifyContent: "flex-start" }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: row.color,
                                                            }}
                                                            className="plan-training-presence"
                                                        >
                                                            {row.paid_payers}
                                                        </div>{"/"}{row.total_payers}
                                                    </div>
                                                </TFBTableCell>
                                                <TFBTableCell align="center">
                                                    <div className="actions-btns-container" style={{ justifyContent: "flex-end" }}>
                                                        <TFBIconButton
                                                            name="view"
                                                            color="darkGray"
                                                            onClick={() => this.props.history.push(`/financial/payment-request-details/${row.id_payment_request}`)}
                                                        />
                                                        <TFBIconButton
                                                            onClick={() => this.openEditPaymentRequestDialog(row)}
                                                            name="edit"
                                                            color="darkGray"
                                                        />
                                                        <TFBIconButton
                                                            onClick={() => this.openDeletePaymentRequestDialog(row)}
                                                            name="delete"
                                                            color="darkGray"
                                                        />
                                                    </div>
                                                </TFBTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <TFBPlaceholder text={languagePack.no_payment_request} />
                        )}
                    </TFBCardBody>
                </TFBCard>

                {/* ----------------- ADD PAYMENT REQUEST ----------------*/}
                {this.state.add_payment_request_dialog &&
                    <AddPaymentRequest
                        isOpenPopup={this.state.add_payment_request_dialog}
                        closePopup={this.closeAddPaymentRequestDialog}
                        refreshList={this.getPaymentRequests}
                    />
                }

                {/* ----------------- EDIT PAYMENT REQUEST ----------------*/}
                {this.state.edit_payment_request_dialog &&
                    <EditPaymentRequest
                        isOpenPopup={this.state.edit_payment_request_dialog}
                        closePopup={this.closeEditPaymentRequestDialog}
                        refreshList={this.getPaymentRequests}
                        paymentRequestData={this.state.editing_payment_request}
                        id={this.state.editing_payment_request?.id_payment_request}
                    />
                }


                {/* ----------------- DELETE PAYMENT REQUEST ----------------*/}
                {this.state.delete_payment_request_dialog &&
                    <DeletePaymentRequest
                        isOpenPopup={this.state.delete_payment_request_dialog}
                        closePopup={this.closeDeletePaymentRequestDialog}
                        refreshList={this.getPaymentRequests}
                        id={this.state.deleting_payment_request?.id_payment_request}
                    />
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logout,
        },
        dispatch
    );
}
export default connect(
    ({ layoutService, auth }) => ({
        languagePack: layoutService.languagePack,
        currentUser: auth.user,
        token: auth?.user?.token,
    }),
    mapDispatchToProps
)(PaymentRequest);
