import React, { Component } from "react";
import { Button, Icon, Checkbox } from "semantic-ui-react";
import Avatar from "@material-ui/core/Avatar";
import axios from "axios";
import moment from "moment";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import PlayerProfile from "./PlayerProfile";

class PlayerSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      players: [],
      selectedPlayers: [],
      profile: "",
      isFetching: false,
      saveShortlistPopup: false,
      shortlist_name: "",
      idPlayerPopup: "",
    };
  }

  componentDidMount = () => {
    this.getPlayersList();
  };

  getPlayersList = () => {
    const query = new URLSearchParams(this.props.location.search);
    this.setState({ isFetching: true });
    axios
      .get(
        `transferlab/players_list?
        profile=${query.get("profile")}
        &foot=${query.get("foot")}
        &nationality=${query.get("nationality")}
        &ageMin=${query.get("ageMin")}
        &ageMax=${query.get("ageMax")}
        &heightMin=${query.get("heightMin")}
        &heightMax=${query.get("heightMax")}
        &league=${query.get("league")}`
      )
      .then((res) => {
        this.setState({
          players: res.data.players,
          profile: res.data.profile_name,
          isFetching: false,
        });
      })
      .catch((err) => console.log(err));
  };

  handleChangeCheckbox = (id) => {
    if (this.state.selectedPlayers.includes(id)) {
      this.setState({
        selectedPlayers: this.state.selectedPlayers.filter(
          (item) => item != id
        ),
      });
    } else {
      this.setState({
        selectedPlayers: this.state.selectedPlayers.concat(id),
      });
    }
  };

  saveShortlist = () => {
    const query = new URLSearchParams(this.props.location.search);

    const payload = {
      id_club: this.props.currentUser.id_club,
      id_user: this.props.currentUser.id_user,
      shortlist_formation: query.get("formation"),
      shortlist_profile: query.get("profile"),
      playersSelected: JSON.stringify(this.state.selectedPlayers),
      shortlist_name: this.state.shortlist_name,
    };
    axios
      .post("transferlab/save_shortlist", payload)
      .then((res) => {
        this.props.history.push("/scouting/shortlists");
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div className="player-search-page-container">
        <div className="player-search-page-header">
          <div className="ps-title">{this.state.profile}</div>
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <Button
              className="select-all-button"
              style={{ backgroundColor: "#666666" }}
              onClick={() => {
                if (
                  this.state.players.length == this.state.selectedPlayers.length
                )
                  this.setState({ selectedPlayers: [] });
                else {
                  let playersIds = [];
                  for (let i = 0; i < this.state.players.length; i++) {
                    playersIds = playersIds.concat(
                      this.state.players[i].id_player_api
                    );
                  }
                  this.setState({ selectedPlayers: playersIds });
                }
              }}
            >
              {this.state.players.length == this.state.selectedPlayers.length
                ? "Unselect all"
                : "Select all"}
            </Button>
            <Button
              className="save-shortlist-button"
              onClick={() => this.setState({ saveShortlistPopup: true })}
            >
              Save shortlist
              <Icon
                name="check"
                size="small"
                className="shortlist-button-icon"
              />
            </Button>
          </div>
        </div>
        {this.state.isFetching ? (
          <div className="loader-container" style={{ marginTop: 50 }}>
            <Loader
              type="TailSpin"
              color={window.clubCustomization.primary_color}
              height={70}
              width={70}
            />
          </div>
        ) : (
          <div className="player-search-list">
            {this.state.players.map((item, index) => (
              <div className="player-box" key={index}>
                <div className="row-1">
                  <Avatar
                    src={`https://instatscout.com/images/players/180/${item.id_player_instat}.png`}
                    className="player-avatar"
                    onClick={() =>
                      this.setState({ idPlayerPopup: item.id_player_api })
                    }
                  />
                  <Checkbox
                    className="player-checkbox"
                    onChange={() =>
                      this.handleChangeCheckbox(item.id_player_api)
                    }
                    checked={
                      this.state.selectedPlayers.filter(
                        (el) => el === item.id_player_api
                      ).length > 0
                    }
                  />
                </div>
                <div
                  className="row-1"
                  style={{
                    paddingBottom: 20,
                    borderBottom: "solid  1px #f5f5f5",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <div
                    className="player-name"
                    onClick={() =>
                      this.setState({ idPlayerPopup: item.id_player_api })
                    }
                  >
                    {item.player_name}
                  </div>
                  <div className="player-value">{item.quality}</div>
                </div>
                <div className="row-3">
                  <div className="team-badge">
                    <img
                      //src="https://api.thefootballbrain.app/uploads/placeholder_team.png"
                      src={`https://instatscout.com/images/teams/180/${item.id_team_instat}.png`}
                      alt="team-badge"
                    />
                  </div>
                  <div className="country-badge">
                    <img
                      src="https://api.thefootballbrain.app/uploads/placeholder_country.png"
                      alt="country-badge"
                    />
                  </div>
                  <div className="position-age">
                    <div className="position-row">{item.position}</div>
                    <div className="stat-row">
                      <div className="stat-name">Age:</div>
                      <div className="stat-value">
                        {item.player_birthday
                          ? moment().diff(item.player_birthday, "years")
                          : "-"}
                      </div>
                    </div>
                  </div>
                  <div className="mins-height">
                    <div className="stat-row">
                      <div className="stat-name">Mins:</div>
                      <div className="stat-value">2578</div>
                    </div>
                    <div className="stat-row">
                      <div className="stat-name">Height:</div>
                      <div className="stat-value">
                        {item.height > 0 ? item.height : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <Dialog
          open={this.state.saveShortlistPopup}
          // onClose={() => this.setState({ saveShortlistPopup: false })}
          maxWidth="xl"
        >
          <DialogContent>
            <div className="save-shortlist-popup-container">
              <div className="ss-header">
                <div className="ss-title">Save shortlist</div>
                <Icon name="close" size="large" />
              </div>
              <input
                type="text"
                value={this.state.shortlist_name}
                onChange={(e) =>
                  this.setState({ shortlist_name: e.target.value })
                }
                placeholder="Shortlist name"
                className="shortlist-name-input"
              />
              <div className="ss-button-row">
                <Button
                  className="ss-button"
                  style={{ backgroundColor: "#666666" }}
                  onClick={() => this.setState({ saveShortlistPopup: false })}
                >
                  Cancel
                  <Icon name="ban" size="small" className="ss-button-icon" />
                </Button>
                <Button
                  className="ss-button"
                  style={{ backgroundColor: "#00d948" }}
                  onClick={this.saveShortlist}
                >
                  Save
                  <Icon name="check" size="small" className="ss-button-icon" />
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.idPlayerPopup !== ""}
          // onClose={() => this.setState({ idPlayerPopup: "" })}
          maxWidth="xl"
        >
          <DialogContent>
            <PlayerProfile id_player={this.state.idPlayerPopup} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(PlayerSearch);
