import React from "react";
import axios from "axios";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { Icon, Dropdown, Button } from "semantic-ui-react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "../Club.styles.scss";
import "./PlayerProfile.scss";
import { connect } from "react-redux";
import moment from "moment";
import dateFormat from "dateformat";
import Select from "react-select";
import { countries } from "../../../utils/countries";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "react-notifications-component";

class EditNationalTeamCallUpWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      national_team:
        this.props.editing_elem == ""
          ? null
          : countries.find(
              (item) => item.text === this.props.editing_elem.national_team
            ).value,
      action_date: this.props.editing_elem?.action_date
        ? this.props.editing_elem.action_date
        : null,
      competition:
        this.props?.editing_elem != ""
          ? {
              label: this.props.editing_elem.competition_name,
              value: this.props.editing_elem.id_competition,
            }
          : null,
      new_competition_name: "",
      age_group:
        this.props?.editing_elem != ""
          ? {
              label: this.props.editing_elem.age_group,
              value: this.props.age_groups.find(
                (item) => item.label == this.props.editing_elem.age_group
              ).value,
            }
          : null,
      action_type:
        this.props?.editing_elem != ""
          ? {
              label:
                this.props.editing_elem.action_type == "antrenament"
                  ? "Antrenament"
                  : "Meci",
              value:
                this.props.editing_elem.action_type == "antrenament"
                  ? "1"
                  : "0",
            }
          : null,
      action_name:
        this.props?.editing_elem.action_type &&
        this.props?.editing_elem.action_type != "antrenament"
          ? this.props.editing_elem.action_type
          : "",
      own_goals: this.props.editing_elem.own_goals,
      opponent_goals: this.props.editing_elem.opponent_goals,
      played_minutes:
        this.props.editing_elem.played_minutes == "-"
          ? ""
          : this.props.editing_elem.played_minutes,
      scored_goals:
        this.props.editing_elem.scored_goals == "-"
          ? ""
          : this.props.editing_elem.scored_goals,
      assists:
        this.props.editing_elem.assists == "-"
          ? ""
          : this.props.editing_elem.assists,
      yellow_cards:
        this.props.editing_elem.yellow_cards == "-"
          ? ""
          : this.props.editing_elem.yellow_cards,
      red_cards:
        this.props.editing_elem.red_cards == "-"
          ? ""
          : this.props.editing_elem.red_cards,
    };
  }

  closeEditDialog = () => {
    this.setState({
      open_edit_dialog: false,
      editing_elem: "",
    });
  };

  handleDateChange = (value) => {
    this.setState({ action_date: value });
  };

  updateNationalTeamCallUp = () => {
    let payload = {
      payload_for_national_team_call_up: {
        id_club: this.props.currentUser.id_club,
        id_national_team_call_up: this.props.editing_elem
          .id_national_team_call_up,
        start_date: this.state.action_date,
        end_date: this.state.action_date,
        age_group: this.state.age_group.label,
        national_team: countries.find(
          (item) => item.value === this.state.national_team
        ).text,
      },
      payload_for_national_team_call_up_action: {
        id_national_team_call_up_action: this.props.editing_elem
          .id_national_team_call_up_action,
        action_date: this.state.action_date,
        action_type:
          this.state.action_type?.value == 1
            ? "antrenament"
            : this.state.action_name,
      },
    };

    let reload_competitions = 0;

    if (this.state.competition?.value == 0) {
      payload.payload_for_national_team_call_up.new_competition_name = this.state.new_competition_name;
      reload_competitions = 1;
    } else {
      payload.payload_for_national_team_call_up.id_competition = this.state.competition.value;
    }

    if (this.state.action_name != "" && this.state.action_type.value != "1") {
      payload.payload_for_national_team_call_up_action.own_goals =
        this.state.own_goals == "" ? null : this.state.own_goals;
      payload.payload_for_national_team_call_up_action.opponent_goals =
        this.state.opponent_goals == "" ? null : this.state.opponent_goals;
      payload.payload_for_national_team_call_up_action.played_minutes =
        this.state.played_minutes == "" ? null : this.state.played_minutes;
      payload.payload_for_national_team_call_up_action.scored_goals =
        this.state.scored_goals == "" ? null : this.state.scored_goals;
      payload.payload_for_national_team_call_up_action.assists =
        this.state.assists == "" ? null : this.state.assists;
      payload.payload_for_national_team_call_up_action.yellow_cards =
        this.state.yellow_cards == "" ? null : this.state.yellow_cards;
      payload.payload_for_national_team_call_up_action.red_cards =
        this.state.red_cards == "" ? null : this.state.red_cards;
    }
    if (this.state.action_type.value == "1") {
      payload.payload_for_national_team_call_up_action.own_goals = null;
      payload.payload_for_national_team_call_up_action.opponent_goals = null;
      payload.payload_for_national_team_call_up_action.played_minutes = null;
      payload.payload_for_national_team_call_up_action.scored_goals = null;
      payload.payload_for_national_team_call_up_action.assists = null;
      payload.payload_for_national_team_call_up_action.yellow_cards = null;
      payload.payload_for_national_team_call_up_action.red_cards = null;
    }

    axios
      .post(`player/update_national_team_call_up`, payload)
      .then((res) => {
        if (res.data == 1) {
          if (reload_competitions == 1) {
            this.props.getCompetitonsForNationalTeamCallUp();
          }
          this.props.getPlayerNationalTeamCallUp();
          this.props.closeEditDialog();
          store.addNotification({
            title: "Jucator",
            message: "Convocarea la nationala a fost modificate cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          this.props.closeEditDialog();
          store.addNotification({
            title: "Jucator",
            message:
              "S-a produs o eroare! Convocarea la nationala nu a fost modificata cu succes!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    const customStylesSelect = {
      container: (provided, state) => ({
        ...provided,
        backgroundColor: "#fafafa",
        width: "100%",
      }),
      control: (provided, state) => ({
        ...provided,
        backgroundColor: "#fafafa",
        height: "50px",
        border: "none",
        "&:hover": {
          border: "none",
          boxShadow: "0 0 0 2px black",
          // border: "2px black"
        },
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        paddingLeft: "22px",
        fontSize: "14px",
        fontWeight: 700,
      }),
      placeholder: (provided, state) => ({
        ...provided,
        color: "#666666",
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
      }),
      menu: (provided, state) => ({
        ...provided,
        maxHeight: "350px",
      }),
      menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
    };

    return (
      <>
        <div className="player-transaction-popup-container add-national-team-call-up">
          <div className="player-transaction-header">
            <div className="player-transaction-title">
              Editare convocare la nationala
            </div>
            <Icon
              name="close"
              size="large"
              onClick={this.props.closeEditDialog}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div className="add-event-form-container">
          <div className="add-event-form-container">
            <Dropdown
              placeholder={"Selecteaza nationala"}
              options={countries}
              value={this.state.national_team}
              fluid
              search
              selection
              className="national-team-dropdown"
              onChange={(e, selected) => {
                this.setState({ national_team: selected.value });
              }}
            />
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="add-event-form-row">
              <DatePicker
                emptyLabel={"Data convocare"}
                value={this.state.action_date}
                onChange={this.handleDateChange}
                ampm={false}
                format="dd.MM.yyyy"
                name="date-picker"
                className="add-event-date-input"
              />
              <Icon
                name="calendar alternate outline"
                className="calendar-icon"
                size="small"
              />
            </div>
          </MuiPickersUtilsProvider>
          <div className="add-event-form-row">
            <Select
              placeholder="Selecteaza competitia"
              options={this.props.competitions}
              value={this.state.competition}
              onChange={(selected) => {
                this.setState({
                  competition: selected,
                });
                if (this.state.competition?.value != selected.value) {
                  this.setState({
                    new_competition_name: "",
                  });
                }
              }}
              styles={customStylesSelect}
              menuPortalTarget={document.body}
              // onMenuOpen={() => this.props.setHeight("570px")}
              // onMenuClose={() => this.props.setHeight("initial")}
            />
          </div>
          {this.state.competition?.value == 0 && (
            <div className="add-event-form-row">
              <input
                type="text"
                value={this.state.new_competition_name}
                onChange={(e) =>
                  this.setState({ new_competition_name: e.target.value })
                }
                placeholder={"Nume competitie noua"}
                className="add-event-input"
              />
            </div>
          )}
          <div className="add-event-form-row">
            <Select
              placeholder="Selecteaza grupa de varsta"
              options={this.props.age_groups}
              value={this.state.age_group}
              onChange={(selected) => {
                this.setState({
                  age_group: selected,
                });
              }}
              styles={customStylesSelect}
              // onMenuOpen={() => this.state.competition?.value == 0 ?
              //     this.props.setHeight("695px") :
              //     this.props.setHeight("630px")}
              // onMenuClose={() => this.props.setHeight("initial")}
            />
          </div>
          <div className="add-event-form-row">
            <Select
              placeholder="Selecteaza tip activitate"
              options={this.props.action_types}
              value={this.state.action_type}
              onChange={(selected) => {
                this.setState({
                  action_type: selected,
                });
                // if (this.state.action_type?.value != selected.value) {
                //     this.setState({
                //         action_name: "",
                //         own_goals: "",
                //         opponent_goals: "",
                //         played_minutes: "",
                //         scored_goals: "",
                //         assists: "",
                //         yellow_cards: "",
                //         red_cards: "",
                //     })
                // }
              }}
              styles={customStylesSelect}

              //   onMenuOpen={() =>
              //     this.state.competition?.value == 0
              //       ? this.props.setHeight("545px")
              //       : this.props.setHeight("480px")
              //   }
              //   onMenuClose={() => this.props.setHeight("initial")}
            />
          </div>
          {this.state.action_type?.value == 0 && (
            <>
              <div className="add-event-form-row">
                <input
                  type="text"
                  value={this.state.action_name}
                  onChange={(e) =>
                    this.setState({ action_name: e.target.value })
                  }
                  placeholder={"Nume echipa adversa"}
                  className="add-event-input"
                />
              </div>
              <div className="add-event-form-row">
                <input
                  type="text"
                  value={this.state.own_goals}
                  onChange={(e) => this.setState({ own_goals: e.target.value })}
                  placeholder={"Numar goluri echipa jucatorului"}
                  className="add-event-input"
                />
              </div>
              <div className="add-event-form-row">
                <input
                  type="text"
                  value={this.state.opponent_goals}
                  onChange={(e) =>
                    this.setState({ opponent_goals: e.target.value })
                  }
                  placeholder={"Numar goluri echipa adversa"}
                  className="add-event-input"
                />
              </div>
              <div className="add-event-form-row">
                <input
                  type="text"
                  value={this.state.played_minutes}
                  onChange={(e) =>
                    this.setState({ played_minutes: e.target.value })
                  }
                  placeholder={"Minute jucate"}
                  className="add-event-input"
                />
              </div>
              <div className="add-event-form-row">
                <input
                  type="text"
                  value={this.state.scored_goals}
                  onChange={(e) =>
                    this.setState({ scored_goals: e.target.value })
                  }
                  placeholder={"Goluri marcate"}
                  className="add-event-input"
                />
              </div>
              <div className="add-event-form-row">
                <input
                  type="text"
                  value={this.state.assists}
                  onChange={(e) => this.setState({ assists: e.target.value })}
                  placeholder={"Pase decisive"}
                  className="add-event-input"
                />
              </div>
              <div className="add-event-form-row">
                <input
                  type="text"
                  value={this.state.yellow_cards}
                  onChange={(e) =>
                    this.setState({ yellow_cards: e.target.value })
                  }
                  placeholder={"Cartonase galbene"}
                  className="add-event-input"
                />
              </div>
              <div className="add-event-form-row">
                <input
                  type="text"
                  value={this.state.red_cards}
                  onChange={(e) => this.setState({ red_cards: e.target.value })}
                  placeholder={"Cartonase rosii"}
                  className="add-event-input"
                />
              </div>
            </>
          )}
          <div className="container-buttons-add-event">
            <Button
              className="cancel-button"
              onClick={() => this.props.closeEditDialog()}
            >
              <Icon name="close" className="plus-icon-button" />
              {languagePack.Cancel}
            </Button>

            <Button
              className="add-button"
              onClick={this.updateNationalTeamCallUp}
              disabled={
                this.state.national_team == "" ||
                this.state.action_date == null ||
                this.state.competition == null ||
                this.state.age_group == "" ||
                this.state.action_type == "" ||
                (this.state.action_type?.value == 0 &&
                  this.state.action_name == "")
              }
            >
              <Icon name="add" className="plus-icon-button" />
              {"Salveaza"}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(EditNationalTeamCallUpWidget);
