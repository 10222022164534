import React from "react";
import "../Instat.scss";
import axios from "axios";

const menuOptions = [
  { label: "Defensiv", key: "defensive" },
  { label: "Ofensiv", key: "offensive" },
  { label: "Disciplinar", key: "disciplinary" },
  { label: "Faze fixe", key: "set_pieces" },
];

class TeamSeasonStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenuOption: "defensive",
      stats: {
        defensive: [],
        offensive: [],
        disciplinary: [],
        set_pieces: [],
      },
    };
  }

  componentDidMount = () => {
    axios
      .get(
        `instat/team_season_stats?id_team=${this.props.id_team}&lang=${this.props.lang}`
      )
      .then((res) =>
        this.setState({
          stats: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div className="card card-custom" style={{ height: 610 }}>
        <div className="team-season-stats-container">
          <div className="team-season-stats-title">Statistici echipa</div>
          <div className="team-season-stats-menu">
            {menuOptions.map((item, index) => {
              return (
                <div
                  className={
                    this.state.selectedMenuOption == item.key
                      ? "team-season-stats-menu-option active-menu-option"
                      : "team-season-stats-menu-option"
                  }
                  onClick={() =>
                    this.setState({ selectedMenuOption: item.key })
                  }
                  key={item.key}
                >
                  <div>{item.label}</div>
                </div>
              );
            })}
          </div>

          <div className="team-season-stats-list">
            <div className="team-season-stat-row team-stats-header">
              <div className="team-season-stat-name">Parametru</div>
              <div className="team-season-stat-total">Valoare medie</div>
              <div className="team-season-stat-avg">Valoare totala</div>
            </div>
            <div className="team-stats-list">
              {this.state.stats[this.state.selectedMenuOption].map(
                (param, index) => {
                  return (
                    <div
                      className="team-season-stat-row"
                      key={param.param_name}
                      style={{
                        backgroundColor: index % 2 == 0 ? "white" : "#fafafa",
                      }}
                    >
                      <div className="team-season-stat-name">
                        {param.param_name}
                      </div>
                      <div className="team-season-stat-total">
                        {parseFloat(param.value_avg).toFixed(2)}
                      </div>
                      <div className="team-season-stat-avg">
                        {param.value_total}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamSeasonStats;
