import React, { useMemo, useState } from "react";
import objectPath from "object-path";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { AsideMenu } from "./aside-menu/AsideMenu";
import Searchbar from "../../../../app/components/reusable/Searchbar";
import { QuickUserToggler } from "../extras/QuickUserToggler";
import { Brand } from "../brand/Brand";
import { KTUtil } from "./../../../_assets/js/components/util";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import * as utilFunctions from "./../../../../app/utils/utilFunctions";
import { connect } from "react-redux";
import * as constants from "./../../../../app/utils/constants";
import permissions from "../../../../app/utils/permissions";
import asideMenuSVG from "../../../../app/utils/asideMenuSVG";
import TFBIconButton from "../../../../app/components/design-system/IconButton/TFBIconButton";

function Aside(props) {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const { languagePack, user_permissions } = useSelector(
    ({ layoutService: { languagePack }, auth }) => ({
      languagePack,
      user_permissions: auth?.user?.user_permissions || {},
    })
  );
  const currentSectionPage = utilFunctions.getCurrentRootPage(
    window.location.pathname
  );

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses("aside", true),
      asideSecondaryDisplay: objectPath.get(
        uiService.config,
        "aside.secondary.display"
      ),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      extrasSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      extrasNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      extrasQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      extrasQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      extrasLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      extrasUserDisplay: objectPath.get(
        uiService.config,
        "extras.user.display"
      ),
    };
  }, [uiService, currentSectionPage]);

  const tabs = {
    tabId1: "kt_aside_tab_1",
    tabId2: "kt_aside_tab_2",
  };
  const [activeTab, setActiveTab] = useState(tabs.tabId1);
  const handleTabChange = (id) => {
    setActiveTab(id);
    const asideWorkspace = KTUtil.find(
      document.getElementById("kt_aside"),
      ".aside-secondary .aside-workspace"
    );
    if (asideWorkspace) {
      KTUtil.scrollUpdate(asideWorkspace);
    }
  };
  const disabledStyle = {
    filter: "opacity(0.2)",
    pointerEvents: "none",
  };

  const handleCloseAsideMenu = () => {
    if (document.body.classList.contains("aside-minimize")) {
      document.body.classList.remove("aside-minimize");
      document.body.classList.add("aside-minimize-cancel");
    }
  };

  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}
      >
        {/* begin::Primary */}
        <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
          <Brand logo={window.clubCustomization.logo} />
          {/* begin::Nav Wrapper */}
          <div
            className="aside-nav"
            style={{ width: "100%", textAlign: "center", flex: "auto" }}
          >
            {/* begin::Nav */}
            <ul
              className="list-unstyled flex-column"
              style={{ width: "100%" }}
              role="tablist"
            >
              {/* begin::Item */}
              <Link to="/dashboard">
                <li
                  className={
                    currentSectionPage === constants.rootPages.DASHBOARD
                      ? "nav-item menu-item-active-aside"
                      : "nav-item"
                  }
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title={languagePack.dashboard}
                  style={
                    !user_permissions[permissions.DASHBOARD]
                      ? disabledStyle
                      : {}
                  }
                  onClick={handleCloseAsideMenu}
                >
                  <div className={`nav-link btn btn-icon btn-clean btn-lg `}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-project">
                          {languagePack.dashboard}
                        </Tooltip>
                      }
                    >
                      <span className="svg-icon svg-icon-lg">
                        <img src={asideMenuSVG.DASHBOARD} />
                      </span>
                    </OverlayTrigger>
                  </div>
                </li>
              </Link>

              {/* end::Item */}

              {/* begin::Item */}
              <Link to="/club">
                <li
                  className={
                    currentSectionPage === constants.rootPages.CLUB
                      ? "nav-item menu-item-active-aside"
                      : "nav-item"
                  }
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title={languagePack.Club}
                  style={
                    !user_permissions[permissions.CLUB] ? disabledStyle : {}
                  }
                >
                  <div className={`nav-link btn btn-icon btn-clean btn-lg `}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-project">
                          {languagePack.Club}
                        </Tooltip>
                      }
                    >
                      <span className="svg-icon svg-icon-lg">
                        <img src={asideMenuSVG.MY_CLUB} />
                      </span>
                    </OverlayTrigger>
                  </div>
                </li>
              </Link>
              {/* end::Item */}

              {/* begin::Item */}

              <Link to="/training/history">
                <li
                  className={
                    currentSectionPage === constants.rootPages.TRAINING
                      ? "nav-item menu-item-active-aside"
                      : "nav-item"
                  }
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title="Antrenamente"
                  style={
                    !user_permissions[permissions.CLUB] ? disabledStyle : {}
                  }
                >
                  <div className={`nav-link btn btn-icon btn-clean btn-lg `}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-project">
                          {languagePack.trainings}
                        </Tooltip>
                      }
                    >
                      <span className="svg-icon svg-icon-lg">
                        <img src={asideMenuSVG.TRAINING} />
                      </span>
                    </OverlayTrigger>
                  </div>
                </li>
              </Link>

              {/* end::Item */}

              {/* begin::Item */}
              <Link to="/reports/report">
                <li
                  className={
                    currentSectionPage === constants.rootPages.REPORTS
                      ? "nav-item menu-item-active-aside"
                      : "nav-item"
                  }
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title={languagePack.Reports}
                  style={
                    !user_permissions[permissions.REPORTS] ? disabledStyle : {}
                  }
                >
                  <div className={`nav-link btn btn-icon btn-clean btn-lg `}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-project">
                          {languagePack.Reports}
                        </Tooltip>
                      }
                    >
                      <span className="svg-icon svg-icon-lg">
                        <img src={asideMenuSVG.REPORT} />
                      </span>
                    </OverlayTrigger>
                  </div>
                </li>
              </Link>
              {/* end::Item */}

              {/* begin::Item */}
              <Link
                to={`/financial/${
                  user_permissions[permissions.BONUSES_MONITORING]
                    ? "bonuses-monitoring"
                    : user_permissions[permissions.COSTS]
                    ? "costs"
                    : user_permissions[permissions.ONLINE_PAYMENTS]
                    ? "online-payments"
                    : user_permissions[permissions.NECESSITY_REPORTS]
                    ? "necessity-reports"
                    : user_permissions[permissions.IMPORT_FROM_STATEMENT]
                    ? "cont-extras"
                    : ""
                }`}
              >
                <li
                  className={
                    currentSectionPage === constants.rootPages.FINANCIAL
                      ? "nav-item menu-item-active-aside"
                      : "nav-item"
                  }
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title={languagePack.finances}
                  style={
                    !user_permissions[permissions.FINANCIAL]
                      ? disabledStyle
                      : {}
                  }
                >
                  <div className={`nav-link btn btn-icon btn-clean btn-lg `}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-project">
                          {languagePack.finances}
                        </Tooltip>
                      }
                    >
                      <span className="svg-icon svg-icon-lg">
                        <img src={asideMenuSVG.FINANCIAL} />
                      </span>
                    </OverlayTrigger>
                  </div>
                </li>
              </Link>
              {/* end::Item */}

              {/* begin::Item */}
              <Link to="/medical/injuries">
                <li
                  className={
                    currentSectionPage === constants.rootPages.MEDICAL
                      ? "nav-item menu-item-active-aside"
                      : "nav-item"
                  }
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title={languagePack.medical}
                  style={
                    !user_permissions[permissions.MEDICAL] ? disabledStyle : {}
                  }
                >
                  <div className={`nav-link btn btn-icon btn-clean btn-lg `}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-project">
                          {languagePack.medical}
                        </Tooltip>
                      }
                    >
                      <span className="svg-icon svg-icon-lg">
                        <img src={asideMenuSVG.MEDICAL} />
                      </span>
                    </OverlayTrigger>
                  </div>
                </li>
              </Link>
              {/* end::Item */}

              {/* begin::Item */}
              <Link to="/documents">
                <li
                  className={
                    currentSectionPage === constants.rootPages.DOCUMENTS
                      ? "nav-item menu-item-active-aside"
                      : "nav-item"
                  }
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title={languagePack.documents}
                  style={
                    !user_permissions[permissions.DOCUMENTS]
                      ? disabledStyle
                      : {}
                  }
                >
                  <div className={`nav-link btn btn-icon btn-clean btn-lg `}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-project">
                          {languagePack.documents}
                        </Tooltip>
                      }
                    >
                      <span className="svg-icon svg-icon-lg">
                        <img src={asideMenuSVG.DOCUMENT} />
                      </span>
                    </OverlayTrigger>
                  </div>
                </li>
              </Link>
              {/* end::Item */}

              {/* begin::Item */}
              <Link to="/fans/list">
                <li
                  className={
                    currentSectionPage === constants.rootPages.FANS
                      ? "nav-item menu-item-active-aside"
                      : "nav-item"
                  }
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title={languagePack.fans_relation}
                  style={
                    !user_permissions[permissions.FANS] ? disabledStyle : {}
                  }
                >
                  <div className={`nav-link btn btn-icon btn-clean btn-lg `}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-project">
                          {languagePack.fans_relation}
                        </Tooltip>
                      }
                    >
                      <span className="svg-icon svg-icon-lg">
                        <img src={asideMenuSVG.FAN_ENGAGEMENT} />
                      </span>
                    </OverlayTrigger>
                  </div>
                </li>
              </Link>
              {/* end::Item */}

              {/* begin::Item */}
              <Link to="/video-gallery">
                <li
                  className={
                    currentSectionPage === constants.rootPages.VIDEOGALLERY
                      ? "nav-item menu-item-active-aside"
                      : "nav-item"
                  }
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title={languagePack.gallery_videos_title}
                  style={
                    !user_permissions.ANY
                      ? !user_permissions.VIDEOGALLERY
                        ? disabledStyle
                        : {}
                      : {}
                  }
                >
                  <div className={`nav-link btn btn-icon btn-clean btn-lg `}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-project">
                          {languagePack.gallery_videos_title}
                        </Tooltip>
                      }
                    >
                      <span className="svg-icon svg-icon-lg">
                        <img src={asideMenuSVG.VIDEO_GALLERY} />
                      </span>
                    </OverlayTrigger>
                  </div>
                </li>
              </Link>
              {/* end::Item */}

              {/* begin::Item */}
              <Link to="/site/news">
                <li
                  className={
                    currentSectionPage === constants.rootPages.SITE
                      ? "nav-item menu-item-active-aside"
                      : "nav-item"
                  }
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title={languagePack.site_management}
                  style={
                    !user_permissions[permissions.SITE] ? disabledStyle : {}
                  }
                >
                  <div className={`nav-link btn btn-icon btn-clean btn-lg `}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-project">
                          {languagePack.site_management}
                        </Tooltip>
                      }
                    >
                      <span className="svg-icon svg-icon-lg">
                        <img src={asideMenuSVG.SITE_ADMINISTRATION} />
                      </span>
                    </OverlayTrigger>
                  </div>
                </li>
              </Link>
              {/* end::Item */}

              {/* begin::Item */}
              <Link to="/application/users">
                <li
                  className={
                    currentSectionPage === constants.rootPages.APPLICATION
                      ? "nav-item menu-item-active-aside"
                      : "nav-item"
                  }
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title={languagePack.admin_parent_app}
                  style={
                    !user_permissions[permissions.PARENTS_APP]
                      ? disabledStyle
                      : {}
                  }
                >
                  <div className={`nav-link btn btn-icon btn-clean btn-lg `}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-project">
                          {languagePack.admin_parent_app}
                        </Tooltip>
                      }
                    >
                      <span className="svg-icon svg-icon-lg">
                        <img src={asideMenuSVG.PARENTS_APP_ADMINISTRATION} />
                      </span>
                    </OverlayTrigger>
                  </div>
                </li>
              </Link>
              {/* end::Item */}

              {/* begin::Item */}
              <Link to="/shop/products">
                <li
                  className={
                    currentSectionPage === constants.rootPages.SHOP
                      ? "nav-item menu-item-active-aside"
                      : "nav-item"
                  }
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title={languagePack.shop}
                  style={
                    !user_permissions.ANY
                      ? !user_permissions.SHOP
                        ? disabledStyle
                        : {}
                      : {}
                  }
                >
                  <div className={`nav-link btn btn-icon btn-clean btn-lg `}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-project">
                          {languagePack.shop}
                        </Tooltip>
                      }
                    >
                      <span className="svg-icon svg-icon-lg">
                        <img src={asideMenuSVG.ONLINE_SHOP_ADMINISTRATION} />
                      </span>
                    </OverlayTrigger>
                  </div>
                </li>
              </Link>
              {/* end::Item */}

              {/* begin::Item */}
              {user_permissions[permissions.CLUB_ADMIN] && (
                <Link to="/users-management">
                  <li
                    className={
                      currentSectionPage ===
                      constants.rootPages.USERS_MANAGEMENT
                        ? "nav-item menu-item-active-aside"
                        : "nav-item"
                    }
                    data-toggle="tooltip"
                    data-placement="rigth"
                    data-container="body"
                    data-boundary="window"
                    title={languagePack.users_management_menu}
                    style={
                      !user_permissions[permissions.PARENTS_APP]
                        ? disabledStyle
                        : {}
                    }
                  >
                    <div className={`nav-link btn btn-icon btn-clean btn-lg `}>
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="latest-project">
                            {languagePack.users_management_menu}
                          </Tooltip>
                        }
                      >
                        <span className="svg-icon svg-icon-lg">
                          <img src={asideMenuSVG.PLATFORM_ADMINISTRATION} />
                        </span>
                      </OverlayTrigger>
                    </div>
                  </li>
                </Link>
              )}
              {/* end::Item */}
            </ul>
            {/* end::Nav */}
          </div>
          {/* end::Nav Wrapper */}

          {/* begin::Footer */}
          <div className="aside-footer nav-item">
            {/* begin::Aside Toggle */}
            {layoutProps.asideSecondaryDisplay &&
              layoutProps.asideSelfMinimizeToggle && (
                <>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="toggle-aside">
                        {languagePack.toggle_aside}
                      </Tooltip>
                    }
                  >
                    <TFBIconButton
                      name="angle-left"
                      color="green"
                      id="kt_aside_toggle"
                      className="aside-toggle"
                    />
                  </OverlayTrigger>
                </>
              )}
            {/* end::Aside Toggle */}

            {/* begin::User*/}
            {layoutProps.extrasUserDisplay && <QuickUserToggler />}
            {/* end::User */}
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Primary */}

        {layoutProps.asideSecondaryDisplay && (
          <>
            {/* begin::Secondary */}
            <div
              className="aside-secondary d-flex flex-row-fluid"
              style={
                currentSectionPage === constants.rootPages.SCOUTING
                  ? {
                      position: "relative",
                    }
                  : {}
              }
            >
              {/* <AsideSearch isActive={activeTab === tabs.tabId1} /> */}
              {currentSectionPage === constants.rootPages.SCOUTING ? (
                <div
                  className="p-3 px-lg-7 py-lg-5"
                  style={{
                    marginTop: "4vh",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 9,
                  }}
                >
                  <Searchbar />
                </div>
              ) : (
                ""
              )}

              {/* begin::Workspace */}
              <div className="aside-workspace scroll scroll-push">
                <div className="tab-content">
                  <AsideMenu isActive />
                </div>
              </div>
              {/* end::Workspace */}
            </div>
            {/* end::Secondary */}
          </>
        )}
      </div>
      {/* end::Aside */}
    </>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  user: auth.user || {},
  token: auth?.user?.token,
}))(Aside);
