import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";

import { Input, Dropdown, Icon, Button, Divider } from "semantic-ui-react";
import dateFormat from "dateformat";
import axios from "axios";
import { connect } from "react-redux";
import * as constants from "./BonusesStaticElements";
import * as myConstants from "./../../../../app/utils/constants";
import DatePickerComp from "../../../components/reusable/DateRange";
import NumberFormat from "react-number-format";

const currencies = Object.entries(myConstants.currencies).map(
  ([uniqueSlug, value]) => value
);

class AddTeamBonus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,

      bonus_name: "",
      bonus_season: "",
      bonus_type: "",
      bonus_competition: "",
      another_bonus_receiver: false,
      matchChecked: false,
      seasonChecked: false,
      periodChecked: false,
      bonus_match: "",
      bonus_date_start: new Date(),
      bonus_date_end: "",

      bonus_criterias: [],
      bonus_criteria_added: "",
      bonus_currency_added: "",
      another_bonus_criteria: false,
      bonus_new_criteria_added: "",
      bonus_amount: "",
      split_equally: true,
    };
  }

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value });
  };

  handleChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleChangeCurrency = (event, newCurrency) => {
    this.setState({ bonus_currency_added: newCurrency });
  };

  submitBonus = () => {
    const bonus = {
      id_team: this.props.currentUser.id_team,

      bonus_name: this.state.bonus_name,
      bonus_season: this.state.bonus_season,
      bonus_type: this.state.bonus_type,
      bonus_competition: this.state.bonus_competition,
      bonus_match: this.state.bonus_match,
      custom_date_start: dateFormat(this.state.bonus_date_start, "yyyy-mm-dd"),
      custom_date_end: dateFormat(this.state.bonus_date_end, "yyyy-mm-dd"),

      bonus_amount: this.state.bonus_amount_total,
      bonus_criterias: this.state.bonus_criterias,
      bonus_currency: this.state.bonus_currency,
      split_type: this.state.split_equally ? "Equally" : this.state.split_type,
    };
    axios
      .post("/finances/add_bonus", bonus, {
        params: {
          token: this.props.token,
        },
      })
      .then(() => {
        this.refreshBonuses();
        this.handleReset();
        this.setState({ addBonusDialogOpen: false });
      })
      .catch((err) => console.log(err));
  };

  addCriteria = () => {
    this.setState((state) => {
      const bonus_criterias = state.bonus_criterias.concat({
        name: state.bonus_criteria_added
          ? state.bonus_criteria_added
          : state.bonus_new_criteria_added,
        amount: state.bonus_amount,
        currency: state.bonus_currency_added,
      });
      return {
        bonus_amount_total:
          Number(state.bonus_amount_total) + Number(state.bonus_amount),
        bonus_criterias,
        bonus_criteria_added: "",
        bonus_new_criteria_added: "",
        bonus_amount: "",
        bonus_currency_added: "",
      };
    });
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div>
        <Stepper activeStep={this.state.activeStep}>
          {constants._addBonusPopupSteps.map(({ translation, key }, index) => {
            const label = languagePack[translation];
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={key} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div className="add-bonus-form-container">
          {this.state.activeStep === 0 ? (
            <div>
              <div className="form-row-bonus sb">
                <Input
                  placeholder={`${languagePack.Name}...`}
                  className="form-item-dual"
                  onChange={this.handleChangeInput}
                  value={this.state.bonus_name}
                  name="bonus_name"
                />
                <Dropdown
                  onChange={this.handleChangeDropdown}
                  name="bonus_season"
                  placeholder="Sezon:"
                  selection
                  options={constants.seasons}
                  value={this.state.bonus_season}
                  className="form-item-dual"
                />
              </div>

              <div className="form-row-bonus">
                <Dropdown
                  onChange={this.handleChangeDropdown}
                  name="bonus_receiver"
                  placeholder={`${languagePack.Bonus_beneficiary}...`}
                  fluid
                  selection
                  options={constants.bonus_receiver.map((item) => ({
                    ...item,
                    text: languagePack[item.translation],
                  }))}
                  value={this.state.bonus_receiver}
                  className="form-item"
                />

                <Icon
                  name="add"
                  className="cusor-pointer"
                  color="green"
                  size="large"
                  onClick={() =>
                    this.setState({
                      another_bonus_receiver: !this.state
                        .another_bonus_receiver,
                      bonus_receiver: "",
                    })
                  }
                  style={{ paddingTop: 6, marginLeft: 10 }}
                />
              </div>

              {this.state.another_bonus_receiver === true ? (
                <div className="form-row-bonus">
                  <Input
                    placeholder={`Another beneficiary...`}
                    className="form-item"
                    onChange={this.handleChangeInput}
                    value={this.state.bonus_receiver}
                    name="bonus_receiver"
                  />
                </div>
              ) : (
                ""
              )}

              <div className="form-row-bonus">
                <Dropdown
                  onChange={this.handleChangeDropdown}
                  placeholder={`${languagePack.Competition}...`}
                  fluid
                  name="bonus_competition"
                  selection
                  options={constants.competitions.map((item) => ({
                    ...item,
                    text: languagePack[item.translation],
                  }))}
                  value={this.state.bonus_competition}
                  className="form-item"
                />
              </div>
              <div className="form-row-bonus sb">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.matchChecked}
                      onChange={this.handleChangeCheckbox}
                      name="matchChecked"
                      color="primary"
                    />
                  }
                  label="Pentru un meci"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.seasonChecked}
                      onChange={this.handleChangeCheckbox}
                      name="seasonChecked"
                      color="primary"
                    />
                  }
                  label="Pentru intreg sezonul"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.periodChecked}
                      onChange={this.handleChangeCheckbox}
                      name="periodChecked"
                      color="primary"
                    />
                  }
                  label="Durata personalizata"
                />
              </div>

              {this.state.matchChecked ? (
                <div className="form-row-bonus">
                  <Dropdown
                    name="bonus_match"
                    onChange={this.handleChangeDropdown}
                    placeholder={languagePack.Select_match}
                    fluid
                    selection
                    options={constants.matches}
                    value={this.state.bonus_match}
                  />
                </div>
              ) : (
                ""
              )}

              {this.state.periodChecked ? (
                <div className="form-row-bonus">
                  <DatePickerComp
                    setDate={(value) =>
                      this.setState({
                        bonus_date_start: value.startDate,
                        bonus_date_end: value.endDate,
                      })
                    }
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {this.state.activeStep === 1 ? (
            <div>
              <div className="form-row-bonus">
                <Dropdown
                  onChange={this.handleChangeDropdown}
                  placeholder={languagePack.Assignment_criteria}
                  fluid
                  name="bonus_criteria_added"
                  selection
                  options={
                    this.state.matchChecked
                      ? constants.bonus_criterias_match.map((item) => ({
                          ...item,
                          text: languagePack[item.translation],
                        }))
                      : constants.bonus_criterias_season.map((item) => ({
                          ...item,
                          text: languagePack[item.translation],
                        }))
                  }
                  value={this.state.bonus_criteria_added}
                  className="form-item"
                />

                <Icon
                  name="add"
                  className="cusor-pointer"
                  color="green"
                  size="large"
                  onClick={() =>
                    this.setState({
                      another_bonus_criteria: !this.state
                        .another_bonus_criteria,
                      bonus_criteria_added: "",
                    })
                  }
                  style={{ paddingTop: 6, marginLeft: 10 }}
                />
              </div>

              {this.state.another_bonus_criteria ? (
                <div className="form-row-bonus">
                  <Input
                    placeholder="Criteriu personalizat"
                    className="form-item"
                    fluid
                    value={this.state.bonus_new_criteria_added}
                    onChange={this.handleChangeInput}
                    name="bonus_new_criteria_added"
                  />
                </div>
              ) : (
                ""
              )}

              <div className="new-criteria-container">
                {this.state.bonus_criteria_added ===
                "Clasare in primele ... locuri" ? (
                  <div className="form-row-bonus">
                    <Input
                      placeholder={languagePack.Ranking}
                      className="form-item"
                      type="number"
                      name="bonus_ranking_position"
                      value={this.state.bonus_ranking_position}
                      style={{ width: "25%", marginRight: 20 }}
                      onChange={this.handleChangeInput}
                    />
                  </div>
                ) : (
                  ""
                )}

                {this.state.bonus_criteria_added ===
                "Calificare in etapa Cupei Romaniei" ? (
                  <div className="form-row-bonus">
                    <Dropdown
                      onChange={this.handleChangeDropdown}
                      name="cup_phase_bonus"
                      placeholder={languagePack.select_cup_phase}
                      fluid
                      selection
                      options={constants.cup_phases.map((item) => ({
                        ...item,
                        text: languagePack[item.translation],
                      }))}
                      value={this.state.cup_phase_bonus}
                      className="form-item"
                      style={{ width: "80%" }}
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="form-row-bonus sb">
                  <Input
                    placeholder={languagePack.Totol_assigned_sum}
                    className="form-item-dual"
                    type="number"
                    name="bonus_amount"
                    value={this.state.bonus_amount}
                    onChange={(event) => {
                      if (event.keyCode === 13 || event.key === 13) {
                        this.addCriteria();
                        return;
                      }
                      this.handleChangeInput(event);
                    }}
                  />

                  <ToggleButtonGroup
                    size="small"
                    value={this.state.bonus_currency_added}
                    exclusive
                    onChange={this.handleChangeCurrency}
                    aria-label="currency"
                  >
                    {currencies.map((currencyItem) => (
                      <ToggleButton
                        value={currencyItem.bonusUniqueSlug}
                        aria-label={currencyItem.bonusUniqueSlug}
                      >
                        {languagePack[currencyItem.translation]}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </div>
                <div className="form-row-bonus">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.split_equally}
                        onChange={this.handleChangeCheckbox}
                        color="primary"
                        name="split_equally"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label="Impartire egala pentru destinatarii selectati"
                  />
                </div>
                {this.state.split_equally === false ? (
                  <div className="form-row-bonus">
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={2}
                      value={this.state.payment_type}
                      onChange={(event) =>
                        this.setState({ payment_type: event.target.value })
                      }
                      placeholder="Descriere distribuire personalizata"
                      fullWidth
                      variant="outlined"
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="form-row-bonus button-container-add-criteria">
                  <Button
                    disabled={
                      !this.state.bonus_amount &&
                      !this.state.bonus_criteria_added
                    }
                    onClick={this.addCriteria}
                    className="bonus-button-add-criteria"
                  >
                    <div className="button-content">
                      Adauga in lista
                      <Icon name="add" />
                    </div>
                  </Button>
                </div>
              </div>

              <Divider />
              <h3>
                {languagePack.active_crtierias}{" "}
                {this.state.bonus_criterias.length}
              </h3>
              {this.state.bonus_criterias.length > 0 ? (
                <div>
                  <div className="criterias-row">
                    <div className="criterias-row-name">Conditie</div>
                    <div className="criterias-row-amount">Suma</div>
                  </div>

                  {this.state.bonus_criterias.map((item, index) => {
                    return (
                      <div
                        key={`${index}-acitve-criteria-item`}
                        className="criterias-row"
                      >
                        <div className="criterias-row-name grey">
                          {item.name}
                        </div>
                        <div className="criterias-row-amount green">
                          {" "}
                          <NumberFormat
                            value={item.amount}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                          <div className="bonus-criteria-list-currency">
                            {item.currency === "Euro" ? (
                              <Icon name="eur" className="green-icon" />
                            ) : (
                              "RON"
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="bonus-bottom-buttons-container">
          <Button
            onClick={
              this.state.activeStep === 0
                ? () => this.setState({ addBonusDialogOpen: false })
                : this.handleBack
            }
            className="bottom-button-back"
          >
            {languagePack.GoBack}
          </Button>
          <Button
            onClick={
              this.state.activeStep === constants._addBonusPopupSteps.length - 1
                ? this.submitBonus
                : this.handleNext
            }
            className="bottom-button-next"
          >
            {this.state.activeStep === constants._addBonusPopupSteps.length - 1
              ? languagePack.Submit
              : languagePack.Next}
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
  currencyItemSelected:
    myConstants.currencies[auth?.user?.currency] || myConstants.currencies.EUR,
}))(AddTeamBonus);
