import React from "react";
import { Dropdown, Icon, Button, Input, TextArea } from "semantic-ui-react";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "./../../utils/api";
import axios from "axios";
import "./Documents.styles.scss";

class AddFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doc_category: "",
      addNewCategory: false,
      addNewCategoryName: "",
      addedFile: "",
      doc_extra_notes: "",
      categories: [],
      error: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  componentDidMount = () => {
    this.getCategories();
  };

  getCategories = () => {
    axios
      .get(
        `upload/get_file_categories?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => {
        this.setState({ categories: res.data });
      })
      .catch((err) => console.log(err));
  };

  onChange = (e) => {
    this.setState({
      addedFile: e.target.files[0],
    });
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value });
  };

  async setLoader(value) {
    return new Promise((resolve) => {
      this.setState(
        {
          fileUploading: value,
        },
        () => {
          resolve(value);
        }
      );
    });
  }

  async onSubmit(e) {
    e.preventDefault();
    await this.setLoader(true);
    let res = await this.uploadFile(this.state.addedFile);
    await this.setLoader(false);
    this.setState(
      {
        doc_category: "",
        addNewCategoryName: "",
        addedFile: "",
        doc_extra_notes: "",
      },
      this.props.refreshFiles
    );
    store.addNotification({
      title: "Document operation",
      message: this.props.languagePack["documents_upload_success"],
      type: "success",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 2000,
      },
    });
  }

  async uploadFile(file) {
    const formData = new FormData();
    formData.append("avatar", file);
    formData.append("fileName", file.name);
    formData.append("id_club", this.props.currentUser.id_club);
    formData.append("uploaded_by", this.props.currentUser.nickname);
    formData.append("category", this.state.addNewCategoryName);
    formData.append("extra_notes", this.state.doc_extra_notes);
    return await axios
      .post("upload/upload_file", formData, {
        params: {
          token: this.props.token,
        },
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(async (res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        }
        return res;
      })
      .catch(async (err) => {
        return { data: {} };
      });
  }

  addNewCategory = () => {
    const newEl = {
      key: this.state.categories.length,
      text: this.state.addNewCategoryName,
      value: this.state.addNewCategoryName,
    };
    if (
      this.state.categories.some(
        (item) => item.text == this.state.addNewCategoryName
      )
    ) {
      this.setState({ addNewCategory: false });
    } else {
      if (this.state.addNewCategoryName != "") {
        this.setState({
          categories: this.state.categories.concat(newEl),
          addNewCategory: false,
          error: "",
        });
      } else {
        this.setState({
          error: this.props.languagePack["new_category_empty_error"],
        });
      }
    }
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="add-file-container">
        <div className="add-file-form-row">
          <Dropdown
            value={this.state.addNewCategoryName}
            name="addNewCategoryName"
            className="add-file-form-dropdown"
            selection
            options={this.state.categories}
            onChange={this.handleChangeDropdown}
            placeholder={languagePack["category"]}
          />

          <div
            className="add-file-form-button dark-grey-button"
            onClick={() =>
              this.setState({
                addNewCategory: !this.state.addNewCategory,
                addNewCategoryName: "",
              })
            }
          >
            <Icon
              name="add"
              size="small"
              className="cusor-pointer white-button-icon"
            />
          </div>
        </div>

        {this.state.addNewCategory && (
          <div className="add-file-form-row">
            <input
              type="text"
              value={this.state.addNewCategoryName}
              onChange={(e) =>
                this.setState({ addNewCategoryName: e.target.value })
              }
              placeholder={languagePack["add_new_category"]}
              className="add-file-form-input"
            />
            <div
              className="add-file-form-button dark-grey-button"
              onClick={this.addNewCategory}
            >
              <Icon
                name="checkmark"
                className="cusor-pointer white-button-icon"
                size="small"
              />
            </div>
            <div
              className="add-file-form-button light-grey-button"
              onClick={(e) =>
                this.setState({ addNewCategory: false, addNewCategoryName: "" })
              }
            >
              <Icon
                name="ban"
                className="cusor-pointer grey-button-icon"
                size="small"
              />
            </div>
          </div>
        )}

        <form onSubmit={this.onSubmit}>
          <div
            className="add-file-form-row"
            style={{
              justifyContent: "space-between",
              paddingRight: 20,
              backgroundColor: "#fafafa",
            }}
          >
            <input
              type="file"
              className="add-file-form-file-input"
              id="customFile"
              onChange={this.onChange}
            />
            <label className="add-file-form-file-label" htmlFor="customFile">
              {this.state.addedFile
                ? this.state.addedFile.name
                : languagePack["upload_file"]}
            </label>
            <Icon
              name="arrow up"
              className="cusor-pointer grey-button-icon"
              size="small"
            />
          </div>

          <TextArea
            placeholder={languagePack["file_details"]}
            className="text-area-file-form"
            value={this.state.doc_extra_notes}
            onChange={(e) => this.setState({ doc_extra_notes: e.target.value })}
            name="doc_extra_notes"
          />
          {this.state.error != "" && (
            <div className="end-injury-error-row">{this.state.error}</div>
          )}

          <div className="add-file-buttons-container">
            {/* <Button
              className="add-file-button cancel-button"
              onClick={() => this.props.closePopup()}
            >
              {languagePack["admin_cancel"]}
              <Icon name="ban" size="small" className="add-file-button-icon" />
            </Button> */}
            <Button
              className="add-file-button"
              disabled={this.state.fileUploading || !this.state.addedFile}
              onClick={this.onSubmit}
            >
              {languagePack["admin_add"]}
              <Icon name="add" size="small" className="add-file-button-icon" />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(AddFile);
