import React, { useState, useEffect } from "react";
import "./ActiveInjuries.scss";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { connect } from "react-redux";
import { logout } from "../../../utils/api";
import { bindActionCreators } from "redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Button, Icon, Accordion } from "semantic-ui-react";
import axios from "axios";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import AddInjuryForm from "./AddInjuryForm";
import AddTreatmentForm from "./AddTreatmentForm";
import NumberFormat from "react-number-format";
import EditInjuryForm from "./EditInjuryForm";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CloseIcon from "@material-ui/icons/Close";
import * as myConstants from "../../../../app/utils/constants";
import EditTreatmentForm from "./EditTreatmentForm";
import { store } from "react-notifications-component";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye-white.svg";
import dateFormat from "dateformat";

let activePopupClassName = "";

function ActiveInjuries(props) {
  const [injuries, setInjuries] = useState([]);
  const [addInjuryPopup, setAddInjuryPopup] = useState(false);
  const [treatmentPopup, setTreatmentPopup] = useState(false);
  const [addTreatment, setAddTreatment] = useState(false);
  const [openedTreatmentId, setOpenedTreatmentId] = useState("");
  const [activeTreatment, setActiveTreatment] = useState([]);
  const [activeInjury, setActiveInjury] = useState([]);
  const [activeIndexes, setActiveIndexes] = useState([]);
  const [rates, setRates] = useState([]);
  const [editInjuryForm, setEditInjuryForm] = useState(false);
  const [editTreatmentForm, setEditTreatmentForm] = useState(false);

  const [endInjuryPopup, setEndInjuryPopup] = useState(false);
  const [endingInjuryDate, setEndingInjuryDate] = useState(null);
  const [endingInjuryInjDate, setEndingInjuryInjDate] = useState(null);
  const [endingInjuryId, setEndingInjuryId] = useState("");

  const [endInjuryError, setEndInjuryError] = useState("");

  const history = useHistory();

  const fetchData = async () => {
    const result = await axios(
      `medical/get_active_injuries?token=${props.token}&id_club=${props.currentUser.id_club}`
    );
    if (result?.data?.go_to_login === 1) {
      props.logout(props.token);
    } else {
      setInjuries(Array.isArray(result.data) ? result.data : []);
    }
  };

  useEffect(() => {
    fetchData();
    getCurrency();
  }, []);

  function finishInjury(id) {
    const payload = {
      id_injury: id,
      ending_date: endingInjuryDate,
    };
    if (dateFormat(endingInjuryDate, "yyyy-mm-dd") > endingInjuryInjDate) {
      axios.post("medical/finish_injury", payload).then(() => {
        fetchData();
        props.refreshInjuryHistory();
        setEndInjuryPopup(false);
        setEndingInjuryDate(null);
        setEndingInjuryInjDate(null);
        setEndInjuryError("");
        store.addNotification({
          title: props.languagePack["injury_details"],
          message: props.languagePack["injury_close_success"],
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      });
    } else {
      setEndInjuryError(props.languagePack["rec_date_not_before_inj_date"]);
    }
  }

  function expandRow(value) {
    if (activeIndexes.includes(value)) {
      setActiveIndexes((oldArray) => oldArray.filter((x) => x !== value));
    } else {
      setActiveIndexes((oldArray) => [...oldArray, value]);
    }
  }

  function handleChangeEndingDate(value) {
    setEndingInjuryDate(value);
  }

  function get_treatment(id) {
    axios
      .get(`medical/get_injury_treatment?id_injury=${id}`)
      .then((res) => {
        setActiveTreatment(res.data.treatments);
        setActiveInjury(res.data.injury_data);
      })
      .catch((err) => console.log(err));
  }

  function isTargetOrContains(target, node) {
    return target === node || node.contains(target);
  }
  function onClickEventListener(event) {
    if (!activePopupClassName) {
      return;
    }

    const target = event.target;
    const popupNodesActive = document.getElementsByClassName(
      activePopupClassName
    );
    const moreIconNode = document.querySelector(
      `.mdical-item-more-icon-${activePopupClassName}`
    );

    if (popupNodesActive.length === 0 || !moreIconNode) {
      return;
    }

    const datepickerNode = document.querySelector(
      `.MuiPaper-root[aria-labelledby=${activePopupClassName}]`
    );
    if (
      !isTargetOrContains(target, moreIconNode) &&
      !isTargetOrContains(target, popupNodesActive[0]) &&
      (!datepickerNode || !isTargetOrContains(target, datepickerNode))
    ) {
      moreIconNode.click();
      activePopupClassName = "";
    }
  }

  useEffect(() => {
    window.addEventListener("click", onClickEventListener);
    return () => {
      window.removeEventListener("click", onClickEventListener);
    };
  }, []);

  function deleteInjury(value) {
    axios
      .post("medical/delete_injury", {
        id_injury: value,
      })
      .then(() => {
        setTreatmentPopup(false);
        fetchData();
        store.addNotification({
          title: props.languagePack["injury_details"],
          message: props.languagePack["injury_delete_success"],
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        props.history.push(`/club`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function deleteTreatment(value) {
    axios
      .post("medical/delete_treatment", {
        id_treatment: value,
      })
      .then(() => {
        get_treatment(activeInjury.id_injury);
        store.addNotification({
          title: props.languagePack["injury_details"],
          message: props.languagePack["treatment_delete_success"],
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      })
      .catch((err) => console.log(err));
  }

  function getCurrency() {
    fetch("https://api.thefootballbrain.app/club/get_currency_rates")
      .then((res) => res.json())
      .then((data) => setRates(data));
  }

  function convertCurrency(value) {
    if (props.currencyItemSelected.uniqueSlug === "EUR") {
      return value;
    } else {
      return value * rates[props.currencyItemSelected.uniqueSlug];
    }
  }

  function formatNumber(value) {
    return (
      <NumberFormat
        value={convertCurrency(value)}
        displayType={"text"}
        decimalScale={2}
        thousandSeparator={true}
      />
    );
  }

  const iconOsCurrency =
    props.currencyItemSelected.uniqueSlug === "EUR" ? (
      <Icon name="eur" className="green-eur-icon mg--0" />
    ) : (
      <span className="font-weight-bold"> RON </span>
    );

  const { languagePack } = props;
  return (
    <div className="col-lg-6 col-xxl-6 widget current-injuries-container">
      <div className="card card-custom">
        <div className="injuries-container-a">
          <div className="injuries-header">
            <div className="injuries-title">
              {languagePack["active_injuries_title"]}
            </div>
            <Button
              className="add-treatment-button add-injury-header-button"
              onClick={() => setAddInjuryPopup(true)}
            >
              <div className="button-content">
                {languagePack["active_injuries_add_button"]}
                <Icon
                  name="add"
                  className="edit-icon"
                  style={{ marginLeft: 10 }}
                />
              </div>
            </Button>
          </div>
          {injuries.length > 0 ? (
            <div className="injuries-list">
              <div className="injury-row columns-names-injuries">
                <div className="injury-player-name">
                  {languagePack["active_injuries_player_name"]}
                </div>
                <div className="injury-type">
                  {languagePack["active_injuries_type"]}
                </div>
                <div className="injury-date">
                  {languagePack["active_injuries_injury_date"]}
                </div>
                <div className="injury-estimated-recovery">
                  {languagePack["active_injuries_estimated_recovery"]}
                </div>
                <div className="injury-options"></div>
              </div>
              {injuries.map((row, index) => {
                return (
                  <div
                    className="injuries-active-container"
                    key={"injury-" + index}
                  >
                    <div className="injuries-active-content">
                      <div
                        className="injury-row columns-names-injuries"
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                        }}
                      >
                        <div className="injury-player-name">
                          {languagePack["active_injuries_player_name"]}
                        </div>
                        <div className="injury-type">
                          {languagePack["active_injuries_type"]}
                        </div>
                        <div className="injury-date">
                          {languagePack["active_injuries_injury_date"]}
                        </div>
                        <div className="injury-estimated-recovery">
                          {languagePack["active_injuries_estimated_recovery"]}
                        </div>
                        <div className="injury-options"></div>
                      </div>
                      <div
                        className="injury-row"
                        key={row.id_injury}
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                        }}
                      >
                        <div
                          className="injury-player-name"
                          onClick={() =>
                            history.push(`/club/player/${row.id_player}`)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {row.player_name}
                        </div>
                        <div className="injury-type">
                          {" "}
                          {row.another_zone == 1
                            ? row.injury_type
                            : props.languagePack[row.injury_type]}
                        </div>
                        <div className="injury-date">
                          {" "}
                          <Moment format="DD.MM.YYYY">{row.injury_date}</Moment>
                        </div>
                        <div className="injury-estimated-recovery">
                          {row.estimated_recovery_date ? (
                            <Moment format="DD.MM.YYYY">
                              {row.estimated_recovery_date}
                            </Moment>
                          ) : (
                            "-"
                          )}
                        </div>
                        <div className="injury-options">
                          <div
                            className="injury-see-more-button"
                            onClick={() => {
                              setTreatmentPopup(true);
                              get_treatment(row.id_injury);
                              setOpenedTreatmentId(row.id_injury);
                            }}
                          >
                            <EyeIcon />
                          </div>
                          <Icon
                            name="calendar alternate outline"
                            size="large"
                            className="end-injury-icon"
                            onClick={() => {
                              setEndInjuryPopup(true);
                              setEndingInjuryId(row.id_injury);
                              setEndingInjuryInjDate(row.injury_date);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="placeholder-files">
              <Icon
                name="search minus"
                size="big"
                className="placeholder-icon-team-page"
              />
              <div className="placeholder-text">
                {"Nu sunt accidentari curente"}
              </div>
            </div>
          )}
        </div>

        <Dialog
          open={addInjuryPopup}
          // onClose={() => setAddInjuryPopup(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <div className="bonus-options-list-popup-header">
            <div className="bonus-options-list-title">
              {languagePack["add_injury_popup_title"]}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={() => setAddInjuryPopup(false)}
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>

          <DialogContent>
            <AddInjuryForm
              update_players={fetchData}
              close_popup={() => setAddInjuryPopup(false)}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          open={treatmentPopup}
          // onClose={() => setTreatmentPopup(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
        >
          <div className="injury-popup-item-subtitle">
            <CloseIcon
              onClick={() => setTreatmentPopup(false)}
              fontSize="large"
            />
          </div>
          <DialogContent>
            <>
              <div className="injury-big-popup-title">
                {languagePack["injury_details"]}
              </div>
              {!editInjuryForm ? (
                <div>
                  <div className="injury-big-popup-column-titles">
                    <div className="injury-column-title">
                      {languagePack["active_injuries_injury_date"]}
                    </div>
                    <div className="injury-column-title">
                      {languagePack["injury_zone"]}
                    </div>
                    <div className="injury-column-title">
                      {languagePack["active_injuries_estimated_recovery"]}
                    </div>
                  </div>
                  <div
                    className="injury-big-popup-values"
                    style={{ backgroundColor: "#fafafa" }}
                  >
                    <div className="injury-column-title">
                      <Moment format="DD.MM.YYYY">
                        {activeInjury.date_start}
                      </Moment>
                    </div>
                    <div className="injury-column-title">
                      {activeInjury.another_zone == 1
                        ? activeInjury.name
                        : props.languagePack[activeInjury.name]}
                    </div>
                    <div className="injury-column-title">
                      {activeInjury.estimated_recovery ? (
                        <Moment format="DD.MM.YYYY">
                          {activeInjury.estimated_recovery}
                        </Moment>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                  <div className="expanded-row-description">
                    {activeInjury.description}
                  </div>
                  <div className="injury-edit-delete-buttons">
                    <Button
                      className="custom-button-dual delete-button"
                      onClick={() => deleteInjury(activeInjury.id_injury)}
                    >
                      <div className="button-content">
                        {languagePack["admin_delete"]}
                        <Icon
                          name="trash alternate outline"
                          className="delete-icon"
                        />
                      </div>
                    </Button>
                    <Button
                      className="custom-button-dual edit-button"
                      onClick={() => setEditInjuryForm(!editInjuryForm)}
                    >
                      <div className="button-content">
                        {languagePack["edit_details"]}
                        <Icon name="pencil" className="edit-icon" />
                      </div>
                    </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <EditInjuryForm
                    ended="0"
                    injury={activeInjury}
                    injury_name={
                      activeInjury.another_zone == 1
                        ? activeInjury.name
                        : props.languagePack[activeInjury.name]
                    }
                    close_section={() => {
                      setEditInjuryForm(false);
                      get_treatment(activeInjury.id_injury);
                    }}
                    languagePack={props.languagePack}
                  />
                </div>
              )}
              <div className="injury-big-popup-subtitle">
                {languagePack["treatment"]}
              </div>
              <div className="treatment-container">
                {activeTreatment.map((item, index) => {
                  return (
                    <div key={index}>
                      <Accordion exclusive={false}>
                        <Accordion.Title
                          style={{ padding: 0 }}
                          onClick={() => expandRow(index)}
                          active={activeIndexes.includes(index)}
                        >
                          <div
                            className="unexpanded-row"
                            style={{
                              backgroundColor:
                                index % 2 == 0 ? "#fafafa" : "white",
                            }}
                          >
                            <div className="unexpanded-row-title">
                              {item.treatment_name}
                            </div>
                            <div className="unexpanded-row-right">
                              <div className="unexpanded-row-cost">
                                {formatNumber(item.treatment_cost)}
                                {iconOsCurrency}
                              </div>
                              {activeIndexes.includes(index) ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </div>
                          </div>
                        </Accordion.Title>
                        <Accordion.Content
                          // active
                          className="expanded-row"
                          style={{
                            backgroundColor:
                              index % 2 == 0 ? "#fafafa" : "white",
                          }}
                          active={activeIndexes.includes(index)}
                        >
                          {!editTreatmentForm ? (
                            <>
                              <div className="expanded-row-date">
                                <Moment format="DD.MM.YYYY">
                                  {item.treatment_date_start}
                                </Moment>
                                -
                                <Moment format="DD.MM.YYYY">
                                  {item.treatment_date_end}
                                </Moment>
                              </div>
                              <div className="expanded-row-description">
                                {item.treatment_description}
                              </div>

                              <div className="injury-edit-delete-buttons treatment-options">
                                <Button
                                  className="custom-button-dual delete-button"
                                  onClick={() =>
                                    deleteTreatment(item.id_treatment)
                                  }
                                >
                                  <div className="button-content">
                                    {languagePack["admin_delete"]}
                                    <Icon
                                      name="trash alternate outline"
                                      className="delete-icon"
                                    />
                                  </div>
                                </Button>
                                <Button
                                  className="custom-button-dual edit-button"
                                  onClick={() => setEditTreatmentForm(true)}
                                >
                                  <div className="button-content">
                                    {languagePack["injury_edit"]}
                                    <Icon name="pencil" className="edit-icon" />
                                  </div>
                                </Button>
                              </div>
                            </>
                          ) : (
                            <EditTreatmentForm
                              close_section={() => {
                                setEditTreatmentForm(false);
                              }}
                              id_treatment={item.id_treatment}
                              treatment_name={item.treatment_name}
                              treatment_cost={item.treatment_cost}
                              date_start={item.treatment_date_start}
                              date_end={item.treatment_date_end}
                              treatment_description={item.treatment_description}
                              languagePack={props.languagePack}
                            />
                          )}
                        </Accordion.Content>
                      </Accordion>
                    </div>
                  );
                })}
              </div>
              {addTreatment ? (
                <div>
                  <AddTreatmentForm
                    get_treatment={get_treatment}
                    id_injury={openedTreatmentId}
                    set_popup={() => setAddTreatment(false)}
                    languagePack={props.languagePack}
                  />
                </div>
              ) : (
                <div className="add-treatment-button-container">
                  <Button
                    className="custom-button-dual add-treatment-button"
                    onClick={() => setAddTreatment(true)}
                  >
                    <div className="button-content">
                      {languagePack["add_treatment"]}
                      <Icon name="add" className="add-icon" />
                    </div>
                  </Button>
                </div>
              )}
            </>
          </DialogContent>
        </Dialog>

        <Dialog
          open={endInjuryPopup}
          // onClose={() => setEndInjuryPopup(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
        >
          <div className="bonus-options-list-popup-header">
            <div className="bonus-options-list-title">
              {languagePack["end_injury"]}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={() => setEndInjuryPopup(false)}
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <DialogContent className="edit-injury-popup-all">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="add-injury-row-end-injury">
                <DatePicker
                  value={endingInjuryDate}
                  onChange={handleChangeEndingDate}
                  format="dd MMMM yyyy"
                  name="injury_date"
                  inputVariant="outlined"
                  className="injury-date-input"
                  emptyLabel={languagePack["return_date"]}
                />
                <Icon
                  name="calendar alternate outline"
                  className="calendar-icon"
                  size="small"
                />
              </div>
            </MuiPickersUtilsProvider>
            {endInjuryError && (
              <div className="end-injury-error-row">{endInjuryError}</div>
            )}
            <div className="end-injury-button-container">
              <Button
                className="end-injury-button"
                onClick={() => finishInjury(endingInjuryId)}
              >
                {languagePack["admin_save"]}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyItemSelected:
      myConstants.currencies[auth?.user?.currency] ||
      myConstants.currencies.EUR,
  }),
  mapDispatchToProps
)(ActiveInjuries);
