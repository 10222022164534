import React from "react";
import Moment from "react-moment";
import { store } from "react-notifications-component";
import "./Documents.styles.scss";
import { Icon, Input, Button } from "semantic-ui-react";
import axios from "axios";
import { logout } from "./../../utils/api";
import { connect } from "react-redux";
import GetAppIcon from "@material-ui/icons/GetApp";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { bindActionCreators } from "redux";
import AddFile from "./AddFile";
import * as myConstants from "./../../../app/utils/constants";

const fileViewOptions = [
  { key: 1, translation: "All documents", value: "Toate documentele" },
  { key: 2, translation: "Players contracts", value: "Contracte jucatori" },
  { key: 3, translation: "Sponsor contracts", value: "Contracte sponsori" },
];

class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      sortedFiles: [],
      addedFile: "",
      selectedFile: "",
      fileViewerOpened: true,
      toBeDeleted: "",
      toBeDeletedId: "",
      toBeDownloadedURL: "",
      displayOptions: "",
      addNewCategoryName: "",
      searchbar: "",

      fileDialogOpened: false,
      contractDialogOpened: false,
      videoPlayDialog: false,
      videoFile: "",
      videoName: "",
    };
  }

  componentDidMount() {
    if (this.props.presetDisplayOption) {
      this.setState(
        {
          displayOptions: this.props.presetDisplayOption,
        },
        () => {
          this.refreshFiles();
        }
      );
    } else {
      this.refreshFiles();
    }
  }

  refreshFiles = () => {
    axios
      .get(`upload/get_files_db`, {
        params: {
          category: this.state.displayOptions,
          id_club: this.props.currentUser.id_club,
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState({
            files: res.data,
            sortedFiles: res.data,
            fileDialogOpened: false,
            contractDialogOpened: false,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.presetDisplayOption !== this.props.presetDisplayOption) {
      this.setState(
        {
          displayOptions: this.props.presetDisplayOption || "",
        },
        () => {
          this.refreshFiles();
        }
      );
    }
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value });
  };

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSearchbarChange = (e) => {
    let currentList = this.state.files;
    let newList = [];

    if (e.target.value !== "") {
      let sortedFiles = this.state.files;
      newList = sortedFiles.filter((item) => {
        const name_lc = item.document_name.toLowerCase();
        const filter = e.target.value.toLowerCase();

        return name_lc.includes(filter);
      });
      this.setState({ sortedFiles: newList });
    } else {
      this.setState({ sortedFiles: currentList });
    }
  };

  async setLoader(value) {
    return new Promise((resolve) => {
      this.setState(
        {
          fileUploading: value,
        },
        () => {
          resolve(value);
        }
      );
    });
  }

  getSVGName = (value) => {
    if (value === "xlsx" || value === "xls") return "xls.svg";
    if (value === "doc" || value === "docx") return "doc.svg";
    if (value === "pdf") return "pdf.svg";
    if (value === "jpg") return "jpg.svg";
    if (value === "png") return "png.svg";
    if (value === "txt") return "txt.svg";
    if (value === "csv") return "csv.svg";
    if (
      value === "m4v" ||
      value === "avi" ||
      value === "mpg" ||
      value === "mp4"
    )
      return "video.svg";
    else return "search.svg";
  };

  deleteFile = () => {
    const deletedFile = {
      id: this.state.toBeDeleted,
    };
    axios
      .post("upload/delete_file", deletedFile, {
        params: {
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.refreshFiles();
          store.addNotification({
            title: "Document operation",
            message: "The file has been deleted",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getIndex(value, arr, prop) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return -1;
  }

  handleChangeDisplayOpt = (e, value) => {
    this.setState({ [value.name]: value.value }, this.refreshFiles);
    switch (value.value) {
      case "Toate documentele":
        this.props.history.replace("/documents");
        break;
      case "Contracte jucatori":
        this.props.history.replace("/documents/players");
        break;
      case "Contracte sponsori":
        this.props.history.replace("/documents/sponsors");
        break;
    }
  };

  playVideo = (item) => {
    this.setState({
      videoFile: item.document_url,
      videoName: item.document_name,
      videoPlayDialog: true,
    });
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="card card-custom">
        <div className="documents-page-container">
          <div className="documents-page-header">
            <div className="searchbar">
              <Input
                icon={{ name: "search", circular: true, link: true }}
                placeholder={languagePack.search}
                className="search-field"
                onChange={this.handleSearchbarChange}
              />
            </div>
            <div className="buttons">
              <Button
                className="documents-header-button"
                onClick={() =>
                  this.setState({
                    fileDialogOpened: true,
                    fileUploading: false,
                  })
                }
              >
                {languagePack.add_new_file}
                <Icon name="add" size="small" className="documents-icon" />
              </Button>
            </div>
          </div>

          <div className="documents-page-list">
            <div className="document-row columns-names">
              <div className="document-name">{languagePack.file_name}</div>
              <div className="document-category">{languagePack.category}</div>
              <div className="document-date-add">{languagePack.date_add}</div>
              <div className="document-added-by">{languagePack.added_by}</div>
              <div className="document-options"></div>
            </div>
            {this.state.sortedFiles.map((row, index) => {
              return (
                <div className="documents-table-container" key={"document-" + index}>
                  <div className="documents-table-content">
                    <div
                      className="document-row columns-names"
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                      }}
                    >
                      <div className="document-name">
                        {languagePack.file_name}
                      </div>
                      <div className="document-category">
                        {languagePack.category}
                      </div>
                      <div className="document-date-add">
                        {languagePack.date_add}
                      </div>
                      <div className="document-added-by">
                        {languagePack.added_by}
                      </div>
                      <div className="document-options"></div>
                    </div>
                    <div
                      className="document-row"
                      key={index}
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                      }}
                    >
                      <div
                        className="document-name"
                        type={this.getSVGName(
                          row.document_name.split(".").pop()
                        )}
                        onClick={() =>
                          this.getSVGName(
                            row.document_name.split(".").pop()
                          ) === "video.svg"
                            ? this.playVideo(row)
                            : void 0
                        }
                      >
                        <img
                          className="file-type-svg"
                          src={`assets/SVG/${this.getSVGName(
                            row.document_name.split(".").pop()
                          )}`}
                        />
                        {row.document_name}
                      </div>
                      <div className="document-category">{row.category}</div>
                      <div className="document-date-add">
                        {" "}
                        <Moment format="DD.MM.YYYY, HH:mm">
                          {row.date_add}
                        </Moment>
                      </div>
                      <div className="document-added-by">
                        {" "}
                        {row.uploaded_by}
                      </div>
                      <div className="document-options">
                        <a
                          style={{ color: "black" }}
                          href={`https://api.thefootballbrain.app/upload/download?document_name=${row.document_name}`}
                        >
                          <GetAppIcon className="mr-10" />
                        </a>
                        <Icon
                          name="trash alternate"
                          onClick={() =>
                            this.setState(
                              {
                                toBeDeleted: row.id_document,
                              },
                              this.deleteFile
                            )
                          }
                          className="delete-icon-documents trash-hover"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* ADAUGARE FISIER */}
          <Dialog
            open={this.state.fileDialogOpened}
          // onClose={() => this.setState({ fileDialogOpened: false })}
          >
            <div className="bonus-options-list-popup-header">
              <div className="bonus-options-list-title">
                {languagePack["add_document"]}
              </div>
              <div className="bonus-options-list-close-icon">
                {" "}
                <Icon
                  name="close"
                  onClick={() => this.setState({ fileDialogOpened: false })}
                  size="large"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>

            <DialogContent className="add-file-container-all">
              <AddFile
                refreshFiles={this.refreshFiles}
                closePopup={() => this.setState({ fileDialogOpened: false })}
              />
            </DialogContent>
          </Dialog>

          {/* PLAY VIDEO */}
          <Dialog
            open={this.state.videoPlayDialog}
            // onClose={() => this.setState({ videoPlayDialog: false })}
            // fullScreen="true"
            maxWidth="lg"
          >
            <div className="close-video">
              <Icon
                name="close"
                onClick={() => this.setState({ videoPlayDialog: false })}
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
            <DialogContent>
              <video
                controls
                autoPlay
                src={this.state.videoFile}
                type="video/mp4"
              ></video>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}

export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyItemSelected:
      myConstants.currencies[auth?.user?.currency] ||
      myConstants.currencies.EUR,
  }),
  mapDispatchToProps
)(Documents);
