import React from "react";
import { Card, Icon, Flag, Dropdown, Button, Popup } from "semantic-ui-react";
import axios from "axios";
import { connect } from "react-redux";


class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicInfo: [],
    };
  }
  dropdownRef = React.createRef();

  componentWillMount = () => {
    // this.getSeasons();
    this.getBasicInfo();
  };

  componentDidMount = () => {
    // this.getPastTests();
  };

  

  getBasicInfo = () => {
    axios
      .get(`user/${this.props.match.params.id}`, {
        params: {
            token: this.props.currentUser.token,
        }
      })
      .then((res) =>
        {
            this.setState({ basicInfo: res.data });
        }
      )
      .catch((err) => console.log(err));
  };

  

  render() {
    // const id_user = this.props.match.params.id;
    const { languagePack } = this.props;

    return (
      <>
        <div className="row">
            <div className="col-lg-2 col-xxl-2 widget">
                {this.state.basicInfo.full_name}c
            </div>
        </div>
      </>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  lang: layoutService.lang,
  currentUser: auth.user,
}))(AddUser);
