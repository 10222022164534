import React, { useState, useEffect } from "react";
import { Button, Icon, TextArea } from "semantic-ui-react";
import axios from "axios";
import { store } from "react-notifications-component";
import "./Teams.scss";
import { connect } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import dateFormat from "dateformat";
import Select from "react-select";

function EditTeamForm(props) {
  const [teamTypes, setTeamTypes] = useState([]);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [transfermarktLink, setTransfermarktLink] = useState("");
  const [coachOptions, setCoachOptions] = useState([]);
  const [coach, setCoach] = useState("");
  const [seasonStartDate, setSeasonStartDate] = useState(null);
  const [seasonEndDate, setSeasonEndDate] = useState(null);
  const [idSeason, setIdSeason] = useState("");

  const { languagePack } = props;

  useEffect(() => {
    getInitialTeamInfo();
    getCoaches();
    getTeamTypes();
  }, []);

  function getInitialTeamInfo() {
    axios
      .get(`club/get_team_info?id_team=${props.id_team}`)
      .then((res) => {
        res.data.type.label = res.data.type.text;
        res.data.coach.label = res.data.coach.text;
        setName(res.data.team_name);
        setCategory(res.data.type);
        setCoach(res.data.coach);
        setSeasonStartDate(res.data.season_start_date);
        setSeasonEndDate(res.data.season_end_date);
        setTransfermarktLink(res.data.transfer_market_link);
        setIdSeason(res.data.id_season);
      })
      .catch((err) => console.log(err));
  }

  function getCoaches() {
    axios
      .get(`club/get_coaches?id_club=${props.id_club}`)
      .then((res) => {
        setCoachOptions(Array.isArray(res.data) ? res.data : []);
      })
      .catch((err) => console.log(err));
  }

  function getTeamTypes() {
    axios
      .get(`club/get_types?id_club=${props.currentUser.id_club}`)
      .then((res) => {
        setTeamTypes(Array.isArray(res.data) ? res.data : []);
      })
      .catch((err) => console.log(err));
  }

  function handleChangeInput(e) {
    setName(e.target.value);
  }

  function handleChangeDateStart(value) {
    setSeasonStartDate(value);
  }

  function handleChangeDateEnd(value) {
    setSeasonEndDate(value);
  }

  function handleChangeTmLink(e) {
    setTransfermarktLink(e.target.value);
  }

  function submitEditTeam() {
    const team = {
      team_name: name,
      id_team_type: category.value,
      id_club: props.id_club,
      id_coach: coach.value,
      season_date_start: dateFormat(seasonStartDate, "yyyy-mm-dd"),
      season_date_end: dateFormat(seasonEndDate, "yyyy-mm-dd"),
      transfer_market_link: transfermarktLink,
      id_season: idSeason,
      id_team: props.id_team,
    };

    axios
      .post(`club/edit_team`, team)
      .then((res) => {
        props.closePopup();
        props.refreshTeams();
        store.addNotification({
          title: languagePack["teams_notification_title"],
          message: languagePack["teams_notification_success"],
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="add-team-container">
      <div className="add-team-header">
        <div className="add-team-title">{languagePack["edit_team"]}</div>
        <Icon
          name="close"
          className="close-icon-popup"
          onClick={() => props.closePopup()}
        />
      </div>
      <div className="add-team-row">
        <input
          type="text"
          value={name}
          onChange={handleChangeInput}
          placeholder={languagePack["team_name"]}
          className="add-team-input"
        />
      </div>
      <div className="add-team-row">
        <Select
          placeholder={languagePack["team_type"]}
          value={category}
          options={teamTypes}
          onChange={(selected) => {
            setCategory(selected);
          }}
          styles={{
            container: (provided, state) => ({
              ...provided,
              backgroundColor: "#fafafa",
              width: "100%",
            }),
            control: (provided, state) => ({
              ...provided,
              backgroundColor: "#fafafa",
              height: "50px",
              border: "none",
              "&:hover": {
                border: "none",
                boxShadow: "0 0 0 2px black",
              },
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              paddingLeft: "22px",
              fontSize: "14px",
              fontWeight: 700,
            }),
            placeholder: (provided, state) => ({
              ...provided,
              color: "#b2b2b2",
            }),
            indicatorSeparator: (provided, state) => ({
              ...provided,
              display: "none",
            }),
            menu: (provided, state) => ({
              ...provided,
              maxHeight: "350px",
            }),
            menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
          }}
          menuPortalTarget={document.body}
        />
      </div>
      <div className="add-team-row">
        <Select
          placeholder={languagePack["team_coordinator"]}
          value={coach}
          options={coachOptions}
          onChange={(selected) => {
            setCoach(selected);
          }}
          styles={{
            container: (provided, state) => ({
              ...provided,
              backgroundColor: "#fafafa",
              width: "100%",
            }),
            control: (provided, state) => ({
              ...provided,
              backgroundColor: "#fafafa",
              height: "50px",
              border: "none",
              "&:hover": {
                border: "none",
                boxShadow: "0 0 0 2px black",
              },
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              paddingLeft: "22px",
              fontSize: "14px",
              fontWeight: 700,
            }),
            placeholder: (provided, state) => ({
              ...provided,
              color: "#b2b2b2",
            }),
            indicatorSeparator: (provided, state) => ({
              ...provided,
              display: "none",
            }),
            menu: (provided, state) => ({
              ...provided,
              maxHeight: "350px",
            }),
            menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
          }}
          menuPortalTarget={document.body}
        />
      </div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="add-team-row">
          <DatePicker
            value={seasonStartDate}
            onChange={handleChangeDateStart}
            format="dd MMMM yyyy"
            inputVariant="outlined"
            className="team-add-date-input"
            emptyLabel={languagePack["season_date_start_add_team"]}
          />
          <Icon
            name="calendar alternate outline"
            className="calendar-icon"
            size="small"
          />
        </div>
        <div className="add-team-row">
          <DatePicker
            value={seasonEndDate}
            onChange={handleChangeDateEnd}
            format="dd MMMM yyyy"
            inputVariant="outlined"
            className="team-add-date-input"
            emptyLabel={languagePack["season_date_end_add_team"]}
          />
          <Icon
            name="calendar alternate outline"
            className="calendar-icon"
            size="small"
          />
        </div>
      </MuiPickersUtilsProvider>
      <TextArea
        placeholder={languagePack["tm_link_team"]}
        value={transfermarktLink}
        onChange={handleChangeTmLink}
        className="tm-link-input"
      />
      <div className="add-team-buttons">
        <Button
          className="custom-button-dual add-button"
          onClick={submitEditTeam}
        >
          <div className="button-content">
            {languagePack["admin_save"]}
            <Icon name="save" className="add-icon" />
          </div>
        </Button>
      </div>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(EditTeamForm);
