import React from "react";
import axios from "axios";
import { logout } from "./../../../utils/api";
import { Input, Divider, Icon, Popup, Button } from "semantic-ui-react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import "./Bonuses.scss";
import DatePicker from "react-datepicker";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import dateFormat from "dateformat";
import { bindActionCreators } from "redux";
import AddTeamBonus from "./AddTeamBonus";
import * as myConstants from "./../../../../app/utils/constants";
import * as utilFunctions from "./../../../../app/utils/utilFunctions";
import Decimal from "decimal.js";

const currencies = Object.entries(myConstants.currencies).map(
  ([uniqueSlug, value]) => value
);

class Bonuses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addBonusDialogOpen: false,
      bonus_amount_total: "",
      bonus_currency: this.props.currencyItemSelected.bonusUniqueSlug,
      past_bonuses: [],
      toBeDeletedBonus: "",
      updatedBonus: "",
      split_equally: true,
      split_type: "",

      paymentDialogOpened: false,
      activeEditedBonus: "",
      payment_type: "",
      paid_amount: "",
      anotherDatePayment: false,
      anotherDatePaymentDate: "",
      totals: [],
    };
  }
  currentExchange = {};

  componentDidMount() {
    this.onInit();
  }

  onInit = (showLoader) => {
    if (!showLoader) {
      this.setState(
        {
          showLoader: true,
        },
        () => {
          this.onInit(true);
        }
      );
      return;
    }
    utilFunctions
      .getCurrentExchange()
      .then((currentExchange) => {
        this.currentExchange = currentExchange;
        this.getBonuses();
      })
      .catch((error) => {
        this.setState({
          showLoader: false,
        });
      });
  };

  getBonuses = (showLoader) => {
    if (!showLoader) {
      this.setState(
        {
          showLoader: true,
        },
        () => {
          this.getBonuses(true);
        }
      );
      return;
    }
    axios
      .get("finances/get_bonuses", {
        params: {
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState({ past_bonuses: this.computeBonuses(res.data) }, () => {
            this.calculateTotals();
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({
          showLoader: false,
        });
      });
  };

  computeBonuses = (data) => {
    if (Array.isArray(data)) {
      return data.map((item) => {
        const currencyItem = currencies.find(
          (cItem) => cItem.bonusUniqueSlug === item.bonus_currency
        );
        const itemFinal = {
          ...item,
          currency: currencyItem
            ? currencyItem.uniqueSlug
            : currencies[0].uniqueSlug,
        };
        return {
          ...itemFinal,
          ...this.amountInAllCurrencies(itemFinal),
        };
      });
    }
    return [];
  };
  calculateTotals = () => {
    const totals = currencies.reduce((final, current) => {
      let bonus_amount = 0;
      this.state.past_bonuses.forEach((item) => {
        bonus_amount += item.amounts[current.uniqueSlug];
      });
      return [...final, { ...current, bonus_amount }];
    }, []);
    this.setState({
      totals,
    });
  };
  amountInAllCurrencies = (bonusItem) => {
    const bonus_amount = Number(bonusItem.bonus_amount) || 0;
    let amountInEur = bonus_amount;
    if (bonusItem.currency !== myConstants.currencies.EUR.uniqueSlug) {
      const rate = Number(this.currentExchange[bonusItem.currency]) || 0;
      amountInEur = new Decimal(bonus_amount).dividedBy(rate);
      amountInEur = Number(amountInEur);
    }
    const amounts = currencies.reduce((final, currencyItem) => {
      let amount = amountInEur;

      if (myConstants.currencies.EUR.uniqueSlug !== currencyItem.uniqueSlug) {
        amount = new Decimal(amountInEur).times(
          Number(this.currentExchange[currencyItem.uniqueSlug]) || 0
        );
        amount = Number(amount);
      }
      return {
        ...final,
        [currencyItem.uniqueSlug]: amount,
      };
    }, {});
    return {
      amounts,
    };
  };

  refreshBonuses = () => {
    this.getBonuses(true);
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      bonus_receiver: "",
      another_bonus_receiver: false,
      bonus_competition: "",
      bonus_criterias: [],
      bonus_amount: "",
      bonus_criteria_added: "",
      bonus_name: "",
      bonus_match: "",
      split_equally: true,
      split_type: "",

      activeEditedBonus: "",
      payment_type: "",
      paid_amount: "",
      anotherDatePayment: false,
      anotherDatePaymentDate: "",
    });
  };

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value });
  };

  handleChangeCurrency = (event, newCurrency) => {
    this.setState({ bonus_currency: newCurrency });
  };

  handleChangeDate = (date) => {
    this.setState({
      anotherDatePaymentDate: date,
    });
  };

  handleChangePaymentType = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  displayCriterias = (value) => {
    const bonus = this.state.past_bonuses.find(
      (elem) => elem.bonus_id === value
    );

    return bonus["criterias"].length > 0 ? (
      bonus["criterias"].map((item) => (
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          key={item.id_bonus_criteria}
        >
          <div style={{ whiteSpace: "nowrap" }}>{item.criteria_name}: </div>
          <div style={{ fontWeight: 700, marginLeft: 30 }}>
            {this.formatNumber(item.criteria_amount)}
          </div>
        </div>
      ))
    ) : (
      <div style={{ textAlign: "center" }}>Criterias not found</div>
    );
  };

  submitBonusUpdate = () => {
    const now = new Date();
    const bonus = {
      id_bonus: this.state.activeEditedBonus,
      date_paid: this.state.anotherDatePayment
        ? dateFormat(this.state.anotherDatePaymentDate, "yyyy-mm-dd")
        : dateFormat(now, "yyyy-mm-dd"),
      paid_amount:
        this.state.payment_type === "total"
          ? this.state.past_bonuses.find(
              (elem) => elem.bonus_id === this.state.activeEditedBonus
            ).bonus_amount
          : this.state.paid_amount,
    };

    axios
      .post("finances/update_bonus", bonus, {
        params: {
          token: this.props.token,
        },
      })
      .then(() => {
        this.refreshBonuses();
        this.handleReset();
        this.setState({ paymentDialogOpened: false });
      })
      .catch((err) => console.log(err));
  };

  deleteBonus = (value) => {
    const bonus = {
      id_bonus: value,
      deleted: 1,
      date_deleted: dateFormat(new Date(), "yyyy-mm-dd"),
    };

    axios
      .post("finances/delete_bonus", bonus, {
        params: {
          token: this.props.token,
        },
      })
      .then(() => {
        this.refreshBonuses();
        this.handleReset();
      })
      .catch((err) => console.log(err));
  };

  formatNumber = (value) => {
    return (
      <NumberFormat
        value={value}
        displayType={"text"}
        decimalScale={2}
        thousandSeparator={true}
      />
    );
  };

  render() {
    const { languagePack } = this.props;

    return (
      <div className="card card-custom">
        <div className="bonuses-page-container">
          <div className="bonuses-header">
            <div className="bonuses-title">Bonusuri de echipa</div>
            <div className="bonuses-buttons">
              <Button
                className="bonus-button"
                onClick={() => this.setState({ addBonusDialogOpen: true })}
              >
                <div className="button-content">
                  {languagePack.Add_team_bonus}
                  <Icon name="add" className="white-icon" />
                </div>
              </Button>
              <Button className="bonus-button">
                <div className="button-content">
                  Adauga prima de joc
                  <Icon name="add" className="white-icon" />
                </div>
              </Button>
            </div>
          </div>

          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{languagePack.Bonus_name}</TableCell>
                  <TableCell align="right">{languagePack.Total_sum}</TableCell>
                  <TableCell align="center">
                    {languagePack.Bonus_type}
                  </TableCell>
                  <TableCell align="center">
                    {languagePack.Bonus_condition}
                  </TableCell>
                  <TableCell align="center">
                    {languagePack.Payment_status}
                  </TableCell>
                  <TableCell align="center">
                    {languagePack.Date_added}
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.past_bonuses.map((row) => {
                  const currencyLabel =
                    languagePack[
                      myConstants.currencies[row.currency].translation
                    ];
                  const iconOsCurrency = myConstants.currencies[row.currency]
                    .iconName ? (
                    <Icon
                      name={myConstants.currencies[row.currency].iconName}
                      color="black"
                      className="mg--0"
                    />
                  ) : (
                    <span className="font-weight-bold">
                      {" "}
                      {` ${currencyLabel}`}{" "}
                    </span>
                  );

                  return (
                    <TableRow hover key={row.bonus_id}>
                      <TableCell scope="row">
                        <div className="bonus-cell-bold">{row.bonus_name}</div>
                      </TableCell>
                      <TableCell align="right">
                        {this.formatNumber(row.bonus_amount)}

                        {iconOsCurrency}
                      </TableCell>
                      <TableCell align="center">{row.bonus_type}</TableCell>
                      <TableCell align="center">
                        <Popup
                          on="click"
                          trigger={<Icon name="ordered list" color="black" />}
                        >
                          <Popup.Content style={{ padding: 0 }}>
                            {this.displayCriterias(row.bonus_id)}
                          </Popup.Content>
                        </Popup>{" "}
                        {languagePack.Fullfiled}
                      </TableCell>
                      <TableCell align="center">
                        <Popup
                          trigger={
                            row.payment_amount >= row.bonus_amount ? (
                              <div>
                                <Icon name="check" color="green" size="large" />
                                {languagePack.Paid}
                              </div>
                            ) : (
                              <div>
                                <Icon name="wait" color="yellow" size="large" />
                                {languagePack.In_pending}
                              </div>
                            )
                          }
                          position="top center"
                          content={
                            <div style={{ display: "flex" }}>
                              <div style={{ marginRight: 8 }}>Paid:</div>
                              {this.formatNumber(row.payment_amount)}/
                              {this.formatNumber(row.bonus_amount)}
                              {iconOsCurrency}
                            </div>
                          }
                          on="click"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Moment format="DD.MM.YYYY">{row.date_add}</Moment>
                      </TableCell>
                      <TableCell align="center">
                        <Popup
                          trigger={<MoreHorizIcon />}
                          position="left center"
                          content={
                            <div className="more-options-container">
                              <Button
                                className="bonus-delete-button"
                                onClick={() => this.deleteBonus(row.bonus_id)}
                              >
                                <div className="button-content">
                                  {languagePack.Delete}
                                  <Icon
                                    name="trash alternate"
                                    className="white-icon trash-hover"
                                  />
                                </div>
                              </Button>
                              <Button
                                className="bonus-change-status-button"
                                onClick={() =>
                                  this.setState({
                                    paymentDialogOpened: true,
                                    activeEditedBonus: row.bonus_id,
                                  })
                                }
                              >
                                <div className="button-content">
                                  {languagePack.Change_status}
                                  <Icon name="credit card" />
                                </div>
                              </Button>
                            </div>
                          }
                          on="click"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {Boolean(this.state.past_bonuses.length) && (
                  <TableRow hover>
                    <TableCell scope="row">
                      <div className="bonus-cell-bold">
                        {languagePack.total}
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      {this.state.totals.map((row) => {
                        const currencyLabel =
                          languagePack[
                            myConstants.currencies[row.uniqueSlug].translation
                          ];
                        const iconOsCurrency = myConstants.currencies[
                          row.uniqueSlug
                        ].iconName ? (
                          <Icon
                            name={
                              myConstants.currencies[row.uniqueSlug].iconName
                            }
                            color="black"
                            className="mg--0"
                          />
                        ) : (
                          <span className="font-weight-bold">
                            {" "}
                            {` ${currencyLabel}`}{" "}
                          </span>
                        );

                        return (
                          <div style={{ marginBottom: 10 }}>
                            {
                              <NumberFormat
                                value={row.bonus_amount}
                                displayType={"text"}
                                decimalScale={2}
                                thousandSeparator={true}
                              />
                            }
                            {iconOsCurrency}
                          </div>
                        );
                      })}
                      {}
                    </TableCell>
                    <TableCell align="center" colSpan="5"></TableCell>
                  </TableRow>
                )}
                {!Boolean(this.state.past_bonuses.length) && (
                  <TableRow hover>
                    <TableCell scope="row" colSpan="7">
                      {languagePack.no_item}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Dialog
            fullWidth
            maxWidth="md"
            open={this.state.addBonusDialogOpen}
            // onClose={() =>
            //   this.setState({ addBonusDialogOpen: false }, this.handleReset)
            // }
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Adauga bonus "}
            </DialogTitle>
            <DialogContent>
              <AddTeamBonus />
            </DialogContent>
          </Dialog>

          <Dialog
            fullWidth
            maxWidth="md"
            open={this.state.paymentDialogOpened}
            // onClose={() => this.setState({ paymentDialogOpened: false })}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Plata bonus"}
            </DialogTitle>
            <DialogContent>
              <RadioGroup
                aria-label="gender"
                name="payment_type"
                value={this.state.payment_type}
                onChange={this.handleChangePaymentType}
              >
                <FormControlLabel
                  value="total"
                  control={<Radio />}
                  label="Plata integrala"
                />
                <FormControlLabel
                  value="partial"
                  control={<Radio />}
                  label="Plata partiala"
                />
              </RadioGroup>
              {this.state.payment_type === "partial" ? (
                <div className="paid-amount">
                  <Input
                    placeholder="Introdu suma platita"
                    className="form-item"
                    onChange={this.handleChangeInput}
                    value={this.state.paid_amount}
                    type="number"
                    name="paid_amount"
                  />
                  <div className="partial-bonus-amount">
                    Out of{" "}
                    {
                      this.state.past_bonuses.find(
                        (elem) => elem.bonus_id === this.state.activeEditedBonus
                      ).bonus_amount
                    }
                  </div>
                </div>
              ) : (
                ""
              )}
              <Divider />
              <div className="paid-amount">
                <Switch
                  checked={this.state.anotherDatePayment}
                  onChange={this.handleChangeCheckbox}
                  color="primary"
                  name="anotherDatePayment"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <div className="partial-bonus-amount">
                  Doresc sa selectez alta data de plata a bonusului:
                </div>

                {this.state.anotherDatePayment ? (
                  <div style={{ paddingLeft: 10, paddingTop: 3 }}>
                    <DatePicker
                      selected={this.state.anotherDatePaymentDate}
                      onChange={this.handleChangeDate}
                      placeholderText="Data platii:"
                      popperPlacement="top"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="buttons-container">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => this.setState({ paymentDialogOpened: false })}
                >
                  {languagePack.GoBack}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 10 }}
                  onClick={this.submitBonusUpdate}
                >
                  {languagePack.Submit}
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyItemSelected:
      myConstants.currencies[auth?.user?.currency] ||
      myConstants.currencies.EUR,
  }),
  mapDispatchToProps
)(Bonuses);
