import React, { useState } from "react";
import * as XLSX from "xlsx";
import DataTable from "react-data-table-component";
import "./ImportCsv.scss";
import { Icon, Checkbox, Button, Dropdown } from "semantic-ui-react";
import axios from "axios";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

function ImportCsv(props) {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [file, setFile] = useState("");
  const [actionName, setActionName] = useState("");
  const [delimitator, setDelimitator] = useState(";");
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(false);

  const dropdownOptions = [
    { key: "player_name", value: "player_name", text: props.languagePack.player_name },
    { key: "player_number", value: "player_number", text: "Player number" },
    { key: "action", value: "action", text: "Action" },
    { key: "side", value: "side", text: "Side" },
    { key: "half", value: "half", text: "Half" },
    { key: "minute", value: "minute", text: "Minute" }
  ];

  const delimitatorOptions = [
    { key: ";", value: ";", text: ";" },
    { key: ",", value: ",", text: "," },
    { key: ":", value: ":", text: ":" },
  ];

  // process CSV data
  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    for (var i = 0; i < headers.length; i++) {
      headers[i] = `Column ${i + 1}`;
    }

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    const columns = headers.map((c, index) => ({
      name: c,
      selector: c,
      id: index,
    }));

    setData(list);
    setColumns(columns);
  };

  // handle file upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setFile(file);
    setSelectedRows([]);
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
    };
    reader.readAsBinaryString(file);
  };

  const handleChangeCheckbox = (id) => {
    if (selectedRows.filter((el) => el.id === id).length > 0) {
      setSelectedRows(selectedRows.filter((item) => item.id !== id));
    } else {
      const el = {
        id: id,
        value: "",
      };
      setSelectedRows(selectedRows.concat(el));
    }
  };

  const handleChangeDropdown = (e, value) => {
    const id = value.name;
    setSelectedRows(
      selectedRows.map((item) =>
        item.id === id ? { ...item, value: value.value } : item
      )
    );
  };

  const handleChangeDelimiter = (e, value) => {
    setDelimitator(value.value);
  };

  const submitCsv = () => {
    setIsFetching(true);
    const data = new FormData();
    data.append("parameters", JSON.stringify(selectedRows));
    data.append("file", file);
    data.append("id_club", props.currentUser.id_club);
    data.append("id_match", props?.history?.location?.state?.id_match);
    data.append("action_name", actionName);
    data.append("delimiter", delimitator);

    axios
      .post("match/upload_csv_envents", data)
      .then((res) => {
        /* store.addNotification({
          title: "Relatia cu suporterii",
          message: "Datele au fost importate cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        history.push("/fans/list"); */
        setIsFetching(false);
      })

      .catch((err) => console.log(err));
  };

  return (
    <div className="card card-custom">
      <div className="import-csv-page-container">
        <div className="import-csv-title">Importa date despre suporteri</div>
        <div className="import-csv-row">
          <div className="step-label">1.</div>
          <div className="import-csv-input-group">
            <input
              className="add-file-form-file-input"
              id="customFile"
              type="file"
              accept=".csv,.xlsx,.xls"
              onChange={handleFileUpload}
            />
            <label className="add-file-form-file-label" htmlFor="customFile">
              {file.name ? file.name : "Incarca fisier .csv"}
            </label>
            <Icon name="arrow up" size="small" />
          </div>
        </div>

        <DataTable pagination highlightOnHover columns={columns} data={data} />
        <div className="import-csv-row">
          <div className="step-label">2.</div>
          <div className="import-csv-row-text">
            Selecteaza campurile pe care doresti sa le salvezi:
          </div>
        </div>

        <div className="import-csv-rows-list">
          {columns.map((item, index) => (
            <div className="csv-row" key={index}>
              <Checkbox
                onChange={() => handleChangeCheckbox(item.id)}
                checked={
                  selectedRows.filter((el) => el.id === item.id).length > 0
                }
                className="checkbox"
              />
              <div className="csv-row-name">{item.name}</div>
              {selectedRows.filter((el) => el.id === item.id).length > 0 && (
                <Dropdown
                  selection
                  name={item.id}
                  options={dropdownOptions}
                  placeholder="Asociat cu..."
                  className="csv-row-dropdown"
                  onChange={handleChangeDropdown}
                />
              )}
            </div>
          ))}
        </div>
        <div className="import-csv-row">
          <div>
            <div className="step-label">3.</div>
            <div className="import-csv-row-text">Introdu numele sursei:</div>
          </div>

          <input
            type="text"
            className="import-csv-input"
            onChange={(e) => setActionName(e.target.value)}
            value={actionName}
            placeholder="ex: Abonare newsletter"
          />
        </div>
        <div className="import-csv-row">
          <div className="step-label">4.</div>
          <div className="import-csv-row-text" style={{ marginRight: 20 }}>
            Introdu delimitator:
          </div>
          <Dropdown
            className="csv-row-dropdown"
            //placeholder={languagePack["select_team"]}
            options={delimitatorOptions}
            selection
            value={delimitator}
            name="delimitator"
            onChange={handleChangeDelimiter}
          />
        </div>
        <div className="import-csv-row" style={{ justifyContent: "flex-end" }}>
          <Button
            className="import-csv-button"
            onClick={submitCsv}
            disabled={isFetching}
          >
            Salveaza
            {isFetching ? (
              <Loader type="TailSpin" color="#ffffff" height={15} width={15} />
            ) : (
              <Icon name="check" size="small" className="import-csv-icon" />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(ImportCsv);
