import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";

import TFBButton from "../../components/design-system/Button/TFBButton";
import TFBIcon from "../../components/design-system/Icon/TFBIcon";

import TFBCard from "../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../components/design-system/Card/TFBCardHeader";
import { TFBTableCell } from "../../components/reusable/useStyles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TFBPlaceholder from "../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBIconButton from "../../components/design-system/IconButton/TFBIconButton";
// import AddUser from "./AddUser";
import EditUser from "./EditUser";
import DeleteUser from "./DeleteUser";
import StatusUser from "./StatusUser";

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userToDelete: "",
      optionsPopupOpen: false,
      users: [],
      addUserPopup: false,
      activeInactiveUserPopup: false,
      editUserPopup: false,
      deleteUserPopup: false,

      userDataToUpdate: "",
      id_user_status: "",
      user_is_active: false,
      id_user_to_update: "",
      id_user_to_delete: "",
    };
  }

  getUsers = () => {
    axios
      .get(
        `user/get_users_list?id_club=${this.props.currentUser.id_club}&id_user=${this.props.currentUser.id_user}&token=${this.props.currentUser.token}`
      )
      .then((res) => this.setState({ users: res.data }))
      .catch((err) => console.log(err));
  };

  componentDidMount() {
    this.getUsers();
  }

  render() {
    const { languagePack } = this.props;
    return (
      <TFBCard>
        <div className="shortlists-page-container">
          <TFBCardHeader title={languagePack.users_management}>
            {/* <TFBButton
              color="green"
              endIcon={true}
              renderIcon={() => <TFBIcon name="add" />}
              onClick={() => this.setState({ addUserPopup: true })}
            >
              {languagePack.add_user}
            </TFBButton> */}
          </TFBCardHeader>

          {this.state.users.length > 0 ? (
            <TFBCardBody>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TFBTableCell>{languagePack.User_name}</TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.account_creation_date}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.team_access}
                      </TFBTableCell>
                      {/* <TFBTableCell align="center">
                        {languagePack.access_module}
                      </TFBTableCell> */}

                      <TFBTableCell align="center">
                        {languagePack.product_actions}
                      </TFBTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.users.map((row, index) => (
                      <TableRow
                        key={"user-management" + index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        <TFBTableCell align="center">
                          <div
                            className="receipt-avatar-player-name"
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                              textAlign: "left",
                            }}
                          >
                            <Avatar
                              className="player-avatar"
                              style={{ cursor: "pointer" }}
                            // src={`https://api.thefootballbrain.app/uploads/players/${row.id_player}.jpg`}
                            />
                            {row.full_name}
                          </div>
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {moment(row.date_add).format("DD.MM.YYYY")}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {row.team_access == "full"
                            ? languagePack.all_teams
                            : languagePack.some_teams}
                        </TFBTableCell>
                        {/* <TFBTableCell align="center">
                        {"Anumite module"}
                      </TFBTableCell> */}

                        <TFBTableCell align="center">
                          <div className="actions-btns-container">
                            <TFBIconButton
                              name={
                                row.active == "1"
                                  ? "active-player"
                                  : "inactive-player"
                              }
                              onClick={() =>
                                this.setState({
                                  activeInactiveUserPopup: true,
                                  id_user_status: row.id_user,
                                  user_is_active:
                                    row.active == "1" ? true : false,
                                })
                              }
                              className="tfb-icon-button-no-filter"
                              color="darkGray"
                            />
                            <TFBIconButton
                              name="edit"
                              color="darkGray"
                              onClick={() =>
                                this.setState({
                                  editUserPopup: true,
                                  id_user_to_update: row.id_user,
                                  userDataToUpdate: row.data_for_edit,
                                })
                              }
                            />
                            <TFBIconButton
                              name="delete"
                              color="darkGray"
                              onClick={() =>
                                this.setState({
                                  deleteUserPopup: true,
                                  id_user_to_delete: row.id_user,
                                })
                              }
                            />
                          </div>
                        </TFBTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TFBCardBody>
          ) : (
            <TFBPlaceholder text="Nu sunt utilizatori." />
          )}
        </div>

        {/* DON T DELETE %%%%%%%%% */}
        {/* <Dialog
          open={this.state.addUserPopup}
          // onClose={() => this.setState({ optionsPopupOpen: false })}
          //   maxWidth="xl"
          maxWidth="sm"
          fullWidth
        >
          <div
            className="bonus-options-list-popup-header"
            // style={{ paddingLeft: 50 }}
          >
            <div className="bonus-options-list-title">
              {languagePack.add_user}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <TFBIconButton
                name="x-small"
                color="darkGray"
                onClick={() => this.setState({ addUserPopup: false })}
              />
            </div>
          </div>
          <DialogContent>
            <AddUser
              //   id_club={this.props.currentUser.id_club}
              //   refreshUsers={this.getUsers}
              //   departments={this.state.departments}
              closePopup={() => this.setState({ addUserPopup: false })}
            />
          </DialogContent>
        </Dialog> */}

        <Dialog open={this.state.editUserPopup} maxWidth="sm" fullWidth>
          <div className="user-management-edit-popup-header">
            <div className="user-management-edit-title">
              {languagePack.edit_user}
            </div>
            <div className="user-management-edit-close-icon">
              <TFBIconButton
                name="x-small"
                color="darkGray"
                onClick={() => {
                  this.getUsers();
                  this.setState({ editUserPopup: false });
                }}
              />
            </div>
          </div>
          <DialogContent>
            <EditUser
              id_club={this.props.currentUser.id_club}
              dataToEdit={this.state.userDataToUpdate}
              refreshUsers={() => this.getUsers()}
              id_user_to_update={this.state.id_user_to_update}
              token={this.props.currentUser.token}
              closePopup={() =>
                this.setState({
                  editUserPopup: false,
                  id_user_to_update: "",
                  userDataToUpdate: "",
                })
              }
            />
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.deleteUserPopup} maxWidth="sm" fullWidth>
          <DialogContent>
            <DeleteUser
              refreshUsers={this.getUsers}
              id_user_to_delete={this.state.id_user_to_delete}
              token={this.props.currentUser.token}
              closePopup={() =>
                this.setState({ deleteUserPopup: false, id_user_to_delete: "" })
              }
            />
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.activeInactiveUserPopup}
          maxWidth="sm"
          fullWidth
        >
          <DialogContent>
            <StatusUser
              refreshUsers={this.getUsers}
              id_user_status={this.state.id_user_status}
              isActive={this.state.user_is_active}
              token={this.props.currentUser.token}
              languagePack={languagePack}
              closePopup={() =>
                this.setState({
                  activeInactiveUserPopup: false,
                  id_user_status: "",
                  user_is_active: "",
                })
              }
            />
          </DialogContent>
        </Dialog>
      </TFBCard>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(UserManagement);
