import React from "react";
import "./PresenceReport.scss";
import axios from "axios";
import { Icon, Button } from "semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/ro";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ProgressBar } from "react-bootstrap";
import "./PrecenseList.scss";

import Loader from "react-loader-spinner";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder.tsx";

import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBDropdown from "../../../components/design-system/Dropdown/TFBDropdown";

class PresenceReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      teamOptions: [],
      idSelectedTeam: "",
      players: [],

      dateStart: null,
      dateEnd: null,
    };
  }

  componentDidMount = () => {
    this.getTeamsDropdown();
  };

  getTeamsDropdown = () => {
    axios
      .get(
        `training/get_teams_dropdown?id_club=${this.props.currentUser.id_club}&all=0`
      )
      .then((res) => {
        this.setState(
          {
            teamOptions: res.data,
            idSelectedTeam: res.data[0].value,
          },
          () => {
            this.getPlayersPresence(res.data[0].value);
          }
        );
      });
  };

  handleToggleLoader = () => {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
    } else {
      this.setState({ isLoading: false });
    }
  };

  getPlayersPresence = (idTeam, start, end) => {
    if (!start) {
      axios
        .get(
          `training/get_players_report_presence?id_team=${idTeam}&token=${this.props.token}`
        )
        .then((res) => {
          this.handleToggleLoader();
          this.setState((prevState) => ({
            ...prevState,
            dateStart: res.data.start,
            dateEnd: res.data.end,
            players: res.data.players,
            idSelectedTeam: idTeam,
          }));
        })
        .catch((err) => console.log(err));
    }

    if (start && end) {
      axios
        .get(
          `training/get_players_report_presence?id_team=${idTeam}&start=${start}&end=${end}&token=${this.props.token}`
        )
        .then((res) => {
          this.handleToggleLoader();
          this.setState((prevState) => ({
            ...prevState,
            dateStart: res.data.start,
            dateEnd: res.data.end,
            players: res.data.players,
          }));
        })
        .catch((err) => console.log(err));
    }
  };

  handleChangeTeam = (e, value) => {
    this.setState((prevState) => ({
      ...prevState,
      idSelectedTeam: value.value,
    }));
  };

  handleShowPresence = () => {
    this.getPlayersPresence(
      this.state.idSelectedTeam,
      this.state.dateStart,
      this.state.dateEnd
    );
  };

  render() {
    const { languagePack } = this.props;
    return (
      <TFBCard>
        <div className="presence-history-container plan-training-list-container">
          <TFBCardHeader title={languagePack.attendance_report}></TFBCardHeader>

          <div className="presence-report-training-interactive">
            <Dropdown
              placeholder={languagePack["select_team"]}
              selection
              options={this.state.teamOptions}
              className="training-teams-dropdown"
              name="selected_team"
              value={this.state.idSelectedTeam}
              onChange={this.handleChangeTeam}
            />

            {/* <TFBDropdown
              placeholder={languagePack["select_team"]}
              selection={true}
              options={this.state.teamOptions}
              // className="training-teams-dropdown"
              // name="selected_team"
              value={this.state.idSelectedTeam}
              onChange={this.handleChangeTeam}
            /> */}

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="presence-training-date-pickers">
                <DatePicker
                  value={this.state.dateStart}
                  onChange={(selected) => {
                    this.setState(
                      (prevState) => ({
                        ...prevState,
                        dateStart: moment(selected).format("YYYY-MM-DD"),
                      }),
                      () => {
                        if (this.state.dateEnd < this.state.dateStart) {
                          this.setState({
                            players: [],
                          });
                        }
                      }
                    );
                  }}
                  emptyLabel={languagePack.start_date}
                  format="dd.MM.yyyy"
                  inputVariant="outlined"
                  className="presence-training-date-input"
                />
                <Icon
                  name="calendar alternate outline"
                  className="calendar-icon"
                  size="small"
                  style={{ position: "relative", left: "-35px", width: "0px" }}
                />
                {"-"}
                <DatePicker
                  value={this.state.dateEnd}
                  onChange={(selected) => {
                    this.setState(
                      (prevState) => ({
                        ...prevState,
                        dateEnd: moment(selected).format("YYYY-MM-DD"),
                      }),
                      () => {
                        if (this.state.dateEnd < this.state.dateStart) {
                          this.setState({
                            players: [],
                          });
                        }
                      }
                    );
                  }}
                  emptyLabel={languagePack.end_date}
                  format="dd.MM.yyyy"
                  inputVariant="outlined"
                  className="presence-training-date-input"
                />
                <Icon
                  name="calendar alternate outline"
                  className="calendar-icon"
                  size="small"
                  style={{ position: "relative", left: "-35px", width: "0px" }}
                />
              </div>
            </MuiPickersUtilsProvider>

            <Button
              className="show-presence-button"
              onClick={() => {
                this.handleToggleLoader();
                this.handleShowPresence();
              }}
              disabled={this.state.dateEnd < this.state.dateStart}
            >
              <div className="button-content">{languagePack.show_presence}</div>
            </Button>
          </div>
          <TFBCardBody>
            {this.state.isLoading ? (
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <Loader
                  type="TailSpin"
                  color={window.clubCustomization.primary_color}
                  height={60}
                  width={60}
                />
              </div>
            ) : this.state.dateEnd < this.state.dateStart ? (
              <div className="no-presence-call-up-message">
                <Icon name="exclamation" color="red" size="big" />
                <div
                  className="no-presence-call-up-message-text"
                  style={{ color: "red" }}
                >
                  {languagePack.start_date_greater_than_end_date}
                </div>
              </div>
            ) : (
              this.state.players.length < 1 && (
                <TFBPlaceholder
                  text={languagePack.no_attendances_in_the_selected_period}
                />
              )
            )}

            <div className="precense-list-wrapper">
              {!this.state.isLoading &&
                !(this.state.dateEnd < this.state.dateStart) &&
                this.state.players.map((item, index) => (
                  <div className="all-time-presence-list" key={index}>
                    <div className="precense-row">
                      <div className="player-name">{item.player_name}</div>
                      <div className="player-presence-list-progress-bar">
                        {item.percentage < 1 && (
                          <>
                            <div className="no-presence">
                              {/* {`Fără prezențe în ${item.total_nr_of_trainings} antrenament(e)`} */}
                              {`${item.player_nr_of_presents}/${item.total_nr_of_trainings}`}
                            </div>
                          </>
                        )}
                        <ProgressBar
                          className="player-presence-progress"
                          label={`${item.player_nr_of_presents}/${item.total_nr_of_trainings}`}
                          now={item.percentage}
                          variant={item.class}
                          placeholder="Nu are prezenta"
                        ></ProgressBar>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {/* </div> */}
          </TFBCardBody>
        </div>
      </TFBCard>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(PresenceReport);
