import React from "react";
import Select from "react-select";
import { Icon, Button, Input } from "semantic-ui-react";
import Avatar from "@material-ui/core/Avatar";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { store } from "react-notifications-component";
import { Redirect } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import { logout } from "./../../../utils/api";
import CircularLoader from "../../../components/reusable/Loader";

import "./RpeTraining.scss";

class RpeTraining extends React.Component {
  constructor(props) {
    super(props);
    const url_params = new URLSearchParams(props.location.search);
    this.state = {
      has_access_to_training: true,
      redirect_to_training_history: false,

      is_fetching: true,
      id_season: "",
      fetching_error: "",

      players: [],
      training_duration: "",
      training_venue: "",
      team_name: "",
      selected_team: "",
      training_date: "",

      overall_rpe_before: 0,
      overall_rpe_after: 0,
      overall_impact: 0,
      global_acumulation: 0,

      rpe_start_date:
        new Date(url_params.get("start")) > 0
          ? new Date(url_params.get("start"))
          : null,
      rpe_end_date:
        new Date(url_params.get("end")) > 0
          ? new Date(url_params.get("end"))
          : null,
      view_rpe_trainings: false,
      trainings_list: [],
      selected_training: null,
    };
  }

  componentDidMount = () => {
    this.hasAccessToTraining();
  };

  hasAccessToTraining = () => {
    axios
      .post(`user/has_access_to_training`, {
        id_club: this.props.currentUser.id_club,
        id_training: this.props.match.params.id,
      })
      .then((res) => {
        if (res.data == 1) {
          this.setState({
            has_access_to_training: true,
          });
          this.getPlayers(this.props.match.params.id, 1);
        } else {
          this.setState({
            redirect_to_training_history: true,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getPlayers = (param, updateTrainingsList = 0) => {
    let url = "";
    if (this.state.rpe_start_date != null && this.state.rpe_end_date) {
      const start = moment(new Date(this.state.rpe_start_date)).format(
        "YYYY-MM-DD"
      );
      const end = moment(new Date(this.state.rpe_end_date)).format(
        "YYYY-MM-DD"
      );
      url = `training/get_training_session_details_rpe?id_training=${param}&start=${start}&end=${end}`;
    } else {
      url = `training/get_training_session_details_rpe?id_training=${param}`;
    }
    axios
      .get(url)
      .then((res) => {
        let playersArray = res.data.players;
        playersArray.forEach((player, index) => {
          if (player.rpe_time == null) player.rpe_time = res.data.duration;
          // if (index == 1) player.injuried = true;
        });
        this.setState(
          {
            players: Array.isArray(playersArray) ? res.data.players : [],
            selected_team: {
              label: res.data.team.team_name,
              value: res.data.team.id_team,
            },
            training_venue: res.data.venue,
            team_name: res.data.team.team_name,
            training_date: res.data.date,
            training_duration: res.data.duration,
            is_fetching: false,
          },
          () => {
            this.state.players.map((player, index) => {
              if (player.was_present == 1)
                this.calculateUa(player.rpe_time, player.rpe_after, index);
            });

            this.overallRpeAfter();
            this.overallRpeBefore();
            this.calculateImpact();
            this.calculateGlobalAcumulation();

            if (
              this.state.rpe_start_date != null &&
              this.state.rpe_end_date &&
              updateTrainingsList == 1
            ) {
              this.handleShowTrainings();
            }
          }
        );
      })
      .catch((err) => console.log(err));
  };

  handleShowTrainings = (updatePlayers = 0) => {
    //set url params
    const team = this.state.selected_team.value;
    const start = moment(new Date(this.state.rpe_start_date)).format(
      "YYYY-MM-DD"
    );
    const end = moment(new Date(this.state.rpe_end_date)).format("YYYY-MM-DD");
    const id_training = this.props.match.params.id;
    let url = this.props.location.search;
    let search_params = new URLSearchParams(url);
    search_params.set("start", start);
    search_params.set("end", end);
    window.history.replaceState(null, null, "?" + search_params.toString());

    axios
      .get(
        `training/get_training_sessions_select_list?team=${team}&history=1&start=${start}&end=${end}`
      )
      .then((res) => {
        const trainings = res.data;
        let training = trainings.find((elem) => elem.value == id_training);
        if (updatePlayers == 1) {
          this.getPlayers(id_training);
        }
        this.setState({
          trainings_list: res.data,
          view_rpe_trainings: true,
          selected_training: training != undefined ? training : null,
        });
      })
      .catch((err) => console.log(err));
  };

  overallRpeAfter = () => {
    let playersArray = this.state.players;
    let allRpe = [];
    playersArray.forEach((player) => {
      if (player.was_present == 1 && player.rpe_after != null) {
        allRpe.push(Number(player.rpe_after));
      }
    });
    if (allRpe.length > 0) {
      let sum = allRpe.reduce((a, b) => a + b, 0);
      let average = sum / allRpe.length;

      this.setState((prevState) => ({
        ...prevState,
        overall_rpe_after: average,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        overall_rpe_after: 0,
      }));
    }
  };

  overallRpeBefore = () => {
    let playersArray = this.state.players;
    let allRpe = [];
    playersArray.forEach((player) => {
      if (player.was_present == 1 && player.rpe_before != null) {
        allRpe.push(Number(player.rpe_before));
      }
    });

    if (allRpe.length > 0) {
      let sum = allRpe.reduce((a, b) => a + b, 0);
      let average = sum / allRpe.length;

      this.setState((prevState) => ({
        ...prevState,
        overall_rpe_before: average,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        overall_rpe_before: 0,
      }));
    }
  };

  calculateImpact = () => {
    let playersArray = this.state.players;
    let allUa = [];
    playersArray.forEach((player) => {
      if (player.was_present == 1 && player.ua != undefined) {
        allUa.push(Number(player.ua));
      }
    });
    let sum = allUa.reduce((a, b) => a + b, 0);
    let average = sum / allUa.length;

    this.setState((prevState) => ({
      ...prevState,
      overall_impact: average,
    }));
  };

  calculateGlobalAcumulation = () => {
    let playersArray = this.state.players;
    let allUaTotal = [];
    playersArray.forEach((player) => {
      if (player.was_present == 1 && player.ua != undefined) {
        allUaTotal.push(Number(player.ua + player.ua_total));
      }
    });
    let sum = allUaTotal.reduce((a, b) => a + b, 0);
    let average = sum / allUaTotal.length;

    this.setState((prevState) => ({
      ...prevState,
      global_acumulation: average,
    }));
  };

  calculateUa = (time, rpe_after, index) => {
    let playersCopy = this.state.players;
    let uaValue = time * rpe_after;
    playersCopy[index].ua = uaValue;

    this.setState((prevState) => ({
      ...prevState,
      players: playersCopy,
    }));
  };

  changeTrainingPlayerTime = (e, index) => {
    let playersCopy = this.state.players;
    playersCopy[index].rpe_time = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      players: playersCopy,
    }));
  };

  changeRpeBefore = (e, index) => {
    if (
      e.target.value.length > 2 ||
      (e.target.value.length == 2 && Number(e.target.value) > 10)
    )
      return false;

    let playersCopy = this.state.players;
    playersCopy[index].rpe_before = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      players: playersCopy,
    }));
  };

  changeRpeAfter = (e, index) => {
    if (
      e.target.value.length > 2 ||
      (e.target.value.length == 2 && Number(e.target.value) > 10)
    )
      return false;

    let playersCopy = this.state.players;
    playersCopy[index].rpe_after = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      players: playersCopy,
    }));
  };

  changeWeightBefore = (e, index) => {
    if (!/^\d*\.?\d*$/.test(e.target.value)) return false;

    let playersCopy = this.state.players;
    playersCopy[index].rpe_weight_before = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      players: playersCopy,
    }));
  };

  changeWeightAfter = (e, index) => {
    if (!/^\d*\.?\d*$/.test(e.target.value)) return false;

    let playersCopy = this.state.players;
    playersCopy[index].rpe_weight_after = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      players: playersCopy,
    }));
  };

  getUaTotalClass = (value) => {
    let className = "";
    if (value >= 300 && value < 500) {
      className = "ua-low";
    } else if (value >= 500 && value < 700) {
      className = "ua-optimal";
    } else if (value >= 700 && value <= 1000) {
      className = "ua-high";
    }
    return className;
  };

  saveData = () => {
    let payload = [];

    this.state.players.forEach((player) => {
      payload.push({
        id_training_session_player: player.id_training_session_player,
        rpe_after: player.rpe_after,
        rpe_before: player.rpe_before,
        rpe_time: player.rpe_time,
        rpe_weight_after: player.rpe_weight_after,
        rpe_weight_before: player.rpe_weight_before,
      });
    });

    axios
      .post(`training/update_rpe_player_details_training`, payload)
      .then((res) => {
        if (res.status == 200) {
          store.addNotification({
            title: this.props.languagePack.trainings,
            message: this.props.languagePack.rpe_details_saved_successfully,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: this.props.languagePack.trainings,
            message: this.props.languagePack.rpe_details_saved_failed,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;

    if (this.state.redirect_to_training_history) {
      return <Redirect to="/training/history" />;
    }

    return (
      <div>
        {this.state.fetching_error === "" ? (
          <div className="card card-custom" style={{ padding: 50 }}>
            <div className="rpe-filter-trainings-wrapper">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="rpe-training-date-pickers">
                  <DatePicker
                    value={this.state.rpe_start_date}
                    onChange={(selected) => {
                      this.setState((prevState) => ({
                        ...prevState,
                        rpe_start_date: selected,
                        view_rpe_trainings: false,
                      }));
                    }}
                    emptyLabel={languagePack.start_date}
                    format="dd.MM.yyyy"
                    inputVariant="outlined"
                    className="rpe-training-date-input"
                  />
                  <DatePicker
                    value={this.state.rpe_end_date}
                    onChange={(selected) => {
                      this.setState((prevState) => ({
                        ...prevState,
                        rpe_end_date: selected,
                        view_rpe_trainings: false,
                      }));
                    }}
                    emptyLabel={languagePack.end_date}
                    format="dd.MM.yyyy"
                    inputVariant="outlined"
                    className="rpe-training-date-input"
                  />
                </div>
              </MuiPickersUtilsProvider>
              <div className="rpe-view-selected-training-wrapper">
                <Button
                  className="selected-training-button"
                  onClick={() => {
                    this.handleShowTrainings(1);
                  }}
                >
                  <div className="button-content">
                    {languagePack.show_trainings}
                  </div>
                </Button>
                {this.state.view_rpe_trainings && (
                  <Select
                    placeholder={languagePack.show_training}
                    options={this.state.trainings_list}
                    value={this.state.selected_training}
                    onChange={(selected) => {
                      const start = moment(
                        new Date(this.state.rpe_start_date)
                      ).format("YYYY-MM-DD");
                      const end = moment(
                        new Date(this.state.rpe_end_date)
                      ).format("YYYY-MM-DD");
                      this.props.history.push(
                        `/training/rpe/${selected.value}?start=${start}&end=${end}`
                      );
                    }}
                    styles={{
                      container: (provided, state) => ({
                        ...provided,
                        backgroundColor: "#fafafa",
                        width: "100%",
                        borderRadius: "4px",
                        border: "1px solid #c0c0c0",
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: "#fafafa",
                        height: "50px",
                        border: "none",
                        "&:hover": {
                          border: "none",
                          boxShadow: "0 0 0 2px black",
                        },
                      }),
                      valueContainer: (provided, state) => ({
                        ...provided,
                        paddingLeft: "22px",
                        fontSize: "14px",
                        fontWeight: 700,
                      }),
                      placeholder: (provided, state) => ({
                        ...provided,
                        color: "#b2b2b2",
                      }),
                      indicatorSeparator: (provided, state) => ({
                        ...provided,
                        display: "none",
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        maxHeight: "350px",
                      }),
                    }}
                  />
                )}
              </div>
            </div>
            {this.state.is_fetching ? (
              <div className="flex-all">
                <CircularLoader />
              </div>
            ) : (
              <>
                <div className="rpe-page-header">
                  <div className="rpe-page-title">
                    {`Antrenament ${this.state.team_name}, ${moment(
                      this.state.training_date
                    ).format("DD.MM.YYYY")} - ${moment(
                      this.state.training_date
                    ).format("HH:mm")}, ${this.state.training_venue}`}
                  </div>
                  <div className="rpe-page-header-right">
                    <Button
                      className="present-list-button"
                      style={{ marginLeft: 10 }}
                      onClick={() =>
                        this.props.history.push(
                          `/training/history/details/${this.props.match.params.id}`
                        )
                      }
                    >
                      <div className="button-content">
                        {languagePack.see_presence_list}
                      </div>
                    </Button>
                    <Button
                      className="add-player-button"
                      onClick={() => this.saveData()}
                    >
                      <div
                        className="button-content"
                        style={{ display: "flex", gap: "15px" }}
                      >
                        {languagePack.save_data}
                        <Icon
                          name="add"
                          className="white-icon"
                          style={{ height: "100%" }}
                        />
                      </div>
                    </Button>
                  </div>
                </div>
                <div className="overall-stats-training-rpe">
                  <div className="rpe-duration">
                    <div className="title">{languagePack.duration}</div>
                    <div className="wrapper-duration-edit">
                      <div className="text">{`${this.state.training_duration} min`}</div>
                    </div>
                  </div>
                  <div className="rpe-before">
                    <div className="title">{languagePack.rpe_before}</div>
                    <div className="text">
                      {Number(this.state.overall_rpe_before).toFixed(2)}
                    </div>
                  </div>
                  <div className="rpe-after">
                    <div className="title">{languagePack.rpe_after}</div>
                    <div className="text">
                      {Number(this.state.overall_rpe_after).toFixed(2)}
                    </div>
                  </div>
                  <div className="rpe-impact">
                    <div className="title">{"Impact"}</div>
                    <div className="text">
                      {Number(this.state.overall_impact).toFixed(2) != "NaN"
                        ? Number(this.state.overall_impact).toFixed(2)
                        : "-"}
                    </div>
                  </div>
                  <div className="rpe-global-acumulation">
                    <div className="title">
                      {languagePack.global_accumulation}
                    </div>
                    <div className="text">
                      {Number(this.state.global_acumulation).toFixed(2) != "NaN"
                        ? Number(this.state.global_acumulation).toFixed(2)
                        : "-"}
                    </div>
                  </div>
                </div>
                {this.state.players.length > 0 ? (
                  <div className="rpe-players-table">
                    <div className="rpe-players-table-header">
                      <div className="player-name">{languagePack.player}</div>
                      <div className="was-present-column">
                        {languagePack.presence}
                      </div>
                      <div className="rpe rpe-column">{"RPE (10)"}</div>
                      <div className="time rpe-column">
                        {languagePack.time_min + " (min)"}
                      </div>
                      <div className="rpe rpe-column">{"RPE(10)"}</div>
                      <div className="ua rpe-column">{"UA"}</div>
                      <div className="ua-total rpe-column">{"UA total"}</div>
                      <div className="variability">
                        {languagePack.variability_between_workouts}
                      </div>
                      <div className="injury">
                        {languagePack.injuruy_general}
                      </div>
                      <div className="weight rpe-column">
                        {languagePack.kg_before}
                      </div>
                      <div className="weight rpe-column">
                        {languagePack.kg_after}
                      </div>
                      <div className="player-options"></div>
                    </div>
                    <div className="rpe-players-table-list">
                      {this.state.players.map((player, index) => (
                        <div key={index} className="rpe-players-container">
                          <div className="rpe-players-content">
                            <div
                              className="rpe-players-table-row"
                              style={{
                                backgroundColor:
                                  index % 2 == 0 ? "#fafafa" : "white",
                              }}
                            >
                              <div className="player-name">
                                <Avatar
                                  className="player-avatar"
                                  src={`${window.baseURL}uploads/players/${player.id_player}.jpg`}
                                />
                                <div
                                  onClick={() =>
                                    this.props.history.push(
                                      `/club/player/${player.id_player}?id_season=${this.state.id_season}`
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                    color:
                                      player.was_present == 1
                                        ? "black"
                                        : "#b2b2b2",
                                  }}
                                  key={index}
                                >
                                  <div>{player.player_name}</div>
                                  {player.was_present == 1 && (
                                    <div
                                      style={{
                                        fontSize: "13px",
                                        color: "#b2b2b2",
                                      }}
                                    >
                                      {player.training_comment}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="was-present-column">
                                {player.was_present == 1 ? (
                                  <Icon
                                    name="check"
                                    size="large"
                                    className="green-icon"
                                  />
                                ) : (
                                  <Icon
                                    name="close"
                                    size="large"
                                    className="red-icon"
                                  />
                                )}
                              </div>
                              <div className="rpe rpe-column">
                                {player.was_present == 0 ? (
                                  "-"
                                ) : (
                                  <Input
                                    onKeyPress={(event) => {
                                      if (!/\b[0-9]\b/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    placeholder="Val"
                                    className="add-value-input-rpe1 rpe-input"
                                    name="rpe-before"
                                    onChange={(e) => {
                                      this.changeRpeBefore(e, index);
                                      this.overallRpeBefore();
                                    }}
                                    value={player.rpe_before ?? ""}
                                  />
                                )}
                              </div>
                              <div className="time rpe-column">
                                {player.was_present == 0 ? (
                                  "-"
                                ) : (
                                  <Input
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    placeholder="Val"
                                    className="add-value-input-time rpe-input"
                                    name="time"
                                    onChange={(e) => {
                                      this.changeTrainingPlayerTime(e, index);
                                      this.calculateUa(
                                        player.rpe_time,
                                        player.rpe_after,
                                        index
                                      );
                                      this.calculateImpact();
                                      this.calculateGlobalAcumulation();
                                    }}
                                    value={player.rpe_time ?? ""}
                                  />
                                )}
                              </div>
                              <div className="rpe rpe-column">
                                {player.was_present == 0 ? (
                                  "-"
                                ) : (
                                  <Input
                                    onKeyPress={(event) => {
                                      if (!/\b[0-9]\b/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    placeholder="Val"
                                    className="add-value-input-rpe2 rpe-input"
                                    name="rpe-after"
                                    onChange={(e) => {
                                      this.changeRpeAfter(e, index);
                                      this.calculateUa(
                                        player.rpe_time,
                                        player.rpe_after,
                                        index
                                      );
                                      this.overallRpeAfter();
                                      this.calculateImpact();
                                      this.calculateGlobalAcumulation();
                                    }}
                                    value={player.rpe_after ?? ""}
                                  />
                                )}
                              </div>
                              <div className={`ua rpe-column`}>
                                <div
                                  className={`ua-value ${this.getUaTotalClass(
                                    player.ua
                                  )}`}
                                >
                                  {player.was_present == 0
                                    ? "-"
                                    : player.ua ?? "-"}
                                </div>
                              </div>
                              <div className="ua-total rpe-column">
                                {player.was_present == 0
                                  ? "-"
                                  : player.ua + player.ua_total ?? "-"}
                              </div>
                              <div className="variability">
                                {player.was_present == 0
                                  ? "-"
                                  : Number(
                                      (player.ua - player.last_training_ua) / 10
                                    ).toFixed(0) != "NaN"
                                  ? Number(
                                      (player.ua - player.last_training_ua) / 10
                                    ).toFixed(0) + "%"
                                  : "-"}
                              </div>
                              <div className="injury">{"-"}</div>
                              <div className="weight rpe-column">
                                {player.was_present == 0 ? (
                                  "-"
                                ) : (
                                  <Input
                                    placeholder="Val"
                                    className="add-value-input-weight rpe-input"
                                    name="weight-before"
                                    onChange={(e) =>
                                      this.changeWeightBefore(e, index)
                                    }
                                    value={player.rpe_weight_before ?? ""}
                                  />
                                )}
                              </div>
                              <div className="weight rpe-column">
                                {player.was_present == 0 ? (
                                  "-"
                                ) : (
                                  <Input
                                    placeholder="Val"
                                    className="add-value-input-weight rpe-input"
                                    name="weight-after"
                                    onChange={(e) =>
                                      this.changeWeightAfter(e, index)
                                    }
                                    value={player.rpe_weight_after ?? ""}
                                  />
                                )}
                              </div>
                              <div className="player-rpe-options">
                                {/* <div
                                      className="delete-rpe-icon-container trash-hover"
                                      //   onClick={() =>
                                      //     this.props.history.push(
                                      //       `/club/player/${player.id_player}?id_season=${this.state.id_season}`
                                      //     )
                                      //   }
                                    >
                                      <Icon className="trash alternate delete-icon" />
                                    </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="placeholder-files">
                    <Icon
                      name="search minus"
                      size="huge"
                      className="placeholder-icon-team-page"
                    />
                    <div className="placeholder-text">
                      {languagePack["no_players_placeholder"]}
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="button-wrapper-save-data">
              <Button
                className="add-player-button"
                onClick={() => this.saveData()}
              >
                <div className="button-content">
                  {languagePack.save_data}
                  <Icon
                    name="add"
                    className="white-icon"
                    style={{ height: "100%" }}
                  />
                </div>
              </Button>
            </div>
          </div>
        ) : (
          <div className="access-error-team-page">
            <Icon name="ban" size="large" className="access-error-icon" />
            {this.state.fetching_error}
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(RpeTraining);
