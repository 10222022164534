import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { logout } from "./../../utils/api";
import Moment from "react-moment";
import moment from "moment";
import { Icon, Flag, Button, Dropdown } from "semantic-ui-react";
import Avatar from "@material-ui/core/Avatar";
import { bindActionCreators } from "redux";
import CircularLoader from "../../components/reusable/Loader";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddPlayer from "./AddPlayer";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye-white.svg";
import { store } from "react-notifications-component";
import { Redirect, Switch, Route } from "react-router-dom";
import Select from "react-select";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TFBCheckbox from "../../components/design-system/Checkbox/TFBCheckbox";
import TFBButton from "../../components/design-system/Button/TFBButton";
import TFBIcon from "../../components/design-system/Icon/TFBIcon";
import ExportPlayers from "./ClubProfile/ExportPlayers/ExportPlayers";

class Team extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      players: [],
      total_mv: 0,
      team_type: "",
      team_name: "",
      average_age: 0,
      isFetching: false,
      addPlayerDialog: false,
      sortConfig: {
        type: "",
        descending: false,
      },

      editNameDialog: false,
      deleteTeamDialog: false,
      newTeamName: "",
      id_season: "",
      current_season: "",
      seasons: [],
      fetchingError: "",
      hasAccessToTeam: false,
      redirectToTeams: false,

      mappingInstatDialog: false,
      idPlayerForInstatMapping: "",
      playerNameForInstat: "",
      idInstatInitial: "",
      playersFromInstat: [],
      selectedPlayer: null,
      height: "initial",

      inactive_pop_up: false,
      set_inactive_player: "",
      inactive_reason: "",
      inactive_from_date: null,

      exportPlayersList: false,

      allChecked: false,

      hideInactivePlayers: true,
    };
  }

  componentWillMount = () => {
    this.getSeasons();
  };

  componentDidMount = () => {
    this.hasAccessToTeam();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hideInactivePlayers !== this.state.hideInactivePlayers) {
      this.getPlayers();
    }
  }

  handleHeaderCheckbox = (e) => {
    let playersArray = [...this.state.players];
    if (e.target.checked == true) {
      playersArray.map((player) => {
        return (player.checked = true);
      });
    } else {
      playersArray.map((player) => {
        return (player.checked = false);
      });
    }

    this.setState({ allChecked: e.target.checked, players: playersArray });
  };

  hasAccessToTeam = () => {
    axios
      .get(
        `user/has_access_to_team?id_team=${this.props.match.params.id}&id_user=${this.props.currentUser.id_user}`
      )
      .then((res) => {
        if (res.data == 1) {
          this.setState({
            hasAccessToTeam: true,
          });
          this.getPlayers();
          this.getPlayersFromInstat();
        } else {
          this.setState({
            redirectToTeams: true,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getSeasons = () => {
    axios
      .get(`club/get_seasons?id_team=${this.props.match.params.id}`)
      .then((res) =>
        this.setState({
          seasons: res.data.seasons,
          id_season: res.data.activeSeason,
          current_season: res.data.activeSeason,
        })
      )
      .catch((err) => console.log(err));
  };

  getPlayers = () => {
    const id_team = this.props.match.params.id;
    this.setState({ isFetching: true });
    axios
      .get(`club/get_players`, {
        params: {
          id_team,
          token: this.props.token,
          id_season: this.state.id_season,
        },
      })
      .then((res) => {
        if (res?.data?.success == 0) {
          this.setState({
            fetchingError: this.props.languagePack[res.data.message],
          });
        } else {
          let playersList = res.data.players.map((player) => {
            return { ...player, checked: false };
          });

          if (this.state.hideInactivePlayers) {
            playersList = playersList.filter((player) => player.inactive == 0);
          }

          this.setState({
            players: Array.isArray(playersList) ? playersList : [],
            total_mv: res.data.total_mv,
            average_age: res.data.average_age,
            team_type: res.data.team_type,
            team_name: res.data.team_name,
            newTeamName: res.data.team_name,
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({
          isFetching: false,
        });
      });
  };

  handleChangeInactivePlayers = () => {
    this.setState({ hideInactivePlayers: !this.state.hideInactivePlayers });
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value }, this.getPlayers);
  };

  intlFormat(num) {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
  }

  saveEditTeamName = () => {
    const payload = {
      id_team: this.props.match.params.id,
      new_name: this.state.newTeamName,
    };
    axios.post("club/edit_team_name", payload).then((res) => {
      store.addNotification({
        title: this.props.languagePack["administrate_teams"],
        message: this.props.languagePack["administrate_teams_success"],
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
      this.setState({
        team_name: this.state.newTeamName,
        editNameDialog: false,
      });
    });
  };

  deleteTeam = () => {
    axios
      .post("club/delete_team", {
        id_team: this.props.match.params.id,
      })
      .then(() => {
        this.setState({ deleteTeamPopup: false });
        store.addNotification({
          title: this.props.languagePack["club_management"],
          message: this.props.languagePack["administrate_teams_delete"],
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        this.props.history.push(`/club`);
      })
      .catch((err) => console.log(err));
  };

  getPlayersFromInstat = () => {
    axios
      .get(
        `instat/get_team_players_instat?id_team=${this.props.match.params.id}`
      )
      .then((res) =>
        this.setState({
          playersFromInstat: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  saveInstatMapping = (type) => {
    axios
      .post("instat/save_player_id_instat", {
        id_player: this.state.idPlayerForInstatMapping,
        id_player_instat: this.state.selectedPlayer.value,
        type: type,
      })
      .then(() => {
        this.getPlayers();
        this.closeInstatPopup();
        store.addNotification({
          title: this.props.languagePack["club_management"],
          message: "ID Instat al jucatorului a fost modficat cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  closeInstatPopup = () => {
    this.setState({
      idInstatInitial: "",
      mappingInstatDialog: false,
      idPlayerForInstatMapping: "",
      selectedPlayer: null,
      playerNameForInstat: "",
    });
  };

  setPlayerInactive = () => {
    axios
      .post("club/set_inactive_player", {
        id_player: this.state.set_inactive_player.id_player,
        inactive_from_date: this.state.inactive_from_date,
        inactive_reason: this.state.inactive_reason,
        inactive: 1,
      })
      .then((res) => {
        if (res.data == 1) {
          this.getPlayers();
          store.addNotification({
            title: this.props.languagePack["club_management"],
            message: "Jucatorul a fost setat ca inactiv cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: this.props.languagePack["club_management"],
            message: "Jucatorul nu a fost setat ca inactiv cu succes!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        this.closeInactivePopup();
      })
      .catch((err) => console.log(err));
  };

  setPlayerActive = () => {
    axios
      .post("club/set_active_player", {
        id_player: this.state.set_inactive_player.id_player,
      })
      .then((res) => {
        if (res.data == 1) {
          this.getPlayers();
          store.addNotification({
            title: this.props.languagePack["club_management"],
            message: "Jucatorul a fost activat cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: this.props.languagePack["club_management"],
            message: "Jucatorul nu a fost activat cu succes!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        this.closeInactivePopup();
      })
      .catch((err) => console.log(err));
  };

  closeInactivePopup = () => {
    this.setState({
      inactive_pop_up: false,
      set_inactive_player: "",
      inactive: "",
      inactive_from_date: null,
    });
  };

  handleChangeDate = (value) => {
    this.setState({ inactive_from_date: value });
  };

  makeFriendly(num) {
    if (num >= 1000000) return this.intlFormat(num / 1000000) + "M";
    if (num >= 1000) return this.intlFormat(num / 1000) + "Th.";
    return this.intlFormat(num);
  }

  changeFilter = (type) => {
    if (this.state.sortConfig.type !== type) {
      this.setState({ sortConfig: { type: type, descending: false } });
    } else {
      this.setState({
        sortConfig: {
          type: type,
          descending: !this.state.sortConfig.descending,
        },
      });
    }
  };

  sortData = () => {
    const items = this.state.players;
    const sortOption = this.state.sortConfig;

    if (sortOption.type === "minutes") {
      items.sort(function(a, b) {
        if (sortOption.descending) return b.minutes_played - a.minutes_played;
        else return a.minutes_played - b.minutes_played;
      });
    } else if (sortOption.type === "value") {
      items.sort(function(a, b) {
        if (sortOption.descending) return b.current_mv - a.current_mv;
        else return a.current_mv - b.current_mv;
      });
    } else if (sortOption.type === "vfm") {
      items.sort(function(a, b) {
        if (sortOption.descending) return b.vfm - a.vfm;
        else return a.vfm - b.vfm;
      });
    } else if (sortOption.type === "pta") {
      items.sort(function(a, b) {
        if (sortOption.descending) return b.pta - a.pta;
        else return a.pta - b.pta;
      });
    } else if (sortOption.type === "instat") {
      items.sort(function(a, b) {
        if (sortOption.descending)
          return b.instat_index_avg - a.instat_index_avg;
        else return a.instat_index_avg - b.instat_index_avg;
      });
    } else if (sortOption.type === "age") {
      items.sort(function(a, b) {
        if (sortOption.descending)
          return (
            moment().diff(b.player_dob, "years") -
            moment().diff(a.player_dob, "years")
          );
        else
          return (
            moment().diff(a.player_dob, "years") -
            moment().diff(b.player_dob, "years")
          );
      });
    } else return items;
  };

  generateList = () => {
    const url = `https://api.thefootballbrain.app/club/download_team_players_list?id_club=${this.props.currentUser.id_club}&id_team=${this.props.match.params.id}&id_season=${this.state.id_season}&token=${this.props.token}`;
    window.location.assign(url);
  };

  render() {
    const { languagePack } = this.props;
    this.sortData();

    if (this.state.redirectToTeams) {
      return <Redirect to="/club" />;
    }

    return (
      <>
        {this.state.hasAccessToTeam && (
          <div>
            {this.state.fetchingError === "" ? (
              <div className="card card-custom" style={{ padding: 50 }}>
                <div className="team-page-header">
                  <div className="team-page-title">
                    {languagePack["team_players"]} {this.state.team_name}{" "}
                    <Icon
                      name="pencil"
                      size="small"
                      className="edit-team-name-icon edit-hover:hover"
                      onClick={() => this.setState({ editNameDialog: true })}
                    />
                    <Icon
                      name="trash"
                      size="small"
                      className="edit-team-name-icon trash-hover"
                      onClick={() => this.setState({ deleteTeamDialog: true })}
                    />
                  </div>
                  <div className="team-page-header-right">
                    <TFBButton
                      onClick={() => {
                        const playersList = [...this.state.players];
                        let idsPlayers = playersList.map((player) => {
                          if (player.checked) return player.id_player;
                        });
                        this.setState({
                          exportPlayersList: true,
                          idPlayersToExport: idsPlayers,
                        });
                      }}
                      endIcon="true"
                      color={"green"}
                      renderIcon={() => <TFBIcon name={"arrow-down"} />}
                      style={{ marginRight: "10px" }}
                    >
                      {languagePack.export_player_list}
                    </TFBButton>

                    {/* <Button
                      className="download-list-button"
                      onClick={this.generateList}
                    >
                      {`${languagePack.campaign_download} .pdf`}
                      <Icon name="download" className="white-icon" />
                    </Button> */}
                    {this.state.id_season == this.state.current_season && (
                      <Button
                        className="add-player-button"
                        onClick={() => this.setState({ addPlayerDialog: true })}
                        style={{ marginLeft: 10 }}
                      >
                        <div className="button-content">
                          {languagePack["add_player"]}
                          <Icon
                            name="add"
                            className="white-icon"
                            style={{ height: "100%" }}
                          />
                        </div>
                      </Button>
                    )}
                    <Dropdown
                      placeholder="Season"
                      selection
                      options={this.state.seasons}
                      className="players-season-dropdown"
                      value={this.state.id_season}
                      onChange={this.handleChangeDropdown}
                      name="id_season"
                    />
                  </div>
                </div>

                <div style={{ margin: "25px 0px" }}>
                  <TFBCheckbox
                    label={languagePack.hide_inactiv_players}
                    checked={this.state.hideInactivePlayers}
                    onChange={this.handleChangeInactivePlayers}
                  />
                </div>

                {this.state.isFetching ? (
                  <div className="flex-all">
                    <CircularLoader />
                  </div>
                ) : (
                  <>
                    {this.state.players.length > 0 ? (
                      <div className="club-players-table">
                        <div className="club-players-table-header">
                          <div
                            className="player-name"
                            style={{ display: "flex" }}
                          >
                            <TFBCheckbox
                              onChange={(e) => this.handleHeaderCheckbox(e)}
                              checked={this.state.allChecked}
                            />
                            {languagePack["PLAYER_NAME"]}
                          </div>
                          <div className="player-column">
                            {languagePack["POSITION"]}
                          </div>
                          <div className="player-nationality">
                            {languagePack["NATIONALITY"]}
                          </div>
                          <div className="player-column-two-rows">
                            <div style={{ marginRight: 7 }}>
                              <div
                                onClick={() => this.changeFilter("age")}
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                }}
                              >
                                {languagePack["AGE"]}
                              </div>
                              <div>
                                {this.state.average_age > 0 && (
                                  <div className="header-stat">
                                    <div className="stat-name">
                                      {languagePack["average"]}:
                                    </div>
                                    <div className="stat-value">
                                      {this.state.average_age}
                                      {languagePack["age_years"]}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            {this.state.sortConfig.type === "age" &&
                              (this.state.sortConfig.descending ? (
                                <Icon name="arrow down" size="small" />
                              ) : (
                                <Icon name="arrow up" size="small" />
                              ))}
                          </div>
                          <div className="player-column player-minutes-played">
                            <div
                              onClick={() => this.changeFilter("minutes")}
                              style={{ cursor: "pointer" }}
                            >
                              {languagePack["minutes_played_uppercase"]}
                            </div>
                            {this.state.sortConfig.type === "minutes" &&
                              (this.state.sortConfig.descending ? (
                                <Icon name="arrow down" size="small" />
                              ) : (
                                <Icon name="arrow up" size="small" />
                              ))}
                          </div>
                          <div className="player-column-two-rows player-market-value">
                            <div style={{ marginRight: 7 }}>
                              <div
                                onClick={() => this.changeFilter("value")}
                                style={{ cursor: "pointer" }}
                              >
                                {languagePack["MARKET_VALUE"]}
                              </div>
                              {this.state.total_mv > 0 && (
                                <div className="header-stat">
                                  <div className="stat-name">
                                    {languagePack["total"]}:
                                  </div>
                                  <div className="stat-value">
                                    {this.makeFriendly(this.state.total_mv)}
                                  </div>
                                  <Icon
                                    name="euro"
                                    className="eur-icon-header"
                                    size="small"
                                  />
                                </div>
                              )}
                            </div>
                            {this.state.sortConfig.type === "value" &&
                              (this.state.sortConfig.descending ? (
                                <Icon name="arrow down" size="small" />
                              ) : (
                                <Icon name="arrow up" size="small" />
                              ))}
                          </div>
                          <div className="player-options"></div>
                        </div>
                        <div className="club-players-table-list">
                          {this.state.players.map((player, index) => (
                            <div key={index} className="club-players-container">
                              <div className="club-players-content">
                                <div
                                  className={`club-players-table-header${
                                    player.inactive == 1 ? " opacity-0-4" : ""
                                  }`}
                                  style={{
                                    backgroundColor:
                                      index % 2 == 0 && player.inactive == 0
                                        ? "#fafafa"
                                        : "",
                                  }}
                                >
                                  <div className="player-name">
                                    {languagePack["PLAYER_NAME"]}
                                  </div>
                                  <div className="player-column">
                                    {languagePack["POSITION"]}
                                  </div>
                                  <div className="player-nationality">
                                    {languagePack["NATIONALITY"]}
                                  </div>
                                  <div className="player-column-two-rows">
                                    <div style={{ marginRight: 7 }}>
                                      <div
                                        onClick={() => this.changeFilter("age")}
                                        style={{
                                          cursor: "pointer",
                                          textAlign: "center",
                                        }}
                                      >
                                        {languagePack["AGE"]}
                                      </div>
                                      <div>
                                        {this.state.average_age > 0 && (
                                          <div className="header-stat">
                                            <div className="stat-name">
                                              {languagePack["average"]}:
                                            </div>
                                            <div className="stat-value">
                                              {this.state.average_age}
                                              {languagePack["age_years"]}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    {this.state.sortConfig.type === "age" &&
                                      (this.state.sortConfig.descending ? (
                                        <Icon name="arrow down" size="small" />
                                      ) : (
                                        <Icon name="arrow up" size="small" />
                                      ))}
                                  </div>
                                  <div className="player-column player-minutes-played">
                                    <div
                                      onClick={() =>
                                        this.changeFilter("minutes")
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {languagePack["minutes_played_uppercase"]}
                                    </div>
                                    {this.state.sortConfig.type === "minutes" &&
                                      (this.state.sortConfig.descending ? (
                                        <Icon name="arrow down" size="small" />
                                      ) : (
                                        <Icon name="arrow up" size="small" />
                                      ))}
                                  </div>
                                  <div className="player-column-two-rows player-market-value">
                                    <div style={{ marginRight: 7 }}>
                                      <div
                                        onClick={() =>
                                          this.changeFilter("value")
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {languagePack["MARKET_VALUE"]}
                                      </div>
                                      {this.state.total_mv > 0 && (
                                        <div className="header-stat">
                                          <div className="stat-name">
                                            {languagePack["total"]}:
                                          </div>
                                          <div className="stat-value">
                                            {this.makeFriendly(
                                              this.state.total_mv
                                            )}
                                          </div>
                                          <Icon
                                            name="euro"
                                            className="eur-icon-header"
                                            size="small"
                                          />
                                        </div>
                                      )}
                                    </div>
                                    {this.state.sortConfig.type === "value" &&
                                      (this.state.sortConfig.descending ? (
                                        <Icon name="arrow down" size="small" />
                                      ) : (
                                        <Icon name="arrow up" size="small" />
                                      ))}
                                  </div>
                                  <div className="player-options"></div>
                                </div>
                                <div
                                  className="club-players-table-row"
                                  style={{
                                    backgroundColor:
                                      index % 2 == 0 && player.inactive == 0
                                        ? "#fafafa"
                                        : "white",
                                    // color: player.inactive == 1 ? "#b2b2b2" : "#666666"
                                  }}
                                >
                                  <div
                                    className={`player-name${
                                      player.inactive == 1 ? " opacity-0-4" : ""
                                    }`}
                                  >
                                    <TFBCheckbox
                                      key={"export-players-team-" + index}
                                      onChange={(e) => {
                                        let playersList = [
                                          ...this.state.players,
                                        ];
                                        playersList[
                                          index
                                        ].checked = !playersList[index].checked;

                                        let allPlayersChecked = playersList.every(
                                          (player) => player.checked == true
                                        );
                                        this.setState({
                                          players: playersList,
                                          allChecked: allPlayersChecked,
                                        });
                                      }}
                                      checked={player.checked}
                                    />
                                    <Avatar
                                      className={`player-avatar${
                                        player.inactive == 1 ? " grayscale" : ""
                                      }`}
                                      src={`${window.baseURL}uploads/players/${player.id_player}.jpg`}
                                    />
                                    <div
                                      onClick={() =>
                                        this.props.history.push(
                                          `/club/player/${player.id_player}?id_season=${this.state.id_season}`
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                      key={index}
                                    >
                                      {player.player_name}
                                    </div>
                                  </div>
                                  <div
                                    className={`player-column${
                                      player.inactive == 1 ? " opacity-0-4" : ""
                                    }`}
                                  >
                                    {player.position}
                                  </div>
                                  <div
                                    className={`nationalities${
                                      player.inactive == 1 ? " opacity-0-4" : ""
                                    }`}
                                  >
                                    <div className="player-nationality">
                                      <Flag
                                        name={player.flag_name}
                                        className={`${
                                          player.inactive == 1
                                            ? " grayscale"
                                            : ""
                                        }`}
                                      />
                                      <div style={{ marginLeft: 10 }}>
                                        {player.birth_country}
                                      </div>
                                    </div>
                                    {player.flag_name !=
                                      player.second_flag_name &&
                                    player.second_country != null &&
                                    player.second_country != "No country" ? (
                                      <div className="player-nationality">
                                        <Flag
                                          name={player.second_flag_name}
                                          className={`${
                                            player.inactive == 1
                                              ? " grayscale"
                                              : ""
                                          }`}
                                        />
                                        <div style={{ marginLeft: 10 }}>
                                          {player.second_country}
                                        </div>
                                      </div>
                                    ) : (
                                      false
                                    )}
                                  </div>
                                  <div
                                    className={`player-column-two-rows${
                                      player.inactive == 1 ? " opacity-0-4" : ""
                                    }`}
                                  >
                                    <div>
                                      {player.player_dob
                                        ? moment().diff(
                                            player.player_dob,
                                            "years"
                                          )
                                        : "-"}
                                    </div>
                                    <div style={{ color: "#b2b2b2" }}>
                                      {" "}
                                      {player.player_dob ? (
                                        <Moment format="DD.MM.YYYY">
                                          {player.player_dob}
                                        </Moment>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className={`player-column player-minutes-played${
                                      player.inactive == 1 ? " opacity-0-4" : ""
                                    }`}
                                  >
                                    {player.minutes_played}
                                  </div>
                                  <div
                                    className={`player-column player-market-value${
                                      player.inactive == 1 ? " opacity-0-4" : ""
                                    }`}
                                  >
                                    {player.current_mv > 0
                                      ? "\u20AC" +
                                        this.makeFriendly(player.current_mv)
                                      : "-"}
                                  </div>
                                  <div className="player-options">
                                    <div
                                      className="edit-icon-container"
                                      onClick={() =>
                                        this.setState({
                                          inactive_pop_up: true,
                                          set_inactive_player: player,
                                        })
                                      }
                                    >
                                      <Icon
                                        name="circle"
                                        style={{
                                          position: "relative",
                                          left: "1px",
                                          color:
                                            player.inactive == 0
                                              ? "#00D948"
                                              : "#B5B5C3",
                                        }}
                                      />
                                    </div>
                                    <div
                                      className="eye-icon-container"
                                      onClick={() =>
                                        this.props.history.push(
                                          `/club/player/${player.id_player}?id_season=${this.state.id_season}`
                                        )
                                      }
                                    >
                                      <EyeIcon />
                                    </div>
                                    <div className="edit-icon-container">
                                      <Icon
                                        name="folder open"
                                        style={{
                                          color: "white",
                                          marginLeft: "4px",
                                        }}
                                        alt="edit-galery"
                                        onClick={() =>
                                          this.props.history.push(
                                            `/club/player-gallery/${player.id_player}`
                                          )
                                        }
                                      />
                                    </div>
                                    <div
                                      className="edit-icon-container"
                                      style={{
                                        backgroundColor:
                                          player.has_id_instat == 0
                                            ? "#666666"
                                            : "white",
                                      }}
                                      title={
                                        player.has_id_instat == 1
                                          ? "Are instat ID"
                                          : "Nu are instat ID"
                                      }
                                      onClick={() => {
                                        let id_player_instat = this.state.playersFromInstat.find(
                                          (elem) => {
                                            return (
                                              elem.value ==
                                              player.id_player_instat
                                            );
                                          }
                                        );
                                        this.setState({
                                          mappingInstatDialog: true,
                                          idPlayerForInstatMapping:
                                            player.id_player,
                                          playerNameForInstat:
                                            player.player_name,
                                          selectedPlayer:
                                            id_player_instat != undefined
                                              ? id_player_instat
                                              : null,
                                          idInstatInitial:
                                            id_player_instat != undefined
                                              ? id_player_instat.value
                                              : "",
                                        });
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlSpace="preserve"
                                        width={20}
                                        height={20}
                                        style={{
                                          shapeRendering: "geometricPrecision",
                                          textRendering: "geometricPrecision",
                                          imageRendering: "optimizeQuality",
                                          fillRule: "evenodd",
                                          clipRule: "evenodd",
                                        }}
                                        viewBox="0 0 33245 19622"
                                      >
                                        <defs></defs>
                                        <g id="Layer_x0020_1">
                                          <path
                                            className="fil0-instat"
                                            style={{
                                              fill:
                                                player.has_id_instat == 0
                                                  ? "white"
                                                  : "#007f3e",
                                            }}
                                            d="M20907 7157v11773c0 439 220 659 660 659h4049c221 0 377-156 377-377V8664c543 0 1752-388 2034 980 243 1176 132 8150 132 9662 194 130 244 283 471 283h4238c220 0 377-156 377-377v-8193c0-1079-195-2140-458-2933-623-1877-1682-2805-3667-3302-2007-504-4597-426-6624 7-1220 261-1589 921-1589 2366zM13844 377v18741c0 220 251 471 471 471h4426c221 0 377-156 377-377V283c0-217-66-283-283-283h-4614c-221 0-377 156-377 377zM6781 4991v13939c0 439 219 659 659 659h4332c221 0 377-156 377-377V4709c0-221-156-377-377-377H7252c-305 0-471 342-471 659zM0 9889v9606c911 212 3863 94 4991 94 217 0 283-66 283-283v-9229c0-217-66-283-283-283-792 0-4501-96-4991 95z"
                                          />
                                        </g>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="placeholder-files">
                        <Icon
                          name="search minus"
                          size="huge"
                          className="placeholder-icon-team-page"
                        />
                        <div className="placeholder-text">
                          {languagePack["no_players_placeholder"]}
                        </div>
                      </div>
                    )}
                  </>
                )}

                {/* ============ Add Player DIALOG =================== */}
                {/* ================================================== */}
                {this.state.addPlayerDialog && (
                  <AddPlayer
                    openDialog={this.state.addPlayerDialog}
                    closePopup={() => this.setState({ addPlayerDialog: false })}
                    id_team={this.props.match.params.id}
                    id_club={this.props.currentUser.id_club}
                    getPlayers={() => this.getPlayers()}
                    languagePack={this.props.languagePack}
                    seasons={this.state.seasons}
                    selectedSeason={this.state.current_season}
                  />
                )}
                {/* ================================================== */}

                <Dialog open={this.state.deleteTeamDialog}>
                  <DialogContent>
                    <div className="edit-team-name-popup">
                      <div className="edit-name-popup-header">
                        <div className="edit-name-popup-title">
                          {languagePack["delete_team"]}
                        </div>
                        <Icon
                          name="close"
                          color="black"
                          size="large"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.setState({ deleteTeamDialog: false })
                          }
                        />
                      </div>
                      <div className="delete-team-popup-text">
                        {languagePack["deleteTeamMessage"]}
                      </div>
                      <div
                        className="delete-team-popup-text"
                        style={{ marginBottom: 25 }}
                      >
                        {languagePack["deleteTeamMessage2"]}
                      </div>
                      <div className="edit-team-buttons">
                        <Button
                          className="edit-team-button"
                          onClick={this.deleteTeam}
                        >
                          {languagePack["delete_team"]}
                          <Icon
                            name="trash"
                            size="small"
                            className="edit-team-button-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>

                <Dialog open={this.state.editNameDialog}>
                  <DialogContent>
                    <div className="edit-team-name-popup">
                      <div className="edit-name-popup-header">
                        <div className="edit-name-popup-title">
                          {languagePack["edit_team_name"]}
                        </div>
                        <Icon
                          name="close"
                          color="black"
                          size="large"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.setState({ editNameDialog: false })
                          }
                        />
                      </div>
                      <input
                        value={this.state.newTeamName}
                        onChange={(e) =>
                          this.setState({ newTeamName: e.target.value })
                        }
                        placeholder={languagePack["team_name"]}
                        className="edit-team-name-input"
                      />
                      <div className="edit-team-buttons">
                        <Button
                          className="edit-team-button"
                          onClick={this.saveEditTeamName}
                        >
                          {languagePack["admin_save"]}
                          <Icon
                            name="check"
                            size="small"
                            className="edit-team-button-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>

                <Dialog open={this.state.mappingInstatDialog}>
                  <DialogContent style={{ height: this.state.height }}>
                    <div className="edit-team-name-popup">
                      <div className="edit-name-popup-header">
                        <div className="edit-name-popup-title">
                          {languagePack.change_instat_id}
                          <br></br>
                          {this.state.playerNameForInstat}
                        </div>
                        <Icon
                          name="close"
                          color="black"
                          size="large"
                          style={{ cursor: "pointer" }}
                          onClick={this.closeInstatPopup}
                        />
                      </div>
                      <div className="add-instat-id-row">
                        <Select
                          placeholder={languagePack.player_search}
                          options={this.state.playersFromInstat}
                          value={this.state.selectedPlayer}
                          onChange={(selected) => {
                            this.setState({
                              selectedPlayer: selected,
                            });
                          }}
                          styles={{
                            container: (provided, state) => ({
                              ...provided,
                              backgroundColor: "#fafafa",
                              width: "100%",
                            }),
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "#fafafa",
                              height: "50px",
                              border: "none",
                              "&:hover": {
                                border: "none",
                                boxShadow: "0 0 0 2px black",
                                // border: "2px black"
                              },
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              paddingLeft: "22px",
                              fontSize: "14px",
                              fontWeight: 700,
                            }),
                            placeholder: (provided, state) => ({
                              ...provided,
                              color: "#666666",
                            }),
                            indicatorSeparator: (provided, state) => ({
                              ...provided,
                              display: "none",
                            }),
                            menu: (provided, state) => ({
                              ...provided,
                              maxHeight: "350px",
                            }),
                            menuPortal: (provided, state) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                          }}
                          menuPortalTarget={document.body}
                          onMenuOpen={() =>
                            this.setState({
                              height:
                                this.state.playersFromInstat.length > 0
                                  ? "485px"
                                  : "initial",
                            })
                          }
                          onMenuClose={() =>
                            this.setState({ height: "initial" })
                          }
                        />
                      </div>
                      <div className="edit-team-buttons">
                        {this.state.selectedPlayer != null &&
                        this.state.selectedPlayer.value ==
                          this.state.idInstatInitial ? (
                          <Button
                            className="edit-team-button"
                            style={{ backgroundColor: "red" }}
                            onClick={() => this.saveInstatMapping("delete")}
                          >
                            {"Sterge asociarea existenta"}
                            <Icon
                              name="close"
                              size="small"
                              className="edit-team-button-icon"
                            />
                          </Button>
                        ) : (
                          <Button
                            className="edit-team-button"
                            style={{ backgroundColor: "#666666" }}
                            onClick={this.closeInstatPopup}
                          >
                            {languagePack["admin_cancel"]}
                            <Icon
                              name="ban"
                              size="small"
                              className="edit-team-button-icon"
                            />
                          </Button>
                        )}

                        <Button
                          className="edit-team-button"
                          onClick={() => this.saveInstatMapping("save")}
                          disabled={this.state.selectedPlayer == null}
                        >
                          {languagePack["admin_save"]}
                          <Icon
                            name="check"
                            size="small"
                            className="edit-team-button-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>

                {/* SET INACTIVE PLAYER */}
                <Dialog
                  open={this.state.inactive_pop_up}
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogContent>
                    {this.state.set_inactive_player.inactive == 0 ? (
                      <div className="edit-team-name-popup approve-necessity-report-container delete-competition-popup-container add-contract-container add-cost-container">
                        <div className="edit-name-popup-header">
                          <div className="edit-name-popup-title">
                            {"Setare ca inactiv jucatorul "}
                            <span style={{ color: "var(--primaryColor)" }}>
                              {this.state.set_inactive_player.player_name}
                            </span>
                          </div>
                          <Icon
                            name="close"
                            color="black"
                            size="large"
                            style={{ cursor: "pointer" }}
                            onClick={this.closeInactivePopup}
                          />
                        </div>
                        <div className="add-player-row add-contract-row">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              value={this.state.inactive_from_date}
                              onChange={this.handleChangeDate}
                              emptyLabel={"Inactiv din data de"}
                              format="dd.MM.yyyy"
                              className="contract-date-input"
                            />
                            <Icon
                              name="calendar alternate outline"
                              className="calendar-icon"
                              size="small"
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div
                          className="add-player-row approve-necessity-report-row"
                          style={{ marginTop: "30px" }}
                        >
                          <label className="label-approve-reason">
                            {"Motiv:"}
                          </label>
                          <textarea
                            id="inactive_reason"
                            name="inactive_reason"
                            className="add-necessity-report-form-input"
                            rows="3"
                            placeholder="Introdu motivul..."
                            value={this.state.inactive_reason}
                            onChange={(e) =>
                              this.setState({
                                inactive_reason: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="edit-team-buttons delete-competition-buttons">
                          <Button
                            className="edit-team-button"
                            style={{ backgroundColor: "#666666" }}
                            onClick={this.closeInactivePopup}
                          >
                            {languagePack["admin_cancel"]}
                            <Icon
                              name="ban"
                              size="small"
                              className="edit-team-button-icon"
                            />
                          </Button>
                          <Button
                            className="edit-team-button no-hover-green-btn"
                            onClick={this.setPlayerInactive}
                            disabled={this.state.inactive_from_date == null}
                          >
                            {languagePack["admin_save"]}
                            <Icon
                              name="check"
                              size="small"
                              className="edit-team-button-icon"
                            />
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="delete-competition-popup-container edit-team-name-popup">
                        <div className="edit-name-popup-header">
                          <div className="edit-name-popup-title">
                            {"Activeaza jucatorul "}
                            <span style={{ color: "var(--primaryColor)" }}>
                              {this.state.set_inactive_player.player_name}
                            </span>
                          </div>
                          <Icon
                            name="close"
                            color="black"
                            size="large"
                            style={{ cursor: "pointer" }}
                            onClick={this.closeInactivePopup}
                          />
                        </div>
                        <div className="delete-competition-text">
                          {
                            "Ești sigur/sigură că dorești să activezi aceast jucător?"
                          }
                        </div>
                        <div className="edit-team-buttons delete-competition-buttons">
                          <Button
                            className="edit-team-button"
                            style={{ backgroundColor: "#666666" }}
                            onClick={this.closeInactivePopup}
                          >
                            {languagePack["admin_cancel"]}
                            <Icon
                              name="ban"
                              size="small"
                              className="edit-team-button-icon"
                            />
                          </Button>
                          <Button
                            className="edit-team-button no-hover-green-btn"
                            onClick={this.setPlayerActive}
                          >
                            {"Da, activează"}
                            <Icon
                              name="check"
                              size="small"
                              className="edit-team-button-icon"
                            />
                          </Button>
                        </div>
                      </div>
                    )}
                  </DialogContent>
                </Dialog>

                {/* ============== EXPORT LIST POPUP ======================== */}
                {/* ========================================================= */}
                <ExportPlayers
                  exportListPopup={this.state.exportPlayersList}
                  closePopup={() => this.setState({ exportPlayersList: false })}
                  languagePack={languagePack}
                  token={this.props.currentUser.token}
                  playerData={this.state.idPlayersToExport}
                  id_club={this.props.currentUser.id_club}
                />
                {/* ==================================================== */}
              </div>
            ) : (
              <div className="access-error-team-page">
                <Icon name="ban" size="large" className="access-error-icon" />
                {this.state.fetchingError}
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(Team);
