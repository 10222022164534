import React, { useEffect, useState } from "react";
import axios from "axios";
import { TextArea, Button, Icon } from "semantic-ui-react";
import { store } from "react-notifications-component";
import "./EditCategories.scss";
import EditCategoryItem from "./EditCategoryItem";
import { connect } from "react-redux";

import delete_icon from "../../../assets/icons/delete.png";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Loader from "react-loader-spinner";

function EditCategory(props) {
  const [categoryList, setCategoryList] = useState([]);

  const [openEditCategoryItem, setOpenEditCategoryItem] = useState(false);
  const [categoryItemToEdit, setCategoryItemToEdit] = useState("");

  const [openDeleteCategoryItem, setOpenDeleteCategoryItem] = useState(false);
  const [categoryItemToDelete, setCategoryItemToDelete] = useState("");

  const { languagePack } = props;

  useEffect(() => {
    setCategoryList(props.categories);
  }, [props.categories]);

  const deleteVenue = () => {
    axios
      .post("report/delete_evaluation_category", {
        id_param_category: categoryItemToDelete.value,
      })
      .then((res) => {
        if (res.data == 1) {
          // props.getSubCategories();
          props.getCategories();
          store.addNotification({
            title: "Management club",
            message: "Categoria a fost stearsa cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: "Management club",
            message: "Terenul nu a fost sters cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        setOpenDeleteCategoryItem(false);
        setCategoryItemToDelete("");
      });
  };

  return (
    <div className="evaluation-category-list-container">
      <div className="category-list-header">
        <div className="category-list-title">
          {languagePack.categories_list}
        </div>
        <Icon
          name="close"
          className="close-icon-popup"
          onClick={() => props.closePopup()}
        />
      </div>
      <div className="categories-list">
        {categoryList.map((item, index) => {
          return (
            <div
              className="category-row"
              style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "" }}
              key={item.value}
            >
              <div className="category-name">{item.label}</div>
              <div className="edit-categories-buttons">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setOpenEditCategoryItem(true);
                    setCategoryItemToEdit(item);
                  }}
                >
                  <Icon
                    name="pencil"
                    size="small"
                    className="edit-team-name-icon edit-venue"
                  />
                </button>
                <button
                  type="button"
                  className="btn btn-secondary delete-venue"
                  onClick={() => {
                    setOpenDeleteCategoryItem(true);
                    setCategoryItemToDelete(item);
                  }}
                >
                  <img src={delete_icon} alt="delete" />
                </button>
              </div>
            </div>
          );
        })}
      </div>

      {/* EDIT VENUE */}
      <Dialog open={openEditCategoryItem}>
        <DialogContent>
          <EditCategoryItem
            closePopup={() => {
              setOpenEditCategoryItem(false);
              setCategoryItemToEdit("");
            }}
            id_club={props.currentUser.id_club}
            languagePack={props.languagePack}
            categoryData={categoryItemToEdit}
            getCategories={props.getCategories}
            // getSubCategories={props.getSubCategories}
          />
        </DialogContent>
      </Dialog>

      {/* DELETE VENUE */}
      <Dialog open={openDeleteCategoryItem}>
        <DialogContent className="add-team-manual-wrapper">
          <div className="delete-bonus-query">
            {props.languagePack.delete_category_message}
            <span
              style={{
                color: "var(--primaryColor)",
                textTransform: "uppercase",
              }}
            >
              {categoryItemToDelete.label}
            </span>
            {" ?"}
          </div>
          <div className="delete-bonus-buttons">
            <Button
              onClick={() => {
                setOpenDeleteCategoryItem(false);
                setCategoryItemToDelete("");
              }}
              className="delete-bonus-button grey-btn"
              color="var(--secondaryColor)"
            >
              {languagePack["add_payment_button_cancel"]}
              <Icon name="ban" className="white-icon" />
            </Button>
            <Button
              className="delete-bonus-button danger"
              color="red"
              onClick={deleteVenue}
              style={{ backgroundColor: "#db2828", color: "white" }}
            >
              <div className="button-content">
                {languagePack["delete_payment_button_delete"]}
              </div>
              <Icon name="trash" className="white-icon" />
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(EditCategory);
