import React from "react";
import "./Competitions.scss";
import { Button, Icon } from "semantic-ui-react";
import axios from "axios";
import Moment from "react-moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddCompetition from "./AddCompetition";
import DeleteCompetition from "./DeleteCompetition";
import EditCompetition from "./EditCompetition";
import AddAgeGroup from "./AddAgeGroup";
import delete_icon from "../../../../assets/icons/delete.png";
import edit_icon from "../../../../assets/icons/edit.png";
import { connect } from "react-redux";
import EditChildCompetition from "./EditChildCompetition";
import CompetitionElemInList from "./CompetitionElemInList";

class Competitions extends React.Component {
  constructor(props) {
    super(props);
    let addCompetition =
      this.props.history.location.state !== null &&
      this.props.history.location.state !== undefined
        ? "openAddCompetition" in this.props.history.location.state
          ? this.props.history.location.state.openAddCompetition
          : false
        : false;
    this.state = {
      competitions: [],
      addCompetitionPopup: addCompetition,
      deleteCompetitionPopup: false,
      wantToDeleteComp: "",
      editCompetitionPopup: false,
      wantToEditComp: "",
      addAgeGroup: false,
      wantToAddGroupComp: "",
      extendParentComp: false,
      editAgeGroup: false,
      wantToEditAgeGroup: "",
    };
  }

  componentDidMount = () => {
    this.getCompetitions();
  };

  getCompetitions = () => {
    axios
      .get(
        `match/get_competitions_common?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) =>
        this.setState({
          competitions: res.data,
        })
      )
      .catch((res) => console.log(res));
  };

  editComp = (item) => {
    this.setState({
      wantToEditComp: item,
      editCompetitionPopup: true,
    });
  };

  deleteComp = (item) => {
    this.setState({
      wantToDeleteComp: item.key,
      deleteCompetitionPopup: true,
    });
  };

  addGroupAge = (item) => {
    this.setState({
      wantToAddGroupComp: item,
      addAgeGroup: true,
    });
  };

  editGroupAge = (item) => {
    this.setState({
      wantToEditAgeGroup: item,
      editAgeGroup: true,
    });
  };

  deleteGroupAge = (item) => {
    this.setState({
      wantToDeleteComp: item.id_competition,
      deleteCompetitionPopup: true,
    });
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="card card-custom">
        <div className="competitions-manual-container">
          <div className="competitions-manual-header">
            <div className="competitions-manual-title">
              {languagePack["active_competitions"]}
            </div>
            <Button
              className="competitions-manual-add-button"
              onClick={() => this.setState({ addCompetitionPopup: true })}
            >
              {languagePack["add_competition"]}
              <Icon name="add" className="add-button-white-icon" />
            </Button>
          </div>
          <div className="competitions-manual-list">
            <div className="competitions-manual-row-header desktop-header">
              <div className="competition-more"></div>
              <div className="competition-name">
                {languagePack["competition_name"]}
              </div>
              <div className="competition-number-teams">
                {languagePack["teams_number"]}
              </div>
              <div className="competition-date">
                {languagePack["starting_date"]}
              </div>
              <div className="competition-date">
                {languagePack["ending_date"]}
              </div>
              <div className="competition-options">
                {languagePack["news_page_header_options"]}
              </div>
            </div>
            {this.state.competitions.map((item, index) => {
              return (
                <CompetitionElemInList
                  index={index}
                  item={item}
                  editGroupAge={this.editGroupAge}
                  deleteGroupAge={this.deleteGroupAge}
                  addGroupAge={this.addGroupAge}
                  editComp={this.editComp}
                  deleteComp={this.deleteComp}
                  history={this.props.history}
                  key={"competiton-" + index}
                />
              );
            })}
          </div>
        </div>

        <Dialog
          open={this.state.addCompetitionPopup}
          // onClose={() => this.setState({ addCompetitionPopup: false })}
        >
          <DialogContent className="add-team-dialog-full-container">
            <AddCompetition
              closePopup={() => this.setState({ addCompetitionPopup: false })}
              getCompetitions={() => this.getCompetitions()}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.deleteCompetitionPopup}
          // onClose={() => this.setState({ deleteCompetitionPopup: false })}
        >
          <DialogContent className="delete-competition-wrapper">
            <DeleteCompetition
              closePopup={() =>
                this.setState({ deleteCompetitionPopup: false })
              }
              getCompetitions={() => this.getCompetitions()}
              id_competition={this.state.wantToDeleteComp}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.editCompetitionPopup}
          // onClose={() => this.setState({ editCompetitionPopup: false })}
        >
          <DialogContent className="add-team-dialog-full-container">
            <EditCompetition
              closePopup={() => this.setState({ editCompetitionPopup: false })}
              getCompetitions={() => this.getCompetitions()}
              competition={this.state.wantToEditComp}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.addAgeGroup}
          // onClose={() => this.setState({ addAgeGroup: false })}
        >
          <DialogContent className="add-team-dialog-full-container">
            <AddAgeGroup
              closePopup={() => this.setState({ addAgeGroup: false })}
              getCompetitions={() => this.getCompetitions()}
              id_competition={this.state.wantToAddGroupComp.key}
              competition_type={this.state.wantToAddGroupComp.competition_type}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.editAgeGroup}
          // onClose={() => this.setState({ addAgeGroup: false })}
        >
          <DialogContent className="add-team-dialog-full-container">
            <EditChildCompetition
              closePopup={() => this.setState({ editAgeGroup: false })}
              getCompetitions={() => this.getCompetitions()}
              competition={this.state.wantToEditAgeGroup}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(Competitions);
