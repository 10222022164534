import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import NumberFormat from "react-number-format";

function SponsorLastTransactions(props) {
    const [transactions, setTransactions] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        axios.get(`finances/get_sponsor_real_payments?id_sponsor=${props.id_sponsor}`)
            .then((res) => {
                setTransactions(res.data.transactions)
                setTotal(res.data.total)
            })
            .catch(err => console.log(err))
    }, [])

    function formatNumber(value) {
        return (
            <NumberFormat
                value={value}
                displayType={"text"}
                decimalScale={2}
                thousandSeparator={true}
            />
        );
    }

    return (
        <div className="sponsor-last-transactions-container">
            <div className="sponsor-transactions-title">Ultimele tranzactii efectuate</div>
            <div className="transactions-list-header">
                <div className="transaction-description">Descriere</div>
                <div className="transaction-date">Data tranzactiei</div>
                <div className="transaction-amount">Valoare</div>
            </div>
            {transactions.map((item, index) => (
                <div className="transaction-row" style={{
                    backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                }}>
                    <div className="transaction-description">{item.transaction_description}</div>
                    <div className="transaction-date"><Moment format="DD.MM.YYYY">{item.transaction_date}</Moment></div>
                    <div className="transaction-amount">{item.transaction_amount}</div>
                </div>
            ))}
            <div className="transactions-total-row">
                <div className="total-text">Total incasari:</div>
                <div className="total-amount">{formatNumber(total)} RON</div>
            </div>
        </div>
    )
}

export default SponsorLastTransactions;