import React from "react";
import "./Documents.styles.scss";
import axios from "axios";
import BonusOptionsList from "./BonusOptionsList";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Button, Icon, Dropdown } from "semantic-ui-react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { logout } from "./../../utils/api";
import { bindActionCreators } from "redux";
import * as myConstants from "./../../../app/utils/constants";
import NumberFormat from "react-number-format";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AddStaffContract from "./AddStaffContract";
import arrow_down from "../../assets/icons/arrow-down.png";
import arrow_up from "../../assets/icons/arrow-up.png";
import EditStaffContract from "./EditStaffContract";
import ContractBonuses from "./ContractBonuses";
import { store } from "react-notifications-component";

class StaffContracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staffMembers: [],
      staffMembersDropdown: [],
      expandedRows: [],
      bonusListOpened: false,
      sortConfig: {
        type: "",
        descending: false,
      },
      rates: [],
      removeContract: false,
      removeContractId: "",
      removeContractDate: null,
      contractDialogOpened: false,

      uploaded_rates: false,

      editContractDialogOpened: false,
      contractToEdit: "",
      contractToEditPlayer: "",

      deletePlayerContractOpen: false,
      deletingContract: "",
    };
  }

  componentDidMount = () => {
    // this.getContracts();
    this.getCurrency();
    this.getMembers();
  };

  getContracts = () => {
    axios
      .get(
        `upload/get_staff_contracts?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => this.setState({ staffMembers: res.data }))
      .catch((err) => console.log(err));
  };

  getMembers = () => {
    axios
      .get(`upload/get_staff_dropdown`, {
        params: {
          id_club: this.props.currentUser.id_club,
          token: this.props.token,
        },
      })
      .then((res) => this.setState({ staffMembersDropdown: res.data }))
      .catch((err) => console.log(err));
  };

  changeFilter = (type) => {
    if (this.state.sortConfig.type !== type) {
      this.setState({ sortConfig: { type: type, descending: false } });
    } else {
      this.setState({
        sortConfig: {
          type: type,
          descending: !this.state.sortConfig.descending,
        },
      });
    }
  };

  sortData = () => {
    const items = this.state.staffMembers;
    const sortOption = this.state.sortConfig;

    if (sortOption.type === "wage") {
      items.sort(function(a, b) {
        if (sortOption.descending) return b.wage - a.wage;
        else return a.wage - b.wage;
      });
    }
    if (sortOption.type === "name") {
      items.sort(function(a, b) {
        return sortOption.descending
          ? b.member_name.localeCompare(a.member_name)
          : a.member_name.localeCompare(b.member_name);
      });
    }
    if (sortOption.type === "date_start") {
      items.sort(function(a, b) {
        if (sortOption.descending)
          return new Date(b.date_start) - new Date(a.date_start);
        else return new Date(a.date_start) - new Date(b.date_start);
      });
    }
    if (sortOption.type === "date_end") {
      items.sort(function(a, b) {
        if (sortOption.descending)
          return new Date(b.date_end) - new Date(a.date_end);
        else return new Date(a.date_end) - new Date(b.date_end);
      });
    } else return items;
  };

  getCurrency = () => {
    fetch("https://api.thefootballbrain.app/club/get_currency_rates")
      .then((res) => res.json())
      .then((data) => this.setState({ rates: data }, this.getContracts));
  };

  convertCurrency = (value, currency) => {
    const user_currency = this.props.currencyItemSelected.uniqueSlug;
    if (user_currency == currency) {
      return value;
    } else {
      if (user_currency == "EUR") {
        return value / this.state.rates[currency];
      } else {
        return value * this.state.rates[user_currency];
      }
    }
  };

  formatNumber = (value, currency) => {
    return (
      <NumberFormat
        value={this.convertCurrency(value, currency)}
        displayType={"text"}
        thousandSeparator={"."}
        decimalSeparator={","}
        decimalScale={2}
        fixedDecimalScale={2}
      />
    );
  };

  handleDateChange = (value) => {
    this.setState({ removeContractDate: value });
  };

  handleChangeDropdown = (e, value) => {
    this.setState({
      [value.name]: value.value,
    });
  };

  removeContract = () => {
    const payload = {
      id_staff: this.state.removeContractId,
      date: this.state.removeContractDate,
    };
    axios
      .post("club/remove_staff_contract", payload)
      .then(() => {
        this.setState({ removeContract: false });
        this.getContracts();
      })
      .catch((err) => console.log(err));
  };

  handleRowClick(rowId) {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowId)
      : currentExpandedRows.concat(rowId);

    this.setState({ expandedRows: newExpandedRows });
  }

  openDeleteDialog = (contract) => {
    this.setState({
      deletePlayerContractOpen: true,
      deletingContract: contract,
    });
  };

  closeDeleteDialog = () => {
    this.setState({
      deletePlayerContractOpen: false,
      deletingContract: "",
    });
  };

  deleteContract = () => {
    axios
      .post(
        "upload/delete_contract_staff_member",
        { id_contract: this.state.deletingContract.id_contract },
        {
          params: {
            token: this.props.token,
          },
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then(async (res) => {
        if (res.data == 1) {
          this.getContracts();
          this.closeDeleteDialog();
          store.addNotification({
            title: "Document operation",
            message: "Ai sters contractul pentru staff cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: "Document operation",
            message: "S-a produs o eroare. Incearca din nou!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch(async (err) => {
        return { data: {} };
      });
  };

  render() {
    const iconOsCurrency =
      this.props.currencyItemSelected.uniqueSlug === "EUR" ? (
        <Icon name="eur" color="black" className="mg--0" />
      ) : (
        <span className="font-weight-bold"> RON </span>
      );
    const { languagePack } = this.props;

    this.sortData();
    return (
      <div className="card card-custom">
        <div className="contracts-section">
          <div className="contracts-table-header">
            <div className="contracts-section-title">
              {languagePack["staff_contracts"]}
            </div>
            <div className="staff-header-buttons">
              <Button
                onClick={() => this.setState({ bonusListOpened: true })}
                style={{ backgroundColor: "#666666" }}
                className="bonus-list-button"
              >
                {languagePack["bonuses_list"]}
              </Button>

              <Button
                onClick={() => this.setState({ removeContract: true })}
                style={{ backgroundColor: "#666666" }}
                className="bonus-list-button"
              >
                {languagePack["remove_contract"]}
              </Button>

              <Button
                onClick={() => this.setState({ contractDialogOpened: true })}
                className="bonus-list-button"
                style={{
                  backgroundColor: window.clubCustomization.primary_color,
                }}
              >
                {languagePack["add_contract"]}
              </Button>
            </div>
          </div>

          <div className="contracts-list-container">
            <div className="contracts-row-table-column-names">
              <div
                className="contract-player-name"
                onClick={() => this.changeFilter("name")}
                style={{ cursor: "pointer", display: "block" }}
              >
                {languagePack["staff_member_name"]}
                {this.state.sortConfig.type === "name" &&
                  (this.state.sortConfig.descending ? (
                    <Icon name="arrow down" />
                  ) : (
                    <Icon name="arrow up" />
                  ))}
              </div>
              <div className="contract-player-team">
                {languagePack["occupation"]}
              </div>
              <div
                className="contract-date-start"
                onClick={() => this.changeFilter("date_start")}
                style={{ cursor: "pointer" }}
              >
                {languagePack["starting_date"]}
                {this.state.sortConfig.type === "date_start" &&
                  (this.state.sortConfig.descending ? (
                    <Icon name="arrow down" />
                  ) : (
                    <Icon name="arrow up" />
                  ))}
              </div>
              <div
                className="contract-date-end"
                onClick={() => this.changeFilter("date_end")}
                style={{ cursor: "pointer" }}
              >
                {languagePack["ending_date"]}
                {this.state.sortConfig.type === "date_end" &&
                  (this.state.sortConfig.descending ? (
                    <Icon name="arrow down" />
                  ) : (
                    <Icon name="arrow up" />
                  ))}
              </div>
              <div
                className="contract-wage"
                onClick={() => this.changeFilter("wage")}
                style={{ cursor: "pointer" }}
              >
                {languagePack["monthly_wage"]}
                {this.state.sortConfig.type === "wage" &&
                  (this.state.sortConfig.descending ? (
                    <Icon name="arrow down" />
                  ) : (
                    <Icon name="arrow up" />
                  ))}
              </div>
              <div className="contract-options"></div>
            </div>
            {this.state.staffMembers.map((item, index) => {
              return (
                <div key={"contract-row-" + index}>
                  <div
                    className="contracts-row"
                    key={item.id_contract}
                    style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "" }}
                  >
                    <div className="contract-player-name">
                      {item.member_name}
                    </div>
                    <div className="contract-player-team">
                      {item.member_role}
                    </div>
                    <div className="contract-date-start">
                      {" "}
                      <Moment format="DD.MM.YYYY">{item.date_start}</Moment>
                    </div>
                    <div className="contract-date-end">
                      {" "}
                      <Moment format="DD.MM.YYYY">{item.date_end}</Moment>
                    </div>
                    <div className="contract-wage">
                      {this.formatNumber(item.wage, item.currency)}
                      {iconOsCurrency}
                    </div>
                    <div className="contract-options">
                      {item.contract_path != null ? (
                        <a
                          style={{ color: "black" }}
                          className="download-contract"
                          href={`https://api.thefootballbrain.app/upload/download_contract?path=${item.contract_path}`}
                        >
                          <Icon name="download" color="black" />
                        </a>
                      ) : (
                        <div style={{ width: "20px" }}></div>
                      )}
                      <div className="delete-contract">
                        <Icon
                          name="trash alternate"
                          className="delete-contract-icon trash-hover"
                          onClick={() => {
                            this.openDeleteDialog(item);
                          }}
                        />
                      </div>
                      <div
                        className="contract-see-more-button"
                        onClick={() =>
                          this.setState({
                            editContractDialogOpened: true,
                            contractToEdit: item.id_contract,
                            contractToEditPlayer: item.member_name,
                          })
                        }
                      >
                        {/* <EyeIcon /> */}
                        <Icon name="edit" className="edit-contract-icon" />
                      </div>
                      <div
                        className="expanded-arrow-button"
                        onClick={() => this.handleRowClick(item.id_contract)}
                        style={{ marginRight: 10 }}
                      >
                        {this.state.expandedRows.includes(item.id_contract) ? (
                          <img src={arrow_up} alt="arrow_up" />
                        ) : (
                          <img src={arrow_down} alt="arrow_down" />
                        )}
                      </div>
                    </div>
                  </div>
                  {this.state.expandedRows.includes(item.id_contract) && (
                    <div
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                      }}
                    >
                      <ContractBonuses
                        id_player={item.id_staff_member}
                        id_contract={item.id_contract}
                        rates={this.state.rates}
                        type={"staff"}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <Dialog open={this.state.bonusListOpened} fullWidth maxWidth="sm">
          <div className="bonus-options-list-popup-header">
            <div className="bonus-options-list-title">
              {languagePack["bonuses_list"]}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={() => this.setState({ bonusListOpened: false })}
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <DialogContent>
            <BonusOptionsList />
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.removeContract}
          // onClose={() => this.setState({ removeContract: false })}
        >
          <div className="bonus-options-list-popup-header">
            <div className="bonus-options-list-title">
              {languagePack["remove_contract"]}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={() => this.setState({ removeContract: false })}
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <DialogContent>
            <div className="remove-contract-container">
              <div className="remove-contract-row">
                <Dropdown
                  selection
                  value={this.state.removeContractId}
                  options={this.state.staffMembersDropdown}
                  placeholder={languagePack["choose_staff_member"]}
                  onChange={this.handleChangeDropdown}
                  name="removeContractId"
                  className="remove-contract-dropdown"
                />
              </div>
              <div className="remove-contract-row">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    value={this.state.removeContractDate}
                    onChange={this.handleDateChange}
                    label={languagePack["remove_contract_date"]}
                    format="dd MMMM yyyy"
                    inputVariant="outlined"
                    className="remove-contract-date-input"
                  />
                  <Icon
                    name="calendar alternate outline"
                    className="calendar-icon"
                    size="small"
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="remove-contract-buttons">
                <Button
                  onClick={() => this.setState({ removeContract: false })}
                  className="remove-contract-cancel-button"
                >
                  {languagePack["admin_cancel"]}
                </Button>

                <Button
                  className="remove-contract-confirm-button"
                  onClick={this.removeContract}
                >
                  {languagePack["remove_contract_confirm"]}
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {/* ADAUGARE CONTRACT JUCATOR */}
        <Dialog
          open={this.state.contractDialogOpened}
          // onClose={() => this.setState({ contractDialogOpened: false })}
        >
          <div
            className="bonus-options-list-popup-header"
            style={{ paddingLeft: 50 }}
          >
            <div className="bonus-options-list-title">
              {languagePack["player_contract"]}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={() => this.setState({ contractDialogOpened: false })}
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <DialogContent className="add-file-container-all">
            <AddStaffContract
              refreshFiles={this.getContracts}
              closePopup={() => this.setState({ contractDialogOpened: false })}
            />
          </DialogContent>
        </Dialog>

        {/* EDITARE CONTRACT STAFF */}
        <Dialog open={this.state.editContractDialogOpened}>
          <div
            className="bonus-options-list-popup-header"
            style={{ paddingLeft: 50 }}
          >
            <div className="bonus-options-list-title">
              {languagePack.edit_staff_contract}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={() =>
                  this.setState({
                    editContractDialogOpened: false,
                    contractToEdit: "",
                    contractToEditPlayer: "",
                  })
                }
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <DialogContent className="add-file-container-all">
            <EditStaffContract
              refreshFiles={this.getContracts}
              contractId={this.state.contractToEdit}
              playerName={this.state.contractToEditPlayer}
              closePopup={() =>
                this.setState({
                  editContractDialogOpened: false,
                  contractToEdit: "",
                  contractToEditPlayer: "",
                })
              }
            />
          </DialogContent>
        </Dialog>

        {/* STERGERE CONTRACT STAFF*/}
        <Dialog open={this.state.deletePlayerContractOpen}>
          <div className="bonus-options-list-popup-header">
            <div className="bonus-options-list-title">
              {languagePack.delete_contract}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={this.closeDeleteDialog}
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <DialogContent>
            <div className="delete-bonus-query">
              {languagePack.delete_contract_staff_text}
              <span
                style={{
                  color: "var(--primaryColor)",
                  textTransform: "uppercase",
                }}
              >
                {this.state.deletingContract.member_name}
              </span>
              {"?"}
            </div>
            <div className="delete-bonus-buttons">
              <Button
                className="delete-bonus-button grey-btn"
                onClick={this.closeDeleteDialog}
              >
                <div className="button-content">
                  {languagePack["admin_cancel"]}
                </div>
                <Icon name="ban" className="white-icon" />
              </Button>
              <Button
                className="delete-bonus-button"
                color="red"
                onClick={this.deleteContract}
              >
                <div className="button-content">{languagePack.yes_delete}</div>
                <Icon name="trash" className="white-icon" />
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyItemSelected:
      myConstants.currencies[auth?.user?.currency] ||
      myConstants.currencies.EUR,
  }),
  mapDispatchToProps
)(StaffContracts);
