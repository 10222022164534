import React from "react";
import "./PlanTraining.scss";
import "./../TrainingHistory/TrainingHistory.scss";
import axios from "axios";

import { connect } from "react-redux";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import AddRecurrentTraining from "./AddRecurrentTraining";
import DeleteTraining from "./DeleteTraining";
import moment from "moment";
import "moment/locale/ro";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton.tsx";
import TFBButton from "../../../components/design-system/Button/TFBButton.tsx";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon.tsx";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../components/reusable/useStyles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TFBDropdown from "../../../components/design-system/Dropdown/TFBDropdown";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";

class PlanTraining extends React.Component {
  constructor(props) {
    const team = new URLSearchParams(props.location.search).get("team");
    super(props);
    this.state = {
      trainings: [],
      teamOptions: [],
      selected_team: team > 0 ? team : "",
      openRecurrentTraining: false,
      openTrainingToDelete: false,
      trainingToDelete: "",
      openTrainingToEdit: false,
      trainingToEdit: {},
    };
  }

  componentDidMount = () => {
    this.getTrainingSessions();
    this.getTeamsDropdown();
  };

  getTrainingSessions = () => {
    axios
      .get(
        `training/get_training_sessions?id_club=${this.props.currentUser.id_club}&selected_team=${this.state.selected_team}&history=0&limit=50`
      )
      .then((res) => {
        this.setState({ trainings: res.data });
      })
      .catch((err) => console.log(err));
  };

  getTeamsDropdown = () => {
    axios
      .get(
        `training/get_teams_dropdown?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => {
        if (this.state.selected_team == "") {
          this.setState({
            selected_team: res.data.length > 0 ? res.data[0].value : "",
          });
        }
        this.setState({
          teamOptions: res.data,
        });
      });
  };

  handleChangeTeam = (e, value) => {
    let url = this.props.location.search;
    let search_params = new URLSearchParams(url);
    if (value.value > 0) {
      search_params.set("team", value.value);
    } else {
      search_params.delete("team");
    }
    window.history.replaceState(null, null, "?" + search_params.toString());
    this.setState(
      {
        [value.name]: value.value,
      },
      this.getTrainingSessions
    );
  };

  render() {
    const { languagePack } = this.props;
    return (
      <TFBCard>
        <TFBCardHeader
          className="plan-training-card-header"
          title={languagePack["plan_training"]}
        >
          <TFBButton
            onClick={() => this.setState({ openRecurrentTraining: true })}
            endIcon="true"
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
          >
            {languagePack.add_recurrent_training}
          </TFBButton>

          <TFBButton
            onClick={() => this.props.history.push(`/training/plan/add`)}
            endIcon="true"
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
          >
            {languagePack["add_training_session"]}
          </TFBButton>

          <TFBDropdown
            placeholder={languagePack["select_team"]}
            selection
            options={this.state.teamOptions}
            name="selected_team"
            color="gray"
            value={this.state.selected_team}
            onChange={this.handleChangeTeam}
          />
        </TFBCardHeader>
        <TFBCardBody>
          <div className="training-history-list">
            {this.state.trainings == 0 ? (
              <TFBPlaceholder text="Nu sunt planificate antrenamente" />
            ) : (
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TFBTableCell sx={sxStickyColumn}>
                        {languagePack["team_name"]}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.confirmed_presence}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.location}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack["injury_date"]}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack["start_hour"]}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack["end_hour"]}
                      </TFBTableCell>
                      <TFBTableCell align="right">
                        {languagePack.product_actions}
                      </TFBTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.trainings.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        <TFBTableCell
                          component="th"
                          scope="row"
                          sx={sxStickyColumn}
                          style={{
                            backgroundColor:
                              index % 2 == 0 ? "#fafafa" : "white",
                          }}
                        >
                          {row.team_name}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          <div className="plan-training-confirmed-presence">
                            <div
                              style={{
                                backgroundColor: row.color,
                              }}
                              className="plan-training-presence"
                            >
                              {row.nr_will_attend}
                            </div>
                            {"/"}
                            {row.nr_total_players}
                          </div>
                        </TFBTableCell>
                        <TFBTableCell align="center">{row.venue}</TFBTableCell>
                        <TFBTableCell align="center">
                          {moment(row.date)
                            .locale("ro")
                            .format("dddd") +
                            ", " +
                            moment(row.date).format("DD.MM.YYYY")}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {row.hour_start}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {row.hour_end}
                        </TFBTableCell>
                        <TFBTableCell align="right">
                          <div className="plan-training-actions-wrapper">
                            <TFBIconButton
                              name="view"
                              onClick={() =>
                                this.props.history.push(
                                  `/training/history/${row.id_training}`
                                )
                              }
                              color="darkGray"
                            />
                            <TFBIconButton
                              name="report"
                              onClick={() => {
                                this.props.history.push(
                                  `/training/history/details/${row.id_training}`
                                );
                              }}
                              color="darkGray"
                            />
                            <TFBIconButton
                              name="edit"
                              onClick={() =>
                                this.setState(
                                  {
                                    openTrainingToEdit: true,
                                    trainingToEdit: row,
                                  },
                                  () => {
                                    this.props.history.push(
                                      `/training/plan/edit/${row.id_training}`
                                    );
                                  }
                                )
                              }
                              color="darkGray"
                            />
                            <TFBIconButton
                              name="delete"
                              onClick={() =>
                                this.setState({
                                  openTrainingToDelete: true,
                                  trainingToDelete: row.id_training,
                                })
                              }
                              color="darkGray"
                            />
                          </div>
                        </TFBTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </TFBCardBody>

        <Dialog open={this.state.openRecurrentTraining} fullWidth maxWidth="lg">
          <DialogContent>
            <AddRecurrentTraining
              closePopup={() => this.setState({ openRecurrentTraining: false })}
              refreshTrainingsList={this.getTrainingSessions}
              id_club={this.props.currentUser.id_club}
              languagePack={this.props.languagePack}
            />
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.openTrainingToDelete} fullWidth maxWidth="sm">
          <DialogContent>
            <DeleteTraining
              closePopup={() =>
                this.setState({
                  openTrainingToDelete: false,
                  trainingToDelete: "",
                })
              }
              refreshTrainingsList={this.getTrainingSessions}
              idTraining={this.state.trainingToDelete}
              languagePack={this.props.languagePack}
            />
          </DialogContent>
        </Dialog>
      </TFBCard>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(PlanTraining);
