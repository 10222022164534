import React from "react";
import "./TrainingPage.scss";
import axios from "axios";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import TrainingDocuments from "./TrainingDocuments";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import VideoTrainingSection from "../Sections/VideoTrainingSection";
import PresenceList from "./PresenceList";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton.tsx";
import TFBButton from "../../../components/design-system/Button/TFBButton.tsx";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon.tsx";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../components/reusable/useStyles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";

class TrainingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: [],
      training_date: null,
      team: {},

      open_image_popup: false,
      image_to_view: "",

      open_video_popup: false,
      video_to_view: "",

      hasAccessToTraining: false,
      redirectToTrainingHistory: false,
      presenceList: [],
    };

    this.playVideoRef = React.createRef();
  }

  componentDidMount = () => {
    this.hasAccessToTraining();
  };

  hasAccessToTraining = () => {
    axios
      .post(`user/has_access_to_training`, {
        id_club: this.props.currentUser.id_club,
        id_training: this.props.match.params.id,
      })
      .then((res) => {
        if (res.data == 1) {
          this.setState({
            hasAccessToTraining: true,
          });
          axios
            .get(
              `training/get_training_session_details?id_training=${this.props.match.params.id}`
            )
            .then((res) => {
              let presenceList = [];
              res.data.players.forEach((player) =>
                presenceList.push({
                  will_attend: player.will_attend,
                  player_name: player.player_name,
                })
              );

              this.setState({
                schedule: res.data.schedule,
                training_date: res.data.date,
                team: res.data.team,
                presenceList: presenceList,
              });
            })
            .catch((err) => console.log(err));
        } else {
          this.setState({
            redirectToTrainingHistory: true,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    if (this.state.redirectToTrainingHistory) {
      return <Redirect to="/training/history" />;
    }

    const { languagePack } = this.props;
    return (
      <>
        {this.state.hasAccessToTraining && (
          <>
            <div className="training-name">
              {languagePack["training"] + ` ${this.state.team.team_name}, ` + moment(this.state.training_date).format("DD.MM.YYYY") + " - " + moment(this.state.training_date).format("HH:mm")}
            </div>
            <div className="row" style={{ flexDirection: "column" }}>
              <div className="col-lg-12 col-lg-padding" >
                <TFBCard>
                  <TFBCardHeader
                    title={languagePack["training_schedule"]}
                  ></TFBCardHeader>
                  <TFBCardBody>
                    {this.state.schedule.length > 0 ? (
                      <>
                        <TableContainer>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TFBTableCell sx={sxStickyColumn}>
                                  {languagePack["section"]}
                                </TFBTableCell>
                                <TFBTableCell align="center">
                                  {languagePack["hours_interval"]}
                                </TFBTableCell>
                                <TFBTableCell align="center">
                                  {languagePack.image}
                                </TFBTableCell>
                                <TFBTableCell align="center">
                                  {languagePack.video}
                                </TFBTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.schedule.map((row, index) => (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                    backgroundColor:
                                      index % 2 == 0 ? "#fafafa" : "white",
                                  }}
                                >
                                  <TFBTableCell
                                    component="th"
                                    scope="row"
                                    style={{
                                      backgroundColor:
                                        index % 2 == 0 ? "#fafafa" : "white",
                                    }}
                                    sx={sxStickyColumn}
                                  >
                                    {row.section_name}
                                  </TFBTableCell>
                                  <TFBTableCell align="center">
                                    {" "}
                                    <div className="plan-training-confirmed-presence">
                                      {moment(row.date_start).format("HH:mm") +
                                        " - " +
                                        moment(row.date_end).format("HH:mm")}
                                    </div>
                                  </TFBTableCell>
                                  <TFBTableCell align="center">
                                    <div
                                      className="training-schedule-image"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {row.error_img_src ? (
                                        <TFBIcon
                                          name="minus"
                                          size="lg"
                                          color="gray"
                                        />
                                      ) : (
                                        <div
                                          className="image-training-schedule"
                                          style={{
                                            backgroundImage: `url(https://api.thefootballbrain.app/uploads/training_sections/${this.props.currentUser.id_club}/${row.id_training_section}.jpg)`,
                                            backgroundSize: "cover",
                                            height: "50px",
                                            width: "73px",
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              image_to_view: row,
                                              open_image_popup: true,
                                            })
                                          }
                                        ></div>
                                      )}
                                    </div>
                                  </TFBTableCell>
                                  <TFBTableCell align="center">
                                    {row.video_url == null ? (
                                      <TFBIcon
                                        name="minus"
                                        size="lg"
                                        color="gray"
                                      />
                                    ) : (
                                      <div className="training-schedule-video">
                                        <Icon
                                          name="play"
                                          className="thumbanail-play-button"
                                          onClick={() =>
                                            this.playVideoRef.current.click()
                                          }
                                        />
                                        <div
                                          ref={this.playVideoRef}
                                          className="training-video-thumbnail"
                                          onClick={() =>
                                            this.setState({
                                              open_video_popup: true,
                                              video_to_view: row,
                                            })
                                          }
                                        >
                                          <video src={row.video_url} />
                                        </div>
                                      </div>
                                    )}
                                  </TFBTableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    ) : (
                      <TFBPlaceholder text="Nu au fost adaugate sectiuni de antrenament." />
                    )}
                  </TFBCardBody>
                </TFBCard>
              </div>
              <div className="col-lg-12 col-lg-padding" >
                <TrainingDocuments idTraining={this.props.match.params.id} />
              </div>
              <div className="col-lg-12 col-lg-padding" >
                <TFBCard>
                  {this.state.presenceList.length > 0 ? (
                    <PresenceList
                      id_training={this.props.match.params.id}
                      presenceList={this.state.presenceList}
                    />
                  ) : (
                    <TFBPlaceholder text="Nu există prezență la acest antrenament" />
                  )}
                </TFBCard>
              </div>
            </div>
            {/* ############# VIEW IMAGE DIALOG #####################*/}
            <Dialog open={this.state.open_video_popup} maxWidth="lg">
              <div className="section-training-dialog">
                <div className="section-training-dialog-title-wrapper">
                  <div className="section-training-dialog-title">
                    {this.state.video_to_view.section_name}
                  </div>
                  <div className="close-video">
                    <TFBIconButton
                      name="x-small"
                      onClick={() =>
                        this.setState({
                          open_video_popup: false,
                          video_to_view: "",
                        })
                      }
                      color="darkGray"
                    />
                  </div>
                </div>
              </div>
              <DialogContent
                style={{ overflow: "hidden", display: "flex", gap: "30px" }}
              >
                <VideoTrainingSection
                  id={
                    "video-player-" +
                    this.state.video_to_view.id_training_section
                  }
                  src={this.state.video_to_view.video_url}
                />
              </DialogContent>
            </Dialog>

            {/* ############# VIEW IMAGE DIALOG #####################*/}
            <Dialog open={this.state.open_image_popup} maxWidth="lg">
              <div className="section-training-dialog">
                <div className="section-training-dialog-title-wrapper">
                  <div className="section-training-dialog-title">
                    {this.state.image_to_view.section_name}
                  </div>
                  <div className="close-video">
                    <TFBIconButton
                      name="x-small"
                      onClick={() =>
                        this.setState({
                          open_image_popup: false,
                          image_to_view: "",
                        })
                      }
                      color="darkGray"
                    />
                  </div>
                </div>
                <img
                  src={`https://api.thefootballbrain.app/uploads/training_sections/${this.props.currentUser.id_club}/${this.state.image_to_view.id_training_section}.jpg`}
                  alt="training_image"
                  style={{ margin: "10px" }}
                  className="image-in-dialog"
                />
              </div>
            </Dialog>
          </>
        )}
      </>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(TrainingPage);
