import React, { useEffect, useState } from "react";
import "./../ClubProfile.scss";
import axios from "axios";
import "./ExportPlayers.scss";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";

export default function ExportPlayers({
  exportListPopup,
  closePopup,
  languagePack,
  playerData,
  id_club,
  token,
}) {
  const [filtersList, setFiltersList] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  // =========== USE EFFECTS =========================
  useEffect(() => {
    getFilterList();
  }, []);

  // =========== GET FILEDS LIST FOR EXPORT =========================

  const getFilterList = () => {
    axios
      .get(`club/get_export_list_fields`)
      .then((res) => {
        let dataArray = res.data.filter_fields_list.map((item) => {
          return { ...item, checked: false };
        });
        let filtersArray = [
          { value: "all_filters", label: "Toate filtrele", checked: false },
          ...dataArray,
        ];

        setFiltersList(filtersArray);
        setIsFetching(false);
      })
      .catch((err) => console.log(err));
  };

  // =========== HANDLERS =========================

  const renderBtn = (position, onClick) => {
    return (
      <TFBButton
        onClick={onClick}
        endIcon={true}
        color={position == "left" ? "darkGray" : "green"}
        renderIcon={() => (
          <TFBIcon name={position == "left" ? "cancel" : "add"} />
        )}
        prettier-ignore
        disabled={
          position == "right" && !filtersList.some((filter) => filter.checked)
        }
      >
        {position == "left" ? languagePack.Cancel : languagePack.export}
      </TFBButton>
    );
  };

  const handleCheckbox = (e, index) => {
    const filterArray = [...filtersList];

    if (filterArray[index].value != "all_filters") {
      filterArray[index].checked = !filterArray[index].checked;
    } else {
      filterArray.forEach((filter) => (filter.checked = e.target.checked));
    }

    const isAllChecked = filterArray.slice(1).every((filter) => filter.checked);
    setAllChecked(isAllChecked);

    setFiltersList(filterArray);
  };

  // =========== DOWNLOAD/EXPORT PLAYERS LIST  =========================

  const exportPlayers = () => {
    let fields = filtersList
      .filter((filter, index) => {
        return index !== 0 && filter.checked;
      })
      .map((field) => {
        return field.value;
      });

    let payloadFields = fields.join("-");
    let playersIds = playerData.join("_");

    let payload = {
      fields: payloadFields,
      id_club: id_club,
      id_players: playersIds,
      token: token,
    };

    const url = `https://api.thefootballbrain.app/club/export_players_list?id_club=${payload.id_club}&token=${payload.token}&fields=${payload.fields}&id_players=${payload.id_players}`;
    window.location.assign(url);
    closePopup();
  };

  return (
    <TFBDialog
      open={exportListPopup}
      fullWidth={true}
      maxWidth="sm"
      closePopup={closePopup}
      title={languagePack.export_player_list}
      btnRight={() => renderBtn("right", () => exportPlayers())}
      btnLeft={() => renderBtn("left", () => closePopup())}
    >
      {!isFetching && (
        <div className="export-players-popup-content">
          <span className="subheader-span">
            {languagePack.select_data_types_included_in_the_list}
          </span>

          <div className="types-list">
            {filtersList.map((filter, index) => (
              <TFBCheckbox
                key={"export-players-" + index}
                label={languagePack[filter.value]}
                className="all-types-checkbox"
                labelColor={index === 0 ? "#00D948" : undefined}
                onChange={(e) => handleCheckbox(e, index)}
                checked={index == 0 ? allChecked : filter.checked}
              />
            ))}
          </div>
        </div>
      )}
    </TFBDialog>
  );
}
