import React from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { Icon, Button } from "semantic-ui-react";
import "./DeleteMatch.scss";
import { connect } from "react-redux";

function DeleteMatch(props) {
  const { languagePack } = props;

  const deletedMatch = () => {
    const payload = {
      id_match: props?.id_match,
    };
    axios.post("match/delete_match", payload).then(() => {
      store.addNotification({
        title: props.languagePack["fixtures_schedule"],
        message: props.languagePack["match_successfully_added"],
        message: "Meciul a fost sters cu succes!",
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
      props.getMatches();
      props.closePopup();
    });
  };

  return (
    <div className="delete-match-program-popup-container">
      <div className="delete-match-program-header">
        <div className="delete-match-program-title">{"Șterge meci"}</div>
        <Icon
          name="close"
          className="close"
          onClick={() => props.closePopup()}
        />
      </div>
      <div className="delete-match-program-text">
        {
          "Esti sigur ca doresti sa stergi aceast meci? Toate datele aferente vor fi pierdute."
        }
      </div>
      <div className="delete-match-program-buttons">
        <Button className="cancel-button" onClick={() => props.closePopup()}>
          {languagePack["admin_cancel"]}
          <Icon name="close" className="white-icon" />
        </Button>
        <Button className="delete-match-program-button" onClick={deletedMatch}>
          {languagePack["admin_delete"]}
          <Icon name="trash alternate outline" className="white-icon" />
        </Button>
      </div>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(DeleteMatch);
