import axios from "axios";

export const LOGIN_URL = "user/login";
export const REGISTER_URL = "user/register";
export const REQUEST_PASSWORD_URL = "user/forgot_password";
export const REQUEST_CHECK_TOKEN = "user/check_token";
export const REQUEST_CHECK_LOGOUT = "user/logout";
export const REQUEST_RESET_PASSWORD_URL = "user/reset_password"
export const REQUEST_CHECK_RESET_TOKEN = "user/check_reset_token"


export const ME_URL = "api/me";

export function login(data) {
  return axios.post(LOGIN_URL, data);
}

export function register(data) {
  return axios.post(REGISTER_URL, data);
}

export function requestPassword(data) {
  return axios.post(REQUEST_PASSWORD_URL, data);
}

export function resetPassword(data) {
  return axios.post(REQUEST_RESET_PASSWORD_URL, data);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function checkToken(data) {
  return axios.post(REQUEST_CHECK_TOKEN, data);
}

export function logout(data) {
  return axios.post(REQUEST_CHECK_LOGOUT, data);
}

export function checkResetToken(data) {
  return axios.post(REQUEST_CHECK_RESET_TOKEN, data);
}
