import React, { useState, useEffect } from "react";
import { Icon, Button } from "semantic-ui-react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";

import "./StaffMembers.scss";
import axios from "axios";

function AddStaffForm(props) {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [license, setLicense] = useState("");
  const [roleOptions, setRoleOptions] = useState([]);

  const { languagePack } = props;

  const licenseType = [
    {
      id: "0",
      license: "Nicio licență",
      value: "0",
      label: "Nicio licență",
    },
    {
      id: "1",
      license: "UEFA B",
      value: "1",
      label: "UEFA B",
    },
    {
      id: "2",
      license: "UEFA A",
      value: "2",
      label: "UEFA A",
    },
    {
      id: "3",
      license: "UEFA A Youth",
      value: "3",
      label: "UEFA A Youth",
    },
    {
      id: "4",
      license: "UEFA A GK",
      value: "4",
      label: "UEFA A GK",
    },
    {
      id: "5",
      license: "UEFA B GK",
      value: "5",
      label: "UEFA B GK",
    },
    {
      id: "6",
      license: "UEFA Pro",
      value: "6",
      label: "UEFA Pro",
    },
    {
      id: "7",
      license: "NON-UEFA",
      value: "7",
      label: "NON-UEFA",
    },
    {
      id: "8",
      license: "UEFA C",
      value: "8",
      label: "UEFA C",
    },
    {
      id: "9",
      license: "Carnet de antrenor",
      value: "9",
      label: "Carnet de antrenor",
    },
  ];

  const [licenseOption, setLicenseOption] = useState(licenseType);
  const [dateExpire, setDateExpire] = useState(null);

  useEffect(() => {
    getRoles();
  }, []);

  function getRoles() {
    axios
      .get(`club/get_roles?id_club=${props.id_club}`)
      .then((res) => {
        setRoleOptions(Array.isArray(res.data) ? res.data : []);
      })
      .catch((err) => console.log(err));
  }

  function handleChangeName(e) {
    setName(e.target.value);
  }
  const handleChangeDate = (value) => {
    setDateExpire(value);
  };

  function submitNewMember() {
    const member = {
      id_club: props.id_club,
      staff_member_name: name,
      staff_member_role: role.label,
      date_expire_license: dateExpire == "Invalid date" ? null : dateExpire,
      license: license.license,
    };

    axios.post("club/add_new_member", member).then(() => {
      props.closePopup();
      props.refreshMembers();
    });
  }

  return (
    <div className="add-staff-container">
      <div className="add-staff-header">
        <div className="add-staff-title">{languagePack["add_member"]}</div>

        <Icon
          name="close"
          className="close-icon-popup"
          onClick={() => props.closePopup()}
        />
      </div>

      <div className="add-staff-row">
        <input
          type="text"
          value={name}
          onChange={handleChangeName}
          placeholder={languagePack["name"]}
        />
      </div>
      <div className="add-staff-row">
        <Select
          placeholder={languagePack["staff_member_role"]}
          value={role}
          options={roleOptions}
          onChange={(selected) => {
            setRole(selected);
          }}
          styles={{
            container: (provided, state) => ({
              ...provided,
              backgroundColor: "#fafafa",
              width: "100%",
            }),
            control: (provided, state) => ({
              ...provided,
              backgroundColor: "#fafafa",
              height: "50px",
              border: "none",
              "&:hover": {
                border: "none",
                boxShadow: "0 0 0 2px black",
              },
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              paddingLeft: "22px",
              fontSize: "14px",
              fontWeight: 700,
            }),
            placeholder: (provided, state) => ({
              ...provided,
              color: "#b2b2b2",
            }),
            indicatorSeparator: (provided, state) => ({
              ...provided,
              display: "none",
            }),
            menu: (provided, state) => ({
              ...provided,
              maxHeight: "350px",
            }),
            menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
          }}
          menuPortalTarget={document.body}
        />
      </div>
      <div className="add-staff-row">
        <Select
          placeholder={languagePack.select_license}
          value={license}
          options={licenseOption}
          onChange={(selected) => {
            setLicense(selected);
            setDateExpire(null);
          }}
          styles={{
            container: (provided, state) => ({
              ...provided,
              backgroundColor: "#fafafa",
              width: "100%",
            }),
            control: (provided, state) => ({
              ...provided,
              backgroundColor: "#fafafa",
              height: "50px",
              border: "none",
              "&:hover": {
                border: "none",
                boxShadow: "0 0 0 2px black",
              },
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              paddingLeft: "22px",
              fontSize: "14px",
              fontWeight: 700,
            }),
            placeholder: (provided, state) => ({
              ...provided,
              color: "#b2b2b2",
            }),
            indicatorSeparator: (provided, state) => ({
              ...provided,
              display: "none",
            }),
            menu: (provided, state) => ({
              ...provided,
              maxHeight: "350px",
            }),
            menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
          }}
          menuPortalTarget={document.body}
        />
      </div>

      {/* {license != "NON-UEFA" ? ( */}
      {license != "Nicio licență" && license != "" ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="add-staff-row-date">
            <DatePicker
              value={dateExpire}
              onChange={handleChangeDate}
              emptyLabel={"Dată expirare licență"}
              format="dd MMMM yyyy"
              className="add-player-date-input"
            />
            <Icon
              name="calendar alternate outline"
              className="calendar-icon"
              size="small"
            />
          </div>
        </MuiPickersUtilsProvider>
      ) : (
        false
      )}

      <div className="add-staff-buttons">
        <Button
          className="custom-button-dual add-button"
          disabled={name == "" || role == "" || license == ""}
          onClick={submitNewMember}
        >
          {languagePack["admin_add"]}
          <Icon name="add" className="white-icon" />
        </Button>
      </div>
    </div>
  );
}

export default AddStaffForm;
