import React from "react";
import axios from "axios";
import { Button, Icon } from "semantic-ui-react";
import "./../../Club/Club.styles.scss";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { store } from "react-notifications-component";
import Select from "react-select";
import "./AddRecurrentTraining.scss";
import { connect } from "react-redux";
import moment from "moment";

const daysOfWeek = [
  { key: "monday", value: "1", label: "Luni" },
  { key: "tuesday", value: "2", label: "Marți" },
  { key: "wednesday", value: "3", label: "Miercuri" },
  { key: "thursday", value: "4", label: "Joi" },
  { key: "friday", value: "5", label: "Vineri" },
  { key: "saturday", value: "6", label: "Sâmbătă" },
  { key: "sunday", value: "7", label: "Duminica" },
];

class AddRecurrentTraining extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: [],
      selected_teams: [],
      day_of_week: null,
      locations: [],
      location: null,
      start_hour: null,
      end_hour: null,
      start_recurrence_date: null,
      end_recurrence_date: null,
    };
  }

  componentDidMount = () => {
    this.getClubTeams();
    this.getClubVenues();
  };

  getClubTeams = () => {
    axios
      .get(
        `training/get_teams_dropdown?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => {
        let teams = [];
        res.data.map((item) => {
          return teams.push({
            value: item.value,
            label: item.text,
          });
        });
        this.setState({ teams: teams });
      })
      .catch((err) => console.log(err));
  };

  getClubVenues = () => {
    axios
      .get(`club/get_venues?id_club=${this.props.currentUser.id_club}`)
      .then((res) => {
        this.setState({
          locations: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

  handleChangeTimePicker = (var_name, value) => {
    this.setState({
      [var_name]: value,
    });
  };

  addRecurrentTraining = () => {
    const payload = {
      id_club: this.props.currentUser.id_club,
      teams: this.state.selected_teams,
      day_of_week: this.state.day_of_week,
      location: this.state.location?.value ? this.state.location.value : null,
      start_hour: moment(this.state.start_hour).format("HH:mm"),
      end_hour: moment(this.state.end_hour).format("HH:mm"),
      start_recurrence_date: moment(this.state.start_recurrence_date).format(
        "YYYY-MM-DD"
      ),
      end_recurrence_date: moment(this.state.end_recurrence_date).format(
        "YYYY-MM-DD"
      ),
    };
    axios
      .post("training/add_recurrent_training", payload)
      .then((res) => {
        if (res.data > 0) {
          this.props.refreshTrainingsList();
          store.addNotification({
            title: this.props.languagePack["training_add_title"],
            message: "Antrenamentul recurent a fost adaugat cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: this.props.languagePack["training_add_title"],
            message: "Antrenamentul recurent nu a fost adaugat cu succes!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        this.props.closePopup();
      })
      .catch((e) => console.log(e));
  };

  handleHourStartChange = (value) => {
    this.setState({ start_hour: value });
  };

  handleHourEndChange = (value) => {
    this.setState({ end_hour: value });
  };

  handleStartDateChange = (value) => {
    this.setState({ startRecurrent: value });
  };

  handleEndDateChange = (value) => {
    this.setState({ endRecurrent: value });
  };

  render() {
    const { languagePack } = this.props;

    return (
      <div className="add-recurrent-form">
        <div className="add-recurrent-header">
          <div className="add-recurrent-title">
            {languagePack.add_recurrent_training}
          </div>
          <div className="add-player-popup-close">
            {" "}
            <Icon
              name="close"
              className="close-icon-popup"
              onClick={() => this.props.closePopup()}
            />
          </div>
        </div>
        <div className="add-recurrent-row add-recurrent-row2">
          <Select
            placeholder={languagePack.team}
            isMulti={true}
            className="recurrent-select-teams"
            options={this.state.teams}
            value={this.state.selected_teams}
            onChange={(selected) => this.setState({ selected_teams: selected })}
            styles={{
              container: (provided, state) => ({
                ...provided,
                backgroundColor: "#fafafa",
                width: "100%",
              }),
              control: (provided, state) => ({
                ...provided,
                backgroundColor: "#fafafa",
                height: "50px",
                border: "none",
                "&:hover": {
                  border: "none",
                  boxShadow: "0 0 0 2px black",
                },
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                paddingLeft: "22px",
                fontSize: "14px",
                fontWeight: 700,
              }),
              placeholder: (provided, state) => ({
                ...provided,
                color: "#b2b2b2",
              }),
              indicatorSeparator: (provided, state) => ({
                ...provided,
                display: "none",
              }),
              menu: (provided, state) => ({
                ...provided,
                maxHeight: "350px",
              }),
              menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
            }}
            menuPortalTarget={document.body}
          />
        </div>
        <div className="add-recurrent-row more-gap-recurrent">
          <Select
            placeholder={languagePack.day_of_the_week}
            className="recurrent-select-teams"
            options={daysOfWeek}
            value={this.state.day_of_week}
            onChange={(selected) => this.setState({ day_of_week: selected })}
            menuPortalTarget={document.body}
            styles={{
              container: (provided, state) => ({
                ...provided,
                backgroundColor: "#fafafa",
                width: "100%",
              }),
              control: (provided, state) => ({
                ...provided,
                backgroundColor: "#fafafa",
                height: "50px",
                border: "none",
                "&:hover": {
                  border: "none",
                  boxShadow: "0 0 0 2px black",
                },
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                paddingLeft: "22px",
                fontSize: "14px",
                fontWeight: 700,
              }),
              placeholder: (provided, state) => ({
                ...provided,
                color: "#b2b2b2",
              }),
              indicatorSeparator: (provided, state) => ({
                ...provided,
                display: "none",
              }),
              menu: (provided, state) => ({
                ...provided,
                maxHeight: "350px",
              }),
              menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
            }}
          />
          <Select
            placeholder={languagePack.location}
            className="recurrent-select-teams"
            options={this.state.locations}
            value={this.state.location}
            isClearable={true}
            onChange={(selected) => this.setState({ location: selected })}
            styles={{
              container: (provided, state) => ({
                ...provided,
                backgroundColor: "#fafafa",
                width: "100%",
              }),
              control: (provided, state) => ({
                ...provided,
                backgroundColor: "#fafafa",
                height: "50px",
                border: "none",
                "&:hover": {
                  border: "none",
                  boxShadow: "0 0 0 2px black",
                },
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                paddingLeft: "22px",
                fontSize: "14px",
                fontWeight: 700,
              }),
              placeholder: (provided, state) => ({
                ...provided,
                color: "#b2b2b2",
              }),
              indicatorSeparator: (provided, state) => ({
                ...provided,
                display: "none",
              }),
              menu: (provided, state) => ({
                ...provided,
                maxHeight: "350px",
              }),
              menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
            }}
            menuPortalTarget={document.body}
          />
        </div>
        <div className="add-recurrent-row">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
              showTodayButton
              ampm={false}
              emptyLabel={languagePack["start_hour"]}
              value={this.state.start_hour}
              minutesStep={5}
              inputVariant="outlined"
              className="recurrent-training-hour-input"
              onChange={(date) =>
                this.handleChangeTimePicker("start_hour", date)
              }
              name={"start_hour"}
            />
            <Icon
              name="clock outline"
              className="calendar-icon start-hour-icon"
              size="small"
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
              showTodayButton
              ampm={false}
              emptyLabel={languagePack["end_hour"]}
              value={this.state.end_hour}
              minutesStep={5}
              inputVariant="outlined"
              className="recurrent-training-hour-input"
              onChange={(date) => this.handleChangeTimePicker("end_hour", date)}
              name={"end_hour"}
            />
            <Icon name="clock outline" className="calendar-icon" size="small" />
          </MuiPickersUtilsProvider>
        </div>
        <div className="add-recurrent-row">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              value={this.state.start_recurrence_date}
              emptyLabel={languagePack.start_recurrent}
              format="dd.MM.yyyy"
              inputVariant="outlined"
              className="recurrent-training-hour-input"
              name={"start_recurrence_date"}
              onChange={(date) =>
                this.handleChangeTimePicker("start_recurrence_date", date)
              }
            />
            <Icon
              name="calendar outline"
              className="calendar-icon start-date-icon"
              size="small"
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              value={this.state.end_recurrence_date}
              emptyLabel={languagePack.end_recurrent}
              format="dd.MM.yyyy"
              inputVariant="outlined"
              className="recurrent-training-hour-input"
              name={"end_recurrence_date"}
              onChange={(date) =>
                this.handleChangeTimePicker("end_recurrence_date", date)
              }
            />
            <Icon
              name="calendar outline"
              className="calendar-icon"
              size="small"
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="add-recurrent-buttons">
          <Button
            className="custom-button-dual add-button"
            onClick={this.addRecurrentTraining}
            disabled={
              this.state.teams == null ||
              this.state.day_of_week == null ||
              this.state.start_hour == null ||
              this.state.end_hour == null ||
              this.state.start_recurrence_date == null ||
              this.state.end_recurrence_date == null
            }
          >
            {" "}
            <div className="button-content">
              {languagePack["admin_add"]}
              <Icon name="add" size="small" className="add-icon" />
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(AddRecurrentTraining);
