import React, { useState } from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import CurrencyPicker from "./CurrencyPicker";

const AddPaymentForPlayer = (props) => {

    const { languagePack, closePopup, isOpenPopup, refreshList, paymentRequestData } = props;

    const paymentTypes = [
        {
            value: "cash",
            label: languagePack.add_payment_type["cash"]
        },
        {
            value: "bank_transfer",
            label: languagePack.add_payment_type["bank_transfer"],
        },
    ];

    const [editData, setEditData] = useState({
        amount: Number(paymentRequestData?.amount - paymentRequestData?.amount_paid > 0 ? paymentRequestData?.amount - paymentRequestData?.amount_paid : 0) ?? "",
        currency: paymentRequestData?.currency ?? "RON",
        payment_type: null,
        payment_payed_date: new Date(),
    });

    const onChangeInput = (e) => {
        setEditData({ ...editData, ...{ amount: e.target.value } });
    }

    const addPaymentForPlayer = () => {
        const payload = {
            id_payment_request: paymentRequestData?.id_payment_request,
            id_player: paymentRequestData?.id_player,
            id_team: paymentRequestData?.id_team,
            id_club: paymentRequestData?.id_club,
            amount: paymentRequestData?.amount,
            amount_paid: editData?.amount,
            currency: editData?.currency,
            payment_type_label: editData?.payment_type?.value,
            payment_payed_date: editData?.payment_payed_date,
            was_paid: 1,
        };

        axios
            .post("finances/add_payment_for_player_payment_request_2", payload)
            .then(res => {
                if (res.data.success == 1) {
                    refreshList();
                    store.addNotification({
                        title: languagePack.payment_request,
                        message: languagePack.success_add_payment,
                        type: "success",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                } else {
                    store.addNotification({
                        title: languagePack.payment_request,
                        message: languagePack.fail_add_payment,
                        type: "danger",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                }
                closePopup();
            })
            .catch(e => console.log(e))
    }

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack.add_payment}
            open={isOpenPopup}
            closePopup={closePopup}
            btnLeft={() => (
                <TFBButton
                    color="darkGray"
                    renderIcon={() => <TFBIcon name="cancel" />}
                    onClick={closePopup}
                >
                    {languagePack.admin_cancel}
                </TFBButton>
            )}
            btnRight={() => (
                <TFBButton
                    color="green"
                    renderIcon={() => <TFBIcon name="check" />}
                    disabled={editData.payment_type == null || editData.payment_payed_date == null}
                    onClick={addPaymentForPlayer}
                >
                    {languagePack.apply}
                </TFBButton>
            )}
        >
            <div className="pop-up-section">
                <div className="pop-up-section-body">
                    <div className="pop-up-row">
                        <label className="row-label">{languagePack.payment_request_amount}</label>
                        <br />
                        <div className="row-input-with-currency">
                            <input
                                className="row-input"
                                type="number"
                                placeholder={`${languagePack.enter_payment_request_amount} (${editData.currency})`}
                                value={editData.amount}
                                name="amount"
                                onChange={onChangeInput}
                            />
                            <div className="currency-options">
                                <CurrencyPicker
                                    name="RON"
                                    checked={editData.currency == "RON"}
                                    onClick={() => setEditData({ ...editData, ...{ currency: "RON" } })}
                                />
                                {/* <CurrencyPicker
                                        name="EUR"
                                        checked={editData.currency == "EUR"}
                                        onClick={() => setEditData({ ...editData, ...{ currency: "EUR" } })}
                                    /> */}
                            </div>
                        </div>
                    </div>
                    <div className="pop-up-row">
                        <label className="row-label">{languagePack.payment_type}</label>
                        <br />
                        <div className="row-team">
                            <TFBSelect
                                placeholder={languagePack.choose_payment_type}
                                options={paymentTypes}
                                value={editData.payment_type}
                                onChange={(selected) => setEditData({ ...editData, ...{ payment_type: selected } })}
                            />
                        </div>
                    </div>
                    <div className="pop-up-row">
                        <label className="row-label">{languagePack.payment_date}</label>
                        <br />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div className="row-input-with-date">
                                <DatePicker
                                    value={editData.payment_payed_date}
                                    onChange={(value) => setEditData({ ...editData, ...{ payment_payed_date: value } })}
                                    ampm={false}
                                    format="dd.MM.yyyy"
                                    name="payment_payed_date"
                                    inputVariant="outlined"
                                    className="input-with-date"
                                    emptyLabel={languagePack.choose_payment_date}
                                />
                                <TFBIcon
                                    name="calendar"
                                    color="gray"
                                    className="calendar-icon"
                                />
                            </div>
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
            </div>
        </TFBDialog>
    )
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
}))(AddPaymentForPlayer);