import React, { useState, useEffect } from "react";
import axios from "axios";
import NumberFormat from "react-number-format";
import { Button, Icon } from "semantic-ui-react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { store } from "react-notifications-component";
import "./PlayerProfile.scss";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../components/reusable/useStyles";

export default function PlayerTransactions(props) {
  const [transactions, setTransactions] = useState([]);
  const [total, setTotal] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [costDate, setCostDate] = useState(null);
  const [addNewCategory, setAddNewCategory] = useState(false);
  const [category, setCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [spendingMatchValue, setSpendingMatchValue] = useState("");

  const [currency, setCurrency] = useState(props.currency);
  const [rates, setRates] = useState([]);

  const [deletePlayerCostOpen, setDeletePlayerCostOpen] = useState(false);
  const [deletingCost, setDeletingCost] = useState("");

  const { languagePack } = props;

  useEffect(() => {
    getCurrency();
  }, []);

  useEffect(() => {
    getTransactions();
    getCategories();
  }, [rates]);

  useEffect(() => {
    setTotal(getTotalTransaction(transactions));
  }, [props.currency]);

  const getTransactions = () => {
    axios
      .get(`player/get_transactions_history?id_player=${props.id_player}`)
      .then((res) => {
        setTransactions(res.data.transactions);
        setTotal(getTotalTransaction(res.data.transactions));
      })
      .catch((error) => console.log(error));
  };

  const resetDialogField = () => {
    setCurrency(props.currency);
    setOpenDialog(false);
    setAddNewCategory(false);
    setSpendingMatchValue("");
    setCategory("");
    setCostDate(null);
  };

  const convertCurrency = (value, currency) => {
    const user_currency = props.currency;
    if (user_currency == currency) {
      return value;
    } else {
      if (user_currency == "EUR") {
        return value / rates[currency];
      } else {
        return value * rates[user_currency];
      }
    }
  };

  const formatNumber = (value, currency) => {
    return (
      <NumberFormat
        value={convertCurrency(value, currency)}
        displayType={"text"}
        thousandSeparator={true}
        decimalScale={0}
      />
    );
  };

  const getTotalTransaction = (bonuses) => {
    let sum = 0;
    bonuses.map((elem) => {
      sum += parseFloat(convertCurrency(elem.expense_value, elem.currency));
    });

    return formatNumber(sum, props.currency);
  };

  const getCurrency = () => {
    axios
      .get("https://api.thefootballbrain.app/club/get_currency_rates")
      .then((res) => {
        setRates(res.data);
      });
  };

  const getCategories = () => {
    axios
      .get(`/player/get_player_expence_categories?id_club=${props.id_club}`)
      .then((res) => setCategoryOptions(res.data));
  };

  const submitPlayerSpending = () => {
    const payload = {
      expense_value: spendingMatchValue,
      expense_date: costDate,
      id_club: props.id_club,
      id_team: props.id_team,
      id_player: props.id_player,
      currency: currency,
    };

    if (addNewCategory) {
      payload.new_category_name = category;
    } else payload.id_player_expense_category = category;

    axios.post(`player/add_player_expence_category`, payload).then(() => {
      store.addNotification({
        title: "Player operation",
        message: "Costul a fost adaugat cu scucces",
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
      getCategories();
      getTransactions();
    });
  };

  const handleChangeDate = (value) => {
    setCostDate(value);
  };

  const openDeleteDialog = (cost) => {
    setDeletePlayerCostOpen(true);
    setDeletingCost(cost);
  };

  const closeDeleteDialog = () => {
    setDeletePlayerCostOpen(false);
    setDeletingCost("");
  };

  const deleteCost = () => {
    axios
      .post(
        "player/delete_player_expence_item",
        { id_player_expense_item: deletingCost.id_player_expense_item },
        {
          params: {
            // token: this.props.token,
          },
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then(async (res) => {
        if (res.data == 1) {
          getTransactions();
          closeDeleteDialog();
          store.addNotification({
            title: "Player operation",
            message: "Ai sters costul pentru jucator cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: "Player operation",
            message: "S-a produs o eroare. Incearca din nou!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch(async (err) => {
        return { data: {} };
      });
  };

  return (
    <TFBCard style={{ height: "100%" }}>
      <TFBCardHeader title={languagePack.cost_history}>
        {props.has_access && (
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
            onClick={() => setOpenDialog(true)}
          >
            {languagePack.add_cost}
          </TFBButton>
        )}
      </TFBCardHeader>
      <TFBCardBody>
        {props.has_access ? (
          transactions.length > 0 ? (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn}>
                      {languagePack.transaction_description}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.transaction_date}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.trasnaction_amount}
                    </TFBTableCell>
                    <TFBTableCell align="right">
                      {languagePack.order_actions}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      <TFBTableCell
                        sx={sxStickyColumn}
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        {row.player_expense_category}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.expense_date == null
                          ? "-"
                          : moment(row.expense_date).format("DD.MM.YYYY")}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {formatNumber(row.expense_value, row.currency)}{" "}
                        {props.currency == "EUR" ? (
                          <Icon name="eur" color="black" className="mg--0" />
                        ) : (
                          <span className="font-weight-bold">{"RON"}</span>
                        )}
                      </TFBTableCell>
                      <TFBTableCell>
                        <div
                          className="actions-btns-container"
                          style={{ justifyContent: "right" }}
                        >
                          <TFBIconButton
                            name="delete"
                            color="darkGray"
                            onClick={() => openDeleteDialog(row)}
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TFBPlaceholder text="Nu sunt costuri." />
          )
        ) : (
          <TFBPlaceholder text={languagePack["section_access_forbidden"]} />
        )}
      </TFBCardBody>

      {openDialog && (
        <Dialog open={openDialog} maxWidth="xl">
          <DialogContent>
            <div className="player-transaction-popup-container">
              <div className="player-transaction-header">
                <div className="player-transaction-title">
                  {languagePack.add_transaction}
                </div>
                <Icon
                  name="close"
                  size="large"
                  onClick={() => resetDialogField()}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="player-transaction-row">
                <div className="add-categories-dropdown">
                  <select
                    // value={category || ""}
                    defaultValue={category}
                    onChange={(e) => {
                      if (e.target.value == "newGroupCost") {
                        setAddNewCategory(true);
                        setCategory(e.value);
                      } else {
                        setAddNewCategory(false);
                        setCategory(e.target.value);
                      }
                    }}
                  >
                    <option value="" disabled>
                      {languagePack.select_cost_category}
                    </option>
                    <option value="newGroupCost">
                      {languagePack.add_new_cost_category}
                    </option>
                    {categoryOptions.map((item, index) => {
                      return (
                        <option value={item.value} key={index}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              {addNewCategory && (
                <div className="player-transaction-row">
                  <input
                    placeholder={"Categorie de costuri"}
                    className="cost-category"
                    // player-cost-value-per-match
                    value={category || ""}
                    onChange={(e) => setCategory(e.target.value)}
                  />
                </div>
              )}

              <div className="player-transaction-row transaction-currency">
                <input
                  type="number"
                  placeholder={languagePack.trasnaction_amount}
                  className="player-cost-value-per-match"
                  value={spendingMatchValue}
                  onChange={(e) => setSpendingMatchValue(e.target.value)}
                />
                <div
                  className="currency-container"
                  onClick={() => setCurrency("RON")}
                >
                  <div
                    className={
                      currency === "RON"
                        ? "currency-checkbox currency-checkbox-selected"
                        : "currency-checkbox"
                    }
                  />
                  <div className="currency-name">RON</div>
                </div>
                <div
                  className="currency-container"
                  onClick={() => setCurrency("EUR")}
                >
                  <div
                    className={
                      currency === "EUR"
                        ? "currency-checkbox currency-checkbox-selected"
                        : "currency-checkbox"
                    }
                  />
                  <div className="currency-name">EUR</div>
                </div>
              </div>
              <div className="player-transaction-cost-date">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="cost-date-wrapper">
                    <DatePicker
                      value={costDate}
                      onChange={handleChangeDate}
                      ampm={false}
                      format="dd MMMM yyyy"
                      inputVariant="outlined"
                      name="match_date"
                      className="add-match-date-picker"
                      emptyLabel={languagePack["match_date"]}
                    />
                    <Icon
                      name="calendar alternate outline"
                      className="calendar-icon"
                      size="small"
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </div>
              <div className="player-spending-details-buttons">
                <Button
                  className="player-spending-button"
                  style={{ backgroundColor: "#666666" }}
                  onClick={() => resetDialogField()}
                >
                  {languagePack["admin_cancel"]}
                  <Icon name="ban" size="small" className="edit-details-icon" />
                </Button>
                <Button
                  className="player-spending-button"
                  onClick={() => {
                    submitPlayerSpending();
                    resetDialogField();

                    // getCategories();
                  }}
                  style={{
                    backgroundColor: window.clubCustomization.primary_color,
                  }}
                >
                  {languagePack["admin_save"]}
                  <Icon
                    name="check"
                    size="small"
                    className="edit-details-icon"
                  />
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}

      {/* STERGERE COST JUCATOR*/}
      <Dialog open={deletePlayerCostOpen}>
        <div className="bonus-options-list-popup-header">
          <div className="bonus-options-list-title">
            {languagePack.delete_cost}
          </div>
          <div className="bonus-options-list-close-icon">
            {" "}
            <Icon
              name="close"
              onClick={closeDeleteDialog}
              size="large"
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <DialogContent>
          <div className="delete-bonus-query">
            {languagePack.delete_cost_message}
            <span
              style={{
                color: "var(--primaryColor)",
                textTransform: "uppercase",
              }}
            >
              {deletingCost.player_expense_category}
            </span>
            {"?"}
          </div>
          <div className="delete-bonus-buttons">
            <Button
              className="delete-bonus-button grey-btn"
              onClick={closeDeleteDialog}
            >
              <div className="button-content">
                {languagePack["admin_cancel"]}
              </div>
              <Icon name="ban" className="white-icon" />
            </Button>
            <Button
              className="delete-bonus-button"
              color="red"
              onClick={deleteCost}
            >
              <div className="button-content">{languagePack.yes_delete}</div>
              <Icon name="trash" className="white-icon" />
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </TFBCard>
  );
}
