import React from "react";
import "./AddCompetition.scss";
import { Icon, Button, Dropdown, Checkbox, Input } from "semantic-ui-react";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";

const ageCategorieOptions = [
  {
    value: 0,
    label: "Seniori",
  },
  {
    value: 1,
    label: "U6",
  },
  {
    value: 2,
    label: "U7",
  },
  {
    value: 3,
    label: "U8",
  },
  {
    value: 4,
    label: "U9",
  },
  {
    value: 5,
    label: "U10",
  },
  {
    value: 6,
    label: "U11",
  },
  {
    value: 7,
    label: "U12",
  },
  {
    value: 8,
    label: "U13",
  },
  {
    value: 9,
    label: "U14",
  },
  {
    value: 10,
    label: "U15",
  },
  {
    value: 11,
    label: "U16",
  },
  {
    value: 12,
    label: "U17",
  },
  {
    value: 13,
    label: "U18",
  },
  {
    value: 14,
    label: "U19",
  },
  {
    value: 15,
    label: "U20",
  },
  {
    value: 16,
    label: "U21",
  },
  {
    value: 17,
    label: "U22",
  },
  {
    value: 18,
    label: "U23",
  },
];

class AddCompetition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      nr_teams: "",
      nr_groups: "",
      type: "",
      addedTeams: [],
      team_name: "",
      date_start: null,
      date_end: null,
      multiple_age_groups: false,
      minutes_per_time: "",
      ageCategory: null,
    };
  }

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeInputGroups = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDateChange = (value) => {
    this.setState({ date_start: value });
  };

  handleEndDateChange = (value) => {
    this.setState({ date_end: value });
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value });
  };

  changeMinutesPerTime = (e) => {
    if (
      e.length > 2 ||
      (e.length == 2 && Number(e) > 45) ||
      (e.length == 1 && Number(e) < 1)
    )
      return false;

    this.setState((prevState) => ({
      ...prevState,
      minutes_per_time: e,
    }));
  };

  submitCompetition = () => {
    const compData = {
      competition_name: this.state.name,
      competition_type: this.state.type,
      id_club: this.props.currentUser.id_club,
      teams_number: this.state.nr_teams,
      groups_number: this.state.nr_groups,
      date_start: this.state.date_start,
      date_end: this.state.date_end,
      multiple_age_groups: this.state.multiple_age_groups ? 1 : 0,
    };

    if (this.state.minutes_per_time != "") {
      compData.minutes_per_time = this.state.minutes_per_time;
    }

    if (this.state.ageCategory) {
      compData.category = this.state.ageCategory.label;
    }

    axios.post("match/add_competition", compData).then(() => {
      store.addNotification({
        title: this.props.languagePack.competitions_actions,
        message: this.props.languagePack.competition_success_added,
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
      this.props.getCompetitions();
      this.props.closePopup();
    });
  };

  render() {
    const { languagePack } = this.props;

    const comp_types = [
      {
        key: 1,
        value: "championship",
        text: languagePack.competition_type.elements["championship"],
      },
      // {
      //   key: 2,
      //   value: "knockout",
      //   text: languagePack.competition_type.elements["knockout"],
      // },
      {
        key: 3,
        value: "group-stage",
        text: languagePack.competition_type.elements["group_stage"],
      },
    ];

    return (
      <div className="add-comp-container">
        <div className="add-comp-header">
          <div className="add-comp-title">
            {languagePack["add_competition"]}
          </div>
          <Icon
            name="close"
            className="close"
            size="large"
            onClick={() => this.props.closePopup()}
          />
        </div>
        <div className="add-comp-row">
          <input
            placeholder={languagePack["competition_name"]}
            className="add-comp-input"
            name="name"
            value={this.state.name}
            onChange={this.handleChangeInput}
          />
        </div>
        <div className="add-comp-row">
          <div className="add-checkbox-input">
            <Checkbox
              label={languagePack["competition_has_more_cat"]}
              checked={this.state.multiple_age_groups}
              onChange={(e, data) => {
                this.setState({
                  multiple_age_groups: data.checked,
                });
              }}
            />
          </div>
        </div>
        {!this.state.multiple_age_groups && (
          <div className="add-comp-row">
            <input
              placeholder={languagePack["teams_number"]}
              type="number"
              className="add-comp-input"
              name="nr_teams"
              value={this.state.nr_teams}
              onChange={this.handleChangeInput}
            />
          </div>
        )}
        {!this.state.multiple_age_groups && (
          <div className="add-comp-row">
            <Dropdown
              selection
              placeholder={languagePack.competition_type["name"]}
              name="type"
              value={this.state.type}
              options={comp_types}
              onChange={this.handleChangeDropdown}
              className="add-comp-dropdown"
            />
          </div>
        )}
        {!this.state.multiple_age_groups && (
          <div className="add-comp-row">
            <Input
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              placeholder={languagePack.minutes_per_time}
              className="add-value-input-time comp-minutes-per-time-input"
              name="time"
              onChange={(e) => {
                this.changeMinutesPerTime(e.target.value);
              }}
              value={this.state.minutes_per_time ?? ""}
            />
          </div>
        )}
        {this.state.type == "group-stage" && (
          <div className="add-comp-row">
            <input
              placeholder={languagePack["number_of_groups"]}
              type="number"
              className="add-comp-input"
              name="nr_groups"
              value={this.state.nr_groups}
              onChange={this.handleChangeInputGroups}
            />
          </div>
        )}
        {!this.state.multiple_age_groups && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="add-comp-row">
              <DatePicker
                value={this.state.date_start}
                onChange={this.handleDateChange}
                format="dd MMMM yyyy"
                name="date_start"
                inputVariant="outlined"
                className="comp-date-input"
                emptyLabel={languagePack["starting_date"]}
              />
              <Icon
                name="calendar alternate outline"
                className="calendar-icon"
                size="small"
              />
            </div>
            <div className="add-comp-row">
              <DatePicker
                value={this.state.date_end}
                onChange={this.handleEndDateChange}
                format="dd MMMM yyyy"
                name="date_end"
                inputVariant="outlined"
                className="comp-date-input"
                emptyLabel={languagePack["ending_date"]}
              />
              <Icon
                name="calendar alternate outline"
                className="calendar-icon"
                size="small"
              />
            </div>
          </MuiPickersUtilsProvider>
        )}

        <div
          className="select-age-category"
          style={{ marginTop: "25px", marginBottom: "25px" }}
        >
          <TFBSelect
            placeholder={languagePack.select_age_category}
            label={languagePack.select_age_category}
            options={ageCategorieOptions}
            value={this.state.ageCategory}
            onChange={(selected) => this.setState({ ageCategory: selected })}
          />
        </div>

        <div className="add-comp-buttons">
          <Button
            className="cancel-button"
            onClick={() => this.props.closePopup()}
          >
            {languagePack["admin_cancel"]}
            <Icon name="close" className="white-icon" />
          </Button>
          <Button
            className="add-comp-button"
            onClick={this.submitCompetition}
            disabled={
              this.state.name == ""
                ? true
                : this.state.multiple_age_groups
                ? false
                : this.state.type == ""
            }
          >
            {languagePack["admin_add"]}
            <Icon name="add" className="white-icon" />
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(AddCompetition);
