import React from "react";
import "./TrainingHistory.scss";
import DeleteTraining from "../PlanTraining/DeleteTraining";
import axios from "axios";
import { connect } from "react-redux";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import "moment/locale/ro";

import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton.tsx";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../components/reusable/useStyles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TFBDropdown from "../../../components/design-system/Dropdown/TFBDropdown";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";

class TrainingHistory extends React.Component {
  constructor(props) {
    const team = new URLSearchParams(props.location.search).get("team");
    super(props);
    this.state = {
      trainings: [],
      teamOptions: [],
      selected_team: team > 0 ? team : "",
      openTrainingToDelete: false,
      trainingToDelete: "",
    };
  }

  componentDidMount = () => {
    this.getTrainingSessions();
    this.getTeamsDropdown();
  };

  getTrainingSessions = () => {
    axios
      .get(
        `training/get_training_sessions?id_club=${this.props.currentUser.id_club}&selected_team=${this.state.selected_team}&history=1&limit=50`
      )
      .then((res) => this.setState({ trainings: res.data }))
      .catch((err) => console.log(err));
  };

  getTeamsDropdown = () => {
    axios
      .get(
        `training/get_teams_dropdown?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => {
        if (this.state.selected_team == "") {
          this.setState({
            selected_team: res.data.length > 0 ? res.data[0].value : "",
          });
        }
        this.setState({
          teamOptions: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

  handleChangeTeam = (e, value) => {
    let url = this.props.location.search;
    let search_params = new URLSearchParams(url);
    if (value.value > 0) {
      search_params.set("team", value.value);
    } else {
      search_params.delete("team");
    }
    window.history.replaceState(null, null, "?" + search_params.toString());
    this.setState(
      {
        [value.name]: value.value,
      },
      this.getTrainingSessions
    );
  };

  render() {
    const { languagePack } = this.props;
    return (
      <TFBCard>
        <TFBCardHeader title={languagePack["trainings_history"]}>
          <TFBDropdown
            placeholder={languagePack["select_team"]}
            selection
            options={this.state.teamOptions}
            name="selected_team"
            color="gray"
            value={this.state.selected_team}
            onChange={this.handleChangeTeam}
          />
        </TFBCardHeader>

        <TFBCardBody>
          {this.state.trainings == 0 ? (
            <TFBPlaceholder text="Nu sunt antrenamente" />
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn}>
                      {languagePack["team_name"]}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.location}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack["injury_date"]}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack["start_hour"]}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack["end_hour"]}
                    </TFBTableCell>
                    <TFBTableCell align="right">
                      {languagePack.product_actions}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.trainings.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      <TFBTableCell
                        component="th"
                        scope="row"
                        sx={sxStickyColumn}
                        onClick={() =>
                          this.props.history.push(
                            `/training/history/${row.id_training}`
                          )
                        }
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                          cursor: "pointer",
                        }}
                      >
                        {row.team_name}
                      </TFBTableCell>
                      <TFBTableCell align="center">{row.venue}</TFBTableCell>
                      <TFBTableCell align="center">
                        {" "}
                        {moment(row.date)
                          .locale("ro")
                          .format("dddd") +
                          ", " +
                          moment(row.date).format("DD.MM.YYYY")}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.hour_start}
                      </TFBTableCell>
                      <TFBTableCell align="center">{row.hour_end}</TFBTableCell>
                      <TFBTableCell align="right">
                        <div className="training-history-actions-wrapper">
                          <TFBIconButton
                            name="view"
                            onClick={() =>
                              this.props.history.push(
                                `/training/history/${row.id_training}`
                              )
                            }
                            color="darkGray"
                          />
                          <TFBIconButton
                            name="report"
                            onClick={() => {
                              this.props.history.push(
                                `/training/history/details/${row.id_training}`
                              );
                            }}
                            color="darkGray"
                          />
                          <TFBIconButton
                            name="rpe"
                            onClick={() => {
                              this.props.history.push(
                                `/training/rpe/${row.id_training}`
                              );
                            }}
                            color="darkGray"
                          />

                          <TFBIconButton
                            name="delete"
                            onClick={() =>
                              this.setState({
                                openTrainingToDelete: true,
                                trainingToDelete: row.id_training,
                              })
                            }
                            color="darkGray"
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TFBCardBody>

        <Dialog open={this.state.openTrainingToDelete} fullWidth maxWidth="sm">
          <DialogContent>
            <DeleteTraining
              closePopup={() =>
                this.setState({
                  openTrainingToDelete: false,
                  trainingToDelete: "",
                })
              }
              refreshTrainingsList={this.getTrainingSessions}
              idTraining={this.state.trainingToDelete}
              languagePack={this.props.languagePack}
            />
          </DialogContent>
        </Dialog>
      </TFBCard>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(TrainingHistory);
