import React from "react";
import axios from "axios";
import { Button, Icon, Checkbox } from "semantic-ui-react";
import "./Club.styles.scss";
import { countries } from "../../utils/countries";
import TextInput from "react-autocomplete-input";
import { store } from "react-notifications-component";
import Select from "react-select";
import TFBSelect from "../../components/design-system/TFBSelect/TFBSelect";
import TFBDropdown from "../../components/design-system/Dropdown/TFBDropdown";
import TFBDatePicker from "../../components/design-system/DatePicker/TFBDatePicker";
import TFBInput from "../../components/design-system/Input/TFBInput";
import TFBDialog from "../../components/design-system/Dialog/TFBDialog";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import TFBPhoneInput from "../../components/design-system/PhoneInput/TFBPhoneInput";

const positions_dropdown = [
  { key: "GK", value: "Goalkeeper", text: "Portar", label: "Portar" },
  { key: "DEF", value: "Defender", text: "Fundas", label: "Fundas" },
  { key: "MID", value: "Midfielder", text: "Mijlocas", label: "Mijlocas" },
  { key: "ATT", value: "Forward", text: "Atacant", label: "Atacant" },
];

const main_foot_dropdown = [
  { key: "RT", value: "Right", text: "Picior drept", label: "Picior drept" },
  { key: "LT", value: "Left", text: "Picior stang", label: "Picior stang" },
  { key: "BOTH", value: "Both", text: "Ambele", label: "Ambele" },
];

const frfListDropdownU21 = [
  { key: 1, value: "A", text: "Lista A", label: "Lista A" },
  { key: 2, value: "B", text: "Lista B", label: "Lista B" },
  { key: 3, value: "0", text: "Nicio Lista", label: "Nicio Lista" },
];

const frfListDropdown = [
  { key: 1, value: "A", text: "Lista A", label: "Lista A" },
  { key: 2, value: "0", text: "Nicio Lista", label: "Nicio Lista" },
];

const frfListNoDob = [
  { key: 1, value: "0", text: "Nicio Lista", label: "Nicio Lista" },
];

class AddPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countriesOption: countries,
      player_name: "",
      player_position: "",
      player_second_position: "",
      player_dob: null,
      birth_country: "ro",
      second_country: null,
      main_foot: "",

      player_shirt_number: "",
      player_frf_list: "",
      player_identity_card: "",
      player_fifa_id: "",
      player_has_professional_license: false,

      legitimation_date: null,
      enrolment_date: null,

      alreadyBeen: false,
      displayInput: true,
      matches: [""],
      isU21: true,
      isOnFrfList: false,

      keep_in_previous_teams: false,
      id_player: "",
      player_teams: [],
      selected_player_teams: [],
      isWrongNumber: false,
      playerPhoneNumber: "",
    };
  }

  isOnFrfList = () => {
    if (
      this.state.player_frf_list == "Nicio Lista" ||
      this.state.player_frf_list == "" ||
      this.state.player_frf_list == "0"
    ) {
      this.setState({ isOnFrfList: false });
    } else {
      this.setState({ isOnFrfList: true });
    }
  };

  validatePhone = (fieldName, value) => {
    let isWrongNumber = this.state.isWrongNumber;

    if (value) {
      switch (fieldName) {
        case "phoneNumber":
          if (value.indexOf(7) == 3) {
            isWrongNumber = !isValidPhoneNumber(value);
          } else {
            isWrongNumber = true;
          }
          break;
        default:
          break;
      }

      this.setState({ isWrongNumber: isWrongNumber });
    } else {
      this.setState({ isWrongNumber: false });
    }
  };

  componentDidMount = () => {
    this.getInputSuggestions();
    let countries2 = JSON.parse(JSON.stringify(countries));
    countries2.unshift({
      key: "noCountry",
      value: "noCountry",
      flag: "noCountry",
      text: "No country",
    });
    this.setState({
      countriesOption: countries2,
    });
  };

  isU21 = () => {
    if (this.state.player_dob != null) {
      const year = "2001";
      let birthdayDate = new Date(this.state.player_dob);
      let u21 = new Date("01-01-" + year);

      birthdayDate <= u21
        ? this.setState({ isU21: false })
        : this.setState({ isU21: true });
    }
    this.setState({ player_frf_list: "" });
  };

  getInputSuggestions = () => {
    //Get players list from the current club in case the player to be added has already been to the club
    axios
      .get(
        `club/player_suggestions_update?id_club=${this.props.id_club}&id_team=${this.props.id_team}&id_season=${this.props.selectedSeason}`
      )
      .then((res) => this.setState({ suggestions: res.data }))
      .catch((err) => console.log(err));
  };

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeDate = (value) => {
    this.setState({ player_dob: value }, () => {
      this.isU21();
    });
  };

  handleChangeDropdown = (selected, value) => {
    this.setState({ [value.name]: selected }, () => {
      this.isOnFrfList();
    });
  };

  handleChangeDropdownNationality = (e, value) => {
    this.setState({ [value.name]: value.value }, () => {
      this.isOnFrfList();
    });
  };

  getCountryName = (value) => {
    if (value && value != "noCountry") {
      const selected_country = countries.find((item) => item.value === value);
      return selected_country.text;
    } else return "";
  };

  addNewPlayer = () => {
    const playerData = {
      id_club: this.props.id_club,
      id_team: this.props.id_team,
      id_player: this.state.id_player,
      player_name: this.state.player_name.trim(),
      player_dob: this.state.player_dob,
      player_position: this.state.player_position?.value ?? null,
      player_position_alternative:
        this.state.player_second_position?.value ?? null,
      player_main_foot: this.state.main_foot?.value ?? null,
      birth_country: this.getCountryName(this.state.birth_country),
      second_country: this.getCountryName(this.state.second_country),
      shirt_number: this.state.player_shirt_number,
      frf_list:
        this.state.player_frf_list?.value == ""
          ? "0"
          : this.state.player_frf_list?.value,
      cnp: this.state.player_identity_card,
      fifa_id: this.state.player_fifa_id,
      has_professional_license: { true: 1, false: 0 }[
        this.state.player_has_professional_license
      ],
      already_been: this.state.alreadyBeen,
      keep_in_previous_teams: this.state.keep_in_previous_teams,
      previous_teams: this.state.selected_player_teams,
      legitimation_date: this.state.legitimation_date,
      enrolment_date: this.state.enrolment_date,
      phone_number:
        this.state.playerPhoneNumber == ""
          ? null
          : this.state.playerPhoneNumber,
    };
    axios.post("club/add_player_2", playerData).then((res) => {
      if (res.data.success_phone_number == 1) {
        store.addNotification({
          title: "Management club",
          message: "Jucatorul a fost adaugat cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        this.props.closePopup();
        this.props.getPlayers();
      } else if (
        res.data.success_phone_number == 0 &&
        res.data.error_code == "used_phone_number_by_another_user"
      ) {
        store.addNotification({
          title: "Management club",
          message: this.props.languagePack.used_phone_number_by_another_user,
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      } else if (
        res.data.success_phone_number == 0 &&
        res.data.error_code == "used_phone_number_by_another_player"
      ) {
        store.addNotification({
          title: "Management club",
          message: this.props.languagePack.used_phone_number_by_another_player,
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      } else {
        store.addNotification({
          title: "Management club",
          message: "Jucatorul nu a fost adaugat !",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      }
    });
  };

  handleSelectPlayer = (selection) => {
    const currentSeasonLabel = this.props.seasons.find(
      (item) => item.key == this.props.selectedSeason
    ).text;
    axios
      .get(
        `club/add_player_info?player_name=${selection}&season_name=${currentSeasonLabel}`
      )
      .then((res) => {
        this.setState({
          player_name: selection,
          player_position: res.data.player_position,
          birth_country: countries.find(
            (item) => item.text == res.data.birth_country
          ).value,
          second_country:
            res.data.second_country != null && res.data.second_country != ""
              ? this.state.countriesOption.find(
                  (item) => item.text == res.data.second_country
                ).value
              : null,
          id_player: res.data.id_player,
          player_teams: res.data.player_teams,
          selected_player_teams: res.data.player_teams,
          player_dob: res.data.player_dob,
          player_second_position: res.data.player_position_alternative,
          main_foot: res.data.player_main_foot,
          player_shirt_number: res.data.shirt_number,
          player_frf_list: res.data.frf_list,
          player_identity_card: res.data.cnp,
          player_fifa_id: res.data.fifa_id,
          legitimation_date: res.data.legitimation_date,
          enrolment_date: res.data.enrolment_date,
          player_has_professional_license: { "1": true, "0": false }[
            res.data.has_professional_license
          ],
          alreadyBeen: true,
          playerPhoneNumber: res.data.phone_number,
        });
      })
      .catch((err) => console.log(err));
  };

  listFilter = () => {
    if (this.state.isU21) {
      return frfListDropdownU21;
    } else {
      return frfListDropdown;
    }
  };

  onChangeInputValue = (value) => {
    this.setState({
      player_name: value,
    });
  };

  resetAddPlayerStates = () => {
    this.setState({
      player_name: "",
      player_position: "",
      player_second_position: "",
      player_dob: null,
      birth_country: "ro",
      second_country: null,
      main_foot: "",
      player_shirt_number: "",
      player_frf_list: "",
      player_identity_card: "",
      player_fifa_id: "",
      player_has_professional_license: false,
      alreadyBeen: false,
      isU21: true,
      isOnFrfList: false,
      keep_in_previous_teams: false,
      id_player: "",
      player_teams: [],
      selected_player_teams: null,
      legitimation_date: null,
      enrolment_date: null,
    });
  };

  render() {
    const { languagePack, openDialog } = this.props;

    return (
      <div className="add-player-form">
        <TFBDialog
          open={openDialog}
          closePopup={() => {
            this.props.closePopup();
            this.resetAddPlayerStates();
          }}
          title={languagePack["add_player"]}
          fullWidth={true}
          className="pop-up-dialog-box"
        >
          <div className="add-player-form-data">
            <div className="add-player-row-ds">
              {this.state.displayInput && (
                <div className="react-text-autocomplete-input">
                  <h6 className="player-name">{"Nume jucător"}</h6>
                  <TextInput
                    options={this.state.suggestions}
                    style={{ height: "50px", width: "100%" }}
                    onChange={(value) => {
                      this.setState({
                        player_name: value,
                      });
                      if (value == "") {
                        this.resetAddPlayerStates();
                      }
                    }}
                    trigger=""
                    Component="input"
                    onSelect={this.handleSelectPlayer}
                    placeholder={languagePack["PLAYER_NAME"]}
                    regex="[a-zA-Z0-9_\-]+"
                    matchAny
                  />
                </div>
              )}
            </div>
            <div className="add-player-row-ds">
              <TFBSelect
                placeholder={languagePack["POSITION"]}
                label={languagePack["POSITION"]}
                value={this.state.player_position}
                name="player_position"
                options={positions_dropdown}
                onChange={(selected, value) => {
                  this.handleChangeDropdown(selected, value);
                }}
              />

              <TFBSelect
                placeholder={languagePack["SECONDARY_POSITION"]}
                label={languagePack["SECONDARY_POSITION"]}
                value={this.state.player_second_position}
                name="player_second_position"
                options={positions_dropdown}
                onChange={(selected, value) => {
                  this.handleChangeDropdown(selected, value);
                }}
              />
            </div>
            <div className="add-player-row-ds">
              <div className="main-foot-select">
                <TFBSelect
                  placeholder={languagePack["MAIN_FOOT"]}
                  label={languagePack["MAIN_FOOT"]}
                  value={this.state.main_foot}
                  name="main_foot"
                  options={main_foot_dropdown}
                  onChange={(selected, value) => {
                    this.handleChangeDropdown(selected, value);
                  }}
                />
              </div>

              <div className="nationality-dropdown">
                <TFBDropdown
                  color="lightGray"
                  placeholder={languagePack["NATIONALITY"]}
                  label={languagePack["NATIONALITY"]}
                  value={this.state.birth_country}
                  options={countries}
                  selection
                  name="birth_country"
                  className="birth-country"
                  search
                  onChange={this.handleChangeDropdownNationality}
                />
              </div>
            </div>
            <div className="add-player-row-ds">
              <div className="nationality-dropdown">
                <TFBDropdown
                  placeholder={languagePack["NATIONALITY"]}
                  label={languagePack["NATIONALITY"]}
                  value={this.state.second_country}
                  options={this.state.countriesOption}
                  selection
                  color="lightGray"
                  name="second_country"
                  search
                  onChange={this.handleChangeDropdownNationality}
                />
              </div>

              <div className="birth-date-datepicker">
                <TFBDatePicker
                  value={this.state.player_dob}
                  onChange={this.handleChangeDate}
                  placeholder={languagePack["birth_date"]}
                  label={languagePack["birth_date"]}
                  format="dd.MM.yyyy"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="add-player-row-ds">
              <TFBInput
                placeholder={languagePack.shirt_number}
                label={languagePack.shirt_number}
                value={this.state.player_shirt_number}
                onChange={(e) => {
                  this.setState({
                    player_shirt_number: e.target.value,
                  });
                }}
                fullWidth
                type="number"
              />

              <TFBSelect
                placeholder={"Lista FRF"}
                label={"Lista FRF"}
                value={this.state.player_frf_list}
                name="player_frf_list"
                options={
                  this.state.player_dob == null
                    ? frfListNoDob
                    : this.listFilter()
                }
                onChange={(selected, value) => {
                  this.handleChangeDropdown(selected, value);
                }}
              />
            </div>

            <div className="add-player-row-ds">
              <TFBInput
                placeholder={languagePack.identity_card_id}
                label={languagePack.identity_card_id}
                value={this.state.player_identity_card}
                fullWidth
                onChange={(e) => {
                  this.setState({
                    player_identity_card: e.target.value,
                  });
                }}
              />

              <TFBInput
                placeholder={"Fifa ID"}
                label={"Fifa ID"}
                fullWidth
                value={this.state.player_fifa_id}
                onChange={(e) => {
                  this.setState({
                    player_fifa_id: e.target.value,
                  });
                }}
              />
            </div>

            <div className="add-player-row-ds">
              <TFBDatePicker
                value={this.state.legitimation_date}
                onChange={(value) =>
                  this.setState({ legitimation_date: value })
                }
                placeholder={languagePack.legitimation_date}
                label={languagePack.legitimation_date}
                format="dd.MM.yyyy"
                style={{ width: "100%" }}
              />

              <TFBDatePicker
                value={this.state.enrolment_date}
                onChange={(value) => this.setState({ enrolment_date: value })}
                placeholder={languagePack.enrolment_date}
                label={languagePack.enrolment_date}
                format="dd.MM.yyyy"
                style={{ width: "100%" }}
              />
            </div>

            <div className="add-player-row-ds">
              <TFBPhoneInput
                initialValueFormat="national"
                defaultCountry="RO"
                countries={["RO"]}
                label={languagePack.tax_redirector_phone_number}
                placeholder={"Ex: 0712 345 678"}
                value={this.state.playerPhoneNumber}
                className="add-player-phone-input"
                onChange={(e) => {
                  this.setState({ playerPhoneNumber: e ?? "" });
                  this.validatePhone("phoneNumber", e);
                }}
              />
            </div>

            {this.state.isWrongNumber &&
              this.state.playerPhoneNumber &&
              this.state.playerPhoneNumber.length > 0 && (
                <div className="text-danger">
                  {languagePack.incorect_phone_number}
                </div>
              )}

            <div className="add-player-professional-license">
              <Checkbox
                label={languagePack.has_professional_license_issued}
                checked={this.state.player_has_professional_license}
                onChange={(e, data) => {
                  this.setState({
                    player_has_professional_license: data.checked,
                  });
                }}
              />
            </div>

            {this.state.isOnFrfList == true &&
              this.state.player_shirt_number == "" && (
                <div className="add-player-shirt-number-warning text-danger">
                  {languagePack.insert_shirt_number}
                </div>
              )}
            {this.state.alreadyBeen && this.state.player_teams.length > 0 && (
              <>
                <div className="add-player-professional-license">
                  <Checkbox
                    label={"Păstrează-l și în echipele anterioare"}
                    checked={this.state.keep_in_previous_teams}
                    onChange={(e, data) => {
                      this.setState({
                        keep_in_previous_teams: data.checked,
                        selected_player_teams: this.state.player_teams,
                      });
                    }}
                  />
                </div>
                {this.state.keep_in_previous_teams && (
                  <div className="">
                    <Select
                      value={this.state.selected_player_teams}
                      options={this.state.player_teams}
                      onChange={(selected) =>
                        this.setState({
                          selected_player_teams: selected,
                          keep_in_previous_teams:
                            selected == null ? false : true,
                        })
                      }
                      isMulti
                      isClearable
                      isSearchable
                    />
                  </div>
                )}
              </>
            )}
          </div>

          <div className="add-player-buttons" style={{ marginTop: "50px" }}>
            <Button
              className="custom-button-dual add-button"
              onClick={this.addNewPlayer}
              disabled={
                this.state.isWrongNumber ||
                this.state.player_name == "" ||
                this.state.birth_country == "" ||
                (this.state.isOnFrfList == true &&
                  this.state.player_shirt_number == "")
              }
            >
              {" "}
              <div className="button-content">
                {languagePack["admin_add"]}
                <Icon name="add" size="small" className="add-icon" />
              </div>
            </Button>
          </div>
        </TFBDialog>
      </div>
    );
  }
}

export default AddPlayer;
