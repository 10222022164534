import React from "react";
import "./TeamsSettings.scss";
import { Button, Icon, Input, Checkbox } from "semantic-ui-react";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import { store } from "react-notifications-component";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";

class TeamsSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: [],
      teams_loaded: false,

      image_view_dialog: false,
      current_section_name: "",
      image_to_delete: "",
      image_to_delete_popup: false,
      image_to_view: "",
      team_to_update: "",
    };

    this.inputOpenFileRef = React.createRef();
  }

  componentDidMount = () => {
    this.getTeams();
  };

  getTeams = () => {
    axios
      .get(
        `club/get_teams?id_club=${this.props.currentUser.id_club}&token=${this.props.token}`
      )
      .then((res) => {
        this.setState({
          teams: res.data,
          teams_loaded: true,
        });
      })
      .catch((err) => console.log(err));
  };

  handleAddFile = (e, team) => {
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      let currentTeamsState = this.state.teams;
      let currentTeamIndex = currentTeamsState.findIndex(
        (elem) => elem.id_team == team.id_team
      );
      currentTeamsState[currentTeamIndex].added_new_img_src = reader.result;
      currentTeamsState[currentTeamIndex].error_img_src = false;
      this.setState({ teams: currentTeamsState, team_to_update: "" });
      this.inputOpenFileRef.current.value = "";
    };
  };

  handleClickAddFile = (team) => {
    this.setState({ team_to_update: team });
    this.inputOpenFileRef.current.click();
  };

  handleChangeOrder = (value, index) => {
    let currentTeam = this.state.teams;
    currentTeam[index].order_in_site = value;

    this.setState({ teams: currentTeam });
  };

  handleTaxAmount = (value, index) => {
    let currentTeam = this.state.teams;
    currentTeam[index].tax_amount = value;

    this.setState({ teams: currentTeam });
  };

  handleShowOnSite = (index, data) => {
    let currentTeam = this.state.teams;
    currentTeam[index].show_on_site = { false: "0", true: "1" }[data.checked];

    this.setState({ teams: currentTeam });
  };

  handleShowOnlinePayments = (index, data) => {
    let currentTeam = this.state.teams;
    currentTeam[index].show_in_online_payments = { false: "0", true: "1" }[
      data.checked
    ];

    this.setState({ teams: currentTeam });
  };

  deleteLocalTeamImage = () => {
    let currentTeamsState = this.state.teams;
    let currentTeamIndex = currentTeamsState.findIndex(
      (elem) => elem.id_team == this.state.image_to_delete.id_team
    );
    currentTeamsState[currentTeamIndex].error_img_src = true;
    delete currentTeamsState[currentTeamIndex].added_new_img_src;
    this.setState({
      teams: currentTeamsState,
      image_to_delete: "",
      image_to_delete_popup: false,
    });
  };

  handleSaveSettings = () => {
    const payload = [];

    this.state.teams.forEach((team) => {
      let dataForm = {
        id_team: team.id_team,
        show_on_site: team.show_on_site,
        tax_amount: team.tax_amount,
        show_in_online_payments: team.show_in_online_payments,
        error_img_src: { false: 0, true: 1 }[team.error_img_src],
      };
      if (
        team.order_in_site == null ||
        team.order_in_site == undefined ||
        team.order_in_site == ""
      ) {
        dataForm.order_in_site = "0";
      } else {
        dataForm.order_in_site = team.order_in_site;
      }
      if (team?.added_new_img_src) {
        dataForm.added_new_img_src = team.added_new_img_src;
      }
      payload.push(dataForm);
    });

    axios
      .post("site/save_team_settings_on_site", payload)
      .then(() => {
        this.getTeams();
        store.addNotification({
          title: "Administrare stiri",
          message: "Setarile salvate cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="card card-custom">
        {" "}
        <div className="news-page-container">
          <div className="news-page-header">
            <div className="news-page-title">
              {languagePack.team_management}
            </div>
          </div>
          {!this.state.teams_loaded ? (
            <div className="loader-container">
              <Loader
                type="TailSpin"
                color={window.clubCustomization.primary_color}
                height={40}
                width={40}
              />
            </div>
          ) : (
            <div className="last-news-list">
              <div className="last-news-row-header desktop-header">
                <div className="team-setting-title">
                  {languagePack.campaign_name}
                </div>
                <div className="team-setting-date">
                  {languagePack.number_order}
                </div>
                <div className="team-setting-date">
                  {languagePack.tax_value}
                </div>
                <div className="team-setting-date">
                  {languagePack.add_view_image}
                </div>
                <div className="team-settings-option">
                  {languagePack.display_on_website}
                </div>
                <div className="team-settings-option">
                  {languagePack.display_for_online_payments}
                </div>
              </div>
              {this.state.teams.map((item, index) => {
                return (
                  <div
                    className="last-news-row"
                    key={index}
                    style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "" }}
                  >
                    <div className="header-row">
                      <div className="mobile-header">
                        <div className="team-setting-title">
                          {languagePack["news_page_header_title"]}
                        </div>
                        <div className="team-setting-date">
                          {languagePack["news_page_header_date"]}
                        </div>
                        <div className="team-settings-option">
                          {languagePack["news_page_header_options"]}
                        </div>
                      </div>
                    </div>
                    <div className="content-row">
                      <div className="team-setting-title">{item.team_name}</div>
                      <div className="team-setting-date">
                        <Input
                          value={item.order_in_site ?? "0"}
                          type="number"
                          placeholder="Număr"
                          className="number"
                          style={{ width: "40%", marginBottom: 20 }}
                          onChange={(event) =>
                            this.handleChangeOrder(event.target.value, index)
                          }
                        />
                      </div>
                      <div className="team-setting-date">
                        <Input
                          value={item.tax_amount ?? "0"}
                          type="number"
                          placeholder="Valoare taxă"
                          className="number"
                          style={{ width: "40%", marginBottom: 20 }}
                          onChange={(event) =>
                            this.handleTaxAmount(event.target.value, index)
                          }
                        />
                      </div>
                      {item.error_img_src ? (
                        <div
                          className="team-setting-date"
                          onClick={() => this.handleClickAddFile(item)}
                        >
                          <Icon
                            name="plus"
                            className="edit-icon"
                            size="large"
                            style={{
                              height: "100%",
                              color: "black",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      ) : (
                        <div className="team-settings-image">
                          <div className="team-image-wrapper">
                            <div
                              className="team-image-schedule"
                              style={{
                                backgroundSize: "cover",
                                backgroundImage: `url(${
                                  item?.added_new_img_src
                                    ? item.added_new_img_src
                                    : `https://api.thefootballbrain.app/uploads/teams/${item.id_team}.jpg`
                                })`,
                                height: "50px",
                                width: "73px",
                              }}
                              onClick={() =>
                                this.setState({
                                  image_view_dialog: true,
                                  current_section_name: item.team_name,
                                  image_to_view: item,
                                })
                              }
                            ></div>
                            <Icon
                              name="close"
                              style={{ height: "100%", color: "#666666" }}
                              onClick={() =>
                                this.setState({
                                  image_to_delete: item,
                                  image_to_delete_popup: true,
                                })
                              }
                            />
                          </div>
                        </div>
                      )}
                      <div className="team-settings-option">
                        <Checkbox
                          checked={item.show_on_site == 0 ? false : true}
                          onChange={(event, data) =>
                            this.handleShowOnSite(index, data)
                          }
                        />
                      </div>
                      <div className="team-settings-option">
                        <Checkbox
                          checked={
                            item.show_in_online_payments == 0 ? false : true
                          }
                          onChange={(event, data) =>
                            this.handleShowOnlinePayments(index, data)
                          }
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="final-settings-submit-button-row">
                <Button
                  className="final-submit-button"
                  onClick={this.handleSaveSettings}
                >
                  {languagePack.save_settings}
                  <Icon name="add" className="final-submit-button-icon" />{" "}
                </Button>
              </div>
            </div>
          )}
          <input
            ref={this.inputOpenFileRef}
            onChange={(e) => this.handleAddFile(e, this.state.team_to_update)}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
          />
          <Dialog open={this.state.image_view_dialog} maxWidth="lg">
            <div className="view-team-image-dialog">
              <div className="view-team-image-dialog-title-wrapper">
                <div className="view-team-image-dialog-title">
                  {this.state.current_section_name}
                </div>
                <div className="close-video">
                  <Icon
                    name="close"
                    onClick={() =>
                      this.setState({
                        image_view_dialog: false,
                        image_to_view: "",
                      })
                    }
                    size="large"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <img
                src={
                  this.state.image_to_view?.added_new_img_src
                    ? this.state.image_to_view.added_new_img_src
                    : `https://api.thefootballbrain.app/uploads/teams/${this.state.image_to_view.id_team}.jpg`
                }
                alt="training_image"
                style={{ margin: "10px" }}
              />
            </div>
          </Dialog>
          <Dialog open={this.state.image_to_delete_popup} maxWidth="lg">
            <div className="delete-team-setting-image-popup-container">
              <div className="delete-team-setting-image-header">
                <div className="delete-team-setting-image-title">
                  {"Șterge imagine echipă"}
                </div>
                <Icon
                  name="close"
                  className="close"
                  size="large"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.setState({
                      image_to_delete_popup: false,
                      image_to_delete: "",
                    })
                  }
                />
              </div>
              <div className="delete-team-setting-text">
                {"Ești sigur că vrei să ștergi imaginea pentru echipa "}
                {this.state.image_to_delete.team_name}?
              </div>
              <div className="delete-team-setting-buttons">
                <Button
                  className="cancel-button"
                  onClick={() =>
                    this.setState({
                      image_to_delete_popup: false,
                      image_to_delete: "",
                    })
                  }
                >
                  {languagePack["admin_cancel"]}
                  <Icon name="close" className="dark-icon" />
                </Button>
                <Button
                  className="delete-team-setting-button"
                  onClick={this.deleteLocalTeamImage}
                >
                  {languagePack["admin_delete"]}
                  <Icon name="trash alternate outline" className="grey-icon" />
                </Button>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(TeamsSettings);
