import React from "react";
import { bindActionCreators } from "redux";
import axios from "axios";
import moment from "moment";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import { Button, Input, Icon } from "semantic-ui-react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { logout } from "./../../utils/api";
import Select from "react-select";

class AddContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teams_dropdown: [],
      players_dropdown: [],
      player_team: null,
      player_id: null,
      addNewPlayerInput: false,
      addNewPlayerName: "",
      contract_signing_date: null,
      contract_ending_date: null,

      player_contract: "",
      player_monthly_wage: "",
      currency: props.currentUser.currency,
      checkedBonuses: {},
      rates: [],
    };
    this.onSubmitPlayerContract = this.onSubmitPlayerContract.bind(this);
  }

  componentDidMount = () => {
    this.get_teams();
    this.getCurrency();
  };

  get_teams = () => {
    axios
      .get(`upload/get_teams_dropdown_searchable`, {
        params: {
          id_club: this.props.currentUser.id_club,
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState({ teams_dropdown: res.data });
        }
      })
      .catch((err) => console.log(err));
  };

  get_players = () => {
    axios
      .get(`upload/get_players_dropdown_searchable`, {
        params: {
          id_team: this.state.player_team.value,
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState({ players_dropdown: res.data });
        }
      })
      .catch((err) => console.log(err));
  };

  getCurrency = () => {
    fetch("https://api.thefootballbrain.app/club/get_currency_rates")
      .then((res) => res.json())
      .then((data) => this.setState({ rates: data }));
  };

  handleDateChangeStart = (value) => {
    this.setState({ contract_signing_date: value });
  };

  handleDateChangeEnd = (value) => {
    this.setState({ contract_ending_date: value });
  };

  onChangePlayerContract = (e) => {
    this.setState({
      player_contract: e.target.files[0],
    });
  };

  async onSubmitPlayerContract(e) {
    e.preventDefault();
    let response = await this.uploadPlayerContract(this.state.player_contract);
    this.setState(
      {
        contractDialogOpened: false,
        player_contract: "",
        player_name: "",
        player_team: "",
        activeStep: 0,
        player_monthly_wage: "",
      },
      () => {
        this.props.refreshFiles();
        this.props.closePopup();
      }
    );
    store.addNotification({
      title: "Document operation",
      message: "Ai adaugat un nou contract pentru jucator!",
      type: "success",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 2000,
      },
    });
  }

  async uploadPlayerContract(file) {
    const playerContract = new FormData();
    playerContract.append("avatar", file);
    playerContract.append("category", "Contracte jucatori");
    playerContract.append("fileName", this.state.player_contract.name);
    playerContract.append("id_club", this.props.currentUser.id_club);
    playerContract.append("uploaded_by", this.props.currentUser.nickname);
    playerContract.append("monthly_wage", this.state.player_monthly_wage);
    playerContract.append("currency", this.state.currency);
    playerContract.append("added_player_name", this.state.addNewPlayerName);
    playerContract.append(
      "contract_start",
      moment(this.state.contract_signing_date).format("YYYY-MM-DD")
    );
    playerContract.append(
      "contract_end",
      moment(this.state.contract_ending_date).format("YYYY-MM-DD")
    );
    if (this.state.player_id != null) {
      playerContract.append("id_player", this.state.player_id.value);
    } else {
      playerContract.append("id_player", "");
    }
    playerContract.append("id_team", this.state.player_team.value);

    return await axios
      .post("upload/upload_contract", playerContract, {
        params: {
          token: this.props.token,
        },
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(async (res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        }
        return res;
      })
      .catch(async (err) => {
        return { data: {} };
      });
  }

  render() {
    const { languagePack } = this.props;

    return (
      <div className="add-contract-container">
        <div className="add-contract-row">
          <Select
            placeholder={languagePack.select_team}
            value={this.state.player_team}
            options={this.state.teams_dropdown}
            onChange={(selected) => {
              this.setState(
                {
                  player_team: selected,
                },
                () => {
                  this.get_players();
                }
              );
            }}
            styles={{
              container: (provided, state) => ({
                ...provided,
                backgroundColor: "#fafafa",
                width: "100%",
              }),
              control: (provided, state) => ({
                ...provided,
                backgroundColor: "#fafafa",
                height: "50px",
                border: "none",
                "&:hover": {
                  border: "none",
                  boxShadow: "0 0 0 2px black",
                  // border: "2px black"
                },
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                paddingLeft: "22px",
                fontSize: "14px",
                fontWeight: 700,
              }),
              placeholder: (provided, state) => ({
                ...provided,
                color: "#666666",
              }),
              indicatorSeparator: (provided, state) => ({
                ...provided,
                display: "none",
              }),
              menu: (provided, state) => ({
                ...provided,
                maxHeight: "350px",
              }),
              menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
            }}
            menuPortalTarget={document.body}
          />
        </div>

        <div className="add-contract-row">
          <Select
            placeholder={languagePack.select_player}
            value={this.state.player_id}
            options={this.state.players_dropdown}
            onChange={(selected) => {
              this.setState({
                player_id: selected,
              });
            }}
            styles={{
              container: (provided, state) => ({
                ...provided,
                backgroundColor: "#fafafa",
                width: "100%",
              }),
              control: (provided, state) => ({
                ...provided,
                backgroundColor: "#fafafa",
                height: "50px",
                border: "none",
                "&:hover": {
                  border: "none",
                  boxShadow: "0 0 0 2px black",
                  // border: "2px black"
                },
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                paddingLeft: "22px",
                fontSize: "14px",
                fontWeight: 700,
              }),
              placeholder: (provided, state) => ({
                ...provided,
                color: "#666666",
              }),
              indicatorSeparator: (provided, state) => ({
                ...provided,
                display: "none",
              }),
              menu: (provided, state) => ({
                ...provided,
                maxHeight: "350px",
              }),
              menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
            }}
            menuPortalTarget={document.body}
          />
        </div>

        {this.state.addNewPlayerInput && (
          <div className="add-contract-row">
            <input
              type="text"
              value={this.state.addNewPlayerName}
              onChange={(e) =>
                this.setState({ addNewPlayerName: e.target.value })
              }
              placeholder="Adauga nume jucator nou"
              className="add-contract-form-input"
            />
            <div className="add-file-form-button dark-grey-button">
              <Icon
                name="checkmark"
                className="cusor-pointer white-button-icon"
                size="small"
              />
            </div>
            <div
              className="add-file-form-button light-grey-button"
              onClick={() =>
                this.setState({
                  addNewPlayerInput: false,
                  player_id: "",
                })
              }
            >
              <Icon
                name="ban"
                className="cusor-pointer grey-button-icon"
                size="small"
              />
            </div>
          </div>
        )}
        <div className="add-contract-row">
          <input
            type="text"
            value={this.state.player_monthly_wage}
            onChange={(e) => {
              const new_value = e.target.value.replace(/[^0-9]*/g, "");
              this.setState({ player_monthly_wage: new_value });
            }}
            placeholder={languagePack["monthly_wage"]}
            className="add-contract-form-input"
          />
          <div
            className="currency-container"
            style={{ marginRight: 50, marginLeft: 20 }}
            onClick={() => this.setState({ currency: "RON" })}
          >
            <div
              className={
                this.state.currency === "RON"
                  ? "currency-checkbox currency-checkbox-selected"
                  : "currency-checkbox"
              }
            />
            <div className="currency-name">RON</div>
          </div>
          <div
            className="currency-container"
            onClick={() => this.setState({ currency: "EUR" })}
          >
            <div
              className={
                this.state.currency === "EUR"
                  ? "currency-checkbox currency-checkbox-selected"
                  : "currency-checkbox"
              }
            />
            <div className="currency-name">EUR</div>
          </div>
        </div>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="add-contract-row">
            <DatePicker
              value={this.state.contract_signing_date}
              onChange={this.handleDateChangeStart}
              format="dd MMMM yyyy"
              name="contract_signing_date"
              inputVariant="outlined"
              className="contract-date-input"
              emptyLabel={languagePack["signing_date"]}
            />
            <Icon
              name="calendar alternate outline"
              className="calendar-icon"
              size="small"
            />
          </div>
          <div className="add-contract-row">
            <DatePicker
              value={this.state.contract_ending_date}
              onChange={this.handleDateChangeEnd}
              emptyLabel={languagePack["ending_date"]}
              format="dd MMMM yyyy"
              name="contract_ending_date"
              inputVariant="outlined"
              className="contract-date-input"
            />
            <Icon
              name="calendar alternate outline"
              className="calendar-icon"
              size="small"
            />
          </div>
        </MuiPickersUtilsProvider>

        <div
          className="add-contract-row"
          style={{ justifyContent: "space-between", paddingRight: 20 }}
        >
          <input
            type="file"
            className="add-contract-file-input"
            id="player_contract"
            onChange={this.onChangePlayerContract}
          />
          <label className="add-contract-file-label" htmlFor="player_contract">
            {this.state.player_contract
              ? this.state.player_contract.name
              : languagePack["upload_file"]}
          </label>
          <Icon
            name="arrow up"
            className="cusor-pointer grey-button-icon"
            size="small"
          />
        </div>

        <div
          className="add-file-buttons-container"
          style={{ marginBottom: 25 }}
        >
          <Button
            className="add-file-button cancel-button"
            onClick={() => this.props.closePopup()}
          >
            {languagePack["admin_cancel"]}
            <Icon name="ban" size="small" className="add-file-button-icon" />
          </Button>
          <Button
            className="add-file-button"
            onClick={this.onSubmitPlayerContract}
          >
            {languagePack["admin_add"]}
            <Icon name="add" size="small" className="add-file-button-icon" />
          </Button>
        </div>
        <div className="add-contract-row-extra-text">
          {languagePack["add_contract_extra_bonuses_text"]}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(AddContract);
