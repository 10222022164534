import React from "react";
import Moment from "react-moment";
import { store } from "react-notifications-component";
import "./VideoGallery.styles.scss";
import { Icon, Button, Search } from "semantic-ui-react";
import axios from "axios";
import { logout } from "./../../utils/api";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { bindActionCreators } from "redux";
import AddVideo from "./AddVideo";
import VideoPlayer from "./VideoPlayerExtra";
import Pusher from "pusher-js";
import TFBCard from "../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../components/design-system/Card/TFBCardHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../components/reusable/useStyles";
import TFBIconButton from "../../components/design-system/IconButton/TFBIconButton";

class VideoGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      sortedFiles: [],
      addedFile: "",
      selectedFile: "",
      fileViewerOpened: true,
      toBeDeleted: "",
      toBeDeletedId: "",
      toBeDownloadedURL: "",
      displayOptions: "",
      searchbar: "",

      fileDialogOpened: false,
      videoPlayDialog: false,
      videoFile: "",
      videoName: "",
      selectedVideo: "",
      is_child_video: false,

      players: [],
    };
  }

  componentDidMount() {
    const pusher = new Pusher("ded84999dbba94030977", {
      cluster: "eu",
    });

    const channel = pusher.subscribe("videos-channel");
    const this2 = this;
    channel.bind(
      "refresh-videos-club-" + this.props.currentUser.id_user,
      function(data) {
        this2.refreshFiles();
      }
    );

    this.getPlayers();
    this.refreshFiles();
  }

  refreshFiles = () => {
    axios
      .get(`upload/get_videos`, {
        params: {
          id_club: this.props.currentUser.id_club,
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState({
            files: res.data,
            sortedFiles: res.data,
            fileDialogOpened: false,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getPlayers = () => {
    axios
      .get(`club/get_club_players`, {
        params: {
          id_club: this.props.currentUser.id_club,
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.success == 0) {
        } else {
          this.setState({ players: res.data });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.presetDisplayOption !== this.props.presetDisplayOption) {
      this.setState(
        {
          displayOptions: this.props.presetDisplayOption || "",
        },
        () => {
          this.refreshFiles();
        }
      );
    }
  };

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  async setLoader(value) {
    return new Promise((resolve) => {
      this.setState(
        {
          fileUploading: value,
        },
        () => {
          resolve(value);
        }
      );
    });
  }

  getSVGName = (value) => {
    if (
      value === "m4v" ||
      value === "avi" ||
      value === "mpg" ||
      value === "mp4"
    )
      return "video.svg";
  };

  deleteFile = () => {
    const deletedFile = {
      id: this.state.toBeDeleted,
    };
    axios
      .post("upload/delete_video", deletedFile, {
        params: {
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.refreshFiles();
          store.addNotification({
            title: this.props.languagePack["video_operation"],
            message: this.props.languagePack["video_delete_success"],
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getIndex(value, arr, prop) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return -1;
  }

  playVideo = (item) => {
    this.setState({
      videoFile: item.video_url,
      videoName: item.video_name,
      videoPlayDialog: true,
    });
  };

  handleSearchChange = (e, { value }) => {
    if (value != "") {
      let new_videos_list = [];
      this.state.files
        .filter(function(item) {
          return item.video_name.toLowerCase().includes(value.toLowerCase());
        })
        .map(function(item) {
          new_videos_list.push(item);
          return item;
        });
      this.setState({
        sortedFiles: Array.isArray(new_videos_list) ? new_videos_list : [],
      });
    } else {
      this.setState({
        sortedFiles: this.state.files,
      });
    }
  };

  handleSearchbarChange = (e) => {
    let currentList = this.state.files;
    let newList = [];

    if (e.target.value !== "") {
      let sortedFiles = this.state.files;
      newList = sortedFiles.filter((item) => {
        const name_lc = item.document_name.toLowerCase();
        const filter = e.target.value.toLowerCase();

        return name_lc.includes(filter);
      });
      this.setState({ sortedFiles: newList });
    } else {
      this.setState({ sortedFiles: currentList });
    }
  };

  render() {
    const { languagePack } = this.props;

    return (
      <TFBCard>
        <div className="galery-video-page-container">
          {/* =========== VIDEO-GALERY HEADER ================= */}
          {/* ================================================= */}
          <TFBCardHeader title={languagePack.gallery_videos_title} />
          <TFBCardBody>
            <div className="documents-page-header">
              <div className="searchbar">
                <Search
                  style={{ alignSelf: "center", fontFamily: "Poppins" }}
                  fluid
                  placeholder={languagePack.search_video + "..."}
                  input={{ icon: "search", iconPosition: "left" }}
                  loading={false}
                  onSearchChange={this.handleSearchChange}
                  defaultValue={""}
                  className="ui active search visible focus search-bar-patch"
                  minCharacters={2}
                />
              </div>
              <div className="buttons">
                <Button
                  className="documents-header-button"
                  onClick={() =>
                    this.setState({
                      fileDialogOpened: true,
                      fileUploading: false,
                    })
                  }
                >
                  {languagePack.add_video}
                  <Icon name="add" size="small" className="documents-icon" />
                </Button>
              </div>
            </div>

            {/* =========== VIDEOS LIST ========================= */}
            {/* ================================================= */}
            <div className="documents-page-list">
              {this.state.sortedFiles.length > 0 ? (
                <>
                  {/* ===============  TABLE  ============== */}
                  {/* ========================================================== */}
                  <div>
                    <TableContainer>
                      <Table aria-label="simple table">
                        {/* ===============  TABLE HEADER ============== */}
                        {/* ========================================================== */}

                        <TableHead>
                          <TableRow>
                            <TFBTableCell style={{ maxWidth: "800px" }}>
                              {languagePack.video_title}
                            </TFBTableCell>
                            <TFBTableCell align="center">
                              {languagePack.date_add}
                            </TFBTableCell>
                            <TFBTableCell align="center">
                              {languagePack.added_by}
                            </TFBTableCell>
                            <TFBTableCell align="center">
                              {languagePack.product_actions}
                            </TFBTableCell>
                          </TableRow>
                        </TableHead>

                        {/* ===============  TABLE CONTENT ============== */}
                        {/* ========================================================== */}
                        <TableBody>
                          {this.state.sortedFiles.map((row, index) => {
                            const raw = row.video_url.split("/");
                            // const file_name = raw[raw.length - 1];
                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  backgroundColor:
                                    index % 2 == 0 ? "#fafafa" : "white",
                                }}
                              >
                                <TFBTableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    minWidth: "200px",
                                    width: "50%",
                                    backgroundColor:
                                      index % 2 == 0 ? "#fafafa" : "white",
                                  }}
                                >
                                  <div
                                    className="document-name"
                                    style={{
                                      width: "max-content",
                                      cursor: "pointer",
                                    }}
                                    type={this.getSVGName(
                                      row.video_name.split(".").pop()
                                    )}
                                    onClick={() => {
                                      if (row.status == "1") {
                                        this.setState({
                                          selectedVideo: row.id_video,
                                          is_child_video:
                                            row.id_video_parent == null,
                                        });
                                        this.playVideo(row);
                                        if (row.id_video_parent == null) {
                                          this.props.history.push(
                                            `/video-gallery/${row.id_video}`
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    <img
                                      className="file-type-svg"
                                      src={`assets/SVG/video.svg`}
                                    />
                                    {row.video_name}
                                  </div>
                                </TFBTableCell>
                                <TFBTableCell
                                  align="center"
                                  style={{ width: "30%" }}
                                >
                                  <div className="document-date-add">
                                    {" "}
                                    <Moment format="DD.MM.YYYY, HH:mm">
                                      {row.date_add}
                                    </Moment>
                                  </div>
                                </TFBTableCell>
                                <TFBTableCell
                                  align="center"
                                  style={{ minWidth: "127px", width: "20%" }}
                                >
                                  {row.uploaded_by}
                                </TFBTableCell>
                                <TFBTableCell>
                                  <div className="actions-btns-container">
                                    <TFBIconButton
                                      name="view"
                                      color="darkGray"
                                      onClick={() =>
                                        this.props.history.push(
                                          `/video-gallery/${row.id_video}`
                                        )
                                      }
                                    />
                                    {row.status == "1" ? (
                                      <a
                                        style={{ color: "black" }}
                                        href={`https://api.thefootballbrain.app/upload/download_video_from_digital_ocean?id_club=${row.id_club}&id_video=${row.id_video}`}
                                      >
                                        <TFBIconButton
                                          name="arrow-down"
                                          color="darkGray"
                                        />
                                      </a>
                                    ) : (
                                      <div className="loader-video"></div>
                                    )}

                                    <TFBIconButton
                                      name="delete"
                                      color="darkGray"
                                      onClick={() =>
                                        this.setState(
                                          {
                                            toBeDeleted: row.id_video,
                                          },
                                          this.deleteFile
                                        )
                                      }
                                    />
                                  </div>
                                </TFBTableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </>
              ) : (
                <div className="placeholder-files">
                  <Icon
                    name="search minus"
                    size="huge"
                    className="placeholder-icon"
                  />
                  <div className="placeholder-text">
                    {languagePack["There_are_no_records_to_display"]}
                  </div>
                </div>
              )}
            </div>
          </TFBCardBody>

          {/* ============= DIALOGS POPUP============== */}
          {/* ======================================== */}

          {/* ADAUGARE FISIER */}
          <Dialog open={this.state.fileDialogOpened}>
            <div className="bonus-options-list-popup-header">
              <div className="bonus-options-list-title">
                {languagePack.add_video}
              </div>
              <div className="bonus-options-list-close-icon">
                {" "}
                <Icon
                  name="close"
                  onClick={() => this.setState({ fileDialogOpened: false })}
                  size="large"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>

            <DialogContent
              className="add-file-container-all"
              style={{ minHeight: "100px" }}
            >
              <AddVideo
                refreshFiles={this.refreshFiles}
                closePopup={() => this.setState({ fileDialogOpened: false })}
              />
            </DialogContent>
          </Dialog>

          {/* PLAY VIDEO */}
          <Dialog open={this.state.videoPlayDialog} maxWidth="lg">
            <div className="close-video">
              <Icon
                name="close"
                onClick={() => this.setState({ videoPlayDialog: false })}
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
            <DialogContent
              style={{ overflow: "hidden", display: "flex", gap: "30px" }}
            >
              <VideoPlayer
                id={"video-player-" + this.state.selectedVideo}
                is_child_video={this.state.is_child_video}
                id_video={this.state.selectedVideo}
                src={this.state.videoFile}
                club={this.props.currentUser.id_club}
                players={this.state.players}
                closePlayer={() => this.setState({ videoPlayDialog: false })}
                refreshFiles={this.refreshFiles}
              />
            </DialogContent>
          </Dialog>
        </div>
      </TFBCard>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}

export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(VideoGallery);
