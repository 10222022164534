import { rootPages } from "./constants";

export function getCurrentRootPage(pathname) {
  const rootPagesArr = Object.entries(rootPages).map(([name, value]) => value);
  for (let i = 0; i < rootPagesArr.length; i++) {
    const regex = new RegExp(`\/(${rootPagesArr[i]})([\/](.*)?)?$`);
    if (regex.test(pathname)) {
      return rootPagesArr[i];
    }
  }
  return null;
}

export function checkPageRoot(pathname, root) {
  return new RegExp(`(${root})([\/](.*)?)?$`).test(pathname);
}

export function getCurrentExchange() {
  return fetch("http://api.exchangeratesapi.io/latest?access_key=4395d9b0c22ce70763c13f0208771969")
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return new Promise((resolve, reject) => reject(res));
    })
    .then((data) => {
      const rates =
        data?.rates && typeof data.rates === "object" ? data.rates : {};
      return new Promise((resolve, reject) => resolve(rates));
    })
    .catch((error) => {
      return new Promise((resolve, reject) => reject(error));
    });
}

export function setURLParam(paramName, paramValue) {
  let url = document.location.search;
  let search_params = new URLSearchParams(url);
  search_params.set(paramName, paramValue);
  window.history.replaceState(
    null,
    null,
    "?" + search_params.toString()
  );
}

const readUploadedFileAsText = (inputFile) => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsDataURL(inputFile);
  });
};

export const handleFileInput = async (file) => {
  try {
    const fileContents = await readUploadedFileAsText(file)
    return fileContents;
  } catch (e) {
    return null;
  }
}
