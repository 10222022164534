import React, { useEffect, useState } from "react";
import axios from "axios";
import "./BasicSeasonStats.scss";
import GoalsIcon from "../../../../assets/icons/season-stats/goals.svg";
import AssistIcon from "../../../../assets/icons/season-stats/assist.svg";
import MatchesIcon from "../../../../assets/icons/season-stats/matches.svg";
import MinutesPlayedIcon from "../../../../assets/icons/season-stats/minutes_played.svg";
import YellowCardIcon from "../../../../assets/icons/season-stats/yellow_card.svg";
import RedCardIcon from "../../../../assets/icons/season-stats/red_card.svg";
import CleanSheetsIcon from "../../../../assets/icons/season-stats/clean_sheets.svg";
import TFBCard from "../../../design-system/Card/TFBCard";
import TFBCardHeader from "../../../design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../design-system/Card/TFBCardBody";

export default function BasicSeasonStats(props) {
  const [stats, setStats] = useState({});

  useEffect(() => {
    const getStats = () => {
      axios
        .get(
          `instat/player_basic_season_stats?id_player=${props.id_player}&id_season=${props.id_season}`
        )
        .then((res) => {
          setStats(res.data);
        });
    };
    getStats();
  }, []);
  return (
    <TFBCard className="current-season-container-all">
      <TFBCardHeader title={props.languagePack["current_season_stats"]} />
      <TFBCardBody>
        <div className="current-season-container">
          <div className="season-stat-row">
            <div className="season-stat-box">
              <div className="season-stat-box-content">
                <div className="stat-icon">
                  <img src={MatchesIcon} alt="matches" />
                </div>
                <div className="stat-number">{stats.matches}</div>
                {stats.matches === 1 ? (
                  <div className="stat-name ">
                    {props.languagePack["match_sg"]}
                  </div>
                ) : (
                  <div className="stat-name ">
                    {props.languagePack["match_pl"]}{" "}
                  </div>
                )}
              </div>
            </div>
            <div className="season-stat-box">
              <div className="season-stat-box-content">
                <div className="stat-icon min-played-logo-mobile">
                  <img src={MinutesPlayedIcon} alt="minutes" />
                </div>
                <div className="stat-number">{stats.minutes_played}</div>
                <div className="stat-name">
                  {props.languagePack["minutes_played"]}
                </div>
              </div>
            </div>
            {stats.assists !== "-" ? (
              <div className="season-stat-box">
                <div className="season-stat-box-content">
                  <div className="stat-icon min-played-logo-mobile">
                    <img src={AssistIcon} alt="assists" />
                  </div>
                  <div className="stat-number">{stats.assists}</div>
                  {stats.assists === 1 ? (
                    <div className="stat-name ">
                      {props.languagePack["assist_sg"]}
                    </div>
                  ) : (
                    <div className="stat-name ">
                      {props.languagePack["assist_pl"]}{" "}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="season-stat-box empty-box-bg"></div>
            )}
          </div>

          <div className="season-stat-row">
            {stats.goals !== "-" && (
              <div className="season-stat-box">
                <div className="season-stat-box-content">
                  <div className="stat-icon">
                    <img src={GoalsIcon} alt="goals" />
                  </div>
                  <div className="stat-number">{stats.goals}</div>
                  {stats.goals === 1 ? (
                    <div className="stat-name ">
                      {props.languagePack["goals_scored_sg"]}
                    </div>
                  ) : (
                    <div className="stat-name ">
                      {props.languagePack["goals_scored_pl"]}
                    </div>
                  )}
                </div>
              </div>
            )}
            {stats.clean_sheets !== "-" && (
              <div className="season-stat-box">
                <div className="season-stat-box-content">
                  <div className="stat-icon">
                    <img src={CleanSheetsIcon} alt="clean sheets" />
                  </div>
                  <div className="stat-number">{stats.clean_sheets}</div>
                  {stats.clean_sheets === 1 ? (
                    <div className="stat-name ">
                      {props.languagePack["clean_sheets_sg"]}
                    </div>
                  ) : (
                    <div className="stat-name ">
                      {props.languagePack["clean_sheets_pl"]}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="season-stat-box">
              <div className="season-stat-box-content">
                <div className="stat-icon">
                  <img src={YellowCardIcon} alt="yellow cards" />
                </div>
                <div className="stat-number yellow-card-color">
                  {stats.yellow_cards}
                </div>
                {stats.yellow_cards === 1 ? (
                  <div className="stat-name ">
                    {props.languagePack["yellow_cards_sg"]}
                  </div>
                ) : (
                  <div className="stat-name ">
                    {props.languagePack["yellow_cards_pl"]}
                  </div>
                )}
              </div>
            </div>
            <div className="season-stat-box">
              <div className="season-stat-box-content">
                <div className="stat-icon">
                  <img src={RedCardIcon} alt="red cards" />
                </div>
                <div className="stat-number red-card-color">
                  {stats.red_cards}
                </div>
                {stats.red_cards === 1 ? (
                  <div className="stat-name ">
                    {props.languagePack["red_cards_sg"]}
                  </div>
                ) : (
                  <div className="stat-name ">
                    {props.languagePack["red_cards_sg"]}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

      </TFBCardBody>
    </TFBCard>
  );
}
