import React, { useRef } from "react";
import axios from "axios";
import "./VideoPlayer.styles.scss";
import { logout } from "../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon, Button } from "semantic-ui-react";
import Loader from "react-loader-spinner";
import { store } from "react-notifications-component";
import AddEventVideoPlayer from "./AddEventVideoPlayer";

const eventsType = ["Gol", "Asist", "Cartonas galben", "Cartonas rosu"];

class VideoPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            video: null,
            current_time: "",
            loading: true,
            generated_video_title: "",

            events: [],
            events_chapters: []
        }
    }


    componentDidMount() {
        this.setState({ video: document.getElementById(this.props.id) });
        this.getTags();
    }

    componentWillUnmount() {
        this.setState({ video: null });
    }

    toHHMMSS(secs) {
        const sec = parseInt(secs, 10);
        let hours = Math.floor(sec / 3600);
        let minutes = Math.floor((sec - (hours * 3600)) / 60);
        let seconds = sec - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    }

    getTags = () => {
        axios
            .get(
                `upload/get_video_tag?id_video=${this.props.id_video}&token=${this.props.token}`
            )
            .then((res) => {
                let chapters = [];
                res.data.map(elem => {
                    let item = {
                        video_tag_title: elem.data.video_tag_title,
                        second_start: elem.data.second_start,
                        second_final: elem.data.second_final
                    };
                    chapters.push(item);
                });
                this.setState({
                    events: res.data,
                    events_chapters: chapters,
                    loading: false
                });
            })
            .catch((err) => console.log(err));
    };

    saveRow(row) {

        const payload = {
            id_club: this.props.club,
            id_video: this.props.id_video,
            id_video_tag: row.data.id_video_tag,
            deleted: "0",
            second_start: row.data.second_start,
            second_final: row.data.second_final,
            video_tag_title: row.data.video_tag_title,
            video_tag_description: row.data.video_tag_description,
            video_tag_details: row.video_tag_details
        }

        this.setState({ loading: true });
        axios
            .post(
                "upload/add_video_tag",
                payload,
                {
                    params: { token: this.props.token },
                    headers: { "content-type": "multipart/form-data" }
                }
            )
            .then(async (res) => {
                if (res?.data?.go_to_login === 1) {
                    this.props.logout(this.props.token);
                }
                if (res.data == 1) {
                    this.getTags();
                    store.addNotification({
                        title: "Video editing",
                        message: "Video tag was successfully updated!",
                        type: "success",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                        },
                    });
                } else {
                    store.addNotification({
                        title: "Video editing",
                        message: "Video tag updating has failed!",
                        type: "danger",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                        },
                    });
                }
                this.setState({ loading: false });
            })
            .catch(async (err) => {
                return { data: {} };
            });
    }

    deleteRow(row) {

        const payload = {
            id_club: this.props.club,
            id_video: this.props.id_video,
            id_video_tag: row.data.id_video_tag,
            deleted: "1",
        }

        this.setState({ loading: true });
        axios
            .post(
                "upload/add_video_tag",
                payload,
                {
                    params: { token: this.props.token },
                    headers: { "content-type": "multipart/form-data" }
                }
            )
            .then(async (res) => {
                if (res?.data?.go_to_login === 1) {
                    this.props.logout(this.props.token);
                }
                if (res.data == 1) {
                    this.getTags();
                    store.addNotification({
                        title: "Video editing",
                        message: "Video tag was successfully deleted!",
                        type: "success",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                        },
                    });
                } else {
                    store.addNotification({
                        title: "Video editing",
                        message: "Video tag deleting has failed!",
                        type: "danger",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                        },
                    });
                }
                this.setState({ loading: false });
                return res;
            })
            .catch(async (err) => {
                return { data: {} };
            });
    }

    addEvent = () => {
        this.setState({ addEvent: true });
    }

    resetState = () => {
        this.setState({
            addEvent: false
        });
    }

    showLoading = () => {
        this.setState({
            loading: true
        })
    }

    generateVideo() {
        let payload = {
            video_name: this.state.generated_video_title,
            category: "video",
            uploaded_by: this.props.currentUser.nickname,
            id_club: this.props.club,
            id_video: this.props.id_video,
            id_user: this.props.currentUser.id_user
        }
        axios.post("upload/generate_video",
            payload,
            {
                params: { token: this.props.token },
                headers: { "content-type": "multipart/form-data" }
            }).then(res => {
                this.props.closePlayer();
                this.props.refreshFiles();

            }).catch(err => {
                console.log(err)
            })
    }

    render() {
        const { languagePack } = this.props;
        return (
            <>
                <div className="video-player"
                >
                    <video
                        id={this.props.id}
                        className="video"
                        src={this.props.src}
                        controls
                        type="video/mp4"
                        style={{ width: window.innerWidth * 0.3, margin: "auto" }}
                        onTimeUpdate={() => {
                            this.setState({
                                current_time: this.state.video.currentTime
                            })
                        }}
                    >
                    </video>
                    {!this.state.loading && this.props.is_child_video &&
                        <div className="video-events" style={{ width: "100%", padding: "0 0 30px 0" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", margin: "30px 0" }}>
                                <div className="event-title">Evenimente</div>
                                <div className="add-event-container">
                                    <button className="button" onClick={this.addEvent}>Adauga Eveniment</button>
                                </div>
                            </div>
                            <div className="video-event-container" style={{ overflowY: "scroll", height: window.innerHeight * 0.35, paddingRight: "10px", overflowX: "hidden" }}>
                                {this.state.addEvent && <AddEventVideoPlayer
                                    players={this.props.players}
                                    resetState={this.resetState}
                                    video={this.state.video}
                                    getTags={this.getTags}
                                    id_video={this.props.id_video}
                                    types_events={eventsType}
                                    club={this.props.club}
                                    showLoading={this.showLoading}
                                />}
                                <div className="events-container" style={{ width: "100%", paddingBottom: "0" }}>
                                    {this.state.events.length != 0 ? (
                                        <>
                                            {this.state.events.map((item, index) => {
                                                return (
                                                    <div
                                                        key={"event-" + index}
                                                        className="video-event-container tags-video-container"
                                                        style={{ paddingBottom: "15px" }}>
                                                        <div className="video-event-row">
                                                            <button
                                                                className="button"
                                                                onClick={() => {
                                                                    let events_update = this.state.events;
                                                                    events_update[index].data.second_start = this.state.video.currentTime;
                                                                    this.setState({ events: events_update });
                                                                }}
                                                            >
                                                                Get Start Time
                                                            </button>
                                                            <input
                                                                className="time"
                                                                value={this.toHHMMSS(item.data.second_start)}
                                                                placeholder="Start: "
                                                            />
                                                            <button
                                                                className="button"
                                                                onClick={() => {
                                                                    let events_update = this.state.events;
                                                                    events_update[index].data.second_final = this.state.video.currentTime;
                                                                    this.setState({ events: events_update });
                                                                }}
                                                            >
                                                                Get End Time
                                                            </button>
                                                            <input
                                                                className="time"
                                                                value={this.toHHMMSS(item.data.second_final)}
                                                                placeholder="End: "
                                                            />
                                                        </div>
                                                        <div className="video-event-row">
                                                            <select
                                                                className='ui selection dropdown event-type'
                                                                value={item.video_tag_details[0].tag_categ_name}
                                                                defaultValue={languagePack.select_type}
                                                                onChange={(event) => {
                                                                    let events_update = this.state.events;
                                                                    events_update[index].video_tag_details[0].tag_categ_name = event.target.value;
                                                                    this.setState({ events: events_update });
                                                                }}
                                                            >
                                                                <option value={languagePack.select_type} disabled>{languagePack.select_type}</option>
                                                                {eventsType.map((elem) => {
                                                                    return (
                                                                        <option value={elem}>{elem}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <select
                                                                className='ui selection dropdown event-type'
                                                                value={item.video_tag_details[0].player_name}
                                                                defaultValue={languagePack.select_player}
                                                                onChange={(e) => {
                                                                    let events_update = this.state.events;
                                                                    events_update[index].video_tag_details[0].id_team = e.target.selectedOptions[0].getAttribute("id_team");
                                                                    events_update[index].video_tag_details[0].id_player = e.target.selectedOptions[0].getAttribute("id_player");
                                                                    events_update[index].video_tag_details[0].player_name = e.target.value;
                                                                    this.setState({ events: events_update });
                                                                }}
                                                            >
                                                                <option value={languagePack.select_player} disabled>{languagePack.select_player}</option>
                                                                {this.props.players.map((elem) => {
                                                                    return (
                                                                        <option
                                                                            value={elem.player_name}
                                                                            id_team={elem.id_team}
                                                                            id_player={elem.id_player}
                                                                        >
                                                                            {elem.player_name}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div className="video-title-description">
                                                            <input
                                                                className="description"
                                                                value={item.data.video_tag_title}
                                                                onChange={(e) => {
                                                                    let events_update = this.state.events;
                                                                    events_update[index].data.video_tag_title = e.target.value;
                                                                    this.setState({ events: events_update })
                                                                }}
                                                                placeholder="Title"
                                                            />
                                                            <textarea
                                                                className="description"
                                                                value={item.data.video_tag_description}
                                                                onChange={(event) => {
                                                                    let events_update = this.state.events;
                                                                    events_update[index].data.video_tag_description = event.target.value;
                                                                    this.setState({ events: events_update });
                                                                }}
                                                                placeholder="Description"
                                                            />
                                                        </div>
                                                        {item.video_tag_details.map((elem, index_tag) => {
                                                            if (index_tag > 0 && elem.deleted == "0") {
                                                                return (
                                                                    <div
                                                                        className="generated-row video-event-row"
                                                                        key={"tag_additional" + index_tag}
                                                                    >
                                                                        <select
                                                                            className='ui selection dropdown event-type'
                                                                            value={elem.tag_categ_name}
                                                                            defaultValue={languagePack.select_type}
                                                                            onChange={(event) => {
                                                                                let events_update = this.state.events;
                                                                                events_update[index].video_tag_details[index_tag].tag_categ_name = event.target.value;
                                                                                this.setState({ events: events_update });
                                                                            }}
                                                                        >
                                                                            <option value={languagePack.select_type} disabled>{languagePack.select_type}</option>
                                                                            {eventsType.map((elem) => {
                                                                                return (
                                                                                    <option value={elem}>{elem}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                        <select
                                                                            className='ui selection dropdown event-type'
                                                                            value={elem.player_name}
                                                                            defaultValue={languagePack.select_player}
                                                                            onChange={(e) => {
                                                                                let events_update = this.state.events;
                                                                                events_update[index].video_tag_details[index_tag].id_team = e.target.selectedOptions[0].getAttribute("id_team");
                                                                                events_update[index].video_tag_details[index_tag].id_player = e.target.selectedOptions[0].getAttribute("id_player");
                                                                                events_update[index].video_tag_details[index_tag].player_name = e.target.value;
                                                                                this.setState({ events: events_update });
                                                                            }}
                                                                        >
                                                                            <option value={languagePack.select_player} disabled>{languagePack.select_player}</option>
                                                                            {this.props.players.map((elem) => {
                                                                                return (
                                                                                    <option
                                                                                        value={elem.player_name}
                                                                                        id_team={elem.id_team}
                                                                                        id_player={elem.id_player}
                                                                                    >
                                                                                        {elem.player_name}
                                                                                    </option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                        <div className="button-container" style={{ padding: "0", height: "auto" }}>
                                                                            <button
                                                                                className="additional-button"
                                                                                onClick={() => {
                                                                                    let events_update = this.state.events;
                                                                                    let event_update = events_update[index];
                                                                                    let video_tag_details_update = event_update.video_tag_details;
                                                                                    if (video_tag_details_update[index_tag].tag_categ_name == "" && video_tag_details_update[index_tag].player_name == "") {
                                                                                        video_tag_details_update.splice(index_tag, 1);
                                                                                    } else {
                                                                                        video_tag_details_update[index_tag].deleted = "1";
                                                                                    }
                                                                                    event_update.video_tag_details = video_tag_details_update;
                                                                                    events_update[index] = event_update;
                                                                                    this.setState({ events: events_update });
                                                                                }}
                                                                            >
                                                                                <Icon className="additional-icon" color="red" name="close" size="small" style={{ cursor: "pointer" }} />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        })}
                                                        <div className="plus-button">
                                                            <button
                                                                className="additional-button"
                                                                onClick={() => {
                                                                    let events_update = this.state.events;
                                                                    let event_update = events_update[index];
                                                                    let video_tag_details_update = event_update.video_tag_details;
                                                                    let new_tag_details = {
                                                                        date_add: "",
                                                                        deleted: "0",
                                                                        id_player: "",
                                                                        id_team: "",
                                                                        id_video_tag: item.data.id_video_tag,
                                                                        id_video_tag_categ: "",
                                                                        id_video_tag_detail: "",
                                                                        player_name: "",
                                                                        tag_categ_name: ""
                                                                    }
                                                                    video_tag_details_update.push(new_tag_details);
                                                                    event_update.video_tag_details = video_tag_details_update;
                                                                    events_update[index] = event_update;
                                                                    this.setState({ events: events_update });
                                                                }}
                                                            >
                                                                <Icon className="additional-icon" name="add" size="small" style={{ cursor: "pointer" }} />
                                                            </button>
                                                        </div>
                                                        <div className="video-event-row button-row">
                                                            <button
                                                                className="button"
                                                                style={{ marginRight: "25px" }}
                                                                onClick={() => {
                                                                    this.saveRow(item);
                                                                }}
                                                            >
                                                                SAVE
                                                            </button>

                                                            <button
                                                                className="button"
                                                                style={{ backgroundColor: "red" }}
                                                                onClick={() => {
                                                                    this.deleteRow(item);
                                                                }}
                                                            >
                                                                DELETE
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            <input
                                                className="add-video-title"
                                                value={this.state.generated_video_title}
                                                onChange={(event) => {
                                                    this.setState({ generated_video_title: event.target.value })
                                                }}
                                                placeholder="Generated video title"
                                            />
                                            <div className="generate-button">
                                                <Button
                                                    className="button"
                                                    onClick={() => {
                                                        this.setState({ generate_video: true });
                                                        this.generateVideo();
                                                    }}
                                                    disabled={this.state.events.length == 0 || this.state.generated_video_title == ""}
                                                >
                                                    Genereaza video
                                                </Button>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="event-subtitle">Niciun eveniment salvat</div>
                                    )}
                                </div>
                            </div>
                        </div>}
                </div>
                {this.state.events_chapters.length > 0 && !this.state.loading &&
                    <div className="video-chapters">
                        <div className="chapters-title">
                            Chapters
                        </div>
                        <div className="chapters-container">
                            {this.state.events_chapters.length > 0 && !this.state.loading && this.state.events_chapters.map((elem, index) => {
                                return (
                                    <div
                                        key={"chapter-" + index}
                                        className="chapter-item"
                                        style={{ backgroundColor: this.toHHMMSS(this.state.current_time).localeCompare(this.toHHMMSS(elem.second_start)) >= 0 && this.toHHMMSS(this.state.current_time).localeCompare(this.toHHMMSS(elem.second_final)) <= 0 ? "#e5e5e5" : "transparent" }}
                                        onClick={() => {
                                            this.state.video.currentTime = elem.second_start;
                                        }}
                                    >
                                        <div className="active-chapter-container">
                                            <Icon
                                                name="play"
                                                size="small"
                                                style={{
                                                    cursor: "pointer",
                                                    color: this.toHHMMSS(this.state.current_time).localeCompare(this.toHHMMSS(elem.second_start)) >= 0 && this.toHHMMSS(this.state.current_time).localeCompare(this.toHHMMSS(elem.second_final)) <= 0 ? "#00d948" : "transparent",
                                                }}
                                            />
                                        </div>
                                        <div className="chapter-data">
                                            <div className="chapter-title">
                                                {elem.video_tag_title}
                                            </div>
                                            <div className="chapter-timeline">
                                                {this.toHHMMSS(elem.second_start) + " - " + this.toHHMMSS(elem.second_final)}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>}
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { logout },
        dispatch
    );
}

export default connect(
    ({ layoutService, auth }) => ({
        languagePack: layoutService.languagePack,
        currentUser: auth.user,
        token: auth?.user?.token
    }),
    mapDispatchToProps
)(VideoPlayer);
