import React, { useState } from "react";
import axios from "axios";
import "./EditCompetition.scss";
import { Icon, Button, Input } from "semantic-ui-react";
import { store } from "react-notifications-component";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";

const ageCategorieOptions = [
  {
    value: 0,
    label: "Seniori",
  },
  {
    value: 1,
    label: "U6",
  },
  {
    value: 2,
    label: "U7",
  },
  {
    value: 3,
    label: "U8",
  },
  {
    value: 4,
    label: "U9",
  },
  {
    value: 5,
    label: "U10",
  },
  {
    value: 6,
    label: "U11",
  },
  {
    value: 7,
    label: "U12",
  },
  {
    value: 8,
    label: "U13",
  },
  {
    value: 9,
    label: "U14",
  },
  {
    value: 10,
    label: "U15",
  },
  {
    value: 11,
    label: "U16",
  },
  {
    value: 12,
    label: "U17",
  },
  {
    value: 13,
    label: "U18",
  },
  {
    value: 14,
    label: "U19",
  },
  {
    value: 15,
    label: "U20",
  },
  {
    value: 16,
    label: "U21",
  },
  {
    value: 17,
    label: "U22",
  },
  {
    value: 18,
    label: "U23",
  },
];

function EditCompetition(props) {
  const [name, setName] = useState(props.competition.text);
  const [type, setType] = useState(props.competition.competition_type);
  const [groups, setGroups] = useState(props.competition.groups_number);
  const [number, setNumber] = useState(props.competition.teams_number);
  const [dateStart, setDateStart] = useState(props.competition.date_start);
  const [dateEnd, setDateEnd] = useState(props.competition.date_end);
  const [minutesPerTime, setMinutesPerTime] = useState(
    props.competition.minutes_per_time
  );
  const [ageCategory, setAgeCategory] = useState(
    ageCategorieOptions.find(
      (option) => option.label == props.competition.category
    )
  );

  const { languagePack } = props;

  const comp_types = [
    {
      key: 1,
      value: "championship",
      text: languagePack.competition_type.elements["championship"],
    },
    {
      key: 2,
      value: "knockout",
      text: languagePack.competition_type.elements["knockout"],
    },
    {
      key: 3,
      value: "group-stage",
      text: languagePack.competition_type.elements["group_stage"],
    },
  ];

  function saveCompetition() {
    const competition = {
      id_competition: props.competition.key,
      competition_name: name,
      teams_number: number,
      date_start: dateStart,
      date_end: dateEnd,
    };

    if (minutesPerTime != "") {
      competition.minutes_per_time = minutesPerTime;
    }

    if (ageCategory) {
      competition.category = ageCategory.label;
    }

    axios.post("match/edit_competition", competition).then(() => {
      store.addNotification({
        title: languagePack["manage_competitions"],
        message: languagePack["manage_competitions_success_changes"],
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      props.closePopup();
      props.getCompetitions();
    });
  }

  const changeMinutesPerTime = (e) => {
    if (
      e.length > 2 ||
      (e.length == 2 && Number(e) > 45) ||
      (e.length == 1 && Number(e) < 1)
    )
      return false;

    setMinutesPerTime(e);
  };

  return (
    <div className="edit-competition-form">
      <div className="edit-competition-header">
        <div className="edit-competition-title">
          {languagePack["edit_competition"]}
        </div>
        <Icon
          name="close"
          className="close"
          onClick={() => props.closePopup()}
        />
      </div>
      <div className="edit-competition-row">
        <input
          placeholder={languagePack["competition_name"]}
          className="edit-comp-input"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      {props.competition.multiple_age_groups == "0" && (
        <div className="edit-competition-row">
          <input
            placeholder={languagePack["teams_number"]}
            type="number"
            className="edit-comp-input"
            name="nr_teams"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
        </div>
      )}
      {props.competition.multiple_age_groups == "0" && (
        <div className="edit-competition-row">
          <Input
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            placeholder={languagePack.minutes_per_time}
            className="add-value-input-time edit-comp-minutes-per-time-input"
            name="time"
            onChange={(e) => {
              changeMinutesPerTime(e.target.value);
            }}
            value={minutesPerTime ?? ""}
          />
        </div>
      )}
      {/* <div className="edit-competition-row">
                <Dropdown
                    selection
                    placeholder={languagePack.competition_type["name"]}
                    name="type"
                    value={type}
                    options={comp_types}
                    onChange={(e, value) => {
                        setType(value.value);
                    }}
                    className="add-comp-dropdown"
                />
            </div>
            {type == "group-stage" &&
                <div className="edit-competition-row">
                    <input
                        placeholder={"Number of groups"}
                        type="number"
                        className="edit-comp-input"
                        name="nr_groups"
                        value={groups}
                        onChange={(e) => {
                            setGroups(e.target.value);
                        }}
                    />
                </div>} */}
      {props.competition.multiple_age_groups == "0" && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="edit-competition-row">
            <DatePicker
              value={dateStart}
              onChange={(value) => setDateStart(value)}
              format="dd MMMM yyyy"
              name="date_start"
              inputVariant="outlined"
              className="comp-date-input"
              emptyLabel={languagePack["starting_date"]}
            />
            <Icon
              name="calendar alternate outline"
              className="calendar-icon"
              size="small"
            />
          </div>
          <div className="edit-competition-row">
            <DatePicker
              value={dateEnd}
              onChange={(value) => setDateEnd(value)}
              format="dd MMMM yyyy"
              name="date_end"
              inputVariant="outlined"
              className="comp-date-input"
              emptyLabel={languagePack["ending_date"]}
            />
            <Icon
              name="calendar alternate outline"
              className="calendar-icon"
              size="small"
            />
          </div>

          <div
            className="select-age-category"
            style={{ marginTop: "25px", marginBottom: "25px" }}
          >
            <TFBSelect
              placeholder={languagePack.select_age_category}
              label={languagePack.select_age_category}
              options={ageCategorieOptions}
              value={ageCategory}
              onChange={(selected) => setAgeCategory(selected)}
            />
          </div>
        </MuiPickersUtilsProvider>
      )}
      <div className="edit-comp-buttons">
        <Button className="cancel-button" onClick={() => props.closePopup()}>
          {languagePack["admin_cancel"]}
          <Icon name="close" className="white-icon" />
        </Button>
        <Button className="save-comp-button" onClick={saveCompetition}>
          {languagePack["admin_add"]}
          <Icon name="add" className="white-icon" />
        </Button>
      </div>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(EditCompetition);
