import * as auth from "../authRedux";
import layoutServiceFunctions from "../functions/layoutServiceFunctions";
import * as authCrud from "../authCrud";

export function authReduxFunctions(dispatch) {
    return {
        logout: function(user) {
            authCrud.logout(user).finally(() => {
                dispatch(auth.actions.logout());
                localStorage.setItem('club', 0);
            });
        }, 
        login: function(user) {
            dispatch(auth.actions.login(user));
            layoutServiceFunctions(dispatch).setLanguage(user.lang);
            layoutServiceFunctions(dispatch).setClub(user.id_club);
        } 
    }
}