import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import "../../ClubProfile/Teams/Teams.scss";
import { store } from "react-notifications-component";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";

class AddMatchEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event_minute: "",
      selected_team: null,
      selected_event_type: null,
      selected_player: null,
      selected_player_assist: null,
      selected_goal_type: null,
      selected_goal_category: null,
      first_time: true,
      second_time: false,
    };
  }

  addNewEvent = () => {
    if (!this.isDisabledAddButton()) {
      //calculate minute
      let minute = Number(this.state.event_minute);
      if (this.state.first_time) {
        minute =
          Number(this.state.event_minute) >
            Number(this.props.matchDetails.minutes_per_time)
            ? Number(this.props.matchDetails.minutes_per_time)
            : Number(this.state.event_minute) < 1
              ? 1
              : minute;
      } else {
        minute =
          Number(this.state.event_minute) <=
            Number(this.props.matchDetails.minutes_per_time)
            ? Number(this.props.matchDetails.minutes_per_time) + 1
            : Number(this.state.event_minute) >
              2 * Number(this.props.matchDetails.minutes_per_time)
              ? 2 * Number(this.props.matchDetails.minutes_per_time)
              : Number(this.state.event_minute) -
              Number(this.props.matchDetails.minutes_per_time);
      }

      const payload = {
        id_match: this.props.idMatch,
        action_name: this.state.selected_event_type?.db,
        minute: minute,
        id_player: this.state.selected_player?.value
          ? this.state.selected_player?.value
          : null,
        player_name: this.state.selected_player?.label
          ? this.state.selected_player?.label
          : null,
        id_second_player: this.state.selected_player_assist?.value
          ? this.state.selected_player_assist?.value
          : null,
        player_second_name: this.state.selected_player_assist?.label
          ? this.state.selected_player_assist?.label
          : null,
        id_team: this.state.selected_team?.own_team_id
          ? this.state.selected_team?.own_team_id
          : null,
        id_manual_competition_team: this.state.selected_team?.value,
        goal_category: this.state.selected_goal_category?.value
          ? this.state.selected_goal_category?.value
          : null,
        half: this.state.first_time ? 1 : 2,
      };

      axios.post("match/add_manual_match_event", payload).then((res) => {
        if (res.data.success == 1) {
          this.props.refreshEvents();
          if (payload.action_name == "Substitution") {
            this.props.updatePlayersRating();
          }
          store.addNotification({
            title: this.props.languagePack["fixtures_schedule"],
            message: this.props.languagePack["event_successfully_added"],
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: this.props.languagePack["fixtures_schedule"],
            message: "Evenimentul nu a fost adaugat cu succes.",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        this.props.closePopup();
      });
    }
  };

  handleChangeInputMinute = (e) => {
    this.setState({ event_minute: e.target.value });
  };

  isDisabledAddButton = () => {
    if (
      this.state.event_minute == "" ||
      this.state.selected_team == null ||
      this.state.selected_event_type == null
    ) {
      return true;
    } else if (
      this.state.selected_team.own_team_id == null &&
      this.state.selected_event_type != null
    ) {
      return false;
    } else if (
      ["1", "2"].indexOf(this.state.selected_event_type.value) !== -1
    ) {
      if (this.state.selected_player == null) {
        return true;
      } else {
        return false;
      }
    } else if (["3"].indexOf(this.state.selected_event_type.value) !== -1) {
      if (
        this.state.selected_player == null ||
        this.state.selected_player_assist == null
      ) {
        return true;
      } else {
        return false;
      }
    } else if (["4"].indexOf(this.state.selected_event_type.value) !== -1) {
      if (this.state.selected_goal_type == null) {
        return true;
      } else {
        if (this.state.selected_goal_category == null) {
          return true;
        } else {
          if (["1"].indexOf(this.state.selected_goal_type.value) !== -1) {
            if (this.state.selected_player == null) {
              return true;
            } else {
              return false;
            }
          } else {
            if (
              this.state.selected_player == null ||
              this.state.selected_player_assist == null
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    }
    return true;
  };

  render() {
    const { languagePack, teams, players, matchDetails } = this.props;

    const event_types = [
      {
        value: "1",
        label: languagePack["yellow_card"],
        db: "Yellow card",
      },
      {
        value: "2",
        label: languagePack["red_card"],
        db: "Red card",
      },
      {
        value: "3",
        label: languagePack["substitution"],
        db: "Substitution",
      },
      {
        value: "4",
        label: languagePack["goal"],
        db: "Goal",
      },
    ];

    const goal_types = [
      {
        value: "1",
        label: languagePack["single_goal"],
      },
      {
        value: "2",
        label: languagePack["goal_assist"],
      },
      // {
      //   value: "2",
      //   label: "Autogol",
      // },
    ];

    return (
      <div className="add-team-container">
        <div className="add-team-header">
          <div className="add-team-title">{languagePack["add_event"]}</div>
          <Icon
            name="close"
            className="close-icon-popup"
            onClick={() => this.props.closePopup()}
          />
        </div>
        <div className="add-team-row">
          <input
            placeholder={languagePack["event_minute"]}
            type="number"
            min={1}
            max={2 * Number(matchDetails.minutes_per_time)}
            className="add-team-input"
            name="event_minute"
            value={this.state.event_minute}
            onChange={this.handleChangeInputMinute}
          />
        </div>
        <div className="add-team-row">
          <div className="time-type">
            <div
              className="currency-container"
              style={{ marginRight: 30 }}
              onClick={() => this.setState({ first_time: true })}
            >
              <div className="checkbox-background">
                <div
                  className={
                    this.state.first_time
                      ? "currency-checkbox currency-checkbox-selected checked-center"
                      : "currency-checkbox checked-center"
                  }
                />
              </div>
              <div className="currency-name">{languagePack.first_half}</div>
            </div>
            <div
              className="currency-container"
              style={{ marginRight: 10 }}
              onClick={() => this.setState({ first_time: false })}
            >
              <div className="checkbox-background">
                <div
                  className={
                    !this.state.first_time
                      ? "currency-checkbox currency-checkbox-selected checked-center"
                      : "currency-checkbox checked-center"
                  }
                />
              </div>
              <div className="currency-name">{languagePack.second_half}</div>
            </div>
          </div>
        </div>
        <div className="add-team-row">
          <TFBSelect
            placeholder={languagePack["select_team"]}
            options={teams}
            value={this.state.selected_team}
            onChange={(selected) =>
              this.setState({
                selected_team: selected,
                selected_event_type: null,
                selected_player: null,
                selected_player_assist: null,
                selected_goal_type: null,
                selected_goal_category: null,
              })
            }
          />
        </div>
        <div className="add-team-row">
          <TFBSelect
            placeholder={languagePack["select_event_type"]}
            options={
              this.state.selected_team == null
                ? []
                : this.state.selected_team.own_team_id != null
                  ? event_types
                  : event_types.filter((elem) => elem.value != 3)
            }
            value={this.state.selected_event_type}
            onChange={(selected) =>
              this.setState({
                selected_event_type: selected,
                selected_player: null,
                selected_player_assist: null,
                selected_goal_type: null,
                selected_goal_category: null,
              })
            }
          />
        </div>
        {this.state.selected_event_type != null &&
          this.state.selected_team != null
          ? ["1", "2"].indexOf(this.state.selected_event_type.value) !== -1 &&
          this.state.selected_team.own_team_id != null && (
            <div className="add-team-row">
              <TFBSelect
                placeholder="Selecteaza jucatorul"
                options={players[this.state.selected_team.value]}
                value={this.state.selected_player}
                onChange={(selected) =>
                  this.setState({
                    selected_player: selected,
                  })
                }
              />
            </div>
          )
          : null}
        {this.state.selected_event_type != null &&
          this.state.selected_team != null
          ? this.state.selected_event_type.value === "3" && (
            <>
              <div className="add-team-row">
                <TFBSelect
                  placeholder="Selecteaza jucatorul out"
                  options={players[this.state.selected_team.value]}
                  value={this.state.selected_player}
                  onChange={(selected) =>
                    this.setState({
                      selected_player: selected,
                    })
                  }
                />
              </div>
              <div className="add-team-row">
                <TFBSelect
                  placeholder="Selecteaza jucatorul in"
                  options={players[this.state.selected_team.value]}
                  value={this.state.selected_player_assist}
                  onChange={(selected) =>
                    this.setState({
                      selected_player_assist: selected,
                    })
                  }
                />
              </div>
            </>
          )
          : null}
        {this.state.selected_event_type != null &&
          this.state.selected_team != null
          ? this.state.selected_event_type.value === "4" &&
          (this.state.selected_team.own_team_id != null ? (
            <>
              <div className="add-team-row">
                <TFBSelect
                  placeholder={languagePack["select_goal_type"]}
                  options={goal_types}
                  value={this.state.selected_goal_type}
                  onChange={(selected) =>
                    this.setState({
                      selected_goal_type: selected,
                      selected_player: null,
                      selected_player_assist: null,
                      selected_goal_category: null,
                    })
                  }
                />
              </div>
              {this.state.selected_goal_type != null && (
                <>
                  <div className="add-team-row">
                    <TFBSelect
                      placeholder="Selecteaza categorie gol"
                      options={goal_category}
                      value={this.state.selected_goal_category}
                      onChange={(selected) =>
                        this.setState({
                          selected_goal_category: selected,
                        })
                      }
                    />
                  </div>
                  <div className="add-team-row">
                    <TFBSelect
                      placeholder="Selecteaza jucatorul marcator"
                      options={players[this.state.selected_team.value]}
                      value={this.state.selected_player}
                      onChange={(selected) =>
                        this.setState({
                          selected_player: selected,
                        })
                      }
                    />
                  </div>
                  {this.state.selected_goal_type.value == 2 && (
                    <div className="add-team-row">
                      <TFBSelect
                        placeholder="Selecteaza jucatorul pasator"
                        options={players[this.state.selected_team.value]}
                        value={this.state.selected_player_assist}
                        onChange={(selected) =>
                          this.setState({
                            selected_player_assist: selected,
                          })
                        }
                      />
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <div className="add-team-row">
              <TFBSelect
                placeholder="Selecteaza categorie gol"
                options={goal_category}
                value={this.state.selected_goal_category}
                onChange={(selected) =>
                  this.setState({
                    selected_goal_category: selected,
                  })
                }
              />
            </div>
          ))
          : null}
        <div className="add-team-buttons">
          <TFBButton
            color="darkGray"
            onClick={() => this.props.closePopup()}
            style={{ width: "48%" }}
          >
            {languagePack["admin_cancel"]}
            <TFBIcon name="cancel" color="white" />
          </TFBButton>
          <TFBButton
            color="green"
            onClick={this.addNewEvent}
            disabled={this.isDisabledAddButton() ? true : false}
            style={{ width: "48%" }}
          >
            {languagePack["admin_add"]}
            <TFBIcon name="add" color="white" />
          </TFBButton>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(AddMatchEvent);

const goal_category = [
  {
    value: "free_kick",
    label: "Fază fixă",
  },
  {
    value: "cross",
    label: "Centrare",
  },
  {
    value: "direct_play",
    label: "Joc direct",
  },
  {
    value: "combination_play",
    label: "Atac combinat",
  },
  {
    value: "counterattack",
    label: "Contraatac",
  },
  {
    value: "penalty",
    label: "Penalty",
  },
  {
    value: "none",
    label: "Niciuna",
  },
];
