import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logout } from "../../utils/api";
import "./UsersManagement.scss";
import TFBIcon from "../../components/design-system/Icon/TFBIcon";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Checkbox from "@material-ui/core/Checkbox";
import TFBButton from "../../components/design-system/Button/TFBButton";
import axios from "axios";
import { store } from "react-notifications-component";

class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      full_name: this.props.dataToEdit.name,
      email: this.props.dataToEdit.email,
      phoneNumber: this.props.dataToEdit.phone_number,

      wrongPhoneNumber: false,
      wrongEmail: false,

      extendTeams: false,

      teamsAccess: this.props.dataToEdit.teams,

      //   platformModules: [
      //     {
      //       moduleName: "Clubul meu",
      //       extend: false,
      //       subModules: [
      //         {
      //           name: "Echipe",
      //           checked: false,
      //         },
      //         { name: "Calendar", checked: false },
      //         { name: "Program meciuri", checked: false },
      //         { name: "Competiții", checked: false },
      //         { name: "Gestionare terenuri", checked: false },
      //         { name: "Cheltuieli meci", checked: false },
      //         { name: "Situație suspendări", checked: false },
      //         { name: "Liste A/B", checked: false },
      //       ],
      //     },
      //     {
      //       moduleName: "Antrenamente",
      //       extend: false,
      //       subModules: [
      //         {
      //           name: "Echipe",
      //           checked: false,
      //         },
      //         { name: "Calendar", checked: false },
      //         { name: "Program meciuri", checked: false },
      //         { name: "Competiții", checked: false },
      //         { name: "Gestionare terenuri", checked: false },
      //         { name: "Cheltuieli meci", checked: false },
      //         { name: "Situație suspendări", checked: false },
      //         { name: "Liste A/B", checked: false },
      //       ],
      //     },
      //     {
      //       moduleName: "Rapoarte",
      //       extend: false,
      //       subModules: [
      //         {
      //           name: "Echipe",
      //           checked: false,
      //         },
      //         { name: "Calendar", checked: false },
      //         { name: "Program meciuri", checked: false },
      //         { name: "Competiții", checked: false },
      //         { name: "Gestionare terenuri", checked: false },
      //         { name: "Cheltuieli meci", checked: false },
      //         { name: "Situație suspendări", checked: false },
      //         { name: "Liste A/B", checked: false },
      //       ],
      //     },
      //     {
      //       moduleName: "Financiar",
      //       extend: false,
      //       subModules: [
      //         {
      //           name: "Echipe",
      //           checked: false,
      //         },
      //         { name: "Calendar", checked: false },
      //         { name: "Program meciuri", checked: false },
      //         { name: "Competiții", checked: false },
      //         { name: "Gestionare terenuri", checked: false },
      //         { name: "Cheltuieli meci", checked: false },
      //         { name: "Situație suspendări", checked: false },
      //         { name: "Liste A/B", checked: false },
      //       ],
      //     },
      //     {
      //       moduleName: "Medical",
      //       extend: false,
      //       subModules: [
      //         {
      //           name: "Echipe",
      //           checked: false,
      //         },
      //         { name: "Calendar", checked: false },
      //         { name: "Program meciuri", checked: false },
      //         { name: "Competiții", checked: false },
      //         { name: "Gestionare terenuri", checked: false },
      //         { name: "Cheltuieli meci", checked: false },
      //         { name: "Situație suspendări", checked: false },
      //         { name: "Liste A/B", checked: false },
      //       ],
      //     },
      //     {
      //       moduleName: "Documente",
      //       extend: false,
      //       subModules: [
      //         {
      //           name: "Echipe",
      //           checked: false,
      //         },
      //         { name: "Calendar", checked: false },
      //         { name: "Program meciuri", checked: false },
      //         { name: "Competiții", checked: false },
      //         { name: "Gestionare terenuri", checked: false },
      //         { name: "Cheltuieli meci", checked: false },
      //         { name: "Situație suspendări", checked: false },
      //         { name: "Liste A/B", checked: false },
      //       ],
      //     },
      //     {
      //       moduleName: "Suporteri",
      //       extend: false,
      //       subModules: [
      //         {
      //           name: "Echipe",
      //           checked: false,
      //         },
      //         { name: "Calendar", checked: false },
      //         { name: "Program meciuri", checked: false },
      //         { name: "Competiții", checked: false },
      //         { name: "Gestionare terenuri", checked: false },
      //         { name: "Cheltuieli meci", checked: false },
      //         { name: "Situație suspendări", checked: false },
      //         { name: "Liste A/B", checked: false },
      //       ],
      //     },
      //     {
      //       moduleName: "Galerie video",
      //       extend: false,
      //       subModules: [
      //         {
      //           name: "Echipe",
      //           checked: false,
      //         },
      //         { name: "Calendar", checked: false },
      //         { name: "Program meciuri", checked: false },
      //         { name: "Competiții", checked: false },
      //         { name: "Gestionare terenuri", checked: false },
      //         { name: "Cheltuieli meci", checked: false },
      //         { name: "Situație suspendări", checked: false },
      //         { name: "Liste A/B", checked: false },
      //       ],
      //     },
      //     {
      //       moduleName: "Adminsitare site",
      //       extend: false,
      //       subModules: [
      //         {
      //           name: "Echipe",
      //           checked: false,
      //         },
      //         { name: "Calendar", checked: false },
      //         { name: "Program meciuri", checked: false },
      //         { name: "Competiții", checked: false },
      //         { name: "Gestionare terenuri", checked: false },
      //         { name: "Cheltuieli meci", checked: false },
      //         { name: "Situație suspendări", checked: false },
      //         { name: "Liste A/B", checked: false },
      //       ],
      //     },
      //     {
      //       moduleName: "Administrare aplciatie parinti",
      //       extend: false,
      //       subModules: [
      //         {
      //           name: "Echipe",
      //           checked: false,
      //         },
      //         { name: "Calendar", checked: false },
      //         { name: "Program meciuri", checked: false },
      //         { name: "Competiții", checked: false },
      //         { name: "Gestionare terenuri", checked: false },
      //         { name: "Cheltuieli meci", checked: false },
      //         { name: "Situație suspendări", checked: false },
      //         { name: "Liste A/B", checked: false },
      //       ],
      //     },
      //     {
      //       moduleName: "Magazin online",
      //       extend: false,
      //       subModules: [
      //         {
      //           name: "Echipe",
      //           checked: false,
      //         },
      //         { name: "Calendar", checked: false },
      //         { name: "Program meciuri", checked: false },
      //         { name: "Competiții", checked: false },
      //         { name: "Gestionare terenuri", checked: false },
      //         { name: "Cheltuieli meci", checked: false },
      //         { name: "Situație suspendări", checked: false },
      //         { name: "Liste A/B", checked: false },
      //       ],
      //     },
      //   ],
    };
  }

  componentDidMount = () => { };

  handleCheckTeam = (teamIndex) => {
    let allTeams = this.state.teamsAccess;
    allTeams[teamIndex].has_access = !allTeams[teamIndex].has_access;
    this.setState({ teamsAccess: allTeams });
  };

  handleCheckAllTeams = (checkAll) => {
    let allTeams = this.state.teamsAccess;

    if (checkAll) {
      allTeams.forEach((team) => (team.has_access = true));
    } else {
      allTeams.forEach((team) => (team.has_access = false));
    }
    this.setState({ teamsAccess: allTeams });
  };

  checkPhoneNumber = () => {
    const phoneNumber = this.state.phoneNumber;

    if (phoneNumber.indexOf("7") == 3 && phoneNumber.length == 12) return true;
    else return false;
  };

  editUser = () => {
    if (
      this.state.phoneNumber &&
      (!isValidPhoneNumber(this.state.phoneNumber) ||
        !this.checkPhoneNumber()) &&
      !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(this.state.email)
    ) {
      this.setState({ wrongPhoneNumber: true });
      this.setState({ wrongEmail: true });
    } else if (
      this.state.phoneNumber &&
      (!isValidPhoneNumber(this.state.phoneNumber) || !this.checkPhoneNumber())
    ) {
      this.setState({ wrongPhoneNumber: true });
      this.setState({ wrongEmail: false });
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(this.state.email)) {
      this.setState({ wrongEmail: true });
      this.setState({ wrongPhoneNumber: false });
    } else if (
      this.state.phoneNumber == null ||
      this.state.phoneNumber == undefined
    ) {
      this.setState({ wrongPhoneNumber: true });
    } else {
      const payload = {
        id_user: this.props.id_user_to_update,
        token: this.props.token,
        id_club: this.props.id_club,
        full_name: this.state.full_name,
        email: this.state.email,
        phone_number: this.state.phoneNumber,
      };

      if (this.state.teamsAccess.every((team) => team.has_access == true)) {
        payload.team_access = "full";
      } else {
        let checkedTeams = this.state.teamsAccess.filter(
          (team) => team.has_access == true
        );

        payload.team_access = "partial";
        payload.teams = checkedTeams;
      }

      axios
        .post("/user/update_user", payload)
        .then((res) => {
          if (res.data.success == 1) {
            store.addNotification({
              title: this.props.languagePack.users_management,
              message: this.props.languagePack.edit_user_success,
              type: "success",
              insert: "bottom",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
              },
            });
            this.props.refreshUsers();
          } else {
            store.addNotification({
              title: this.props.languagePack.users_management,
              message: this.props.languagePack.edit_user_danger,
              type: "danger",
              insert: "bottom",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
              },
            });
          }
        })
        .catch((err) => console.log(err));

      this.props.closePopup();
    }
  };

  render() {
    const { languagePack } = this.props;

    return (
      <div className="add-user-container">
        <div className="form-group">
          <div className="form-group-title">{languagePack.campaign_name}</div>
          <div className="input-wrapper" style={{ width: "100%" }}>
            <input
              type="text"
              value={this.state.full_name}
              onChange={(e) => this.setState({ full_name: e.target.value })}
              placeholder={languagePack.campaign_name}
              className="add-input"
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-group-title">{languagePack.Email}</div>
          <div className="input-wrapper" style={{ width: "100%" }}>
            <input
              type="text"
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
              placeholder={languagePack.Email}
              className="add-input"
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-group-title">
            {languagePack.admin_contact_phone}
          </div>
          <div className="input-wrapper" style={{ width: "100%" }}>
            <PhoneInput
              initialValueFormat="national"
              defaultCountry="RO"
              placeholder={languagePack.admin_contact_phone}
              value={this.state.phoneNumber}
              onChange={(e) => this.setState({ phoneNumber: e })}
              className="phone-number-input"
            />
          </div>
        </div>

        {this.state.wrongPhoneNumber && (
          <div class="ui red message">{languagePack.wrong_phone_number}</div>
        )}

        {this.state.wrongEmail && (
          <div class="ui red message">{languagePack.wrong_email}</div>
        )}

        <div className="team-access-wrapper">
          <div className="team-access-title">{languagePack.team_access}</div>
          <div className="teams-access">
            <div className="text-checkbox-container">
              <div className="checkbox">
                <Checkbox
                  checked={this.state.teamsAccess.every(
                    (team) => team.has_access == true
                  )}
                  onChange={(e, checkAll) => this.handleCheckAllTeams(checkAll)}
                />
              </div>
              <div className="all-teams-text">{languagePack.all_teams}</div>
            </div>

            {this.state.extendTeams ? (
              <div
                className="extend-icon-wrapper cursor-pointer"
                onClick={() => {
                  this.setState({ extendTeams: !this.state.extendTeams });
                }}
              >
                {" "}
                <TFBIcon name="angle-up" color="grey" className="extend-icon" />
              </div>
            ) : (
              <div
                className="extend-icon-wrapper cursor-pointer"
                onClick={() => {
                  this.setState({ extendTeams: !this.state.extendTeams });
                }}
              >
                {" "}
                <TFBIcon
                  name="angle-down"
                  color="grey"
                  className="extend-icon"
                />
              </div>
            )}
          </div>

          {this.state.extendTeams && (
            <div className="teams-enum">
              {this.state.teamsAccess.map((team, index) => (
                <div className="checkbox-text" key={"user-team-" + index}>
                  <Checkbox
                    checked={team.has_access}
                    onChange={() => this.handleCheckTeam(index)}
                  />
                  <div
                    className="team-name"
                    onClick={() => this.handleCheckTeam(index)}
                  >
                    {team.team_name}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* DON T DELETE  */}
        {/* <div className="module-access-container">
          <div className="module-access-title">{"Acces module"}</div>

          <div className="platform-modules-wrapper">
            {this.state.platformModules.map((module, index) => (
              <div className="module-access-wrapper" key={"module-" + index}>
                <div className="modules-access">
                  <div className="text-checkbox-container">
                    <div className="checkbox">
                      <Checkbox
                        checked={this.state.platformModules[
                          index
                        ].subModules.every(
                          (submodule) => submodule.checked == true
                        )}
                        onChange={(e, data) => {
                          let allModules = this.state.platformModules;
                          if (data) {
                            allModules[index].subModules.forEach(
                              (submodule) => (submodule.checked = true)
                            );
                          } else {
                            allModules[index].subModules.forEach(
                              (submodule) => (submodule.checked = false)
                            );
                          }
                          this.setState({ platformModules: allModules });
                        }}
                      />
                    </div>
                    <div className="module-icon">
                      <TFBIcon name="report" color="black" />
                    </div>
                    <div className="module-text">{module.moduleName}</div>
                  </div>

                  {this.state.platformModules[index].extend ? (
                    <div
                      className="extend-icon-wrapper"
                      onClick={() => {
                        let allModules = this.state.platformModules;
                        allModules[index].extend = !allModules[index].extend;
                        this.setState({ platformModules: allModules });
                      }}
                    >
                      {" "}
                      <TFBIcon
                        name="angle-up"
                        color="grey"
                        className="extend-icon"
                      />
                    </div>
                  ) : (
                    <div
                      className="extend-icon-wrapper"
                      onClick={() => {
                        let allModules = this.state.platformModules;
                        allModules[index].extend = !allModules[index].extend;
                        this.setState({ platformModules: allModules });
                      }}
                    >
                      {" "}
                      <TFBIcon
                        name="angle-down"
                        color="grey"
                        className="extend-icon"
                      />
                    </div>
                  )}
                </div>

                {this.state.platformModules[index].extend && (
                  <div className="submodule-enum">
                    {module.subModules.map((submodule, submoduleIndex) => (
                      <>
                        <div className="checkbox-text">
                          <Checkbox
                            checked={submodule.checked}
                            onClick={() => {
                              let allModules = this.state.platformModules;
                              allModules[index].subModules[
                                submoduleIndex
                              ].checked = !allModules[index].subModules[
                                submoduleIndex
                              ].checked;
                              this.setState({ platformModules: allModules });
                            }}
                          />
                          <div className="submodule-name">{submodule.name}</div>
                        </div>
                      </>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div> */}

        <div className="add-user-footer-buttons">
          <TFBButton
            color="darkGray"
            endIcon={true}
            style={{ width: "100%" }}
            renderIcon={() => <TFBIcon name="cancel" />}
            onClick={() => {
              this.props.closePopup();
              this.props.refreshUsers();
            }}
          >
            {languagePack.admin_cancel}
          </TFBButton>
          <TFBButton
            color="green"
            endIcon={true}
            style={{ width: "100%" }}
            renderIcon={() => <TFBIcon name="check" />}
            onClick={this.editUser}
            disabled={this.state.full_name == ""}
          >
            {languagePack.apply}
          </TFBButton>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(EditUser);
