import React from "react";
import SponsorBonuses from "./SponsorBonuses";
import SponsorPayment from "./SponsorPayment";
import axios from "axios";
import SponsorLastTransactions from "./SponsorLastTransactions";

class SponsorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seasons: [],
      sponsor_name: "",
      barter: 0,
    };
  }

  componentDidMount = () => {
    axios
      .get(`finances/sponsor_general_details?id=${this.props.match.params.id}`)
      .then((res) =>
        this.setState({
          seasons: res.data.seasons,
          sponsor_name: res.data.sponsor_name,
          barter: res.data.barter,
        })
      );
  };

  render() {
    return (
      <div>
        <div className="sponsor-page-header">
          <div className="sponsor-name">
            {this.state.barter == 0 ? (
              <div> Profil sponsor - </div>
            ) : (
              <div>Profil barter -</div>
            )}
            {this.state.sponsor_name}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="card card-custom">
              <SponsorBonuses
                id_sponsor={this.props.match.params.id}
                seasons={this.state.seasons}
              />
            </div>
            <div className="card card-custom" style={{ marginTop: 25 }}>
              <SponsorLastTransactions
                id_sponsor={this.props.match.params.id}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card card-custom">
              <SponsorPayment
                id_sponsor={this.props.match.params.id}
                seasons={this.state.seasons}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SponsorPage;
