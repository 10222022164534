import React, { useEffect, useState } from 'react';
import './PrepareNextMatch.scss';
import Moment from 'react-moment';
import axios from 'axios';
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";

function UnavailablePlayers(props) {
    const [players, setPlayers] = useState([]);
    const [option, setOption] = useState("injured");

    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        axios.get(`match/get_unavailable_players?option=${option}&id_club=${props.currentUser.id_club}`)
            .then((res) => setPlayers(res.data))
            .catch((err) => console.log(err));
    }

    return (
        <div className="unavailable-players-container">
            <div className="unavailable-players-menu">
                <div className="menu-option" style={{ backgroundColor: option === "injured" ? '#fafafa' : 'white' }} onClick={() => setOption('injured')}>Accidentati</div>
                <div className="menu-option" style={{ backgroundColor: option === "suspended" ? '#fafafa' : 'white' }} onClick={() => setOption('suspended')}>Suspendati</div>
            </div>
            {option === "injured" ?
                <div className="injured-players-container">
                    <div className="injured-player-row-table-header">
                        <div className="player-name">Nume jucator</div>
                        <div className="injury-zone">Zona</div>
                        <div className="injury-date">Revenire estimata</div>
                    </div>
                    {players.map((item, index) => {
                        return (
                            <div className="injured-player-row" style={{
                                backgroundColor: index % 2 == 0 ? "white" : "#fafafa",
                            }}>
                                <div className="player-name">
                                    <Avatar
                                        src={`${window.baseURL}uploads/players/${item.id_player}.jpg`}
                                        className="player-avatar"
                                    />
                                    <div>{item.player_name}</div>
                                </div>
                                <div className="injury-zone">{props.languagePack[item.injury_type]}</div>
                                <div className="injury-date"><Moment format="DD.MM.YYYY">{item.estimated_recovery_date}</Moment></div>

                            </div>
                        )
                    })}
                </div>
                : <div className="suspended-players-container"></div>
            }
        </div>
    )
}

export default connect(
    ({ layoutService, auth }) => ({ layoutService, auth }) => ({
        languagePack: layoutService.languagePack,
        currentUser: auth.user,
        token: auth?.user?.token,
    })
)(UnavailablePlayers);