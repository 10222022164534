import React from "react";
import axios from "axios";
import { Dropdown, Button, Icon, Checkbox } from "semantic-ui-react";
import "../../Club/Club.styles.scss";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { store } from "react-notifications-component";

class AddEvaluationCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category_name: "",
      subcategories: [{ subcategory_name: "" }],
    };
  }

  addNewVenue = () => {
    const payload = {
      category_name: this.state.category_name,
      subcategories: this.state.subcategories,
      id_club: this.props.id_club,
    };

    axios
      .post("report/add_evaluation_category", payload)
      .then((res) => {
        if (res.data == 1) {
          this.props.getCategories();
          store.addNotification({
            title: "Evaluare raport",
            message: "Categoria de evaluare a fost adaugata cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: "Evaluare raport",
            message: "Categoria de evaluare nu a fost adaugata cu succes!",
            type: "warning",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        this.props.closePopup();
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="add-player-form">
        <div className="add-player-header">
          <div className="add-player-title">{languagePack.add_category}</div>
          <div className="add-player-popup-close">
            {" "}
            <Icon
              name="close"
              className="close-icon-popup"
              onClick={() => this.props.closePopup()}
            />
          </div>
        </div>
        <div className="add-player-row">
          <input
            className="add-video-title"
            placeholder={languagePack.designation}
            value={this.state.category_name}
            onChange={(event) => {
              this.setState({ category_name: event.target.value });
            }}
          />
        </div>

        {this.state.subcategories.map((elem, index) => {
          return (
            <div
              className="add-player-row"
              style={{
                marginBottom:
                  index == this.state.subcategories.length - 1 ? "0" : "",
              }}
              key={"category-child-" + index}
            >
              <input
                className="add-video-title"
                placeholder={languagePack.subcategory_designation}
                value={elem.subcategory_name}
                onChange={(event) => {
                  let subcategories = this.state.subcategories;
                  subcategories[index].subcategory_name = event.target.value;

                  this.setState({ subcategories: subcategories });
                }}
              />

              {this.state.subcategories.length != 1 && (
                <Icon
                  name="minus"
                  size="large"
                  className="add-icon"
                  color="red"
                  onClick={() => {
                    let subcategories = this.state.subcategories;
                    subcategories.splice(index, 1);
                    this.setState({ subcategories: subcategories });
                  }}
                />
              )}
            </div>
          );
        })}
        {
          <div
            className="add-new-sub-venue"
            onClick={() => {
              let subcategories = this.state.subcategories;
              subcategories.push({ subcategory_name: "" });
              this.setState({ subcategories: subcategories });
            }}
          >
            {languagePack.add_new_subcategory}
          </div>
        }
        <div className="add-player-buttons">
          <Button
            className="custom-button-dual add-button"
            onClick={this.addNewVenue}
            disabled={
              this.state.category_name.length > 0
                ? this.state.subcategories.length > 0
                  ? !this.state.subcategories.every((elem) => {
                      if (elem.subcategory_name != "") {
                        return true;
                      }
                      return false;
                    })
                  : false
                : true
            }
          >
            {" "}
            <div className="button-content">
              {languagePack["admin_add"]}
              <Icon name="add" size="small" className="add-icon" />
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default AddEvaluationCategory;
