import React, { Component } from "react";
import "../AddMatch/AddMatchForm.scss";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import moment from "moment";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import TFBDateTimePicker from "../../../../components/design-system/DateTimePicker/TFBDateTimePicker";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";
import dayjs from "dayjs";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import MatchResult from "../../../../components/reusable/MatchResults/MatchResult";

class EditMatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      match_date: null,
      location: "",
      locations: [],
      new_location: "",

      add_new_location: false,
      add_venue_to_mng: false,
      newR: "",
      newL: "",
      newRHalf: "",
      newLHalf: "",
      homeTeam: "",
      awayTeam: "",
      homeTeamId: "",
      awayTeamId: "",
      minutes_per_time: "",

      approximate_date: false,

      match_formats: [],
      selected_match_format: "",
      other_match_format: false,
      new_match_format: "",

      isFetching: true,
    };
  }

  componentDidMount = () => {
    this.getLocations();
    this.getMatchFormats();
  };

  getMatchFormats = () => {
    axios
      .get(`match/manual_match_formats`)
      .then((res) => {
        let matchFormats = [
          ...res.data.formats,
          { value: "0", label: "Alt format" },
        ];
        this.setState({ match_formats: matchFormats }, () =>
          this.getMatchBasicInfo()
        );
      })
      .catch((err) => console.log(err));
  };

  getMatchBasicInfo = () => {
    if (this.props.idMatch) {
      axios
        .get(`match/get_manual_match_basic_info?id_match=${this.props.idMatch}`)
        .then((res) => {
          let matchLocation = {};

          if (res.data.id_venue > 0) {
            let locationsIds = [];
            this.state.locations.forEach((venue) => {
              locationsIds.push(venue.value);
            });

            let matchLocationId = locationsIds.find(
              (venue) => venue == res.data.id_venue
            );
            let indexOfVanue = locationsIds.indexOf(matchLocationId);
            matchLocation = this.state.locations[indexOfVanue];
          }

          let selectedMatchFormat;
          if (res.data.match_format == null) {
            selectedMatchFormat = "";
          } else if (res.data.match_format == 0) {
            selectedMatchFormat = {
              value: res.data.match_format,
              label: res.data.another_format_name,
            };
          } else {
            let findMatchFormat = this.state.match_formats.find(
              (match) => match.value == res.data?.match_format
            );
            selectedMatchFormat = findMatchFormat;
          }

          this.setState(
            {
              match_date: dayjs(res.data.match_date),
              location: res.data.id_venue > 0 ? matchLocation : "",
              newL: res.data.team_home_goals,
              newR: res.data.team_away_goals,
              newLHalf: res.data.home_team_goals_half_time,
              newRHalf: res.data.team_away_goals_half_time,
              homeTeam: res.data.home_team_name,
              awayTeam: res.data.away_team_name,
              homeTeamId: res.data.id_team_home,
              awayTeamId: res.data.id_team_away,
              approximate_date: { 1: true, 0: false }[
                res.data.approximate_date
              ],
              minutes_per_time: res.data.minutes_per_time,
              selected_match_format: selectedMatchFormat,
            },
            () => this.setState({ isFetching: false })
          );
        })
        .catch((err) => console.log(err));
    }
  };

  getLocations = () => {
    axios
      .get(`site/get_locations?id_club=${this.props.currentUser.id_club}`)
      .then((res) => {
        let venuesArray = [{ value: "0", label: "Adauga locatie noua" }];
        res.data.forEach((venue) => {
          venuesArray.push({
            value: venue.id_venue,
            label: venue.venue_name,
          });
        });
        this.setState({ locations: venuesArray });
      })
      .catch((err) => console.log(err));
  };

  handleChangeMatchFormat = (selectedFormat) => {
    if (selectedFormat.value == "0") {
      this.setState({
        selected_match_format: selectedFormat,
        other_match_format: true,
      });
      return;
    }
    this.setState({
      selected_match_format: selectedFormat,
      other_match_format: false,
    });
  };

  handleDateChange = (value) => {
    this.setState({ match_date: value });
  };

  handleChangeMinutesPerTime = (e) => {
    e.preventDefault();
    let numbers = /^\s*\d*\s*$/;
    if (e.target.value.match(numbers)) {
      this.setState({ minutes_per_time: e.target.value });
    }
  };

  handleChangeL = (e) => {
    this.setState({
      newL: e.target.value,
    });
  };

  handleChangeR = (e) => {
    this.setState({
      newR: e.target.value,
    });
  };

  handleChangeLHalf = (e) => {
    this.setState({
      newLHalf: e.target.value,
    });
  };

  handleChangeRHalf = (e) => {
    this.setState({
      newRHalf: e.target.value,
    });
  };

  renderBtn = (position, onClick) => {
    return (
      <TFBButton
        onClick={onClick}
        endIcon={true}
        color={position == "left" ? "darkGray" : "green"}
        renderIcon={() => (
          <TFBIcon name={position == "left" ? "cancel" : "add"} />
        )}
        // prettier-ignore
        disabled={
          (position == "right" &&
           ((this.state.other_match_format &&
            (this.state.new_match_format == null ||
              this.state.new_match_format == "")) ||
          (this.state.add_new_location &&
            (this.state.new_location == null || this.state.new_location == ""))))
        }
      >
        {position == "left"
          ? this.props.languagePack.Cancel
          : this.props.languagePack.admin_save}
      </TFBButton>
    );
  };

  saveMatchChanges = () => {
    let payload = {
      id_match: this.props.idMatch,
      match_date: moment(this.state.match_date.$d).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      approximate_date: { true: 1, false: 0 }[this.state.approximate_date],
      id_club: this.props.currentUser.id_club,
      team_home_goals: Number.isInteger(parseInt(this.state.newL))
        ? this.state.newL
        : null,
      team_away_goals: Number.isInteger(parseInt(this.state.newR))
        ? this.state.newR
        : null,
      home_team_goals_half_time: Number.isInteger(parseInt(this.state.newLHalf))
        ? this.state.newLHalf
        : null,
      team_away_goals_half_time: Number.isInteger(parseInt(this.state.newRHalf))
        ? this.state.newRHalf
        : null,
      minutes_per_time: this.state.minutes_per_time,
    };

    payload.match_format = this.state.selected_match_format.value;
    payload.another_format_name = this.state.other_match_format
      ? this.state.new_match_format
      : null;

    if (this.state.add_new_location) {
      payload.venue_name = this.state.new_location;
      payload.add_venue_to_mng = { false: "0", true: "1" }[
        this.state.add_venue_to_mng
      ];
    } else {
      payload.id_venue = this.state.location.value;
    }

    axios.post("match/update_match", payload).then(() => {
      store.addNotification({
        title: this.props.languagePack["fixtures_schedule"],
        message: "Meciul a fost actualizat cu succes!",
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
      this.props.getMatches();
      this.props.closePopup();
      this.props.refreshGroupsResults();
    });
  };

  render() {
    const { languagePack } = this.props;
    return (
      <>
        {!this.state.isFetching && (
          <TFBDialog
            open={this.props.openPopup}
            fullWidth={true}
            maxWidth="sm"
            closePopup={() => this.props.closePopup()}
            title={languagePack.edit_match}
            btnRight={() =>
              this.renderBtn("right", () => this.saveMatchChanges())
            }
            btnLeft={() =>
              this.renderBtn("left", () => this.props.closePopup())
            }
          >
            <div className="add-match-container">
              <div className="add-match-row">
                <TFBDateTimePicker
                  label={languagePack["match_date"]}
                  fullWidth={true}
                  value={this.state.match_date}
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="add-match-row">
                <div
                  className="add-checkbox-input"
                  onClick={() =>
                    this.setState({
                      approximate_date: !this.state.approximate_date,
                    })
                  }
                >
                  <TFBCheckbox
                    label={languagePack.date_and_time_are_approximate}
                    // startLabel
                    onChange={(e) =>
                      this.setState({
                        approximate_date: e.target.checked,
                      })
                    }
                    checked={this.state.approximate_date}
                  />
                </div>
              </div>
              <div className="add-match-row">
                <TFBSelect
                  placeholder={languagePack["select_match_venue"]}
                  label={languagePack["select_match_venue"]}
                  options={this.state.locations}
                  value={this.state.location}
                  onChange={(selected) => {
                    if (selected.value == "0") {
                      this.setState({ add_new_location: true });
                      this.setState({ location: selected });
                      this.setState({ new_location: "" });
                    } else {
                      this.setState({ add_new_location: false });
                      this.setState({ location: selected });
                    }
                  }}
                />
              </div>

              {this.state.add_new_location && (
                <>
                  <div className="add-match-row">
                    <TFBInput
                      placeholder={languagePack["new_match_venue"]}
                      label={languagePack["new_match_venue"]}
                      value={this.state.new_location}
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        this.setState({ new_location: e.target.value })
                      }
                    />
                  </div>
                  <div className="add-match-row">
                    <div
                      style={{ width: "100%", maxWidth: "355px" }}
                      onClick={() =>
                        this.setState({
                          add_venue_to_mng: !this.state.add_venue_to_mng,
                        })
                      }
                    >
                      <TFBCheckbox
                        label={languagePack.add_location_to_field_management}
                        onChange={(e) => {
                          this.setState({
                            add_venue_to_mng: e.target.checked,
                          });
                        }}
                        checked={this.state.add_venue_to_mng}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="add-match-row">
                <TFBInput
                  placeholder={languagePack.minutes_per_time}
                  label={languagePack.minutes_per_time}
                  value={this.state.minutes_per_time}
                  style={{ width: "100%" }}
                  type="number"
                  min="0"
                  onChange={(e) => this.handleChangeMinutesPerTime(e)}
                />
              </div>
              <div className="add-match-row">
                <TFBSelect
                  placeholder={languagePack.match_format}
                  label={languagePack.match_format}
                  options={this.state.match_formats}
                  value={this.state.selected_match_format}
                  onChange={(selected) =>
                    this.handleChangeMatchFormat(selected)
                  }
                />
              </div>

              {this.state.other_match_format && (
                <div className="add-match-row">
                  <TFBInput
                    placeholder={languagePack.add_match_format}
                    label={languagePack.add_match_format}
                    value={this.state.new_match_format}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      this.setState({ new_match_format: e.target.value });
                    }}
                  />
                </div>
              )}

              {this.props.type == "past" && (
                <div
                  style={{
                    gap: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <MatchResult
                    type="half"
                    homeScore={this.state.newLHalf}
                    awayScore={this.state.newRHalf}
                    homeTeamName={this.state.homeTeam}
                    awayTeamName={this.state.awayTeam}
                    onChangeHomeScore={this.handleChangeLHalf}
                    onChangeAwayScore={this.handleChangeRHalf}
                  />

                  <MatchResult
                    type="final"
                    homeScore={this.state.newL}
                    awayScore={this.state.newR}
                    homeTeamName={this.state.homeTeam}
                    awayTeamName={this.state.awayTeam}
                    onChangeHomeScore={this.handleChangeL}
                    onChangeAwayScore={this.handleChangeR}
                  />
                </div>
              )}
            </div>
          </TFBDialog>
        )}
      </>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(EditMatch);
