import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { logout } from "./../../utils/api";
import Moment from "react-moment";
import moment from "moment";
import { Icon, Flag, Button, Dropdown } from "semantic-ui-react";
import Avatar from "@material-ui/core/Avatar";
import { bindActionCreators } from "redux";
import CircularLoader from "../../components/reusable/Loader";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye-white.svg";
import { store } from "react-notifications-component";

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isFetching: false,
      addPlayerDialog: false,
      sortConfig: {
        type: "",
        descending: false,
      },
      fetchingError: false
    };
  }

  componentDidMount = () => {
    this.getUsers();
  };

  getUsers = () => {
    this.setState({ isFetching: true });
    axios
      .get(`user`, {
        params: {
            token: this.props.token,
            id_club: this.props.currentUser.id_club,
        }
      })
      .then((res) => {
        if (res?.data?.success == 0) {
            this.setState({
                fetchingError: this.props.languagePack[res.data.message],
            });
        } else {
            this.setState({
                users: Array.isArray(res.data) ? res.data : []
            });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({
          isFetching: false,
        });
      });
  };

  render() {
    const { languagePack } = this.props;

    return (
      <div>
        {this.state.fetchingError === false ? (
          <div className="card card-custom card-user-list" style={{ padding: 50 }}>
            <div className="team-page-header">
              <div className="team-page-title">
                Users
                {/* <Icon
                  name="trash"
                  size="small"
                  className="edit-team-name-icon"
                  onClick={() => this.setState({ deleteTeamDialog: true })}
                /> */}
              </div>
              <div className="team-page-header-right">
                {/* <Dropdown
                  placeholder="Season"
                  selection
                  options={this.state.seasons}
                  className="players-season-dropdown"
                  value={this.state.id_season}
                  onChange={this.handleChangeDropdown}
                  name="id_season"
                /> */}
              </div>
            </div>

            {this.state.isFetching ? (
              <div className="flex-all">
                <CircularLoader />
              </div>
            ) : (
              <>
                {this.state.users.length > 0 ? (
                  <div className="club-players-table">
                    <div className="club-players-table-header">
                      <div className="player-name">
                        {"Name"}
                      </div>
                    </div>
                    <div className="club-players-table-list">
                      {this.state.users.map((user, index) => (
                        <div
                          className="club-players-table-row"
                          style={{
                            backgroundColor:
                              index % 2 == 0 ? "#fafafa" : "white",
                          }}
                        >
                          <div className="player-name">
                            <div
                              onClick={() =>
                                this.props.history.push(
                                  `/user/${user.id_user}`,
                                  this.props.token
                                )
                              }
                              style={{ cursor: "pointer" }}
                              key={index}
                            >
                              {user.full_name}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="placeholder-files">
                    <Icon
                      name="search minus"
                      size="huge"
                      className="placeholder-icon-team-page"
                    />
                    <div className="placeholder-text">
                      {languagePack["no_players_placeholder"]}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <div className="access-error-team-page">
            <Icon name="ban" size="large" className="access-error-icon" />
            {this.state.fetchingError}
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(UserList);
