import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import "./UserList.scss";
import { Button, Icon } from "semantic-ui-react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddMobileUser from "./AddMobileUser";
import EditMobileUser from "./EditMobileUser";
import DeleteMobileUser from "./DeleteMobileUser";
import ActivateMobileUser from "./ActivateMobileUser";
import DesactivateMobileUser from "./DesactivateMobileUser";

class UserListMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileUsers: [],
      userToDelete: "",
      optionsPopupOpen: false,
      openEditRelative: false,
      openDeleteRelative: false,
      id_userToUpdate: "",

      openActivateUserPopup: false,
      openDesactivateUserPopup: false,
      idUserToActivate: "",
      is_player: "",
      id_player: null,
    };
  }

  getMobileUsers = () => {
    axios
      .get(
        `parentsApp/get_parents_list?id_club=${this.props.currentUser.id_club}&token=${this.props.token}`
      )
      .then((res) => this.setState({ mobileUsers: res.data }))
      .catch((err) => console.log(err));
  };

  componentDidMount() {
    this.getMobileUsers();
  }

  render() {
    const { languagePack } = this.props;
    return (
      <div className="card card-custom" style={{ padding: 50 }}>
        <div className="shortlists-page-container">
          <div className="shortlists-header">
            <div className="shortlists-title">{languagePack.users_list}</div>
            <Button
              className="create-shortlist-button"
              onClick={() => this.setState({ optionsPopupOpen: true })}
            >
              {languagePack.add_parent}

              <Icon name="add" className="shortlist-button-icon" />
            </Button>
          </div>
          <div className="shortlists-list-header">
            <div className="user-name-header">{languagePack.campaign_name}</div>
            <div className="user-col">{languagePack.player_players_name}</div>
            <div className="user-col">{languagePack.admin_contact_phone}</div>
            <div className="user-date">{languagePack.Email}</div>
            <div className="user-date">
              {languagePack.current_status_payment}
            </div>
            <div className="user-list-mobile-buttons"></div>
          </div>
          <div className="shortlists-list">
            {this.state.mobileUsers.map((item, index) => (
              <div
                className="shortlist-row"
                key={index}
                style={{
                  backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                }}
              >
                <div className="user-name-relationship">
                  <div className="user-name">{item.name}</div>
                  <div className="user-relationship">{item.relationships}</div>
                </div>

                <div className="user-col">{item.players}</div>
                <div className="user-col">{item.phone_number}</div>
                <div className="user-date">{item.email}</div>

                <div className="user-date">
                  {item.is_recurrent == 1 && item.is_player == "0" && (
                    <Icon
                      name="check"
                      size="large"
                      style={{ color: "#00D948" }}
                    />
                  )}

                  {item.is_recurrent != 1 && item.is_player == "0" && (
                    <Icon name="close" size="large" />
                  )}
                </div>

                <div className="user-list-mobile-buttons">
                  {item.is_accepted == 1 ? (
                    <div
                      className="edit-user-activate-button"
                      onClick={() =>
                        this.setState({
                          openDesactivateUserPopup: true,
                          idUserToDesactivate: item.id_user_external,
                        })
                      }
                    >
                      <Icon name="circle" style={{ color: "#00D948" }} />
                    </div>
                  ) : (
                    <div
                      className="edit-user-activate-button"
                      onClick={() =>
                        this.setState({
                          openActivateUserPopup: true,
                          idUserToActivate: item.id_user_external,
                          is_player: item.is_player,
                        })
                      }
                    >
                      <Icon name="circle" />
                    </div>
                  )}
                  <div
                    className="edit-user-button"
                    onClick={() =>
                      this.setState({
                        openEditRelative: true,
                        id_userToUpdate: item.id_user_external,
                        is_player: item.is_player,
                        id_player: item.id_player,
                      })
                    }
                  >
                    <Icon name="edit" className="white-icon" />
                  </div>
                  <div
                    className="delete-user-button"
                    onClick={() =>
                      this.setState({
                        openDeleteRelative: true,
                        userToDelete: item.id_user_external,
                        is_player: item.is_player,
                      })
                    }
                  >
                    <Icon name="trash alternate" className="white-icon" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Dialog open={this.state.optionsPopupOpen} maxWidth="xl">
          <div
            className="bonus-options-list-popup-header"
            style={{ paddingLeft: 50 }}
          >
            <div className="bonus-options-list-title">
              {languagePack.add_parent}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={() => this.setState({ optionsPopupOpen: false })}
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <DialogContent>
            <AddMobileUser
              id_club={this.props.currentUser.id_club}
              refreshUsers={this.getMobileUsers}
              departments={this.state.departments}
              closePopup={() => this.setState({ optionsPopupOpen: false })}
            />
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.openEditRelative} maxWidth="xl">
          <div
            className="bonus-options-list-popup-header"
            style={{ paddingLeft: 50 }}
          >
            <div className="bonus-options-list-title">
              {this.state.is_player == 1
                ? languagePack.edit_player
                : languagePack.edit_relative}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={() => this.setState({ openEditRelative: false })}
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <DialogContent>
            <EditMobileUser
              id_club={this.props.currentUser.id_club}
              refreshUsers={this.getMobileUsers}
              id_userToUpdate={this.state.id_userToUpdate}
              is_player={this.state.is_player}
              id_player={this.state.id_player}
              closePopup={() => this.setState({ openEditRelative: false })}
            />
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.openDeleteRelative} maxWidth="xl">
          <DialogContent>
            <DeleteMobileUser
              refreshUsers={this.getMobileUsers}
              userToDelete={this.state.userToDelete}
              closePopup={() => this.setState({ openDeleteRelative: false })}
              is_player={this.state.is_player}
            />
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.openActivateUserPopup} maxWidth="xl">
          <DialogContent>
            <ActivateMobileUser
              refreshUsers={this.getMobileUsers}
              id_user_to_activate={this.state.idUserToActivate}
              closePopup={() => this.setState({ openActivateUserPopup: false })}
            />
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.openDesactivateUserPopup} maxWidth="xl">
          <DialogContent>
            <DesactivateMobileUser
              refreshUsers={this.getMobileUsers}
              id_user_to_desactivate={this.state.idUserToDesactivate}
              closePopup={() =>
                this.setState({ openDesactivateUserPopup: false })
              }
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(UserListMobile);
