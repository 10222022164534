import React from "react";
import styles from "./TFBFileInput.module.scss";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { TFileInput } from "../types";
import TFBIcon from "../Icon/TFBIcon";

const TFBFileInput = ({
    label,
    accept = "*/*",
    multiple = false,
    files,
    onChange,
    placeholder = "Select file",
    style,
    className,
}: TFileInput) => {
    return (
        <div className={styles.inputGroup}>
            <h6 className={styles.inputLabel}>{label}</h6>
            <div className={styles.tfbInputFileContainer}>
                <input
                    id="tfbInputFile"
                    type="file"
                    accept={accept}
                    multiple={multiple}
                    onChange={onChange}
                />
                <label htmlFor="tfbInputFile">
                    {Array.isArray(files) && Array.from(files).length > 0 ? Array.from(files).join(",\r\n") : placeholder}
                </label>
                <TFBIcon
                    name="arrow-up"
                    color="gray"
                />
            </div>
        </div>
    );
};

export default TFBFileInput;
