import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import "./Matches.scss";
import { logout } from "./../../../utils/api";
import { bindActionCreators } from "redux";
import ManualLastMatches from "./List/ManualLastMatches";
import ManualNextMatches from "./List/ManualNextMatches";
import AddMatchFormDirect from "./AddMatch/AddMatchFormDirect";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBDropdown from "../../../components/design-system/Dropdown/TFBDropdown";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";

class Matches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: new URLSearchParams(this.props.location.search).get("type"),
      teams_dropdown: [],
      rounds_dropdown: [],
      competitions_dropdown: [],
      selected_team: "",
      selected_round: "",
      selected_competition: "",
      addMatchPopup: false,
      refreshTimes: 0,
    };
  }

  componentDidMount = () => {
    this.getTeamsDropdown();
    this.getCompsDropdown();
  };

  getTeamsDropdown = () => {
    axios
      .get(
        `club/get_teams_dropdown?token=${this.props.token}&id_club=${this.props.currentUser.id_club}&all=1&id_user=${this.props.currentUser.id_user}`
      )
      .then((res) =>
        this.setState({
          teams_dropdown: Array.isArray(res.data) ? res.data : [],
          selected_team: res.data.length > 0 ? res.data[0]["value"] : "",
        })
      )
      .catch((err) => console.log(err));
  };

  getCompsDropdown = () => {
    axios
      .get(`match/get_competitions?id_club=${this.props.currentUser.id_club}`)
      .then((res) =>
        this.setState({
          competitions_dropdown: Array.isArray(res.data) ? res.data : [],
        })
      )
      .catch((err) => console.log(err));
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value });
  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { languagePack } = this.props;
    return (
      <TFBCard>
        <TFBCardHeader title={languagePack["fixtures_schedule"]}>
          <TFBDropdown
            selection
            options={this.state.teams_dropdown}
            value={this.state.selected_team}
            className="team-dropdown"
            name="selected_team"
            onChange={this.handleChangeDropdown}
            placeholder={languagePack["select_team"]}
          />
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="add" color="white" />}
            onClick={() => this.setState({ addMatchPopup: true })}
          >
            {languagePack["add_match"]}
          </TFBButton>
        </TFBCardHeader>
        <TFBCardBody>
          <div className="matches-menu-options">
            <div
              className="matches-option"
              onClick={() => {
                this.setState({ activeItem: "past" });
                let url = this.props.location.search;
                let search_params = new URLSearchParams(url);
                search_params.set("type", "past");
                window.history.replaceState(
                  null,
                  null,
                  "?" + search_params.toString()
                );
              }}
              style={{
                backgroundColor:
                  this.state.activeItem === "past" ? "#fafafa" : "",
              }}
            >
              <div>{languagePack["past_matches"]}</div>
            </div>
            <div
              className="matches-option"
              onClick={() => {
                this.setState({ activeItem: "future" });
                let url = this.props.location.search;
                let search_params = new URLSearchParams(url);
                search_params.set("type", "future");
                window.history.replaceState(
                  null,
                  null,
                  "?" + search_params.toString()
                );
              }}
              style={{
                backgroundColor:
                  this.state.activeItem === "future" ? "#fafafa" : "",
              }}
            >
              <div>{languagePack["future_matches"]}</div>
            </div>
          </div>

          {this.state.activeItem === "past" ? (
            <ManualLastMatches
              id_team={this.state.selected_team}
              refreshTimes={this.state.refreshTimes}
            />
          ) : (
            <ManualNextMatches
              id_team={this.state.selected_team}
              refreshTimes={this.state.refreshTimes}
            />
          )}
        </TFBCardBody>

        <Dialog open={this.state.addMatchPopup} fullWidth maxWidth="sm">
          <DialogContent className="add-team-manual-wrapper">
            <AddMatchFormDirect
              closePopup={() => this.setState({ addMatchPopup: false })}
              getMatches={() => {
                this.setState({ refreshTimes: this.state.refreshTimes + 1 });
              }}
            />
          </DialogContent>
        </Dialog>
      </TFBCard>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(Matches);
