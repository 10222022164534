import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logout } from "./../../../utils/api";
import axios from "axios";
import moment, { lang } from "moment";
import "./UserList.scss";
import { store } from "react-notifications-component";
import { Button, Input, Icon } from "semantic-ui-react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

class EditMobileUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      surname: "",
      phoneNumber: "",
      email: "",

      wrongPhoneNumber: false,
      wrongEmail: false,

      playersDropdown: [],
      selectedPlayer: "",
      selectedRelative: "",
      relativesDropdown: [],
      label_entities_loaded: true,
      additional_labels: [],

      marginTop: "initial",
    };
  }

  componentDidMount = () => {
    this.getPlayersDropdown();
    this.getRelatives();
    this.getUserToUpdate();
  };

  checkPhoneNumber = () => {
    const phoneNumber = this.state.phoneNumber;

    if (phoneNumber.indexOf("7") == 3 && phoneNumber.length == 12) return true;
    else return false;
  };

  getUserToUpdate = () => {
    axios
      .get(
        `parentsApp/get_edit_external_user?id_club=${this.props.id_club}&id_user_external=${this.props.id_userToUpdate}`
      )
      .then((res) => {
        const arrayAditionalPlayers = res.data.players.slice(1);

        let aditionalLabelsArray = [];
        arrayAditionalPlayers.forEach((label) => {
          aditionalLabelsArray.push({
            label_entities_loaded: true,
            selectedPlayer: {
              label: label.player_name,
              value: label.id_player,
            },
            selectedRelative: {
              label: label.relationship_name,
              value: label.relationship,
            },
          });
        });

        this.setState({
          name: res.data.name,
          surname: res.data.surname,
          phoneNumber: res.data.phone_number,
          email: res.data.email,

          selectedPlayer: {
            label: res.data.players[0].player_name,
            value: res.data.players[0].id_player,
          },
          selectedRelative: {
            label: res.data.players[0].relationship_name,
            value: res.data.players[0].relationship,
          },

          additional_labels: aditionalLabelsArray,
        });
      })
      .catch((err) => console.log(err));
  };

  getPlayersDropdown = () => {
    axios
      .get(
        `parentsApp/get_players?id_club=${this.props.id_club}&token=${this.props.token}`
      )
      .then((res) =>
        this.setState({
          playersDropdown: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  getRelatives = () => {
    axios
      .get(
        `parentsApp/get_relationships?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) =>
        this.setState({
          relativesDropdown: res.data,
          label_entities_loaded: true,
        })
      )
      .catch((err) => console.log(err));
  };

  handleChangeAdditionalLabel = (index, label) => {
    let currentAdditionalLabels = this.state.additional_labels;
    currentAdditionalLabels[index] = {
      selectedPlayer: label,
      selectedRelative: "",
      label_entities_loaded: false,
    };
    this.setState(
      {
        additional_labels: currentAdditionalLabels,
      },
      () => {
        let currentAdditionalLabels = this.state.additional_labels;
        axios
          .get(
            `parentsApp/get_relationships?id_club=${this.props.currentUser.id_club}`
          )
          .then((res) => {
            let relativesDropdown = res.data;
            currentAdditionalLabels[index] = {
              relativesDropdown: relativesDropdown,
              selectedPlayer: label,
              selectedRelative: "",
              label_entities_loaded: true,
            };
            this.setState({
              additional_labels: currentAdditionalLabels,
            });
          })
          .catch((err) => console.log(err));
      }
    );
  };

  onChangePlayerContract = (e) => {
    this.setState({
      player_contract: e.target.files[0],
    });
  };

  addMobileUser = () => {
    if (
      this.state.phoneNumber &&
      (!isValidPhoneNumber(this.state.phoneNumber) ||
        !this.checkPhoneNumber()) &&
      !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(this.state.email)
    ) {
      this.setState({ wrongPhoneNumber: true });
      this.setState({ wrongEmail: true });
    } else if (
      this.state.phoneNumber &&
      (!isValidPhoneNumber(this.state.phoneNumber) || !this.checkPhoneNumber())
    ) {
      this.setState({ wrongPhoneNumber: true });
      this.setState({ wrongEmail: false });
    } else if (
      !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(this.state.email) &&
      this.props.is_player == "0"
    ) {
      this.setState({ wrongEmail: true });
      this.setState({ wrongPhoneNumber: false });
    } else {
      let playersArray = [];
      //get short name for match
      playersArray.push({
        id_player: this.state.selectedPlayer.value,
        relationship: this.state.selectedRelative.value,
      });

      this.state.additional_labels.forEach((label) => {
        playersArray.push({
          id_player: label.selectedPlayer.value,
          relationship: label.selectedRelative.value,
        });
      });

      const payload = {
        id_club: this.props.id_club,
        name: this.state.name,
        surname: this.state.surname,
        phone_number: this.state.phoneNumber,
        email: this.state.email,
        players: playersArray,
        update_user_external: this.props.id_userToUpdate,
      };

      if (this.props.is_player == "1") {
        payload.id_player = this.props.id_player;
      }

      axios
        .post("parentsApp/add_parent_2", payload)
        .then((res) => {
          if (res.data.success == 1 && res.data.success_phone_number == 1) {
            this.props.refreshUsers();
            this.props.closePopup();
            store.addNotification({
              title: this.props.languagePack.notif_edit_external_user,
              message: this.props.languagePack.edit_user_success_message,
              type: "success",
              insert: "bottom",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
              },
            });
          } else if (
            res.data.success_phone_number == 0 &&
            res.data.error_code == "used_phone_number_by_another_player"
          ) {
            store.addNotification({
              title: this.props.languagePack.notif_edit_external_user,
              message: this.props.languagePack
                .used_phone_number_by_another_player,
              type: "danger",
              insert: "bottom",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
              },
            });
          } else if (
            res.data.success_phone_number == 0 &&
            res.data.error_code == "used_phone_number_by_another_user"
          ) {
            store.addNotification({
              title: this.props.languagePack.notif_edit_external_user,
              message: this.props.languagePack
                .used_phone_number_by_another_user,
              type: "danger",
              insert: "bottom",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
              },
            });
          } else {
            store.addNotification({
              title: this.props.languagePack.notif_edit_external_user,
              message: this.props.languagePack.edit_user_error_message,
              type: "danger",
              insert: "bottom",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
              },
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  render() {
    const { languagePack } = this.props;

    return (
      <div
        className="add-contract-container add-cost-container"
        style={{ height: this.state.height }}
      >
        <div style={{ display: "flex", gap: "10px" }}>
          <div className="add-contract-row">
            <input
              type="text"
              value={this.state.surname}
              onChange={(e) => this.setState({ surname: e.target.value })}
              placeholder={languagePack.last_name}
              className="add-contract-form-input"
              style={{ width: "100%" }}
            />
          </div>

          <div className="add-contract-row">
            <input
              type="text"
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
              placeholder={languagePack.first_name}
              className="add-contract-form-input"
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div style={{ display: "flex", gap: "10px" }}>
          <div className="add-contract-row add-user-phone-number">
            <PhoneInput
              initialValueFormat="national"
              defaultCountry="RO"
              placeholder={languagePack.admin_contact_phone}
              value={this.state.phoneNumber}
              onChange={(e) => this.setState({ phoneNumber: e })}
              className="add-contract-form-input"
              style={{ width: "100%" }}
            />
          </div>

          <div className="add-contract-row">
            <input
              type="text"
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
              placeholder={"Email"}
              className="add-contract-form-input"
              style={{ width: "100%" }}
            />
          </div>
        </div>

        {this.state.wrongPhoneNumber && (
          <div class="ui red message">{languagePack.incorect_phone_number}</div>
        )}

        {this.state.wrongEmail && (
          <div class="ui red message">{languagePack.wrong_email}</div>
        )}

        <div className="additional-label">
          <div className="additional-label-rows" style={{ width: "100%" }}>
            <div className="add-contract-row">
              <Select
                placeholder={languagePack.player}
                value={this.state.selectedPlayer}
                options={this.state.playersDropdown}
                isDisabled={this.props.is_player == "1"}
                onChange={(selected) => {
                  this.setState(
                    {
                      selectedPlayer: selected != null ? selected : "",
                      selectedRelative: "",
                      relativesDropdown: this.state.relativesDropdown,
                      label_entities_loaded: false,
                    },
                    this.getRelatives()
                  );
                }}
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#fafafa",
                    width: "100%",
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#fafafa",
                    height: "50px",
                    border: "none",
                    "&:hover": {
                      border: "none",
                      boxShadow: "0 0 0 2px black",
                    },
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    paddingLeft: "22px",
                    fontSize: "14px",
                    fontWeight: 700,
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    color: "#b2b2b2",
                  }),
                  indicatorSeparator: (provided, state) => ({
                    ...provided,
                    display: "none",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    maxHeight: "350px",
                  }),
                  menuPortal: (provided, state) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                menuPortalTarget={document.body}
                isClearable={true}
              />
            </div>
            <div className="add-contract-row">
              <Select
                placeholder={languagePack.player_relative}
                value={this.state.selectedRelative}
                options={this.state.relativesDropdown}
                isDisabled={this.props.is_player == "1"}
                onChange={(selected) => {
                  this.setState({
                    selectedRelative: selected != null ? selected : "",
                  });
                }}
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#fafafa",
                    width: "100%",
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#fafafa",
                    height: "50px",
                    border: "none",
                    "&:hover": {
                      border: "none",
                      boxShadow: "0 0 0 2px black",
                    },
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    paddingLeft: "22px",
                    fontSize: "14px",
                    fontWeight: 700,
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    color: "#b2b2b2",
                  }),
                  indicatorSeparator: (provided, state) => ({
                    ...provided,
                    display: "none",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    maxHeight: "350px",
                  }),
                  menuPortal: (provided, state) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                menuPortalTarget={document.body}
                isLoading={!this.state.label_entities_loaded}
                isClearable={true}
              />
            </div>
          </div>
        </div>
        {this.state.additional_labels.map((elem, index) => {
          return (
            <div key={"additional-label-" + index} className="additional-label">
              <div className="additional-label-rows">
                <div className="add-contract-row">
                  <Select
                    placeholder={languagePack.player}
                    value={elem.selectedPlayer}
                    options={this.state.playersDropdown}
                    isDisabled={this.props.is_player == "1"}
                    onChange={(selected) => {
                      this.handleChangeAdditionalLabel(index, selected);
                    }}
                    styles={{
                      container: (provided, state) => ({
                        ...provided,
                        backgroundColor: "#fafafa",
                        width: "100%",
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: "#fafafa",
                        height: "50px",
                        border: "none",
                        "&:hover": {
                          border: "none",
                          boxShadow: "0 0 0 2px black",
                        },
                      }),
                      valueContainer: (provided, state) => ({
                        ...provided,
                        paddingLeft: "22px",
                        fontSize: "14px",
                        fontWeight: 700,
                      }),
                      placeholder: (provided, state) => ({
                        ...provided,
                        color: "#b2b2b2",
                      }),
                      indicatorSeparator: (provided, state) => ({
                        ...provided,
                        display: "none",
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        maxHeight: "350px",
                      }),
                      menuPortal: (provided, state) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                  />
                </div>
                <div className="add-contract-row">
                  <Select
                    placeholder={languagePack.player_relative}
                    value={elem.selectedRelative}
                    options={this.state.relativesDropdown}
                    isDisabled={this.props.is_player == "1"}
                    onChange={(selected) => {
                      let currentAdditionalLabels = this.state
                        .additional_labels;
                      currentAdditionalLabels[
                        index
                      ].selectedRelative = selected;
                      this.setState({
                        additional_labels: currentAdditionalLabels,
                      });
                    }}
                    styles={{
                      container: (provided, state) => ({
                        ...provided,
                        backgroundColor: "#fafafa",
                        width: "100%",
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: "#fafafa",
                        height: "50px",
                        border: "none",
                        "&:hover": {
                          border: "none",
                          boxShadow: "0 0 0 2px black",
                        },
                      }),
                      valueContainer: (provided, state) => ({
                        ...provided,
                        paddingLeft: "22px",
                        fontSize: "14px",
                        fontWeight: 700,
                      }),
                      placeholder: (provided, state) => ({
                        ...provided,
                        color: "#b2b2b2",
                      }),
                      indicatorSeparator: (provided, state) => ({
                        ...provided,
                        display: "none",
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        maxHeight: "350px",
                      }),
                      menuPortal: (provided, state) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    isLoading={!elem.label_entities_loaded}
                    // isClearable={true}
                  />
                </div>
              </div>
              <Icon
                name="minus"
                color="red"
                size="large"
                className="delete-additionale-label"
                onClick={() => {
                  let currentAdditionalLabels = this.state.additional_labels;
                  currentAdditionalLabels.splice(index, 1);
                  this.setState({
                    additional_labels: currentAdditionalLabels,
                  });
                }}
              />
            </div>
          );
        })}

        {this.props.is_player != "1" && (
          <div
            className="add-contract-row"
            style={{
              backgroundColor: "white",
              marginTop: this.state.marginTop,
            }}
          >
            <Button
              className="add-label-button"
              onClick={() => {
                let currentAdditionalLabels = this.state.additional_labels;
                currentAdditionalLabels.push({
                  selectedPlayer: "",
                  relativesDropdown: this.state.relativesDropdown,
                  selectedRelative: "",
                  label_entities_loaded: true,
                });
                this.setState({
                  additional_labels: currentAdditionalLabels,
                });
              }}
            >
              {languagePack.add_player}
              <Icon name="add" size="small" className="add-file-button-icon" />
            </Button>
          </div>
        )}

        <div
          className="add-file-buttons-container"
          style={{ marginBottom: 25 }}
        >
          <Button
            className="add-file-button cancel-button"
            onClick={() => this.props.closePopup()}
          >
            {languagePack["admin_cancel"]}
            <Icon name="ban" size="small" className="add-file-button-icon" />
          </Button>
          <Button
            className="add-file-button"
            disabled={
              this.state.surname == "" ||
              this.state.name == "" ||
              this.state.phoneNumber == "" ||
              this.state.phoneNumber == undefined ||
              this.state.email == "" ||
              this.state.selectedPlayer == "" ||
              this.state.selectedRelative == "" ||
              (this.state.additional_labels.length > 0
                ? !this.state.additional_labels.every((elem) => {
                    if (
                      elem.selectedRelative != "" &&
                      elem.selectedPlayer != ""
                    ) {
                      return true;
                    }
                    return false;
                  })
                : false)
            }
            onClick={this.addMobileUser}
          >
            {languagePack["admin_add"]}
            <Icon name="add" size="small" className="add-file-button-icon" />
          </Button>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(EditMobileUser);
