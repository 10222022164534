import React from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { Icon, Button } from "semantic-ui-react";
import "../../Club/Matches/Competitions/ManageCompetitions.scss";
import "./UserList.scss";
import { connect } from "react-redux";

function desactivateMobileUser(props) {
  const { languagePack } = props;

  function desactivateUser() {
    const payload = {
      id_user_external: props.id_user_to_desactivate,
    };

    axios.post("parentsApp/desactivate_parent", payload).then((resp) => {
      if (resp.data === 1) {
        store.addNotification({
          title: languagePack.desactivate_user,
          message: languagePack.notif_desactivate_mobile_user_success_message,
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
        props.refreshUsers();
        props.closePopup();
      }

      if (resp.data === 0) {
        store.addNotification({
          title: languagePack.desactivate_user,
          message: languagePack.notif_desactivate_mobile_user_failed_message,
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });

        props.closePopup();
      }
    });
  }

  return (
    <div className="delete-competition-popup-container">
      <div className="delete-competition-header">
        <div className="delete-competition-title">
          {languagePack.desactivate_user}
        </div>
        <Icon
          name="close"
          className="close"
          size="large"
          onClick={() => props.closePopup()}
        />
      </div>
      <div className="delete-competition-text">
        {languagePack.desactivate_user_popup_text}
      </div>
      <div className="delete-competition-buttons">
        <Button
          className="cancel-user-mobile-button"
          onClick={() => props.closePopup()}
        >
          {languagePack["admin_cancel"]}
          <Icon name="close" className="white-icon" />
        </Button>
        <Button
          className="desactivate-user-mobile-button"
          onClick={() => desactivateUser()}
        >
          {languagePack.desactivate}

          <Icon name="check" className="white-icon" />
        </Button>
      </div>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(desactivateMobileUser);
