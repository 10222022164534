import React from "react";
import axios from "axios";
import { Input, Button, Icon, Dropdown } from "semantic-ui-react";
import moment from "moment";
import { store } from "react-notifications-component";

const MonthCoresp = [
  "",
  "ianuarie",
  "februarie",
  "martie",
  "aprilie",
  "mai",
  "iunie",
  "iulie",
  "august",
  "septembrie",
  "octombrie",
  "noiembrie",
  "decembrie",
];

class AddNewClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      currentSold: "",
      providers: [],
      selectedProvider: "",
      currency: "EUR",
    };
  }

  componentDidMount = () => {
    this.getProvidersDropdown();
  };

  getProvidersDropdown = () => {
    axios
      .get(`finances/get_providers_dropdown?id_club=${this.props.id_club}`)
      .then((res) => this.setState({ providers: res.data }))
      .catch((err) => console.log(err));
  };

  addClient = () => {
    const payload = {
      partner_name: this.state.selectedProvider === "" ? this.state.name : "",
      partner_id: this.state.selectedProvider,
      current_sold: this.state.currentSold,
      last_month: moment().format("MM") - 1,
      year: moment().format("YYYY"),
      type: "D",
      id_club: this.props.id_club,
      currency: this.state.currency,
    };
    axios.post("finances/add_new_partner", payload).then(() => {
      this.props.closePopup();
      this.props.refreshList();
      store.addNotification({
        title: "Operatiuni clienti",
        message: "Clientul a fost adaugat cu succes",
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
    });
  };

  render() {
    const lastMonth = moment().format("MM") - 1;
    return (
      <div className="add-new-client-container">
        <div className="form-row-add-client">
          <Input
            placeholder="Introdu numele..."
            fluid
            disabled={this.state.selectedProvider !== ""}
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
        </div>
        <div className="or-row">sau</div>
        <div className="form-row-add-client">
          <Dropdown
            placeholder="Selecteaza din furnizorii deja existenti"
            fluid
            options={this.state.providers}
            selection
            search
            value={this.state.selectedProvider}
            onChange={(e, { value }) =>
              this.setState({ selectedProvider: value })
            }
            clearable
          />
        </div>
        <div className="form-row-add-client sb-row">
          <Input
            placeholder={"Balanta la finalul lunii " + MonthCoresp[lastMonth]}
            value={this.state.currentSold}
            onChange={(e) => this.setState({ currentSold: e.target.value })}
            type="number"
            style={{ width: "70%" }}
          />
          <div className="currency-selector">
            <div
              className="currency"
              style={{
                backgroundColor: this.state.currency === "EUR" ? "#b3b3b3" : "",
              }}
              onClick={() => this.setState({ currency: "EUR" })}
            >
              EUR
            </div>
            <div
              className="currency"
              style={{
                backgroundColor: this.state.currency === "RON" ? "#b3b3b3" : "",
              }}
              onClick={() => this.setState({ currency: "RON" })}
            >
              RON
            </div>
          </div>
        </div>

        <div className="buttons-container-add-client">
          <Button
            className="add-client-cancel-button"
            onClick={() => this.props.closePopup()}
          >
            {" "}
            <div className="button-content">
              Anuleaza
              <Icon name="x" className="op-cancel-icon" />
            </div>
          </Button>
          <Button
            className="add-client-save-button"
            onClick={this.addClient}
            disabled={this.state.name === "" || this.state.currentSold === ""}
          >
            {" "}
            <div className="button-content">
              Salveaza
              <Icon name="add" className="add-icon" />
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default AddNewClient;
