import React, { useState } from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import CurrencyPicker from "./CurrencyPicker";

const EditPlayerAmountToPay = (props) => {

    const { languagePack, closePopup, isOpenPopup, refreshList, paymentRequestData } = props;

    const [editData, setEditData] = useState({
        amount: paymentRequestData?.amount ?? "",
        currency: paymentRequestData?.currency ?? "RON",
    });

    const editAmountToPay = () => {
        const payload = {
            id_entity: paymentRequestData?.id_payment_request,
            id_player: paymentRequestData?.id_player,
            amount: editData?.amount,
            currency: editData?.currency,
        };

        axios
            .post("finances/edit_player_amount_to_pay_for_payment_request_2", payload)
            .then(res => {
                if (res.data.success == 1) {
                    refreshList();
                    store.addNotification({
                        title: languagePack.payment_request,
                        message: languagePack.success_edit_payment_request_amount,
                        type: "success",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                } else {
                    store.addNotification({
                        title: languagePack.payment_request,
                        message: languagePack.fail_edit_payment_request_amount,
                        type: "danger",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                }
                closePopup();
            })
            .catch(e => console.log(e))
    }

    const onChangeInput = (e) => {
        setEditData({ ...editData, ...{ amount: e.target.value } });
    }

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack.edit_amount_to_pay}
            open={isOpenPopup}
            closePopup={closePopup}
            btnLeft={() => (
                <TFBButton
                    color="darkGray"
                    renderIcon={() => <TFBIcon name="cancel" />}
                    onClick={closePopup}
                >
                    {languagePack.admin_cancel}
                </TFBButton>
            )}
            btnRight={() => (
                <TFBButton
                    color="green"
                    renderIcon={() => <TFBIcon name="check" />}
                    disabled={String(editData.amount).length == 0}
                    onClick={editAmountToPay}
                >
                    {languagePack.apply}
                </TFBButton>
            )}
        >
            <div className="pop-up-section">
                <div className="pop-up-section-body">
                    <div className="pop-up-row">
                        <label className="row-label">{languagePack.payment_request_amount}</label>
                        <br />
                        <div className="row-input-with-currency">
                            <input
                                className="row-input"
                                type="number"
                                placeholder={`${languagePack.enter_payment_request_amount} (${editData.currency})`}
                                value={editData.amount}
                                name="amount"
                                onChange={onChangeInput}
                            />
                            <div className="currency-options">
                                <CurrencyPicker
                                    name="RON"
                                    checked={editData.currency == "RON"}
                                    onClick={() => setEditData({ ...editData, ...{ currency: "RON" } })}
                                />
                                {/* <CurrencyPicker
                                        name="EUR"
                                        checked={editData.currency == "EUR"}
                                        onClick={() => setEditData({ ...editData, ...{ currency: "EUR" } })}
                                    /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </TFBDialog>
    )
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
}))(EditPlayerAmountToPay);