import React, { Suspense, useState, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { logout, login } from "./utils/api";
import { LayoutSplashScreen } from "../_metronic/layout";

import { DashboardPage } from "./pages/DashboardPage";

import IncomeOutcome from "./pages/Financial/Cashflow/IncomeOutcome";
import Bonuses from "./pages/Financial/Bonuses/Bonuses";
import Sponsors from "./pages/Financial/Sponsors/Sponsors";
import Providers from "./pages/Financial/Providers/Providers";
import Clients from "./pages/Financial/Clients/Clients";
import AccountTransactions from "./pages/Financial/BankAccount/AccountTransactions";
import PartnerProfile from "./pages/Financial/Partner/PartnerProfile";
import PlayersCosts from "./pages/Financial/PlayersCosts/PlayersCosts";
import WagesDetailed from "./pages/Financial/PlayersCosts/WagesDetailed";
import PlayerSearch from "./pages/Scouting/PlayerSearch.jsx";

import Documents from "./pages/Documents/Documents";
import PlayerContracts from "./pages/Documents/PlayerContracts";

import Injuries from "./pages/Medical/Injuries/Injuries";
import Matches from "./pages/Club/Matches/Matches";
import MatchDetails from "./pages/Club/Matches/match/MatchDetails";
import MatchDetailsInStat from "./components/data-providers/football-api/match/MatchDetails";
import MatchImport from "./pages/Club/Matches/match/ImportCsv";
import ClubProfile from "./pages/Club/ClubProfile/ClubProfile";
import Team from "./pages/Club/Team";
import Payments from "./pages/Club/Payments/Payments";
import MedicalCertificates from "./pages/Club/MedicalCertificates/MedicalCertificates";
import TeamPerformance from "./pages/Club/TeamPerformance";
import Reports from "./pages/Reports/Reports";
import EvaluationsPage from "./pages/Reports/Evaluation/EvaluationsPage";
import Evaluation from "./pages/Reports/Evaluation/Evaluation";
import EvaluationStats from "./pages/Reports/Evaluation/EvaluationStats";
import EvaluationEdit from "./pages/Reports/Evaluation/EvaluationEdit";

import ReportPage from "./pages/Reports/ReportPage";
import AddReport from "./pages/Reports/AddReport";
import PhysicalReportEdit from "./pages/Reports/PhysicalReportEdit";
import PlayerProfile from "./pages/Club/PlayerProfile/PlayerProfile";
import StaffProfile from "./pages/Club/StaffProfile/StaffProfile";
import Calendar from "./components/widgets/Calendar/Calendar";

import Logout from "./Auth/Logout";

import permissions from "./../app/utils/permissions";
import StaffContracts from "./pages/Documents/StaffContracts";
import MatchCosts from "./pages/Financial/MatchCosts/MatchCosts";
import MatchCostsEdit from "./pages/Financial/MatchCosts/MatchCostsEdit";
import TaxRedirectors from "./pages/Fans/TaxRedirectors";
import SponsorPage from "./pages/Financial/Sponsors/SponsorPage";
import Competitions from "./pages/Club/Matches/Competitions/Competitions";
import CompetitionPage from "./pages/Club/Matches/Competitions/CompetitionPage";

import NewsPage from "./pages/SiteAdmin/News/NewsPage/NewsPage";
import AddNews from "./pages/SiteAdmin/News/AddNews/AddNews";
import EditNews from "./pages/SiteAdmin/News/EditNews/EditNews";
import AboutUs from "./pages/SiteAdmin/AboutUs/AboutUs";
import Photos from "./pages/SiteAdmin/Photos/Photos";

import UserListMobile from "./pages/MobileAppAdmin/UserList/UserListMobile";

import PlanTraining from "./pages/Training/PlanTraining/PlanTraining";
import RpeTraining from "./pages/Training/Rpe/RpeTraining";
import AddTraining from "./pages/Training/PlanTraining/AddTraining";
import EditTraining from "./pages/Training/PlanTraining/EditTraining";

import TrainingHistory from "./pages/Training/TrainingHistory/TrainingHistory";
import TrainingPage from "./pages/Training/TrainingPage/TrainingPage";
import WeightControl from "./pages/Training/WeightControl/WeightControl";
import PrepareNextMatch from "./pages/Club/Matches/PrepareNextMatch/PrepareNextMatch";
import SiteSponsors from "./pages/SiteAdmin/Sponsors/SiteSponsors";
import SiteContact from "./pages/SiteAdmin/Contact/SiteContact";
import SocialMedia from "./pages/SiteAdmin/SocialMedia/SocialMedia";
import WebsiteSettings from "./pages/SiteAdmin/WebsiteSettings/WebsiteSettings";
import TeamsSettings from "./pages/SiteAdmin/TeamsSettings/TeamsSettings";
import StaffSettings from "./pages/SiteAdmin/StaffSettings/StaffSettings";
import ImportCsv from "./pages/Fans/ImportCSV/ImportCsv";
import FansList from "./pages/Fans/FansList/FansList";
import FanProfile from "./pages/Fans/FanProfile/FanProfile";
import SendEmail from "./pages/Fans/SendEmail/SendEmail";
import PlayerEventsPitch from "./components/data-providers/instat/player/PlayerEventsPitch";
import FansDashboard from "./pages/Fans/Dashboard/FansDashboard";
import MyList from "./pages/Scouting/MyList";
import ShortlistPlayers from "./pages/Scouting/ShortlistPlayers";
import UserList from "./pages/User/UserList";
import AddUser from "./pages/User/AddUser";
import User from "./pages/User/User";
import Campaigns from "./pages/Fans/Campaigns/Campaigns";
import PlayerGallery from "./pages/SiteAdmin/Photos/PlayerGallery";
import VideoGallery from "./pages/VideoGallery/VideoGallery";
import CompetitionInfoPage from "./pages/Club/Matches/Competitions/CompetitionInfoPage";
import TrainingDetailsPage from "./pages/Training/TrainingDetailsPage/TrainingDetailsPage";
import TrainingSections from "./pages/Training/Sections/TrainingSections";
import PresenceReport from "./pages/Training/Presence/PresenceReport";
import PrizesPage from "./pages/Club/Matches/Competitions/PrizesPage";
import InitialSetUpPage from "./pages/InitialSetUpPage";
import PlayersStatistics from "./pages/Scouting/Statistics/PlayersStatistics";
import BonusesMonitoring from "./pages/Financial/BonusesMonitoring/BonusesMonitoring";
import VenueManagement from "./pages/Club/VenueManagement/VenueManagement";
import CardsManagement from "./pages/Club/ClubProfile/CardsManagement/CardsManagement";
import FRFLists from "./pages/Club/ClubProfile/FRFLists/FRFLists";
import Notifications from "./pages/Notifications/Notifications";
import Costs from "./pages/Financial/Costs/Costs";
import OnlinePayments from "./pages/Financial/OnlinePaymentsList/OnlinePayments";
import NecessityReport from "./pages/Financial/NecessityReport/NecessityReport";
import Shop from "./pages/Shop/Shop";
import Products from "./pages/Shop/Products/Products";
import Orders from "./pages/Shop/Orders/Orders";
import Categories from "./pages/Shop/Categories/Categories";
import Sizes from "./pages/Shop/Sizes/Sizes";
import ContExtras from "./pages/Financial/ContExtras/ContExtras";
import Receipt from "./pages/Financial/Receipt/Receipt";
import UsersManagement from "./pages/UsersManagement/UsersManagement";
import Goals from "./pages/Statistics/Goals";
import EnrolmentRequests from "./pages/Club/EnrolmentRequests/EnrolmentRequests";
import PaymentRequest from "./pages/Financial/PaymentRequest/PaymentRequest";
import PaymentRequestDetails from "./pages/Financial/PaymentRequest/PaymentRequestDetails";
import VideoDetails from "./pages/VideoGallery/VideoDetails/VideoDetails";
import UserNotifications from "./pages/MobileAppAdmin/UserNotifications/UserNotifications";
import PrivacyPolicy from "./pages/SiteAdmin/PrivacyPolicy/PrivacyPolicy";

function DefaultRoute(props) {
  useEffect(() => {
    for (let permission in props.user_permissions) {
      switch (permission) {
        case permissions.MEDICAL:
          props.history.replace("/medical/injuries");
          break;
        case permissions.FINANCIAL:
          props.history.replace("/financial");
          break;
        case permissions.CLUB:
          props.history.replace("/club");
          break;
        case permissions.DOCUMENTS:
          props.history.replace("/documents");
          break;
        case permissions.SCOUTING:
          props.history.replace("/scouting");
          break;
        case permissions.REPORTS:
          props.history.replace("/reports");
          break;
      }
    }
  }, []);
  return "";
}

export function BasePage(props) {
  const { user_permissions } = props;

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Route exact path="/logout" component={Logout} />
        <Route
          exact
          path="/aux"
          render={(props) => (
            <DefaultRoute {...props} user_permissions={user_permissions} />
          )}
        />

        <Route path="/notifications" component={Notifications} />

        {user_permissions[permissions.DASHBOARD] && (
          <Route path="/dashboard" component={DashboardPage} />
        )}
        {user_permissions[permissions.DASHBOARD] && (
          <Route path="/initial-setup" component={InitialSetUpPage} />
        )}
        {user_permissions[permissions.SCOUTING] && (
          <Route path="/scouting/players" component={PlayerSearch} />
        )}
        {user_permissions[permissions.SCOUTING] && (
          <Route exact path="/scouting/shortlists" component={MyList} />
        )}
        {user_permissions[permissions.SCOUTING] && (
          <Route
            exact
            path="/scouting/players-statistics"
            component={PlayersStatistics}
          />
        )}

        {user_permissions[permissions.SCOUTING] && (
          <Route path="/scouting/shortlists/:id" component={ShortlistPlayers} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/club" component={ClubProfile} />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route exact path="/club/team/:id" component={Team} />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route
            exact
            path="/club/medical-certificates/:id"
            component={MedicalCertificates}
          />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route exact path="/club/payments/:id" component={Payments} />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route
            path="/club/team/:id/performance"
            component={TeamPerformance}
          />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route path="/club/matches" component={Matches} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/club/match/import" component={MatchImport} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/club/match/:id" component={MatchDetails} />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route
            exact
            path="/club/match/instat/:id"
            component={MatchDetailsInStat}
          />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route
            exact
            path="/club/match/prepare/:id"
            component={PrepareNextMatch}
          />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route path="/club/player/:id" component={PlayerProfile} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route path="/club/player-gallery/:id" component={PlayerGallery} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route path="/club/staff/:id" component={StaffProfile} />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route path="/test" component={PlayerEventsPitch} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route path="/club/calendar" component={Calendar} />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route exact path="/club/competitions" component={Competitions} />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route
            exact
            path="/club/competitions/:id"
            component={CompetitionPage}
          />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route
            exact
            path="/club/competitions/info/:id"
            component={CompetitionInfoPage}
          />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route
            exact
            path="/club/competitions/prizes/:id"
            component={PrizesPage}
          />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route
            exact
            path="/club/venue-management"
            component={VenueManagement}
          />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route exact path="/club/match-costs" component={MatchCosts} />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route
            exact
            path="/club/match-costs/edit/:id"
            component={MatchCostsEdit}
          />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route
            exact
            path="/club/cards-management"
            component={CardsManagement}
          />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/club/stats/goals" component={Goals} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/club/frf-lists" component={FRFLists} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route
            exact
            path="/club/enrolment-requests"
            component={EnrolmentRequests}
          />
        )}

        {user_permissions[permissions.REPORTS] && (
          <Route exact path="/reports/report" component={Reports} />
        )}
        {user_permissions[permissions.REPORTS] && (
          <Route
            exact
            path="/reports/evaluations_test/edit/:id-:id_team"
            component={EvaluationEdit}
          />
        )}
        {user_permissions[permissions.REPORTS] && (
          <Route
            exact
            path="/reports/evaluations_test/:id"
            component={EvaluationStats}
          />
        )}
        {user_permissions[permissions.REPORTS] && (
          <Route
            exact
            path="/reports/physical/edit/:id-:id_team"
            component={PhysicalReportEdit}
          />
        )}
        {user_permissions[permissions.REPORTS] && (
          <Route exact path="/reports/:type/:id" component={ReportPage} />
        )}

        {user_permissions[permissions.REPORTS] && (
          <Route exact path="/reports/add" component={AddReport} />
        )}

        {user_permissions[permissions.REPORTS] && (
          <Route exact path="/reports/evaluation" component={EvaluationsPage} />
        )}

        {user_permissions[permissions.REPORTS] && (
          <Route exact path="/reports/evaluation-add" component={Evaluation} />
        )}

        {user_permissions[permissions.FINANCIAL] && (
          <Route path="/financial/cashflow" component={IncomeOutcome} />
        )}
        {user_permissions[permissions.FINANCIAL] && (
          <Route
            path="/financial/transactions"
            component={AccountTransactions}
          />
        )}
        {user_permissions[permissions.FINANCIAL] && (
          <Route path="/financial/providers" component={Providers} />
        )}

        {user_permissions[permissions.FINANCIAL] && (
          <Route exact path="/financial/sponsors" component={Sponsors} />
        )}

        {user_permissions[permissions.FINANCIAL] && (
          <Route path="/financial/sponsors/:id" component={SponsorPage} />
        )}

        {user_permissions[permissions.FINANCIAL] && (
          <Route path="/financial/clients" component={Clients} />
        )}
        {user_permissions[permissions.FINANCIAL] && (
          <Route path="/financial/bonuses" component={Bonuses} />
        )}
        {user_permissions[permissions.FINANCIAL] && (
          <Route path="/financial/partner/:id" component={PartnerProfile} />
        )}
        {user_permissions[permissions.FINANCIAL] && (
          <Route path="/financial/players-costs" component={PlayersCosts} />
        )}
        {user_permissions[permissions.FINANCIAL] && (
          <Route path="/financial/players-wages" component={WagesDetailed} />
        )}

        {user_permissions[permissions.FINANCIAL] &&
          user_permissions[permissions.BONUSES_MONITORING] && (
            <Route
              exact
              path="/financial/bonuses-monitoring"
              component={BonusesMonitoring}
            />
          )}
        {user_permissions[permissions.FINANCIAL] &&
          user_permissions[permissions.COSTS] && (
            <Route exact path="/financial/costs" component={Costs} />
          )}
        {user_permissions[permissions.FINANCIAL] &&
          user_permissions[permissions.ONLINE_PAYMENTS] && (
            <Route
              exact
              path="/financial/online-payments"
              component={OnlinePayments}
            />
          )}
        {user_permissions[permissions.FINANCIAL] &&
          user_permissions[permissions.IMPORT_FROM_STATEMENT] && (
            <Route exact path="/financial/cont-extras" component={ContExtras} />
          )}

        {user_permissions[permissions.FINANCIAL] &&
          user_permissions[permissions.NECESSITY_REPORTS] && (
            <Route
              exact
              path="/financial/necessity-reports"
              component={NecessityReport}
            />
          )}

        {user_permissions[permissions.FINANCIAL] && (
          <Route exact path="/financial/receipt" component={Receipt} />
        )}

        {user_permissions[permissions.FINANCIAL] && (
          <Route
            exact
            path="/financial/payment-request"
            component={PaymentRequest}
          />
        )}

        {user_permissions[permissions.FINANCIAL] && (
          <Route
            exact
            path="/financial/payment-request-details/:id"
            component={PaymentRequestDetails}
          />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route path="/fans/tax-redirectors" component={TaxRedirectors} />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route path="/fans/social_dashboard" component={FansDashboard} />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route path="/fans/import" component={ImportCsv} />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route exact path="/fans/list" component={FansList} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/fans/mail" component={SendEmail} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/fans/campaigns" component={Campaigns} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/fans/:id" component={FanProfile} />
        )}

        {user_permissions[permissions.MEDICAL] && (
          <Route path="/medical/injuries" component={Injuries} />
        )}

        {user_permissions[permissions.DOCUMENTS] && (
          <Route
            path="/documents/players"
            render={(props) => (
              <Documents {...props} presetDisplayOption="Contracte jucatori" />
            )}
          />
        )}
        {user_permissions[permissions.DOCUMENTS] && (
          <Route exact path="/documents" component={Documents} />
        )}
        {user_permissions[permissions.DOCUMENTS] && (
          <Route
            path="/documents/player-contracts"
            component={PlayerContracts}
          />
        )}

        {user_permissions[permissions.DOCUMENTS] && (
          <Route path="/documents/staff-contracts" component={StaffContracts} />
        )}

        {user_permissions[permissions.DASHBOARD] && (
          <Redirect exact from="/" to="/dashboard" />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/application/users" component={UserListMobile} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route
            exact
            path="/application/users-notifications"
            component={UserNotifications}
          />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/site/news" component={NewsPage} />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route exact path="/site/news/add" component={AddNews} />
        )}
        {user_permissions[permissions.CLUB] && (
          <Route exact path="/site/news/edit/:id" component={EditNews} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/site/photos" component={Photos} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/site/sponsors" component={SiteSponsors} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/site/contact" component={SiteContact} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/site/social-media" component={SocialMedia} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route
            exact
            path="/site/website-settings"
            component={WebsiteSettings}
          />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/site/teams-settings" component={TeamsSettings} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/site/staff-settings" component={StaffSettings} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/site/privacy-policy-site" component={PrivacyPolicy} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/site/about_us" component={AboutUs} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/training/plan" component={PlanTraining} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route
            path="/training/rpe/:id"
            render={(props) => (
              <RpeTraining key={props.match.params.id} {...props} />
            )}
          />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/training/plan/add" component={AddTraining} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route
            exact
            path="/training/plan/edit/:id"
            component={EditTraining}
          />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/training/history" component={TrainingHistory} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/training/weight" component={WeightControl} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/training/history/:id" component={TrainingPage} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route
            exact
            path="/training/history/details/:id"
            component={TrainingDetailsPage}
          />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/training/sections" component={TrainingSections} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route
            exact
            path="/training/report-presence"
            component={PresenceReport}
          />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/user/list" component={UserList} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/user/add" component={AddUser} />
        )}

        {user_permissions[permissions.CLUB] && (
          <Route exact path="/user/:id" component={User} />
        )}

        {(user_permissions.ANY || user_permissions?.VIDEOGALLERY === true) && (
          <Route exact path="/video-gallery" component={VideoGallery} />
        )}

        {(user_permissions.ANY || user_permissions?.VIDEOGALLERY === true) && (
          <Route exact path="/video-gallery/:id" component={VideoDetails} />
        )}

        {/* {(user_permissions.ANY || user_permissions?.SHOP === true) && (
          <Route exact path="/shop" component={Shop} />
        )} */}

        {(user_permissions.ANY || user_permissions?.SHOP === true) && (
          <Route exact path="/shop/products" component={Products} />
        )}

        {(user_permissions.ANY || user_permissions?.SHOP === true) && (
          <Route exact path="/shop/orders" component={Orders} />
        )}

        {(user_permissions.ANY || user_permissions?.SHOP === true) && (
          <Route exact path="/shop/categories" component={Categories} />
        )}

        {(user_permissions.ANY || user_permissions?.SHOP === true) && (
          <Route exact path="/shop/sizes" component={Sizes} />
        )}

        {user_permissions[permissions.CLUB_ADMIN] && (
          <Route exact path="/users-management" component={UsersManagement} />
        )}

        {!user_permissions[permissions.DASHBOARD] && (
          <Route
            path="/"
            render={(props) => (
              <DefaultRoute {...props} user_permissions={user_permissions} />
            )}
          />
        )}
        <Redirect from="/auth" to="/" />
      </Switch>
    </Suspense>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
      login,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    user: auth.user || {},
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(BasePage);
