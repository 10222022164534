import React from "react";
import { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { ro, enUS } from "date-fns/locale";

export default function DatePickerComp({ lang, setDate }) {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const locale = (() => {
    switch (lang) {
      case "ro":
        return ro;
      default:
        return enUS;
    }
  })();

  return (
    <DateRange
      locale={locale}
      editableDateInputs={true}
      onChange={(item) => {
        setState([item.selection])
        setDate(item.selection);
      }}
      moveRangeOnFirstSelection={false}
      ranges={state}
    />
  );
}
