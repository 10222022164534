import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";
import * as utilFunctions from "./../../../../../app/utils/utilFunctions";
import { rootPages } from "../../../../../app/utils/constants";
import { connect } from "react-redux";
import permissions from "./../../../../../app/utils/permissions";
import asideMenuListSVG from "../../../../../app/utils/asideMenuListSVG";
import TFBIcon from "../../../../../app/components/design-system/Icon/TFBIcon";

function AsideMenuList(props) {
  const layoutProps = props.layoutProps;
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const { languagePack, user } = useSelector(
    ({ auth, layoutService: { languagePack } }) => ({
      user: auth?.user,
      languagePack,
    })
  );

  const currentSectionPage = utilFunctions.getCurrentRootPage(
    window.location.pathname
  );

  const handleCloseAsideMenu = () => {
    if (document.body.classList.contains("aside-minimize")) {
      document.body.classList.remove("aside-minimize");
      document.body.classList.add("aside-minimize-cancel");
    }
  };

  const user_permissions =
    user?.user_permissions && typeof user.user_permissions === "object"
      ? user.user_permissions
      : null;

  const disabledStyle = {
    filter: "opacity(0.2)",
    pointerEvents: "none",
  };

  const AsideMenuListItem = ({ src, text }) => (
    <>
      <span className="svg-icon menu-icon">
        <img src={src} />
      </span>
      <span className="menu-text">{text}</span>
      <TFBIcon name="angle-right" color="black" style={{ height: 13 }} />
    </>
  );

  const AsideMenuListTitle = ({ text, style = {} }) => (
    <li
      className="menu-section "
      style={{
        height:
          document.getElementById("tfb-logo-brand")?.clientHeight ?? "initial",
        ...style,
      }}
    >
      <h4 className="menu-text">{text}</h4>
    </li>
  );

  return (
    <>
      {(location.pathname === "/dashboard" ||
        location.pathname === "/notifications" ||
        currentSectionPage === rootPages.CLUB) && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.club_management} />

          <li
            className={`menu-item menu-item-submenu ${new RegExp(
              `\/club$`
            ).test(window.location.pathname) &&
              " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/club"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.TEAM}
                text={languagePack.teams}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/club/calendar"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/club/calendar"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.CALENDAR}
                text={languagePack.calendar}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/club/matches"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/club/matches?type=past"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.MATCH}
                text={languagePack.matches_schedule}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/club/competitions"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/club/competitions"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.COMPETITION}
                text={languagePack.competitions}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/club/venue-management"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/club/venue-management"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.VENUE}
                text={languagePack.venue_management}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/club/match-costs"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/club/match-costs"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.MATCH_COST}
                text={languagePack.match_costs}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/club/cards-management"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/club/cards-management"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.CARDS_MANAGEMENT}
                text={languagePack.suspension_status}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/club/stats/goals"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/club/stats/goals"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.GOALS_STATS}
                text={languagePack.goals_stats}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/club/frf-lists"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/club/frf-lists"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.FRF_LIST}
                text={languagePack.ab_list}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/club/enrolment-requests"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/club/enrolment-requests"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.ENROLMENT_REQUEST}
                text={languagePack.enrolment_request}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {currentSectionPage === rootPages.TRAINING && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.trainings} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/training/plan"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/training/plan"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.TRAINING_PLANING}
                text={languagePack.plan_training}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/training/history"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/training/history"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.TRAINING_HISTORY}
                text={languagePack.trainings_history}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/training/weight"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/training/weight"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.WEIGHT_CONTROL}
                text={languagePack.weight_control}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/training/sections"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/training/sections"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.TRAINING_SECTION}
                text={languagePack.training_sections}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/training/report-presence"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/training/report-presence"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.TRAINING_PRESENCE_REPORT}
                text={languagePack.attendance_report}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {currentSectionPage === rootPages.REPORTS && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.reports_aside_title} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/reports/report"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/reports/report"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.PHYSICAL_TEST}
                text={languagePack.physical_tests}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/reports/evaluation"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/reports/evaluation"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.EVALUATION}
                text={languagePack.evaluation_reports}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {currentSectionPage === rootPages.FINANCIAL && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.financial_aside_title} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/financial/bonuses-monitoring"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              !user_permissions[permissions.BONUSES_MONITORING]
                ? disabledStyle
                : {}
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/financial/bonuses-monitoring"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.BONUS_MONITORING}
                text={languagePack.bonuses_monitoring}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/financial/costs"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={!user_permissions[permissions.COSTS] ? disabledStyle : {}}
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/financial/costs"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.COST}
                text={languagePack.costs}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/financial/online-payments"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              !user_permissions[permissions.ONLINE_PAYMENTS]
                ? disabledStyle
                : {}
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/financial/online-payments"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.ONLINE_PAYMENT}
                text={languagePack.online_payment_list}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/financial/necessity-reports"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              !user_permissions[permissions.NECESSITY_REPORTS]
                ? disabledStyle
                : {}
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/financial/necessity-reports"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.NECCESSITY_REPORT}
                text={languagePack.necessity_reports}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/financial/cont-extras"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
            style={
              !user_permissions[permissions.IMPORT_FROM_STATEMENT]
                ? disabledStyle
                : {}
            }
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/financial/cont-extras"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.STATEMENT}
                text={languagePack.upload_bank_account_statements}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/financial/receipt"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/financial/receipt"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.RECEIPT}
                text={languagePack.receipts}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/financial/payment-request"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/financial/payment-request"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.PAYMENT_REQUEST}
                text={languagePack.payment_request}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {currentSectionPage === rootPages.MEDICAL && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.medical} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/medical/injuries"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/medical/injuries"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.INJURY}
                text={languagePack.injuries}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {currentSectionPage === rootPages.DOCUMENTS && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.contracts} />

          <li
            className={`menu-item menu-item-submenu ${window.location
              .pathname === "/documents" &&
              " menu-item-active"}  ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/documents"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.DOCUMENT}
                text={languagePack.documents}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/documents/player-contracts"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/documents/player-contracts"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.CONTRACT_PLAYER}
                text={languagePack.player_contracts}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/documents/staff-contracts"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/documents/staff-contracts"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.CONTRACT_STAFF}
                text={languagePack.staff_contracts}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {currentSectionPage === rootPages.FANS && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.fans_relation} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/fans/list"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/fans/list"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.FANS_LIST}
                text={languagePack.fans_list}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/fans/import"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/fans/import"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.FANS_IMPORT}
                text={languagePack.import_data}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/fans/campaigns"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/fans/campaigns"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.FANS_CAMPAIGN}
                text={languagePack.campaigns}
              />
            </NavLink>
          </li>

          <AsideMenuListTitle
            text={languagePack.categories}
            style={{ height: 55 }}
          />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/fans/social_dashboard"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/fans/social_dashboard"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.FANS_SOCIAL_MEDIA}
                text={languagePack.social_media_activity}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/fans/tax-redirectors"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/fans/tax-redirectors"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.TAX_REDIRECTION}
                text={languagePack.tax_redirectors}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {currentSectionPage === rootPages.VIDEOGALLERY && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.gallery_videos_title} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/video-gallery"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/video-gallery"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.VIDEO}
                text={languagePack.videos}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {currentSectionPage === rootPages.SITE && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.site_management} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/site/news"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/news"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.NEWS}
                text={languagePack.news}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/site/about_us"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/about_us"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.ABOUT_US}
                text={languagePack.about_us_title}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/site/sponsors"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/sponsors"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.SPONSOR}
                text={languagePack.sponsors}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/site/photos"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/photos"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.PHOTO_VIDEO_GALLERY}
                text={languagePack.gallery_notification_title}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${window.location
              .pathname === "/site/contact" &&
              " menu-item-active"}  ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/contact"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.CONTACT}
                text={languagePack.contact_data}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${window.location
              .pathname === "/site/social-media" &&
              " menu-item-active"}  ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/social-media"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.SOCIAL_MEDIA_CONNECT}
                text={languagePack.social_media_connect}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${window.location
              .pathname === "/site/website-settings" &&
              " menu-item-active"}  ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/website-settings"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.WEBSITE_SETTINGS}
                text={languagePack.website_settings}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${window.location
              .pathname === "/site/teams-settings" &&
              " menu-item-active"}  ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/teams-settings"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.TEAMS_SETTINGS}
                text={languagePack.teams_settings}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${window.location
              .pathname === "/site/staff-settings" &&
              " menu-item-active"}  ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/staff-settings"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.STAFF_SETTINGS}
                text={languagePack.staff_settings}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${window.location
              .pathname === "/site/privacy-policy-site" &&
              " menu-item-active"}  ${getMenuItemActive(
              "/react-bootstrap",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/site/privacy-policy-site"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.PRIVACY_POLICY}
                text={languagePack.privacy_policy}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {currentSectionPage === rootPages.APPLICATION && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.admin_parent_app} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/application/users"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/application/users"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.PARENT_USER}
                text={languagePack.users_list}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/application/users-notifications"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/application/users-notifications"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.NOTIFICATE_USER}
                text={languagePack.users_notifications}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {currentSectionPage === rootPages.SHOP && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.shop} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/shop/products" //de schimbat aici
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/shop/products"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.PRODUCT}
                text={languagePack.products}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/shop/orders" //de schimbat aici
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/shop/orders"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.ORDER}
                text={languagePack.orders}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/shop/categories" //de schimbat aici
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/shop/categories"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.CATEGORY}
                text={languagePack.categories}
              />
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/shop/sizes"
            ) && " menu-item-active"}  ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/shop/sizes"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.SIZE}
                text={languagePack.sizes}
              />
            </NavLink>
          </li>
        </ul>
      )}

      {currentSectionPage === rootPages.USERS_MANAGEMENT && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <AsideMenuListTitle text={languagePack.users_management_menu} />

          <li
            className={`menu-item menu-item-submenu ${utilFunctions.checkPageRoot(
              window.location.pathname,
              "/users-management"
            ) && " menu-item-active"} ${getMenuItemActive(
              "/google-material",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/users-management"
              onClick={handleCloseAsideMenu}
            >
              <AsideMenuListItem
                src={asideMenuListSVG.PLATFORM_ADMINISTRATION}
                text={languagePack.users_management_menu}
              />
            </NavLink>
          </li>
        </ul>
      )}
    </>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  user: auth.user || {},
  token: auth?.user?.token,
}))(AsideMenuList);
