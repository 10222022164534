import React from 'react';
import axios from 'axios';
import { store } from 'react-notifications-component';
import { Icon, Button } from 'semantic-ui-react';
import './ManageCompetitions.scss';
import { connect } from 'react-redux';

function DeleteCompetition(props) {
    const { languagePack } = props;

    function deleteCompetition() {
        const payload = {
            id: props.id_competition,
        };
        axios.post("match/delete_competition", payload).then(() => {
            store.addNotification({
                title: props.languagePack["manage_competitions"],
                message: props.languagePack["competition_deleted_success"],
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    onScreen: true,
                },
            });
            props.getCompetitions();
            props.closePopup();
        });
    };

    return (
        <div className="delete-competition-popup-container">
            <div className="delete-competition-header">
                <div className="delete-competition-title">
                    {languagePack["delete_competition"]}
                </div>
                <Icon name="close" className="close" onClick={() => props.closePopup()} />
            </div>
            <div className="delete-competition-text">
                {languagePack["delete_competition_text"]}
            </div>
            <div className="delete-competition-buttons">
                <Button
                    className="cancel-button"
                    onClick={() => props.closePopup()}
                >
                    {languagePack["admin_cancel"]}
                    <Icon name="close" className="dark-icon" />
                </Button>
                <Button
                    className="delete-comp-button"
                    onClick={deleteCompetition}

                >
                    {languagePack["admin_delete"]}
                    <Icon name="trash alternate outline" className="grey-icon" />
                </Button>
            </div>
        </div>
    )
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
}))(DeleteCompetition);