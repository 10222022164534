import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon, Button, TextArea } from "semantic-ui-react";
import OrderList from "./OrderList";
import axios from "axios";
import "./Orders.scss";

import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import { TFBTableCell, sxStickyColumn } from "../../../components/reusable/useStyles";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import ViewOrder from "./ViewOrder";
import DeleteOrder from "./DeleteOrder";

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      isFetching: true,

      open_view_dialog: false,
      viewing_order: {},

      open_edit_dialog: false,
      editing_order: {},

      open_delete_dialog: false,
      deleting_order: {},
    };
  }

  componentDidMount() {
    this.getOrders();
  }

  getOrders = () => {
    axios
      .get(`https://api.thefootballbrain.app/delivery/${this.props.currentUser.id_club}/id_venue`)
      .then((res) => this.setState({ orders: res.data, isFetching: false }))
      .catch((err) => console.log(err));
  };

  openViewOrder = (order) => {
    this.setState({ open_view_dialog: true, viewing_order: order })
  }

  closeViewOrder = () => {
    this.setState({ open_view_dialog: false, viewing_order: {} })
  }

  openEditOrder = (order) => {
    this.setState({ open_edit_dialog: true, editing_order: order })
  }

  closeEditOrder = () => {
    this.setState({ open_edit_dialog: false, editing_order: {} })
  }

  openDeleteOrder = (order) => {
    this.setState({ open_delete_dialog: true, deleting_order: order })
  }

  closeDeleteOrder = () => {
    this.setState({ open_delete_dialog: false, deleting_order: {} })
  }

  render() {
    const { languagePack } = this.props;

    return (
      <TFBCard>
        <TFBCardHeader title={languagePack.orders} />
        <TFBCardBody>
          {this.state.orders.length > 0 ?
            <TableContainer>
              <Table faria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn}>
                      {languagePack.order_id}
                    </TFBTableCell>
                    <TFBTableCell>
                      {languagePack.order_date}
                    </TFBTableCell>
                    <TFBTableCell>
                      {languagePack.order_customer}
                    </TFBTableCell>
                    <TFBTableCell>
                      {languagePack.order_shipping_method}
                    </TFBTableCell>
                    <TFBTableCell>
                      {languagePack.order_status}
                    </TFBTableCell>
                    <TFBTableCell>
                      {languagePack.order_payment_method}
                    </TFBTableCell>
                    <TFBTableCell>
                      {languagePack.order_amount}
                    </TFBTableCell>
                    <TFBTableCell align="right">
                      {languagePack.order_actions}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.orders.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                    >
                      <TFBTableCell
                        sx={sxStickyColumn}
                        style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                      >
                        {row.id_delivery_intern}
                      </TFBTableCell>
                      <TFBTableCell>
                        {moment(row.date_added).format("DD.MM.YYYY HH:mm:ss")}
                      </TFBTableCell>
                      <TFBTableCell>
                        {row.client_name}
                      </TFBTableCell>
                      <TFBTableCell>
                        {languagePack.order_delivery_types[row.delivery_type]}
                      </TFBTableCell>
                      <TFBTableCell>
                        {row.order_status}
                      </TFBTableCell>
                      <TFBTableCell>
                        {languagePack.order_payment_types[row.payment_type]}
                      </TFBTableCell>
                      <TFBTableCell>
                        {Number(row.total_order).toFixed(2)}
                      </TFBTableCell>
                      <TFBTableCell>
                        <div className="actions-btns-container" style={{ justifyContent: "flex-end" }}>
                          <TFBIconButton
                            name="edit"
                            color="darkGray"
                            onClick={() => this.openViewOrder(row)}
                          />
                          <TFBIconButton
                            name="delete"
                            color="darkGray"
                            onClick={() => this.openDeleteOrder(row)}
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> :
            <TFBPlaceholder text={languagePack.no_orders} />
          }
        </TFBCardBody>

        {/* ------------------- VIEW ORDER ---------------- */}
        {this.state.open_view_dialog &&
          <ViewOrder
            isOpenPopup={this.state.open_view_dialog}
            closePopup={this.closeViewOrder}
            refreshList={this.getOrders}
            orderData={this.state.viewing_order}
          />
        }

        {/* ------------------- DELETE ORDER ---------------- */}
        {this.state.open_delete_dialog &&
          <DeleteOrder
            isOpenPopup={this.state.open_delete_dialog}
            closePopup={this.closeDeleteOrder}
            refreshList={this.getOrders}
            orderData={this.state.deleting_order}
          />
        }
      </TFBCard>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(Orders);
