import React, { useState, useEffect, useRef } from "react";
import { logout } from "./../../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import { store } from "react-notifications-component";

import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import "./VideoDetails.scss";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";

import dayjs from "dayjs";
import TFBTimerInput from "../../../components/design-system/TFBTimerInput/TFBTimerInput";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";
import DeleteEvent from "./DeleteEvent/DeleteEvent";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";

const tfbSelectStyle = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#ffffff",
    height: "35px",
    border: "none",
    "&:hover": {
      border: "none",
      boxShadow: "0 0 0 2px black",
    },
  }),
};

const VideoDetails = (props) => {
  const [startSeconds, setStartSeconds] = useState(0);
  const [endSeconds, setEndSeconds] = useState(0);

  const [showForm, setShowForm] = useState(false);
  const [video, setVideo] = useState("");
  const [videoData, setVideoData] = useState("");

  const [startTimer, setStartTimer] = useState(dayjs("2022-04-17T00:00"));
  const [endTimer, setEndTimer] = useState(dayjs("2022-04-17T00:00"));
  const [currentVideoTime, setCurrentVideoTime] = useState("");

  const [events, setEvents] = useState("");
  const [eventsChapters, setEventsChapters] = useState("");
  const [loading, setLoading] = useState(false);

  const [playersOptions, setPlayersOptions] = useState("");
  const [categsOptions, setCategsOptions] = useState("");

  const [newEventTitle, setNewEventTitle] = useState("");
  const [newEventDesc, setNewEventDesc] = useState("");

  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [formEvents, setFormEvents] = useState(null);

  const [inputEvent, setInputEvent] = useState("");

  const [allChaptersChecked, setAllChaptersChecked] = useState("");

  const [idVideoTag, setIdVideoTag] = useState("");
  const [idVideo, setIdVideo] = useState("");

  const [deletePopup, setDeletePopup] = useState(false);
  const [editEventChapter, setEditEventChapter] = useState(false);
  const [eventsChapterToUpdate, setEventsChapterToUpdate] = useState(null);

  const [summaryVideoTitle, setSummaryVideoTitle] = useState("");

  const [timeInputError, setTimeInputError] = useState(false);
  const [dateDifferenceError, setDateDifferenceError] = useState(false);

  const videoRef = useRef(null);

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    getVideoData();
    getTags();
    getPlayers();
    setVideo(document.getElementById("video-player-" + id));
  }, []);

  useEffect(() => {
    getVideoData();
  }, [props.languagePack]);

  useEffect(() => {
    let totalDuration = null;
    if (videoRef.current) {
      totalDuration = videoRef.current.duration;
    }

    if (startSeconds > totalDuration || endSeconds > totalDuration) {
      setTimeInputError(true);
    } else {
      setTimeInputError(false);
    }

    if (startSeconds > endSeconds) {
      setDateDifferenceError(true);
    } else {
      setDateDifferenceError(false);
    }
  }, [startSeconds, endSeconds]);

  useEffect(() => {
    setVideo(document.getElementById("video-player-" + id));
  }, [showForm, editEventChapter]);

  useEffect(() => {
    if (eventsChapterToUpdate) {
      let chapterObject = { ...eventsChapterToUpdate };

      const second_start = chapterObject.data.second_start;
      const second_final = chapterObject.data.second_final;

      const firstEvent = {
        eventName:
          props.languagePack[
            chapterObject.video_tag_details[0].video_tag_slug
          ] ?? chapterObject.video_tag_details[0].tag_categ_name,
        eventId: chapterObject.video_tag_details[0].id_video_tag_categ,
        playerName: chapterObject.video_tag_details[0].player_name,
        playerId: chapterObject.video_tag_details[0].id_player,
        id_video_tag_detail:
          chapterObject.video_tag_details[0].id_video_tag_detail,
        id_video_tag_categ:
          chapterObject.video_tag_details[0].id_video_tag_categ,
        id_club: chapterObject.video_tag_details[0].id_club,
      };

      let additionalEvents = chapterObject.video_tag_details.filter(
        (tag, index) => {
          if (index != 0) return tag;
        }
      );
      additionalEvents = additionalEvents.map((eventTag) => {
        return {
          event: {
            label:
              props.languagePack[eventTag.video_tag_slug] ??
              eventTag.tag_categ_name,
            value: eventTag.id_video_tag,
            ...eventTag,
          },
          player: {
            label: eventTag.player_name,
            value: eventTag.id_player,
            ...eventTag,
          },
        };
      });

      setFormEvents(additionalEvents);

      setSelectedEvent({
        label: firstEvent.eventName,
        value: firstEvent.eventId,
        id_video_tag_detail: firstEvent.id_video_tag_detail,
        id_video_tag_categ: firstEvent.id_video_tag_categ,
        id_club: firstEvent.id_club,
      });

      setSelectedPlayer({
        label: firstEvent.playerName,
        value: firstEvent.playerId,
        ...chapterObject.video_tag_details[0],
      });

      setStartSeconds(chapterObject.data.second_start);
      setEndSeconds(chapterObject.data.second_final);
      setStartTimer(dayjs(`2022-04-17T${getTimeFromSecond(second_start)}`));
      setEndTimer(dayjs(`2022-04-17T${getTimeFromSecond(second_final)}`));
      setNewEventTitle(chapterObject.data.video_tag_title);
      setNewEventDesc(chapterObject.data.video_tag_description);
    }
  }, [eventsChapterToUpdate]);

  //================ GET DATA ========================
  // =================================================

  const getPlayers = () => {
    axios
      .get(`club/get_club_players`, {
        params: {
          id_club: props.currentUser.id_club,
          token: props.token,
        },
      })
      .then((res) => {
        let players = res.data.map((player) => {
          return {
            ...player,
            value: player.id_player,
            label: player.player_name,
          };
        });

        setPlayersOptions(players);
      })
      .catch((err) => console.log(err));
  };

  const getVideoData = () => {
    axios
      .get(`upload/get_video_data2`, {
        params: {
          id_video: id,
          token: props.token,
          id_club: props.currentUser.id_club,
        },
      })
      .then((res) => {
        setLoading(true);

        let allCategories = res.data.video_categs;

        var categoriesArray = allCategories.map((category) => {
          if (category.video_tag_slug != null) {
            return {
              ...category,
              label: props.languagePack[category.video_tag_slug],
              value: props.languagePack[category.video_tag_slug],
            };
          } else {
            return category;
          }
        });

        setVideoData(res.data.video_data[0]);
        setCategsOptions(categoriesArray);

        setLoading((prevState) => (prevState = false));
      })
      .catch((err) => console.log(err));
  };

  const getTags = () => {
    axios
      .get(`upload/get_video_tag2?id_video=${id}&token=${props.token}`)
      .then((res) => {
        let chapters = [];
        res.data.map((elem) => {
          let item = {
            video_tag_title: elem.data.video_tag_title,
            id_video_tag: elem.data.id_video_tag,
            second_start: elem.data.second_start,
            second_final: elem.data.second_final,
            id_video: elem.data.id_video,
            checked: false,
          };
          chapters.push(item);
        });
        setEvents(res.data);
        setEventsChapters(chapters);
      })
      .catch((err) => console.log(err));
  };

  //================ HANDLERS ========================
  // =================================================

  const getTimeFromSecond = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const second = seconds % 60;

    return `${hours}:${minutes}:${second}`;
  };

  function calculateSecondsString(timeString) {
    // Impartim timpul in ore, minute si secunde
    const [hours, minutes, seconds] = timeString.split(":").map(Number);

    // Calculam secundele totale
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;

    return totalSeconds;
  }

  const onEnterTimeInput = (event) => {
    if (event.keyCode === 13) {
      // ("Enter apăsat!"); 13 este codul pentru tasta enter
      if (videoRef.current) {
        videoRef.current.currentTime = calculateSecondsString(
          event.target.value
        );
      }
    }
  };

  const toHHMMSS = (secs) => {
    const sec = parseInt(secs, 10);
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - hours * 3600) / 60);
    let seconds = sec - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  };

  const handleGoToMinute = (startPoint) => {
    // Obține referința către elementul video
    const videoElement = videoRef.current;

    // Verifică dacă referința există și video-ul este încărcat
    if (videoElement && videoElement.readyState >= videoElement.HAVE_METADATA) {
      // Calculează numărul de secunde corespunzător minutei dorite
      const seconds = startPoint;

      // Setează currentTime la valoarea calculată (exprimată în secunde)
      videoElement.currentTime = seconds;
    }
  };

  const handleSelectEventType = (option) => {
    setSelectedEvent(option);
  };

  const handleSelectEventTypeEdit = (option) => {
    setSelectedEvent({
      ...option,
    });
  };

  const handleBlur = () => {
    // Verificăm dacă nu există o opțiune selectată și valoarea introdusă este nevidă
    // Creăm o nouă opțiune folosind valoarea introdusă
    if (inputEvent != "") {
      const newOption = {
        label: inputEvent,
        value: inputEvent,
        id_club: props.currentUser.id_club,
      };

      // Setăm noua opțiune ca opțiune selectată
      setSelectedEvent(newOption);
    }
  };

  const handleBlurEdit = () => {
    // Verificăm dacă nu există o opțiune selectată și valoarea introdusă este nevidă
    // Creăm o nouă opțiune folosind valoarea introdusă
    if (inputEvent != "") {
      const newOption = {
        label: inputEvent,
        value: inputEvent,
        id_club: props.currentUser.id_club,
      };

      // Setăm noua opțiune ca opțiune selectată
      setSelectedEvent(newOption);
    }
  };

  const handleInputEvent = (value) => {
    setInputEvent(value);
  };

  const generateSummaryVideo = () => {
    let allChaptersChecked = [...eventsChapters];
    allChaptersChecked = allChaptersChecked
      .filter((obj) => obj.checked == true) // Filtrăm obiectele cu checked === true
      .sort((a, b) => parseFloat(a.second_start) - parseFloat(b.second_start)); // Sortăm în funcție de second_start

    const idVideoTagArray = allChaptersChecked
      .map((obj) => obj.id_video_tag)
      .join("_");

    let payload = {
      video_name: summaryVideoTitle,
      category: "video",
      uploaded_by: props.currentUser.nickname,
      id_club: props.currentUser.id_club,
      id_video: id,
      id_user: props.currentUser.id_user,
      tags: idVideoTagArray,
    };

    axios
      .post("upload/generate_video", payload, {
        params: { token: props.token },
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res) => {
        history.push("/video-gallery");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlerResetForm = () => {
    setStartTimer(dayjs("2022-04-17T00:00"));
    setEndTimer(dayjs("2022-04-17T00:00"));
    setSelectedEvent(null);
    setSelectedPlayer(null);
    setNewEventTitle("");
    setNewEventDesc("");

    setStartSeconds(0);
    setEndSeconds(0);

    //FOR EDIT
    setEditEventChapter(false);
    setEventsChapterToUpdate(null);
    setFormEvents(null);
  };

  // ================= CONNECTION WITH BACKEND =================
  // ===========================================================
  const addNewTag = () => {
    let mainVideoTagDetails = {
      date_add: "",
      deleted: "0",
      id_club: selectedEvent.id_club ?? null,
      id_player: selectedPlayer.value,
      id_team: selectedPlayer.id_team,
      id_video_tag_categ: selectedEvent.id_video_tag_categ ?? "",
      id_video_tag_detail: "",
      player_name: selectedPlayer.player_name,
      tag_categ_name: selectedEvent.label,
    };

    let allTags = [];

    if (formEvents != null) {
      let additionalTags = formEvents.map((tag) => {
        return {
          date_add: "",
          deleted: "0",
          id_player: tag.player.id_player,
          id_team: tag.player.id_team,
          id_video_tag_categ: tag.event.id_video_tag_categ ?? "",
          id_club: tag.event.id_club ?? null,
          id_video_tag_detail: "",
          player_name: tag.player.player_name,
          tag_categ_name: tag.event.label,
        };
      });
      allTags = [mainVideoTagDetails, ...additionalTags];
    } else {
      allTags = [mainVideoTagDetails];
    }

    const payload = {
      id_club: props.currentUser.id_club,
      id_video: id,
      deleted: "0",
      second_start: startSeconds,
      second_final: endSeconds,
      video_tag_title: newEventTitle,
      video_tag_description: newEventDesc,
      video_tag_details: allTags,
    };

    axios
      .post("upload/add_video_tag2", payload, {
        params: { token: props.token },
        headers: { "content-type": "multipart/form-data" },
      })
      .then(async (res) => {
        if (res?.data?.go_to_login === 1) {
          props.logout(props.token);
        }
        if (res.data == 1) {
          getTags();
          store.addNotification({
            title: props.languagePack.gallery_videos_title,
            message: props.languagePack.success_video_added,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
            },
          });
          handlerResetForm();
          setShowForm(false);
        } else {
          store.addNotification({
            title: props.languagePack.gallery_videos_title,
            message: props.languagePack.failed_video_added,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
            },
          });
        }
      })
      .catch(async (err) => {
        return { data: {} };
      });

    setTimeInputError(false);
  };

  const editTag = () => {
    let mainVideoTagDetails = {
      deleted: "0",
      id_club: selectedEvent.id_club ?? null,
      id_player: selectedPlayer.value,
      id_team: selectedPlayer.id_team,
      id_video_tag_categ: selectedEvent.id_video_tag_categ ?? "",
      id_video_tag_detail:
        eventsChapterToUpdate.video_tag_details[0].id_video_tag_detail ?? "",
      id_video_tag: eventsChapterToUpdate.data.id_video_tag,
      player_name: selectedPlayer.player_name,
      tag_categ_name: selectedEvent.label,
    };

    let allTags = [];

    if (formEvents != null) {
      let additionalTags = formEvents.map((tag, tagIndex) => {
        return {
          deleted: tag.event.deleted,
          id_player: tag.player.id_player,
          id_team: tag.player.id_team,
          id_video_tag_categ: tag.event.id_video_tag_categ ?? "",
          id_club: tag.event.id_club ?? null,
          id_video_tag_detail: eventsChapterToUpdate.video_tag_details[
            tagIndex + 1
          ]
            ? eventsChapterToUpdate.video_tag_details[tagIndex + 1]
                .id_video_tag_detail
            : "",
          id_video_tag: eventsChapterToUpdate.data.id_video_tag,
          player_name: tag.player.player_name,
          tag_categ_name: tag.event.label,
        };
      });
      allTags = [mainVideoTagDetails, ...additionalTags];
    } else {
      allTags = [mainVideoTagDetails];
    }

    const payload = {
      id_club: props.currentUser.id_club,
      id_video: id,
      deleted: "0",
      id_video_tag: eventsChapterToUpdate.data.id_video_tag,
      second_start: startSeconds,
      second_final: endSeconds,
      video_tag_title: newEventTitle,
      video_tag_description: newEventDesc,
      video_tag_details: allTags,
    };

    axios
      .post("upload/add_video_tag2", payload, {
        params: { token: props.token },
        headers: { "content-type": "multipart/form-data" },
      })
      .then(async (res) => {
        if (res?.data?.go_to_login === 1) {
          props.logout(props.token);
        }
        if (res.data == 1) {
          getTags();
          store.addNotification({
            title: props.languagePack.gallery_videos_title,
            message: props.languagePack.success_video_edited,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
            },
          });
          handlerResetForm();
          setShowForm(false);
        } else {
          store.addNotification({
            title: props.languagePack.gallery_videos_title,
            message: props.languagePack.failed_video_edited,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
            },
          });
        }
      })
      .catch(async (err) => {
        return { data: {} };
      });

    setTimeInputError(false);
  };

  return (
    <div className="row">
      {/*================ VIDEO =====================  */}
      {/* =========================================== */}
      <div className="col-lg-8 col-lg-padding">
        <div className="video-player-card">
          {!loading ? (
            <TFBCard>
              <TFBCardHeader
                className="animate-slide"
                title={videoData.video_name}
              />

              <TFBCardBody>
                <video
                  ref={videoRef}
                  id={"video-player-" + id}
                  className="video"
                  src={videoData.video_url}
                  controls
                  type="video/mp4"
                  style={{ width: "100%", margin: "auto" }}
                  onTimeUpdate={() => setCurrentVideoTime(video.currentTime)}
                ></video>
              </TFBCardBody>
            </TFBCard>
          ) : (
            <div>{"Loading..."}</div>
          )}
        </div>
      </div>

      {/*================ EVENTS =====================  */}
      {/* =========================================== */}
      <div className="col-lg-4 col-lg-padding">
        <div className="events-card">
          <TFBCard>
            <TFBCardHeader title={props.languagePack.video_event}>
              <TFBButton
                color="green"
                renderIcon={() => <TFBIcon name="add" />}
                onClick={() => {
                  setShowForm(true);
                  setEditEventChapter(false);
                  handlerResetForm();
                }}
              >
                {props.languagePack.add_tag}
              </TFBButton>
            </TFBCardHeader>

            <TFBCardBody>
              {/*================ ADD EVENT =====================  */}
              {/* =========================================== */}
              {showForm && (
                <>
                  {" "}
                  <div className="time-setting-container animate-drop">
                    <div className="start-moment">
                      <TFBTimerInput
                        onKeyDown={(event) => onEnterTimeInput(event)}
                        label={props.languagePack.starting_moment}
                        value={startTimer}
                        onChange={(newValue) => {
                          setStartTimer(newValue);
                          let totalDuration = null;
                          if (videoRef.current) {
                            totalDuration = videoRef.current.duration;
                          }

                          if (newValue && newValue.$d != "Invalid Date") {
                            var totalSeconds =
                              newValue.$H * 3600 +
                              newValue.$m * 60 +
                              newValue.$s;
                            if (totalSeconds > totalDuration) {
                              setTimeInputError(true);
                            }
                            setStartSeconds(totalSeconds);
                          }
                        }}
                      />

                      <TFBButton
                        onClick={() => {
                          let totalTime = video.currentTime;
                          let hours = Math.floor(totalTime / 3600);
                          let minutes = Math.floor(
                            (totalTime - hours * 3600) / 60
                          );
                          let seconds = Math.floor(
                            totalTime - hours * 3600 - minutes * 60
                          );
                          setStartTimer(
                            dayjs(`2022-04-17T${hours}:${minutes}:${seconds}`)
                          );
                          setStartSeconds(totalTime);
                        }}
                      >
                        Preia
                      </TFBButton>
                    </div>

                    <div className="end-moment">
                      <TFBTimerInput
                        onKeyDown={(event) => onEnterTimeInput(event)}
                        label={props.languagePack.ending_moment}
                        value={endTimer}
                        onChange={(newValue) => {
                          setEndTimer(newValue);

                          let totalDuration = null;
                          if (videoRef.current) {
                            totalDuration = videoRef.current.duration;
                          }

                          if (newValue && newValue.$d != "Invalid Date") {
                            var totalSeconds =
                              newValue.$H * 3600 +
                              newValue.$m * 60 +
                              newValue.$s;

                            if (totalSeconds > totalDuration) {
                              setTimeInputError(true);
                            }
                            setEndSeconds(totalSeconds);
                          }
                        }}
                      />

                      <TFBButton
                        onClick={() => {
                          let totalTime = video.currentTime;
                          let hours = Math.floor(totalTime / 3600);
                          let minutes = Math.floor(
                            (totalTime - hours * 3600) / 60
                          );
                          let seconds = Math.floor(
                            totalTime - hours * 3600 - minutes * 60
                          );
                          setEndTimer(
                            dayjs(`2022-04-17T${hours}:${minutes}:${seconds}`)
                          );
                          setEndSeconds(totalTime);
                        }}
                      >
                        Preia
                      </TFBButton>
                    </div>
                  </div>
                  {timeInputError && (
                    <div
                      className="text-danger"
                      style={{ marginBottom: "20px", marginTop: "20px" }}
                    >
                      {props.languagePack.vide_time_input_error}
                    </div>
                  )}
                  {dateDifferenceError && (
                    <div
                      className="text-danger"
                      style={{ marginBottom: "20px", marginTop: "20px" }}
                    >
                      {props.languagePack.date_time_difference_error}
                    </div>
                  )}
                  <div className="event-player-container animate-drop">
                    <TFBSelect
                      options={categsOptions}
                      value={selectedEvent}
                      onBlur={handleBlur}
                      onChange={handleSelectEventType}
                      onInputChange={handleInputEvent}
                      label={props.languagePack.event_type}
                      placeholder={props.languagePack.event_type}
                      className="event-type"
                      styles={tfbSelectStyle}
                    />
                    <TFBSelect
                      options={playersOptions}
                      label={props.languagePack.player}
                      placeholder={props.languagePack.player}
                      className="event-player"
                      value={selectedPlayer}
                      onChange={(selected) => setSelectedPlayer(selected)}
                      styles={tfbSelectStyle}
                    />
                  </div>
                  {formEvents &&
                    formEvents.map((event, index) => (
                      <div
                        className="event-player-container animate-drop"
                        key={"new-event-form-" + index}
                      >
                        <TFBSelect
                          options={categsOptions}
                          value={event.event}
                          onBlur={(e) => {
                            if (e.target.value && e.target.value != "") {
                              let allNewEvents = [...formEvents];

                              if (allNewEvents[index].event != null) {
                                allNewEvents[index].event = {
                                  ...allNewEvents[index].event,
                                  id_club: props.currentUser.id_club,
                                  value: e.target.value,
                                  label: e.target.value,
                                  tag_categ_name: e.target.value,
                                  id_video_tag_categ: "",
                                };
                              } else {
                                allNewEvents[index].event = {
                                  id_club: props.currentUser.id_club,
                                  deleted: "0",
                                  date_add: "",
                                  id_video_tag: "",
                                  id_video_tag_detail: "",
                                  value: e.target.value,
                                  label: e.target.value,
                                  tag_categ_name: e.target.value,
                                  id_video_tag_categ: "",
                                };
                              }

                              setFormEvents(allNewEvents);
                            }
                          }}
                          onChange={(selected) => {
                            let allNewEvents = [...formEvents];
                            allNewEvents[index].event = {
                              ...selected,
                              date_add: "",
                              id_video_tag: "",
                              id_club: selected.id_club ?? null,
                              id_video_tag_detail: "",
                              deleted: "0",
                              tag_categ_name: selected.label,
                            };

                            setFormEvents(allNewEvents);
                          }}
                          label={props.languagePack.event_type}
                          placeholder={props.languagePack.event_type}
                          className="event-type"
                          styles={tfbSelectStyle}
                        />
                        <TFBSelect
                          options={playersOptions}
                          label={props.languagePack.player}
                          placeholder={props.languagePack.player}
                          className="event-player"
                          value={event.player}
                          onChange={(selected) => {
                            let allNewEvents = [...formEvents];
                            allNewEvents[index].player = selected;
                            setFormEvents(allNewEvents);
                          }}
                          styles={tfbSelectStyle}
                        />

                        <TFBIconButton
                          color="red"
                          name="x-small"
                          style={{ marginBottom: "1px" }}
                          onClick={() => {
                            let allEvents = [...formEvents];
                            allEvents.splice(index, 1);

                            setFormEvents(allEvents);
                          }}
                        />
                      </div>
                    ))}
                  <div className="add-more-events animate-drop">
                    <TFBButton
                      renderIcon={() => <TFBIcon name="add" />}
                      onClick={() => {
                        let newEvent = {
                          player: null,
                          event: null,
                        };
                        let allNewEvents = [];
                        if (formEvents) {
                          allNewEvents = [...formEvents, newEvent];
                        } else {
                          allNewEvents = [newEvent];
                        }

                        setFormEvents(allNewEvents);
                      }}
                    >
                      {props.languagePack.add_event}
                    </TFBButton>
                  </div>
                  <div className="event-title animate-drop">
                    <TFBInput
                      label={props.languagePack.tag_title}
                      placeholder={props.languagePack.tag_title}
                      fullWidth
                      onChange={(e) => {
                        setNewEventTitle(e.target.value);
                      }}
                    />
                  </div>
                  <div className="event-details animate-drop">
                    <TFBInput
                      label={props.languagePack.tag_details}
                      placeholder={props.languagePack.enter_details}
                      style={{ minHeight: "75px" }}
                      fullWidth
                      onChange={(e) => {
                        setNewEventDesc(e.target.value);
                      }}
                    />
                  </div>
                  <div className="add-event-buttons animate-drop">
                    <TFBButton
                      renderIcon={() => <TFBIcon name="cancel" />}
                      onClick={() => {
                        setShowForm(false);
                        handlerResetForm();
                        setTimeInputError(false);
                        setShowForm(false);
                      }}
                    >
                      {props.languagePack.Cancel}
                    </TFBButton>
                    <TFBButton
                      renderIcon={() => <TFBIcon name="check" />}
                      color="green"
                      onClick={addNewTag}
                      disabled={
                        timeInputError ||
                        dateDifferenceError ||
                        newEventTitle == "" ||
                        selectedEvent == null ||
                        selectedPlayer == null ||
                        (formEvents &&
                          formEvents.some(
                            (event) => !event.event || !event.player
                          ))
                      }
                    >
                      {props.languagePack.admin_save}
                    </TFBButton>
                  </div>
                  <div className="white-bar-horizontal animate-slide"></div>
                </>
              )}

              {/*================ EDIT EVENT =====================  */}
              {/* =========================================== */}
              {editEventChapter && (
                <>
                  {" "}
                  <div className="time-setting-container animate-drop">
                    <div className="start-moment">
                      <TFBTimerInput
                        onKeyDown={(event) => onEnterTimeInput(event)}
                        label={props.languagePack.starting_moment}
                        value={startTimer}
                        onChange={(newValue) => {
                          setStartTimer(newValue);

                          let totalDuration = null;
                          if (videoRef.current) {
                            totalDuration = videoRef.current.duration;
                          }

                          if (newValue && newValue.$d != "Invalid Date") {
                            var totalSeconds =
                              newValue.$H * 3600 +
                              newValue.$m * 60 +
                              newValue.$s;

                            if (totalSeconds > totalDuration) {
                              setTimeInputError(true);
                            }
                            setStartSeconds(totalSeconds);
                          }
                        }}
                      />

                      <TFBButton
                        onClick={() => {
                          let totalTime = video.currentTime;
                          let hours = Math.floor(totalTime / 3600);
                          let minutes = Math.floor(
                            (totalTime - hours * 3600) / 60
                          );
                          let seconds = Math.floor(
                            totalTime - hours * 3600 - minutes * 60
                          );
                          setStartTimer(
                            dayjs(`2022-04-17T${hours}:${minutes}:${seconds}`)
                          );
                          setStartSeconds(totalTime);
                        }}
                      >
                        Preia
                      </TFBButton>
                    </div>

                    <div className="end-moment">
                      <TFBTimerInput
                        onKeyDown={(event) => onEnterTimeInput(event)}
                        label={props.languagePack.ending_moment}
                        value={endTimer}
                        onChange={(newValue) => {
                          setEndTimer(newValue);

                          let totalDuration = null;
                          if (videoRef.current) {
                            totalDuration = videoRef.current.duration;
                          }

                          if (newValue && newValue.$d != "Invalid Date") {
                            var totalSeconds =
                              newValue.$H * 3600 +
                              newValue.$m * 60 +
                              newValue.$s;

                            if (totalSeconds > totalDuration) {
                              setTimeInputError(true);
                            }
                            setEndSeconds(totalSeconds);
                          }
                        }}
                      />

                      <TFBButton
                        onClick={() => {
                          let totalTime = video.currentTime;
                          let hours = Math.floor(totalTime / 3600);
                          let minutes = Math.floor(
                            (totalTime - hours * 3600) / 60
                          );
                          let seconds = Math.floor(
                            totalTime - hours * 3600 - minutes * 60
                          );
                          setEndTimer(
                            dayjs(`2022-04-17T${hours}:${minutes}:${seconds}`)
                          );
                          setEndSeconds(totalTime);
                        }}
                      >
                        Preia
                      </TFBButton>
                    </div>
                  </div>
                  {timeInputError && (
                    <div
                      className="text-danger"
                      style={{ marginBottom: "20px", marginTop: "20px" }}
                    >
                      {props.languagePack.vide_time_input_error}
                    </div>
                  )}
                  {dateDifferenceError && (
                    <div
                      className="text-danger"
                      style={{ marginBottom: "20px", marginTop: "20px" }}
                    >
                      {props.languagePack.date_time_difference_error}
                    </div>
                  )}
                  <div className="event-player-container animate-drop">
                    <TFBSelect
                      options={categsOptions}
                      value={selectedEvent}
                      onBlur={handleBlurEdit}
                      onChange={handleSelectEventTypeEdit}
                      onInputChange={handleInputEvent}
                      label={props.languagePack.event_type}
                      placeholder={props.languagePack.event_type}
                      className="event-type"
                      styles={tfbSelectStyle}
                    />
                    <TFBSelect
                      options={playersOptions}
                      label={props.languagePack.player}
                      placeholder={props.languagePack.player}
                      className="event-player"
                      value={selectedPlayer}
                      onChange={(selected) => setSelectedPlayer(selected)}
                      styles={tfbSelectStyle}
                    />
                  </div>
                  {formEvents &&
                    formEvents.map((event, index) => {
                      if (event.event == null || event.event.deleted == 0) {
                        return (
                          <div
                            className="event-player-container animate-drop"
                            key={"new-event-form-" + index}
                          >
                            <TFBSelect
                              options={categsOptions}
                              value={event.event}
                              onBlur={(e) => {
                                if (e.target.value && e.target.value != "") {
                                  let allNewEvents = [...formEvents];
                                  allNewEvents[index].event = {
                                    ...allNewEvents[index].event,
                                    value: e.target.value,
                                    label: e.target.value,
                                    tag_categ_name: e.target.value,
                                    id_video_tag_categ: "",
                                    id_club: props.currentUser.id_club,
                                    deleted: "0",
                                  };
                                  setFormEvents(allNewEvents);
                                }
                              }}
                              onChange={(selected) => {
                                let allNewEvents = [...formEvents];
                                allNewEvents[index].event = {
                                  ...selected,
                                  date_add: "",
                                  id_video_tag: "",
                                  id_video_tag_categ:
                                    selected.id_video_tag_categ,
                                  id_video_tag_detail: "",
                                  deleted: "0",
                                  tag_categ_name: selected.label,
                                };

                                setFormEvents(allNewEvents);
                              }}
                              label={props.languagePack.event_type}
                              placeholder={props.languagePack.event_type}
                              className="event-type"
                              styles={tfbSelectStyle}
                            />
                            <TFBSelect
                              options={playersOptions}
                              label={props.languagePack.player}
                              placeholder={props.languagePack.player}
                              className="event-player"
                              value={event.player}
                              onChange={(selected) => {
                                let allNewEvents = [...formEvents];
                                allNewEvents[index].player = selected;
                                setFormEvents(allNewEvents);
                              }}
                              styles={tfbSelectStyle}
                            />

                            <TFBIconButton
                              color="red"
                              name="x-small"
                              style={{ marginBottom: "1px" }}
                              onClick={() => {
                                let allEvents = [...formEvents];
                                if (allEvents[index].event != null) {
                                  allEvents[index].event.deleted = "1";
                                } else {
                                  allEvents.splice(index, 1);
                                }

                                setFormEvents(allEvents);
                              }}
                            />
                          </div>
                        );
                      }
                    })}
                  <div className="add-more-events animate-drop">
                    <TFBButton
                      renderIcon={() => <TFBIcon name="add" />}
                      onClick={() => {
                        let newEvent = {
                          player: null,
                          event: null,
                        };
                        let allNewEvents = [];
                        if (formEvents) {
                          allNewEvents = [...formEvents, newEvent];
                        } else {
                          allNewEvents = [newEvent];
                        }

                        setFormEvents(allNewEvents);
                      }}
                    >
                      {props.languagePack.add_event}
                    </TFBButton>
                  </div>
                  <div className="event-title animate-drop">
                    <TFBInput
                      label={props.languagePack.tag_title}
                      value={newEventTitle}
                      placeholder={props.languagePack.tag_title}
                      fullWidth
                      onChange={(e) => {
                        setNewEventTitle(e.target.value);
                      }}
                    />
                  </div>
                  <div className="event-details animate-drop">
                    <TFBInput
                      label={props.languagePack.tag}
                      value={newEventDesc}
                      placeholder={props.languagePack.enter_details}
                      style={{ minHeight: "75px" }}
                      fullWidth
                      onChange={(e) => {
                        setNewEventDesc(e.target.value);
                      }}
                    />
                  </div>
                  <div className="add-event-buttons animate-drop">
                    <TFBButton
                      renderIcon={() => <TFBIcon name="cancel" />}
                      onClick={() => {
                        setShowForm(false);
                        handlerResetForm();
                        setTimeInputError(false);
                        setShowForm(false);
                      }}
                    >
                      {props.languagePack.Cancel}
                    </TFBButton>
                    <TFBButton
                      renderIcon={() => <TFBIcon name="check" />}
                      color="green"
                      onClick={editTag}
                      disabled={
                        timeInputError ||
                        dateDifferenceError ||
                        newEventTitle == "" ||
                        selectedEvent == null ||
                        selectedPlayer == null ||
                        (formEvents &&
                          formEvents.some(
                            (event) => !event.event || !event.player
                          ))
                      }
                    >
                      {props.languagePack.injury_edit}
                    </TFBButton>
                  </div>
                  <div className="white-bar-horizontal animate-slide"></div>
                </>
              )}

              {/*================ EVENTS CHAPTERS LIST =====================  */}
              {/* ================================================= */}
              <div className="events-list">
                <TFBCheckbox
                  label={props.languagePack.select_all}
                  checked={allChaptersChecked}
                  onChange={(e) => {
                    let allChapters = [...eventsChapters];

                    allChapters = allChapters.map((chapter) =>
                      e.target.checked == true
                        ? { ...chapter, checked: true }
                        : { ...chapter, checked: false }
                    );

                    setEventsChapters(allChapters);
                    setAllChaptersChecked(e.target.checked);
                  }}
                />

                {eventsChapters &&
                  eventsChapters.map((event, index) => (
                    <div
                      className="event-itself animate-drop"
                      key={"event-itself" + index}
                    >
                      <TFBCheckbox
                        style={{ marginLeft: "20px", cursor: "pointer" }}
                        checked={event.checked}
                        onChange={() => {
                          let allChapters = [...eventsChapters];
                          allChapters[index].checked = !allChapters[index]
                            .checked;

                          const isAllChecked = allChapters.every(
                            (chapter) => chapter.checked
                          );

                          setAllChaptersChecked(isAllChecked ? true : false);
                          setEventsChapters(allChapters);
                        }}
                      />

                      <div
                        className="details"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleGoToMinute(event.second_start)}
                      >
                        <div className="title">{event.video_tag_title}</div>
                        <div className="exact-time">{`${toHHMMSS(
                          event.second_start
                        )} - ${toHHMMSS(event.second_final)}`}</div>
                      </div>

                      <div className="buttons">
                        <TFBIcon
                          name="play"
                          color="black"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleGoToMinute(event.second_start)}
                        ></TFBIcon>
                        <TFBIcon
                          name="edit"
                          color="black"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setEventsChapterToUpdate(events[index]);
                            setShowForm(false);
                            setEditEventChapter(true);
                          }}
                        />
                        <TFBIcon
                          name="delete"
                          color="black"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setEditEventChapter(false);
                            setIdVideoTag(event.id_video_tag);
                            setIdVideo(event.id_video);
                            setDeletePopup(true);
                          }}
                        ></TFBIcon>
                      </div>
                    </div>
                  ))}
              </div>

              {/*================ GENERATE VIDEO HIGHLIGHTS ======================  */}
              {/* ================================================================ */}
              <div className="generate-highlights">
                <TFBInput
                  value={summaryVideoTitle}
                  placeholder={props.languagePack.add_summary_title}
                  onChange={(e) => setSummaryVideoTitle(e.target.value)}
                />
                <TFBButton
                  renderIcon={() => <TFBIcon name="calendar" />}
                  onClick={generateSummaryVideo}
                  disabled={
                    summaryVideoTitle == "" ||
                    eventsChapters.every((chapter) => chapter.checked == false)
                  }
                >
                  {props.languagePack.create_summary}
                </TFBButton>
              </div>
            </TFBCardBody>
          </TFBCard>
        </div>
      </div>

      {/*================ DELETE EVENT POPUP =============================  */}
      {/* ================================================================ */}
      <DeleteEvent
        closePopup={() => {
          setDeletePopup(false);
          setIdVideoTag("");
          setIdVideo("");
        }}
        openDeletePopup={deletePopup}
        languagePack={props.languagePack}
        id_video={idVideo}
        id_club={props.currentUser.id_club}
        id_video_tag={idVideoTag}
        token={props.token}
        logout={props.logout}
        refreshTags={getTags}
      />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}

export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(VideoDetails);
