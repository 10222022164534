import React from "react";
import axios from "axios";
import { Button, Icon } from "semantic-ui-react";
import "./PartnerProfile.scss";
import LastTransactions from "./LastTransactions";
import EndOfMonthSold from "./EndOfMonthSold";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

class PartnerProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      partner_name: "",
      end_month_values: [],
      client_end_month_values: [],
      provider_end_month_values: [],
      transactions: [],
      current_sold: "",
      editPopup: false,
      new_partner_name: "",
    };
  }

  componentDidMount = () => {
    this.getPartnerData();
  };

  getPartnerData = () => {
    const id = this.props.match.params.id;
    axios.get(`finances/get_partner_data?id_partner=${id}`).then((res) => {
      this.setState({
        partner_name: res.data.partner_name,
        end_month_values: res.data.end_month_values,
        transactions: res.data.transactions,
        current_sold: res.data.current_sold,
        client_end_month_values: res.data.client_end_month_values,
        provider_end_month_values: res.data.provider_end_month_values,
      });
    });
  };

  saveEditChanges = () => {
    const partner_data = {
      name: this.state.new_partner_name,
      id_partner: this.props.match.params.id,
    };
    axios
      .post("finances/edit_partner_data", partner_data)
      .then((res) => {
        this.setState({ editPopup: false });
        this.getPartnerData();
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div>
        <div className="partner-header">
          <div className="partner-title">
            Profil partener -
            <div className="name">{this.state.partner_name}</div>
          </div>
          <Button
            className="edit-partner-button"
            onClick={() => this.setState({ editPopup: true })}
          >
            Editeaza detalii
          </Button>
        </div>
        <div className="row" style={{ marginTop: 20 }}>
          <div className="col-lg-6">
            <div className="card card-custom">
              <LastTransactions
                transactions={this.state.transactions}
                refreshData={this.getPartnerData}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card card-custom">
              <EndOfMonthSold
                end_month_values={this.state.end_month_values}
                client_end_month_values={this.state.client_end_month_values}
                provider_end_month_values={this.state.provider_end_month_values}
                current_sold={this.state.current_sold}
              />
            </div>
          </div>
        </div>

        <Dialog
          open={this.state.editPopup}
          maxWidth="xl"
          // onClose={() => this.setState({ editPopup: false })}
        >
          <div className="add-team-popup-header">
            <div className="add-team-popup-title">
              Editeaza detalii partener
            </div>
            <div className="add-team-popup-close">
              {" "}
              <Icon
                name="close"
                className="close-icon-popup"
                onClick={() => this.setState({ editPopup: false })}
              />
            </div>
          </div>

          <DialogContent>
            <div className="edit-partner-popup-container">
              <input
                type="text"
                className="edit-partner-input"
                value={this.state.new_partner_name}
                onChange={(e) =>
                  this.setState({ new_partner_name: e.target.value })
                }
                placeholder="Modifica numele"
              />
              <div
                className="operation-form-row buttons-container-operations"
                style={{ justifyContent: "space-between", display: "flex" }}
              >
                <Button
                  className="operation-cancel-button"
                  onClick={() => this.setState({ editPopup: false })}
                >
                  {" "}
                  <div className="button-content">
                    Anuleaza
                    <Icon name="x" className="op-cancel-icon" />
                  </div>
                </Button>
                <Button
                  className="operation-save-button"
                  onClick={this.saveEditChanges}
                >
                  {" "}
                  <div className="button-content">
                    Salveaza
                    <Icon name="add" className="add-icon" />
                  </div>
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default PartnerProfile;
