import React from "react";
import "./Players.scss";
import _ from "lodash";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../../components/reusable/useStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";
import { useHistory } from "react-router-dom";

export default function PlayersTable(props) {
  const history = useHistory();

  return (
    <TableContainer style={{ marginTop: "50px" }}>
      <Table
        sx={{
          minWidth: 650,
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TFBTableCell
              sx={sxStickyColumn}
              style={{
                width: "24%",
              }}
            >
              <div
                className="header-table-checkbox"
                style={{
                  display: "flex",
                }}
              >
                <TFBCheckbox
                  onChange={(e) => props.handleHeaderCheckbox(e)}
                  checked={props.allChecked}
                />
                {props.languagePack.PLAYER_NAME}
              </div>
            </TFBTableCell>
            <TFBTableCell
              align="center"
              style={{
                width: "24%",
              }}
            >
              {props.languagePack.POSITION}
            </TFBTableCell>
            <TFBTableCell
              align="center"
              style={{
                width: "24%",
              }}
            >
              {props.languagePack.AGE}
            </TFBTableCell>
            <TFBTableCell
              align="center"
              style={{
                width: "24%",
              }}
            >
              {props.languagePack.teams}
            </TFBTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.players.map((row, index) => (
            <TableRow
              key={"active-players-table-" + index}
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
                backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                opacity: row.inactive == 1 ? "0.3" : "1",
              }}
            >
              <TFBTableCell
                component="th"
                scope="row"
                sx={sxStickyColumn}
                style={{
                  backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                  width: "24%",
                }}
              >
                <div
                  className="row-table-checkbox"
                  style={{
                    display: "flex",
                  }}
                >
                  <TFBCheckbox
                    key={"active-players-table-aa-" + index}
                    onChange={(e) => props.handlePlayerCheckbox(index)}
                    checked={row.checked}
                  />
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => history.push(`club/player/${row.id_player}`)}
                  >
                    {row.player_name}
                  </div>
                </div>
              </TFBTableCell>
              <TFBTableCell align="center">{row.position}</TFBTableCell>
              <TFBTableCell
                align="center"
                style={{
                  width: "24%",
                }}
              >
                {row.age}
                <br />
                <span className="player-dob">{row.player_dob}</span>
              </TFBTableCell>
              <TFBTableCell
                align="center"
                style={{
                  width: "24%",
                }}
              >
                {row.team}
              </TFBTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
