import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { TPlayerWithAvatar } from "../types";
import styles from "./TFBPlayerWithAvatar.module.scss";

const TFBPlayerWithAvatar = ({
  className,
  style,
  avatarUrl,
  playerName,
  onClick,
}: TPlayerWithAvatar) => {
  return (
    <div
      className={`${styles.playerAvatarWrapper}  ${className ? className : ""}`}
      style={style}
    >
      <div style={{ cursor: "pointer" }} onClick={onClick}>
        <Avatar src={avatarUrl} />
      </div>
      <div>
        <div style={{ color: "#666666", cursor: "pointer" }} onClick={onClick}>
          {playerName}
        </div>
      </div>
    </div>
  );
};

export default TFBPlayerWithAvatar;
