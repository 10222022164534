import React from "react";
import axios from "axios";
import { Button, Icon, Dropdown, TextArea } from "semantic-ui-react";

const payment_options = [
  {
    key: "once",
    value: "once",
    text: "Plata unica",
  },
  {
    key: "weekly",
    value: "weekly",
    text: "Saptamanal",
  },
  {
    key: "monthly",
    value: "monthly",
    text: "Lunar",
  },
  {
    key: "yearly",
    value: "yearly",
    text: "Anual",
  },
];

class AddRentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      players_dropdown: [],
      rates: [],
      id_player: "",
      value: "",
      currency: "RON",
      type: "",
      frequency: "",
      description: "",
    };
  }

  componentDidMount = () => {
    this.getCurrency();
    this.getPlayersDropdown();
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value });
  };

  getCurrency = () => {
    fetch("https://api.thefootballbrain.app/club/get_currency_rates")
      .then((res) => res.json())
      .then((data) => this.setState({ rates: data }));
  };

  getPlayersDropdown = () => {
    axios
      .get(`finances/get_players_rent_dropdown?id_club=${this.props.id_club}`)
      .then((res) => this.setState({ players_dropdown: res.data }))
      .catch((err) => console.log(err));
  };

  submitRent = () => {
    const rentData = {
      id_player: this.state.id_player,
      id_club: this.props.id_club,
      payment_frequency: this.state.frequency,
      value:
        this.state.currency === "EURO"
          ? this.state.value
          : this.state.value / this.state.rates["RON"],
      cost_type: "rent",
    };

    axios
      .post("finances/add_player_cost", rentData)
      .then(() => {
        this.props.closePopup();
        this.props.refreshList();
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div className="add-rent-form-container">
        <div className="add-rent-form-row">
          <Dropdown
            placeholder="Alege jucator"
            fluid
            selection
            className="add-rent-custom-dropdown"
            options={this.state.players_dropdown}
            value={this.state.id_player}
            name="id_player"
            onChange={this.handleChangeDropdown}
            search
          />
        </div>
        <div className="add-rent-form-row">
          <input
            type="text"
            value={this.state.value}
            onChange={(e) => this.setState({ value: e.target.value })}
            placeholder="Valoare"
            className="add-rent-custom-input"
          />
          <div
            className="currency-container"
            style={{ marginRight: 50, marginLeft: 20 }}
            onClick={() => this.setState({ currency: "RON" })}
          >
            <div
              className={
                this.state.currency === "RON"
                  ? "currency-checkbox currency-checkbox-selected"
                  : "currency-checkbox"
              }
            />
            <div className="currency-name">RON</div>
          </div>
          <div
            className="currency-container"
            onClick={() => this.setState({ currency: "EURO" })}
          >
            <div
              className={
                this.state.currency === "EURO"
                  ? "currency-checkbox currency-checkbox-selected"
                  : "currency-checkbox"
              }
            />
            <div className="currency-name">EURO</div>
          </div>
        </div>
        <div className="add-rent-form-row">
          <Dropdown
            placeholder="Frecventa platii"
            fluid
            selection
            className="add-rent-custom-dropdown"
            options={payment_options}
            value={this.state.frequency}
            name="frequency"
            onChange={this.handleChangeDropdown}
          />
        </div>

        <TextArea
          placeholder="Detalii.."
          className="add-rent-text-area"
          value={this.state.description}
          onChange={(e) => this.setState({ description: e.target.value })}
        />

        <div className="add-rent-buttons-row">
          <Button
            className="rent-cancel-button"
            onClick={() => this.props.closePopup()}
          >
            <div className="button-content">
              Anuleaza
              <Icon name="ban" size="small" className="white-icon" />
            </div>
          </Button>
          <Button className="rent-add-button" onClick={this.submitRent}>
            <div className="button-content">
              Adauga
              <Icon name="add" size="small" className="white-icon" />
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default AddRentForm;
